import React from 'react'
import clsx from 'clsx'

import {
    LocationDataType,
} from '../OrderSteps.types'

import useStyles from './StepLocation.style'

const StepLocation = ({
    value,
}: {value: LocationDataType[]}) => {
    const {
        classes,
    } = useStyles()

    return (
        <div>
            {
                value.map(({
                    title,
                    iconPath,
                    isActual,
                }, index) => {
                    if (index === 0) {
                        return (
                            <div
                                className={classes.address}
                                key={`step-location-item-${index}`} /* eslint-disable-line react/no-array-index-key */
                            >
                                <img
                                    className={classes.iconLocation}
                                    src={iconPath}
                                    alt="location"
                                />
                                <span className={classes.addressName}>{title}</span>
                            </div>
                        )
                    }
                    return (
                        <div
                            className={classes.addressDelivery}
                            data-testid="address-delivery"
                            key={`step-location-item-${index}`} /* eslint-disable-line react/no-array-index-key */
                        >
                            <div className={classes.containerIcon}>
                                <div className={clsx(
                                    classes.verticalLine,
                                    isActual && classes.blue,
                                )}
                                />
                                <div className={clsx(
                                    classes.verticalLine,
                                    isActual && classes.blue,
                                )}
                                />
                                <div className={clsx(
                                    classes.verticalLine,
                                    isActual && classes.blue,
                                )}
                                />
                                <img
                                    className={classes.iconLocation}
                                    src={iconPath}
                                    alt="location"
                                />
                            </div>
                            <span className={classes.addressName}>{title}</span>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default StepLocation
