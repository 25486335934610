// eslint-disable-next-line import/prefer-default-export
import {
    BaseLeaseTable,
} from './BaseLease/BaseLease.types'
import {
    EmbargoContriesTable,
} from './EmbargoCountries/EmbargoContries.types'
import {
    OtherLaneFeesTable,
} from './OtherLaneFees/OtherLaneFees.types'
import {
    AdditionalFeesTable,
} from './AdditionalFees/AdditionalFees.types'

export enum RateCardsTabs {
    BaseLease = 'base-lease',
    OtherLaneFees = 'other-lane-fees',
    AdditionalFees = 'additional-fees',
    EmbargoCountries = 'embargo-countries',
    Margins = 'margins',
    LogisticsCosts = 'logistics-costs',
    RegionalServiceAvailability = 'regional-service-availability'
}

export type RateCardsValues = BaseLeaseTable & EmbargoContriesTable
    & OtherLaneFeesTable & AdditionalFeesTable
