import {
    createContext, useContext, Dispatch, SetStateAction,
} from 'react'
import {
    ScenarioEditorDataType,
} from './ScenarioEditor.types'

export type ScenarioEditorContextType = {
    contextValue: ScenarioEditorDataType,
    setContextValue: Dispatch<SetStateAction<Partial<ScenarioEditorDataType>>>,
}

export const ScenarioEditorContext = createContext<ScenarioEditorContextType>(
    {} as ScenarioEditorContextType,
)

const useScenarioEditorContext = () => {
    return useContext<ScenarioEditorContextType>(ScenarioEditorContext)
}

export default useScenarioEditorContext
