import React, {
    useState, useEffect, useRef, useCallback,
} from 'react'

import {
    useAppSelector, useAppDispatch,
} from 'app/store/hooks'
import useOutsideClick from 'app/hooks/useOutsideClick'
import useClickHandler from 'app/hooks/useClickHandler'
import {
    addToDomainConfigs,
    selectSpreadSheetConfigByDomain,
} from 'app/store/SpreadSheetConfigs'
import {
    KeyboardKeys,
} from 'app/types/common.enums'

import Dropdown from '../../Dropdown'
import {
    SpreadsheetCellOption,
} from '../../SkyNetSpreadSheet.types'
import useSkyNetSpreadSheetContext from '../../SkyNetSpreadSheetContext'

import {
    TypeaheadCell as TypeaheadCellType,
} from './TypeaheadCellTemplate'

const TypeaheadCell = ({
    onChange,
    cell,
}: {
    onChange: (e) => void,
    cell: TypeaheadCellType,
}) => {
    const dispatch = useAppDispatch()
    const wrapperRef = useRef()
    const {
        forceEditQuit,
        setFocusChanging,
        rowHeight,
        configId,
    } = useSkyNetSpreadSheetContext()

    const domainConfig = useAppSelector((state) => {
        return selectSpreadSheetConfigByDomain(state, {
            name: configId,
            field: cell.name,
            domain: cell.domain,
        })
    })

    const [
        options,
        setOptions,
    ] = useState<SpreadsheetCellOption[]>(domainConfig.options)

    const [
        search,
        setSearch,
    ] = useState<string>(null)

    const [
        pending,
        setPending,
    ] = useState<boolean>(!!cell.filters)

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        async function loadOptions() {
            const result = await cell.loadOptions({
                phrase: search,
                filters: cell.filters,
            })

            setOptions(result)
            setPending(false)
        }

        if (search || cell.filters) loadOptions()
    }, [
        search,
        cell,
    ])

    useEffect(() => {
        if (inputRef.current) inputRef.current.focus()
        setFocusChanging(false)

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (inputRef.current) inputRef.current.blur()
            setFocusChanging(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useOutsideClick(wrapperRef, forceEditQuit)
    useClickHandler([KeyboardKeys.ESCAPE], forceEditQuit)

    const handleChange = useCallback(({
        value, label, textLabel, ...rest
    }) => {
        onChange({
            value, label, textLabel, ...rest,
        })
        if (cell.domain) {
            dispatch(addToDomainConfigs({
                name: configId,
                domain: cell.domain,
                field: cell.name,
                params: {
                    options: [{
                        value, label, textLabel, id: value,
                    }],
                },
            }))
        }
    }, [
        cell,
        configId,
        dispatch,
        onChange,
    ])

    return (
        <div ref={wrapperRef}>
            <input
                ref={inputRef}
                data-testid="typeaheadInput"
                defaultValue={search}
                onClick={forceEditQuit}
                onChange={(e) => {
                    setSearch(e?.target?.value)
                }}
                onKeyDown={(e) => {
                    e.stopPropagation()
                    if (e.key === KeyboardKeys.BACKSPACE) {
                        const val = inputRef.current?.value

                        if (val) setSearch(val.slice(0, val.length - 1))
                    }
                }}
            />
            <Dropdown
                rowHeight={rowHeight}
                onChange={handleChange}
                options={options}
                pending={pending}
            />
        </div>
    )
}

export default TypeaheadCell
