import React from 'react'
import {
    LaneFeesUpdate, LaneFeesActionButtons,
} from 'app/shared-components/LaneFees'
import {
    LaneFeesStatus,
} from 'app/types/enums'

export default function AssignPricingLaneFeesUpdate({
    id,
    refetch,
}: Readonly<{ id?: number, refetch?: (...args: any[]) => void }>) {
    return (
        <LaneFeesUpdate
            id={id}
            refetch={refetch}
            buttonsConfig={{
                [LaneFeesStatus.SUBMITTED]: [
                    LaneFeesActionButtons.SetInProgress,
                    LaneFeesActionButtons.SetToRejected,
                ],
                [LaneFeesStatus.IN_PROGRESS]: [LaneFeesActionButtons.SetToSubmitted],
                [LaneFeesStatus.REJECTED]: [LaneFeesActionButtons.SetToAwarded],
            } as Record<LaneFeesStatus, LaneFeesActionButtons[]>}
        />
    )
}
