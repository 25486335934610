import React from 'react'
import clsx from 'clsx'
import noop from 'lodash/noop'

import containerTemperatureRanges from 'app/utils/containerTemperatureRanges'
import {
    ProductType,
} from 'app/types/enums'

import {
    ContainerTypeSelectionItemType,
} from '../../simulation.types'

import ContainerTypeSelectionSpecialItem from './ContainerTypeSelectionSpecialItem'
import useStyles from './ContainerTypeSelectionItem.style'
import getTemperatureString from './utils/getTemperatureString'

type Props = {
    value: ContainerTypeSelectionItemType,
    onChange: (newVal: ContainerTypeSelectionItemType, isFormChange: boolean) => void,
    selectedProductType?: ProductType,
}

const defaultProps = {
    selectedProductType: null,
}

const ContainerTypeSelectionItem = ({
    value,
    selectedProductType,
    onChange,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const {
        productType,
        title,
        imgSrc,
        tempRange,
        reicingEnabled,
    } = value

    const isSelected: boolean = selectedProductType === productType

    const unselected: boolean = selectedProductType && !isSelected

    const handleClick = (): void => {
        onChange(value, false)
    }

    const handleFormClick = (event: React.SyntheticEvent<EventTarget>): void => {
        if (reicingEnabled && isSelected) {
            event.stopPropagation()
        }
    }

    const renderTemperatureString: string = tempRange && getTemperatureString(
        containerTemperatureRanges[tempRange],
    )

    return (
        <li
            key={`Container-selection-item-${productType}`}
            data-testid={`container-selection-item-${productType}`}
            className={classes.selectionItemContainer}
        >
            <div
                className={classes.selectionItemBtn}
                onClick={handleClick}
                onKeyPress={noop}
                data-testid="selection-item-btn"
            >
                <div
                    className={
                        clsx(
                            classes.selectionItem,
                            unselected && classes.unselectedViewItem,
                        )
                    }
                >
                    <div className={classes.selectionItemContent}>
                        <div
                            className={
                                clsx(
                                    classes.itemTitle,
                                    unselected && classes.unselectedItemTitle,
                                )
                            }
                        >
                            {title}
                        </div>
                        <div
                            className={classes.selectionItemImageContainer}
                        >
                            <img
                                className={classes.selectionItemImage}
                                alt={title}
                                src={imgSrc}
                            />
                        </div>
                        <div
                            className={
                                clsx(
                                    classes.tempExcursion,
                                    unselected && classes.unselectedItemTempExcursion,
                                )
                            }
                            onClick={handleFormClick}
                            onKeyPress={noop}
                            data-testid="form-wrapper"
                        >
                            {
                                reicingEnabled
                                    ? (
                                        <ContainerTypeSelectionSpecialItem
                                            value={value}
                                            isSelected={isSelected}
                                            onChange={onChange}
                                        />
                                    )
                                    : renderTemperatureString
                            }
                        </div>
                    </div>
                    <div
                        className={
                            clsx(
                                classes.footer,
                                !unselected && classes[`colorType${tempRange}`],
                                unselected && classes.unselectedViewItemFooter,
                            )
                        }
                    />
                </div>
            </div>
        </li>
    )
}

ContainerTypeSelectionItem.defaultProps = defaultProps

export default ContainerTypeSelectionItem
