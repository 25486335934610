import React, {
    useEffect,
} from 'react'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useEnumValues from 'app/hooks/useEnumValues'

import {
    SimulationStep,
    SimulationStepType,
} from '../../simulation.types'

import useStyles from './StepItem.styles'
import getFields from './stepItem.fields'

type Props = {
    onChange: (step: SimulationStep) => void,
    value: SimulationStep,
}

const StepItem = ({
    value,
    onChange,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const fieldsWithClassName = useFieldsWithClassName(
        getFields(value.type, getAllowedValues),
        classes,
    )

    useEffect((): void => {
        if (value.type === SimulationStepType.RE_ICING && !value?.amountCoolingGel) {
            onChange({
                ...value,
                amountCoolingGel: 70,
            })
        }
    }, [
        onChange,
        value,
        value?.type,
    ])

    return (
        <>
            <DomainObjectForm
                value={value}
                name="SimulationStep"
                fields={fieldsWithClassName}
                onChange={onChange}
            />
            <div className={classes.containerImg}>
                {
                    value.type && (
                        <img
                            className={classes.img}
                            alt={value.type}
                            src={`/assets/images/icons/${value.type.toLowerCase()}.svg`}
                        />
                    )
                }
            </div>
        </>
    )
}

export default StepItem
