import React, {
    useMemo,
} from 'react'
import {
    Navigate,
} from 'react-router-dom'

import {
    CommonRoutes, SkyNetRouteConfigObject, useSkyNetRoutes,
} from 'app/SkyNetRoutes'

import Damages from './Damages'
import DamageTypes from './DamageTypes'
import useDamageTrackingRoutes from './hooks/useDamageTrackingRoutes'
import {
    DamageTrackingDomains,
} from './Damages/Damages.types'

export default function DamageTracking() {
    const Routes = useDamageTrackingRoutes()

    const routesConfig: SkyNetRouteConfigObject<DamageTrackingDomains> = useMemo(() => {
        return {
            [Routes.Damages.route]: <Damages />,
            [Routes.DamageTypes.route]: <DamageTypes />,
            [CommonRoutes.SLASH]: <Navigate
                to={Routes.Damages.route}
                replace
            />,
        }
    }, [Routes])

    return useSkyNetRoutes({
        routesConfig,
    })
}
