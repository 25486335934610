import React from 'react'
import useEnumValues from 'app/hooks/useEnumValues'

import FormWithControls from 'app/shared-components/FormWithControls'
import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import {
    RequestConfig,
} from 'app/types/request.types'

import {
    DamageType,
} from '../DamageTypes.types'
import requests from '../DamageTypes.requests'
import getDamageTypeCreateFields from './DamageType.fields'

type Props = ActionComponentCreateProps & {
    requestParams: RequestConfig,
    data?: DamageType,
    disabled?: boolean,
    exists?: boolean,
    customButtons?: JSX.Element[],
}

const defaultProps: Partial<Props> = {
    data: {} as DamageType,
    disabled: false,
    exists: false,
    customButtons: [],
}

const DamageTypeForm = ({
    onSuccess,
    requestParams,
    disabled,
    data,
    exists,
    customButtons,
}: Props) => {
    const getAllowedValue = useEnumValues()

    return (
        <FormWithControls
            onSuccess={onSuccess}
            name={requests.domainName}
            fields={getDamageTypeCreateFields(getAllowedValue)}
            requestParams={requestParams}
            disabled={disabled}
            exists={exists}
            value={data}
            customButtons={customButtons}
        />
    )
}

DamageTypeForm.defaultProps = defaultProps

export default DamageTypeForm
