import {
    RichTableColumns,
} from 'app/types/richtable.types'

export default [
    {
        id: 'noteSubject',
        headerProps: {
            children: 'Note Subject',
        },
        mapCellProps: 'noteSubject',
    },
    {
        id: 'noteText',
        headerProps: {
            children: 'Note Text',
        },
        mapCellProps: 'noteText',
    },
    {
        id: 'createdBy',
        headerProps: {
            children: 'Created By',
        },
        mapCellProps: 'createdBy',
    },
    {
        id: 'validDateTimestamp',
        headerProps: {
            children: 'Valid Date Timestamp',
        },
        mapCellProps: 'validDateTimestamp',
        componentName: 'DateTimeCell',
    },
] as RichTableColumns[]
