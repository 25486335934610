import React, {
    useMemo,
    useCallback,
    useState,
} from 'react'
import Typography from '@mui/material/Typography'
import {
    Collapse,
} from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import Card from 'app/shared-components/Card'
import {
    Currency,
} from 'app/types/enums'

import useStyles from './Positions.style'
import getColumns from './positions.columns'
import PositionForm from './PositionForm'
import useGetPositionsByInvoiceId from './hooks/useGetPositionsByInvoiceId'

type Props = {
    disabled?: boolean,
    canBeDeleted: boolean,
    invoiceId: number,
    currency?: Currency,
}

const defaultProps: Partial<Props> = {
    disabled: false,
    currency: undefined,
}

const DOMAIN_NAME = orderManagementRequest.Position.name

const Positions = ({
    currency,
    invoiceId,
    disabled,
    canBeDeleted,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const {
        refresh,
        load,
    } = useGetPositionsByInvoiceId(invoiceId)

    const [
        isNewPosition,
        setNewPosition,
    ] = useState<boolean>(false)

    const cancelNewPosition = useCallback((): void => {
        setNewPosition(false)
    }, [])

    const openNewPosition = useCallback((): void => {
        setNewPosition(true)
    }, [])

    const columns = useMemo(() => {
        return getColumns({
            currency,
            onSuccess: refresh,
            canBeDeleted,
            invoiceId,
            disabled,
        })
    }, [
        refresh,
        currency,
        canBeDeleted,
        invoiceId,
        disabled,
    ])

    const getDetailPanel = useCallback(({
        rowProps,
        closeOpenedRow,
    }): JSX.Element => {
        return (
            <PositionForm
                value={rowProps}
                closeOpenedRow={closeOpenedRow}
                onSuccess={refresh}
                invoiceId={invoiceId}
                name={DOMAIN_NAME}
                exists
            />
        )
    }, [
        invoiceId,
        refresh,
    ])

    return (
        <div
            data-testid="invoice-position"
            className={classes.root}
        >
            {!disabled && (
                <Collapse in={isNewPosition}>
                    <PositionForm
                        onSuccess={refresh}
                        value={{
                            positionCode: null,
                            positionType: null,
                            positionDescription: null,
                            unitPrice: null,
                            quantity: null,
                            id: null,
                        }}
                        closeOpenedRow={cancelNewPosition}
                        invoiceId={invoiceId}
                        name={DOMAIN_NAME}
                    />
                </Collapse>
            )}
            <Card
                fullHeight
                contentClass={classes.cardContent}
                data-testid="position-card"
            >
                <Typography variant="h3">
                    Positions
                </Typography>
                {!disabled && (
                    <div className={classes.iconContainer}>
                        {isNewPosition
                            ? (
                                <RemoveIcon
                                    className={classes.icon}
                                    onMouseDown={cancelNewPosition}
                                    data-testid="cancel-new-position"
                                />
                            )
                            : (
                                <AddIcon
                                    data-testid="add-new-position"
                                    className={classes.icon}
                                    onMouseDown={openNewPosition}
                                />
                            )}
                    </div>
                )}
                <RichTable
                    configName="Positions"
                    name={DOMAIN_NAME}
                    load={load}
                    columns={columns}
                    className={classes.tableContent}
                    classNames={{
                        contentWrapper: classes.tableContentWrapper,
                    }}
                    detailPanel={!disabled && getDetailPanel}
                />
            </Card>
        </div>
    )
}

Positions.defaultProps = defaultProps

export default Positions
