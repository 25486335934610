import React, {
    useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission/useTabsByPermission'

import useDamageTrackingRoutes from '../hooks/useDamageTrackingRoutes'

import getTableConfig from './Damages.config'
import request from './Damages.requests'
import DamageCreate from './DamageCreate'
import DamageUpdate from './DamageUpdate'
import DamageChangeHistory from './DamageChangeHistory'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Damages = () => {
    const {
        Damages: DamagesRoutes,
    } = useDamageTrackingRoutes()
    const createPermission = useHasPermission(['damage_tracking_create'])
    const readPermission = useHasPermission(['damage_tracking_read'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Data Update',
                url: DamagesRoutes.Update.route,
                Component: DamageUpdate,
            },
            permission: readPermission,
        },
        {
            tab: {
                label: 'Change History',
                url: DamagesRoutes.ChangeHistory.route,
                Component: DamageChangeHistory,
            },
            permission: readPermission,
        },
    ])

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            actionComponents={{
                Create: createPermission
                    ? DamageCreate
                    : undefined,
            }}
            name={tableConfig.name}
            sidebarTabs={tabs}
            defaultTab={DamagesRoutes.Update.route}
        >
            <SkyNetTable
                moduleName={request.domainName}
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default Damages
