// eslint-disable-next-line import/prefer-default-export
export enum AccountManagementDomains {
    KamDashboard = 'kam-dashboard',
    Accounts = 'accounts',
    RegionalGroups = 'regional-groups',
    Opportunitites = 'opportunities',
    Lanes = 'lanes',
    AQuotes = 'a-quotes',
    CQuotes = 'c-quotes',
    PreBookings = 'pre-bookings',
}
