import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import request from '../customerTransport.request'

const useUpdateStatusOrder = (onSuccess: () => void, orderNumber: string) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessModification,
        onError,
    } = useHandleStatusRequest({
        id: 'OrderByNumberView',
        title: 'Order Status Updated',
        onSuccess,
    })

    return useMutation({
        mutationFn: () => {
            return request.UpdateStatusOrder.mutationFn({
                orderNumber,
                token,
            })
        },
        onSuccess: onSuccessModification,
        onError,
    })
}

export default useUpdateStatusOrder
