import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        link: {
            color: theme.palette.secondary[600],
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '3px 5px 3px 2px !important',
        },
    }
})

export default useStyles
