import React, {
    useMemo,
} from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import useLabel from 'app/hooks/useLabel'

import useCQuoteDocuments from '../hooks/useCQuoteDocuments'

import columns from './CQuotesPDF.columns'
import useStyles from './CQuotesPDF.styles'

const CQuotesPDF = ({
    id,
}: {
    id: number
}) => {
    const {
        classes,
    } = useStyles()

    const l = useLabel()

    const {
        onParamsChange,
        load,
    } = useCQuoteDocuments(id)

    const fields = useMemo(() => {
        return columns({
            l,
        })
    }, [l])

    return (
        <Card
            fullHeight
            contentClass={classes.cardContent}
        >
            <RichTable
                configName="CQuotePDFTable"
                onParamsChange={onParamsChange}
                name="CQuotePDF"
                load={load}
                columns={fields}
                uniqField="id"
                className={classes.tableContent}
                classNames={{
                    contentWrapper: classes.tableContentWrapper,
                }}
            />
        </Card>
    )
}

export default CQuotesPDF
