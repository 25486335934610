export enum IndustryType {
    AIRLINE = 'AIRLINE',
    ACTIVE_PHARMACEUTICAL_INGREDIENT = 'ACTIVE_PHARMACEUTICAL_INGREDIENT',
    BANKING = 'BANKING',
    BIOTECH = 'BIOTECH',
    CHEMICALS = 'CHEMICALS',
    CONTRACT_MANUFACTURING_ORGANIZATION = 'CONTRACT_MANUFACTURING_ORGANIZATION',
    COMPETITOR = 'COMPETITOR',
    CONTRACT_RESEARCH_ORGANIZATION = 'CONTRACT_RESEARCH_ORGANIZATION',
    FOOD_SUPPLY = 'FOOD_SUPPLY',
    FREIGHT_FORWARDER = 'FREIGHT_FORWARDER',
    GOVERNMENT = 'GOVERNMENT',
    HEALTHCARE = 'HEALTHCARE',
    INVESTOR = 'INVESTOR',
    LOGISTIC_COMPANY = 'LOGISTIC_COMPANY',
    MEDIA = 'MEDIA',
    MEDICAL_CANNABIS = 'MEDICAL_CANNABIS',
    MEDICAL_DEVICES = 'MEDICAL_DEVICES',
    NON_GOVERNMENT_ORGANISATION = 'NON_GOVERNMENT_ORGANISATION',
    NON_PROFIT_ORGANIZATION = 'NON_PROFIT_ORGANIZATION',
    OTHER = 'OTHER',
    PHARMACEUTICAL_COMPANY = 'PHARMACEUTICAL_COMPANY',
    PHARMACEUTICAL_MANUFACTURER = 'PHARMACEUTICAL_MANUFACTURER',
    RESEARCH = 'RESEARCH'
}

export type Company = {
    availableInSkyMind?: boolean,
    skyMindId?: number,
    changedBy?: string,
    companyName?: string,
    companyNumber?: string,
    createdBy?: string,
    custom?: boolean,
    deleted?: boolean,
    id: number,
    locationNameRule?: string,
    website?: string
    awbPrefix?: number,
    archived?: boolean,
    hasAccount?: boolean,
    industryType?: IndustryType,
}

export type CompanyWithParent = Company & { parentCompany?: Company }

export enum CompaniesTabs {
    SkyMind = 'skymind'
}
