import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import Button from 'app/shared-components/Button'
import {
    isDeliveryRoad,
    isCollection,
} from 'app/Apps/Pricing/utils/utils'

import useStyles from '../AQuoteCreate.styles'
import useAQuoteInformationContext from '../AQuoteInformationContext'
import useGetLanesQuoteStepper from '../../hooks/useGetLanesQuoteStepper'

import QuoteLanesTable from './QuoteLanesTable'

const QuoteLanes = ({
    disabled,
}: {
    disabled: boolean
}) => {
    const {
        classes,
        cx,
    } = useStyles()
    const [
        lanes,
        setLanes,
    ] = useState([])
    const {
        value, setValue, activeStep, setActiveStep, initialValue,
    } = useAQuoteInformationContext()

    const isCreate = !initialValue

    const {
        data,
        isDataReady,
    } = useGetLanesQuoteStepper(value)

    const dataEnhanced = useMemo(() => {
        return data?.map((lane) => {
            return {
                ...lane,
                handoverPoint: isDeliveryRoad(lane.deliveryServiceType)
                    ? `${lane.handoverPointZipCode} ${lane.handoverPointCountryName}`
                    : '-',
                collectionDropoff: isCollection(lane.collectionServiceType)
                    ? `${lane.collectionDropoffPointZipCode} ${lane.collectionDropoffPointCountryName}`
                    : '-',
            }
        })
    }, [data])

    useEffect(() => {
        if (isDataReady) {
            if (isCreate) {
                setLanes(dataEnhanced)

                return
            }
            setLanes(dataEnhanced.map((lane) => {
                const requestedLane = initialValue?.requestedLanes.find(({
                    accountLane,
                }) => {
                    return accountLane.id === lane.id
                })

                if (requestedLane) {
                    return {
                        ...lane,
                        baseLeaseDays: requestedLane.baseLeaseDays?.join(';'),
                        otherComments: requestedLane.otherComments,
                        selected: true,
                        existed: true,
                    }
                }
                return lane
            }))
        }
    }, [
        isDataReady,
        dataEnhanced,
        isCreate,
        initialValue?.requestedLanes,
    ])

    const handleChange = useCallback((updates) => {
        setLanes((prev) => {
            return [...prev.map((lane) => {
                if (updates.id === lane.id) {
                    return {
                        ...lane,
                        ...updates,
                    }
                }
                return lane
            })]
        })
    }, [])

    const selectedLanes = useMemo(() => {
        return lanes?.reduce((acc, lane) => {
            const {
                selected,
                baseLeaseDays,
                otherComments,
                existed,
                ...restLane
            } = lane

            if (selected && existed) {
                acc.push({
                    accountLane: restLane,
                    baseLeaseDays,
                    otherComments,
                })
            }

            if (selected && !existed) {
                acc.push({
                    accountLane: restLane,
                })
            }
            return acc
        }, [])
    }, [lanes])

    const nextAction = useCallback(() => {
        setValue({
            ...value,
            requestedLanes: selectedLanes,
        })
        setActiveStep(activeStep + 1)
    }, [
        selectedLanes,
        value,
        setValue,
        setActiveStep,
        activeStep,
    ])

    const backAction = useCallback(() => {
        if (value.requestedLanes?.length) {
            setValue({
                ...value,
                requestedLanes: isCreate ? [] : value.requestedLanes,
            })
        }
        setActiveStep(activeStep - 1)
    }, [
        value,
        setActiveStep,
        activeStep,
        setValue,
        isCreate,
    ])

    const isNextDisabled = useMemo(() => {
        return disabled
            || (isCreate
                ? !selectedLanes?.length
                : selectedLanes?.length === initialValue?.requestedLanes?.length)
    }, [
        disabled,
        initialValue?.requestedLanes?.length,
        isCreate,
        selectedLanes?.length,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={!isDataReady}
        >
            <div>
                <QuoteLanesTable
                    data={lanes}
                    onChange={handleChange}
                    disabled={disabled}
                />
                <div
                    className={cx(classes.buttonWrapper, classes.doubleButtons)}
                >
                    <Button
                        label="Next"
                        onClick={nextAction}
                        className={classes.button}
                        disabled={isNextDisabled}
                    />
                    <Button
                        label="Back"
                        secondary
                        onClick={backAction}
                        className={classes.button}
                        disabled={disabled}
                    />
                </div>
            </div>
        </StatusHandler>
    )
}

export default QuoteLanes
