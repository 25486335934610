import {
    FilterType,
} from 'app/types/common.enums'
import {
    FilterConfig,
} from 'app/hooks/useFilter'
import {
    OtherLaneFeesTable,
} from '../OtherLaneFees.types'

const filterConfig: FilterConfig<keyof Pick<OtherLaneFeesTable,
    'currency'>> = {
        currency: {
            filterField: 'currency',
            filterType: FilterType.Array,
        },
    }

export default filterConfig
