import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requestConfig from '../../AQuotes.requests'

const useRemoveLaneFromLaneGroup = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessRemove,
        onError,
    } = useHandleStatusRequest({
        id: 'RemoveLaneFromLaneGroup',
        title: 'Lane has been removed from lane group',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            laneId,
        }: {
            laneId: number,
        }) => {
            return requestConfig.RemoveLaneFromLaneGroup.mutationFn({
                laneId,
                token,
            })
        },
        onSuccess: onSuccessRemove,
        onError,
    })
}

export default useRemoveLaneFromLaneGroup
