import React, {
    useCallback, useMemo, useEffect,
} from 'react'
import pick from 'lodash/pick'
import isEqual from 'lodash/isEqual'
import useRequest, {
    RequestFn, RequestKeys,
} from 'app/hooks/useRequest'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    ForecastsByProductType,
} from '../ScenarioEditor.types'
import requests from '../../serviceCenters.request'
import useScenarioEditorContext from '../ScenarioEditorContext'
import ForecastsByProduct from './ForecastsByProduct'
import {
    formatToForecastsByProductType,
} from './ForecastsByProduct.utils'

export default function ForecastsByProductProvider() {
    const {
        contextValue,
        setContextValue,
    } = useScenarioEditorContext()

    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
    } = useRequest<{ forecastsByProduct: ForecastsByProductType[]}>({
        key: RequestKeys.scenarioEditorInitialValues,
        requestFunc: RequestFn.getEntity(requests[RequestKeys.scenarioEditorInitialValues]),
        enabled: !contextValue.modified,
        params: {
            data: pick(contextValue, [
                'serviceCenter',
                'orderSelectionDelay',
                'includeNotStartedOrders',
                'includePreBookings',
            ]),
        },
    })

    const applyParameters = useCallback((value) => {
        setContextValue({
            modified: true,
            forecastsByProduct: formatToForecastsByProductType(value),
        })
    }, [setContextValue])

    const forecastsByProduct = useMemo(() => {
        if (contextValue?.modified) {
            return contextValue?.forecastsByProduct
        }
        return data?.forecastsByProduct
    }, [
        contextValue?.forecastsByProduct,
        contextValue?.modified,
        data?.forecastsByProduct,
    ])

    useEffect(() => {
        if (isDataReady && !contextValue?.modified
            && !isEqual(contextValue?.forecastsByProduct, data?.forecastsByProduct)) {
            setContextValue({
                forecastsByProduct: data?.forecastsByProduct,
            })
        }
    }, [
        isDataReady,
        data?.forecastsByProduct,
        setContextValue,
        contextValue?.modified,
        contextValue?.forecastsByProduct,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ForecastsByProduct
                data={forecastsByProduct}
                applyParameters={applyParameters}
            />
        </StatusHandler>
    )
}
