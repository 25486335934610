import {
    tss,
} from 'app/tss'

export default tss.create(({
    theme,
}) => {
    return {
        container: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                '& $icon': {
                    color: theme.palette.text.primary,
                },
            },
        },
        button: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(1),
            borderRadius: '0 2px 2px 0',
            cursor: 'pointer',
            transition: 'transform 0.2s ease-in',
            background: theme.palette.common.white,
            height: 48,
            width: 24,
            boxShadow: '0px 1px 3px #00000033',
            '&:hover': {
                background: theme.palette.common.white,
            },
        },
        icon: {
            height: '10px !important',
            width: '10px !important',
            color: theme.palette.text.secondary,
            borderRadius: 0,
            '&:hover': {
                backgroundColor: `${theme.palette.primary[30]} !important`,
            },
            '&:focus': {
                outline: 'none',
            },
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        content: {
            height: '100%',
            transition: 'opacity 0.2s ease-in, visibility 0.2s ease-in,  width 0.2s ease-in',
            overflow: 'hidden',
            '&.collapsed': {
                width: 0,
                opacity: 0,
                visibility: 'hidden',
            },
            '&.expanded': {
                width: '100%',
                opacity: 1,
                visibility: 'visible',
            },
        },
    }
})
