import React, {
    useMemo,
} from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import usePricingRoutes from 'app/Apps/Pricing/hooks/usePricingRoutes'
import useGetContractBasis from 'app/Apps/Pricing/ContractBasis/hooks/useGetContractBasis'

import {
    CQuote,
} from '../CQuotes.types'

import getFields from './CQuoteForm.fields'

export default function CQuoteForm({
    data,
    disabled,
}: Readonly<{
    data: CQuote,
    disabled: boolean
}>) {
    const {
        ContractBasis: ContractBasisRoutes,
    } = usePricingRoutes()
    const {
        data: contractBasis,
        isDataReady: contractBasisReady,
    } = useGetContractBasis(data?.linkedContractBasis?.id)

    const getAllowedValues = useEnumValues()
    const fields = useMemo(() => {
        return getFields({
            getAllowedValues,
            linkedContractBasis: data?.linkedContractBasis?.id ? generateSkyNetLink({
                domainPath: ContractBasisRoutes.ContractBasis.path,
                params: {
                    key: data?.linkedContractBasis?.id,
                },
            }) : null,
            contractBasisNumber: contractBasisReady
                ? contractBasis?.contractBasisNumber : undefined,
        })
    }, [
        contractBasisReady,
        ContractBasisRoutes,
        getAllowedValues,
        data,
        contractBasis,
    ])

    return (
        <DomainObjectForm
            fields={fields}
            value={data}
            disabled={disabled}
        />
    )
}
