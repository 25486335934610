import React, {
    useCallback, useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import {
    RoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'

import usePricingRoutes from '../hooks/usePricingRoutes'

import useStyles from './Dashboard.style'
import getNewQuotesConfig from './config/newQuotes.config'
import getBeingPricedConfig from './config/beingPriced.config'
import getPricingCompletedConfig from './config/completed.config'
import getLanesConfig from './config/lanes.config'
import dashboardRequest from './Dashboard.requests'

const DOMAIN_NAME = 'Dashboard'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Dashboard = () => {
    const {
        AQuotes: AQuotesRoutes,
    } = usePricingRoutes()
    const {
        classes,
    } = useStyles()

    const onRowClick = useCallback(({
        id,
    }: {id: number}) => {
        return AQuotesRoutes.Overview.navigate({
            params: {
                key: id,
            },
        })
    }, [AQuotesRoutes])

    const onLaneRowClick = useCallback(({
        quoteId,
        laneGroupId,
    }: {
        quoteId: number,
        laneGroupId: number,
    }) => {
        if (laneGroupId) {
            return (AQuotesRoutes.AssignPricing as RoutesConfig).LaneFees.navigate({
                params: {
                    key: quoteId,
                    laneGroupId,
                },
            })
        }

        return AQuotesRoutes.Overview.navigate({
            params: {
                key: quoteId,
            },
        })
    }, [AQuotesRoutes])

    const {
        newQuotesConfig,
        beingPricedConfig,
        pricingCompletedConfig,
        lanesConfig,
    } = useMemo(() => {
        return {
            newQuotesConfig: getNewQuotesConfig(),
            beingPricedConfig: getBeingPricedConfig(),
            pricingCompletedConfig: getPricingCompletedConfig(),
            lanesConfig: getLanesConfig(),
        }
    }, [])

    return (
        <div className={classes.root}>
            <div className={classes.headerTitle}>
                Quotes
                <div className={classes.actionWrapper} />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={onRowClick}
                    customUrl={dashboardRequest.url.newQuotes}
                    tableConfig={newQuotesConfig}
                    tableControls={tableControls}
                    uniqField={dashboardRequest.uniqField}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={onRowClick}
                    customUrl={dashboardRequest.url.pricingBeingPriced}
                    tableConfig={beingPricedConfig}
                    tableControls={tableControls}
                    uniqField={dashboardRequest.uniqField}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={onRowClick}
                    customUrl={dashboardRequest.url.pricingCompleted}
                    tableConfig={pricingCompletedConfig}
                    tableControls={tableControls}
                    uniqField={dashboardRequest.uniqField}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.headerTitle}>
                Lanes
                <div className={classes.actionWrapper} />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    moduleName="Lanes"
                    onRowClick={onLaneRowClick}
                    customUrl={dashboardRequest.url.lanes}
                    tableConfig={lanesConfig}
                    tableControls={tableControls}
                    uniqField={dashboardRequest.uniqField}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
        </div>
    )
}

export default Dashboard
