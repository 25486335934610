import React, {
    useCallback,
    useState,
} from 'react'
import noop from 'lodash/noop'

import {
    RequestConfig,
} from 'app/types/request.types'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import FormWithControls from 'app/shared-components/FormWithControls'
import useEnumValues from 'app/hooks/useEnumValues'

import AlLeasePricingRequests from '../AlLeasePricing.requests'
import {
    AlLeasePricing,
} from '../AlLeasePricing.types'

import alLeasePricingFields from './alLeasePricing.fields'
import useStyles from './AlLeasePricingForm.styles'

type Props = {
    data?: AlLeasePricing,
    onSuccess?: (props: any) => void,
    onCancel?: (...args: any[]) => void,
    modifyDataBeforeSend?: (newValue: any) => any,
    customButtons?: JSX.Element[],
    requestParam: RequestConfig,
}

const defaultProps: Partial<Props> = {
    data: {} as AlLeasePricing,
    onSuccess: noop,
    onCancel: noop,
    modifyDataBeforeSend: (val) => { return val },
    customButtons: undefined,
}

const AlLeasePricingForm = ({
    data,
    onSuccess,
    onCancel,
    modifyDataBeforeSend,
    customButtons,
    requestParam,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const exists = !!data?.id
    const [
        isGenericAccount,
        setIsGenericAccount,
    ] = useState(data?.isGenericAccount)

    const fields = useFieldsWithClassName(
        alLeasePricingFields(
            {
                originalStatus: data?.status,
                getAllowedValues,
                exists,
                isGenericAccount,
            },
        ),
        classes,
    )

    const setExternalValue = useCallback((update: AlLeasePricing) => {
        setIsGenericAccount(update.isGenericAccount)
    }, [])

    const removeAccountOnGenericAccount = useCallback((newData) => {
        if (newData.isGenericAccount) {
            return modifyDataBeforeSend({
                ...newData,
                account: undefined,
            })
        }
        return modifyDataBeforeSend({
            ...newData,
            excludedAccounts: undefined,
        })
    }, [modifyDataBeforeSend])

    return (
        <div
            className={classes.root}
        >
            <FormWithControls
                name={AlLeasePricingRequests.create.name}
                setExternalValue={setExternalValue}
                value={data}
                onSuccess={onSuccess}
                fields={fields}
                modifyDataBeforeSend={removeAccountOnGenericAccount}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                }}
                customButtons={customButtons}
                requestParams={requestParam}
                exists={exists}
                onCancel={onCancel}
            />
        </div>
    )
}

AlLeasePricingForm.defaultProps = defaultProps

export default AlLeasePricingForm
