import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    LeaseType,
} from 'app/types/enums'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    newQuotes,
} from '../Dashboard.types'

export default (): SkyNetTableConfig<keyof newQuotes> => {
    return {
        name: 'newQuotes',
        tabs: [{
            name: 'New Quotes',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'quoteNumber',
                    labelKey: 'AccountQuotes.quoteNumber',
                },
                {
                    name: 'quotedForCompanyLocationName',
                    labelKey: 'AccountQuotes.quotedForCompany',
                },
                {
                    name: 'accountName',
                    labelKey: 'AccountLane.accountCompanyName',
                    filterField: 'accountName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'leaseType',
                    labelKey: 'AccountLane.leaseType',
                    filterField: 'leaseType',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: LeaseType,
                },
                {
                    name: 'pricingResponsible',
                    labelKey: 'AccountQuotes.pricingResponsible',
                    componentName: 'List',
                },
                {
                    name: 'createdBy',
                    labelKey: 'KamDashboard.requestor',
                    filterField: 'createdBy',
                    filterType: FilterType.Array,
                },
                {
                    name: 'lanesToBePriced',
                    labelKey: 'PriceDashboard.lanesToBePriced',
                    sorting: false,
                },
                {
                    name: 'createdOn',
                    labelKey: 'PriceDashboard.submittedOn',
                    componentName: 'DateTimeCell',
                },
            ],
        }],
    }
}
