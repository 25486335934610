import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'

import {
    DTOCardFormField, FieldInCard, FormField, FormFields,
} from 'app/types/form.types'
import {
    Column,
} from 'app/types/simpletable.types'

const parseRequiredFieldNames = (fields, resAcc = []) => {
    let result = []

    if (isArray(fields)) {
        result = fields.reduce((acc, item) => {
            if (isArray(item)) {
                acc.push(...parseRequiredFieldNames(item))
            }
            if (isArray(item?.fields)) {
                acc.push(...parseRequiredFieldNames(item?.fields))
            }
            if ((item.name || item.fieldName) && item.required && !item.hidden && !item.disabled) {
                acc.push(item.name || item.fieldName)
            }
            return acc
        }, resAcc || [])
    }

    return result
}

export default ({
    fields, values,
}: {
    fields: DTOCardFormField<any> | FieldInCard<any> | FormFields | FormField<any> | Column<any>[]
    values: Record<string, any> | Record<string, any>[]
}): {
    isValid: boolean
} => {
    const requiredFields = parseRequiredFieldNames(fields)

    const data = isArray(values) ? values : [values]

    return {
        isValid: data.every((item) => {
            return requiredFields.every((el) => {
                if (isNumber(item[el])) {
                    return true
                }
                if (isArray(item[el])) {
                    return item[el].length > 0
                }
                return !isEmpty(item[el])
            })
        }),
    }
}
