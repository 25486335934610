import {
    Currency, FeeChargeability, ProductType, RangeType,
} from 'app/types/enums'

import {
    AdditionalFeesAllowed, LaneFeesAllowed,
} from 'app/shared-components/Fees'

import {
    AQuoteTable,
} from '../AQuotes/AQuotes.types'

export type CQuotesTable = AQuoteTable & {
    pricingAccountName: string
}

export type CQuote = {
    id: number;
    createdOn: string; // ISO date string
    changedOn: string; // ISO date string
    createdBy: string;
    changedBy: string;
    archived: boolean;
    quoteNumber: string;
    pricingAccount: {
        id: number;
    };
    currency: 'USD'; // Assuming currency is always USD, otherwise use string
    comment: string;
    quotedForCompany: {
        id: number;
    };
    quotedFor: {
        id: number;
    };
    skyCellContact: {
        id: number;
    };
    linkedContractBasis: {
        id: number;
    };
    requestedLanes: {id: number}[];
};

export enum TemperatureRangeCQuote {
    C = 'C',
    CRT = 'CRT',
}

export enum ProductTypeCQuote {
    VAL_1500XCOL = 'VAL_1500XCOL',
    VAL_1500XCRT = 'VAL_1500XCRT',
}

export enum LeaseTypePossibilityStatus {
    AIRLINE_LEASE_REQUIRED_ROUND_TRIP_REQUIRED = 'AIRLINE_LEASE_REQUIRED_ROUND_TRIP_REQUIRED',
    AIRLINE_LEASE_REQUIRED_ONE_WAY_POSSIBLE = 'AIRLINE_LEASE_REQUIRED_ONE_WAY_POSSIBLE',
    DIRECT_LEASE_POSSIBLE = 'DIRECT_LEASE_POSSIBLE',
}

export type RateCardsData = {
    leaseTypePossibilityStatus: LeaseTypePossibilityStatus,
    originPositioningFtl: boolean,
    originTruckCapacityAvailability: boolean,
    originTruckCapacity: number,
    originPositioningLtl: boolean,
    destinationCollectionLtl: boolean,
    deliveryBySkyCellAvailability: boolean,
    collectionBySkyCellAvailability: boolean,
    currencies: Currency[],
    logisticsDays: number,
    plannedPaymentTerms: number,
}

export enum QuotationLevel {
    REGION_SECOND_LEVEL = 'REGION_SECOND_LEVEL',
    COUNTRY = 'COUNTRY',
    IATA = 'IATA',
}

export type LaneFees = {
    feeName: LaneFeesAllowed,
    feeChargeability: FeeChargeability,
    productType: ProductType,
    description: string,
    feeValue: number
}

export type AdditionalFees = {
    feeName: AdditionalFeesAllowed,
    feeChargeability: FeeChargeability,
    productType: ProductType,
    description: string,
    simplifiedCQuoteDescription: string
    feeValue: number
}

export type FeesData = {
    laneFees: {
        validFrom: string,
        validTo: string,
        fees: LaneFees[],
        postponementFeeTimeline: number,
        postponementDaysType: RangeType,
        expressOrderTimeline: number,
        expressDaysType: RangeType,
        maxNumberOfContainersPerTruck: number
    },
    additionalFees: {
        validFrom: string,
        validTo: string,
        fees: AdditionalFees[],
        cancellationFeeTimeline: number,
        cancellationDaysType: RangeType
    }
}

export enum CQuotesTab {
    AwardReject = 'award-reject',
    QuotePdf = 'quote-pdf',
}

export type CQuotesPdf = {
    id: number,
    filename: string,
    fileId: string,
    createdBy: string,
    createdOn: string,
    description: string,
    attachmentUrl: string,
}
