import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requests from '../AQuotes.requests'
import {
    AQuote,
} from '../AQuotes.types'

const useGetAQuote = (id: number) => {
    return useRequest<AQuote>({
        key: RequestKeys.getAQuote,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requests.get),
        enabled: Boolean(id),
        keepPreviousData: true,
    })
}

export default useGetAQuote
