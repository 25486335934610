import {
    useEffect,
    useState, useMemo,
} from 'react'
import isEqual from 'lodash/isEqual'
import isFunction from 'lodash/isFunction'
import {
    Validate,
} from 'app/types/form.types'

export default function useValidateItem({
    validate,
    value,
    setFormError,
    key,
    returnOnFirstError = true,
}: {
    validate: Validate | Validate[],
    value: any,
    setFormError?: (e:[string, any[]]) => void,
    key?: string,
    returnOnFirstError?: boolean,
}) {
    const [
        errors,
        setErrors,
    ] = useState<string[]>()
    const [
        validated,
        setValidated,
    ] = useState<boolean>(false)

    useEffect(() => {
        let next
        let isValidated: boolean = false

        if (!validate) return
        const v = (isFunction(validate) ? [validate] : validate) as Validate[]

        for (let i = 0; i < v.length; i++) {
            const result = v[i](value)

            // sets if validations were run successfully (isValidated === true)
            isValidated = !isValidated && Boolean(result)

            if (result && Array.isArray(result)) {
                next = next?.length ? [
                    ...next,
                    ...result,
                ] : result
                if (returnOnFirstError) break
            }
        }

        if (validated !== isValidated) {
            setValidated(isValidated)
        }

        if (!isEqual(next, errors)) {
            if (setFormError) {
                setFormError([
                    key,
                    next,
                ])

                setErrors(next)
            }
        }
    }, [
        value,
        errors,
        setFormError,
        key,
        returnOnFirstError,
        validate,
        validated,
    ])

    return useMemo(() => {
        return {
            errors, validated,
        }
    }, [
        errors,
        validated,
    ])
}
