import React, {
    useEffect, useState, useCallback,
} from 'react'
import Popover from '@mui/material/Popover'

import {
    useSharedContext,
} from 'app/shared-components/SharedContext'
import StatusHandler from 'app/shared-components/StatusHandler'
import useIntersectionObserver from 'app/hooks/useIntersectionObserver'

import useGetAlertNotifications from '../hooks/useGetAlertNotifications'
import AlertCard from '../AlertCard'
import {
    AlertNotificationListItem,
} from '../alertNotifications.types'

import useStyles from './NotificationsList.styles'

const NotificationsList = ({
    onClose,
    anchorEl,
    open,
    anchorPosition,
    hasUnreadAlertsInvalidate,
}: Readonly<{
    onClose: (...args: any) => void,
    anchorEl: HTMLDivElement,
    open: boolean,
    anchorPosition: {
        top: number,
        left: number,
    },
    hasUnreadAlertsInvalidate: ()=>void
}>) => {
    const {
        data,
        fetchNext,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetAlertNotifications({
        enabled: open,
    })
    const height: number = useSharedContext() || 0
    const {
        cx, classes,
    } = useStyles({
        height,
    })

    const [
        lastElement,
        setLastElement,
    ] = useState(null)

    const [
        alertCards,
        setAlertCards,
    ] = useState<AlertNotificationListItem[]>([])

    useEffect(() => {
        setAlertCards((prev) => {
            if (data?.length) {
                return data.map((card) => {
                    return {
                        ...card,
                        ...prev.find((el) => { return el.id === card.id }),
                    }
                })
            }

            return [{
                message: 'No new alerts',
            }] as AlertNotificationListItem[]
        })
    }, [data])

    const onChangeStatus = useCallback((id) => {
        setAlertCards((prev) => {
            return prev.reduce((acc, card) => {
                return [
                    ...acc,
                    {
                        ...card,
                        isRead: card.id === id ? !card.isRead : card.isRead,
                    },
                ]
            }, [])
        })
        hasUnreadAlertsInvalidate()
    }, [
        setAlertCards,
        hasUnreadAlertsInvalidate,
    ])

    useIntersectionObserver({
        intersectionCallback: fetchNext, el: lastElement,
    })

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorReference="anchorPosition"
                anchorPosition={anchorPosition}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={onClose}
                classes={{
                    paper: classes.paper,
                }}
            >
                <div className={cx(classes.container)}>
                    {
                        alertCards.map(({
                            id,
                            createdOn,
                            message,
                            triggeredByUserUserName,
                            isRead,
                        }, i) => {
                            return (
                                <div
                                    key={`alert-${id}`}
                                    ref={i === (alertCards.length - 1) ? setLastElement : undefined}
                                >
                                    <AlertCard
                                        id={id}
                                        onChangeStatus={onChangeStatus}
                                        createdOn={createdOn}
                                        message={message}
                                        triggeredByUserUserName={triggeredByUserUserName}
                                        isRead={isRead}
                                    />
                                </div>
                            )
                        })
                    }
                    {isFetching ? (<div className={classes.loading}>Please, wait...</div>) : null}
                </div>
            </Popover>
        </StatusHandler>
    )
}

export default NotificationsList
