import React, {
    useCallback,
} from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import clsx from 'clsx'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import {
    PickupFeeType,
} from 'app/types/enums'
import LocationObjectSelector from 'app/shared-components/LocationSelector/LocationObjectSelector'
import Select from 'app/shared-components/Fees/FeeInputs/Select'
import InputBase from 'app/shared-components/InputBase'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    DeliveryCollectionFees,
} from 'app/Apps/Pricing/AlLogisticPricing/AlLogisticPricing.types'

import useStyles from '../Inputs.styles'

import DeliveryCollectionAllowedTemperatures from './DeliveryCollectionAllowedTemperatures'

type Props = {
    value?: DeliveryCollectionFees,
        onChange: (...args: any[]) => void,
        onDelete: (...args: any[]) => void,
        disabled?: boolean,
}
const defaultProps: Partial<Props> = {
    value: undefined,
    disabled: false,
}

const DeliveryCollectionFeesInputs = ({
    value,
    onChange,
    onDelete,
    disabled,
}: Props) => {
    const getEnumValues = useEnumValues()

    const {
        classes,
    } = useStyles()

    const handleChange = useCallback((newValue, {
        target: {
            name,
        },
    }) => {
        const update = {
            ...value,
            pickupFee: name === 'pickupFeeType' && newValue !== PickupFeeType.CHARGE ? undefined : value.pickupFee,
            [name]: newValue,
        }

        onChange(update)
    }, [
        value,
        onChange,
    ])

    const handleOnDelete = useCallback(() => {
        onDelete(value.id)
    }, [
        value,
        onDelete,
    ])
    const handleLocationChange = useCallback((change) => {
        handleChange(change, {
            target: {
                name: 'location',
            },
        })
    }, [handleChange])

    return (
        <TableRow>
            <TableCell
                classes={{
                    root: clsx(classes.locationCell, classes.tableCell),
                }}
            >
                <LocationObjectSelector
                    value={{
                        id: value?.location?.id,
                    }}
                    name="location"
                    onChange={handleLocationChange}
                    disabled={disabled}
                    className={classes.location}
                />
            </TableCell>
            <TableCell
                classes={{
                    root: clsx(classes.temperatureRange, classes.tableCell),

                }}
            >
                <Select
                    allowedValues={getEnumValues(DeliveryCollectionAllowedTemperatures)}
                    value={value?.temperatureRange}
                    name="temperatureRange"
                    onChange={handleChange}
                    disableUnderline
                    disableHelperText
                    disabled={disabled}
                    required
                />
            </TableCell>
            <TableCell
                classes={{
                    root: clsx(classes.inputs, classes.tableCell),
                }}
            >
                <InputBase
                    value={value?.deliveryFeeLTL}
                    name="deliveryFeeLTL"
                    onChange={handleChange}
                    disabled={disabled}
                    type="number"
                    autoComplete="off"
                    classes={{
                        root: classes.inputBase,
                    }}
                    inputProps={{
                        className: classes.input,
                    }}
                />
            </TableCell>
            <TableCell
                classes={{
                    root: clsx(classes.inputs, classes.tableCell),
                }}
            >
                <InputBase
                    value={value?.deliveryFeeFTL}
                    name="deliveryFeeFTL"
                    onChange={handleChange}
                    disabled={disabled}
                    type="number"
                    autoComplete="off"
                    classes={{
                        root: classes.inputBase,
                    }}
                    inputProps={{
                        className: classes.input,
                    }}
                />
            </TableCell>
            <TableCell
                classes={{
                    root: clsx(classes.inputs, classes.tableCell),
                }}
            >
                <Select
                    allowedValues={getEnumValues(PickupFeeType)}
                    value={value?.pickupFeeType}
                    name="pickupFeeType"
                    onChange={handleChange}
                    disableUnderline
                    disableHelperText
                    disabled={disabled}
                    required
                />
            </TableCell>
            <TableCell
                classes={{
                    root: clsx(classes.inputs, classes.tableCell),
                }}
            >
                <InputBase
                    value={value?.pickupFee}
                    name="pickupFee"
                    onChange={handleChange}
                    disabled={disabled || value?.pickupFeeType !== PickupFeeType.CHARGE}
                    type="number"
                    autoComplete="off"
                    classes={{
                        root: classes.inputBase,
                    }}
                    inputProps={{
                        className: value?.pickupFeeType !== PickupFeeType.CHARGE
                            ? classes.input : classes.required,
                    }}
                    required
                />
            </TableCell>
            <TableCell
                classes={{
                    root: clsx(classes.inputs, classes.tableCell),
                }}
            >
                <InputBase
                    value={value?.dropoffCollectionFee}
                    name="dropoffCollectionFee"
                    onChange={handleChange}
                    disabled={disabled}
                    type="number"
                    autoComplete="off"
                    classes={{
                        root: classes.inputBase,
                    }}
                    inputProps={{
                        className: classes.input,
                    }}
                />
            </TableCell>
            <TableCell
                classes={{
                    root: clsx(classes.inputs, classes.tableCell),
                }}
            >
                <InputBase
                    value={value?.expressDays}
                    name="expressDays"
                    onChange={handleChange}
                    disabled={disabled}
                    type="number"
                    autoComplete="off"
                    classes={{
                        root: classes.inputBase,
                    }}
                    inputProps={{
                        className: classes.required,
                    }}
                />
            </TableCell>
            <TableCell
                classes={{
                    root: clsx(classes.inputs, classes.tableCell),
                }}
            >
                <InputBase
                    value={value?.postponementDays}
                    name="postponementDays"
                    onChange={handleChange}
                    disabled={disabled}
                    type="number"
                    autoComplete="off"
                    classes={{
                        root: classes.inputBase,
                    }}
                    inputProps={{
                        className: classes.required,
                    }}
                />
            </TableCell>
            <TableCell
                classes={{
                    root: classes.tableCell,
                }}
            >
                {!disabled
                    && (
                        <DeleteIcon
                            className={classes.deleteIcon}
                            onClick={handleOnDelete}
                        >
                            Delete
                        </DeleteIcon>
                    )}
            </TableCell>
        </TableRow>
    )
}

DeliveryCollectionFeesInputs.defaultProps = defaultProps

export default DeliveryCollectionFeesInputs
