import {
    useCallback,
} from 'react'
import {
    useQueryClient,
} from '@tanstack/react-query'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import config from 'app/services/sharedBeConfig/ContainerTypes.config'

import {
    ContainerType,
} from '../../Containers/containers.types'

const useGetContainerTypes = (params: Record<string, any> = {}) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getContainerTypes],
        })
    }, [queryClient])

    const {
        data: containerTypes,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<ContainerType[]>({
        key: RequestKeys.getContainerTypes,
        requestFunc: RequestFn.getEntity(config.ContainerTypes.requestFn, (promise) => {
            return promise.then((resp) => {
                return resp?.data?.items
            })
        }),
        params,
        staleTime: Infinity,
        keepPreviousData: true,
    })

    return {
        containerTypes,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetContainerTypes
