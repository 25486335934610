import React, {
    useMemo,
} from 'react'

import Card from 'app/shared-components/Card'
import CollapsibleTable from 'app/shared-components/CollapsibleTable'
import useLabel from 'app/hooks/useLabel'
import useStyles from 'app/tss/collapsibleTable.styles'

import requests from '../AQuotes.requests'
import {
    AQuoteContext, AQuoteContextType,
} from '../AQuoteContext/useAQuoteContext'
import useGetAQuote from '../hooks/useGetAQuote'
import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'

import useGetLaneGroupsPricingAssignment from './hooks/useGetLaneGroupsPricingAssignment'
import getColumns from './AssignPricing.columns'
import AssignPricingLaneGroupUpdateWrapper from './AssignPricingLaneGroupUpdate'

const AssignPricing = ({
    id,
}: {
    id: number
}) => {
    const {
        AQuotes: AQuotesRoutes,
    } = useAccountManagementRoutes()
    const l = useLabel()
    const {
        classes,
    } = useStyles()
    const {
        data: quote,
    } = useGetAQuote(id)

    const requestHandlers = useGetLaneGroupsPricingAssignment(id)

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    const actionComponents = useMemo(() => {
        return {
            Update: AssignPricingLaneGroupUpdateWrapper,
        }
    }, [])

    const aQuoteContext = useMemo(() => {
        return {
            quoteId: id,
            ...(quote || {}),
        } as AQuoteContextType
    }, [
        id,
        quote,
    ])

    return (
        <AQuoteContext.Provider value={aQuoteContext}>
            <div
                data-testid="LaneGroupsAssignPricing"
                className={classes.root}
            >
                <Card
                    fullHeight
                    contentClass={classes.cardContent}
                    data-testid="LanesGroups-card"
                >
                    <CollapsibleTable
                        title="Lane Groups"
                        configName="AssignPricingLaneGroups"
                        domainName={requests.domainName}
                        columns={columns}
                        customUrl={null}
                        requestHandlers={requestHandlers}
                        actionComponents={actionComponents}
                        createEnabled={false}
                        tableControls={[]}
                        switchableFormTable
                        backToTableText="Back To Lane Groups"
                        routeParams={AQuotesRoutes.AssignPricing.params.map(({
                            name,
                        }) => {
                            return name
                        })}
                    />
                </Card>
            </div>
        </AQuoteContext.Provider>
    )
}

export default AssignPricing
