import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'
import get from 'lodash/get'
import noop from 'lodash/noop'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import {
    TableResponse,
} from 'app/types/request.types'

import {
    useStoreTableConfig,
} from 'app/store/Configs'
import {
    useSkyNetDomainContext,
} from 'app/shared-components/SkyNetDomain'
import SkyNetDomainHeader from 'app/shared-components/SkyNetDomain/SkyNetDomainHeader'
import {
    SkyNetTableTab, SkyNetTableTabWithLabel, SkyNetTableConfig,
} from './types/skyNetTable.types'
import usePrepareColumns from './hooks/usePrepareColumns'
import useLoadWithParams from './hooks/useLoadWithParams'
import useSkyNetTableControls from './hooks/useSkyNetTableControls'
import SkyNetTable from './SkyNetTable'

type Props = {
    className?: string,
    customUrl?: string,
    excludeFilters?: Record<string, any>,
    filter?: Record<string, any>,
    method?: string,
    moduleName?: string,
    name?: string,
    onLoad?: (data: TableResponse)=> void,
    onRowClick?: (...args: any[])=>void,
    onSelectRow?: (rowIds: string|number[])=> void,
    rowsPerPageDefault?: number,
    selectedRows?: number[],
    simple?: boolean,
    tableConfig: SkyNetTableConfig,
    tableControls?: TableControlTypes[],
    isShowCounterTab?: boolean,
    showSearch?: boolean,
    paginationModeMatches?: boolean,
    showSelectAll?: boolean,
    disabled?: boolean,
    uniqField?: string,
    parseResponse?: (data: TableResponse) => TableResponse
}

const defaultProps: Partial<Props> = {
    className: null,
    customUrl: undefined,
    excludeFilters: {},
    filter: {},
    isShowCounterTab: false,
    method: undefined,
    moduleName: undefined,
    onLoad: noop,
    onRowClick: undefined,
    onSelectRow: undefined,
    rowsPerPageDefault: undefined,
    simple: false,
    selectedRows: undefined,
    showSearch: false,
    tableControls: [],
    paginationModeMatches: undefined,
    showSelectAll: false,
    disabled: false,
    parseResponse: undefined,
    name: undefined,
    uniqField: undefined,
}

const SkyNetTableContainer = ({
    className,
    customUrl,
    excludeFilters,
    filter,
    method,
    moduleName,
    onLoad,
    onRowClick,
    onSelectRow,
    rowsPerPageDefault,
    selectedRows,
    simple,
    tableConfig,
    tableControls,
    isShowCounterTab,
    showSearch,
    paginationModeMatches,
    showSelectAll,
    disabled,
    uniqField,
    name: tableName,
    parseResponse,
}: Props) => {
    const {
        getTableRef,
        name: domainName,
        uniqField: domainUniqField,
    } = useSkyNetDomainContext()

    const name = tableName || domainName

    useStoreTableConfig(tableConfig)

    const tableTabs = useMemo((): SkyNetTableTabWithLabel[] => {
        const tabs: SkyNetTableTab[] = get(tableConfig, 'tabs', [])

        return tabs.map((tab) => {
            return {
                label: tab.name,
                ...tab,
            }
        })
    }, [tableConfig])

    const skyNetTableControls = useSkyNetTableControls({
        showSearch, tableControls, tabs: tableTabs,
    })

    const [
        counter,
        setCounter,
    ] = useState<number>()

    const onLoadData = useCallback((data: TableResponse) => {
        if (isShowCounterTab) {
            setCounter(data?.meta?.matchedresults)
        }

        onLoad(data)
    }, [
        isShowCounterTab,
        onLoad,
    ])

    const [
        searchValue,
        setSearchValue,
    ] = useState<string>()

    const {
        loadWithParams,
        appliedFilter,
        selectedTabUrlQuery,
        selectedTabCustomUrl,
    } = useLoadWithParams({
        customUrl,
        filter,
        method,
        name,
        moduleName,
        onLoadData,
        rowsPerPageDefault,
        tabs: tableTabs,
        searchValue,
        paginationModeMatches,
        parseResponse,
    })

    const columns = usePrepareColumns({
        customUrl,
        name,
        moduleName,
        tableConfig,
        selectedTabUrlQuery,
        tableTabs,
        selectedTabCustomUrl,
    })

    const visibleByDefaultColumn = columns.reduce((acc, {
        isHidden, id,
    }) => {
        if (!isHidden) {
            return [
                ...acc,
                id,
            ]
        }
        return acc
    }, [])

    const renderControlPanel = useCallback((controlPanelProps) => {
        return (
            <SkyNetDomainHeader
                reducedMode={false}
                simple={simple}
                tableControls={skyNetTableControls}
                tabs={tableTabs}
                counter={counter}
                applySearch={setSearchValue}
                controlPanelProps={controlPanelProps}
            />
        )
    }, [
        counter,
        simple,
        skyNetTableControls,
        tableTabs,
    ])

    return (
        <SkyNetTable
            className={className}
            columns={columns}
            excludeFilters={excludeFilters}
            filter={appliedFilter}
            load={loadWithParams}
            name={moduleName || name}
            onRowClick={onRowClick}
            onSelectRow={onSelectRow}
            richtableRef={getTableRef}
            selectedRows={selectedRows}
            simple={simple}
            tableControls={skyNetTableControls}
            uniqField={uniqField || domainUniqField}
            counter={counter}
            configName={tableConfig.name}
            visibleByDefaultColumn={visibleByDefaultColumn}
            showSelectAll={showSelectAll}
            disabled={disabled}
            renderControlPanel={renderControlPanel}
        />
    )
}

SkyNetTableContainer.defaultProps = defaultProps

export default SkyNetTableContainer
