import React, {
    useMemo,
} from 'react'

import {
    useUserRoles,
} from 'app/Auth'
import useSelectedTab from 'app/MainMenu/Panel/ActiveTab/hooks/useSelectedTab'

import useMainMenuConfig from '../hooks/useMainMenuConfig'

import Menu from './Menu'

const MenuContainer = () => {
    const activeTab = useSelectedTab()
    const mainMenuConfig = useMainMenuConfig()

    const roles = useUserRoles()

    const allowedItems = useMemo(() => {
        return mainMenuConfig
            .reduce((acum, item) => {
                return [
                    ...acum,
                    {
                        ...item,
                        links: item.links.filter(({
                            auth,
                        }) => {
                            if (!auth) {
                                return true
                            }

                            return auth.some((role) => {
                                return roles.includes(role)
                            })
                        }),
                    },
                ]
            }, [])
            .filter(({
                links,
            }) => {
                return links.length > 0
            })
    }, [
        roles,
        mainMenuConfig,
    ])

    return (
        <Menu
            items={allowedItems}
            selectedId={('id' in activeTab) ? activeTab.id : undefined}
        />
    )
}

export default MenuContainer
