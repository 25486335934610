import {
    RequestConfig,
} from 'app/types/request.types'

type SimplifiedCQuotesRequests = {
    generateSimplifiedCQuote: (params) => RequestConfig,
    getFeesData: (params) => RequestConfig,
}

const request: SimplifiedCQuotesRequests = {
    generateSimplifiedCQuote: (params) => {
        return {
            url: 'simplifiedquote/generate',
            data: {
                ...params,
            },
            method: 'POST',
        }
    },
    getFeesData: (params) => {
        return {
            url: 'simplifiedquote/fees-data',
            data: {
                ...params,
            },
            method: 'POST',
        }
    },
}

export default request
