import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    Currency,
    FeeChargeability, ProductType,
} from 'app/types/enums'
import {
    AdditionalFeesAllowed,
} from 'app/shared-components/Fees'

import {
    AdditionalFees,
} from '../../CQuotes.types'

const getColumns = (
    l: (l:string) => string, currency: Currency,
): RichTableColumns<keyof AdditionalFees>[] => {
    return [
        {
            id: 'feeName',
            headerProps: {
                children: l('Fee.feeName'),
                sorting: false,
            },
            mapCellProps: 'feeName',
            componentName: 'EnumValue',
            allowedValuesEnum: AdditionalFeesAllowed,
        },
        {
            id: 'feeChargeability',
            headerProps: {
                children: l('Fee.feeChargeability'),
                sorting: false,
            },
            mapCellProps: 'feeChargeability',
            componentName: 'EnumValue',
            allowedValuesEnum: FeeChargeability,
        },
        {
            id: 'productType',
            headerProps: {
                children: l('Fee.productType'),
                sorting: false,
            },
            mapCellProps: 'productType',
            componentName: 'EnumValue',
            allowedValuesEnum: ProductType,
        },
        {
            id: 'simplifiedCQuoteDescription',
            headerProps: {
                children: l('Fee.feeDescription'),
                sorting: false,
            },
            mapCellProps: 'simplifiedCQuoteDescription',
        },
        {
            id: 'feeValue',
            headerProps: {
                children: l(`Fee.${currency}`),
                sorting: false,
            },
            mapCellProps: 'feeValue',
        },
    ]
}

export default getColumns
