import React, {
    useCallback,
    useMemo,
} from 'react'
import Typography from '@mui/material/Typography'

import Card from 'app/shared-components/Card'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import TextSingleline from 'app/shared-components/TextSingleline'
import Label from 'app/shared-components/Label'
import useCheckTransportPlanerRoles from 'app/Apps/TransportPlanner/hooks/useCheckTransportPlanerRoles'

import {
    AnalyseTemperatureType,
    SimulationStep, SimulationTempResult,
} from '../../simulation.types'

import getTemperatureRange from './utils/getTemperatureRange'
import rangeToString from './utils/rangeToString'
import fields from './summary.fields'
import useStyles from './Summary.style'

type Props = {
    steps: SimulationStep[],
    simulationResults?: SimulationTempResult[],
    summaryTimeSteps: {
        h?: number,
        m?: number,
    },
    onChange: (newVal: AnalyseTemperatureType) => void,
    value?: AnalyseTemperatureType,
}

const defaultProps = {
    simulationResults: [],
    value: {},
}

const Summary = ({
    steps,
    simulationResults,
    summaryTimeSteps,
    onChange,
    value,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const excursionTime: string | null = useMemo(() => {
        const time = simulationResults.find(({
            isExcursion,
        }) => { return isExcursion })?.time

        if (!time) {
            return null
        }

        if (summaryTimeSteps.h > 500) {
            return `${time}h`
        }

        const timeInMin = time * 5

        return `${Math.floor(timeInMin / 60)}h ${timeInMin % 60}min`
    }, [
        simulationResults,
        summaryTimeSteps.h,
    ])

    const onChangeInput = useCallback((_, {
        target: {
            value: newValue,
            name,
        },
    }): void => {
        onChange({
            ...value,
            [name]: newValue,
        })
    }, [
        onChange,
        value,
    ])

    const resultDuration: string = `${summaryTimeSteps.h}h ${summaryTimeSteps.m}min`

    const ambientTemperatureList: number[] = steps.map(({
        ambientTemperature,
    }) => { return ambientTemperature })

    const internalTemperatureList = simulationResults.map(({
        simulatedTemperature,
    }) => { return simulatedTemperature })

    const {
        isRolePlannedUpdate,
    } = useCheckTransportPlanerRoles()

    const ambientTemperatureRangeString: string = rangeToString(
        getTemperatureRange(ambientTemperatureList),
    )
    const internalTemperatureRangeString: string = rangeToString(
        getTemperatureRange(internalTemperatureList),
    )

    return (
        <Card>
            <Typography variant="h3">
                Summary
            </Typography>
            <div className={classes.containerRow}>
                <TextSingleline
                    title="Duration"
                    name="total-duration"
                    value={resultDuration}
                />
                <div className={classes.excursion}>
                    <Label title="Temperature Excursion" />
                    <Typography data-testid="excursion">
                        {
                            excursionTime
                                ? (
                                    <span className={classes.excursionStatus}>
                                        <img
                                            className={classes.icon}
                                            src="/assets/images/icons/failed.svg"
                                            alt="Yes"
                                        />
                                        <span>
                                            {`After ${excursionTime}`}
                                        </span>
                                    </span>
                                )
                                : (
                                    <span className={classes.excursionStatus}>
                                        <img
                                            className={classes.icon}
                                            src="/assets/images/icons/checked.svg"
                                            alt="None"
                                        />
                                        <span>None</span>
                                    </span>
                                )
                        }
                    </Typography>
                </div>
            </div>
            <TextSingleline
                title="Ambient Temperature Range"
                value={ambientTemperatureRangeString}
            />
            <TextSingleline
                title="Internal Temperature Range"
                value={internalTemperatureRangeString}
            />
            {isRolePlannedUpdate && (
                <DomainObjectForm
                    name="TransportSimulation"
                    value={value}
                    onChange={onChangeInput}
                    fields={fields}
                />
            )}
        </Card>
    )
}

Summary.defaultProps = defaultProps

export default Summary
