import queryString from 'query-string'

import {
    RequestConfig,
} from 'app/types/request.types'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    AllowedProductTypes,
} from './serviceCenters.types'

type Request = {
    domainName: string,
    GetServiceCenterById: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    GetServiceCenterByLocation: ({
        locationId,
    }: {
        locationId: number,
    }) => RequestConfig,
    UpdateServiceCenter: ({
        serviceCenterId,
    }) => RequestConfig,
    CreateServiceCenter: () => RequestConfig,
    GetAvailability: {
        requestFn: ({
            params,
        }: {
            params: {
                serviceCenter: {id: number},
                productType: AllowedProductTypes,
            }}) => RequestConfig,
    },
    GetDryStorage: {
        requestFn: ({
            params,
        }: {
            params: {
                serviceCenter: {id: number},
            }}) => RequestConfig,
    },
    GetTruckCapacity: {
        requestFn: ({
            params,
        }: {
            params: {
                serviceCenter: {id: number},
            }}) => RequestConfig,
    },
    Archive: ({
        id: number,
    }) => RequestConfig,
    Restore: ({
        id: number,
    }) => RequestConfig,
    [RequestKeys.scenarioEditorInitialValues]: (params?: Record<string, any>) => RequestConfig
    [RequestKeys.scenarioEditorContainerAvailability]:
        (params?: Record<string, any>) => RequestConfig
}

const request: Request = {
    domainName: 'ServiceCenter',
    GetServiceCenterById: ({
        id,
    }) => {
        return {
            url: `servicecenter/${id}`,
        }
    },
    GetServiceCenterByLocation: ({
        locationId,
    }) => {
        return {
            url: queryString.stringifyUrl({
                url: 'servicecenter',
                query: {
                    locationId,
                },
            }),
        }
    },
    UpdateServiceCenter: ({
        serviceCenterId,
    }) => {
        return {
            url: `servicecenter/${serviceCenterId}`,
            method: 'PUT',
        }
    },
    CreateServiceCenter: () => {
        return {
            url: 'servicecenter',
            method: 'POST',
        }
    },
    GetAvailability: {
        requestFn: ({
            params,
        }) => {
            return {
                url: 'servicecenter/monitoring/container-availability',
                method: 'POST',
                data: params,
            }
        },
    },
    GetDryStorage: {
        requestFn: ({
            params,
        }) => {
            return {
                url: 'servicecenter/monitoring/dry-storage',
                method: 'POST',
                data: params,
            }
        },
    },
    GetTruckCapacity: {
        requestFn: ({
            params,
        }) => {
            return {
                url: 'servicecenter/monitoring/truck-capacity',
                method: 'POST',
                data: params,
            }
        },
    },
    Archive: ({
        id,
    }) => {
        return {
            url: `servicecenter/${id}/archive`,
            method: 'PATCH',
        }
    },
    Restore: ({
        id,
    }) => {
        return {
            url: `servicecenter/${id}/restore`,
            method: 'PATCH',
        }
    },
    [RequestKeys.scenarioEditorInitialValues]: (params = {}) => {
        return {
            url: 'servicecenter/monitoring/container-availability/scenario-editor/initial-values',
            method: 'POST',
            ...params,
        }
    },
    [RequestKeys.scenarioEditorContainerAvailability]: (params) => {
        return {
            url: 'servicecenter/monitoring/container-availability/scenario-editor',
            method: 'POST',
            data: params,
        }
    },

}

export default request
