import get from 'lodash/get'

import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    LanesTableRowUniq,
} from 'app/Apps/Pricing/ContractBasis/utils/createQuote.types'
import List from 'app/Apps/DomainObject/ComponentMapper/List'
import isoStrToLocalStr from 'app/utils/date/isoStrToLocalStr'

const ABSENCE_SYMBOL = '-'

export const additionalFeesColumns: RichTableColumns[] = [
    {
        id: 'title',
        headerProps: {
            children: 'Fee',
        },
        mapCellProps: 'title',
        width: '20%',
    },
    {
        id: 'price',
        headerProps: {
            children: 'Amount',
        },
        mapCellProps: 'price',
        width: '10%',
    },
    {
        id: 'description',
        headerProps: {
            children: 'Description',
        },
        mapCellProps: 'description',
    },
]

const laneFeesArrayStringConverter = (arrayName: string) => {
    return ({
        laneFees,
    }: LanesTableRowUniq) => {
        return {
            children: get(laneFees, arrayName, []).join('\n'),
        }
    }
}

export const laneFeesColumns: RichTableColumns[] = [
    {
        id: 'laneNumber',
        headerProps: {
            children: 'Lane/Fee',
        },
        mapCellProps: ({
            laneNumber,
            laneFees,
        }: LanesTableRowUniq) => {
            return {
                children: `${laneNumber}\n${laneFees.laneFeesNumber}`,
            }
        },
    },
    {
        id: 'validFrom',
        headerProps: {
            children: 'Validity',
        },
        mapCellProps: ({
            laneFees,
        }: LanesTableRowUniq) => {
            return {
                children: laneFees.validTo ? `${isoStrToLocalStr(laneFees.validFrom)}\n${isoStrToLocalStr(laneFees.validTo)}` : isoStrToLocalStr(laneFees.validFrom),
            }
        },
    },
    {
        id: 'originAirportIataCode',
        headerProps: {
            children: 'From',
        },
        mapCellProps: ({
            originAirportIataCode,
        }) => {
            return {
                value: [originAirportIataCode],
            }
        },
        Cell: List,
    },
    {
        id: 'destAirportIataCode',
        headerProps: {
            children: 'To',
        },
        mapCellProps: ({
            destAirportIataCode,
        }) => {
            return {
                value: [destAirportIataCode],
            }
        },
        Cell: List,
    },
    {
        id: 'expPalletsYear',
        headerProps: {
            children: 'Pallets',
        },
        mapCellProps: 'expPalletsYear',
    },
    {
        id: 'laneFees.baseLeaseDays',
        headerProps: {
            children: 'Base Lease Days',
        },
        mapCellProps: 'laneFees.baseLeaseDays',
    },
    {
        id: 'laneFees.containerProductTypes',
        headerProps: {
            children: 'Container Type',
        },
        mapCellProps: laneFeesArrayStringConverter('containerProductTypes'),
    },
    {
        id: 'skyCellDelivers',
        headerProps: {
            children: 'SkyCell Delivers',
        },
        mapCellProps: 'skyCellDelivers',
    },
    {
        id: 'skyCellCollects',
        headerProps: {
            children: 'SkyCell Collects',
        },
        mapCellProps: 'skyCellCollects',
    },
    {
        id: 'laneFees.handoverDeliveryLtl',
        headerProps: {
            children: 'Handover Delivery LTL',
        },
        mapCellProps: laneFeesArrayStringConverter('handoverDeliveryLtl'),
    },
    {
        id: 'laneFees.baseLease',
        headerProps: {
            children: 'Base Lease',
        },
        mapCellProps: laneFeesArrayStringConverter('baseLease'),
    },
    {
        id: 'laneFees.collectionDropOff',
        headerProps: {
            children: 'Collection Drop-off',
        },
        mapCellProps: laneFeesArrayStringConverter('collectionDropOff'),
    },
    {
        id: 'laneFees.cargoStraps',
        headerProps: {
            children: 'Cargo Straps',
        },
        mapCellProps: laneFeesArrayStringConverter('cargoStraps'),
    },
    {
        id: 'laneFees.damageWaiver',
        headerProps: {
            children: 'Damage Waiver',
        },

        mapCellProps: laneFeesArrayStringConverter('damageWaiver'),
    },
    {
        id: 'laneFees.deliveryFtlVan',
        headerProps: {
            children: 'Delivery(FTL) Van/Truck',
        },
        mapCellProps: ({
            laneFees,
        }: LanesTableRowUniq) => {
            return {
                children: `${laneFees.deliveryFtlVan || ABSENCE_SYMBOL}/${laneFees.deliveryFtlTruck || ABSENCE_SYMBOL}`,
            }
        },
    },
]
