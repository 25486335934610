import React, {
    useCallback,
    useMemo,
} from 'react'
import clsx from 'clsx'

import useLabel from 'app/hooks/useLabel'
import InputSingleline from 'app/shared-components/InputSingleline'
import {
    defaultTaxCodes,
    defaultEmptyTaxCodes,
} from 'app/config'

import useStyles from './TaxCodesSelector.styles'

const defaultProps = {
    disabled: false,
    className: '',
    isNew: false,
}

type Props = {
    onChange: (val: Record<string, any>[]) => void,
    value: Record<string, any>[],
    className?: string,
    disabled?: boolean,
    isNew?: boolean,
}

const TaxCodesSelector = (props: Props) => {
    const {
        onChange,
        value,
        className,
        disabled,
        isNew,
    } = props
    const l = useLabel()

    const {
        classes,
    } = useStyles()

    const codes = useMemo(() => {
        if (!value || value.length === 0) {
            return isNew ? defaultTaxCodes : defaultEmptyTaxCodes
        }
        return value
    }, [
        value,
        isNew,
    ])

    const handleTaxCodeChange = useCallback((name: string) => {
        return (taxCode: string) => {
            const taxCodes = codes.map((item) => {
                if (item.currency === name) {
                    return {
                        ...item,
                        taxCode,
                    }
                }

                return item
            })

            onChange(taxCodes)
        }
    }, [
        onChange,
        codes,
    ])

    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.columnLeft}>
                {codes.map((taxCode, index) => {
                    return (
                        index <= 5 && (
                            <InputSingleline
                                key={taxCode.currency}
                                disabled={disabled}
                                title={`${l('LocationDefaultTaxCode.taxCode')} ${taxCode.currency}`}
                                value={taxCode.taxCode}
                                onChange={handleTaxCodeChange(taxCode.currency)}
                                required
                            />
                        )
                    )
                })}
            </div>
            <div className={classes.columnRight}>
                {codes.map((taxCode, index) => {
                    return (
                        index > 5 && (
                            <InputSingleline
                                key={taxCode.currency}
                                disabled={disabled}
                                title={`${l('LocationDefaultTaxCode.taxCode')} ${taxCode.currency}`}
                                value={taxCode.taxCode}
                                onChange={handleTaxCodeChange(taxCode.currency)}
                                required
                            />
                        )
                    )
                })}
            </div>
        </div>
    )
}

TaxCodesSelector.defaultProps = defaultProps

export default TaxCodesSelector
