export enum EmbargoType {
    EMBARGO = 'EMBARGO',
    CLOSELY_MONITORED_BY_USA = 'CLOSELY_MONITORED_BY_USA'
}

export type EmbargoContriesTable = {
    id: number,
    countryCountryName: string,
    embargoType: EmbargoType,
}
