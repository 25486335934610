import React, {
    useEffect, useMemo,
} from 'react'
import omit from 'lodash/omit'

import Button from 'app/shared-components/Button'
import {
    OrderStep, OrderTypes,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import Tooltip from 'app/shared-components/Tooltip'
import useOnSuccessCreateOrder from 'app/Apps/OrderManagement/Orders/hooks/useOnSuccessCreateOrder'
import {
    FreightTypes,
    StepTypeDetailed,
} from 'app/types/enums'

import {
    AutomaticallyFormType,
} from '../../createAutomaticallyForm.types'
import useCreateInternalOrder from '../../../hooks/useCreateInternalOrder'

import OrderStepOption from './OrderStepOption'
import useStyles from './EditAvailableOptions.style'

const FREIGHT_RATE_MAP = {
    [StepTypeDetailed.SEA_ROAD_INTERNAL]: 'exportForwarder',
    [StepTypeDetailed.AIR_ROAD_INTERNAL]: 'exportForwarder',
    [StepTypeDetailed.ROAD_INTERNAL_DELIVERY]: 'importForwarder',
    [StepTypeDetailed.ROAD_INTERNAL]: 'forwarder',
}

const EditAvailableOptions = ({
    value,
    onChange,
    activeStep,
    setActiveStep,
    index,
    orderSteps,
}: {
    value: AutomaticallyFormType,
    onChange: (newVal: AutomaticallyFormType) => void,
    activeStep: number,
    setActiveStep: (val: number) => void,
    index: number,
    orderSteps: OrderStep[],
}) => {
    const {
        classes,
    } = useStyles()

    useEffect(() => {
        onChange({
            ...value,
            orderSteps,
        })
    }, []) // eslint-disable-line

    const onSuccessCreate = useOnSuccessCreateOrder(OrderTypes.INTERNAL_TRANSPORT)

    const {
        isPending: isLoading,
        mutate,
    } = useCreateInternalOrder(onSuccessCreate)

    const onChangeValue = (updatedStep: OrderStep) => {
        const newVal = value?.orderSteps?.map((step) => {
            if (updatedStep.stepTypeDetailed === step.stepTypeDetailed) {
                return updatedStep
            }

            return step
        })

        onChange({
            ...value,
            orderSteps: newVal,
        })
    }

    const isFormValid = useMemo(() => {
        return value?.orderSteps?.every((step) => {
            return step.forwarder
        })
    }, [value?.orderSteps])

    const onClickBack = () => {
        setActiveStep(index - 1)
        onChange({
            ...value,
            orderSteps: null,
        })
    }

    const onCreateOrder = () => {
        const {
            requiredContainers,
            ...rest
        } = omit(value, 'bestOption')

        mutate({
            ...rest,
            requiredContainersInternal: requiredContainers,
            freightType: FreightTypes.CONTAINERS,
            orderType: OrderTypes.INTERNAL_TRANSPORT,
        })
    }

    return (
        <div>
            {value?.orderSteps?.map((step) => {
                return (
                    <OrderStepOption
                        key={`${step.stepTypeDetailed}`}
                        value={step}
                        onChange={onChangeValue}
                        freightRateServiceProviderCode={
                            value.bestOption[FREIGHT_RATE_MAP[step.stepTypeDetailed]]
                        }
                        zip={value?.bestOption?.destinationZipCode}
                        countryName={value?.bestOption?.destinationCountry}
                    />
                )
            })}
            <div className={classes.actions}>
                {activeStep === index && (
                    <>
                        <Tooltip
                            title="Please fill in all mandatory fields to continue"
                            disabled={isFormValid}
                        >
                            <div>
                                <Button
                                    label="Create Order"
                                    onClick={onCreateOrder}
                                    className={classes.button}
                                    data-testid="btn-create"
                                    disabled={!isFormValid}
                                    saving={isLoading}
                                />
                            </div>
                        </Tooltip>
                        <Button
                            label="Back"
                            secondary
                            onClick={onClickBack}
                            className={classes.button}
                            data-testid="btn-back"
                        />
                    </>
                )}
            </div>
        </div>
    )
}

export default EditAvailableOptions
