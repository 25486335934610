import {
    SkyNetTableTab,
} from 'app/shared-components/SkyNetTable/types/skyNetTable.types'
import {
    BaseLeaseCategory, RangeType,
    RegionSecondLevel,
} from 'app/types/enums'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    RateCardsTabs,
} from '../RateCards.types'

import requests from './Margins.request'
import {
    MarginsTable,
} from './Margins.types'

export default function getMarginsConfig(): SkyNetTableTab<keyof MarginsTable> {
    return {
        name: 'Margins',
        url: RateCardsTabs.Margins,
        customUrl: requests[RequestKeys.marginsFilterRequest]().url,
        fields: [
            {
                name: 'regionSecondLevel',
                labelKey: 'MarginsRateCard.regionSecondLevel',
                componentName: 'EnumValue',
                allowedValuesEnum: RegionSecondLevel,
            },
            {
                name: 'baseLeaseCategory',
                labelKey: 'MarginsRateCard.baseLeaseCategory',
                componentName: 'EnumValue',
                allowedValuesEnum: BaseLeaseCategory,
            },
            {
                name: 'logisticsDays',
                labelKey: 'MarginsRateCard.logisticsDays',
            },
            {
                name: 'dropOffMargin',
                labelKey: 'MarginsRateCard.dropOffMargin',
            },
            {
                name: 'positioningMargin',
                labelKey: 'MarginsRateCard.positioningMargin',
            },
            {
                name: 'postponementFeeTimeline',
                labelKey: 'MarginsRateCard.postponementFeeTimeline',
            },
            {
                name: 'postponementDaysType',
                labelKey: 'MarginsRateCard.postponementDaysType',
                componentName: 'EnumValue',
                allowedValuesEnum: RangeType,
            },
            {
                name: 'expressOrderTimeline',
                labelKey: 'MarginsRateCard.expressOrderTimeline',
            },
            {
                name: 'expressDaysType',
                labelKey: 'MarginsRateCard.expressDaysType',
                componentName: 'EnumValue',
                allowedValuesEnum: RangeType,
            },
            {
                name: 'cancellationFeeTimeline',
                labelKey: 'MarginsRateCard.cancellationFeeTimeline',
            },
            {
                name: 'cancellationDaysType',
                labelKey: 'MarginsRateCard.cancellationDaysType',
                componentName: 'EnumValue',
                allowedValuesEnum: RangeType,
            },
        ],
    }
}
