import React, {
    useMemo,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import {
    useTheme,
} from '@mui/material/styles'
import SkyNetSpreadSheet, {
    useSkyNetSpreadSheetStyles,
} from 'app/shared-components/SkyNetSpreadSheet'
import DataContentWrapper from 'app/shared-components/DataContentWrapper'
import {
    ScenarioByProductTypeRow,
} from '../ForecastsByProduct/ForecastsByProduct.utils'
import getConfig from './ScenarioByProductTypeSpreadsheet.config'

export default function ScenarioByProductTypeSpreadsheet({
    data,
    columns,
    onChange,
}: Readonly<{
    data: ScenarioByProductTypeRow[];
    columns: Set<string>;
    onChange: (a: ScenarioByProductTypeRow[]) => void;
}>) {
    const theme = useTheme()
    const {
        styles,
    } = useSkyNetSpreadSheetStyles()

    const config = useMemo(() => {
        return getConfig({
            columns,
            theme,
            styles,
        })
    }, [
        columns,
        theme,
        styles,
    ])

    return (
        <DataContentWrapper isEmpty={isEmpty(data)}>
            <SkyNetSpreadSheet
                value={data}
                config={config}
                onChange={onChange}
            />
        </DataContentWrapper>
    )
}
