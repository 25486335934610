import React, {
    useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import {
    AutomaticallyFormType,
} from '../../createAutomaticallyForm.types'

import useGetOrderSteps from './hooks/useGetOrderSteps'
import EditAvailableOptions from './EditAvailableOptions'

const EditAvailableOptionsContainer = ({
    value,
    onChange,
    activeStep,
    setActiveStep,
    index,
}: {
    value: AutomaticallyFormType,
    onChange: (newVal: AutomaticallyFormType) => void,
    activeStep: number,
    setActiveStep: (val: number) => void,
    index: number,
}) => {
    const {
        bestOption,
        originLocation,
        destinationLocation,
        requiredContainers,
        transportModes,
        temperatureRange,
        requestedPickup,
        incoTerm,
        maxLeadTime,
    } = value

    const paramsRequest = useMemo(() => {
        return {
            bestOption,
            originLocation,
            destinationLocation,
            requiredContainers,
            transportModes,
            temperatureRange,
            requestedPickup,
            incoTerm,
            maxLeadTime,
        }
    }, [
        bestOption,
        destinationLocation,
        incoTerm,
        maxLeadTime,
        originLocation,
        requestedPickup,
        requiredContainers,
        temperatureRange,
        transportModes,
    ])

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetOrderSteps(paramsRequest)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <EditAvailableOptions
                value={value}
                onChange={onChange}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                index={index}
                orderSteps={data}
            />
        </StatusHandler>
    )
}

export default EditAvailableOptionsContainer
