import React, {
    useMemo,
} from 'react'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useHasPermission from 'app/hooks/useHasPermission'
import useTabsByPermission from 'app/hooks/useTabsByPermission/useTabsByPermission'

import useDamageTrackingRoutes from '../hooks/useDamageTrackingRoutes'

import getTableConfig from './DamageTypes.config'
import DamageTypeCreate from './DamageTypeCreate'
import DamageTypeUpdate from './DamageTypeUpdate'
import DamageTypeOverview from './DamageTypeOverview'
import DamageTypeChangeHistory from './DamageTypeChangeHistory'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const DamageTypes = () => {
    const {
        DamageTypes: DamageTypesRoutes,
    } = useDamageTrackingRoutes()

    const readPermission = useHasPermission(['damage_types_read'])
    const updatePermission = useHasPermission(['damage_types_update'])
    const createPermission = useHasPermission(['damage_types_create'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: DamageTypesRoutes.Overview.route,
                Component: DamageTypeOverview,
            },
            permission: readPermission,
        },
        {
            tab: {
                label: 'Data Update',
                url: DamageTypesRoutes.Update.route,
                Component: DamageTypeUpdate,
            },
            permission: updatePermission,
        },
        {
            tab: {
                label: 'Change History',
                url: DamageTypesRoutes.ChangeHistory.route,
                Component: DamageTypeChangeHistory,
            },
            permission: readPermission,
        },
    ])

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            name={tableConfig.name}
            defaultTab={DamageTypesRoutes.Overview.route}
            actionComponents={{
                Create: createPermission
                    ? DamageTypeCreate
                    : undefined,
            }}
            sidebarTabs={tabs}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>
    )
}

export default DamageTypes
