import React, {
    useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useHasPermission from 'app/hooks/useHasPermission'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'

import getTableConfig from './serviceCenters.config'
import ServiceCenterCreate from './ServiceCenterCreate'
import ServiceCenterOverview from './ServiceCenterOverview'
import ServiceCenterUpdate from './ServiceCenterUpdate'
import Monitoring from './Monitoring'
import ScenarioEditor from './ScenarioEditor'
import request from './serviceCenters.request'
import {
    ServiceCenterStatus, ServiceCenterTabs,
} from './serviceCenters.types'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const isSCActive = ({
    serviceCenterStatus,
}) => {
    return serviceCenterStatus === ServiceCenterStatus.ACTIVE
}

const ServiceCenters = () => {
    const canCreate = useHasPermission(['service_center_create'])
    const canUpdate = useHasPermission(['service_center_update'])
    const canMonitoring = useHasPermission(['service_center_monitoring'])
    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                url: CommonRoutes.OVERVIEW,
                label: 'Overview',
                Component: ServiceCenterOverview,
            },
            permission: true,
        },
        {
            tab: {
                url: CommonRoutes.DATA_UPDATE,
                label: 'Data Update',
                Component: ServiceCenterUpdate,
            },
            permission: canUpdate,
        },
        {
            tab: {
                url: ServiceCenterTabs.Monitoring,
                label: 'Monitoring',
                Component: Monitoring,
                condition: isSCActive,
            },
            permission: canMonitoring,
        },
        {
            tab: {
                url: ServiceCenterTabs.ScenarioEditor,
                label: 'Scenario Editor',
                Component: ScenarioEditor,
                condition: isSCActive,
            },
            permission: canMonitoring,
        },
    ])
    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            defaultTab={CommonRoutes.OVERVIEW}
            name={tableConfig.name}
            getDomainObject={request.GetServiceCenterById}
            actionComponents={{
                Create: canCreate ? ServiceCenterCreate : undefined,
            }}
            sidebarTabs={tabs}
            domainRequestKey={RequestKeys.getServiceCenterById}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default ServiceCenters
