import {
    useQueryClient,
} from '@tanstack/react-query'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import request from '../../AQuotes.requests'
import {
    AssignPricingLaneGroupResponse,
} from '../../AQuotes.types'

const useGetLaneGroupPricingAssignmentById = ({
    id, quoteId,
}: { id: number, quoteId: number }) => {
    const queryClient = useQueryClient()

    const invalidate = () => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getLaneGroupPricingAssessmentById],
        })
    }

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<AssignPricingLaneGroupResponse>({
        key: RequestKeys.getLaneGroupPricingAssessmentById,
        params: {
            id, quoteId,
        },
        requestFunc: RequestFn.getEntity(request.getLaneGroupPricingAssignment),
        enabled: Boolean(id),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetLaneGroupPricingAssignmentById
