import {
    CheckboxCellTemplate, Compatible, CheckboxCell, UncertainCompatible, keyCodes,
} from '@silevis/reactgrid'
import React from 'react'
import SelectableElement from '../../SelectableElement'
import SelectCellWrapper from '../SelectCellTemplate/SelectCellWrapper'
import {
    SelectCell,
} from '../SelectCellTemplate'

export interface CustomCheckboxCell extends CheckboxCell {
    renderAsSelectValues?: [string, string]
}

export default class CustomCheckboxCellTemplate extends CheckboxCellTemplate {
    // eslint-disable-next-line class-methods-use-this
    getClassName(cell: Compatible<CustomCheckboxCell>): string {
        if (cell?.renderAsSelectValues) {
            return 'rg-cell rg-select-cell'
        }
        return 'rg-cell rg-checkbox-cell'
    }

    handleKeyDown(
        cell: Compatible<CustomCheckboxCell>,
        keyCode: number,
        ctrl: boolean,
        shift: boolean,
        alt: boolean,
    ): { cell: Compatible<CustomCheckboxCell>, enableEditMode: boolean } {
        if (!cell?.renderAsSelectValues) {
            return super.handleKeyDown(cell, keyCode, ctrl, shift, alt)
        }

        return {
            cell,
            enableEditMode:
                keyCode === keyCodes.POINTER
                || keyCode === keyCodes.ENTER || keyCode === keyCodes.SPACE,
        }
    }

    update(
        cell: Compatible<CustomCheckboxCell>,
        cellToMerge: UncertainCompatible<CustomCheckboxCell>,
    ):
        Compatible<CustomCheckboxCell> {
        return this.getCompatibleCell({
            ...cell,
            checked: !!cellToMerge.value
                || String(cellToMerge.text) === cellToMerge.renderAsSelectValues?.[0],
        })
    }

    render(
        cell: Compatible<CustomCheckboxCell>,
        isInEditMode: boolean,
        onCellChanged: (
            cell: Compatible<CustomCheckboxCell>,
            commit: boolean
        ) => void,
    ): React.ReactNode {
        if (!cell?.renderAsSelectValues) {
            return super.render(cell, isInEditMode, onCellChanged)
        }

        const selectedValue = cell.checked
            ? cell.renderAsSelectValues?.[0] : cell.renderAsSelectValues?.[1]

        if (!isInEditMode) {
            return (
                <SelectableElement
                    disabled={cell.nonEditable}
                    text={selectedValue}
                    id={selectedValue}
                    name="BooleanSelect"
                    editMode={false}
                />
            )
        }

        return (
            <SelectCellWrapper
                noneOption={false}
                cell={{
                    ...cell, text: selectedValue, selectedValue, id: selectedValue,
                } as unknown as Compatible<SelectCell>}
                options={(cell.renderAsSelectValues || []).map((value) => {
                    return {
                        value,
                        id: value,
                        label: value,
                    }
                })}
                onChange={({
                    value,
                } = {}) => {
                    return onCellChanged(this.getCompatibleCell({
                        ...cell,
                        checked: value === cell.renderAsSelectValues?.[0],
                    }), true)
                }}
            />
        )
    }
}
