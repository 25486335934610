import React, {
    useCallback,
    useState,
} from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import CollapsableAddRemovePanel from 'app/shared-components/CollapsableAddRemovePanel'
import LoggerForm from 'app/Apps/ContainerManagement/Containers/components/LoggerExchange/LoggerForm'
import Card from 'app/shared-components/Card'
import {
    useLoggerRoutes,
} from 'app/Apps/Loggers'

import useGetLoggerContainerInformationBySerialNumber from '../../hooks/useGetLoggerContainerInformationBySerialNumber'

import columns from './LoggerExchange.columns'
import deletedLoggersColumns from './DeletedLoggers.columns'
import useStyles from './LoggerExchange.style'
import LoggerUpdateForm from './LoggerUpdateForm'
import useGetDeletedLoggerHistory from './hooks/useGetDeletedLoggerHistory'

const LoggerExchange = ({
    id: serialNumber,
}:{ id: string }) => {
    const {
        Loggers: LoggerRoutes,
    } = useLoggerRoutes()
    const {
        classes,
    } = useStyles()

    const onRowClick = useCallback(({
        logger: {
            loggerNumber,
        },
    }) => {
        LoggerRoutes.Update.navigate({
            params: {
                key: loggerNumber,
            },
        })
    }, [LoggerRoutes])

    const [
        createFormOpened,
        setCreateFormOpened,
    ] = useState(true)

    const closeCreateForm = useCallback(() => {
        setCreateFormOpened(false)
    }, [])

    const openCreateForm = useCallback(() => {
        setCreateFormOpened(true)
    }, [])

    const {
        onParamsChange,
        loadData,
        invalidate,
    } = useGetLoggerContainerInformationBySerialNumber(serialNumber)

    const {
        loadData: loadDeletedLoggersData,
        onParamsChange: onDeletedLoggersParamsChange,
        refetch,
    } = useGetDeletedLoggerHistory(serialNumber)

    const refresh = useCallback(() => {
        refetch()
        invalidate()
    }, [
        refetch,
        invalidate,
    ])

    const getUpdateForm = ({
        rowProps,
    }) => {
        return (
            <LoggerUpdateForm
                data={rowProps}
                serialNumber={serialNumber}
                refresh={refresh}
            />
        )
    }

    return (
        <div className={classes.container}>
            <Card
                fullHeight
                data-testid="loggerContainerInformation"
            >
                <CollapsableAddRemovePanel
                    title="Loggers"
                    onOpenForm={openCreateForm}
                    onCloseForm={closeCreateForm}
                    formOpened={createFormOpened}
                >
                    <LoggerForm
                        serialNumber={serialNumber}
                        onSuccess={invalidate}
                    />
                </CollapsableAddRemovePanel>
                <RichTable
                    configName="loggerContainerInformation"
                    name="LoggerContainerInformation"
                    load={loadData}
                    onParamsChange={onParamsChange}
                    uniqField="id"
                    columns={columns}
                    className={classes.tableContent}
                    detailPanel={getUpdateForm}
                    classNames={{
                        contentWrapper: classes.tableContentWrapper,
                    }}
                />
            </Card>
            <Card
                title="Delete History"
                className={classes.deleteHistoryCard}
            >
                <RichTable
                    configName="deletedLoggers"
                    name="deletedLoggers"
                    load={loadDeletedLoggersData}
                    onParamsChange={onDeletedLoggersParamsChange}
                    uniqField="id"
                    columns={deletedLoggersColumns}
                    className={classes.tableContent}
                    classNames={{
                        contentWrapper: classes.tableContentWrapper,
                    }}
                    onRowClick={onRowClick}
                />
            </Card>
        </div>
    )
}

export default LoggerExchange
