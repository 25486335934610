import {
    tss,
} from 'app/tss'

const general = 'general'
const regionalService = 'regionalService'

export default tss.create(({
    theme,
}) => {
    return {
        msgContainer: {
            background: theme?.palette.common.beige,
            display: 'flex',
            padding: theme.spacing(1.5, 1),
            alignItems: 'center',
            borderRadius: 8,
            marginBottom: theme.spacing(3),
        },
        warningText: {
            fontWeight: theme.typography.h4.fontWeight,
            padding: theme.spacing(0, 1),
        },
        gridWrapper: {
            display: 'grid',
            gap: theme.spacing(2),
            gridTemplateAreas: `
                "${general} ${general} ${regionalService} ${regionalService}"
            `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        general: {
            gridArea: general,
        },
        regionalService: {
            gridArea: regionalService,
        },
        green: {
            '&&': {
                color: `${theme.palette.common.green} !important`,
            },
        },
        red: {
            '&&': {
                color: `${theme.palette.common.red} !important`,
            },
        },
    }
})
