import React, {
    useCallback,
} from 'react'

import useFilter from 'app/hooks/useFilter'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'

import OpportunitiesSpreadSheet from '../OpportunitiesSpreadSheet'
import {
    OpportunityType,
} from '../opportunities.types'

import useOpportunitiesBulkCreate from './hooks/useOpportunitiesBulkCreate'

const OpportunitiesBulkCreate = ({
    onClose,
}: {
    onClose: () => void
}) => {
    const filterOptions = useFilter()

    const opportunitiesCreate = useOpportunitiesBulkCreate()

    const onSave = useCallback((
        opportunities: OpportunityType[], options?: AsyncMutationOptions,
    ) => {
        opportunitiesCreate(opportunities, {
            ...options,
            onSuccess: options?.onSuccess || onClose,
        })
    }, [
        onClose,
        opportunitiesCreate,
    ])

    return (
        <OpportunitiesSpreadSheet
            filterOptions={filterOptions}
            onSave={onSave}
            onCancel={onClose}
            enableAddNewRows
        />
    )
}

export default OpportunitiesBulkCreate
