import React from 'react'
import useRequest, {
    RequestKeys, RequestFn,
} from 'app/hooks/useRequest'
import StatusHandler from 'app/shared-components/StatusHandler'
import requests from '../CQuote.request'
import CQuoteForm from '../CQuoteForm'
import {
    CQuote,
} from '../CQuotes.types'
import CQuoteRequestedLanes from '../CQuoteRequestedLanes/CQuoteRequestedLanes'

export default function CQuoteOverview({
    id,
}: Readonly<{ id: number }>) {
    const {
        data,
        isDataReady,
        isError,
        isFetching,
        error,
    } = useRequest<CQuote>({
        key: RequestKeys.getCQuote,
        requestFunc: RequestFn.getEntity(requests[RequestKeys.getCQuote]),
        enabled: Boolean(id),
        keepPreviousData: true,
        params: {
            id,
        },
    })

    return (
        <StatusHandler
            isError={isError}
            isSuccess={isDataReady}
            isFetching={isFetching}
            error={error}
        >
            <>
                <CQuoteForm
                    data={data}
                    disabled
                />
                <CQuoteRequestedLanes requestedLanes={data?.requestedLanes} />
            </>
        </StatusHandler>
    )
}
