import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    ActivityRecordTable, ActivityRecordType,
} from 'app/Apps/RelationshipsManagement/ActivityRecords/activityRecords.types'

const columns = ({
    l,
}: {
    l: (l: string) => string,
}): RichTableColumns<keyof ActivityRecordTable>[] => {
    return [
        {
            id: 'companiesCompanyName',
            headerProps: {
                children: l('ActivityRecords.companyName'),
            },
            mapCellProps: 'companiesCompanyName',
            componentName: 'List',
        },
        {
            id: 'participants',
            headerProps: {
                children: l('ActivityRecords.participants'),
            },
            mapCellProps: 'participants',
            componentName: 'List',
        },
        {
            id: 'subject',
            headerProps: {
                children: l('ActivityRecords.subject'),
            },
            mapCellProps: 'subject',
        },
        {
            id: 'type',
            headerProps: {
                children: l('ActivityRecords.type'),
            },
            mapCellProps: 'type',
            componentName: 'EnumValue',
            allowedValuesEnum: ActivityRecordType,
        },
        {
            id: 'organizer',
            headerProps: {
                children: l('ActivityRecords.organizer'),
            },
            mapCellProps: 'organizer',
        },
        {
            id: 'isFollowUpNeeded',
            componentName: 'ChipCheckMark',
            headerProps: {
                children: l('ActivityRecord.followUpNeeded'),
            },
            mapCellProps: 'isFollowUpNeeded',
        },
        {
            id: 'happenedOn',
            headerProps: {
                children: l('ActivityRecords.happenedOn'),
            },
            mapCellProps: 'happenedOn',
            componentName: 'DateTimeCell',
        },
        {
            id: 'durationInMinutes',
            headerProps: {
                children: l('ActivityRecords.duration'),
            },
            componentName: 'AmountOfTime',
            mapCellProps: 'durationInMinutes',
        },
    ]
}

export default columns
