import {
    tss,
} from 'app/tss'
import {
    IconType,
} from './TextIcon'

export default tss.withParams<{type: IconType}>().create(({
    theme,
}) => {
    return {
        cell: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(0.5),
            height: '100%',
        },
        text: {
            fontFamily: theme.typography.h5.fontFamily,
            fontSize: theme.typography.h5.fontSize,
            fontWeight: theme.typography.h5.fontWeight,
            color: theme.palette.secondary[600],
            display: 'flex',
            justifyContent: 'center',
            marginRight: theme.spacing(0.25),
        },
        icon: {
            width: 18,
            height: 16,
        },
        [IconType.Warning]: {
            color: theme.palette.common.strongRed,
        },
    }
})
