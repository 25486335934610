import React, {
    useState,
    useEffect,
    useCallback, useMemo, useRef,
} from 'react'

import Card from 'app/shared-components/Card'
import Radio from 'app/shared-components/Radio'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import StatusHandler from 'app/shared-components/StatusHandler'
import useLabel from 'app/hooks/useLabel'

import {
    NotificationsOptions,
} from '../lanes.types'

import useGetNotificationsSettings from './hooks/useGetNotificationsSettings'
import SettingsTable from './SettingsTable'
import useUpdateNotificationsSettings from './hooks/useUpdateNotificationsSettings'

const radioOptions: {
    label: string,
    value: string,
}[] = [
    {
        value: NotificationsOptions.ALL_ACTIVATED,
        label: 'Activate All',
    },
    {
        value: NotificationsOptions.ALL_DEACTIVATED,
        label: 'Deactivate All',
    },
    {
        value: NotificationsOptions.ADVANCED_SETTINGS_ENABLED,
        label: 'Advanced mode',
    },
]

const Notifications = ({
    id,
}: {id: number}) => {
    const l = useLabel()
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetNotificationsSettings(id)

    const [
        value,
        setValue,
    ] = useState<{
        settingsMode: NotificationsOptions,
        settings?: string[],
    }>()

    useEffect(() => {
        if (data?.notificationsAdvancedSettings?.length) {
            setValue({
                settingsMode: data.settingsMode,
                settings: data.notificationsAdvancedSettings.reduce((acc, el) => {
                    if (!el.disabled) {
                        return [
                            ...acc,
                            el.triggerType,
                        ]
                    }
                    return acc
                }, []),
            })

            return
        }
        setValue(data)
    }, [data])

    const {
        mutate,
    } = useUpdateNotificationsSettings(invalidate)

    const onChangeMode = (mode: NotificationsOptions) => {
        setValue({
            ...value,
            settingsMode: mode,
        })
    }

    const isSettingsChanged = useRef(false)

    const onChangeSettings = useCallback((newSettings: string[]) => {
        isSettingsChanged.current = true

        setValue({
            ...value,
            settings: newSettings,
        })
    }, [value])

    const onClickSave = () => {
        mutate({
            notificationsSettings: {
                settingsMode: value?.settingsMode,
                notificationsAdvancedSettings: value?.settings
                    && data.notificationsAdvancedSettings.map((item) => {
                        if (value?.settings.some((el) => {
                            return el === item.triggerType
                        })) {
                            return {
                                ...item,
                                disabled: false,
                            }
                        }

                        return {
                            ...item,
                            disabled: true,
                        }
                    }),
            },
            laneId: id,
        })
    }

    const disabled = useMemo(() => {
        if (value?.settings) {
            return !isSettingsChanged.current && data?.settingsMode === value?.settingsMode
        }

        return data?.settingsMode === value?.settingsMode
    }, [
        data?.settingsMode,
        value,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <Card title="Active Notifications">
                    <Radio
                        title={l('Lane.notifications')}
                        value={value?.settingsMode}
                        options={radioOptions}
                        compact
                        onChange={onChangeMode}
                    />
                </Card>
                {value?.settings
                    && value.settingsMode === NotificationsOptions.ADVANCED_SETTINGS_ENABLED && (
                    <SettingsTable
                        data={data?.notificationsAdvancedSettings}
                        value={value.settings}
                        onChange={onChangeSettings}
                    />
                )}
                <ButtonContainer>
                    <Button
                        label="Save"
                        onClick={onClickSave}
                        disabled={disabled}
                    />
                </ButtonContainer>
            </>
        </StatusHandler>
    )
}

export default Notifications
