import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import CountriesRequest from '../Countries.request'
import {
    Country,
} from '../Countries.types'

const useGetCountry = (id, keepPreviousData = true) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<Country>({
        key: RequestKeys.getCountry,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(CountriesRequest.get),
        enabled: Boolean(id),
        keepPreviousData,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetCountry
