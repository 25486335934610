import React, {
    useMemo,
} from 'react'

import useHasPermission from 'app/hooks/useHasPermission'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    getUrlsFromTableConfig,
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import useTabsByPermission from 'app/hooks/useTabsByPermission'

import useContainerManagementRoutes from '../hooks/useContainerManagementRoutes'

import CreateContainer from './components/CreateContainer'
import PrintLabel from './components/PrintLabel'
import Overview from './components/Overview'
import ChangeData from './components/ChangeData'
import ChangeStatus from './components/ChangeStatus'
import LoggerExchange from './components/LoggerExchange'
import SensorData from './components/SensorData'
import Damages from './components/Damages'
import getConfig from './Container.config'
import Geolocation from './components/Geolocation'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Containers = () => {
    const {
        Containers: ContainersRoutes,
    } = useContainerManagementRoutes()
    const containerCreate = useHasPermission(['container_create'])
    const containerUpdate = useHasPermission(['container_update'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: ContainersRoutes.Overview.route,
                Component: Overview,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Data Update',
                url: ContainersRoutes.Update.route,
                Component: ChangeData,
            },
            permission: containerUpdate,
        },
        {
            tab: {
                label: 'Status Update',
                url: ContainersRoutes.ChangeStatus.route,
                Component: ChangeStatus,
            },
            permission: containerUpdate,
        },
        {
            tab: {
                label: 'Loggers',
                url: ContainersRoutes.Loggers.route,
                Component: LoggerExchange,
            },
            permission: containerUpdate,
        },
        {
            tab: {
                label: 'Sensor Data',
                url: ContainersRoutes.SensorData.route,
                Component: SensorData,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Damages',
                url: ContainersRoutes.Damages.route,
                Component: Damages,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Geolocation',
                url: ContainersRoutes.Geolocation.route,
                Component: Geolocation,
            },
            permission: true,
        },
    ])

    const tableConfig = useMemo(() => {
        return getConfig()
    }, [])

    return (
        <SkyNetDomain
            actionComponents={{
                Create: containerCreate ? CreateContainer : undefined,
                Print: PrintLabel,
            }}
            name="ContainerView"
            uniqField="serialNumber"
            defaultTab={ContainersRoutes.Overview.route}
            sidebarTabs={tabs}
            subdomainUrls={getUrlsFromTableConfig(tableConfig)}
        >
            <SkyNetTable
                moduleName="Container"
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default Containers
