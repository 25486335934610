import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requests from '../alertNotifications.requests'
import {
    HasUnreadAlerts,
} from '../alertNotifications.types'
import {
    HAVE_ALERTS_REFETCH_INTERVAL,
} from '../alertNotifications.constants'

const useHasUnreadAlerts = ({
    enabled,
}: {enabled: boolean}) => {
    return useRequest<HasUnreadAlerts>({
        key: RequestKeys.hasUnreadAlerts,
        params: {},
        requestFunc: RequestFn.getEntity(requests.hasUnreadAlerts),
        enabled,
        refetchInterval: HAVE_ALERTS_REFETCH_INTERVAL,
        refetchIntervalInBackground: window?.navigator?.onLine,
    })
}

export default useHasUnreadAlerts
