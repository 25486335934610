import {
    RichTableColumns,
} from '../../types/richtable.types'
import {
    TableControlTypes,
} from '../TableControls'
import {
    TableResponse,
} from '../../types/request.types'

export type CollapsibleTableProps<D = Record<string, any>> = Readonly<{
    title: string,
    domainName: string,
    configName?: string,
    customUrl?: string,
    preDefinedFilters?: Record<string, any>,
    requestHandlers: {
        loadData: (...args: any[]) => Promise<any>,
        refetch: (...args: any[]) => void,
        onParamsChange: (...args: any[]) => void,
        data?: TableResponse<D> | D,
        isDataReady?: boolean,
    },
    actionComponents: {
        Copy?: (props: Record<string, any>) => JSX.Element,
        Update: (props: Record<string, any>) => JSX.Element,
        Create?: (props: Record<string, any>) => JSX.Element
    },
    columns: RichTableColumns[],
    createEnabled?: boolean,
    tableControls?: TableControlTypes[],
    switchableFormTable?: boolean,
    backToTableText?: string,
    routeParams?: string[],
    radioSelect?: boolean,
    uniqField?: string,
}>

export const collapsibleDefaultTableProps = {
    configName: undefined,
    createEnabled: true,
    tableControls: undefined,
    customUrl: undefined,
    switchableFormTable: false,
    backToTableText: undefined,
    routeParams: [
        'actionId',
        'action',
    ],
    uniqField: undefined,
}
