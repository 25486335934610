import React, {
    useCallback,
} from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import SkyNetStepperButtons from 'app/shared-components/SkyNetStepper/SkyNetStepperButtons'
import useValidation from 'app/hooks/useValidation/useValidation'
import useMarginStyles from 'app/tss/margin.styles'

import useSimplifiedCQuoteCreateContext from '../../SimplifiedCQuoteCreateContext'
import FeesOverview from '../../../FeesOverview'
import {
    SimplifiedCQuoteCreateType,
} from '../../simplifiedCQuoteCreate.types'
import AdditionalFeesTable from '../../../AdditionalFeesTable'
import LaneFeesTable from '../../../LaneFeesTable'

import useGenerateSimplifiedCQuote from './hooks/useGenerateSimplifiedCQuote'
import fields from './feesAndContactDetails.fields'

const FeesAndContactDetails = () => {
    const {
        classes,
    } = useMarginStyles({
        top: 3,
    })
    const {
        activeStep,
        setActiveStep,
        setValue,
        value,
        feesData,
    } = useSimplifiedCQuoteCreateContext()

    const {
        mutate: downloadPDF,
        isPending,
    } = useGenerateSimplifiedCQuote({
        originLocation: {
            quotationLevel: value.firstStep.originQuotationLevel,
            airport: value.firstStep.originAirport,
            country: value.firstStep.originCountry,
            regionSecondLevel: value.firstStep.originRegion,
            state: value.firstStep.originState,
        },
        destinationLocation: {
            quotationLevel: value.firstStep.destinationQuotationLevel,
            airport: value.firstStep.destinationAirport,
            country: value.firstStep.destinationCountry,
            regionSecondLevel: value.firstStep.destinationRegion,
            state: value.firstStep.destinationState,
        },
        deliveryServiceType: value.secondStep.deliveryServiceType,
        collectionServiceType: value.secondStep.collectionServiceType,
        productType: value.firstStep.productType,
        currency: value.thirdStep.currency,
        rentalDays: value.thirdStep.rentalDays,
        logisticsDays: value.thirdStep.logisticsDays,
        customer: {
            companyName: value.thirdStep.companyName,
            companyAddress: value.thirdStep.companyAddress,
            contactNameAndSurname: value.forthStep.contactNameAndSurname,
            contactEmail: value.forthStep.contactEmail,
        },
        skycellContact: value.forthStep.skycellContact,
    })

    const onChange = useCallback((newVal: Partial<SimplifiedCQuoteCreateType>) => {
        setValue({
            ...value,
            forthStep: newVal,
        })
    }, [
        setValue,
        value,
    ])

    const backAction = useCallback(() => {
        setActiveStep(activeStep - 1)
        setValue({
            ...value,
            forthStep: {},
        })
    }, [
        setActiveStep,
        activeStep,
        setValue,
        value,
    ])

    const {
        isValid,
    } = useValidation({
        fields,
        values: value.forthStep,
    })

    const handleNextAction = useCallback(() => {
        downloadPDF()
    }, [downloadPDF])

    return (
        <>
            <FeesOverview value={feesData} />
            <LaneFeesTable
                value={feesData?.laneFees?.fees}
                currency={value.thirdStep.currency}
                title="Lane Fees"
            />
            <AdditionalFeesTable
                value={feesData?.additionalFees.fees}
                currency={value.thirdStep.currency}
                title="Additional Fees"
            />
            <DomainObjectForm
                value={value.forthStep}
                onChange={onChange}
                fields={fields}
                name="FeesAndContactDetails"
                className={classes.margin}
            />
            <SkyNetStepperButtons
                isValid={isValid}
                nextLabel="Generate"
                nextAction={handleNextAction}
                canGoBack
                backAction={backAction}
                isLoading={isPending}
            />
        </>
    )
}

export default FeesAndContactDetails
