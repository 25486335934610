import React, {
    useCallback, useEffect,
    useMemo,
} from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useShareContext from 'app/shared-components/SharedContext/useSharedContext'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    OrderTemperatureRange,
} from 'app/types/enums'
import {
    Event,
} from 'app/types/common.types'

import {
    AnalyseTemperatureType,
    SelectedContainer,
} from '../../simulation.types'

import getFields from './simulationOptions.fields'
import useStyles from './SimulationOptions.style'

type Props = {
    value?: AnalyseTemperatureType,
    onChange: (changeValue: AnalyseTemperatureType) => void,
}

const defaultProps = {
    value: {},
}

const SimulationOptions = ({
    onChange,
    value,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const {
        selectedContainer,
    }: {
        selectedContainer: SelectedContainer,
    } = useShareContext()

    const {
        customLowerTempRange,
        customUpperTempRange,
    } = value

    useEffect((): void => {
        if (
            selectedContainer?.tempRange === OrderTemperatureRange.DF
            && !customLowerTempRange && !customUpperTempRange
        ) {
            onChange({
                ...value,
                customLowerTempRange: -80,
                customUpperTempRange: -60,
            })
        }
    }, [
        customLowerTempRange,
        customUpperTempRange,
        onChange,
        selectedContainer?.tempRange,
        value,
    ])

    const onChangeInput = useCallback((_, {
        target: {
            value: newValue,
            name,
        },
    }: Event<AnalyseTemperatureType>): void => {
        onChange({
            ...value,
            [name]: newValue,
        })
    }, [
        onChange,
        value,
    ])

    const fields = useMemo(() => {
        return getFields(value?.useCustomTempRange, getAllowedValues)
    }, [
        getAllowedValues,
        value?.useCustomTempRange,
    ])

    const fieldsWithClassName = useFieldsWithClassName(fields, classes)

    return (
        <DomainObjectForm
            className={classes.container}
            classNames={{
                gridCardWrapper: classes.gridCardWrapper,
            }}
            name="TransportSimulation"
            value={value}
            onChange={onChangeInput}
            fields={fieldsWithClassName}
        />
    )
}

SimulationOptions.defaultProps = defaultProps

export default SimulationOptions
