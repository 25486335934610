import React, {
    useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import {
    DeliveryServiceType, Order,
} from '../../orders.types'
import useGetOrderByNumber from '../../hooks/useGetOrderByNumber'

import useGetPositioningSetup from './hooks/useGetPositioningSetup'
import OrderConfirmation from './OrderConfirmation'

const OrderConfirmationContainer = ({
    id: orderNumber,
}: {id: string}) => {
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetPositioningSetup(orderNumber)

    const {
        data: order = {} as Order,
        invalidate: invalidateOrder,
    } = useGetOrderByNumber(orderNumber)

    const isDeliveryRoad: boolean = useMemo(() => {
        return data?.deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD
    }, [data?.deliveryServiceType])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <OrderConfirmation
                positioningSetup={data}
                order={order}
                isDeliveryRoad={isDeliveryRoad}
                onSuccess={invalidate}
                invalidateOrder={invalidateOrder}
            />
        </StatusHandler>
    )
}

export default OrderConfirmationContainer
