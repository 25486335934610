import {
    ContractCurrency,
} from 'app/types/enums'

export const defaultTaxCodes = Object.values(ContractCurrency).map((currency) => {
    return {
        currency,
        taxCode: '401',
    }
})

export const defaultEmptyTaxCodes = Object.values(ContractCurrency).map((currency) => {
    return {
        currency,
    }
})
