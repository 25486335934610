import React, {
    useState,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    ModificationType, Order,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import useGetOrderByNumber from '../../hooks/useGetOrderByNumber'

import getFields from './dataUpdate.fields'
import useStyles from './DataUpdate.style'
import {
    ModificationData,
} from './dataUpdate.types'
import useModifyOrder from './hooks/useModifyOrder'
import RelinkLaneDialog from './RelinkLaneDialog'

const DataUpdate = ({
    id: orderNumber,
}: {id: string}) => {
    const getAllowedValues = useEnumValues()
    const {
        classes,
    } = useStyles()
    const {
        data = {} as Order,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetOrderByNumber(orderNumber)

    const fields = useFieldsWithClassName(getFields({
        getAllowedValues,
        leaseType: data.leaseType,
    }), classes)

    const {
        value,
        setValue,
        resetData,
        updateOrder,
        isSaveDisabled,
        isLoading,
    }: ModificationData = useModifyOrder({
        data,
        onSuccess: invalidate,
    })

    const [
        modification,
        setModification,
    ] = useState<ModificationType>()

    const [
        openRelinkLane,
        setRelinkLaneOpen,
    ] = useState<boolean>(false)

    const isRoleLaneUpdate = useHasPermission(['order_lane_update'])

    const modifyOrder = (modificationType: ModificationType) => {
        updateOrder(modificationType)
        setModification(modificationType)
    }

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <DomainObjectForm
                    value={value}
                    onChange={setValue}
                    fields={fields}
                    className={classes.gridWrapper}
                    name="OrderByNumberView"
                />
                <ButtonContainer>
                    {isRoleLaneUpdate && (
                        <Button
                            label="relink lane"
                            onClick={() => { setRelinkLaneOpen(true) }}
                            data-testid="btn-relink-lane"
                        />
                    )}
                    <Button
                        label="Save (SkyCell)"
                        onClick={() => {
                            modifyOrder(ModificationType.INTERNAL)
                        }}
                        data-testid="save-btn-internal"
                        disabled={
                            isSaveDisabled
                            || (isLoading && modification === ModificationType.CUSTOMER)
                        }
                        saving={isLoading && modification === ModificationType.INTERNAL}
                    />
                    <Button
                        label="Save (Customer)"
                        onClick={() => { modifyOrder(ModificationType.CUSTOMER) }}
                        disabled={
                            isSaveDisabled
                            || (isLoading && modification === ModificationType.INTERNAL)
                        }
                        data-testid="save-btn-customer"
                        saving={isLoading && modification === ModificationType.CUSTOMER}
                    />
                    <Button
                        secondary
                        label="Cancel"
                        onClick={resetData}
                        disabled={isLoading}
                    />
                </ButtonContainer>
                <RelinkLaneDialog
                    open={openRelinkLane}
                    setOpen={setRelinkLaneOpen}
                    orderNumber={data.orderNumber}
                    onSuccess={invalidate}
                />
            </>
        </StatusHandler>
    )
}

export default DataUpdate
