import isEmpty from 'lodash/isEmpty'

import {
    ProductType,
} from 'app/types/enums'
import {
    RequiredContainersCustomer,
} from 'app/types/common.types'
import getEnumLabel from 'app/utils/getEnumLabel'

import {
    PreBooking, PreBookingEntity,
} from '../PreBooking.types'

import {
    PreBookingEntitySpreadsheetData,
} from './PreBookingSpreadsheet.config'

type RequiredProductTypes = [ProductType, number][]

export const getLaneLabel = ({
    laneNumber,
    leaseType,
    customerExecutionLocationName,
}) => {
    return `${laneNumber} | ${getEnumLabel(leaseType)} | ${customerExecutionLocationName}`
}

export const formatRequiredContainers = (productTypes: RequiredProductTypes) => {
    return productTypes.reduce((acc, [
        key,
        quantity,
    ]) => {
        if (quantity) {
            return [
                ...acc,
                {
                    productType: key,
                    quantity,
                },
            ]
        }
        return acc
    }, [] as RequiredContainersCustomer[])
}

export const convertToPrebookingEntity = (
    value: PreBookingEntitySpreadsheetData[],
): PreBookingEntity[] => {
    return value.map(({
        id,
        lane,
        requestedHandover,
        handoverTimezone,
        requestedPickup,
        pickupTimezone,
        requestedHandoverWeek,
        requestedHandoverYear,
        [ProductType.VAL_1500C]: productTypeVAL1500C,
        [ProductType.VAL_1500XCOL]: productTypeVAL1500XCOL,
        [ProductType.VAL_2500C]: productTypeVAL2500C,
        mawbNumber,
        customerReference,
        preBookingStatus,
        containersStatus,
        isForecasted,
        hasBooking,
        project,
        originServiceCenter,
        modificationReason,
    }) => {
        return {
            id,
            lane,
            requestedHandover: isEmpty(requestedHandover) ? undefined : `${requestedHandover} ${handoverTimezone}`,
            requestedPickup: isEmpty(requestedPickup) ? undefined : `${requestedPickup} ${pickupTimezone}`,
            requestedHandoverWeek,
            requestedHandoverYear,
            mawbNumber: isEmpty(mawbNumber) ? undefined : mawbNumber,
            customerReference,
            preBookingStatus,
            containersStatus,
            isForecasted,
            hasBooking,
            project,
            originServiceCenter,
            modificationReason,
            requiredContainers: formatRequiredContainers([
                [
                    ProductType.VAL_1500C,
                    productTypeVAL1500C,
                ],
                [
                    ProductType.VAL_1500XCOL,
                    productTypeVAL1500XCOL,
                ],
                [
                    ProductType.VAL_2500C,
                    productTypeVAL2500C,
                ],
            ]),
        } as PreBookingEntity
    })
}

// eslint-disable-next-line max-len
export const parseRequiredContainers = (values: RequiredContainersCustomer[], type: ProductType): number => {
    return values.find(({
        productType,
    }) => {
        return productType === type
    })?.quantity
}

const splitDateTimeZome = (dateString: string): {
    timezone: string,
    date: string,
} => {
    if (!dateString) {
        return {
            timezone: undefined,
            date: undefined,
        }
    }

    const result = dateString.split(' ')

    return {
        timezone: result.at(-1),
        date: `${result.at(0)} ${result.at(1)}`,
    }
}

// eslint-disable-next-line max-len
export const convertToPrebookingEntitySpreadsheetData = (values: PreBooking[] = []): PreBookingEntitySpreadsheetData[] => {
    return values.map(({
        id,
        originAirportId,
        originAirportIataCode,
        destinationAirportId,
        destinationAirportIataCode,
        laneId,
        laneNumber,
        customerExecution,
        leaseType,
        handoverPointLocationName,
        collectionDropoffPointLocationName,
        requestedHandover,
        requestedHandoverYear,
        requestedHandoverWeek,
        project,
        customerReference,
        requestedPickup,
        mawbNumber,
        requiredContainers,
        preBookingStatus,
        containersStatus,
        hasBooking,
        originServiceCenter,
        originServiceCenterId,
        isForecasted,
    }: PreBooking) => {
        const requestedHandoverValues = splitDateTimeZome(requestedHandover)
        const requestedPickupValues = splitDateTimeZome(requestedPickup)

        return {
            id,
            originAirport: originAirportId ? {
                id: originAirportId,
                label: originAirportIataCode,
            } : undefined,
            destinationAirport: destinationAirportId ? {
                id: destinationAirportId,
                label: destinationAirportIataCode,
            } : undefined,
            lane: laneId ? {
                id: laneId,
                label: getLaneLabel({
                    customerExecutionLocationName: customerExecution,
                    leaseType,
                    laneNumber,
                }),
            } : undefined,
            handoverPointLocationName,
            collectionDropoffPointLocationName,
            requestedHandover: requestedHandoverValues.date,
            handoverTimezone: requestedHandoverValues.timezone,
            requestedHandoverYear,
            requestedHandoverWeek,
            project,
            customerReference,
            requestedPickup: requestedPickupValues.date,
            pickupTimezone: requestedPickupValues.timezone,
            mawbNumber,
            [ProductType.VAL_1500XCOL]:
                parseRequiredContainers(requiredContainers, ProductType.VAL_1500XCOL),
            [ProductType.VAL_2500C]:
                parseRequiredContainers(requiredContainers, ProductType.VAL_2500C),
            [ProductType.VAL_1500C]:
                parseRequiredContainers(requiredContainers, ProductType.VAL_1500C),
            preBookingStatus,
            containersStatus,
            hasBooking,
            originServiceCenter: originServiceCenterId ? {
                id: originServiceCenterId,
                label: originServiceCenter,
            } : undefined,
            isForecasted,
        } as unknown as PreBookingEntitySpreadsheetData
    })
}
