import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    CompanyWithParent,
    IndustryType,
} from '../Companies.types'

const getCompanyFields = (
    getAllowedValues,
    hasParentCompany,
): DTOCardFormField<keyof CompanyWithParent>[] => {
    return [{
        id: 'summary',
        title: 'Summary',
        className: 'summary',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'companyName',
                        required: true,
                        labelKey: 'Company.companyName',
                        componentName: 'InputSingleline',
                    },
                    {
                        name: 'locationNameRule',
                        componentName: 'InputRule',
                        rule: 'Official name',
                        example: 'Kuehne & Nagel AG',
                        labelKey: 'Company.locationNameRule',
                    },
                    {
                        name: 'website',
                        componentName: 'InputSingleline',
                        labelKey: 'Company.website',
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'awbPrefix',
                        componentName: 'InputSingleline',
                        type: 'number',
                        labelKey: 'Company.awbPrefix',
                    },
                    {
                        name: 'parentCompany',
                        componentName: 'CompanyObjectSelector',
                        labelKey: 'Company.parentCompany',
                    },
                    {
                        name: 'industryType',
                        labelKey: 'Accounts.industryType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(IndustryType),
                        useDropdown: true,
                        required: !hasParentCompany,
                    },
                ],
            },
        ],
    }]
}

export default getCompanyFields
