import React, {
    useMemo,
} from 'react'
import noop from 'lodash/noop'
import clsx from 'clsx'
import {
    Select as MaterialSelect,
    MenuItem,
    FormControl,
    FormHelperText,
} from '@mui/material'

import Label from 'app/shared-components/Label'
import sortAscending from 'app/utils/sortAscending'

import useStyles from './Select.style'

type Props = {
    title?: string,
    name?: string,
    onChange?: (...args: any[]) => void,
    value?: string | number,
    data?: any[],
    allowedValues?: Record<string, string>,
    hasError?: string,
    required?: boolean,
    timeFormat?: boolean,
    disableUnderline?: boolean,
    disableHelperText?: boolean,
    disableSorting?: boolean,
    className?: {
        formControl?: string,
        overrideSelect?: string,
        select?: string,
    },
    showOutOfRangeOption?: boolean,
    labelOutOfRangeValue?: string,
    noNone?: boolean,
}

const defaultProps: Partial<Props> = {
    title: '',
    name: '',
    data: [],
    allowedValues: {},
    onChange: noop,
    value: undefined,
    hasError: '',
    required: false,
    timeFormat: false,
    disableUnderline: false,
    disableSorting: false,
    disableHelperText: false,
    className: {},
    showOutOfRangeOption: false,
    labelOutOfRangeValue: undefined,
    noNone: undefined,
}

const Select = (props: Readonly<Props>) => {
    const {
        title,
        name,
        onChange,
        value,
        data,
        allowedValues,
        hasError,
        required,
        timeFormat,
        disableUnderline,
        disableHelperText,
        disableSorting,
        className,
        showOutOfRangeOption,
        labelOutOfRangeValue,
        noNone,
    } = props
    const {
        classes,
    } = useStyles()

    const options = useMemo(() => {
        const result = data.length === 0
            ? Object.entries(allowedValues)
            : data

        return disableSorting ? result : sortAscending(result)
    }, [
        data,
        allowedValues,
        disableSorting,
    ])

    const valueNotInOptions = useMemo(() => {
        return showOutOfRangeOption && !options.some(([key]) => {
            return key === value
        })
    }, [
        showOutOfRangeOption,
        options,
        value,
    ])

    return (
        <FormControl
            error={Boolean(hasError)}
            className={clsx(
                classes.formControl,
                className.formControl,
            )}
            variant="standard"
        >
            {title && (
                <Label
                    title={title}
                    hasError={hasError}
                />
            )}
            <div className={clsx(
                {
                    [classes.selectContainer]: true,
                    [classes.timeFormat]: timeFormat,
                },
            )}
            >
                {required && <div className={classes.requiredMark} />}
                <MaterialSelect
                    data-testid={`select-${name}`}
                    className={clsx(
                        {
                            [classes.isRequiredBackground]: required,
                        },
                        className.select,
                    )}
                    name={name}
                    value={value}
                    onChange={onChange}
                    color="primary"
                    fullWidth
                    disableUnderline={disableUnderline}
                    classes={{
                        root: classes.input,
                        select: className.overrideSelect || classes.select,
                    }}
                >
                    { !noNone && (
                        <MenuItem value={undefined}>
                            <em>None</em>
                        </MenuItem>
                    )}
                    {showOutOfRangeOption && labelOutOfRangeValue && valueNotInOptions && (
                        <MenuItem
                            data-testid="our-of-range-hidded-item"
                            style={{
                                display: 'none',
                            }}
                            value={value}
                            disabled
                        >
                            {labelOutOfRangeValue}
                        </MenuItem>
                    )}
                    {options.map(([
                        key,
                        val,
                    ]) => {
                        return (
                            <MenuItem
                                key={`menuitem-${key}`}
                                value={key}
                            >
                                {val}
                            </MenuItem>
                        )
                    })}
                </MaterialSelect>
                {Boolean(hasError) && (
                    <FormHelperText
                        error
                        className={clsx(
                            {
                                [classes.errorMessage]: true,
                                [classes.hideHelperText]: disableHelperText,
                            },
                        )}
                    >
                        {hasError}
                    </FormHelperText>
                )}
            </div>
        </FormControl>
    )
}

Select.defaultProps = defaultProps

export default Select
