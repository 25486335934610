import React, {
    useCallback, useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import usePricingRoutes from '../hooks/usePricingRoutes'

import getTableConfig from './lanes.config'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Lanes = () => {
    const {
        LaneGroups: LaneGroupsRoutes,
    } = usePricingRoutes()
    const onRowClick = useCallback(({
        laneGroupId, id,
    }) => {
        LaneGroupsRoutes.Lanes.navigate({
            params: {
                key: laneGroupId,
                actionId: id,
            },
        })
    }, [LaneGroupsRoutes])

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetTable
            name="LaneOpsOverView"
            onRowClick={onRowClick}
            tableControls={tableControls}
            tableConfig={tableConfig}
            customUrl="lane/filter-request/cs-overview"
            showSearch
        />
    )
}

export default Lanes
