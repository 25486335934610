import React, {
    useCallback,
    useEffect,
} from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import SkyNetStepperButtons from 'app/shared-components/SkyNetStepper/SkyNetStepperButtons'
import useValidation from 'app/hooks/useValidation'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import useSimplifiedCQuoteCreateContext from '../../SimplifiedCQuoteCreateContext'
import {
    CollServiceTypeSimplifiedCQuote,
    SimplifiedCQuoteCreateType,
} from '../../simplifiedCQuoteCreate.types'

import getFields from './deliveryOptions.fields'

const DeliveryOptions = ({
    index,
}: {
    index: number,
}) => {
    const getAllowedValues = useEnumValues()

    const {
        activeStep,
        setActiveStep,
        setValue,
        value,
        rateCardsData,
    } = useSimplifiedCQuoteCreateContext()

    useEffect(() => {
        if (!rateCardsData.originPositioningFtl && !rateCardsData.originPositioningLtl
            && !value.secondStep.deliveryServiceType) {
            setValue({
                ...value,
                secondStep: {
                    ...value.secondStep,
                    deliveryServiceType: DeliveryServiceType.PICKUP,
                },
            })
        }

        if (!rateCardsData.destinationCollectionLtl && !value.secondStep.collectionServiceType) {
            setValue({
                ...value,
                secondStep: {
                    ...value.secondStep,
                    collectionServiceType: CollServiceTypeSimplifiedCQuote.DROPOFF_SKYCELL,
                },
            })
        }
    }, [
        rateCardsData.destinationCollectionLtl,
        rateCardsData.originPositioningFtl,
        rateCardsData.originPositioningLtl,
        setValue,
        value,
    ])

    const fields = getFields({
        getAllowedValues,
        disabledDeliveryServiceType: !rateCardsData.originPositioningFtl
            && !rateCardsData.originPositioningLtl,
        disabledCollectionServiceType: !rateCardsData.destinationCollectionLtl,
        disabled: activeStep > index,
    })

    const onChange = useCallback((newVal: Partial<SimplifiedCQuoteCreateType>) => {
        setValue({
            ...value,
            secondStep: newVal,
        })
    }, [
        setValue,
        value,
    ])

    const handleNextAction = useCallback(() => {
        setActiveStep(activeStep + 1)
    }, [
        setActiveStep,
        activeStep,
    ])

    const editAction = useCallback(() => {
        setActiveStep(index)
        setValue({
            ...value,
            thirdStep: {},
            forthStep: {},
        })
    }, [
        index,
        setActiveStep,
        setValue,
        value,
    ])

    const backAction = useCallback(() => {
        setActiveStep(activeStep - 1)
        setValue({
            ...value,
            secondStep: {},
        })
    }, [
        setActiveStep,
        activeStep,
        setValue,
        value,
    ])

    const {
        isValid,
    } = useValidation({
        fields,
        values: value.secondStep,
    })

    return (
        <>
            <DomainObjectForm
                value={value.secondStep}
                onChange={onChange}
                fields={fields}
                disabled={activeStep > index}
                name="DeliveryOptions"
            />
            <SkyNetStepperButtons
                isValid={isValid}
                disabled={activeStep !== 1}
                nextLabel="Continue"
                nextAction={handleNextAction}
                canEdit={activeStep > index}
                editAction={editAction}
                canGoBack
                backAction={backAction}
            />
        </>
    )
}

export default DeliveryOptions
