import omit from 'lodash/omit'

import {
    LogisticsCostsItem,
    LogisticsCostsEditable,
} from './LogisticsCosts.types'

// eslint-disable-next-line import/prefer-default-export
export const prepareLogisticsCostsToBulkEdit = (
    items: LogisticsCostsItem[] = [],
): LogisticsCostsEditable[] => {
    return items.map((i) => {
        return omit(i, [
            'regionFirstLevel',
            'regionSecondLevel',
            'countries',
        ])
    })
}
