import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    DamageStatus, DamageType,
} from 'app/types/enums'
import {
    Damage,
} from './Damages.types'

export default (): SkyNetTableConfig<keyof Damage> => {
    return {
        name: 'Damages',
        tabs: [{
            name: 'All Damages',
            url: 'all',
            fields: [
                {
                    name: 'damageNumber',
                    labelKey: 'Damages.damageNumber',
                    reduced: true,
                },
                {
                    name: 'serialNumber',
                    labelKey: 'Damages.serialNumber',
                    reduced: true,
                },
                {
                    name: 'containerModel',
                    labelKey: 'Damages.containerModel',
                    filterField: 'containerModel',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'reportedOn',
                    labelKey: 'Damages.reportedOn',
                    componentName: 'DateTimeCell',
                },
                {
                    name: 'causingOrder',
                    labelKey: 'Damages.causingOrder',
                    filterField: 'causingOrder',
                    filterType: FilterType.Array,
                },
                {
                    name: 'damageType',
                    labelKey: 'Damages.damageType',
                    filterField: 'damageType',
                    filterType: FilterType.Array,
                    allowedValuesEnum: DamageType,
                    componentName: 'EnumValue',
                },
                {
                    name: 'damageStatus',
                    labelKey: 'Damages.damageStatus',
                    filterField: 'damageStatus',
                    filterType: FilterType.Array,
                    allowedValuesEnum: DamageStatus,
                    componentName: 'EnumValue',
                },
                {
                    name: 'repairingServiceCenter',
                    labelKey: 'Damages.repairingServiceCenter',
                    filterField: 'repairingServiceCenter',
                    filterType: FilterType.Array,
                },
                {
                    name: 'repairedOn',
                    labelKey: 'Damages.repairedOn',
                    componentName: 'DateTimeCell',
                },
                {
                    name: 'repairedBy',
                    labelKey: 'Damages.repairedBy',
                    filterField: 'repairedBy',
                    filterType: FilterType.Array,
                },
            ],
        }],
    }
}
