import React, {
    useMemo,
} from 'react'
import ReactApexChart from 'react-apexcharts'
import {
    useTheme,
} from '@mui/material/styles'

import Card from 'app/shared-components/Card'
import Button from 'app/shared-components/Button'
import useEnumValues from 'app/hooks/useEnumValues'
import useShareContext from 'app/shared-components/SharedContext/useSharedContext'

import {
    AnalyseTemperatureType, SimulationDataChart,
    SimulationStep,
    SimulationStepType, StepDataChart,
} from '../../simulation.types'

import getStepsData from './utils/getStepsData'
import chartOptions from './сhartOptions'
import useStyles from './AnalyseTemperatureChart.style'
import getDataChart from './utils/getDataChart'
import getSimulationData from './utils/getSimulationData'
import getExcursionTime from './utils/getExcursionTime'

const INFO_MSG = 'To obtain simulation data, please ensure that Container Model is selected and all required fields are completed.'

type Props = {
    simulationSteps: SimulationStep[],
    summaryTimeSteps: {
        h?: number,
        m?: number,
    },
    value: AnalyseTemperatureType,
}

const AnalyseTemperatureChart = ({
    simulationSteps,
    summaryTimeSteps,
    value,
}: Props) => {
    const theme = useTheme()
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const {
        simulationResults,
        isDataChange,
        setIsDataChange,
        selectedContainer,
    } = useShareContext()

    const allowedValuesType = getAllowedValues(SimulationStepType)

    const {
        showTemperatureRange,
        showEnergyLevel,
        showLocation,
        showStepType,
    } = value

    const isDurationByHours: boolean = useMemo(() => {
        return summaryTimeSteps.h > 500
    }, [summaryTimeSteps])

    const simulationData: SimulationDataChart = getSimulationData({
        isDurationByHours,
        simulationResults,
    })

    const excursionTime: number = getExcursionTime({
        isDurationByHours,
        simulationResults,
    })

    const stepsData: StepDataChart = getStepsData(
        {
            isDurationByHours,
            steps: simulationSteps,
            allowedValuesType,
        },
    )

    const options: Record<string, any> = chartOptions({
        theme,
        showLocation,
        showStepType,
        dataPoints: stepsData.points,
        excursionTime,
        showEnergyLevel,
        simulationResults,
        showTemperatureRange,
        maxTemperature: simulationResults?.[0]?.maxTemperature,
        minTemperature: simulationResults?.[0]?.minTemperature,
        categories: simulationData?.time,
    })

    const dataChart: {
        name: string,
        data: number[]
    }[] = getDataChart({
        simulationData,
        ambientTemperature: stepsData.ambientTemp,
        showEnergyLevel,
        showTemperatureRange,
    })

    const onClickRunSimulation = (): void => {
        setIsDataChange(false)
    }

    return (
        <Card
            className={simulationResults && !isDataChange ? classes.container : classes.emptyState}
        >
            {simulationResults && !isDataChange ? (
                <ReactApexChart
                    options={options}
                    height={700}
                    style={{
                        width: '100%',
                    }}
                    series={dataChart}
                    type="line"
                />
            ) : (
                <div className={classes.buttonContainer}>
                    <div data-testid="info-msg">{INFO_MSG}</div>
                    <Button
                        label="Run Simulation"
                        onClick={onClickRunSimulation}
                        className={classes.button}
                        disabled={!selectedContainer || !simulationSteps.length}
                    />
                </div>
            )}
        </Card>
    )
}

export default AnalyseTemperatureChart
