import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    CQuotesPdf,
} from '../CQuotes.types'

const columns = ({
    l,
}: {
    l: (l:string) => string,
}): RichTableColumns<keyof CQuotesPdf>[] => {
    return [
        {
            id: 'filename',
            headerProps: {
                children: l('Document.documentName'),
            },
            mapCellProps: 'filename',
        },
        {
            id: 'createdBy',
            headerProps: {
                children: l('LaneGroup.createdBy'),
            },
            mapCellProps: 'createdBy',
        },
        {
            id: 'createdOn',
            headerProps: {
                children: l('Accounts.createdOn'),
            },
            mapCellProps: 'createdOn',
            componentName: 'DateTimeCell',
        },
        {
            id: 'attachmentUrl',
            mapCellProps: 'attachmentUrl',
            componentName: 'DownloadDocument',
            width: '50px',
        },
    ]
}

export default columns
