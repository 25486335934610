import React, {
    useMemo,
} from 'react'

import useGetContainerTypes from 'app/Apps/ContainerManagement/ContainerTypes/hooks/useGetContainerTypes'
import {
    OrderTemperatureRange,
} from 'app/types/enums'
import {
    RequiredContainersInternal,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import StatusHandler from 'app/shared-components/StatusHandler'

import RequiredContainersByContainerTypeList from './RequiredContainersByContainerTypeList'

type OrderTemperatureRangeWithoutBT = Omit<OrderTemperatureRange, 'BT'>

type Props = {
    value?: RequiredContainersInternal[],
    disabled?: boolean,
    required?: boolean,
    temperatureRange?: OrderTemperatureRangeWithoutBT,
    onChange: (changeValue: RequiredContainersInternal[]) => void,
}

const defaultProps = {
    value: [],
    disabled: false,
    required: true,
    temperatureRange: undefined,
}

const RequiredContainersByContainerTypeListContainer = ({
    value,
    onChange,
    disabled,
    required,
    temperatureRange,
}: Props) => {
    const applicableTempRange: OrderTemperatureRangeWithoutBT[]
        | undefined = useMemo(() => {
            if (!temperatureRange || temperatureRange === OrderTemperatureRange.ANY_TEMP_RANGE) {
                return undefined
            }

            return [temperatureRange]
        }, [temperatureRange])

    const {
        containerTypes,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetContainerTypes({
        includeFilters: {
            applicableTempRange,
        },
        sortField: 'containerModel',
        sortType: 'asc',
    })

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <RequiredContainersByContainerTypeList
                value={value}
                onChange={onChange}
                disabled={disabled}
                required={required}
                containerTypes={containerTypes}
                temperatureRange={temperatureRange}
            />
        </StatusHandler>
    )
}

RequiredContainersByContainerTypeListContainer.defaultProps = defaultProps

export default RequiredContainersByContainerTypeListContainer
