import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    DamageType, ExpectedContainerStatus,
} from '../DamageTypes.types'

const getDamageTypeCreateFields = (getAllowedValues): DTOCardFormField<keyof DamageType>[] => {
    return [{
        title: 'Damage Type Details',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'name',
                        labelKey: 'DamageTypes.damageTypeName',
                        required: true,
                        componentName: 'InputSingleline',
                    },
                    {
                        name: 'expectedContainerStatus',
                        labelKey: 'DamageTypes.expectedContainerStatus',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(ExpectedContainerStatus),
                        required: true,
                        useDropdown: true,
                    },
                    {
                        name: 'expectedFixingDays',
                        labelKey: 'DamageTypes.expectedFixingDays',
                        componentName: 'InputSingleline',
                    },
                    {
                        name: 'blocksQcPassed',
                        labelKey: 'DamageTypes.blocksQcPassed',
                        componentName: 'Checkbox',
                    },
                ],
            },
            {
                id: 'column2',
                fields: [{
                    name: 'technicalDrawings',
                    labelKey: 'DamageTypes.technicalDrawings',
                    componentName: 'AttachmentsObjectSelector',
                }],
            },
            {
                id: 'column3',
                fields: [{
                    name: 'pictures',
                    labelKey: 'DamageTypes.pictures',
                    componentName: 'AttachmentsObjectSelector',
                }],
            },
            {
                id: 'column4',
                fields: [{
                    name: 'comment',
                    labelKey: 'DamageTypes.comment',
                    componentName: 'LongText',
                }],
            },
        ],
    }]
}

export default getDamageTypeCreateFields
