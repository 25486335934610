import {
    useCallback,
} from 'react'
import {
    useQueryClient,
} from '@tanstack/react-query'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    Account,
} from 'app/Apps/AccountManagement/Accounts/Account.types'

import AccountManagementRequests from '../AccountManagement.request'

const useGetAccount = (id) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getAccount],
        })
    }, [queryClient])

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<Account>({
        key: RequestKeys.getAccount,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(AccountManagementRequests.get),
        enabled: Boolean(id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetAccount
