import React, {
    useMemo,
} from 'react'
import noop from 'lodash/noop'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    getUrlsFromTableConfig,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useHasPermission from 'app/hooks/useHasPermission'

import tableConfig from './RateCards.config'
import RateCardsBulkEdit from './RateCardsBulkEdit'

export default function RateCards() {
    const updatePermissions = useHasPermission(['rates_update'])

    const tableControls: TableControlTypes[] = useMemo(() => {
        const result = [
            TableControlTypes.ClearFilters,
            TableControlTypes.ClearSorting,
        ]

        if (updatePermissions) {
            result.push(TableControlTypes.BulkEdit)
        }

        return result
    }, [updatePermissions])

    return (
        <SkyNetDomain
            name={tableConfig.name}
            subdomainUrls={getUrlsFromTableConfig(tableConfig)}
            actionComponents={{
                BulkEdit: updatePermissions ? RateCardsBulkEdit : undefined,
            }}
        >
            <SkyNetTable
                onRowClick={noop}
                tableControls={tableControls}
                tableConfig={tableConfig}
            />
        </SkyNetDomain>
    )
}
