import React, {
    useCallback, useEffect, useState,
} from 'react'
import isEqual from 'lodash/isEqual'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import Button from 'app/shared-components/Button'

import QuoteInformationForm from '../../QuoteInformationForm'
import {
    AQuote,
} from '../../AQuotes.types'
import useStyles from '../AQuoteCreate.styles'
import useAQuoteInformationContext from '../AQuoteInformationContext'

const AQuoteInformationCreate = ({
    data,
    disabled,
}: {
    data: AQuote,
    disabled: boolean
}) => {
    const {
        classes,
    } = useStyles()
    const {
        activeStep,
        setActiveStep,
        setValue,
        initialValue,
        setIsEdited,
    } = useAQuoteInformationContext()

    const [
        formValues,
        setFormValues,
    ] = useState<AQuote>(data)

    const nextAction = useCallback(() => {
        setValue(formValues)
        setActiveStep(activeStep + 1)
    }, [
        formValues,
        setValue,
        setActiveStep,
        activeStep,
    ])

    useEffect(() => {
        if (!isEqual(data, formValues)) {
            setIsEdited(true)
        }
    }, [
        setIsEdited,
        formValues,
        data,
    ])

    const isCreate = !initialValue

    return (
        <QuoteInformationForm
            disabled={disabled}
            value={formValues}
            setValue={setFormValues}
            omitField={[
                'customerQuotation',
                'pricingResponsible',
                'plannedContractBasis',
                'skyCellContact',
            ]}
            render={({
                fields, formData, onChange, isValid,
            }) => {
                return (
                    <div>
                        <DomainObjectForm
                            name="AQuoteInformation"
                            value={formData}
                            fields={fields}
                            onChange={onChange}
                            disabled={disabled}
                        />
                        {isCreate && (
                            <div className={classes.buttonWrapper}>
                                <Button
                                    label="Next"
                                    onClick={nextAction}
                                    className={classes.button}
                                    disabled={disabled || !isValid}
                                    data-testid="btn-next"
                                />
                            </div>
                        )}
                    </div>
                )
            }}
        />
    )
}

export default AQuoteInformationCreate
