import {
    SkyNetTableConfig, RangeType, SkyNetTableField, injectFieldsToTabs,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    SkyNetTableTab,
} from 'app/shared-components/SkyNetTable/types/skyNetTable.types'

import {
    CommonRoutes,
} from '../../SkyNetRoutes'

import LoggerType, {
    LoggerStatus,
    LoggerTable,
    LoggerMode,
} from './Loggers.types'

type LoggerTypeValue =
    Exclude<LoggerType, LoggerType.SKYCELL | LoggerType.CARTASENSE_M | LoggerType.MR_813
        | LoggerType.CL_FRIGGA_V5_PLUS | LoggerType.MR_816>
type LoggerTypeConfig = { name: string, url: string }
type LoggerTypeTab = Record<LoggerTypeValue, LoggerTypeConfig>

const loggerTypeTabMapper: LoggerTypeTab = {
    [LoggerType.SKYCELL_SAVY_BLE]: {
        name: 'SkyCell Savy Logger',
        url: 'skycellsavylogger',
    },
    [LoggerType.CARTASENSE]: {
        name: 'CartaSense Logger',
        url: 'cartasenselogger',
    },
    [LoggerType.MR_810T]: {
        name: 'MR 810 T',
        url: 'mr810t',
    },
    [LoggerType.MR_812P]: {
        name: 'MR 812 P',
        url: 'mr812p',
    },
    [LoggerType.CL_TT_7100]: {
        name: 'CL TT-7100',
        url: 'cltt7100',
    },
    [LoggerType.MR_814]: {
        name: 'MR 814',
        url: 'mr814',
    },
    [LoggerType.MR_815]: {
        name: 'MR 815',
        url: 'mr815',
    },
}

export default (): SkyNetTableConfig<keyof LoggerTable> => {
    const nameColumn: SkyNetTableField<keyof LoggerTable> = {
        name: 'loggerNumber',
        reduced: true,
    }
    const stateColumn: SkyNetTableField<keyof LoggerTable> = {
        name: 'loggerStatus',
        componentName: 'EnumValue',
        allowedValuesEnum: LoggerStatus,
        filterField: 'loggerStatus',
        filterType: FilterType.Array,
    }
    const otherColumns: SkyNetTableField<keyof LoggerTable>[] = [
        {
            name: 'loggerMode',
            componentName: 'EnumValue',
            labelKey: 'Logger.loggerMode',
            allowedValuesEnum: LoggerMode,
            filterField: 'loggerMode',
            filterType: FilterType.Array,
        },
        {
            name: 'locationName',
            filterField: 'locationName',
            labelKey: 'Logger.lastLocation',
            filterType: FilterType.Array,
        },
        {
            name: 'gatewayImeiMac',
            labelKey: 'Gateway.gatewayImeiMac',
            sorting: false,
        },
        {
            name: 'loggerType',
            componentName: 'EnumValue',
            allowedValuesEnum: LoggerType,
            filterField: 'loggerType',
            filterType: FilterType.Array,
        },
        {
            name: 'loggerQrCode',
            width: '370px',
        },
        {
            name: 'containerSerialNumber',
            sorting: false,
        },
        {
            name: 'lastMeasuredOnUtc',
            componentName: 'TemperatureAge',
            rangeType: RangeType.DayRange,
            sorting: false,
        },
        {
            name: 'batteryVoltage',
        },
        {
            name: 'rentingCompany',
            labelKey: 'Logger.rentingCompany',
            sorting: false,
            filterField: 'rentingCompany',
            filterType: FilterType.Array,
        },
        {
            name: 'loggerAge',
            componentName: 'LoggerAgeFromDate',
            labelKey: 'ContainerView.loggerExchangedOnUtcAmbient',
            mapCellProps: 'createdOn',
            sorting: false,
        },
    ]
    const defaultFields: SkyNetTableField<keyof LoggerTable>[] = [
        ...[nameColumn],
        ...[stateColumn],
        ...otherColumns,
    ]

    const excludeTab: SkyNetTableTab<keyof LoggerTable> = {
        name: 'Excluded',
        url: CommonRoutes.EXCLUDED,
        requestUrl: 'all',
        includeFilters: {
            loggerStatus: [LoggerStatus.DECOMMISSIONED],
        },
        fields: [
            ...[nameColumn],
            ...otherColumns,
        ],
    }

    return {
        name: 'LoggersTable',
        tabs: injectFieldsToTabs<keyof LoggerTable>([
            {
                name: 'Loggers',
                url: CommonRoutes.ALL,
                filters: {
                    loggerStatusExcluded: [LoggerStatus.DECOMMISSIONED],
                },
            },
            ...Object.entries(loggerTypeTabMapper).map(([
                logger,
                {
                    name, url,
                },
            ]: [
            LoggerTypeValue,
            LoggerTypeConfig
        ]) => {
                const filters = {
                    filters: {
                        loggerStatusExcluded: [LoggerStatus.DECOMMISSIONED],
                    },
                    includeFilters: {
                        loggerType: [logger],
                    },
                }

                return {
                    name,
                    url,
                    requestUrl: 'all',
                    ...filters,
                }
            }),
            excludeTab,
        ], defaultFields),
    }
}
