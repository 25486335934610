import {
    useCallback,
    useState,
} from 'react'

export default function useFormError() {
    const [
        errors,
        setErrors,
    ] = useState({})

    const setFormErrors = useCallback(([
        key,
        error,
    ]) => {
        if (error?.length > 0) {
            setErrors((prev) => {
                return {
                    ...prev,
                    [key]: error,
                }
            })
            return
        }

        setErrors((prev) => {
            const next = {
                ...prev,
            }

            delete next[key]
            return next
        })
    }, [setErrors])

    return {
        errors,
        setErrors: setFormErrors,
    }
}
