import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import Button from 'app/shared-components/Button'

import {
    SkyNetConfirmationProps,
} from '../SkyNetConfirmation/SkyNetConfirmation.types'

import useStyles from './ConfirmationDialog.style'

const defaultProps = {
    dialogTitle: '',
    dialogContent: '',
    positiveLabel: 'yes',
    isPositiveAlert: false,
    positiveLabelDisabled: undefined,
    negativeLabel: 'no',
    isLoading: false,
    children: null,
    classNames: {},
    customButtons: undefined,
}

export default function ConfirmationDialog({
    open,
    handleClose,
    dialogTitle,
    dialogContent,
    positiveLabel,
    isPositiveAlert,
    positiveLabelDisabled,
    negativeLabel,
    isLoading,
    children,
    classNames,
    customButtons,
}: Readonly<Omit<SkyNetConfirmationProps, 'children'> & {
    open: boolean,
    handleClose: (p: boolean) => void,
    children?: JSX.Element,
    isLoading?: boolean,
}>) {
    const {
        classes,
        cx,
    } = useStyles()

    return (
        <Dialog
            open={Boolean(open)}
            onClose={() => { return handleClose(false) }}
            disableEscapeKeyDown
            onClick={(event) => { event.stopPropagation() }}
            classes={{
                paper: classNames.paper || classes.paper,
            }}
        >
            <DialogTitle classes={{
                root: cx(classes.title, classNames.title),
            }}
            >
                {dialogTitle.toUpperCase()}
            </DialogTitle>
            <DialogContent className={cx(classes.dialogContent, classNames.content)}>
                {dialogContent && (
                    <DialogContentText>
                        {dialogContent}
                    </DialogContentText>
                )}
                {children}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    label={positiveLabel}
                    onClick={() => { handleClose(true) }}
                    saving={isLoading}
                    disabled={positiveLabelDisabled}
                    alert={isPositiveAlert}
                />
                {(customButtons || []).map((customButton) => {
                    return (
                        <div
                            key={`custom-button-${customButton.props.name}`}
                        >
                            {customButton}
                        </div>
                    )
                })}
                <Button
                    label={negativeLabel}
                    secondary
                    onClick={() => { handleClose(false) }}
                    disabled={isLoading}
                />
            </DialogActions>
        </Dialog>
    )
}

ConfirmationDialog.defaultProps = defaultProps
