import {
    Currency,
    RegionSecondLevel,
} from 'app/types/enums'
import {
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import {
    LeaseTypePossibilityStatus, RateCardsData,
    QuotationLevel, TemperatureRangeCQuote, ProductTypeCQuote,
} from '../../CQuotes.types'

export enum CollServiceTypeSimplifiedCQuote {
    COLLECTION = 'COLLECTION',
    DROPOFF_SKYCELL = 'DROPOFF_SKYCELL',
}

export type SimplifiedCQuoteCreateType = {
    pricingAccount: string,
    temperatureRange: TemperatureRangeCQuote,
    productType: ProductTypeCQuote,
    originQuotationLevel: QuotationLevel,
    destinationQuotationLevel: QuotationLevel,
    originAirport: {id: number},
    destinationAirport: {id: number},
    originRegion: RegionSecondLevel,
    destinationRegion: RegionSecondLevel,
    originCountry: {id: number},
    originState?: {id: number},
    destinationCountry: {id: number},
    destinationState?: {id: number},
    allowedLeaseType: LeaseTypePossibilityStatus,
    originPositioningFtl: boolean,
    originPositioningLtl: boolean,
    destinationCollectionLtl: boolean,
    originTruckCapacity: number,
    deliveryServiceType: DeliveryServiceType,
    collectionServiceType: CollServiceTypeSimplifiedCQuote,
    setRateCardData: (val: RateCardsData) => void,
    rateCardsData: RateCardsData,
    currency: Currency,
    skycellContact: {id: number},
    contactNameAndSurname: string,
    contactEmail: string,
    companyName: string,
    companyAddress: string,
    rentalDays: number,
    logisticsDays: number,
    plannedPaymentTerms: number,
}

export type StepperData = {
    firstStep: Partial<SimplifiedCQuoteCreateType>,
    secondStep: Partial<SimplifiedCQuoteCreateType>,
    thirdStep: Partial<SimplifiedCQuoteCreateType>,
    forthStep: Partial<SimplifiedCQuoteCreateType>,
}

export type RequestFeesData = {
    originRegion: RegionSecondLevel,
    destinationRegion: RegionSecondLevel,
    deliveryServiceType: DeliveryServiceType,
    collectionServiceType: CollServiceTypeSimplifiedCQuote,
    currency: Currency,
    productType: ProductTypeCQuote,
    rentalDays: number,
    logisticsDays: number,
}
