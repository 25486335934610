import React, {
    useCallback, useState, useMemo,
} from 'react'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import Button from 'app/shared-components/Button'
import {
    AQuote, RequestedLane,
} from '../../AQuotes.types'
import useStyles from '../AQuoteCreate.styles'
import useAQuoteInformationContext from '../AQuoteInformationContext'
import QuotationOptionsTable from './QuotationOptionsTable'

const QuotationOptions = ({
    actionQuote,
    data,
}: {
    actionQuote: (a: AQuote) => void,
    data: AQuote
}) => {
    const {
        classes,
    } = useStyles()
    const [
        value,
        setValue,
    ] = useState(data?.requestedLanes as unknown as (RequestedLane & {baseLeaseDays: string})[])
    const [
        pending,
        setPending,
    ] = useState<boolean>(false)

    const {
        activeStep, setActiveStep, initialValue, value: {
            requestedOpportunities,
        },
    } = useAQuoteInformationContext()

    const isCreate = !initialValue

    const handleChange = useCallback((updates) => {
        setValue((prev) => {
            return [...prev.map((lane) => {
                const {
                    id, ...restUpdates
                } = updates

                if (id === lane.accountLane.id) {
                    return {
                        ...lane,
                        ...restUpdates,
                    }
                }
                return lane
            })]
        })
    }, [])

    const nextAction = useCallback(async () => {
        setPending(true)
        await actionQuote({
            ...data,
            requestedLanes: value.map((lane) => {
                return {
                    ...lane,
                    baseLeaseDays: (lane.baseLeaseDays || '').split(';').reduce((acc, el) => {
                        if (!isEmpty(el)) {
                            acc.push(Number.parseInt(el, 10))
                        }
                        return acc
                    }, []),
                }
            }),
        })

        setPending(false)
    }, [
        actionQuote,
        data,
        value,
    ])

    const backAction = useCallback(() => {
        setActiveStep(activeStep - 1)
    }, [
        setActiveStep,
        activeStep,
    ])

    const tableValues = useMemo(() => {
        return value.map((el) => {
            return {
                ...el,
                ...el.accountLane,
                productTypes: requestedOpportunities?.find((requestedOpportunity) => {
                    return requestedOpportunity.opportunity.opportunityNumber
                        === el.accountLane.opportunity
                })?.productTypes,
            }
        })
    }, [
        value,
        requestedOpportunities,
    ])

    const renderButtonPanel = useCallback(({
        isValid,
    }) => {
        return (
            <div
                className={clsx(classes.buttonWrapper, classes.doubleButtons)}
            >
                <Button
                    label={isCreate ? 'Create' : 'Update'}
                    onClick={nextAction}
                    className={classes.button}
                    disabled={!isValid || pending}
                />
                <Button
                    label="Back"
                    secondary
                    onClick={backAction}
                    className={classes.button}
                />
            </div>
        )
    }, [
        classes.buttonWrapper,
        classes.doubleButtons,
        classes.button,
        isCreate,
        nextAction,
        backAction,
        pending,
    ])

    return (
        <QuotationOptionsTable
            data={tableValues}
            onChange={handleChange}
            renderButtonPanel={renderButtonPanel}
        />
    )
}

export default QuotationOptions
