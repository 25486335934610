import {
    FieldConfig,
    SkyNetSpreadSheetCellType,
    SkyNetSpreadSheetConfigType,
} from 'app/shared-components/SkyNetSpreadSheet'
import {
    Fee,
} from 'app/Apps/Pricing/Pricing.types'
import {
    ContractCurrency,
    Currency,
} from 'app/types/enums'
import defaultCurrency from 'app/utils/currency'
import {
    FeeCells,
} from '../Fee.types'

const getFeesSpreadSheetConfig = ({
    feeNameOptions,
    productTypesOptions,
    getChargeabilityOptions,
    showProductTypeSelect,
    showPercentage,
    showFeeValue,
    currency,
    currencyConverter,
    getLabel,
}: {
    feeNameOptions: Record<string, string>,
    productTypesOptions: Record<string, string>,
    getChargeabilityOptions: (value: Fee) => [any, string][]
    showProductTypeSelect: (value: Fee) => boolean,
    showPercentage: (value: Fee) => boolean,
    showFeeValue: (value: Fee) => boolean,
    currency: ContractCurrency
    currencyConverter: (value: Fee) => number,
    getLabel: (...args: any[]) => string,
}): SkyNetSpreadSheetConfigType<keyof FeeCells> => {
    const isDefaultCurrency = currency === defaultCurrency.default

    const defaultFields: FieldConfig<keyof FeeCells>[] = [
        {
            name: 'feeName',
            title: getLabel('Fee.feeName'),
            width: 200,
            required: true,
            allowedValues: feeNameOptions,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
        },
        {
            name: 'feeChargeability',
            title: getLabel('Fee.feeChargeability'),
            width: 250,
            required: true,
            getAllowedValues: getChargeabilityOptions,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
        },
        {
            name: 'productType',
            title: getLabel('Fee.productType'),
            width: 120,
            required: true,
            allowedValues: productTypesOptions,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
                conditionalType: [[
                    showProductTypeSelect,
                    SkyNetSpreadSheetCellType.NO_VALUE,
                ]],
            },
        },
        {
            name: 'feeDescription',
            title: getLabel('Fee.feeDescription'),
            width: 300,
        },
    ]

    const optionalFields = isDefaultCurrency ? [{
        name: 'feeValue',
        title: currency,
        width: 75,
        required: true,
        cellConfig: {
            type: SkyNetSpreadSheetCellType.NUMBER,
            conditionalType: [
                [
                    showFeeValue,
                    SkyNetSpreadSheetCellType.NO_VALUE,
                ],
                [
                    showPercentage,
                    SkyNetSpreadSheetCellType.PERCENTAGE,
                ],
            ],
        },
    }] as FieldConfig<keyof FeeCells>[] : [
        {
            name: 'calcEUR',
            title: Currency.EUR,
            disabled: true,
            width: 75,
            calcValue: currencyConverter, // calculated on front-end, won't be sent to server
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                conditionalType: [
                    [
                        showFeeValue,
                        SkyNetSpreadSheetCellType.NO_VALUE,
                    ],
                    [
                        showPercentage,
                        SkyNetSpreadSheetCellType.NO_VALUE,
                    ],
                ],
            },
        },
        {
            name: 'feeValue',
            title: currency,
            width: 75,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                conditionalType: [
                    [
                        showFeeValue,
                        SkyNetSpreadSheetCellType.NO_VALUE,
                    ],
                    [
                        showPercentage,
                        SkyNetSpreadSheetCellType.PERCENTAGE,
                    ],
                ],
            },
        },
    ] as FieldConfig<keyof FeeCells>[]

    return {
        id: 'Fees',
        rowsCounter: true,
        emptyRows: 1,
        addRowMenuOption: true,
        enableRangeSelection: true,
        enableRowSelection: true,
        stickyTopRows: 1,
        headerRow: true,
        fields: [
            ...defaultFields,
            ...optionalFields,
            {
                name: 'id',
                width: 1,
                hidden: true,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.ID,
                },
            },
        ],
    }
}

export default getFeesSpreadSheetConfig
