import React, {
    useMemo,
} from 'react'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import getTableConfig from './AQuotes.config'
import requests from './AQuotes.requests'
import AQuoteCreate from './AQuoteCreate'
import AssignQuote from './AssignQuote'
import AQuoteOverview from './AQuoteOverview'
import AQuotePdf from './AQuotePdf'
import {
    AQuote, AQuotesTab, QuoteStatus,
} from './AQuotes.types'
import LanesToBeGrouped from './LanesToBeGrouped'
import AssignPricing from './AssignPricing'
import AQuoteAwardRejectLanes from './AQuoteAwardRejectLanes'
import AQuoteUpdate from './AQuoteUpdate'

const quoteStatusCondition = ({
    quoteStatus,
    linkedContractBasis,
}: AQuote = {} as AQuote) => {
    return Boolean(quoteStatus)
        && !(quoteStatus === QuoteStatus.CREATED
            || (quoteStatus === QuoteStatus.CANCELLED && !(linkedContractBasis?.id)))
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const defaultProps = {
    isCreateFormEnabled: true,
}

const AQuotes = ({
    isCreateFormEnabled,
}: {
    isCreateFormEnabled?: boolean,
}) => {
    const accountCreate = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])
    const accountUpdate = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])
    const aQuoteLaneGroup = useHasPermission(['account_quote_lane_group'])
    const aQuoteLaneAwardReject = useHasPermission(['account_quote_lane_award_reject'])
    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: CommonRoutes.OVERVIEW,
                Component: AQuoteOverview,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Data Update',
                url: CommonRoutes.DATA_UPDATE,
                Component: AQuoteUpdate,
            },
            permission: accountUpdate,
        },
        {
            tab: {
                label: 'Assign Quote',
                url: AQuotesTab.AssignQuote,
                Component: AssignQuote,
            },
            permission: aQuoteLaneGroup,
        },
        {
            tab: {
                label: 'Review/Group',
                url: AQuotesTab.ReviewGroup,
                Component: LanesToBeGrouped,
                condition: quoteStatusCondition,
                activeComparer: (pathname) => {
                    return pathname.split('/').includes(AQuotesTab.ReviewGroup)
                },
            },
            permission: aQuoteLaneGroup,
        },
        {
            tab: {
                label: 'Assign Pricing',
                url: AQuotesTab.AssignPricing,
                Component: AssignPricing,
                condition: quoteStatusCondition,
                activeComparer: (pathname) => {
                    return pathname.split('/').includes(AQuotesTab.AssignPricing)
                },
            },
            permission: aQuoteLaneGroup,
        },
        {
            tab: {
                label: 'Award/Reject',
                url: AQuotesTab.AwardReject,
                Component: AQuoteAwardRejectLanes,
                activeComparer: (pathname) => {
                    return pathname.split('/').includes(AQuotesTab.AwardReject)
                },
            },
            permission: aQuoteLaneAwardReject,
        },
        {
            tab: {
                label: 'Quote PDF',
                url: AQuotesTab.QuotePdf,
                Component: AQuotePdf,
                condition: quoteStatusCondition,
            },
            permission: true,
        },
    ])

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            name={tableConfig.name}
            getDomainObject={requests.get}
            sidebarTabs={tabs}
            defaultTab="overview"
            domainRequestKey={RequestKeys.getAQuote}
            actionComponents={{
                Create: isCreateFormEnabled && accountCreate ? AQuoteCreate : null,
            }}
        >
            <SkyNetTable
                customUrl={requests.getAll}
                moduleName={requests.domainName}
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>

    )
}

AQuotes.defaultProps = defaultProps

export default AQuotes
