import React from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import useLoadDtoForSkyNetTable from 'app/shared-components/SkyNetTable/hooks/useLoadDtoForSkyNetTable'
import ContactRequests from '../Contacts.request'

import columns from './ContactChangeSummary.columns'
import useStyles from './ContactChangeSummary.style'
import {
    Modification,
} from './ContactChangeSummary.types'

type Props = {
    id: number
}

const ContactChangeSummary = ({
    id,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const load: (params: any) => Promise<{
        items: Modification,
        matches: number
    }> = useLoadDtoForSkyNetTable(
        ContactRequests.getContactsChangeHistory.request(id),
    )

    return (
        <Card
            title="Change History"
        >
            <RichTable
                configName="ContactChangeSummaryTable"
                name={ContactRequests.name}
                load={load}
                columns={columns}
                uniqField="id"
                className={classes.tableContent}
                classNames={{
                    contentWrapper: classes.tableContentWrapper,
                }}
            />
        </Card>
    )
}

export default ContactChangeSummary
