import {
    tss,
} from 'app/tss'

const deliveryServiceType = 'deliveryServiceType'
const handoverPoint = 'handoverPoint'
const collectionServiceType = 'collectionServiceType'
const collectionDropoffPoint = 'collectionDropoffPoint'

export default tss.create(({
    theme,
}) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
        gridCardWrapper: {
            display: 'grid',
            gap: theme.spacing(2),
            gridTemplateAreas: `
                "${deliveryServiceType} ${collectionServiceType}" 
                "${handoverPoint} ${collectionDropoffPoint}"
            `,
            gridTemplateColumns: 'repeat(2, 2fr) !important',
            [theme.breakpoints.down('xl')]: {
                gridTemplateColumns: 'repeat(2, 2fr)',
            },
        },
        deliveryServiceType: {
            gridArea: deliveryServiceType,
        },
        handoverPoint: {
            gridArea: handoverPoint,
        },
        collectionServiceType: {
            gridArea: collectionServiceType,
        },
        collectionDropoffPoint: {
            gridArea: collectionDropoffPoint,
        },
    }
})
