import React, {
    useMemo, useCallback,
} from 'react'
import isEmpty from 'lodash/isEmpty'

import DataContentWrapper from 'app/shared-components/DataContentWrapper'
import SkyNetSpreadSheet from 'app/shared-components/SkyNetSpreadSheet'

import {
    ForecastData, ForecastDataSpreadsheetColumnsConfig, ForecastSpreadsheetDataTypeEnum,
} from '../../Forecast.types'

import getConfig from './SingleForecastSpreadsheet.config'

const SingleForecastSpreadsheet = ({
    value,
    onSpreadsheetChange,
    tmpFields,
    disabled = false,
}: Readonly<{
    disabled: boolean,
    value: Record<string, any>[],
    onSpreadsheetChange: (...a: ForecastData[]) => void,
    tmpFields: ForecastDataSpreadsheetColumnsConfig,
}>) => {
    const isDisabled = useCallback((item: Record<string, any>) => {
        return disabled || item.dataType === ForecastSpreadsheetDataTypeEnum.ACTUALS
    }, [disabled])

    const config = useMemo(() => {
        return getConfig({
            tmpFields,
            isDisabled,
        })
    }, [
        tmpFields,
        isDisabled,
    ])

    return (
        <DataContentWrapper isEmpty={isEmpty(value)}>
            <SkyNetSpreadSheet
                value={value}
                config={config}
                onChange={onSpreadsheetChange}
                disabled={disabled}
            />
        </DataContentWrapper>
    )
}

export default SingleForecastSpreadsheet
