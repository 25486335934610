import React, {
    useCallback,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import useGetCountry from '../hooks/useGetCountry'

import CountryOverview from './CountryOverview'

const CountryOverviewContainer = ({
    id,
}: { id: number }) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetCountry(id)

    const loadData = useCallback(() => {
        return Promise.resolve({
            data: data?.states,
        })
    }, [data])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <CountryOverview
                value={data}
                load={loadData}
                editable={false}
            />
        </StatusHandler>

    )
}

export default CountryOverviewContainer
