import React, {
    useCallback,
    useEffect, useMemo, useState,
} from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import SkyNetStepperButtons from 'app/shared-components/SkyNetStepper/SkyNetStepperButtons'
import useValidation from 'app/hooks/useValidation/useValidation'
import useEnumValues from 'app/hooks/useEnumValues'

import useSimplifiedCQuoteCreateContext from '../../SimplifiedCQuoteCreateContext'
import {
    CollServiceTypeSimplifiedCQuote,
    SimplifiedCQuoteCreateType,
} from '../../simplifiedCQuoteCreate.types'
import useGetFeesData from '../../../hooks/useGetFeesData'

import getFields from './offerSetup.fields'

const OfferSetup = ({
    index,
}: {
    index: number,
}) => {
    const getAllowedValues = useEnumValues()
    const {
        activeStep,
        setActiveStep,
        setValue,
        value,
        rateCardsData,
        setFeesData,
    } = useSimplifiedCQuoteCreateContext()

    const [
        isFetchFeesData,
        setIsFetchFeesData,
    ] = useState(false)

    useEffect(() => {
        if (value.secondStep.collectionServiceType
            === CollServiceTypeSimplifiedCQuote.DROPOFF_SKYCELL) {
            return setValue({
                ...value,
                thirdStep: {
                    ...value?.thirdStep,
                    logisticsDays: rateCardsData?.logisticsDays,
                },
            })
        }

        return setValue({
            ...value,
            thirdStep: {
                ...value?.thirdStep,
                logisticsDays: 0,
            },
        })
    }, []) // eslint-disable-line

    const {
        data: feesData,
        isFetching,
        isError,
    } = useGetFeesData(isFetchFeesData ? {
        originRegion: value.firstStep.originRegion,
        destinationRegion: value.firstStep.destinationRegion,
        deliveryServiceType: value.secondStep.deliveryServiceType,
        collectionServiceType: value.secondStep.collectionServiceType,
        currency: value.thirdStep.currency,
        productType: value.firstStep.productType,
        rentalDays: value.thirdStep.rentalDays,
        logisticsDays: value.thirdStep.logisticsDays,
    } : null)

    useEffect(() => {
        if (feesData && activeStep === index) {
            setFeesData(feesData)
            setActiveStep(activeStep + 1)
            setIsFetchFeesData(false)
        }
        if (isError) {
            setIsFetchFeesData(false)
        }
    }, [
        activeStep,
        feesData,
        index,
        isError,
        setActiveStep,
        setFeesData,
    ])

    const allowedCurrency = rateCardsData?.currencies.reduce((acc, item) => {
        return {
            ...acc,
            [item]: item,
        }
    }, {})

    const fields = useMemo(() => {
        return getFields({
            getAllowedValues,
            allowedCurrency,
            plannedPaymentTerms: rateCardsData?.plannedPaymentTerms,
        })
    }, [
        allowedCurrency,
        getAllowedValues,
        rateCardsData?.plannedPaymentTerms,
    ])

    const onChange = useCallback((newVal: Partial<SimplifiedCQuoteCreateType>) => {
        setValue({
            ...value,
            thirdStep: newVal,
        })
    }, [
        setValue,
        value,
    ])

    const handleNextAction = () => {
        setIsFetchFeesData(true)
    }

    const editAction = useCallback(() => {
        setActiveStep(index)
        setValue({
            ...value,
            forthStep: {},
        })
    }, [
        index,
        setActiveStep,
        setValue,
        value,
    ])

    const backAction = useCallback(() => {
        setActiveStep(activeStep - 1)
        setValue({
            ...value,
            thirdStep: {},
        })
    }, [
        setActiveStep,
        activeStep,
        setValue,
        value,
    ])

    const {
        isValid,
    } = useValidation({
        fields,
        values: value.thirdStep,
    })

    return (
        <>
            <DomainObjectForm
                value={value.thirdStep}
                onChange={onChange}
                fields={fields}
                disabled={activeStep > index}
                name="OfferSetup"
            />
            <SkyNetStepperButtons
                isValid={isValid}
                disabled={activeStep !== 2}
                nextLabel="Continue"
                nextAction={handleNextAction}
                canEdit={activeStep > index}
                editAction={editAction}
                backAction={backAction}
                isLoading={isFetching}
                canGoBack
            />
        </>
    )
}

export default OfferSetup
