import {
    tss,
} from './config'

export default tss.create(({
    theme,
}) => {
    return {
        // use to center the link inside cell
        tableLink: {
            paddingTop: theme.spacing(0.5),
            paddingLeft: theme.spacing(0.5),
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        },
    }
})
