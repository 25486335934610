import {
    LaneStatus,
} from 'app/types/enums'

import {
    AQuote,
} from '../AQuotes.types'

import useGetAccountLanesByOpportunities from './useGetAccountLanesByOpportunities'
import useGetLanesByQuote from './useGetLanesByQuote'

const useGetLanesQuoteStepper = (aQuote: AQuote) => {
    const {
        data: lanesByOpportunities,
        isDataReady: isDataReadyLanesByOpportunities,
    } = useGetAccountLanesByOpportunities({
        opportunities: aQuote.requestedOpportunities.map(({
            opportunity,
        }) => {
            return opportunity?.opportunityNumber
        }),
        leaseType: aQuote.leaseType,
        laneStatus: LaneStatus.TO_BE_QUOTED,
        quoteId: aQuote?.id,
    })

    const {
        data: lanesByQuote,
        isDataReady: isDataReadyLanesByQuote,
    } = useGetLanesByQuote({
        opportunityIds: aQuote.requestedOpportunities.map(({
            opportunity,
        }) => {
            return opportunity.id
        }),
        leaseType: aQuote.leaseType,
        quoteId: aQuote?.id,
    })

    return {
        data: aQuote?.id ? lanesByQuote : lanesByOpportunities,
        isDataReady: aQuote?.id ? isDataReadyLanesByQuote : isDataReadyLanesByOpportunities,
    }
}

export default useGetLanesQuoteStepper
