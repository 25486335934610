import React, {
    useRef, useEffect, useState, useCallback,
} from 'react'
import {
    Compatible,
} from '@silevis/reactgrid'
import useOutsideClick from 'app/hooks/useOutsideClick'
import useClickHandler from 'app/hooks/useClickHandler'

import SelectableElement from '../../SelectableElement'
import useSkyNetSpreadSheetContext from '../../SkyNetSpreadSheetContext'
import MultipleSelectDropdown from '../../MultipleSelectDropdown'
import {
    SpreadsheetCellOption,
} from '../../SkyNetSpreadSheet.types'

import {
    MultipleSelectCell as MultipleSelectCellType,
} from './MultipleSelectCellTemplate'
import useStyles from './MultipleSelectCell.styles'

const MultipleSelectCell = ({
    cell,
    options,
    onChange,
}: {
    cell: Compatible<MultipleSelectCellType>,
    options: SpreadsheetCellOption[],
    onChange: (...args: any[]) => void
}) => {
    const [
        selected,
        setSelected,
    ] = useState<string[]>(cell.selectedValue)
    const {
        classes,
    } = useStyles()
    const wrapperRef = useRef(null)

    const {
        forceEditQuit,
        setFocusChanging,
        rowHeight,
    } = useSkyNetSpreadSheetContext()

    useEffect(() => {
        setFocusChanging(false)

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setFocusChanging(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const saveChanges = useCallback(() => {
        onChange({
            selected,
        })
    }, [
        onChange,
        selected,
    ])

    const saveAndQuit = useCallback(() => {
        setSelected((prev) => {
            onChange({
                selected: prev,
            })
            return [...prev]
        })
        forceEditQuit()
    }, [
        forceEditQuit,
        onChange,
    ])

    useOutsideClick(wrapperRef, saveAndQuit)
    useClickHandler(['Esc'], forceEditQuit)
    useClickHandler(['Enter'], saveChanges)

    return (
        <div
            ref={wrapperRef}
            className={classes.wrapper}
        >
            <SelectableElement
                text={cell.text}
                id={cell.id}
                name={cell.name}
                editMode
                onClick={saveChanges}
            />
            <MultipleSelectDropdown
                rowHeight={rowHeight}
                options={options}
                selected={selected}
                setSelected={setSelected}
            />
        </div>

    )
}

export default MultipleSelectCell
