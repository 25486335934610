import React, {
    useCallback,
    useEffect, useMemo,
} from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useGetLocation from 'app/Apps/ContactManagement/Locations/hooks/useGetLocation'
import useGetCountry from 'app/Apps/ContactManagement/Countries/hooks/useGetCountry'
import SkyNetStepperButtons from 'app/shared-components/SkyNetStepper/SkyNetStepperButtons'
import useValidation from 'app/hooks/useValidation/useValidation'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import useEmbargoValidations from 'app/Apps/AccountManagement/CQuotes/CQuoteCreateSelectMethod/hooks/useEmbargoValidations'
import useGetRateCardsData from 'app/Apps/Rates/RateCards/hooks/useGetRateCardsData'
import {
    LeaseTypePossibilityStatus, ProductTypeCQuote, QuotationLevel,
} from 'app/Apps/AccountManagement/CQuotes/CQuotes.types'
import useStyles from 'app/Apps/AccountManagement/CQuotes/CQuoteCreateSelectMethod/QuotationDetails.styles'

import useSimplifiedCQuoteCreateContext from '../../SimplifiedCQuoteCreateContext'
import {
    SimplifiedCQuoteCreateType,
} from '../../simplifiedCQuoteCreate.types'

import getFields from './quotationDetails.fields'

const MSG = 'For information only. NO Lanes or Lane Fees will be created as a result of this quote document generation.'
const PRODUCT_TYPE_MAP = {
    C: ProductTypeCQuote.VAL_1500XCOL,
    CRT: ProductTypeCQuote.VAL_1500XCRT,
}

const QuotationDetails = ({
    index,
}: {
    index: number,
}) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const {
        activeStep,
        setActiveStep,
        setValue,
        value,
        setIsEdited,
        setRateCardsData,
    } = useSimplifiedCQuoteCreateContext()

    const {
        data: originAirportData,
    } = useGetLocation(value.firstStep.originAirport?.id)

    const {
        data: originCountryData,
    } = useGetCountry(value.firstStep.originCountry?.id, false)

    const {
        data: destinationAirportData,
    } = useGetLocation(value?.firstStep?.destinationAirport?.id)
    const {
        data: destinationCountryData,
    } = useGetCountry(value.firstStep.destinationCountry?.id, false)

    const {
        embargoOrigin,
        embargoDestination,
        validateEmbargoOrigin,
        validateEmbargoDestination,
    } = useEmbargoValidations({
        origin: value.firstStep.originCountry?.id,
        destination: value.firstStep.destinationCountry?.id,
    })

    const {
        data: rateCardData,
    } = useGetRateCardsData(!embargoOrigin?.embargoed && !embargoDestination?.embargoed ? {
        originRegion: value.firstStep.originRegion,
        destinationRegion: value.firstStep.destinationRegion,
        productType: value.firstStep.productType,
    } : null)

    const needSelectOriginState = useMemo(() => {
        return Boolean(originCountryData)
         && !originCountryData?.regionSecondLevel
    }, [originCountryData])

    const needSelectDestinationState = useMemo(() => {
        return Boolean(destinationCountryData)
            && !destinationCountryData?.regionSecondLevel
    }, [destinationCountryData])

    // set originRegion:
    // if originState is selected than get regionSecondLevel from originState
    // if no need to select state, then get regionSecondLevel from originCountry
    useEffect(() => {
        if (originCountryData
            && value.firstStep.originCountry
            && value?.firstStep?.originQuotationLevel === QuotationLevel.COUNTRY) {
            let newRegionSecondLevel = originCountryData.regionSecondLevel

            if (needSelectOriginState && value.firstStep.originState) {
                newRegionSecondLevel = originCountryData.states.find(({
                    id,
                }) => {
                    return id === Number(value.firstStep.originState?.id)
                })?.regionSecondLevel
            }
            if (value.firstStep.originRegion !== newRegionSecondLevel) {
                setValue({
                    ...value,
                    firstStep: {
                        ...value.firstStep,
                        originRegion: newRegionSecondLevel,
                    },
                })
            }
        }
        // reset originRegion and originState if originCountry is none
        if (!value.firstStep.originCountry
            && value.firstStep.originQuotationLevel === QuotationLevel.COUNTRY
            && (value.firstStep.originRegion || value.firstStep.originState)
        ) {
            setValue({
                ...value,
                firstStep: {
                    ...value.firstStep,
                    originRegion: null,
                    originState: null,
                },
            })
        }
    }, [
        needSelectOriginState,
        originCountryData,
        setValue,
        value,
    ])

    // set destinationRegion:
    // if destinationState is selected than get regionSecondLevel from destinationState
    // if no need to select state, then get regionSecondLevel from destinationCountry
    useEffect(() => {
        if (destinationCountryData
            && value.firstStep.destinationCountry
            && value?.firstStep?.destinationQuotationLevel === QuotationLevel.COUNTRY) {
            let newRegionSecondLevel = destinationCountryData.regionSecondLevel

            if (needSelectDestinationState && value.firstStep.destinationState) {
                newRegionSecondLevel = destinationCountryData.states.find(({
                    id,
                }) => {
                    return id === Number(value.firstStep.destinationState?.id)
                })?.regionSecondLevel
            }
            if (value.firstStep.destinationRegion !== newRegionSecondLevel) {
                setValue({
                    ...value,
                    firstStep: {
                        ...value.firstStep,
                        destinationRegion: newRegionSecondLevel,
                    },
                })
            }
        }
        // reset destinationRegion and destinationState if destinationCountry is none
        if (!value.firstStep.destinationCountry
            && value.firstStep.destinationQuotationLevel === QuotationLevel.COUNTRY
            && (value.firstStep.destinationRegion || value.firstStep.destinationState)
        ) {
            setValue({
                ...value,
                firstStep: {
                    ...value.firstStep,
                    destinationRegion: null,
                    destinationState: null,
                },
            })
        }
    }, [
        needSelectDestinationState,
        destinationCountryData,
        setValue,
        value,
    ])

    useEffect(() => {
        // set originCountry and originRegion if originAirport is selected
        if (
            originAirportData && value.firstStep.originAirport
            && value.firstStep.originCountry?.id !== originAirportData.country.id
        ) {
            setValue({
                ...value,
                firstStep: {
                    ...value.firstStep,
                    originCountry: originAirportData.country,
                    originRegion: originAirportData.regionSecondLevel,
                },
            })
        }

        // reset originCountry and originRegion if originAirport is none
        if (!value.firstStep.originAirport
            && value.firstStep.originQuotationLevel === QuotationLevel.IATA
            && (value.firstStep.originCountry || value.firstStep.originRegion)) {
            setValue({
                ...value,
                firstStep: {
                    ...value.firstStep,
                    originCountry: null,
                    originRegion: null,
                    originState: null,
                },
            })
        }
    }, [
        originAirportData,
        originAirportData?.country,
        setValue,
        value,
    ])

    useEffect(() => {
        // set destinationCountry and destinationRegion if destinationAirport is selected
        if (
            destinationAirportData && value.firstStep.destinationAirport
            && value.firstStep.destinationCountry?.id !== destinationAirportData.country.id
        ) {
            setValue({
                ...value,
                firstStep: {
                    ...value.firstStep,
                    destinationCountry: destinationAirportData.country,
                    destinationRegion: destinationAirportData.regionSecondLevel,
                },
            })
        }
        // reset originCountry and originRegion if originAirport is none
        if (!value.firstStep.destinationAirport
            && value.firstStep.destinationQuotationLevel === QuotationLevel.IATA
            && (value.firstStep.destinationCountry || value.firstStep.destinationRegion)) {
            setValue({
                ...value,
                firstStep: {
                    ...value.firstStep,
                    destinationCountry: null,
                    destinationRegion: null,
                    destinationState: null,
                },
            })
        }
    }, [
        destinationAirportData,
        setValue,
        value,
    ])

    const onChange = (newVal: Partial<SimplifiedCQuoteCreateType>) => {
        setValue((prevState) => {
            if (newVal?.temperatureRange !== prevState?.firstStep?.temperatureRange) {
                return {
                    ...value,
                    firstStep: {
                        ...newVal,
                        productType: PRODUCT_TYPE_MAP[newVal?.temperatureRange],
                    },
                }
            }

            // reset auto populated data when selection is changed
            if (newVal?.originQuotationLevel !== prevState?.firstStep?.originQuotationLevel) {
                return {
                    ...value,
                    firstStep: {
                        ...newVal,
                        originAirport: null,
                        originCountry: null,
                        originRegion: null,
                        originState: null,
                    },
                }
            }

            if (newVal?.destinationQuotationLevel
                !== prevState?.firstStep?.destinationQuotationLevel) {
                return {
                    ...value,
                    firstStep: {
                        ...newVal,
                        destinationAirport: null,
                        destinationCountry: null,
                        destinationRegion: null,
                        destinationState: null,
                    },
                }
            }

            return {
                ...value,
                firstStep: newVal,
            }
        })

        setIsEdited(true)
    }

    const fields = useFieldsWithClassName(getFields({
        getAllowedValues,
        originQuotationLevel: value?.firstStep?.originQuotationLevel,
        destinationQuotationLevel: value?.firstStep?.destinationQuotationLevel,
        validateEmbargoOrigin,
        validateEmbargoDestination,
        rateCardData,
        disabled: activeStep > index,
        classes,
        originCountryData,
        needSelectOriginState,
        destinationCountryData,
        needSelectDestinationState,
    }), classes)

    const {
        isValid,
    } = useValidation({
        fields,
        values: value.firstStep,
    })

    const handleNextAction = useCallback(() => {
        setRateCardsData(rateCardData)
        setActiveStep(activeStep + 1)
    }, [
        setRateCardsData,
        rateCardData,
        setActiveStep,
        activeStep,
    ])

    const editAction = useCallback(() => {
        setActiveStep(index)
        setValue({
            ...value,
            secondStep: {},
            thirdStep: {},
            forthStep: {},
        })
    }, [
        index,
        setActiveStep,
        setValue,
        value,
    ])

    const isFormValid = useMemo(() => {
        return isValid && !embargoOrigin?.embargoed && !embargoDestination?.embargoed
            && rateCardData?.leaseTypePossibilityStatus
            === LeaseTypePossibilityStatus.DIRECT_LEASE_POSSIBLE
    }, [
        embargoDestination?.embargoed,
        embargoOrigin?.embargoed,
        isValid,
        rateCardData?.leaseTypePossibilityStatus,
    ])

    return (
        <>
            <div className={classes.msgContainer}>
                <img
                    src="assets/images/warning_orange.svg"
                    alt="warning"
                />
                <div>
                    <span className={classes.warningText}>DISCLAIMER:</span>
                    {MSG}
                </div>
            </div>
            <DomainObjectForm
                value={value.firstStep}
                onChange={onChange}
                fields={fields}
                disabled={activeStep > index}
                className={classes.gridWrapper}
                name="GeneralStep"
            />
            <SkyNetStepperButtons
                isValid={isFormValid}
                disabled={activeStep !== 0}
                nextLabel="Continue"
                nextAction={handleNextAction}
                canEdit={activeStep > index}
                editAction={editAction}
            />
        </>
    )
}

export default QuotationDetails
