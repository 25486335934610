import {
    FormPart,
} from 'app/types/form.types'
import {
    LaneStatus,
} from 'app/types/enums'

import {
    CollServiceType,
    DeliveryServiceType, InvoiceSettingsType, Lane,
} from '../../lanes.types'

import positioningFields from './positioning.fields'
import laneGroupFields from './laneGroup.fields'
import laneInformationFields from './laneInformation.fields'
import originFields from './origin.fields'
import reverseLogisticsFields from './reverseLogistics.fields'
import proformaInvoiceFields from './proformaInvoice.fields'

const getFields = ({
    deliveryServiceType,
    isDropOffSkyCell,
    reqCustomsCoord,
    originalStatus,
    getAllowedValues,
}: {
    deliveryServiceType: DeliveryServiceType,
    isDropOffSkyCell: boolean,
    reqCustomsCoord: boolean,
    originalStatus: LaneStatus,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
}): FormPart<keyof (Lane & InvoiceSettingsType)>[] => {
    return [
        laneGroupFields(getAllowedValues),
        {
            title: 'Customer (Quotation)',
            className: 'customerQuotation',
            fields: [
                {
                    name: 'customerQuotation',
                    componentName: 'LocationObjectSelector',
                    disabled: true,
                    labelKey: 'LaneOpsInformationDto.customerQuotation',
                },
                {
                    name: 'customerQuotationContacts',
                    componentName: 'MultipleContactObjectSelector',
                    disabled: true,
                    labelKey: 'LaneOpsInformationDto.customerQuotationContacts',
                },
            ],
        },
        {
            title: 'Consignee',
            className: 'consignee',
            fields: [
                {
                    name: 'consigneeProvisional',
                    componentName: 'InputSingleline',
                    disabled: true,
                    labelKey: 'LaneOpsInformationDto.consigneeProvisional',
                },
                {
                    name: 'consignee',
                    componentName: 'LocationObjectSelector',
                    disabled: true,
                    labelKey: 'LaneOpsInformationDto.consignee',
                },
                {
                    name: 'consigneeContacts',
                    componentName: 'MultipleContactObjectSelector',
                    disabled: true,
                    labelKey: 'LaneOpsInformationDto.consigneeContacts',
                },
            ],
        },
        {
            title: 'Customer (Execution)',
            className: 'customerExecution',
            fields: [
                {
                    name: 'customerExecution',
                    componentName: 'LocationObjectSelector',
                    disabled: true,
                    labelKey: 'LaneOpsInformationDto.customerExecution',
                },
                {
                    name: 'customerExecutionContactsOps',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'LaneOpsInformationDto.customerExecutionContactsOps',
                    required: true,
                },
            ],
        },
        laneInformationFields({
            originalStatus,
            getAllowedValues,
        }),
        proformaInvoiceFields(getAllowedValues),
        originFields({
            isDeliveryRoad: deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD,
            getAllowedValues,
        }),
        positioningFields({
            deliveryServiceType,
            getAllowedValues,
        }),
        {
            title: 'Destination',
            className: 'destination',
            fields: [
                {
                    name: 'destinationAirport',
                    componentName: 'LocationObjectSelector',
                    disabled: true,
                    labelKey: 'LaneOpsInformationDto.destAirport',
                },
                {
                    name: 'collectionServiceType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(CollServiceType),
                    disabled: true,
                },
                {
                    name: 'collectionDropoffPoint',
                    componentName: 'LocationExpandedViewObjectSelector',
                    disabled: true,
                    labelKey: 'LaneOpsInformationDto.collectionDropoffPoint',
                },
                {
                    name: 'collectionDropoffPointContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'LaneOpsInformationDto.collectionDropoffPointContacts',
                },
            ],
        },
        reverseLogisticsFields({
            reqCustomsCoord,
            isDropOffSkyCell,
        }),
    ]
}

export default getFields
