import React, {
    useMemo,
    useRef,
    useState,
} from 'react'

import Card from 'app/shared-components/Card'
import StatusHandler from 'app/shared-components/StatusHandler'

import {
    AllowedProductTypes, ContainerAvailabilityForecastContainerStatuses,
    ForecastAvailabilityOptions,
} from '../../serviceCenters.types'
import useGetAvailability from '../../hooks/useGetAvailability'

import useStyles from './ContainerAvailabilityForecast.styles'
import ContainerAvailabilityForecastChart from './ContainerAvailabilityForecastChart'
import OptionPanel from './OptionPanel'

const ContainerAvailabilityForecast = ({
    id,
    defaultProductType,
    productTypes,
}: {
    id: number,
    defaultProductType: AllowedProductTypes,
    productTypes: Record<string, string>,
}) => {
    const {
        classes,
    } = useStyles()
    const allowedValues = {
        ALL: 'ALL',
        ...productTypes,
    }
    const [
        value,
        setValue,
    ] = useState<ForecastAvailabilityOptions>({
        containerStatus: {
            [ContainerAvailabilityForecastContainerStatuses.ToBeProcessed]: true,
            [ContainerAvailabilityForecastContainerStatuses.InProcess]: false,
            [ContainerAvailabilityForecastContainerStatuses.Processed]: false,
            [ContainerAvailabilityForecastContainerStatuses.InPreconditioning]: false,
            [ContainerAvailabilityForecastContainerStatuses.Preconditioned]: true,
        },
        productType: defaultProductType,
    })

    const [
        includesOrders,
        setIncludesOrders,
    ] = useState<{
        notStarted: boolean,
        preBookings: boolean,
    }>({
        notStarted: false,
        preBookings: false,
    })

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetAvailability({
        serviceCenter: {
            id,
        },
        includeNotStartedOrders: includesOrders.notStarted,
        includePreBookings: includesOrders.preBookings,
    })

    const listStatuses = useRef(Object.keys(value.containerStatus))

    const dataChart = useMemo(() => {
        return data?.forecastsByProduct?.find(({
            productType,
        }) => {
            return productType === value.productType
        })
    }, [
        data?.forecastsByProduct,
        value.productType,
    ])

    return (
        <Card
            contentClass={classes.cardContent}
            data-testid="availability-forecast-card"
            title="Container Availability Forecast"
        >
            <OptionPanel
                value={value}
                onChange={setValue}
                allowedValues={allowedValues}
                includesOrders={includesOrders}
                setIncludesOrders={setIncludesOrders}
            />
            <StatusHandler
                isSuccess={isDataReady}
                isFetching={isFetching}
                isError={isError}
                error={error}
            >
                <ContainerAvailabilityForecastChart
                    value={dataChart?.forecast}
                    listStatuses={listStatuses.current}
                    containerStatus={value.containerStatus}
                    minPreconditioned={dataChart?.minPreconditioned}
                />
            </StatusHandler>
        </Card>
    )
}

export default ContainerAvailabilityForecast
