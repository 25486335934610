import {
    useMemo,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'

import useEnumValues from 'app/hooks/useEnumValues'
import {
    LaneGroupTemperatureRange, ProductType,
} from 'app/types/enums'

import getFields, {
    LaneInfo,
} from '../PreBookingLaneDetails.fields'

import useGetProductTypesForTempRange from './useGetProductTypesForTempRange'

const useGetPreBookingLaneDetailsFields = ({
    disabled,
    laneSelected,
    tempRange,
    requestedHandover,
    lane,
    showGeneralInfo,
}:{
    disabled: boolean,
    laneSelected: boolean,
    tempRange?: LaneGroupTemperatureRange,
    requestedHandover?: string,
    lane?: LaneInfo,
    showGeneralInfo?: boolean,
}):{
    fields: any,
    isDataReady: boolean,
    allowedProductTypes: ProductType[],
    isError: boolean,
    error: Record<string, any>,
    isFetching: boolean
} => {
    const {
        pathname,
    } = useLocation()

    const laneLink = pathname.replace('pre-bookings', 'lanes').replace(/\/\d+\//, `/${lane?.id}/`)
    const getAllowedValues = useEnumValues()
    const {
        productTypes: allowedProductTypes,
        isDataReady,
        isError,
        error,
        isFetching,
    } = useGetProductTypesForTempRange({
        tempRange,
    })

    const fields = useMemo(() => {
        return getFields({
            getAllowedValues,
            disabled,
            hasLane: laneSelected,
            allowedProductTypes,
            requestedHandoverSelected: Boolean(requestedHandover),
            lane: {
                ...lane,
                link: laneLink,
            },
            showGeneralInfo,
        })
    }, [
        disabled,
        getAllowedValues,
        allowedProductTypes,
        laneSelected,
        requestedHandover,
        lane,
        laneLink,
        showGeneralInfo,
    ])

    return {
        fields,
        isDataReady,
        allowedProductTypes,
        isError,
        error,
        isFetching,
    }
}

export default useGetPreBookingLaneDetailsFields
