import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    ExpectedContainerStatus,
    DamageType,
} from './DamageTypes.types'

export default (): SkyNetTableConfig<keyof DamageType> => {
    return {
        name: 'DamageType',
        tabs: [{
            name: 'All',
            url: 'all',
            fields: [
                {
                    name: 'name',
                    labelKey: 'DamageTypes.damageTypeName',
                    reduced: true,
                },
                {
                    name: 'expectedContainerStatus',
                    labelKey: 'DamageTypes.expectedContainerStatus',
                    allowedValuesEnum: ExpectedContainerStatus,
                    componentName: 'EnumValue',
                    filterField: 'expectedContainerStatus',
                    filterType: FilterType.Array,

                },
                {
                    name: 'expectedFixingDays',
                    labelKey: 'DamageTypes.expectedFixingDays',
                    filterField: 'expectedFixingDays',
                    filterType: FilterType.Array,
                },
                {
                    name: 'comment',
                    labelKey: 'DamageTypes.comment',
                },
                {
                    name: 'blocksQcPassed',
                    labelKey: 'DamageTypes.blocksQcPassed',
                    componentName: 'ChipCheckMark',
                },
            ],
        }],
    }
}
