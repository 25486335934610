import {
    tss,
} from 'app/tss'

export default tss.create(({
    theme,
}) => {
    return {
        root: {
            marginBottom: theme.spacing(3),
        },
        wrapper: {
            display: 'flex',
        },
        title: {
            '&&': {
                marginBottom: theme.spacing(2),
            },
        },
    }
})
