import {
    useMemo, useEffect,
} from 'react'
import useRequest, {
    RequestFn,
    RequestKeys,
} from 'app/hooks/useRequest'
import config from 'app/Apps/ContainerManagement/Containers/Containers.request'
import {
    ContainerStatus,
} from 'app/types/enums'
import useEnumLabels from 'app/hooks/useEnumLabels'

export default function useGetAllowedContainerStatusTransitions({
    serialNumber,
    currentStatus,
}: {
    serialNumber: string,
    currentStatus: ContainerStatus,
}) {
    const getLabels = useEnumLabels()
    const {
        data,
        isDataReady,
        invalidate,
    } = useRequest<{
        allowedStatusTransitions: ContainerStatus[];
    }>({
        key: RequestKeys.getAllowedContainerStatusTransitions,
        enabled: Boolean(serialNumber),
        requestFunc: RequestFn.getEntity(config.getAllowedContainerStatusTransitions),
        params: {
            serialNumber,
        },
    })

    useEffect(() => {
        if (currentStatus) {
            invalidate()
        }
    }, [
        invalidate,
        currentStatus,
    ])

    return useMemo(() => {
        if (isDataReady && data?.allowedStatusTransitions) {
            return getLabels(data?.allowedStatusTransitions || [])
        }

        return {}
    }, [
        isDataReady,
        data?.allowedStatusTransitions,
        getLabels,
    ])
}
