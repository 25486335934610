import {
    FieldConfig, isNegativeNumber,
    SkyNetSpreadSheetCellType, SkyNetSpreadSheetConfigType,
} from 'app/shared-components/SkyNetSpreadSheet'
import {
    BaseLeaseCategory, Currency, ProductType,
} from 'app/types/enums'
import {
    BaseLeaseTable,
} from '../BaseLease.types'

export default function BaseLeaseSpreadsheetConfig({
    getLabel,
    getAllowedValues,
}: {
    getLabel: (s: string) => string,
    getAllowedValues: (type: Record<string, string>) => Record<string, string>,
}): SkyNetSpreadSheetConfigType<keyof BaseLeaseTable> {
    const fields: FieldConfig<keyof BaseLeaseTable>[] = [
        {
            name: 'pricingAccountCompanyName',
            title: getLabel('LaneFeesRateCard.pricingAccountName'),
            width: 150,
            disabled: true,
        },
        {
            name: 'baseLeaseCategory',
            title: getLabel('BaseLease.baseLeaseCategory'),
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            width: 150,
            allowedValues: getAllowedValues(BaseLeaseCategory),
            noneOption: false,
            disabled: true,
        },
        {
            name: 'productType',
            title: getLabel('BaseLease.productType'),
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            width: 100,
            allowedValues: getAllowedValues(ProductType),
            noneOption: false,
            disabled: true,
        },
        {
            name: 'currency',
            title: getLabel('BaseLease.currency'),
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            width: 150,
            allowedValues: getAllowedValues(Currency),
            noneOption: false,
            disabled: true,
        },
        {
            name: 'baseFeeValue',
            title: getLabel('BaseLease.base'),
            width: 200,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                validate: isNegativeNumber('baseFeeValue'),
            },
        },
        {
            name: 'additionalPeriodOne',
            title: getLabel('BaseLease.additionalPeriod1'),
            required: true,
            width: 200,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                validate: isNegativeNumber('additionalPeriodOne'),
            },
        },
        {
            name: 'additionalPeriodTwo',
            title: getLabel('BaseLease.additionalPeriod2'),
            required: true,
            width: 200,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                validate: isNegativeNumber('additionalPeriodTwo'),
            },
        },
        {
            name: 'additionalPeriodThree',
            title: getLabel('BaseLease.additionalPeriod3'),
            required: true,
            width: 200,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                validate: isNegativeNumber('additionalPeriodThree'),
            },
        },
        {
            name: 'id',
            width: 1,
            hidden: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.ID,
            },
        },
    ]

    return {
        id: 'BaseLease',
        title: 'Standard Base Lease',
        headerRow: true,
        rowsCounter: true,
        headerHeight: 60,
        addRowMenuOption: false,
        enableRangeSelection: true,
        enableRowSelection: true,
        stickyTopRows: 1,
        filterable: true,
        rowsLimit: 25,
        emptyRows: 0,
        initialFocusLocation: {
            columnId: 'baseLeaseCategory',
            rowId: 0,
        },
        fields,
    }
}
