import {
    useMutation,
} from '@tanstack/react-query'
import moment from 'moment'
import {
    useDispatch,
} from 'react-redux'

import {
    useJWTToken,
} from 'app/Auth'
import downloadDocument from 'app/utils/downloadDocument'
import requestConfig from 'app/Apps/AccountManagement/CQuotes/CQuoteCreateSelectMethod/SimplifiedCQuoteCreate/simplifiedCQuotes.request'
import {
    ProductTypeCQuote,
    QuotationLevel,
} from 'app/Apps/AccountManagement/CQuotes/CQuotes.types'
import {
    RegionSecondLevel,
    Currency,
} from 'app/types/enums'
import {
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    failedToLoadSaveForm,
    startToSaveForm, successedToSaveForm,
} from 'app/shared-components/FormStatuses/store/formStatuses.slice'
import simpleFullResponseApi from 'app/services/simpleApi/simpleFullResponseApi'
import {
    BEErrorResponse,
} from 'app/types/request.types'

import {
    CollServiceTypeSimplifiedCQuote,
} from '../../../simplifiedCQuoteCreate.types'

type RequestData = {
    originLocation: {
        quotationLevel: QuotationLevel,
        airport: {
            id: number
        },
        country: {
            id: number,
        },
        regionSecondLevel: RegionSecondLevel,
        state?: {id: number},
    },
    destinationLocation: {
        quotationLevel: QuotationLevel,
        airport: {
            id: number
        },
        country: {
            id: number
        },
        regionSecondLevel: RegionSecondLevel,
        state?: {id: number},
    },
    deliveryServiceType: DeliveryServiceType,
    collectionServiceType: CollServiceTypeSimplifiedCQuote,
    productType: ProductTypeCQuote,
    currency: Currency,
    rentalDays: number,
    logisticsDays: number,
    customer: {
        companyName: string,
        companyAddress: string,
        contactNameAndSurname: string,
        contactEmail: string
    },
    skycellContact: {
        id: number,
    }
}

const useGenerateSimplifiedCQuote = (requestData: RequestData) => {
    const token = useJWTToken()
    const dispatch = useDispatch()

    return useMutation({
        mutationFn: () => {
            dispatch(
                startToSaveForm({
                    id: 'GenerateSimplifiedCQuote',
                    title: 'Please stand by while we’re preparing the PDF.',
                }),
            )

            return simpleFullResponseApi({
                ...requestConfig.generateSimplifiedCQuote(requestData),
                responseType: 'arraybuffer',
                data: requestData,
                token,
            })
        },
        onSuccess: (data: unknown) => {
            const fileName = requestData?.customer?.companyName.replace(/\s/g, '_')

            downloadDocument(
                data,
                `${moment().format('YYYYMMDD')}_Indicative_Pricing_${fileName}`,
            )
            dispatch(
                successedToSaveForm({
                    id: 'GenerateSimplifiedCQuote',
                    title: 'The Quote PDF have been successfully downloaded.',
                }),
            )
        },
        onError: (err: BEErrorResponse) => {
            const enc = new TextDecoder('utf-8')

            const {
                response: {
                    data: errors,
                    status: statusCode,
                },
            } = err

            dispatch(
                failedToLoadSaveForm({
                    id: 'GenerateSimplifiedCQuote',
                    errors: JSON.parse(enc.decode(errors)),
                    statusCode,
                }),
            )
        },
    })
}

export default useGenerateSimplifiedCQuote
