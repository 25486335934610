import React, {
    useState, useMemo, useCallback,
} from 'react'
import SkyNetStepper from 'app/shared-components/SkyNetStepper'
import HistoryBlockPrompt from 'app/shared-components/HistoryBlockPrompt'
import useBeforeUnloadDialog from 'app/hooks/useBeforeUnloadDialog'
import useAccountManagementRoutes from 'app/Apps/AccountManagement/hooks/useAccountManagementRoutes'
import {
    CQuoteInformationContext, TCQuoteStepper,
} from './CQuoteInformationContext'
import CQuoteSelectLane from './Steps/CQuoteSelectLane'
import CQuoteLaneDetails from './Steps/CQuoteLaneDetails'
import CQuoteDeliveryOptions from './Steps/CQuoteDeliveryOptions'
import CQuoteOfferSetup from './Steps/CQuoteOfferSetup'
import CQuoteFeesContactDetails from './Steps/CQuoteFeesContactDetails'
import useCQuoteCreate from './hooks/useCQuoteCreate'

export default function CQuoteCreateStepper() {
    const [
        value,
        setValue,
    ] = useState<TCQuoteStepper>({
        lane: {},
        laneDetails: {},
        deliveryOptions: {},
        contactDetails: {},
    } as TCQuoteStepper)
    const {
        CQuotes: CQuotesRoutes,
    } = useAccountManagementRoutes()

    const onSuccess = useCallback(({
        id,
    }: {id: number}) => {
        CQuotesRoutes.Overview.navigate({
            params: {
                key: id,
            },
        })
    }, [CQuotesRoutes.Overview])

    const createCQuoteHandler = useCQuoteCreate({
        onSuccess,
    })

    const isEdited = Boolean(value?.lane?.id || value?.laneDetails)
        && !(createCQuoteHandler.isPending || createCQuoteHandler.isSuccess)

    useBeforeUnloadDialog(isEdited)

    const [
        activeStep,
        setActiveStep,
    ] = useState<number>(0)

    const stepperSteps = useMemo(() => {
        return [
            {
                key: '1',
                label: 'Select Lane',
                expanded: true,
                renderContent: ({
                    index,
                }: Readonly<{ index: number }>) => { return <CQuoteSelectLane index={index} /> },
            },
            {
                key: '2',
                label: 'Lane Details',
                expanded: (step: number) => { return step > 0 },
                renderContent: ({
                    index,
                }: Readonly<{ index: number }>) => { return <CQuoteLaneDetails index={index} /> },
            },
            {
                key: '3',
                label: 'Delivery Options',
                expanded: (step: number) => { return step > 1 },
                renderContent: ({
                    index,
                }: Readonly<{ index: number }>) => {
                    return <CQuoteDeliveryOptions index={index} />
                },
            },
            {
                key: '4',
                label: 'Offer Setup',
                expanded: (step: number) => { return step > 2 },
                renderContent: ({
                    index,
                }: Readonly<{ index: number }>) => { return <CQuoteOfferSetup index={index} /> },
            },
            {
                key: '5',
                label: 'Fees and Contact Details',
                expanded: (step: number) => { return step > 3 },
                renderContent: ({
                    index,
                }: Readonly<{ index: number }>) => {
                    return (
                        <CQuoteFeesContactDetails
                            index={index}
                            onCreate={createCQuoteHandler}
                        />
                    )
                },
            },
        ]
    }, [createCQuoteHandler])

    const cQuoteInformationContext = useMemo(() => {
        return {
            activeStep,
            setActiveStep,
            value,
            setValue,
        }
    }, [
        activeStep,
        value,
        setValue,
    ])

    return (
        <CQuoteInformationContext.Provider value={cQuoteInformationContext}>
            <SkyNetStepper
                activeStep={activeStep}
                steps={stepperSteps}
            />
            <HistoryBlockPrompt
                when={isEdited}
                positiveLabel="Discard"
                isPositiveAlert
                promptText="You are about to leave this page, your progress will be lost. Would you like to discard your changes anyway?"
            />
        </CQuoteInformationContext.Provider>
    )
}
