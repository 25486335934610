import React, {
    useMemo,
    useState,
} from 'react'

import SkyNetStepper from 'app/shared-components/SkyNetStepper'
import HistoryBlockPrompt from 'app/shared-components/HistoryBlockPrompt'
import useBeforeUnloadDialog from 'app/hooks/useBeforeUnloadDialog'

import {
    FeesData,
    RateCardsData,
} from 'app/Apps/AccountManagement/CQuotes/CQuotes.types'
import QuotationDetails from './Steps/QuotationDetails'
import {
    SimplifiedCQuoteCreateContext,
} from './SimplifiedCQuoteCreateContext'
import {
    StepperData,
} from './simplifiedCQuoteCreate.types'
import DeliveryOptions from './Steps/DeliveryOptions'
import OfferSetup from './Steps/OfferSetup'
import FeesAndContactDetails from './Steps/FeesAndContactDetails'

const getSteps = () => {
    return [
        {
            key: '1',
            label: 'Quotation Details',
            expanded: true,
            renderContent: ({
                index,
            }: {
                index: number,
            }) => {
                return (
                    <QuotationDetails
                        index={index}
                    />
                )
            },
        },
        {
            key: '2',
            label: 'Delivery Options',
            expanded: (currentStep: number) => {
                return currentStep > 1
            },
            renderContent: ({
                index,
            }: {
            index: number,
            }) => {
                return (
                    <DeliveryOptions
                        index={index}
                    />
                )
            },
        },
        {
            key: '3',
            label: 'Offer Setup',
            expanded: (currentStep: number) => {
                return currentStep > 2
            },
            renderContent: ({
                index,
            }: {
            index: number,
            }) => {
                return (
                    <OfferSetup
                        index={index}
                    />
                )
            },
        },
        {
            key: '4',
            label: 'Fees and Contact Details',
            expanded: (currentStep: number) => {
                return currentStep > 3
            },
            renderContent: () => {
                return (
                    <FeesAndContactDetails />
                )
            },
        },
    ]
}

export default function SimplifiedCQuoteCreate() {
    const [
        value,
        setValue,
    ] = useState<StepperData>({
        firstStep: {},
        secondStep: {},
        thirdStep: {},
        forthStep: {},
    })

    const [
        activeStep,
        setActiveStep,
    ] = useState<number>(0)

    const [
        isEdited,
        setIsEdited,
    ] = useState<boolean>()

    const [
        rateCardsData,
        setRateCardsData,
    ] = useState<RateCardsData>()

    const [
        feesData,
        setFeesData,
    ] = useState<FeesData>()

    const simplifiedCQuoteCreateContext = useMemo(() => {
        return {
            activeStep,
            setActiveStep,
            value,
            setValue,
            setIsEdited,
            rateCardsData,
            setRateCardsData,
            feesData,
            setFeesData,
        }
    }, [
        activeStep,
        value,
        rateCardsData,
        feesData,
    ])

    useBeforeUnloadDialog(isEdited)

    const stepperSteps = useMemo(() => {
        return getSteps()
    }, [])

    return (
        <SimplifiedCQuoteCreateContext.Provider value={simplifiedCQuoteCreateContext}>
            <SkyNetStepper
                activeStep={activeStep}
                steps={stepperSteps}
            />
            <HistoryBlockPrompt
                when={isEdited}
                positiveLabel="Discard"
                isPositiveAlert
                promptText="You are about to leave this page, your progress will be lost. Would you like to discard your changes anyway?"
            />
        </SimplifiedCQuoteCreateContext.Provider>
    )
}
