import {
    RequestConfig,
} from 'app/types/request.types'

type AdditionalFeesRequests = {
    filterRequest: (params?: Record<string, any>) => RequestConfig,
    filterCount: ({
        fieldName,
    }: {fieldName: string}) => RequestConfig,
    bulkEdit: ({
        data,
    }: {
        data: Record<string, any>,
    }) => RequestConfig
}

const requests: AdditionalFeesRequests = {
    filterRequest: (params = {}) => {
        return {
            url: 'additionalfeeratecard/filter-request/all',
            method: 'POST',
            ...params,
        }
    },
    filterCount: ({
        fieldName,
    }: {
        fieldName: string
    }) => {
        return {
            url: `additionalfeeratecard/filter-request/all/count/${fieldName}`,
            method: 'POST',
            data: {
                rangeType: '',
            },
        }
    },
    bulkEdit: ({
        data,
    }) => {
        return {
            url: 'additionalfeeratecard/bulk',
            method: 'PATCH',
            data,
        }
    },
}

export default requests
