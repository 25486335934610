import {
    RequestConfig,
} from 'app/types/request.types'

type LocationAreasRequests = {
    domainName: string,
    create: ({
        locationId,
    }) => RequestConfig,
    update: ({
        id,
    }) => RequestConfig,
    getLocationArea: ({
        id,
    }) => RequestConfig,
}

const requests: LocationAreasRequests = {
    domainName: 'LocationAreas',
    create: ({
        locationId,
    }) => {
        return {
            url: `location/${locationId}/area`,
            method: 'POST',
        }
    },
    update: ({
        id,
    }) => {
        return {
            url: `area/${id}`,
            method: 'PATCH',
        }
    },
    getLocationArea: ({
        id,
    }) => {
        return {
            url: `area/${id}`,
            method: 'GET',
        }
    },
}

export default requests
