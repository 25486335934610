import {
    useMemo,
} from 'react'
import {
    App, CommonRoutes, useSkyNetNavigate,
} from 'app/SkyNetRoutes'
import {
    createRoutesConfig,
} from 'app/SkyNetRoutes/skyNetRoutes.utils'
import useCreateSkyNetDomainRoutes from 'app/shared-components/SkyNetDomain/useCreateSkyNetDomainRoutes'
import {
    SkyNetRoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'
import {
    AccountManagementDomains,
} from '../AccountManagement.types'
import {
    RegionalGroupsTabs,
} from '../RegionalGroups/regionalGroups.types'
import {
    AccountTabs,
} from '../Accounts/Account.types'
import {
    OpportunitiesTabs,
} from '../Opportunities/opportunities.types'
import {
    AQuotesTab,
} from '../AQuotes/AQuotes.types'
import {
    CQuotesTab,
} from '../CQuotes/CQuotes.types'
import {
    AssingPricingSubRoutes,
} from '../AQuotes/AssignPricing/AssingPricing.types'

export default function useAccountManagementRoutes() {
    const skyNetNavigate = useSkyNetNavigate()
    const SkyNetDomainRoutes = useCreateSkyNetDomainRoutes()()

    return useMemo(() => {
        const config: SkyNetRoutesConfig = {
            path: [App.ACCOUNT_MANAGEMENT],
            KamDashboard: {
                route: AccountManagementDomains.KamDashboard,
            },
            Accounts: {
                route: AccountManagementDomains.Accounts,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                RegionalGroups: {
                    route: AccountTabs.RegionalGroups,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Opportunities: {
                    route: AccountTabs.Opportunities,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Lanes: {
                    route: AccountTabs.Lanes,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Quotes: {
                    route: AccountTabs.Quotes,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                BillingCompanies: {
                    route: AccountTabs.BillingCompanies,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Addresses: {
                    route: AccountTabs.Addresses,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Contacts: {
                    route: AccountTabs.Contacts,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ActivityRecords: {
                    route: AccountTabs.ActivityRecords,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
            RegionalGroups: {
                route: AccountManagementDomains.RegionalGroups,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Opportunities: {
                    route: RegionalGroupsTabs.OPPORTUNITIES,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
            Opportunities: {
                route: AccountManagementDomains.Opportunitites,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Lanes: {
                    route: OpportunitiesTabs.LANES,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ContainerInformation: {
                    route: OpportunitiesTabs.CONTAINER_INFORMATION,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ChangeHistory: {
                    route: CommonRoutes.CHANGE_HISTORY,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Create: {
                    route: CommonRoutes.ASTERISK,
                    via: SkyNetDomainRoutes.All.Create,
                },
            },
            Lanes: {
                route: AccountManagementDomains.Lanes,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                    params: [{
                        name: 'copy',
                    }],
                },
                Copy: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                    params: [{
                        name: 'copy',
                    }],
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Create: {
                    route: CommonRoutes.ASTERISK,
                    via: SkyNetDomainRoutes.All.Create,
                },
            },
            AQuotes: {
                route: AccountManagementDomains.AQuotes,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                AssignQuote: {
                    route: AQuotesTab.AssignQuote,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ReviewGroup: {
                    route: AQuotesTab.ReviewGroup,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                AssignPricing: {
                    route: AQuotesTab.AssignPricing,
                    via: SkyNetDomainRoutes.All.Edit,
                    params: [{
                        name: 'laneGroupId',
                    }],
                    LaneFees: {
                        route: AssingPricingSubRoutes.LaneFees,
                        params: [
                            {
                                name: 'feesId',
                            },
                            {
                                name: 'feesAction',
                            },
                        ],
                    },
                },
                AwardReject: {
                    route: AQuotesTab.AwardReject,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                QuotePdf: {
                    route: AQuotesTab.QuotePdf,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Create: {
                    route: null,
                    via: SkyNetDomainRoutes.All.Create,
                },
            },
            CQuotes: {
                route: AccountManagementDomains.CQuotes,
                Create: {
                    route: null,
                    via: SkyNetDomainRoutes.All.Create,
                    params: [{
                        name: 'selectedMethod',
                        required: false,
                    }],
                },
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                AwardReject: {
                    route: CQuotesTab.AwardReject,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                QuotePdf: {
                    route: CQuotesTab.QuotePdf,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
            PreBookings: {
                route: AccountManagementDomains.PreBookings,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ChangeHistory: {
                    route: CommonRoutes.CHANGE_HISTORY,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [
        SkyNetDomainRoutes,
        skyNetNavigate,
    ])
}
