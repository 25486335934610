import React, {
    useCallback, useMemo,
} from 'react'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import useLabel from 'app/hooks/useLabel'
import useHasPermission from 'app/hooks/useHasPermission'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    ProductType,
} from 'app/types/enums'
import {
    OpportunityType,
} from 'app/Apps/AccountManagement/Opportunities/opportunities.types'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import useAccountManagementRoutes from 'app/Apps/AccountManagement/hooks/useAccountManagementRoutes'
import useTableStyles from 'app/tss/table.styles'
import useLinkStyles from 'app/tss/link.styles'
import {
    RequestedLane, RequestedOpportunity,
} from '../../AQuotes.types'
import useGetAccountLanesByOpportunities from '../../hooks/useGetAccountLanesByOpportunities'

import getColumns from './RequestedLanes.columns'

const RequestedLanes = ({
    requestedOpportunities,
    requestedLanes,
    aQuoteId,
    disabled,
}: {
    requestedOpportunities: (RequestedOpportunity & OpportunityType)[],
    requestedLanes: RequestedLane[],
    aQuoteId: number,
    disabled: boolean,
}) => {
    const {
        classes,
    } = useTableStyles()
    const {
        classes: linkClasses,
    } = useLinkStyles()
    const {
        AQuotes: AQuotesRoutes,
        Lanes: LanesRoutes,
    } = useAccountManagementRoutes()
    const l = useLabel()
    const getProductTypesAllowedValues = useEnumValues()(ProductType)
    const {
        data: lanes,
        isDataReady: lanesIsReady,
    } = useGetAccountLanesByOpportunities({
        opportunities: requestedOpportunities.map(({
            opportunityNumber,
        }) => {
            return opportunityNumber
        }),
    })

    const columns = useMemo(() => {
        return getColumns({
            l,
            laneStyle: linkClasses.tableLink,
            getLaneLink: (id) => {
                return id ? generateSkyNetLink({
                    domainPath: LanesRoutes.Overview.path,
                    params: {
                        key: id,
                    },
                }) : null
            },
        })
    }, [
        LanesRoutes,
        l,
        linkClasses.tableLink,
    ])

    const accountUpdate = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])

    const load = useCallback(() => {
        if (lanesIsReady) {
            const lanesTableData = lanes.reduce((acc, lane) => {
                const requestedLane = requestedLanes.find((el) => {
                    return el.accountLane?.id === lane.id
                })

                if (!requestedLane) {
                    return acc
                }

                return [
                    ...acc,
                    {
                        ...requestedLane,
                        productTypes: requestedOpportunities?.find((requestedOpportunity) => {
                            return requestedOpportunity.opportunityNumber
                                === lane.opportunity
                        })?.productTypes?.map((productType) => {
                            return getProductTypesAllowedValues[productType]
                        }),
                        ...lane,
                    },
                ]
            }, [])

            return Promise.resolve({
                data: lanesTableData,
            })
        }
        return Promise.resolve({
            data: [],
        })
    }, [
        lanesIsReady,
        lanes,
        requestedLanes,
        requestedOpportunities,
        getProductTypesAllowedValues,
    ])

    const moveToUpdateTab = () => {
        AQuotesRoutes.Update.navigate({
            params: {
                key: aQuoteId,
            },
            options: {
                state: {
                    path: 'overview',
                },
            },
        })
    }

    return (
        <Card
            className={classes.cardWrapper}
        >
            <div className={classes.headerWrapper}>
                <Typography variant="h3">
                    Requested Lanes
                </Typography>
                {(!disabled && accountUpdate) && (
                    <div
                        className={classes.actionWrapper}
                        data-testid="btn-add-lane"
                    >
                        <AddIcon />
                        <a
                            onKeyDown={moveToUpdateTab}
                            onClick={moveToUpdateTab}
                        >
                            Add Existing Lane
                        </a>
                    </div>
                )}
            </div>
            <RichTable
                configName="RequestedLanesTable"
                name="Requested Lanes"
                load={load}
                columns={columns}
                uniqField="id"
                className={classes.tableContent}
                classNames={{
                    contentWrapper: classes.tableContentWrapper,
                }}
            />
        </Card>
    )
}

export default RequestedLanes
