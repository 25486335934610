import {
    useCallback,
} from 'react'
import {
    useQuery,
} from '@tanstack/react-query'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'

const useGetEngagementByContact = (id: number) => {
    const token = useJWTToken()
    const queryFn = useCallback(() => {
        return simpleApi({
            url: `engagement/contact/${id}`,
            token,
            method: 'GET',
        })
    }, [
        token,
        id,
    ])

    const {
        data,
        isFetching,
        isError,
        error,
    } = useQuery({
        queryKey: [
            RequestKeys.getEngagementByContactId,
            id,
        ],
        queryFn,
        retry: 0,
        enabled: Boolean(id),
    })

    return {
        isDataReady: isError || Boolean(data),
        data,
        isFetching,
        isError,
        error,
    }
}

export default useGetEngagementByContact
