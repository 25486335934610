import React, {
    useCallback,
    useState, useMemo,
} from 'react'
import pick from 'lodash/pick'
import some from 'lodash/some'
import isEqual from 'lodash/isEqual'

import useEnumValues from 'app/hooks/useEnumValues'
import SkyNetStepperButtons from 'app/shared-components/SkyNetStepper/SkyNetStepperButtons'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useValidation from 'app/hooks/useValidation/useValidation'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import {
    DeliveryServiceType, CollServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import reduceEnumValues from 'app/utils/reduceEnumValues'

import useCQuoteInformationContext, {
    TCQuoteStepper,
} from '../../CQuoteInformationContext'
import getFields from './CQuoteDeliveryOptions.fields'
import useStyles from './CQuoteDeliveryOptions.styles'

export default function CQuoteDeliveryOptions({
    index,
}: Readonly<{index: number}>) {
    const getAllowedValues = useEnumValues()
    const {
        classes,
    } = useStyles()
    const {
        value: contextValue,
        setValue: setContextValue,
        setActiveStep,
        activeStep,
    } = useCQuoteInformationContext()

    const [
        value,
        setValue,
    ] = useState<TCQuoteStepper>(contextValue)

    const showDeliveryOptions = useMemo(() => {
        const {
            originPositioningFtl,
            originPositioningLtl,
        } = value?.rateCardsData || {}

        return some([
            originPositioningFtl,
            originPositioningLtl,
        ], Boolean)
    }, [value?.rateCardsData])

    const collectionOptions = useMemo(() => {
        return (contextValue?.isGroupedLaneStatuses
            || value?.rateCardsData?.destinationCollectionLtl)
            ? reduceEnumValues(CollServiceType, [CollServiceType.DROPOFF_AIRPORT])
            : reduceEnumValues(CollServiceType, [
                CollServiceType.DROPOFF_AIRPORT,
                CollServiceType.COLLECTION,
            ])
    }, [
        contextValue?.isGroupedLaneStatuses,
        value?.rateCardsData?.destinationCollectionLtl,
    ])

    const deliveryOptions = useMemo(() => {
        return (contextValue?.isGroupedLaneStatuses || showDeliveryOptions)
            ? DeliveryServiceType
            : reduceEnumValues(DeliveryServiceType, [DeliveryServiceType.DELIVERY_ROAD])
    }, [
        contextValue?.isGroupedLaneStatuses,
        showDeliveryOptions,
    ])

    const fields = useFieldsWithClassName(getFields({
        getAllowedValues,
        deliveryOptions,
        collectionOptions,
    }), classes)

    const {
        isValid,
    } = useValidation({
        fields,
        values: value?.deliveryOptions || {},
    })

    const onChange = useCallback((newValue) => {
        if (!isEqual(newValue, value?.deliveryOptions)) {
            setValue({
                ...value,
                deliveryOptions: newValue,
            })
        }
    }, [
        value,
        setValue,
    ])

    const nextAction = useCallback(() => {
        setContextValue(value)
        setActiveStep(activeStep + 1)
    }, [
        setContextValue,
        value,
        setActiveStep,
        activeStep,
    ])

    const editAction = useCallback(() => {
        setActiveStep(index)
        setContextValue({
            lane: value?.lane,
            laneDetails: value?.laneDetails,
            deliveryOptions: value?.deliveryOptions,
        })
    }, [
        index,
        setActiveStep,
        setContextValue,
        value,
    ])

    const formValues = useMemo(() => {
        let laneDeliveryOptions = {
            deliveryServiceType: undefined,
            collectionServiceType: undefined,
        }

        if (value?.lane?.id) {
            laneDeliveryOptions = pick(value?.laneDetails,
                !contextValue?.isGroupedLaneStatuses ? [
                    'deliveryServiceType',
                    'collectionServiceType',
                ] : [
                    'deliveryServiceType',
                    'handoverPoint',
                    'collectionServiceType',
                    'collectionDropoffPoint',
                ])
        }

        const deliveryOptionsValues = Object.values(deliveryOptions)
        const collectionOptionsValues = Object.values(collectionOptions)
        const deliveryOption = deliveryOptionsValues.length === 1
            ? deliveryOptionsValues[0] : undefined

        if (contextValue?.isGroupedLaneStatuses) {
            return {
                ...laneDeliveryOptions,
                ...value?.deliveryOptions,
            }
        }

        return {
            ...laneDeliveryOptions,
            deliveryServiceType:
                deliveryOptionsValues.includes(laneDeliveryOptions.deliveryServiceType)
                    ? laneDeliveryOptions.deliveryServiceType : deliveryOption,
            collectionServiceType: collectionOptionsValues
                .includes(laneDeliveryOptions.collectionServiceType)
                ? laneDeliveryOptions.collectionServiceType : undefined,
            ...value?.deliveryOptions,
        }
    }, [
        collectionOptions,
        contextValue?.isGroupedLaneStatuses,
        deliveryOptions,
        value,
    ])

    return (
        <>
            <DomainObjectForm
                value={formValues}
                onChange={onChange}
                fields={fields}
                disabled={activeStep !== index || value?.nonEditable}
                name="CQuoteDeliveryOption"
                classNames={{
                    gridWrapper: classes.gridWrapper,
                    gridCardWrapper: classes.gridCardWrapper,
                }}
            />
            <SkyNetStepperButtons
                isValid={isValid}
                disabled={contextValue?.nonEditable}
                nextLabel="Continue"
                nextAction={nextAction}
                canEdit={activeStep > index && !contextValue?.nonEditable}
                editAction={editAction}
            />
        </>
    )
}
