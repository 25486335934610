import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        icon: {
            color: theme.palette.secondary[600],
        },
    }
})

export default useStyles
