import {
    tss,
} from 'app/tss'

export default tss.create(({
    theme,
}) => {
    return {
        title: {
            paddingBottom: theme.spacing(1.5),
        },
        subtitle: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        scenarioEditor: {
            display: 'flex',
        },
        control: {
            '&&': {
                paddingRight: theme.spacing(6),
            },
            flexGrow: 1,
        },
        wrapperCheckbox: {
            display: 'flex',
        },
        checkbox: {
            padding: '0 !important',
        },
        checkboxLabel: {
            paddingBottom: theme.spacing(1.25),
        },
    }
})
