import React from 'react'
import Typography from '@mui/material/Typography'

import Card from 'app/shared-components/Card'
import Button from 'app/shared-components/Button'

import useStyles from './NoOption.style'

const title = 'No option'
const text = 'Try using other search parameters or create manually and we will save  Order Information'

const NoOption = ({
    onClick,
}: {onClick: () => void}) => {
    const {
        classes,
    } = useStyles()

    return (
        <Card title="Best options">
            <Typography
                className={classes.h3}
                variant="h3"
            >
                {title}
            </Typography>
            <Typography>{text}</Typography>
            <div className={classes.actions}>
                <Button
                    label="Create Manually"
                    onClick={onClick}
                    className={classes.button}
                    data-testid="btn-edit"
                />
            </div>
        </Card>
    )
}

export default NoOption
