import React, {
    useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useHasPermission from 'app/hooks/useHasPermission'
import useTabsByPermission from 'app/hooks/useTabsByPermission'

import useRelationshipsManagementRoutes from '../hooks/useRelationshipsManagementRoutes'

import ActivityRecordOverview from './ActivityRecordOverview'
import ActivityRecordUpdate from './ActivityRecordUpdate'
import ActivityRecordCreate from './ActivityRecordCreate'
import getTableConfig from './activityRecords.config'

const tableControls: TableControlTypes[] = [
    TableControlTypes.Search,
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const ActivityRecords = () => {
    const {
        ActivityRecords: ActivityRecordsRoutes,
    } = useRelationshipsManagementRoutes()
    const canCreate = useHasPermission([
        'relationships_ops',
        'relationships_sales',
    ])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: ActivityRecordsRoutes.Overview.route,
                Component: ActivityRecordOverview,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Data Update',
                url: ActivityRecordsRoutes.Update.route,
                Component: ActivityRecordUpdate,
            },
            permission: true,
        },
    ])

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            actionComponents={{
                Create: canCreate ? ActivityRecordCreate : null,
            }}
            name="ActivityRecord"
            sidebarTabs={tabs}
            defaultTab={ActivityRecordsRoutes.Overview.route}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
            />
        </SkyNetDomain>
    )
}

export default ActivityRecords
