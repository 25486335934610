import React, {
    useEffect, useState, useMemo, useCallback,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import StatusHandler from 'app/shared-components/StatusHandler'
import useGetServiceCenterById from '../hooks/useGetServiceCenterById'
import {
    StorageTypeEnum,
} from '../serviceCenters.types'
import {
    ScenarioEditorDataType,
} from './ScenarioEditor.types'
import ScenarioEditorForm from './ScenarioEditorForm'
import {
    ScenarioEditorContext,
} from './ScenarioEditorContext'
import ForecastsByProduct from './ForecastsByProduct'
import ScenarioEditorAvailabilityChart from './ScenarioEditorAvailabilityChart'

export default function ScenarioEditor({
    id,
}: Readonly<{ id: number }>) {
    const [
        value,
        setValue,
    ] = useState<ScenarioEditorDataType>()

    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
    } = useGetServiceCenterById(id)

    useEffect(() => {
        if (isDataReady && data?.id !== value?.serviceCenter?.id) {
            setValue({
                processingTime: data?.storages?.find(({
                    storageType,
                }) => { return storageType === StorageTypeEnum.C })?.processingTime ?? 0,
                orderSelectionDelay: data?.orderSelectionDelay ?? 0,
                serviceCenter: {
                    id: data?.id,
                },
                includeNotStartedOrders: false,
                includePreBookings: false,
                productType: 'ALL',
                forecastsByProduct: [],
            })
        }
    }, [
        data,
        isDataReady,
        value?.serviceCenter?.id,
    ])

    const setContextValue = useCallback((newValue: Partial<ScenarioEditorDataType>) => {
        setValue({
            ...value,
            ...newValue,
        })
    }, [value])

    const context = useMemo(() => {
        return {
            contextValue: value,
            setContextValue,
        }
    }, [
        value,
        setContextValue,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady && !isEmpty(value)}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ScenarioEditorContext.Provider value={context}>
                <ScenarioEditorForm />
                <ForecastsByProduct />
                <ScenarioEditorAvailabilityChart />
            </ScenarioEditorContext.Provider>
        </StatusHandler>
    )
}
