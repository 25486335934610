import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import config from '../DamageTypes/DamageTypes.requests'
import {
    DamageType,
} from '../DamageTypes/DamageTypes.types'

export default (id) => {
    return useRequest<DamageType>({
        key: RequestKeys.getDamageType,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(config.get),
        enabled: Boolean(id),
    })
}
