import React, {
    useCallback,
} from 'react'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    getEditedItems,
    useGetRequestSpreadSheetAdapter,
} from 'app/shared-components/SkyNetSpreadSheet'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import useFilter from 'app/hooks/useFilter'
import {
    useSkyNetPagination, SkyNetPaginationContext,
} from 'app/shared-components/SkyNetPagination'
import useBulkEdit from 'app/hooks/useBulkEdit'
import requests from '../AdditionalFees.request'
import {
    AdditionalFeesEditable,
    AdditionalFeesTable,
} from '../AdditionalFees.types'
import AdditionalFeesSpreadsheet from '../AdditionalFeesSpreadsheet'
import {
    prepareFeesToBulkEdit,
} from '../../OtherLaneFees/OtherLaneFees.utils'

export default function AdditionalFeesBulkEdit({
    onClose,
}: {
    onClose: () => void
}) {
    const filterOptions = useFilter<AdditionalFeesTable[]>()
    const paginationOptions = useSkyNetPagination(25)
    const {
        isError,
        loaded,
        error,
    } = useGetRequestSpreadSheetAdapter<AdditionalFeesTable>({
        config: requests.filterRequest,
        requestKey: RequestKeys.getAdditionalFeesRateCards,
        enabled: true,
        filterOptions,
        paginationOptions,
    })

    const bulkEdit = useBulkEdit<AdditionalFeesEditable>({
        id: 'AdditionalFeesBulkUpdate',
        successMessage: 'Additional Fees were updated',
        requestConfig: requests.bulkEdit,
    })

    const onSave = useCallback((
        items: AdditionalFeesTable[],
        options?: AsyncMutationOptions,
    ) => {
        const editedItems = getEditedItems({
            allItems: items,
            initData: filterOptions?.filteredData,
        })

        bulkEdit(prepareFeesToBulkEdit(editedItems) as AdditionalFeesEditable[], {
            ...options,
            onSuccess: options?.onSuccess || onClose,
        })
    }, [
        bulkEdit,
        filterOptions?.filteredData,
        onClose,
    ])

    return (
        <StatusHandler
            isError={isError}
            isFetching={!loaded}
            error={error}
            isSuccess={loaded}
        >
            <SkyNetPaginationContext.Provider value={paginationOptions}>
                <AdditionalFeesSpreadsheet
                    filterOptions={filterOptions}
                    onCancel={onClose}
                    onSave={onSave}
                />
            </SkyNetPaginationContext.Provider>
        </StatusHandler>
    )
}
