import React, {
    useCallback,
} from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import get from 'lodash/get'
import noop from 'lodash/noop'

import {
    Column,
} from 'app/types/simpletable.types'

import {
    DedicatedContainerType,
} from '../../../longTermLease.types'

import useStyles from './TableRowComponent.style'

type Props = {
    value: DedicatedContainerType,
    onChange: (changeValue: any) => void,
    columns: Column<any>[],
}

const TableRowComponent = ({
    value,
    onChange,
    columns,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const handleChange = useCallback((newValue, {
        target: {
            name,
        },
    }): void => {
        const update = {
            ...value,
            [name]: newValue,
        }

        onChange(update)
    }, [
        value,
        onChange,
    ])

    return (
        <TableRow classes={{
            root: classes.root,
        }}
        >
            {value && columns.map(({
                fieldName,
                component: Component,
                getProps = noop,
            }) => {
                const currentInput = get(value, fieldName)

                return (
                    <TableCell
                        classes={{
                            root: classes.tableCell,
                        }}
                        key={`${fieldName}`}
                    >
                        <Component
                            value={currentInput}
                            name={fieldName}
                            data-testid={`input-${fieldName}`}
                            onChange={handleChange}
                            {...getProps(value)} // eslint-disable-line react/jsx-props-no-spreading
                        />
                    </TableCell>
                )
            })}
        </TableRow>
    )
}

export default TableRowComponent
