import {
    RequestConfig,
} from 'app/types/request.types'

type OtherLaneFeesRequests = {
    filterRequest: (params?: Record<string, any>) => RequestConfig,
    bulkEdit: ({
        data,
    }: {
        data: Record<string, any>,
    }) => RequestConfig,
    filterCount: ({
        fieldName,
    }: {
        fieldName: string,
    }) => RequestConfig,
}

const requests: OtherLaneFeesRequests = {
    filterRequest: (params = {}) => {
        return {
            url: 'lanefeeratecard/filter-request/all',
            method: 'POST',
            ...params,
        }
    },
    filterCount: ({
        fieldName,
    }) => {
        return {
            url: `lanefeeratecard/filter-request/all/count/${fieldName}`,
            method: 'POST',
            data: {
                rangeType: '',
            },
        }
    },
    bulkEdit: ({
        data,
    }) => {
        return {
            url: 'lanefeeratecard/bulk',
            method: 'PATCH',
            data,
        }
    },
}

export default requests
