import {
    FilterType,
} from 'app/types/common.enums'
import {
    FilterConfig,
} from 'app/hooks/useFilter'
import {
    BaseLeaseTable,
} from '../BaseLease.types'

const filterConfig: FilterConfig<keyof Pick<BaseLeaseTable,
    'pricingAccountCompanyName'>> = {
        pricingAccountCompanyName: {
            filterField: 'pricingAccountCompanyName',
            filterType: FilterType.Array,
        },
    }

export default filterConfig
