import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    TypeIcon,
} from 'app/shared-components/NotificationIcon/notificationIcon.types'
import {
    CQuoteOfferSetup,
} from '../../CQuoteInformationContext'
import {
    logisticDaysValidate,
    rentalDaysValidate,
} from './CQuoteOfferSetup.validation'

const getFields = ({
    getAllowedValues,
    allowedCurrency = {},
    plannedPaymentTerms,
    isGroupedLaneStatuses,
    disabled,
}: {
    getAllowedValues: (val: Record<string, string>) => Record<string, string>,
    allowedCurrency: Record<string, string>,
    plannedPaymentTerms: number,
    isGroupedLaneStatuses: boolean,
    disabled: boolean,
}): DTOCardFormField<keyof CQuoteOfferSetup>[] => {
    return [{
        id: 'general',
        title: 'General',
        className: 'general',
        fields: [
            {
                id: 'offerSetup-column1',
                fields: [
                    {
                        name: 'currency',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(allowedCurrency),
                        value: Object.values(allowedCurrency).length === 1
                            ? Object.values(allowedCurrency)?.[0] : undefined,
                        useDropdown: true,
                        required: true,
                        disabled: isGroupedLaneStatuses || disabled,
                        labelKey: 'LaneOpsInformationDto.currency',
                    },
                    {
                        name: 'rentalDays',
                        componentName: 'IntegerInput',
                        min: 5,
                        max: 30,
                        required: true,
                        labelKey: 'LaneFeesUpdateDto.rentalDays',
                        validate: rentalDaysValidate,
                    },
                    {
                        name: 'logisticsDays',
                        componentName: 'IntegerInput',
                        min: 0,
                        max: 30,
                        required: true,
                        labelKey: 'LaneFeesUpdateDto.logisticsDays',
                        validate: logisticDaysValidate,
                    },
                ],
            },
            {
                id: 'offerSetup-column2',
                fields: [
                    {
                        name: 'customerQuotation',
                        componentName: 'LocationObjectSelector',
                        required: true,
                        disabled: isGroupedLaneStatuses || disabled,
                        labelKey: 'ContractBasisCreateDto.customerQuotation',
                    },
                    {
                        name: 'plannedPaymentTerms',
                        componentName: 'InputSingleline',
                        value: plannedPaymentTerms,
                        disabled: true,
                        labelKey: 'ContractBasisUpdateDto.plannedPaymentTerms',
                        notificationIconProps: {
                            text: 'If Planned Payment Terms are not set in Contract Basis, '
                                + 'the value will default to 30 days',
                            type: TypeIcon.INFO,
                        },
                    },
                ],
            },
        ],
    }]
}

export default getFields
