import React, {
    useCallback, useMemo, useState,
} from 'react'

import SkyNetStepper from 'app/shared-components/SkyNetStepper'
import useBeforeUnloadDialog from 'app/hooks/useBeforeUnloadDialog'
import HistoryBlockPrompt from 'app/shared-components/HistoryBlockPrompt'

import {
    AQuote, QuoteStatus,
} from '../AQuotes.types'
import {
    AQuoteInformationContext,
} from '../AQuoteCreate/AQuoteInformationContext'
import AQuoteInformationCreate from '../AQuoteCreate/AQuoteInformationCreate'
import QuoteOpportunities from '../AQuoteCreate/QuoteOpportunities'
import QuoteLanes from '../AQuoteCreate/QuoteLanes'
import QuotationOptions from '../AQuoteCreate/QuotationOptions'
import useAQuoteUpdate from './hooks/useAQuoteUpdate'
import getRequestData from './getRequestData'

const AQuoteUpdate = ({
    data,
    onSuccess,
}: {data: AQuote, onSuccess: () => void}) => {
    const [
        activeStep,
        setActiveStep,
    ] = useState<number>(1)

    const [
        value,
        setValue,
    ] = useState<AQuote>(data)

    const [
        isEdited,
        setIsEdited,
    ] = useState<boolean>()

    useBeforeUnloadDialog(isEdited)

    const aQuoteInformationContext = useMemo(() => {
        return {
            activeStep,
            setActiveStep,
            value,
            initialValue: data,
            setValue,
            setIsEdited,
        }
    }, [
        activeStep,
        value,
        data,
    ])

    const {
        mutate,
    } = useAQuoteUpdate(onSuccess)

    const updateQuote = useCallback((aQuote: AQuote) => {
        setIsEdited(false)
        mutate({
            id: value?.id,
            data: getRequestData(aQuote, data),
        })
    }, [
        mutate,
        value?.id,
        data,
    ])

    const stepperSteps = useMemo(() => {
        return [
            {
                key: '1',
                label: 'Fill Quote Information',
                expanded: true,
                content: <AQuoteInformationCreate
                    data={value}
                    disabled
                />,
            },
            {
                key: '2',
                label: 'Select Opportunities & Required Products',
                expanded: (step) => {
                    return step > 0
                },
                content: <QuoteOpportunities
                    disabled={activeStep !== 1 || data?.quoteStatus === QuoteStatus.CANCELLED}
                />,
            },
            {
                key: '3',
                label: 'Select Lanes',
                expanded: (step) => {
                    return step > 1
                },
                content: <QuoteLanes disabled={activeStep !== 2} />,
            },
            {
                key: '4',
                label: 'Specify Quotation Options',
                expanded: (step) => {
                    return step > 2
                },
                content: <QuotationOptions
                    data={value}
                    actionQuote={updateQuote}
                />,
            },
        ]
    }, [
        activeStep,
        data?.quoteStatus,
        updateQuote,
        value,
    ])

    return (
        <AQuoteInformationContext.Provider value={aQuoteInformationContext}>
            <SkyNetStepper
                activeStep={activeStep}
                steps={stepperSteps}
            />
            <HistoryBlockPrompt
                when={isEdited}
                isPositiveAlert
                positiveLabel="Discard"
                promptText="You are about to leave this page, your progress will be lost. Would you like to discard your changes anyway?"
            />
        </AQuoteInformationContext.Provider>
    )
}

export default AQuoteUpdate
