import {
    tss,
} from './config'

// https://docs.tss-react.dev/api/tss-usestyles

export default tss.withParams<{
    top?: number, left?: number, right?: number, bottom?: number
}>().create(({
    theme,
    top,
    left,
    right,
    bottom,
}) => {
    return {
        margin: {
            marginTop: top ? theme.spacing(top) : 'auto',
            marginLeft: left ? theme.spacing(left) : 'auto',
            marginRight: right ? theme.spacing(right) : 'auto',
            marginBottom: bottom ? theme.spacing(bottom) : 'auto',
        },
    }
})
