import {
    RequestConfig,
} from 'app/types/request.types'
import {
    RegionSecondLevel,
} from 'app/types/enums'
import {
    ProductTypeCQuote,
} from 'app/Apps/AccountManagement/CQuotes/CQuotes.types'

type RateCardsRequests = {
    getLogisticDetails: ({
        originRegion,
        destinationRegion,
        productType,
    }:{
        originRegion: RegionSecondLevel,
        destinationRegion: RegionSecondLevel,
        productType: ProductTypeCQuote
    }) => RequestConfig
}

const rateCardsRequests: RateCardsRequests = {
    getLogisticDetails: (params) => {
        return {
            url: 'ratecard/logistics-details',
            data: {
                ...params,
            },
            method: 'POST',
        }
    },
}

export default rateCardsRequests
