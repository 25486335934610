import React, {
    useMemo,
} from 'react'
import noop from 'lodash/noop'

import Select from 'app/shared-components/Select'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    Frequency,
} from 'app/types/enums'
import {
    FxRateValues,
} from 'app/Apps/Finance/FxRates/FxRate.types'
import isoStrToLocalStr from 'app/utils/date/isoStrToLocalStr'

type Props = {
    data?: FxRateValues[]
    value?: number,
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    disabled?: boolean,
}

const defaultProps: Partial<Props> = {
    data: [],
    value: undefined,
    onChange: noop,
    disabled: false,
}

const FxRateSelect = ({
    data: fxRates,
    value,
    onChange,
    disabled,
}: Props) => {
    const fxRateTypeAllowedValues = useEnumValues()(Frequency)

    const options = useMemo(() => {
        return fxRates.map(({
            id,
            validFrom,
            fxRateType,
        }) => {
            return [
                id,
                `${fxRateTypeAllowedValues[fxRateType]} (valid from ${isoStrToLocalStr(validFrom)})`,
            ]
        })
    }, [
        fxRates,
        fxRateTypeAllowedValues,
    ])

    return (
        <div>
            <Select
                title="FX Rate to Billing Currency"
                value={value}
                data={options}
                name="fxRateId"
                disabled={disabled}
                onChange={onChange}
            />
        </div>
    )
}

FxRateSelect.defaultProps = defaultProps

export default FxRateSelect
