import React, {
    useMemo,
} from 'react'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useHasPermission from 'app/hooks/useHasPermission'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import getTableConfig from './CQuotes.config'
import requests from './CQuote.request'
import CQuoteCreateSelectMethod from './CQuoteCreateSelectMethod'
import {
    CQuotesTab,
} from './CQuotes.types'
import CQuoteOverview from './CQuoteOverview'
import CQuotesAwardRejectLanes from './CQuotesAwardRejectLanes'
import CQuotesPDF from './CQuotesPDF'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

export default function CQuotes() {
    const accountCreate = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])

    const accountUpdate = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: CommonRoutes.OVERVIEW,
                Component: CQuoteOverview,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Award/Reject',
                url: CQuotesTab.AwardReject,
                Component: CQuotesAwardRejectLanes,
                activeComparer: (pathname) => {
                    return pathname.split('/').includes(CQuotesTab.AwardReject)
                },
            },
            permission: accountUpdate,
        },
        {
            tab: {
                label: 'Quote PDF',
                url: CQuotesTab.QuotePdf,
                Component: CQuotesPDF,
            },
            permission: true,
        },
    ])

    return (
        <SkyNetDomain
            name={tableConfig.name}
            sidebarTabs={tabs}
            defaultTab={CommonRoutes.OVERVIEW}
            actionComponents={{
                Create: accountCreate ? CQuoteCreateSelectMethod : null,
            }}
        >
            <SkyNetTable
                customUrl={requests[RequestKeys.cQuotesFilterRequest]().url}
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>

    )
}
