import {
    useCallback,
    useState,
} from 'react'
import {
    useMutation,
} from '@tanstack/react-query'
import {
    useDispatch,
} from 'react-redux'
import {
    useJWTToken,
} from 'app/Auth'

import {
    failedToLoadSaveForm,
} from 'app/shared-components/FormStatuses/store/actions'
import responseParser from 'app/hooks/useRequest/utils/responseParser'
import requestBusinessObjects from 'app/hooks/useRequest/utils/requestBusinessObjects'

import config from '../ContainerNotes.request'

const ROWS_PER_PAGE = 25

export default (containerId) => {
    const token = useJWTToken()
    const dispatch = useDispatch()

    const [
        needsRefresh,
        setNeedsRefresh,
    ] = useState(false)

    const onError = useCallback((error) => {
        const {
            response: {
                data: errors,
                status: statusCode,
            },
        } = error

        dispatch(
            failedToLoadSaveForm({
                id: 'ContainerNotes',
                errors,
                statusCode,
            }),
        )
    }, [dispatch])

    /* TODO: To handle current paging implementation of Richtable by load function
    *  we have to use ueMutation because it allowed to return data by promise
    * but we need to refactor Richtable to be able to use useQuery */
    const {
        mutateAsync,
        reset,
    } = useMutation({
        mutationFn: ({
            page,
            sort,
        }: {page: number, sort?:{ field: string, direction: string, }}) => {
            const rows = page === 1 ? (ROWS_PER_PAGE * 2) : ROWS_PER_PAGE
            const sorting = sort ? {
                sortField: sort.field,
                sortType: sort.direction,
            }
                : {
                    sortField: 'validDateTimestamp',
                    sortType: 'desc',
                }

            return requestBusinessObjects({
                token,
                ...config.filterRequest({
                    containerId,
                    sorting,
                    rows,
                    start: (page === 1 ? (page - 1) : page) * rows,
                }),
            })
        },
        onError,
    })

    const load = useCallback(async (params) => {
        const {
            sort,
            page,
        } = params

        if (needsRefresh) {
            reset()
            setNeedsRefresh(false)
        }

        return responseParser.table(mutateAsync({
            page,
            sort,
        }))
    }, [
        mutateAsync,
        needsRefresh,
        reset,
    ])

    const refresh = useCallback(() => {
        setNeedsRefresh(true)
    }, [])

    return {
        load,
        refresh,
    }
}
