import {
    useContext, createContext,
} from 'react'
import {
    ContractCurrency,
} from 'app/types/enums'

export type LaneGroupContextType = {
    laneGroupId: number,
    currency?: ContractCurrency,
    tempRange?: string,
    leaseType?: string,
    deliveryServiceType?: string,
    collectionServiceType?: string,
}

export const LaneGroupContext = createContext<LaneGroupContextType>(null)

const useLaneGroupContext = () => {
    return useContext<LaneGroupContextType>(LaneGroupContext)
}

export default useLaneGroupContext
