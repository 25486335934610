import {
    FieldConfig,
    isNegativeNumber,
    SkyNetSpreadSheetCellType,
    SkyNetSpreadSheetConfigType,
} from 'app/shared-components/SkyNetSpreadSheet'
import {
    AdditionalFeesAllowed, OtherFeesAllowed,
} from 'app/shared-components/Fees'
import {
    ContractCurrency,
    FeeChargeability, ProductType,
} from 'app/types/enums'
import {
    AdditionalFeesTable,
} from '../AdditionalFees.types'

export default function AdditionalFeesSpreadsheetConfig({
    getLabel,
    getAllowedValues,
    noAmountValue,
}: {
    getLabel: (s: string) => string,
    getAllowedValues: (type: Record<string, string>) => Record<string, string>,
    noAmountValue: (item: AdditionalFeesTable) => boolean
}): SkyNetSpreadSheetConfigType<keyof AdditionalFeesTable> {
    const fields: FieldConfig<keyof AdditionalFeesTable>[] = [
        {
            name: 'feeName',
            title: getLabel('LaneFeesRateCard.feeName'),
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            width: 200,
            allowedValues: getAllowedValues({
                ...OtherFeesAllowed, ...AdditionalFeesAllowed,
            }),
            noneOption: false,
            disabled: true,
        },
        {
            name: 'feeChargability',
            title: getLabel('LaneFeesRateCard.feeChargability'),
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            width: 200,
            allowedValues: getAllowedValues(FeeChargeability),
            noneOption: false,
            disabled: true,
        },
        {
            name: 'productType',
            title: getLabel('LaneFeesRateCard.productType'),
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            width: 100,
            allowedValues: getAllowedValues(ProductType),
            noneOption: false,
            disabled: true,
        },
        {
            name: 'simplifiedCQuoteDescription',
            title: getLabel('LaneFeesRateCard.simplifiedCQuoteDescription'),
            width: 250,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.TEXT,
            },
        },
        {
            name: 'description',
            title: getLabel('LaneFeesRateCard.quoteDescription'),
            width: 250,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.TEXT,
            },
        },
        {
            name: 'currency',
            title: getLabel('LaneFeesRateCard.currency'),
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            width: 80,
            allowedValues: getAllowedValues(ContractCurrency),
            noneOption: false,
            disabled: true,
        },
        {
            name: 'feeValue',
            title: getLabel('LaneFeesRateCard.feeValue'),
            width: 100,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                conditionalType: [[
                    noAmountValue,
                    SkyNetSpreadSheetCellType.NO_VALUE,
                ]],
                validate: isNegativeNumber('feeValue'),
            },
        },
        {
            name: 'id',
            width: 1,
            hidden: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.ID,
            },
        },
    ]

    return {
        id: 'AdditionalFees',
        title: 'Standard Additional Fees',
        headerRow: true,
        rowsCounter: true,
        headerHeight: 60,
        addRowMenuOption: false,
        enableRangeSelection: true,
        enableRowSelection: true,
        stickyTopRows: 1,
        filterable: true,
        rowsLimit: 25,
        emptyRows: 0,
        initialFocusLocation: {
            columnId: 'feeName',
            rowId: 0,
        },
        fields,
    }
}
