import React, {
    useCallback, useMemo, useState,
} from 'react'
import pick from 'lodash/pick'

import FormWithControls from 'app/shared-components/FormWithControls'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import Button from 'app/shared-components/Button'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    LaneStatus,
} from 'app/types/enums'

import {
    CollServiceType, InvoiceSettingsType, Lane,
} from '../lanes.types'

import getVisibleFields from './utils/getVisibleFields'
import getFields from './fields/laneImplementation.fields'
import useSetAsImplemented from './hooks/useSetAsImplemented'
import useSetAsInactive from './hooks/useSetAsInactive'
import useStyles from './LaneImplementation.styles'
import getIncoTermAdditionalText from './utils/getIncoTermAdditionalText'

type Props = {
    data: Lane,
    name: string,
    onSuccess: () => void,
}

const LaneImplementation = ({
    data,
    name,
    onSuccess,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const [originalStatus] = useState<LaneStatus>(data.laneStatus)
    const [
        value,
        setValue,
    ] = useState<Lane & InvoiceSettingsType>({
        ...data,
        ...data?.invoiceSettings,
        incoTermAdditionalText: data?.invoiceSettings?.incoTermAdditionalText
            || getIncoTermAdditionalText(data),
    })

    const {
        reqCustomsCoord,
        deliveryServiceType,
        collectionServiceType,
        id,
    } = value

    const onChange = useCallback((newVal: Lane & InvoiceSettingsType) => {
        return setValue((prevState) => {
            if (newVal?.incoTerm !== prevState?.incoTerm) {
                return {
                    ...newVal,
                    incoTermAdditionalText: getIncoTermAdditionalText(newVal),
                }
            }

            return newVal
        })
    }, [])

    const {
        isPending: isLoadingImplemented,
        mutate: setAsImplemented,
    } = useSetAsImplemented(onSuccess)

    const {
        isPending: isLoadingInactive,
        mutate: setAsInactive,
    } = useSetAsInactive(onSuccess)

    const fields = useMemo(() => {
        return getFields({
            deliveryServiceType,
            originalStatus,
            reqCustomsCoord,
            isDropOffSkyCell: collectionServiceType === CollServiceType.DROPOFF_SKYCELL,
            getAllowedValues,
        })
    }, [
        getAllowedValues,
        deliveryServiceType,
        originalStatus,
        reqCustomsCoord,
        collectionServiceType,
    ])

    const fieldsWithClassNames = useFieldsWithClassName(fields, classes)

    const modifyDataBeforeSend = useCallback(() => {
        const visibleFields = getVisibleFields(fields)

        const dataForm = pick(value, visibleFields)
        const {
            incoTermAdditionalText,
            hsCodeContainer,
            hsCodeLogger,
            invoiceFootNote,
            consigneeAddress,
            scheduleBNumber,
            ...rest
        } = dataForm

        return {
            ...rest,
            invoiceSettings: {
                incoTermAdditionalText,
                hsCodeContainer,
                hsCodeLogger,
                invoiceFootNote,
                consigneeAddress,
                scheduleBNumber,
            },
        }
    }, [
        fields,
        value,
    ])

    const customButtons = useMemo(() => {
        if (value?.laneStatus === LaneStatus.IMPLEMENTED) {
            return [<Button
                label="Set As Inactive"
                name="inactive"
                onClick={() => { setAsInactive(id) }}
                saving={isLoadingInactive}
                data-testid="btn-inactive"
            />]
        }

        if (value?.laneStatus === LaneStatus.AWARDED || value?.laneStatus === LaneStatus.INACTIVE) {
            return [<Button
                label="Set As Implemented"
                name="implemented"
                onClick={() => { setAsImplemented(id) }}
                saving={isLoadingImplemented}
                data-testid="btn-implemented"
            />]
        }

        return []
    }, [
        id,
        isLoadingImplemented,
        isLoadingInactive,
        setAsImplemented,
        setAsInactive,
        value?.laneStatus,
    ])

    return (
        <FormWithControls
            name={name}
            requestParams={orderManagementRequest.Lane.Update({
                id,
            })}
            value={value}
            setExternalValue={onChange}
            fields={fieldsWithClassNames}
            classNames={{
                gridWrapper: classes.gridWrapper,
            }}
            onSuccess={onSuccess}
            modifyDataBeforeSend={modifyDataBeforeSend}
            customButtons={customButtons}
            exists
        />
    )
}

export default LaneImplementation
