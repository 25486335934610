import {
    RequestConfig, SelectorConfig,
} from 'app/types/request.types'

type ContactsRequests = {
    name: string,
    getContactsChangeHistory: {
        request: (id: number) => {
            customUrl: string,
            method: string,
            name: string,
        }
    }
    get: ({
        id: number,
    }) => RequestConfig,
    create: () => RequestConfig,
    update: () => RequestConfig,
    archive: ({
        id: number,
    }) => RequestConfig,
    restore: ({
        id: number,
    }) => RequestConfig,
    selector: SelectorConfig<{ contactName: string, id: number }[]>
}

const request: ContactsRequests = {
    name: 'Modification',
    getContactsChangeHistory: {
        request: (id) => {
            return {
                customUrl: `contact/${id}/modifications`,
                method: 'GET',
                name: 'Contact',
            }
        },
    },
    get: ({
        id,
    }) => {
        return {
            url: `contact/${id}`,
            method: 'GET',
        }
    },
    create: () => {
        return {
            url: 'contact',
            method: 'POST',
        }
    },
    update: () => {
        return {
            url: 'contact',
            method: 'PUT',
        }
    },
    archive: ({
        id,
    }) => {
        return {
            url: `contact/${id}/archive`,
            method: 'PATCH',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `contact/${id}/restore`,
            method: 'PATCH',
        }
    },
    selector: {
        url: 'contact/filter-request/selector',
        convertion: (contacts) => {
            return contacts.map(({
                contactName, id,
            }) => {
                return {
                    label: contactName,
                    value: id,
                    id,
                }
            })
        },
    },
}

export default request
