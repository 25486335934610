import React, {
    useRef, useState, useEffect, CSSProperties,
} from 'react'
import {
    useJsApiLoader,
} from '@react-google-maps/api'

import Loading from '../Loading'

import {
    DEFAULT_HEIGHT,
    GOOGLE_MAP_KEY, libraries,
} from './SkyNetMap.constants'
import useStyles from './SkyNetMap.styles'

type ContainerStyles = {
    width: string | undefined,
    height?: string | undefined
}

const defaultProps = {
    height: DEFAULT_HEIGHT,
    width: undefined,
}

const SkyNetMapProvider = ({
    width,
    height,
    children,
}: {
    width?: string,
    height?: string,
    children: (containerStyles: CSSProperties) => JSX.Element
}) => {
    const {
        classes,
    } = useStyles({
        height,
    })

    const ref = useRef<HTMLDivElement>(null)

    const [
        containerStyles,
        setContainerStyles,
    ] = useState<ContainerStyles>({
        width, height,
    })

    const {
        isLoaded,
    } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAP_KEY,
        libraries,
    })

    useEffect(() => {
        if (ref?.current && isLoaded) {
            setContainerStyles((prev) => {
                return {
                    ...prev,
                    width: `${ref?.current?.clientWidth}px`,
                }
            })
        }
    }, [isLoaded])

    if (!isLoaded) return <Loading className={classes.container} />

    return (
        <div
            ref={ref}
            className={classes.container}
        >
            {
                ref?.current
            && (
                children(containerStyles)
            )
            }
        </div>
    )
}

SkyNetMapProvider.defaultProps = defaultProps

export default SkyNetMapProvider
