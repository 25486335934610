import React from 'react'

type Props = {
    value: string,
}

const DateTimeCell = ({
    value,
}: Props) => {
    return (
        <>
            {value}
        </>
    )
}

export default DateTimeCell
