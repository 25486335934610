import React, {
    useMemo,
    useCallback,
} from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import DeleteIcon from '@mui/icons-material/Delete'

import CURRENCY from 'app/utils/currency'
import InputBase from 'app/shared-components/InputBase'
import useEnumValues from 'app/hooks/useEnumValues'
import FxRate from 'app/shared-components/FxRate/FxRate.types'
import {
    FeeChargeability,
    ProductType,
} from 'app/types/enums'

import useFeesNameToFeesChargeabilityMapping from '../useFeesNameToFeesChargeabilityMapping'
import {
    AdditionalFeesAllowed,
    LaneFeesAllowed,
    OtherFeesAllowed,
    FeesType,
} from '../Fee.types'

import useFeePricingName from './useFeePricingName'
import Select from './Select'
import Calculation from './Calculation'
import Percentage from './Percentage'

import useStyles from './FeeInputs.styles'

const LANE_INDEPENDENT_FEE_NAME = 'laneIndependentPricingFees'
const OTHERFEES = 'otherFees'
const FEES = 'fees'

const feeChargeabilityWithoutValues = [
    'NOT_APPLICABLE',
    'INCLUDED',
    'PERCENTAGE_BASE_LEASE_PERIOD',
    'PERCENTAGE_BASE_LEASE_POSITIONING_PERIOD',
    'TBD',
]

const CHARGEABILITIES_WITH_PRODUCT_TYPE = [
    'FIXED_AMOUNT_PER_DAY_CONTAINER',
    'FIXED_AMOUNT_CONTAINER_SPECIFIC',
]

type Props = {
    value?: {
        id?: string| number,
        feeChargeability?: string,
        productType?: string,
        feeName?: string,
        rangeType?: string,
        feeDescription?: string,
        feeValue?: number,
    },
    fxRate?: FxRate,
    onChange: (tempValue: any) => void,
    onDelete: (id: string| number) => void,
    disabled?: boolean,
    currency?: string,
    feeName?: string,
    productTypes?: {
        productType: string,
        temperatureRange: string,
    }[],
    tempRange?: string,
}

const defaultProps : Partial<Props> = {
    value: {},
    disabled: false,
    currency: undefined,
    feeName: undefined,
    productTypes: [],
    fxRate: undefined,
    tempRange: undefined,
}

const FeeInputs = ({
    value,
    onChange,
    fxRate,
    disabled,
    onDelete,
    currency,
    feeName,
    productTypes,
    tempRange,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getEnumValues = useEnumValues()
    const allowedProductTypes = getEnumValues(ProductType)
    const allowedFeeChargeabilities = getEnumValues(FeeChargeability)
    const feePricingName = useFeePricingName()
    const name = feePricingName || feeName
    const chargeabilityMapping = useFeesNameToFeesChargeabilityMapping(name as FeesType)

    const feeNames = useMemo(() => {
        return {
            [OTHERFEES]: OtherFeesAllowed,
            [LANE_INDEPENDENT_FEE_NAME]: AdditionalFeesAllowed,
            [FEES]: LaneFeesAllowed,
        }
    }, [])
    const feeNameOptions = useMemo(() => {
        return Object.entries(
            getEnumValues(feeNames[name]),
        )
    },
    [
        name,
        getEnumValues,
        feeNames,
    ])

    const productTypesOptions = useMemo(() => {
        const list = tempRange ? Object.values(productTypes)?.filter((pt) => {
            return pt.temperatureRange === tempRange
        }) : Object.values(productTypes)

        return list?.reduce((acc, element) => {
            return {
                ...acc,
                [element.productType]: allowedProductTypes[element.productType],
            }
        }, {})
    }, [
        productTypes,
        allowedProductTypes,
        tempRange,
    ])

    const chargeabilityOptions = useMemo(() => {
        if (chargeabilityMapping) {
            const optionKeys = chargeabilityMapping[value.feeName] as string[]

            return optionKeys ? optionKeys.map((key) => {
                return [
                    key,
                    allowedFeeChargeabilities[key],
                ]
            }) : []
        }
        return []
    },
    [
        allowedFeeChargeabilities,
        chargeabilityMapping,
        value.feeName,
    ])

    const showProductTypeSelect = useMemo(() => {
        return CHARGEABILITIES_WITH_PRODUCT_TYPE.includes(value.feeChargeability)
    }, [value.feeChargeability])

    const deleteFee = useCallback(() => {
        onDelete(value.id)
    }, [
        value.id,
        onDelete,
    ])

    const handleChange = useCallback((change, {
        target: {
            name: changeName,
        },
    }) => {
        const feeNameChanged = changeName === 'feeName'
        const feeChargeabilityChanged = changeName === 'feeChargeability'

        onChange({
            ...value,
            feeChargeability: feeNameChanged ? null
                : value?.feeChargeability,
            productType: (feeNameChanged || feeChargeabilityChanged)
                ? null : value?.productType,
            feeValue: (feeNameChanged || feeChargeabilityChanged || changeName === 'productType')
                ? null : value?.feeValue,
            [changeName]: change,
        })
    }, [
        onChange,
        value,
    ])

    return (
        <TableRow
            key={`fee-item_${value.id}`}
            data-testid={`feeInput-${value.feeName}`}
        >
            <TableCell
                component="th"
                scope="fee"
                classes={{
                    root: classes.fee,
                }}
            >
                <Select
                    data={feeNameOptions}
                    value={value.feeName}
                    name="feeName"
                    onChange={handleChange}
                    disableUnderline
                    disableHelperText
                    disabled={disabled}
                    required
                />
            </TableCell>
            <TableCell
                classes={{
                    root: classes.feeChargeability,
                }}
            >
                <Select
                    data={chargeabilityOptions}
                    value={value.feeChargeability}
                    name="feeChargeability"
                    onChange={handleChange}
                    disableUnderline
                    disableHelperText
                    disabled={disabled}
                    required
                />
            </TableCell>
            <TableCell
                classes={{
                    root: classes.productType,
                }}
            >
                {showProductTypeSelect && (
                    <Select
                        allowedValues={productTypesOptions}
                        value={value.productType}
                        name="productType"
                        onChange={handleChange}
                        disableUnderline
                        disableHelperText
                        disabled={disabled}
                        required
                    />
                )}
            </TableCell>
            <TableCell
                align="right"
                classes={{
                    root: classes.descriptionCell,
                }}
            >
                <InputBase
                    value={value.feeDescription}
                    name="feeDescription"
                    onChange={handleChange}
                    disabled={disabled}
                    classes={{
                        root: classes.description,
                    }}
                    inputProps={{
                        className: classes.input,
                    }}
                />
            </TableCell>
            {!value.feeChargeability || feeChargeabilityWithoutValues.some((feeChargability) => {
                return feeChargability === value.feeChargeability
            })
                ? (
                    <TableCell
                        classes={{
                            root: classes.tableCell,
                        }}
                        colSpan={currency === CURRENCY.default ? 1 : 2}
                    />
                )
                : (
                    <>
                        {value.feeChargeability === 'PERCENTAGE_BASE_LEASE'
                            ? (
                                <>
                                    {currency !== CURRENCY.default
                                    && (
                                        <TableCell
                                            classes={{
                                                root: classes.tableCell,
                                            }}
                                        />
                                    )}
                                    <Percentage
                                        value={value}
                                        onChange={handleChange}
                                        disabled={disabled}
                                    />
                                </>
                            )
                            : (
                                <Calculation
                                    value={value}
                                    params={{
                                        exchangeRate: fxRate,
                                    }}
                                    onChange={handleChange}
                                    disabled={disabled}
                                    currency={currency}
                                />
                            )}
                    </>
                )}
            <TableCell classes={{
                root: classes.delete,
            }}
            >
                {!disabled
                    && (
                        <DeleteIcon
                            className={classes.deleteIcon}
                            onClick={deleteFee}
                        >
                            Delete
                        </DeleteIcon>
                    )}
            </TableCell>
        </TableRow>
    )
}

FeeInputs.defaultProps = defaultProps

export default FeeInputs
