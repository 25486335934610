import React from 'react'

import {
    dateToStrTimezone,
} from 'app/utils/date'

import {
    TimeStampDataType,
} from '../OrderSteps.types'

import useStyles from './StepDate.style'

const EMPTY_MSG = 'No date / time yet'

const StepDate = ({
    value,
}: {value: TimeStampDataType[]}) => {
    const {
        classes,
    } = useStyles()

    return (
        <>
            {value.map(({
                date,
                iconPath,
            }, index) => {
                return (
                    <div
                        className={classes.container}
                        data-testid="step-date"
                        key={`step-location-item-${index}`} /* eslint-disable-line react/no-array-index-key */
                    >
                        <img
                            className={classes.icon}
                            src={iconPath}
                            alt="date"
                        />
                        {dateToStrTimezone(date) || EMPTY_MSG}
                    </div>
                )
            })}
        </>
    )
}

export default StepDate
