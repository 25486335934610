import isNil from 'lodash/isNil'
import {
    Validate,
} from 'app/types/form.types'

export const rentalDaysValidate: Validate<number> = (value) => {
    if (isNil(value)) return undefined
    if (value < 5 || value > 30) {
        return ['The value must be between 5 and 30 days.']
    }
    return undefined
}

export const logisticDaysValidate: Validate<number> = (value) => {
    if (isNil(value)) return undefined
    if (value < 0 || value > 30) {
        return ['The value must be between 0 and 30 days.']
    }
    return undefined
}
