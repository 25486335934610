import React from 'react'
import camelCase from 'lodash/camelCase'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'

import {
    DTOCardFormField,
} from 'app/types/form.types'

import Form from '../../Form'

import useStyles from './GroupNoCard.style'

const defaultProps = {
    classNames: {},
}

const GroupNoCard = (props: Readonly<{
    fields: DTOCardFormField<any>[],
    classNames: {
        gridCardWrapper: string,
        title: string,
    }
}>) => {
    const {
        fields,
        classNames,
    } = props

    const {
        classes,
    } = useStyles()

    return (
        <>
            {
                fields.map(({
                    title,
                    getTitlePanelElements,
                    titleIcon,
                    className,
                    classNames: classNamesField,
                    fields: groupFields,
                    id,
                    'data-testid': dataTestid = 'group',
                }) => {
                    return (
                        <div
                            key={`${camelCase(title)}-${id}`}
                            data-testid={dataTestid}
                        >
                            <Typography
                                variant="h3"
                                className={classNamesField?.title || classNames.title}
                            >
                                <div className={classes.titlePanelContainer}>
                                    <div>
                                        {titleIcon && (
                                            <img
                                                className={classes.titleIcon}
                                                src={titleIcon}
                                                alt={title}
                                            />
                                        )}
                                        {title}
                                    </div>
                                    {
                                        getTitlePanelElements ? (
                                            <div>
                                                {
                                                    (getTitlePanelElements() || []).map((el) => {
                                                        return el
                                                    })
                                                }
                                            </div>
                                        ) : null
                                    }
                                </div>
                            </Typography>

                            <div
                                className={clsx(
                                    classes.gridCardWrapper,
                                    classNames.gridCardWrapper,
                                    className,
                                )}
                            >
                                {
                                    groupFields[0].fields && groupFields.map((item) => {
                                        return (
                                            <div
                                                key={`Form-${item.id}`}
                                                className={item.className}
                                            >
                                                <Form
                                                    {...props} /* eslint-disable-line */
                                                    fields={item.fields}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                {
                                    !groupFields[0].fields && (
                                        <Form
                                            {...props} /* eslint-disable-line */
                                            fields={groupFields}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

GroupNoCard.defaultProps = defaultProps

export default GroupNoCard
