import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
        },
        icon: {
            paddingTop: theme.spacing(1),
            height: `${theme.spacing(2)} !important`,
        },
        error: {
            color: theme.palette.error.main,
        },
        success: {
            color: theme.palette.success.main,
        },
    }
})

export default useStyles
