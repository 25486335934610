import React, {
    useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import usePricingRoutes from '../hooks/usePricingRoutes'

import AlLeasePricingCreate from './AlLeasePricingCreate'
import AlLeasePricingOverview from './AlLeasePricingOverview'
import getTableConfig from './AlLeasePricing.config'
import request from './AlLeasePricing.requests'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const AlLeasePricing = () => {
    const {
        AlLeasePricing: AlLeasePricingRoutes,
    } = usePricingRoutes()

    const tabs: SidebarTab[] = [{
        url: AlLeasePricingRoutes.Update.route,
        label: 'Al Lease Pricing',
        Component: AlLeasePricingOverview,
    }]

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            actionComponents={{
                Create: AlLeasePricingCreate,
            }}
            name={tableConfig.name}
            sidebarTabs={tabs}
            defaultTab={AlLeasePricingRoutes.Update.route}
        >
            <SkyNetTable
                moduleName={request.domainName}
                tableControls={tableControls}
                tableConfig={tableConfig}
            />
        </SkyNetDomain>
    )
}

export default AlLeasePricing
