import React, {
    useMemo,
} from 'react'
import Card from 'app/shared-components/Card'
import CollapsibleTable from 'app/shared-components/CollapsibleTable'
import requests from 'app/shared-components/AreaSelector/AreaSelector.request'

import requestsForDomain from '../LocationAreas.requests'
import useGetLocationAreasByLocationId from '../hooks/useGetLocationAreasByLocationId'
import useLocationAreasContext from '../hooks/useLocationAreasContext'
import LocationAreasCreate from '../LocationAreasCreate'
import LocationAreasUpdate from '../LocationAreasUpdate'

import useStyles from './LocationAreasTable.styles'
import columns from './locationAreasTable.columns'

const LocationAreasTable = () => {
    const {
        locationId,
    } = useLocationAreasContext()
    const {
        classes,
    } = useStyles()

    const requestsHandlers = useGetLocationAreasByLocationId(locationId)
    const actionComponents = useMemo(() => {
        return {
            Update: LocationAreasUpdate,
            Create: LocationAreasCreate,
        }
    }, [])
    const customUrl = useMemo(() => {
        return requests.filterRequest({
            id: locationId,
        })?.url
    }, [locationId])

    return (
        <div
            className={classes.root}
        >
            <Card
                fullHeight
                contentClass={classes.cardContent}
            >
                <CollapsibleTable
                    title="Areas"
                    domainName={requestsForDomain.domainName}
                    columns={columns}
                    customUrl={customUrl}
                    requestHandlers={requestsHandlers}
                    tableControls={[]}
                    actionComponents={actionComponents}
                />
            </Card>
        </div>
    )
}

export default LocationAreasTable
