import React, {
    useCallback,
    useState, useMemo,
} from 'react'
import isEqual from 'lodash/isEqual'

import useEnumValues from 'app/hooks/useEnumValues'
import useLabel from 'app/hooks/useLabel'
import SkyNetSpreadSheet, {
    SkyNetSpreadSheetControlPanel,
} from 'app/shared-components/SkyNetSpreadSheet'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import {
    FilterContextType,
    FilterOptions,
    FilterContext,
} from 'app/hooks/useFilter'

import {
    OtherLaneFeesTable,
} from '../OtherLaneFees.types'
import requests from '../OtherLaneFees.request'

import getConfig from './OtherLaneFeesSpreadsheet.config'
import feesFilterConfig from './OtherLaneFeesSpreadsheet.filterConfig'

export default function OtherLaneFeesSpreadsheet({
    filterOptions,
    onSave,
    onCancel,
}: Readonly<{
    filterOptions?: FilterOptions<OtherLaneFeesTable[]>,
    onSave?: (v: OtherLaneFeesTable[], o?: AsyncMutationOptions) => void,
    onCancel?: (...args: any[]) => void,
}>) {
    const getAllowedValues = useEnumValues()
    const getLabel = useLabel()
    const [
        value,
        setValue,
    ] = useState<OtherLaneFeesTable[]>(filterOptions?.filteredData)

    const handleSave = useCallback(async (options?: { onSuccess?: (...a: any) => void }) => {
        return onSave(value, options)
    }, [
        onSave,
        value,
    ])

    const config = useMemo(() => {
        return getConfig({
            getLabel,
            getAllowedValues,
        })
    }, [
        getLabel,
        getAllowedValues,
    ])

    const edited = !isEqual(filterOptions?.filteredData, value)

    const saveBeforeFilter = useCallback((setFilter) => {
        handleSave({
            onSuccess: setFilter,
        })
    }, [handleSave])

    const filterConfig: FilterContextType = useMemo(() => {
        return {
            ...filterOptions,
            config: feesFilterConfig,
            request: requests.filterCount,
            beforeFilterCallback: saveBeforeFilter,
            edited,
        }
    }, [
        edited,
        filterOptions,
        saveBeforeFilter,
    ])

    return (
        <FilterContext.Provider value={filterConfig}>
            <SkyNetSpreadSheetControlPanel
                onSave={handleSave}
                onCancel={onCancel}
                edited={edited}
            >
                <SkyNetSpreadSheet
                    config={config}
                    value={value}
                    disabled={false}
                    onChange={setValue}
                />
            </SkyNetSpreadSheetControlPanel>
        </FilterContext.Provider>
    )
}
