import React, {
    useCallback, useMemo, useState,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import {
    LoggerType,
} from 'app/types/enums'
import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import LoggerRequest from '../Logger.request'
import {
    LoggerResponse,
} from '../Loggers.types'

import loggerCreateFields from './LoggerCreate.fields'

type LoggerCreateFormData = Pick<LoggerResponse, 'id' | 'loggerStatus' | 'loggerNumber' | 'loggerType'>

const LoggerCreate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    const [
        value,
        setValue,
    ] = useState<LoggerCreateFormData>({})

    const fields = useMemo(() => {
        return loggerCreateFields(value?.loggerStatus)
    }, [value?.loggerStatus])

    const onChangeValue = useCallback((val: LoggerCreateFormData) => {
        const newValue = {
            ...val,
        }

        if (JSON.stringify(newValue) !== JSON.stringify(value)) {
            setValue(newValue)
        }
    }, [value])

    const onSuccessLocal = useCallback((obj) => {
        let loggerNumber = value?.loggerNumber

        if ([
            LoggerType.CARTASENSE,
            LoggerType.CARTASENSE_M,
        ].includes(value?.loggerType)) {
            loggerNumber = value?.loggerNumber.padStart(10, '0')
        }

        onSuccess({
            ...obj || {},
            loggerNumber,
        })
    }, [
        onSuccess,
        value,
    ])

    return (
        <FormWithControls
            name={LoggerRequest.domainName}
            value={value}
            fields={fields}
            onSuccess={onSuccessLocal}
            requestParams={LoggerRequest.create()}
            setExternalValue={onChangeValue}
        />
    )
}

export default LoggerCreate
