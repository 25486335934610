import {
    SkyNetTableConfig, SkyNetTableField, injectFieldsToTabs,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    InvoiceDesignation, OrderStatuses,
} from 'app/types/enums'
import {
    InvoiceStatus,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'

type OrderInvoicesView = {
    orderNumber: string,
    orderStatus: string,
    hasBillingCompleted: boolean,
    billingCompanyLocationName: string,
    customerLocationName: string,
    from: string,
    to: string,
    requestedHandover: string,
    accountCompanyCompanyName: string,
    outgoingInvoicesDetails: {
        invoiceDesignation: InvoiceDesignation,
        invoiceStatus: InvoiceStatus,
    }[],
    billedAmount: number,
}

type OrderInvoicesViewFields = keyof OrderInvoicesView

export enum InvoicesTableTabs {
    MissingBillingCompany = 'missing-billing-company',
    BaseLeaseCreated = 'base-lease-created',
    ExtraDaysToBeCreated = 'extra-days-to-be-created',
    ExtraDaysCreated = 'extra-days-created',
    CancelledBilling = 'cancelled-billing',
    BillingCompleted = 'billing-completed',
    RequiredManualBillingReview = 'requires-manual-billing-review',
    NotSyncedBillingCompany = 'not-synced-billing-company',
    BillingNotCompleted = 'billing-not-completed',
}

export default ({
    theme,
}): SkyNetTableConfig<OrderInvoicesViewFields> => {
    const fields: SkyNetTableField<OrderInvoicesViewFields>[] = [
        {
            name: 'orderNumber',
            labelKey: 'OrderView.orderNumber',
            reduced: true,
        },
        {
            name: 'orderStatus',
            filterField: 'orderStatus',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: OrderStatuses,
            labelKey: 'OrderView.orderStatus',
            reduced: true,
        },
        {
            name: 'hasBillingCompleted',
            componentName: 'ChipCheckMark',
        },
        {
            name: 'billingCompanyLocationName',
            filterField: 'billingCompanyLocationName',
            filterType: FilterType.Array,
            reduced: true,
        },
        {
            name: 'customerLocationName',
            filterField: 'customerLocationName',
            filterType: FilterType.Array,
        },
        {
            name: 'accountCompanyCompanyName',
            filterField: 'accountCompanyCompanyName',
            labelKey: 'AccountQuotes.accountName',
            filterType: FilterType.Array,
        },
        {
            name: 'requestedHandover',
            filterField: 'requestedHandover',
            filterType: FilterType.Array,
            componentName: 'DateTimeCell',
        },
        {
            name: 'from',
            filterField: 'from',
            filterType: FilterType.Array,
            labelKey: 'OrderView.from',
        },
        {
            name: 'to',
            filterField: 'to',
            filterType: FilterType.Array,
            labelKey: 'OrderView.to',
        },
        {
            name: 'outgoingInvoicesDetails',
            labelKey: 'OrderInvoicesView.invoices',
            componentName: 'EnumListMultipleColored',
            enumsConfig: [
                {
                    name: 'invoiceStatus',
                    allowedValuesEnum: InvoiceStatus,
                    colorConfig: {
                        [InvoiceStatus.CANCELLED]: theme?.palette.common.orange,
                        [InvoiceStatus.CREATED]: theme?.palette.common.lightYellow,
                        [InvoiceStatus.PAID]: theme?.palette.common.green,
                        [InvoiceStatus.OVERDUE]: theme?.palette.common.red,
                        [InvoiceStatus.SENTTOABACUS]: theme?.palette.common.yellow,
                        [InvoiceStatus.CHECKEXTRADAYS]: theme?.palette.common.beige,
                        [InvoiceStatus.SENTTOBILLINGCOMPANY]: theme?.palette.primary.main,
                    },
                    renderAs: 'tooltip',
                },
                {
                    name: 'invoiceDesignation',
                    allowedValuesEnum: InvoiceDesignation,
                    renderAs: 'chip',
                },
            ],
            sorting: false,
        },
        {
            name: 'billedAmount',
            sorting: false,
        },
    ]

    return {
        name: 'InvoicesTable',
        tabs: injectFieldsToTabs<OrderInvoicesViewFields>([
            {
                name: 'All Orders',
                url: CommonRoutes.ALL,
                requestUrl: 'customer-transport',
            },
            {
                name: 'Missing Billing Company',
                url: InvoicesTableTabs.MissingBillingCompany,
            },
            {
                name: 'BL Created',
                url: InvoicesTableTabs.BaseLeaseCreated,
            },
            {
                name: 'ED To Be Created',
                url: InvoicesTableTabs.ExtraDaysToBeCreated,
            },
            {
                name: 'ED Created',
                url: InvoicesTableTabs.ExtraDaysCreated,
            },
            {
                name: 'CA To Be Created',
                url: InvoicesTableTabs.CancelledBilling,
            },
            {
                name: 'Billing Completed',
                url: InvoicesTableTabs.BillingCompleted,
            },
            {
                name: 'Manual Review',
                url: InvoicesTableTabs.RequiredManualBillingReview,
            },
            {
                name: 'Not Synced Billing Company',
                requestUrl: 'customer-transport',
                includeFilters: {
                    billingBillingCompanyIsSyncedWithAbacus: [false],
                },
                url: InvoicesTableTabs.NotSyncedBillingCompany,
            },
            {
                name: 'Billing Not Completed',
                requestUrl: 'customer-transport',
                includeFilters: {
                    hasBillingCompleted: [false],
                },
                url: InvoicesTableTabs.BillingNotCompleted,
            },
        ], fields),
    }
}
