import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    OrderStatuses, OrderTemperatureRange,
} from 'app/types/enums'
import {
    PreviousOrderViewFields, TransportModeRoad, TransportModeSea,
} from '../../../orders.types'

const columns: RichTableColumns<PreviousOrderViewFields>[] = [
    {
        id: 'orderNumber',
        headerProps: {
            children: 'Order',
        },
        mapCellProps: 'orderNumber',
    },
    {
        id: 'from',
        headerProps: {
            children: 'From',
        },
        mapCellProps: 'from',
    },
    {
        id: 'to',
        headerProps: {
            children: 'To',
        },
        mapCellProps: 'to',
    },
    {
        id: 'pricingAccountCompanyName',
        headerProps: {
            children: 'Pricing Account',
        },
        mapCellProps: 'pricingAccountCompanyName',
    },
    {
        id: 'customerLocationName',
        headerProps: {
            children: 'Customer',
        },
        mapCellProps: 'customerLocationName',
    },
    {
        id: 'consigneeLocationName',
        headerProps: {
            children: 'Consignee',
        },
        mapCellProps: 'consigneeLocationName',
    },
    {
        id: 'leaseEndDate',
        headerProps: {
            children: 'Lease End Date',
        },
        mapCellProps: 'leaseEndDate',
        componentName: 'DateTimeCell',
    },
    {
        id: 'requiredContainers',
        headerProps: {
            children: 'Containers',
        },
        mapCellProps: 'requiredContainers',
        componentName: 'List',
    },
    {
        id: 'transportModeSea',
        headerProps: {
            children: 'Mode',
        },
        mapCellProps: 'transportModeSea',
        componentName: 'EnumValue',
        allowedValuesEnum: TransportModeSea,
    },
    {
        id: 'transportModeRoad',
        headerProps: {
            children: 'Mode',
        },
        mapCellProps: 'transportModeRoad',
        componentName: 'EnumValue',
        allowedValuesEnum: TransportModeRoad,
    },
    {
        id: 'temperatureRange',
        headerProps: {
            children: 'Range',
        },
        mapCellProps: 'temperatureRange',
        componentName: 'EnumValue',
        allowedValuesEnum: OrderTemperatureRange,
    },
    {
        id: 'orderStatus',
        headerProps: {
            children: 'Status',
        },
        mapCellProps: 'orderStatus',
        componentName: 'EnumValue',
        allowedValuesEnum: OrderStatuses,
    },
]

export default columns
