import React, {
    useCallback, useMemo,
} from 'react'

import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import Typeahead from 'app/shared-components/Typeahead'
import useGetAccount from 'app/Apps/AccountManagement/hooks/useGetAccount'
import useGetCompany from 'app/Apps/ContactManagement/Companies/hooks/useGetCompany'
import requests from 'app/Apps/AccountManagement/AccountManagement.request'

type Props = {
    name?: string,
    value?: {
        id: number | string,
    },
    onChange: (value: {id: number}) => void,
    title?: string,
    disabled?: boolean,
    className?: string,
    required?: boolean,
    placeholder?: string,
    noneOption?: boolean,
    requestParams?: Record<string, any>,
    includeFilters: Record<string, any>
}

const defaultProps : Partial<Props> = {
    name: '',
    value: undefined,
    title: 'Accounts',
    disabled: false,
    className: '',
    required: false,
    placeholder: undefined,
    noneOption: true,
    requestParams: undefined,
}

const AccountSelector = ({
    name,
    value,
    onChange,
    title,
    className,
    disabled,
    required,
    placeholder,
    noneOption,
    requestParams,
    includeFilters,
}: Props) => {
    const selectorParams = useMemo(() => {
        return requests.selector({
            params: requestParams,
            includeFilters,
        })
    }, [
        requestParams,
        includeFilters,
    ])

    const loadOptions = useDelayedLoadOptions(selectorParams)

    const {
        data: account,
    } = useGetAccount(value?.id)

    const {
        data: company,
    } = useGetCompany(account?.company?.id)

    const onChangeValue = useCallback((newValue) => {
        const convertedValue = newValue ? {
            id: newValue,
        } : undefined

        onChange(convertedValue)
    }, [onChange])

    return (
        <Typeahead
            name={name}
            title={title}
            className={className}
            onChange={onChangeValue}
            loadOptions={loadOptions}
            labelData={company}
            labelField="companyName"
            value={value?.id}
            disabled={disabled}
            isRequired={required}
            placeholder={placeholder}
            noneOption={noneOption}
        />
    )
}

AccountSelector.defaultProps = defaultProps

export default AccountSelector
