/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useMemo,
} from 'react'
import {
    TableCellProps,
} from '@mui/material'

import useLabel from 'app/hooks/useLabel'
import currency from 'app/utils/currency'
import getUniqueId from 'app/utils/getUniqueId'
import SimpleTable from 'app/shared-components/SimpleTable'
import {
    Fee,
} from 'app/Apps/Pricing/Pricing.types'
import FxRate from 'app/shared-components/FxRate/FxRate.types'
import {
    ProductTypeContainerTempRange,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import FeeInputs from './FeeInputs'

type Props = {
    value?: Fee[],
    fxRate?: FxRate,
    onChange: (value: Fee) => void,
    addEntry: () => void,
    deleteEntry: (index: number) => void,
    disabled?: boolean,
    currency?: string,
    name?: string,
    tempRange?: string,
    productTypes?: ProductTypeContainerTempRange[],
    feeValueLabel: string,
}

const defaultProps: Partial<Props> = {
    value: [],
    disabled: false,
    currency: undefined,
    fxRate: undefined,
    name: undefined,
    tempRange: undefined,
    productTypes: [],
}

const Fees = ({
    value,
    fxRate,
    onChange,
    addEntry,
    disabled,
    deleteEntry,
    currency: selectedCurrency,
    name,
    tempRange,
    productTypes,
    feeValueLabel,
}: Props) => {
    const l = useLabel()

    const defaultColumns = useMemo(() => {
        return [
            {
                label: l('Fee.feeName'),
                id: getUniqueId(),
            },
            {
                label: l('Fee.feeChargeability'),
                id: getUniqueId(),
            },
            {
                label: l('Fee.productType'),
                id: getUniqueId(),
            },
            {
                label: l('Fee.feeDescription'),
                id: getUniqueId(),
            },
            {
                label: feeValueLabel,
                align: 'center' as TableCellProps['align'],
                id: getUniqueId(),
            },
        ]
    }, [
        l,
        feeValueLabel,
    ])

    const extraCurrency = useMemo(() => {
        return {
            label: selectedCurrency,
            align: 'center' as TableCellProps['align'],
            id: getUniqueId(),
        }
    }, [selectedCurrency])

    const columns = useMemo(() => {
        if (selectedCurrency !== currency.default
                    && fxRate?.exchangeRate !== 1) {
            return [
                ...defaultColumns,
                extraCurrency,
            ]
        }
        return defaultColumns
    }, [
        defaultColumns,
        fxRate,
        selectedCurrency,
        extraCurrency,
    ])

    return (
        <SimpleTable
            columns={columns}
            data={value}
            addEntry={addEntry}
            onChange={onChange}
            onDelete={deleteEntry}
            disabled={disabled}
            component={FeeInputs}
            renderComponent={(tableProps) => {
                return (
                    <FeeInputs
                        {...tableProps}
                        currency={selectedCurrency}
                        feeName={name}
                        productTypes={productTypes}
                        tempRange={tempRange}
                        fxRate={fxRate}
                    />
                )
            }}
        />
    )
}

Fees.defaultProps = defaultProps

export default Fees
