import {
    useMemo,
} from 'react'
import {
    App, useSkyNetNavigate,
} from 'app/SkyNetRoutes'
import {
    createRoutesConfig,
} from 'app/SkyNetRoutes/skyNetRoutes.utils'
import {
    SkyNetRoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'
import {
    RatesDomains,
} from '../Rates.types'
import {
    RateCardsTabs,
} from '../RateCards/RateCards.types'

export default function useRatesRoutes() {
    const skyNetNavigate = useSkyNetNavigate()

    return useMemo(() => {
        const config: SkyNetRoutesConfig = {
            path: [App.RATES],
            RateCards: {
                route: RatesDomains.RateCards,
                ...(Object.entries(RateCardsTabs).reduce((acc, [
                    key,
                    value,
                ]) => {
                    return {
                        ...acc,
                        [key]: {
                            route: value,
                        },
                    }
                }, {})),
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [skyNetNavigate])
}
