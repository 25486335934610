import {
    ContainerStatus, DefaultTemperatureRange,
    LaneGroupTemperatureRange, RejectionReason,
} from 'app/types/enums'
import {
    IncoTerm,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    RequiredContainersCustomer,
} from 'app/types/common.types'

import {
    ModificationReason,
    OrderStatusTransition,
} from '../Orders/orders.types'

export enum StatusLongTermLease {
    NOT_STARTED = 'NOT_STARTED',
    CONFIRMED = 'CONFIRMED',
    BOOKED = 'BOOKED',
    RENTAL = 'RENTAL',
    CLOSED = 'CLOSED',
}

export type LongTermLeaseTableType = {
    longTermLeaseNumber: string,
    customerExecutionLocationName: string,
    customerExecutionContactsContactName: string[],
    pricingAccountCompanyName: string,
    requiredContainers: RequiredContainersCustomer[],
    leaseStartTimestamp: string,
    status: StatusLongTermLease,
}

export type LongTermLeaseType = {
    longTermLeaseNumber: string,
    customerExecution: {id: number},
    customerExecutionContacts: {id: number}[],
    pricingAccount: {id: number},
    customerComment: string,
    customerReference: string,
    receivedOn: string,
    enteredOn: string,
    tempRange: LaneGroupTemperatureRange,
    incoTerm: IncoTerm,
    requiredContainers: RequiredContainersCustomer [],
    leaseStartTimestamp: string,
    leaseEndTimestamp: string,
    commentForBillingOps: string,
    createdBy: string,
    baseLeaseDays: number,
    status: StatusLongTermLease,
    statusTransitions: OrderStatusTransition[],
    id: number,
}

export type ModificationRequiredContainersType = {
    modificationReason: ModificationReason,
    modificationComment: string,
    cargoComment?: string,
    requiredContainers: RequiredContainersCustomer[],
}

export type AvailableContainersType = {
    id: number,
    changedOn: string,
    serialNumber: string,
    jypId: string,
    containerStatus: ContainerStatus,
    containerTypeContainerModel: string,
    locationLocationName: string,
    tempRange: LaneGroupTemperatureRange,
    currentLongTermLeaseId: number,
}

export type DedicatedContainerType = {
    id: number,
    containerId: number,
    containerSerialNumber: string,
    containerJypId: string,
    containerContainerStatus: ContainerStatus,
    containerContainerTypeContainerModel: string,
    containerLocationLocationName: string,
    containerTempRange: DefaultTemperatureRange,
    rejectionReason: RejectionReason,
    rejectionComment: string,
}

export type RejectContainersRequestData = {
    rejectionReason: RejectionReason,
    rejectionComment?: string,
    bookedContainer: {
        id: number,
    },
}

export enum LongTermLeaseTabs {
    Containers = 'containers',
}
