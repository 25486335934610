import {
    Dispatch, SetStateAction,
} from 'react'
import {
    DefaultCellTypes, Row,
} from '@silevis/reactgrid'
import isFunction from 'lodash/isFunction'

import isEmpty from 'lodash/isEmpty'
import {
    SkyNetSpreadSheetCellType,
    SkyNetSpreadSheetConfigType,
    SuperHeaderConfig,
    SkyNetSpreadSheetColumn,
} from './SkyNetSpreadSheet.types'

import getCellPropsByType from './getCellPropsByType'
import getValidated from './SkyNetSpreadsheetValidation/getValidated'

// returns cell settings based on config
const getCellProps = ({
    cellConfig, required, disabled, hidden,
    styles, name, item, showError, valid, setValid, validateRow,
}) => {
    const errors = getValidated({
        name, validate: cellConfig.validate, required, valid, setValid, validateRow,
    })(item)

    return {
        ...cellConfig,
        errors,
        nonEditable: disabled,
        style: {
            ...styles.cell,
            ...(required ? styles.required : {}),
            ...(disabled ? styles.disabled : {}),
            ...(hidden ? styles.hidden : {}),
            ...(errors?.length && showError ? styles.errors : {}),
            ...cellConfig.style,
        },
    }
}

// returns type of cell to be applied
// useful when we have conditions when cell can be different types depends on some conditions
const getCellType = (cellConfig, item) => {
    if (cellConfig.conditionalType) {
        const condition = cellConfig.conditionalType.find(
            ([conditionFn]) => { return conditionFn(item) },
        )

        return condition?.[1] || cellConfig.type
    }

    return cellConfig.type
}

// adds row counter cells
const getRowCounter = (rowNr: string | number, rowsCounter, styles) => {
    return rowsCounter ? [{
        type: SkyNetSpreadSheetCellType.NO_VALUE,
        text: String(rowNr),
        nonEditable: true,
        style: styles.header,
    }] : []
}

// creates header row parameters
const getHeaderRow = (config, styles): Row => {
    return {
        rowId: 'header',
        cells: [
            ...getRowCounter('', config.rowsCounter, styles),
            ...config.fields.map(({
                name,
                title,
                hidden,
                cellConfig,
            }) => {
                return {
                    type: config.filterable
                        ? SkyNetSpreadSheetCellType.HEADER_FILTERABLE
                        : SkyNetSpreadSheetCellType.HEADER,
                    name,
                    text: title || '',
                    style: {
                        ...styles.header,
                        ...cellConfig?.headerStyle,
                        ...(hidden ? styles.hidden : {}),
                    },
                }
            }),
        ] as DefaultCellTypes[],
        height: config?.headerHeight || config?.rowHeight,
    }
}

export const getHeaderConfig = (config, styles): Row[] => {
    let headerRows: Row[] = []

    if (config.superHeader) {
        headerRows = config.superHeader.reduce((acc, {
            height, cells = [],
        }: SuperHeaderConfig, currentIndex: number) => {
            return [
                ...acc,
                {
                    rowId: `superheader-${currentIndex}`,
                    height: height ?? (config.headerHeight || config.rowHeight),
                    cells: [
                        ...getRowCounter('', config.rowsCounter, styles),
                        ...cells.reduce((cellsAcc, {
                            title, colspan, style,
                        }) => {
                            return [
                                ...cellsAcc,
                                ...Array.from(Array(colspan)).map((_, i) => {
                                    return {
                                        type: SkyNetSpreadSheetCellType.HEADER,
                                        text: i === 0 ? title : '',
                                        colspan: i === 0 ? colspan : undefined,
                                        style: {
                                            ...styles.header,
                                            ...styles.superHeader,
                                            ...style || {},
                                        },
                                    }
                                }),
                            ]
                        }, [] as DefaultCellTypes[]),
                    ],
                },
            ]
        }, headerRows)
    }

    if (config.headerRow) {
        headerRows.push(getHeaderRow(config, styles))
    }

    return headerRows
}

// used for row-to-data conversion
// returns value from cell based on its type
export const getCellValue = (cell) => {
    switch (cell.type) {
    case SkyNetSpreadSheetCellType.NO_VALUE: return undefined
    case SkyNetSpreadSheetCellType.PERCENTAGE:
    case SkyNetSpreadSheetCellType.NUMBER: {
        if (cell?.nanToZero) return Number.isNaN(cell.value) ? 0 : cell.value
        return Number.isNaN(cell.value) ? undefined : cell.value
    }
    case SkyNetSpreadSheetCellType.SELECT: return cell.selectedValue || undefined
    case SkyNetSpreadSheetCellType.MULTIPLE_SELECT: return cell.selectedValue || []
    case SkyNetSpreadSheetCellType.TYPEAHEAD:
        return (cell.selectedValue?.value || cell.selectedValue?.id)
            ? cell.selectedValue : undefined
    case SkyNetSpreadSheetCellType.MULTIPLE_TYPEAHEAD: return cell.selectedValue || []
    case SkyNetSpreadSheetCellType.TEXT: return isEmpty(cell.text) ? undefined : cell.text
    case SkyNetSpreadSheetCellType.DATETIME: return cell.text
    case SkyNetSpreadSheetCellType.ID: return cell.id || cell.text
    case SkyNetSpreadSheetCellType.CHECKBOX: return Boolean(cell.checked || cell.value)
    default: return cell.selectedValue || cell.value || cell.text
    }
}

// returns value for cell based on config
const getValue = ({
    name, item, calcValue, noValue,
}: {
    name: string,
    item: Record<string, any>,
    calcValue: (...args: any[]) => any,
    noValue: boolean
}) => {
    if (noValue) {
        return null
    }

    if (calcValue) {
        return calcValue(item)
    }

    return item[name]
}

// returns function-reducer used for row-to-data conversion
export const rowToDataConverter = (columns: SkyNetSpreadSheetColumn[]) => {
    return (acc, {
        rowId, cells,
    }: Row) => {
        return String(rowId).includes(SkyNetSpreadSheetCellType.HEADER) ? acc : [
            ...acc,
            ...[cells.reduce((cellAcc, cell, i) => {
                if (columns[i].columnId === 'rowsCounter' || columns[i].calcValue) {
                    return cellAcc
                }

                return {
                    ...cellAcc,
                    [columns[i].columnId]: getCellValue(cell),
                }
            }, {} as Record<string, any>)],
        ]
    }
}

// create rows based on data and config
export default ({
    data, config, styles, spreadSheetDisabled, showError, valid, setValid, validateRow,
}: {
    data: Record<string, any>[],
    config: SkyNetSpreadSheetConfigType<any>,
    styles: Record<string, any>,
    spreadSheetDisabled: boolean,
    showError: boolean,
    valid: boolean,
    setValid: Dispatch<SetStateAction<boolean>>,
    validateRow: (item: Record<string, any>) => boolean,
}): Row[] => {
    return (data || []).reduce((rowAcc: Row[], item, idx) => {
        return [
            ...rowAcc,
            {
                rowId: idx,
                height: config?.rowHeight,
                cells: [
                    ...getRowCounter(idx + 1, config.rowsCounter, styles),
                    ...config.fields.map((cell) => {
                        const {
                            getAllowedValues,
                            required: cellRequired,
                            disabled: cellDisabled,
                            loadOptions,
                            applyFilters,
                            domain,
                            hidden,
                            name,
                            format,
                            calcValue,
                            defaultValue,
                            renderAsSelectValues,
                            noneOption,
                            cellConfig = {
                                type: SkyNetSpreadSheetCellType.TEXT,
                            },
                            hideZero, nanToZero,
                        } = cell as Record<string, any>

                        const type = getCellType(cellConfig, item)
                        const noValueCell = type === SkyNetSpreadSheetCellType.NO_VALUE
                        const required = !noValueCell && cellRequired
                        const disabled = spreadSheetDisabled || noValueCell
                            || (isFunction(cellDisabled) ? cellDisabled(item) : cellDisabled)
                        const cellConfigStyle = isFunction(cellConfig?.style)
                            ? cellConfig?.style(item) : cellConfig?.style

                        return {
                            ...getCellProps({
                                cellConfig: {
                                    ...cellConfig, type, style: cellConfigStyle,
                                },
                                required,
                                disabled,
                                hidden,
                                styles,
                                name,
                                item,
                                showError,
                                valid,
                                setValid,
                                validateRow,
                            }),
                            ...getCellPropsByType({
                                type,
                                name,
                                value: getValue({
                                    name,
                                    item,
                                    calcValue,
                                    noValue: noValueCell,
                                }),
                                loadOptions,
                                applyFilters,
                                domain,
                                getAllowedValues,
                                defaultValue,
                                format,
                                item,
                                renderAsSelectValues,
                                noneOption,
                                hideZero,
                                nanToZero,
                            }),
                        }
                    }),
                ],
            },
        ]
    }, getHeaderConfig(config, styles)) as Row[]
}
