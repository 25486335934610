import React, {
    useCallback, useMemo, useState,
} from 'react'
import isEqual from 'lodash/isEqual'

import SkyNetSpreadSheet, {
    SkyNetSpreadSheetControlPanel,
    useTypeaheadOptions,
} from 'app/shared-components/SkyNetSpreadSheet'
import useLabel from 'app/hooks/useLabel'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    useOpportunityLoadOptions,
} from 'app/shared-components/OpportunitiesSelector'
import {
    FilterContext, FilterContextType, FilteredData,
} from 'app/hooks/useFilter'
import {
    useLocationAirportLoadOptions,
    standardLocationConvertionFn,
} from 'app/shared-components/LocationSelector'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import contactsRequests from 'app/Apps/ContactManagement/Contacts/Contacts.request'
import countriesRequest from 'app/Apps/ContactManagement/Countries/Countries.request'
import DataContentWrapper from 'app/shared-components/DataContentWrapper'
import {
    OpportunityType,
    useFetchOpportunityById,
} from 'app/Apps/AccountManagement/Opportunities'
import {
    useFetchLocationById,
} from 'app/Apps/ContactManagement/Locations/hooks'
import {
    showLTL, showFTL, isDeliveryRoad, isCollection,
} from 'app/Apps/Pricing/utils/utils'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import {
    AddressCategory,
    LocationType,
} from 'app/types/enums'
import LocationsRequests from 'app/Apps/ContactManagement/Locations/Locations.request'
import {
    AccountLane,
} from '../AccountLanes.types'
import useFetchRegionalGroupById from '../../RegionalGroups/hooks/useFetchRegionalGroupById'

import getConfig, {
    AccountLaneSpreadsheetType,
} from './AccountLaneSpreadsheet.config'
import filterConfig from './AccountLaneSpreadsheet.filterConfig'

const AccountLaneSpreadsheet = ({
    onSave,
    onCancel,
    filterOptions,
}: {
    onSave: (v: AccountLane[], o: AsyncMutationOptions) => void
    onCancel: (...args: any[]) => void,
    filterOptions: FilterContextType,
}) => {
    const [
        value,
        setValue,
    ] = useState(filterOptions?.filteredData as FilteredData<AccountLaneSpreadsheetType[]>)

    const getLabel = useLabel()
    const getAllowedValues = useEnumValues()

    const getOpportunity = useFetchOpportunityById()
    const getRegionalGroupById = useFetchRegionalGroupById()
    const getLocation = useFetchLocationById()

    const handleSave = useCallback(async (options?: AsyncMutationOptions) => {
        return onSave(value.map((lane: AccountLaneSpreadsheetType) => {
            return {
                ...lane,
                ...(lane?.isTemperatureControlled || []).reduce((acc, key) => {
                    return {
                        ...acc,
                        [key]: true,
                    }
                }, {}),
                isTemperatureControlled: undefined,
            }
        }), options)
    }, [
        onSave,
        value,
    ])

    const filterByAccount = useMemo(() => {
        return {
            accountId: filterOptions?.filter?.accountId,
        }
    }, [filterOptions?.filter])

    const loadOpportunityOptions = useOpportunityLoadOptions(filterByAccount)
    const opportunityParams = useTypeaheadOptions({
        load: loadOpportunityOptions,
        filters: filterByAccount,
        isFilterRequired: true,
    })

    const loadLocationAirportOptions = useLocationAirportLoadOptions()
    const locationAirportParams = useTypeaheadOptions({
        load: loadLocationAirportOptions,
    })

    const loadCountryOptions = useDelayedLoadOptions(countriesRequest.selector)
    const countryParams = useTypeaheadOptions({
        load: loadCountryOptions,
    })

    const loadContactOptions = useDelayedLoadOptions(contactsRequests.selector)
    const contactParams = useTypeaheadOptions({
        load: loadContactOptions,
    })

    const customerExecutionOptions = useMemo(() => {
        return {
            ...LocationsRequests.selector,
            convertion: standardLocationConvertionFn,
            includeFilters: {
                locationType: [LocationType.ADDRESS],
                addressCategory: [
                    AddressCategory.CUSTOMER,
                    AddressCategory.CUSTOMER_QUOTATION,
                ],
            },
        }
    }, [])
    const loadCustomerExecutionOptions = useDelayedLoadOptions(customerExecutionOptions)
    const customerExecutionParams = useTypeaheadOptions({
        load: loadCustomerExecutionOptions,
    })

    const onOpportunityChange = useCallback(async ({
        id,
    }: OpportunityType, lane: AccountLane) => {
        const opportunity = await getOpportunity(id)

        if (opportunity) {
            const originAirport = await getLocation(opportunity.reprOriginAirport.id)
            const destinationAirport = await getLocation(opportunity.reprDestinationAirport.id)
            const regionalGroup = await getRegionalGroupById(opportunity.regionalGroup?.id)

            return {
                temperatureRange: regionalGroup?.tempRange,
                originAirport: {
                    ...originAirport, label: originAirport?.iataCode,
                },
                destinationAirport: {
                    ...destinationAirport, label: destinationAirport?.iataCode,
                },
                expPalletsYear: lane?.expPalletsYear ?? 1,
            }
        }
        return undefined
    }, [
        getLocation,
        getOpportunity,
        getRegionalGroupById,
    ])

    const isNotDeliveryByRoad = useCallback((lane: AccountLane) => {
        return !isDeliveryRoad(lane.deliveryServiceType)
    }, [])

    const isNoTransportModeRoad = useCallback((lane: AccountLane) => {
        return !lane.transportModeRoad
    }, [])

    const isNotCollection = useCallback((lane: AccountLane) => {
        return !isCollection(lane.collectionServiceType)
    }, [])

    const getTemperatureControlledValues = useCallback((lane: AccountLane) => {
        let result = {}

        if (showFTL(lane.transportModeRoad)) {
            result = {
                ...result,
                isFtlPositioningTemperatureControlled: getLabel('AccountLane.isFtlPositioningTemperatureControlled'),
            }
        }

        if (showLTL(lane.transportModeRoad)) {
            result = {
                ...result,
                isLtlPositioningTemperatureControlled: getLabel('AccountLane.isLtlPositioningTemperatureControlled'),
            }
        }

        return result
    }, [getLabel])

    const config = useMemo(() => {
        return getConfig({
            getLabel,
            getAllowedValues,
            opportunityParams,
            addRowMenuOption: true,
            countryParams,
            contactParams,
            locationAirportParams,
            onOpportunityChange,
            isNotDeliveryByRoad,
            isNoTransportModeRoad,
            isNotCollection,
            getTemperatureControlledValues,
            customerExecutionParams,
        })
    }, [
        getLabel,
        getAllowedValues,
        opportunityParams,
        countryParams,
        contactParams,
        locationAirportParams,
        onOpportunityChange,
        isNotDeliveryByRoad,
        isNoTransportModeRoad,
        isNotCollection,
        getTemperatureControlledValues,
        customerExecutionParams,
    ])

    const edited = !isEqual(filterOptions?.filteredData, value)
    const filterContext = useMemo(() => {
        return {
            ...filterOptions,
            config: filterConfig,
            edited,
        }
    }, [
        filterOptions,
        edited,
    ])

    return (
        <FilterContext.Provider value={filterContext}>
            <SkyNetSpreadSheetControlPanel
                onSave={handleSave}
                onCancel={onCancel}
                edited={edited}
            >
                <DataContentWrapper
                    isEmpty={!filterByAccount.accountId}
                    emptyDataReplacer="Please select the account to add new lanes"
                >
                    <SkyNetSpreadSheet
                        config={config}
                        value={value}
                        onChange={setValue}
                    />
                </DataContentWrapper>
            </SkyNetSpreadSheetControlPanel>
        </FilterContext.Provider>
    )
}

export default AccountLaneSpreadsheet
