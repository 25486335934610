import React, {
    useCallback,
    useMemo,
} from 'react'
import clsx from 'clsx'

import formatCurrencyValue from 'app/utils/formatCurrencyValue'
import {
    InvoiceStatus,
    OutgoingInvoice,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    Currency,
    InvoiceDesignation,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'

import InvoiceCardContainer from '../InvoiceCardContainer'

import statusMap from './statusMap'
import useStyles from './InvoiceCard.styles'
import useCreateCreditNote from './hooks/useCreateCreditNote'
import useRemoveInvoice from './hooks/useRemoveInvoice'

type Props = {
    selected?: boolean,
    currency?: Currency,
    orderNumber?: string,
    onSuccess: (params: OutgoingInvoice) => void,
    selectInvoice: (OutgoingInvoice) => void,
    invoice?: OutgoingInvoice,
    disabled: boolean,
}

const defaultProps = {
    invoice: {},
    orderNumber: undefined,
    currency: undefined,
    selected: false,
}

const CAN_NOT_BE_DELETED_INVOICES = [
    InvoiceStatus.SENTTOBILLINGCOMPANY,
    InvoiceStatus.OVERDUE,
    InvoiceStatus.PAID,
    InvoiceStatus.CANCELLED,
]

const InvoiceCard = ({
    invoice,
    selectInvoice,
    orderNumber,
    currency,
    selected,
    onSuccess,
    disabled,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const allowedValues = useEnumValues()(InvoiceDesignation)

    const canBeDeleted: boolean = useMemo(() => {
        return disabled || !CAN_NOT_BE_DELETED_INVOICES.includes(invoice.invoiceStatus)
    }, [
        disabled,
        invoice.invoiceStatus,
    ])

    const handleClick = useCallback((): void => {
        selectInvoice(invoice)
    }, [
        invoice,
        selectInvoice,
    ])

    const showRechnung: boolean = useMemo(() => {
        return ![
            InvoiceStatus.CREATED,
            InvoiceStatus.SENTTOABACUS,
            InvoiceStatus.CANCELLED,
        ].includes(invoice.invoiceStatus)
    }, [invoice.invoiceStatus])

    const showAuftrag: boolean = useMemo(() => {
        return ![
            InvoiceStatus.CREATED,
            InvoiceStatus.CANCELLED,
        ].includes(invoice.invoiceStatus)
    }, [invoice.invoiceStatus])

    const {
        mutate: removeInvoice,
    } = useRemoveInvoice(onSuccess)

    const remove = useCallback((): void => {
        removeInvoice({
            orderNumber,
            invoice,
        })
    }, [
        invoice,
        orderNumber,
        removeInvoice,
    ])

    const {
        mutate,
    } = useCreateCreditNote(onSuccess)

    const createCreditNote = (event: React.SyntheticEvent<EventTarget>) => {
        event.stopPropagation()
        mutate({
            data: {
                id: invoice.id,
            },
        })
    }
    const invoicePrice: string = useMemo(() => {
        return formatCurrencyValue(invoice.invoiceAmount)
    }, [invoice.invoiceAmount])

    return (
        <InvoiceCardContainer
            selected={selected}
            handleClick={handleClick}
            canBeDeleted={canBeDeleted}
            title={allowedValues[invoice.invoiceDesignation]}
            removeInvoice={remove}
            createCreditNote={createCreditNote}
            invoiceId={invoice.id}
            invoiceDesignation={invoice.invoiceDesignation}
        >
            <div className={classes.priceContainer}>
                <div
                    className={classes.price}
                    data-testid="price"
                >
                    {`${invoicePrice} ${currency || ''}`}
                </div>
                <div className={classes.wrapper}>
                    {showRechnung && (
                        <div className={classes.info}>
                            {`Rechnung: ${invoice.invoiceNumber}`}
                        </div>
                    )}
                    {showAuftrag && (
                        <div className={classes.info}>
                            {`Auftrag: ${invoice.abacusOrderNumber}`}
                        </div>
                    )}
                </div>
            </div>
            <div className={classes.statusRoot}>
                <div className={clsx(classes.border,
                    invoice.invoiceStatus === InvoiceStatus.CANCELLED
                        ? classes.borderGray
                        : classes.borderBlue)}
                />
                <div className={classes.statusContainer}>
                    <div className={classes.invoiceStatus}>
                        {statusMap[invoice.invoiceStatus].map(({
                            iconPath,
                            title,
                        }) => {
                            return (
                                <div
                                    className={classes.wrapperStatus}
                                    key={title}
                                >
                                    <img
                                        className={classes.icon}
                                        src={`assets/images/${iconPath}.svg`}
                                        alt={title}
                                    />
                                    <div>{title}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={classes.invoiceDate}>
                        {invoice.invoiceDate}
                    </div>
                </div>
            </div>
        </InvoiceCardContainer>
    )
}

InvoiceCard.defaultProps = defaultProps

export default InvoiceCard
