import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    ContractCurrency,
} from 'app/types/enums'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'

import TextIcon, {
    IconType,
} from 'app/Apps/DomainObject/ComponentMapper/TextIcon/TextIcon'
import {
    CQuotesTable,
} from './CQuotes.types'

const config = (): SkyNetTableConfig<keyof CQuotesTable> => {
    return {
        name: 'cquote',
        tabs: [{
            name: 'All',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'quoteNumber',
                    labelKey: 'AccountQuotes.quoteNumber',
                    reduced: true,
                    mapCellProps: ({
                        quoteNumber,
                        pricingInProgressWithLane,
                        pricingInProgressWithLaneFee,
                    }) => {
                        return {
                            value: quoteNumber,
                            type: (pricingInProgressWithLane || pricingInProgressWithLaneFee)
                                ? IconType.Warning : undefined,
                        }
                    },
                    Cell: TextIcon,
                },
                {
                    name: 'quotedForCompanyLocationName',
                    labelKey: 'AccountQuotes.quotedForCompany',
                    filterField: 'quotedForCompanyLocationName',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'pricingAccountCompanyName',
                    labelKey: 'AccountLane.pricingAccount',
                    filterField: 'pricingAccountCompanyName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'originAirportsIataCode',
                    labelKey: 'AccountLane.originAirport',
                    sorting: false,
                },
                {
                    name: 'destinationAirportsIataCode',
                    labelKey: 'AccountLane.destinationAirport',
                    sorting: false,
                },
                {
                    name: 'currency',
                    labelKey: 'AccountQuotes.currency',
                    componentName: 'EnumValue',
                    allowedValuesEnum: ContractCurrency,
                    filterField: 'currency',
                    filterType: FilterType.Array,
                },
                {
                    name: 'createdBy',
                    labelKey: 'AccountQuotes.createdBy',
                    filterField: 'createdBy',
                    filterType: FilterType.Array,
                },
            ],
        }],
    }
}

export default config
