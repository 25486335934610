/* eslint-disable import/prefer-default-export */
import omit from 'lodash/omit'

import {
    AdditionalFeesEditable,
} from '../AdditionalFees/AdditionalFees.types'

import {
    OtherLaneFeesTable, OtherLaneFeesEditable,
} from './OtherLaneFees.types'

export const prepareFeesToBulkEdit = (
    items: OtherLaneFeesTable[] = [],
): (OtherLaneFeesEditable | AdditionalFeesEditable
)[] => {
    return items.map((i) => {
        return omit(i, [
            'feeName',
            'feeChargability',
            'productType',
        ])
    })
}
