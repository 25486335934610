import React, {
    useCallback, useMemo, useState,
} from 'react'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

import useEnumValues from 'app/hooks/useEnumValues'
import useValidation from 'app/hooks/useValidation'
import SkyNetStepperButtons from 'app/shared-components/SkyNetStepper/SkyNetStepperButtons'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import {
    CollServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import useFormError from 'app/hooks/useFormError'

import useGetContractBasis from 'app/Apps/Pricing/ContractBasis/hooks/useGetContractBasis'
import useCQuoteInformationContext, {
    CQuoteOfferSetup as TCQuoteOfferSetup,
    TCQuoteStepper,
} from '../../CQuoteInformationContext'
import getFields from './CQuoteOfferSetup.fields'

export default function CQuoteOfferSetup({
    index,
}: Readonly<{ index: number }>) {
    const getAllowedValues = useEnumValues()
    const {
        value: contextValue,
        setValue: setContextValue,
        setActiveStep,
        activeStep,
    } = useCQuoteInformationContext()

    const {
        data: contractBasis,
    } = useGetContractBasis(
        contextValue?.laneDetails?.contractBasisId,
        contextValue?.isGroupedLaneStatuses,
    )

    const {
        errors, setErrors,
    } = useFormError()

    const [
        value,
        setValue,
    ] = useState<TCQuoteStepper>({
        ...contextValue,
        offerSetup: {
            logisticsDays: [
                CollServiceType.DROPOFF_SKYCELL,
                CollServiceType.DROPOFF_FORWARDER,
            ].includes(contextValue?.deliveryOptions?.collectionServiceType
                    || contextValue?.laneDetails?.collectionServiceType)
                ? contextValue?.rateCardsData?.logisticsDays : 0,
            plannedPaymentTerms: contextValue?.rateCardsData?.plannedPaymentTerms,
            ...contextValue.offerSetup || {} as TCQuoteOfferSetup,
        },
    })

    const fields = useMemo(() => {
        return getFields({
            getAllowedValues,
            allowedCurrency: contextValue?.rateCardsData?.currencies?.reduce((acc, item) => {
                return {
                    ...acc,
                    [item]: item,
                }
            }, {}),
            plannedPaymentTerms: contextValue?.rateCardsData?.plannedPaymentTerms,
            isGroupedLaneStatuses: contextValue?.isGroupedLaneStatuses,
            disabled: activeStep !== index,
        })
    }, [
        getAllowedValues,
        contextValue?.rateCardsData,
        contextValue?.isGroupedLaneStatuses,
        activeStep,
        index,
    ])

    const onChange = useCallback((newValue) => {
        if (!isEqual(newValue, value?.offerSetup)) {
            setValue({
                ...value,
                offerSetup: newValue,
            })
        }
    }, [
        value,
        setValue,
    ])

    const nextAction = useCallback(() => {
        setContextValue(value)
        setActiveStep(activeStep + 1)
    }, [
        setContextValue,
        value,
        setActiveStep,
        activeStep,
    ])

    const editAction = useCallback(() => {
        setActiveStep(index)
        setContextValue({
            lane: value?.lane,
            laneDetails: value?.laneDetails,
            deliveryOptions: value?.deliveryOptions,
            rateCardsData: value?.rateCardsData,
            offerSetup: value?.offerSetup,
        })
    }, [
        index,
        setActiveStep,
        setContextValue,
        value,
    ])

    const {
        isValid,
    } = useValidation({
        fields,
        values: value?.offerSetup || {},
    })

    const formValues = useMemo(() => {
        if (contextValue?.isGroupedLaneStatuses) {
            return {
                ...value?.offerSetup,
                currency: contractBasis?.currency,
                customerQuotation: contractBasis?.customerQuotation,
                plannedPaymentTerms: contractBasis?.plannedPaymentTerms,
            }
        }

        return value?.offerSetup
    }, [
        contractBasis?.currency,
        contractBasis?.customerQuotation,
        contractBasis?.plannedPaymentTerms,
        contextValue?.isGroupedLaneStatuses,
        value?.offerSetup,
    ])

    return (
        <>
            <DomainObjectForm
                value={formValues}
                onChange={onChange}
                fields={fields}
                name="CQuoteOfferSetup"
                disabled={activeStep !== index}
                setErrors={setErrors}
            />
            <SkyNetStepperButtons
                isValid={isValid && isEmpty(errors)}
                nextLabel="Continue"
                nextAction={nextAction}
                canEdit={activeStep > index}
                editAction={editAction}
            />
        </>
    )
}
