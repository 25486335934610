import React from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useEnumValues from 'app/hooks/useEnumValues'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName/useFieldsWithClassName'

import {
    FeesData,
} from '../../CQuotes.types'
import getFields from './feesOverview.fields'
import useStyles from './feesOverview.styles'

const FeesOverview = ({
    value,
}: {
    value: FeesData,
}) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const fields = useFieldsWithClassName(getFields({
        getAllowedValues,
        value,
    }), classes)

    return (
        <DomainObjectForm
            fields={fields}
            name="FeesOverview"
            disabled
            className={classes.gridWrapper}
        />
    )
}

export default FeesOverview
