import React, {
    useEffect,
    useState,
} from 'react'

import useOnSuccessCreateOrder from 'app/Apps/OrderManagement/Orders/hooks/useOnSuccessCreateOrder'
import OrderStepsForm from 'app/Apps/OrderManagement/Orders/Components/OrderStepsForm'
import isTypeTransport from 'app/Apps/OrderManagement/Orders/utils/isTypeTransport'
import Tooltip from 'app/shared-components/Tooltip'
import Button from 'app/shared-components/Button'
import {
    StepTypeDetailed, SubstepName,
} from 'app/types/enums'
import Card from 'app/shared-components/Card'
import {
    OrderStep, OrderTypes,
    StepType, TransportModeRoad,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import RadioButtonTile from 'app/shared-components/RadioButtonTile'

import {
    InternalOrderForm,
    StepData,
} from '../../createManuallyForm.types'
import useCreateInternalOrder from '../../../hooks/useCreateInternalOrder'
import modifyData from '../../modifyData'

import {
    getListSteps,
    getLocation,
} from './utils'
import useStyles from './SelectionOrderStep.style'

const DEFAULT_TRANSPORT_MODE = TransportModeRoad.FTL

const SelectionOrderStep = ({
    value,
    onChange,
    activeStep,
    setActiveStep,
    index,
    setIsEdited,
}: {
    value: InternalOrderForm,
    onChange: (val: InternalOrderForm) => void,
    activeStep: number,
    setActiveStep: (val: number) => void,
    index: number,
    setIsEdited: (isEdited: boolean) => void
}) => {
    const {
        classes,
    } = useStyles()

    const [
        orderStep,
        setOrderStep,
    ] = useState<Partial<OrderStep>>()

    const [
        settings,
        setSettings,
    ] = useState<{
        listSteps: StepData[],
        location: {id: number},
        pickupLocationContacts: {id: number}[],
    }>()

    const [
        isFormValid,
        setIsFormValid,
    ] = useState(false)

    const {
        orderSteps,
    } = value

    const isFirstStep = orderSteps?.length === 0

    useEffect(() => {
        const listSteps = getListSteps(orderSteps)

        setSettings({
            listSteps,
            location: getLocation({
                isFirstStep,
                orderSteps,
                location: value.location,
            }),
            pickupLocationContacts: !isFirstStep
                ? orderSteps?.[orderSteps?.length - 1]?.deliveryLocationContacts : null,
        })

        if (listSteps.length === 1) {
            onSelectStepType(listSteps[0].stepType, listSteps[0].stepTypeDetailed)
        }
    }, []) //eslint-disable-line

    const onSuccessCreate = useOnSuccessCreateOrder(OrderTypes.INTERNAL_TRANSPORT)

    const {
        isPending: isLoading,
        mutate,
    } = useCreateInternalOrder(onSuccessCreate)

    const onSelectStepType = (stepType: StepType, stepTypeDetailed: StepTypeDetailed): void => {
        setOrderStep(
            {
                stepType,
                stepTypeDetailed,
                transportModeRoad: stepType === StepType.ROAD || stepType === StepType.AIR
                    ? DEFAULT_TRANSPORT_MODE
                    : undefined,
                stepProgress: [{
                    substepName: SubstepName.CREATION,
                    substepCompleted: false,
                }],
                isTemperatureControlled: isTypeTransport(stepType) ? false : null,
            },
        )
    }

    const onClickContinue = () => {
        onChange({
            ...value,
            orderSteps: [
                ...orderSteps,
                orderStep,
            ],
        })

        setActiveStep(index + 1)
    }
    const onClickBack = () => {
        onChange({
            ...value,
            orderSteps: [...orderSteps.slice(0, orderSteps.length - 1)],
        })
        setActiveStep(index - 1)
    }

    const onClickEdit = () => {
        const currentIndexStep = orderSteps.findIndex(({
            stepTypeDetailed,
        }) => {
            return stepTypeDetailed === orderStep.stepTypeDetailed
        })

        onChange({
            ...value,
            orderSteps: [...orderSteps.slice(0, currentIndexStep)],
        })

        setActiveStep(index)
    }

    const onClickCreateOrder = () => {
        setIsEdited(false)
        mutate(modifyData({
            ...value,
            orderSteps: [
                ...orderSteps,
                orderStep,
            ],
        }))
    }

    const isLastStep: boolean = Boolean(orderStep && !getListSteps([
        ...orderSteps,
        orderStep,
    ]).length)

    const isAirInternal = orderStep?.stepTypeDetailed === StepTypeDetailed.AIR_INTERNAL

    return (
        <>
            <div className={classes.wrapper}>
                {settings?.listSteps?.map(({
                    imgSrc,
                    title,
                    stepType,
                    stepTypeDetailed,
                }) => {
                    return (
                        <RadioButtonTile
                            key={title}
                            imgSrc={imgSrc}
                            title={title}
                            onClick={() => { onSelectStepType(stepType, stepTypeDetailed) }}
                            selected={orderStep?.stepTypeDetailed === stepTypeDetailed}
                            disabled={activeStep > index}
                        />
                    )
                })}
            </div>
            {orderStep && (
                <Card className={classes.card}>
                    <OrderStepsForm
                        value={orderStep}
                        location={settings.location}
                        pickupLocationContacts={settings.pickupLocationContacts}
                        isFirstStep={isFirstStep}
                        onChange={setOrderStep}
                        setIsFormValid={setIsFormValid}
                        disabled={activeStep > index}
                    />
                </Card>
            )}
            { orderStep && (
                <div className={classes.actions}>
                    {activeStep === index && (
                        <>
                            <Tooltip
                                title="Please fill in all mandatory fields to continue"
                                disabled={isFormValid}
                            >
                                <div className={classes.btnWrapper}>
                                    {!isLastStep && (
                                        <Button
                                            label="Continue"
                                            onClick={onClickContinue}
                                            className={classes.button}
                                            disabled={!isFormValid}
                                            data-testid="btn-continue"
                                        />
                                    )}
                                    {(isLastStep || isAirInternal) && (
                                        <Button
                                            label="Create Order"
                                            onClick={onClickCreateOrder}
                                            className={classes.button}
                                            disabled={!isFormValid}
                                            saving={isLoading}
                                            data-testid="btn-create-order"
                                        />
                                    )}
                                </div>
                            </Tooltip>
                            <Button
                                label="Back"
                                secondary
                                onClick={onClickBack}
                                className={classes.button}
                                data-testid="btn-back"
                            />
                        </>
                    )}
                    {activeStep > index && (
                        <Button
                            label="Edit"
                            onClick={onClickEdit}
                            className={classes.button}
                            data-testid="btn-edit"
                        />
                    )}
                </div>
            )}
        </>
    )
}

export default SelectionOrderStep
