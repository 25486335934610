import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import requestConfig from 'app/Apps/AccountManagement/CQuotes/CQuoteCreateSelectMethod/SimplifiedCQuoteCreate/simplifiedCQuotes.request'

import {
    RequestFeesData,
} from '../SimplifiedCQuoteCreate/simplifiedCQuoteCreate.types'
import {
    FeesData,
} from '../../CQuotes.types'

const useGetFeesData = (requestData: RequestFeesData) => {
    return useRequest<FeesData>({
        key: RequestKeys.getSimplifiedQuoteFeesData,
        params: requestData,
        requestFunc: RequestFn.getEntity(requestConfig.getFeesData),
        enabled: Boolean(requestData) && Object.keys(requestData).every((key) => {
            return Boolean(requestData[key]) || requestData[key] === 0
        }),
    })
}

export default useGetFeesData
