import React from 'react'
import noop from 'lodash/noop'

import {
    RequestConfig,
} from 'app/types/request.types'
import FormWithControls from 'app/shared-components/FormWithControls'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'

import getFields from '../Deployment.fields'
import DeploymentRequests from '../Deployments.request'
import {
    Deployment,
} from '../Deployment.types'

import useStyles from './DeploymentForm.styles'

type Props = {
    data?: Deployment,
    onSuccess?: (props: any) => void,
    onCancel?: (...args: any[]) => void,
    requestParam: RequestConfig,
    disabled?: boolean,

    customButtons?: JSX.Element[],
}

const defaultProps: Partial<Props> = {
    data: {} as Deployment,
    onSuccess: noop,
    onCancel: noop,
    disabled: false,
    customButtons: [],
}
const DeploymentForm = ({
    data,
    onSuccess,
    onCancel,
    requestParam,
    disabled,
    customButtons,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const exists = !!data?.id

    const fieldsWithClassName: [] = useFieldsWithClassName(
        getFields({
            disabled,
        }),
        classes,
    )

    return (
        <FormWithControls
            name={DeploymentRequests.domainName}
            requestParams={requestParam}
            value={data}
            fields={fieldsWithClassName}
            onSuccess={onSuccess}
            exists={exists}
            disabled={disabled}
            onCancel={onCancel}
            customButtons={customButtons}
        />

    )
}

DeploymentForm.defaultProps = defaultProps

export default DeploymentForm
