import queryString from 'query-string'
import simpleApi from 'app/services/simpleApi'
import {
    RequestConfig,
} from 'app/types/request.types'

import {
    ModificationType, PostponeInfo,
} from '../orders.types'
import {
    ModificationCustomerOrder,
    RelinkLaneType,
} from './DataUpdate/dataUpdate.types'

type CustomerTransportRequest = {
    RelinkLaneOrder: {
        mutationFn: ({
            modificationType,
            orderNumber,
            token,
            data,
        }: {
            modificationType: ModificationType,
            orderNumber: string,
            data: Omit<RelinkLaneType, 'modificationType'>,
            token: string,
        }) => Promise<any>,
    },
    BillingInformation: {
        requestFn: ({
            orderNumber,
        }: {orderNumber: string}) => RequestConfig,
    },
    LaneFees: {
        requestFn: ({
            orderNumber,
        }: {orderNumber: string}) => RequestConfig,
    },
    SelectedLane: {
        requestFn: ({
            selectedLaneId,
        }: {selectedLaneId: number}) => RequestConfig,
    },
    AdditionalFees: {
        requestFn: ({
            orderNumber,
        }: {orderNumber: string}) => RequestConfig,
    },
    UpdateStatusOrder: {
        mutationFn: ({
            orderNumber,
            token,
        }: {
            orderNumber: string,
            token: string,
        }) => Promise<any>,
    },
    PostponeOrder: {
        mutationFn: ({
            orderNumber,
            data,
            token,
        }: {
            orderNumber: string,
            data: PostponeInfo,
            token: string,
        }) => Promise<any>,
    },
    ModifyOrder: {
        mutationFn: ({
            modificationType,
            orderNumber,
            token,
            data,
        }: {
            modificationType: ModificationType,
            orderNumber: string,
            data: Partial<ModificationCustomerOrder>,
            token: string,
        }) => Promise<any>,
    },
}

const customerTransportRequest: CustomerTransportRequest = {
    RelinkLaneOrder: {
        mutationFn: ({
            modificationType,
            orderNumber,
            token,
            data,
        }) => {
            return simpleApi({
                url: queryString.stringifyUrl({
                    url: `order/${orderNumber}/lane-update`,
                    query: {
                        modificationType,
                    },
                }),
                data,
                method: 'PATCH',
                token,
            })
        },
    },
    BillingInformation: {
        requestFn: ({
            orderNumber,
        }) => {
            return {
                url: `order/${orderNumber}/billing-information`,
            }
        },
    },
    LaneFees: {
        requestFn: ({
            orderNumber,
        }) => {
            return {
                url: `order/${orderNumber}/billing/lanefees`,
            }
        },
    },
    AdditionalFees: {
        requestFn: ({
            orderNumber,
        }) => {
            return {
                url: `order/${orderNumber}/billing/additionalfees`,
            }
        },
    },
    UpdateStatusOrder: {
        mutationFn: ({
            orderNumber,
            token,
        }) => {
            return simpleApi({
                url: `order/${orderNumber}/status/confirmed`,
                method: 'PATCH',
                token,
            })
        },
    },
    PostponeOrder: {
        mutationFn: ({
            orderNumber,
            data,
            token,
        }) => {
            return simpleApi({
                url: `order/${orderNumber}/postponement`,
                data,
                method: 'POST',
                token,
            })
        },
    },
    ModifyOrder: {
        mutationFn: ({
            modificationType,
            orderNumber,
            token,
            data,
        }) => {
            return simpleApi({
                url: queryString.stringifyUrl({
                    url: `order/${orderNumber}/data`,
                    query: {
                        modificationType,
                    },
                }),
                data,
                method: 'PATCH',
                token,
            })
        },
    },
    SelectedLane: {
        requestFn: ({
            selectedLaneId,
        }) => {
            return {
                url: `lane/${selectedLaneId}/ops-information`,
            }
        },
    },
}

export default customerTransportRequest
