import {
    useMemo,
} from 'react'
import {
    useSkyNetNavigate, SkyNetRoutesConfig, App, CommonRoutes, createRoutesConfig,
} from 'app/SkyNetRoutes'
import useCreateSkyNetDomainRoutes from 'app/shared-components/SkyNetDomain/useCreateSkyNetDomainRoutes'
import {
    DamageTrackingDomains,
} from '../Damages/Damages.types'

export default function useDamageTrackingRoutes() {
    const skyNetNavigate = useSkyNetNavigate()
    const SkyNetDomainRoutes = useCreateSkyNetDomainRoutes()()

    return useMemo(() => {
        const config: SkyNetRoutesConfig = {
            path: [App.DAMAGE_TRACKING],
            Damages: {
                route: DamageTrackingDomains.Damages,
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ChangeHistory: {
                    route: CommonRoutes.CHANGE_HISTORY,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
            DamageTypes: {
                route: DamageTrackingDomains.DamageTypes,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ChangeHistory: {
                    route: CommonRoutes.CHANGE_HISTORY,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [
        SkyNetDomainRoutes,
        skyNetNavigate,
    ])
}
