import React from 'react'

import Typeahead from 'app/shared-components/Typeahead'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import useGetContainer from 'app/Apps/ContainerManagement/ContainerTypes/hooks/useGetContainerType'
import containerTypeRequest from 'app/Apps/ContainerManagement/ContainerTypes/ContainerType.request'

type Props = {
    value?: string | number,
    onChange: (value: any, ...params: any[]) => void,
    disabled?: boolean,
    required?: boolean,
    className?: string,
    classNames?: {
        options?: string,
        label?: string,
    },
    noneOption?: boolean,
}

const defaultProps: Partial<Props> = {
    value: undefined,
    disabled: false,
    required: false,
    className: '',
    classNames: undefined,
    noneOption: true,
}

const ContainerTypeSelector = ({
    value,
    onChange,
    disabled,
    required,
    className,
    classNames,
    noneOption,
}: Props) => {
    const loadOptions = useDelayedLoadOptions({
        url: containerTypeRequest.selector.url,
        params: {
            sortField: 'containerModel',
            sortType: 'asc',
        },
        convertion: (containers) => {
            return containers.map(({
                containerModel, id,
            }) => {
                return {
                    label: containerModel,
                    value: id,
                    id,
                }
            })
        },
    })

    const {
        data: labelData,
    } = useGetContainer(value)

    return (
        <Typeahead
            name="ContainerType"
            loadOptions={loadOptions}
            labelData={labelData}
            labelField="containerModel"
            isRequired={required}
            value={value}
            disabled={disabled}
            onChange={onChange}
            className={className}
            classNames={classNames}
            noneOption={noneOption}
        />
    )
}

ContainerTypeSelector.defaultProps = defaultProps

export default ContainerTypeSelector
