import {
    tss,
} from 'app/tss'

const general = 'general'
const laneFeesValidity = 'laneFeesValidity'
const additionalFeesValidity = 'additionalFeesValidity'

export default tss.create(({
    theme,
}) => {
    return {
        gridWrapper: {
            display: 'grid',
            gap: theme.spacing(2),
            gridTemplateAreas: `
                "${general} ${laneFeesValidity}"
                "${general} ${additionalFeesValidity}"
            `,
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        general: {
            gridArea: general,
        },
        laneFeesValidity: {
            gridArea: laneFeesValidity,
        },
        additionalFeesValidity: {
            gridArea: additionalFeesValidity,
        },
    }
})
