import React, {
    useMemo,
} from 'react'

import {
    Navigate,
} from 'react-router-dom'
import {
    CommonRoutes,
    SkyNetRouteConfigObject,
    useSkyNetRoutes,
} from 'app/SkyNetRoutes'

import PreBookings from 'app/Apps/PreBooking'
import {
    AccountManagementDomains,
} from './AccountManagement.types'
import KamDashboard from './KamDashboard'
import Accounts from './Accounts'
import RegionalGroups from './RegionalGroups'
import Opportunities from './Opportunities'
import AccountLanes from './AccountLanes'
import AQuotes from './AQuotes'
import useAccountManagementRoutes from './hooks/useAccountManagementRoutes'
import CQuotes from './CQuotes'

export default function AccountManagement() {
    const Routes = useAccountManagementRoutes()

    const routesConfig: SkyNetRouteConfigObject<AccountManagementDomains> = useMemo(() => {
        return {
            [AccountManagementDomains.KamDashboard]: <KamDashboard />,
            [AccountManagementDomains.Accounts]: <Accounts />,
            [AccountManagementDomains.RegionalGroups]: <RegionalGroups />,
            [AccountManagementDomains.Opportunitites]: <Opportunities />,
            [AccountManagementDomains.Lanes]: <AccountLanes />,
            [AccountManagementDomains.AQuotes]: <AQuotes />,
            [AccountManagementDomains.CQuotes]: <CQuotes />,
            [AccountManagementDomains.PreBookings]: <PreBookings />,
            [CommonRoutes.SLASH]: <Navigate
                to={Routes.KamDashboard.route}
                replace
            />,
        }
    }, [Routes])

    return useSkyNetRoutes({
        routesConfig,
    })
}
