import React, {
    useState, useEffect,
} from 'react'
import isEqual from 'lodash/isEqual'
import {
    useParams, useNavigate,
} from 'react-router-dom'
import RadioButtonTileSelector from 'app/shared-components/RadioButtonTileSelector'

import SimplifiedCQuoteCreate from './SimplifiedCQuoteCreate'
import CQuoteCreate from './CQuoteCreate'

export enum CQuoteCreateMethod {
    SIMPLIFIED_C_QUOTE = 'Simplified C-Quote',
    C_QUOTE = 'C-Quote',
}

export const routeParamValuesMap = {
    simplified: CQuoteCreateMethod.SIMPLIFIED_C_QUOTE,
    cquote: CQuoteCreateMethod.C_QUOTE,
}

export default function CQuoteCreateSelectMethod() {
    const params = useParams()
    const navigate = useNavigate()

    const [
        method,
        setMethod,
    ] = useState<CQuoteCreateMethod>(Object.entries(routeParamValuesMap).find(([key]) => {
        return key === params?.selectedMethod
    })?.[1])

    useEffect(() => {
        if (!isEqual(method, routeParamValuesMap[params?.selectedMethod])) {
            navigate(`${method ? '..' : '.'}/${Object.entries(routeParamValuesMap).find(([
                key, // eslint-disable-line @typescript-eslint/no-unused-vars
                value,
            ]) => {
                return value === method
            })?.[0]}`, {
                replace: true,
            })
        }
    }, [
        method,
        navigate,
        params?.selectedMethod,
    ])

    return (
        <>
            <RadioButtonTileSelector<typeof CQuoteCreateMethod>
                values={CQuoteCreateMethod}
                setValue={setMethod}
                title="Select a method"
                selectedValue={method}
            />
            {
                method === CQuoteCreateMethod.SIMPLIFIED_C_QUOTE && <SimplifiedCQuoteCreate />
            }
            {
                method === CQuoteCreateMethod.C_QUOTE && <CQuoteCreate />
            }
        </>
    )
}
