import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import {
    CollServiceTypeSimplifiedCQuote,
    SimplifiedCQuoteCreateType,
} from '../../simplifiedCQuoteCreate.types'

const getFields = ({
    getAllowedValues,
    disabledDeliveryServiceType,
    disabledCollectionServiceType,
    disabled,
}: {
    getAllowedValues: (val: Record<string, string>) => Record<string, string>,
    disabledDeliveryServiceType: boolean,
    disabledCollectionServiceType: boolean,
    disabled: boolean,
}): DTOCardFormField<keyof SimplifiedCQuoteCreateType>[] => {
    return [{
        id: 'deliveryOptions',
        title: 'Delivery Options',
        className: 'deliveryOptions',
        fields: [
            {
                id: 'deliveryOptions-column1',
                fields: [{
                    name: 'deliveryServiceType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(DeliveryServiceType),
                    required: true,
                    showInColumns: true,
                    disabled: disabledDeliveryServiceType || disabled,
                    labelKey: 'LaneGroup.deliveryServiceType',
                }],
            },
            {
                id: 'deliveryOptions-column2',
                fields: [{
                    name: 'collectionServiceType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(CollServiceTypeSimplifiedCQuote),
                    showInColumns: true,
                    required: true,
                    disabled: disabledCollectionServiceType || disabled,
                    labelKey: 'LaneGroup.collectionServiceType',
                }],
            },
        ],
    }]
}

export default getFields
