import React, {
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import omit from 'lodash/omit'

import {
    v1 as uuidv1,
} from 'uuid'

import TruckingCapacity from './TruckingCapacity'

const propTypes = {
    name: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.any),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    tempRange: PropTypes.string,
}

const defaultProps = {
    name: 'truckingCapacity',
    value: [],
    onChange: noop,
    disabled: false,
    tempRange: undefined,
}

const TruckingCapacityContainer = ({
    name,
    value,
    onChange,
    disabled,
    tempRange,
}) => {
    const handleChange = useCallback((truckingCapacity) => {
        const updatedTruckingCapacity = value.map((item) => {
            if (item.id === truckingCapacity.id) {
                return {
                    ...omit(truckingCapacity, 'tempRange'),
                }
            }
            return item
        })

        onChange(updatedTruckingCapacity, {
            target: {
                name,
                value: updatedTruckingCapacity,
            },
        })
    }, [
        value,
        onChange,
        name,
    ])

    const handleAddEntry = useCallback(() => {
        const newEntry = [
            ...value,
            {
                id: `temp-${uuidv1()}`,
                containerTypeId: undefined,
                maxNumberOfContainersPerTruck: undefined,
                maxNumberOfContainersPerVan: undefined,
            },
        ]

        onChange(
            newEntry,
            {
                target: {
                    name,
                    value: newEntry,
                },
            },
        )
    }, [
        name,
        onChange,
        value,
    ])

    const handleDeleteEntry = useCallback((truckingCapacityId) => {
        onChange(value.filter(({
            id,
        }) => {
            return id !== truckingCapacityId
        }))
    }, [
        onChange,
        value,
    ])

    return (
        <TruckingCapacity
            value={value}
            onChange={handleChange}
            addEntry={handleAddEntry}
            deleteEntry={handleDeleteEntry}
            disabled={disabled}
            tempRange={tempRange}
        />
    )
}

TruckingCapacityContainer.propTypes = propTypes
TruckingCapacityContainer.defaultProps = defaultProps

export default TruckingCapacityContainer
