import React, {
    useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import {
    ArchiveButton,
    ArchiveConfirmationDialog,
    useArchive,
} from 'app/shared-components/Archive'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'

import useGetDamageType from '../../hooks/useGetDamageType'
import useDamageTrackingRoutes from '../../hooks/useDamageTrackingRoutes'
import requests from '../DamageTypes.requests'
import DamageTypeForm from '../DamageTypeForm'

type Props = {
    id: number,
    disabled?: boolean
}

const defaultProps = {
    disabled: false,
}

const DamageTypeUpdate = ({
    id,
    disabled,
}: Props) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetDamageType(id)
    const {
        DamageTypes: DamageTypesRoutes,
    } = useDamageTrackingRoutes()

    const archiveAction = useArchive({
        requestParamFn: data?.archived ? requests.restore
            : requests.archive,
        notificationId: 'DamageType',
        title: 'Damage Type',
        data,
        redirectionAfterSuccess: generateSkyNetLink({
            domainPath: DamageTypesRoutes.path,
        }),
    })

    const customButtons = useMemo(() => {
        return [<ArchiveButton
            key="archive-button"
            onClick={archiveAction.handleArchiveRestore}
            archived={data?.archived}
        />]
    }, [
        archiveAction,
        data,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <DamageTypeForm
                    onSuccess={invalidate}
                    data={data}
                    disabled={disabled}
                    requestParams={requests.update({
                        id,
                    })}
                    exists
                    customButtons={customButtons}
                />
                <ArchiveConfirmationDialog
                    title="Damage Type"
                    openConfirmDialog={archiveAction.openConfirmDialog}
                    handleClose={archiveAction.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
            </>
        </StatusHandler>
    )
}

DamageTypeUpdate.defaultProps = defaultProps

export default DamageTypeUpdate
