import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import {
    UseMutationResult,
} from '@tanstack/react-query/src/types'

import useValidation from 'app/hooks/useValidation'
import SkyNetStepperButtons from 'app/shared-components/SkyNetStepper/SkyNetStepperButtons'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useMarginStyles from 'app/tss/margin.styles'
import {
    Currency, LaneStatus,
} from 'app/types/enums'
import useRequest, {
    RequestFn, RequestKeys,
} from 'app/hooks/useRequest'
import requests from 'app/Apps/AccountManagement/CQuotes/CQuote.request'

import Loading from 'app/shared-components/Loading'
import useCQuoteInformationContext, {
    CQuoteContactDetails as TCQuoteContactDetails,
    TCQuoteStepper,
} from '../../CQuoteInformationContext'
import useGetCQuotesFeesData from '../../hooks/useGetCQuoteFeesData'
import AdditionalFeesTable from '../../../AdditionalFeesTable'
import LaneFeesTable from '../../../LaneFeesTable'
import FeesOverview from '../../../FeesOverview'
import fields from './CQuoteFeesContactDetails.fields'

export default function CQuoteFeesContactDetails({
    onCreate,
}: Readonly<{ onCreate: UseMutationResult, index: number }>) {
    const {
        classes,
    } = useMarginStyles({
        top: 3,
    })

    const {
        value: contextValue,
        setValue: setContextValue,
        setActiveStep,
        activeStep,
    } = useCQuoteInformationContext()

    const [
        value,
        setValue,
    ] = useState<TCQuoteStepper>({
        ...contextValue,
        contactDetails: {
            generatePdf: true,
        } as TCQuoteContactDetails,
    } as TCQuoteStepper)

    const requestData = useMemo(() => {
        const result = {
            expPalletsYear: value.laneDetails?.expPalletsYear,
            rentalDays: value?.offerSetup?.rentalDays,
            logisticsDays: value?.offerSetup?.logisticsDays,
        }

        switch (value?.laneDetails?.laneStatus) {
        case LaneStatus.TO_BE_QUOTED:
        case LaneStatus.UNDER_REVIEW_PRICING:
            return {
                ...result,
                lane: value?.lane,
                pricingAccount: value.laneDetails?.contractBasisAccount,
                handoverPoint: value.deliveryOptions?.handoverPoint
                    || value.laneDetails?.handoverPoint,
                collectionDropoffPoint: value.deliveryOptions?.collectionDropoffPoint
                    || value.laneDetails?.collectionDropoffPoint,
                currency: value?.offerSetup?.currency,
                customerQuotation: value?.offerSetup?.customerQuotation,
            }
        case LaneStatus.IN_PROGRESS:
        case LaneStatus.PRICED:
        case LaneStatus.AWARDED:
        case LaneStatus.IMPLEMENTED:
        case LaneStatus.INACTIVE:
            return {
                ...result,
                lane: value?.lane,
            }
        default:
            return {
                ...result,
                pricingAccount: value.laneDetails?.contractBasisAccount,
                tempRange: value?.laneDetails?.temperatureRange,
                productType: value?.laneDetails?.productType,
                originAirport: value.laneDetails?.originAirport,
                destinationAirport: value.laneDetails?.destinationAirport,
                deliveryServiceType: value.deliveryOptions?.deliveryServiceType
                    || value.laneDetails?.deliveryServiceType,
                collectionServiceType: value.deliveryOptions?.collectionServiceType
                    || value.laneDetails?.collectionServiceType,
                handoverPoint: value.deliveryOptions?.handoverPoint
                    || value.laneDetails?.handoverPoint,
                collectionDropoffPoint: value.deliveryOptions?.collectionDropoffPoint
                    || value.laneDetails?.collectionDropoffPoint,
                currency: value?.offerSetup?.currency,
                customerQuotation: value?.offerSetup?.customerQuotation,
            }
        }
    }, [
        value.laneDetails,
        value.offerSetup,
        value.deliveryOptions,
        value.lane,
    ])

    const {
        data,
        isDataReady,
        isFetching,
        error,
    } = useGetCQuotesFeesData(requestData)

    const {
        data: contactDetails,
        isDataReady: contactDetailsDataReady,
    } = useRequest<TCQuoteContactDetails>({
        key: RequestKeys.getCQuoteQuotationDetails,
        params: {
            lane: value?.lane,
        },
        requestFunc: RequestFn.getEntity(requests[RequestKeys.getCQuoteQuotationDetails]),
        enabled: Boolean(value?.lane?.id),
    })

    const onChange = useCallback((newValue: TCQuoteContactDetails) => {
        if (!isEqual(newValue, value?.contactDetails)) {
            setValue({
                ...value,
                contactDetails: newValue,
            })
        }
    }, [value])

    const nextAction = useCallback(() => {
        onCreate.mutate({
            ...requestData,
            ...value.contactDetails,
        })
    }, [
        onCreate,
        requestData,
        value.contactDetails,
    ])

    const {
        isValid,
    } = useValidation({
        fields: [],
        values: value?.offerSetup || {},
    })

    const backAction = useCallback(() => {
        setActiveStep(activeStep - 1)
        setContextValue({
            ...value,
            contactDetails: {} as TCQuoteContactDetails,
        })
    }, [
        setActiveStep,
        activeStep,
        setContextValue,
        value,
    ])

    useEffect(() => {
        if (contactDetailsDataReady && !isEqual(contactDetails, pick(value?.contactDetails, [
            'skyCellContact',
            'quotedFor',
        ]))) {
            setValue({
                ...value,
                contactDetails: {
                    ...value.contactDetails,
                    ...contactDetails,
                },
            })
        }
    }, [
        contactDetailsDataReady,
        contactDetails,
        value,
    ])

    useEffect(() => {
        if (error) {
            setActiveStep((prev) => {
                return prev - 1
            })
        }
    }, [
        error,
        setActiveStep,
    ])

    if (isFetching) return <Loading />
    if (!isDataReady) return null

    return (
        <>
            <FeesOverview value={data} />
            <LaneFeesTable
                value={data?.laneFees?.fees}
                currency={value.offerSetup?.currency as unknown as Currency}
                title="Lane Fees"
            />
            <AdditionalFeesTable
                value={data?.additionalFees.fees}
                currency={value.offerSetup?.currency as unknown as Currency}
                title="Additional Fees"
            />
            <DomainObjectForm
                value={value?.contactDetails}
                onChange={onChange}
                fields={fields}
                name="FeesAndContactDetails"
                className={classes.margin}
            />
            <SkyNetStepperButtons
                isValid={isValid}
                nextLabel="Create"
                nextAction={nextAction}
                canGoBack
                backAction={backAction}
                isLoading={onCreate.isPending}
            />
        </>
    )
}
