import React, {
    useCallback,
} from 'react'
import Link from '@mui/material/Link'
import {
    useNavigate,
} from 'react-router-dom'

import {
    SkyNetDomainRoutes,
} from 'app/shared-components/SkyNetDomain/useCreateSkyNetDomainRoutes'

import useStyles from '../common.styles'

const defaultProps = {
    onClick: undefined,
}

const BulkCreateIcon = () => {
    const {
        classes,
    } = useStyles()

    const navigate = useNavigate()

    const redirectToBulkCreate = useCallback(() => {
        navigate(`../${SkyNetDomainRoutes.BULK_CREATE}`)
    }, [navigate])

    return (
        <Link
            className={classes.link}
            component="button"
            color="secondary"
            onClick={redirectToBulkCreate}
        >
            <img
                src="assets/images/icons/bulk_create.svg"
                alt="Bulk Create"
            />
        </Link>
    )
}

BulkCreateIcon.defaultProps = defaultProps

export default BulkCreateIcon
