import React from 'react'
import {
    Cell, Compatible, UncertainCompatible, getCellProperty,
    Uncertain, CellTemplate, isCharAlphaNumeric, getCharFromKey, keyCodes,
} from '@silevis/reactgrid'

import {
    dateToStr, strToDate,
} from 'app/utils/date'
import {
    selectSpreadSheetConfigByFieldName,
} from 'app/store/SpreadSheetConfigs'
import store from 'app/store'

import {
    SkyNetSpreadSheetCellType,
} from '../../SkyNetSpreadSheet.types'

import DateTime from './DateTimeCell'

export interface DateTimeCell extends Cell {
    text: string,
    type: SkyNetSpreadSheetCellType.DATETIME,
    date?: Date,
    defaultValue?: string | number | Date,
    selectedValue?: string,
    value?: number,
    name?: string,
}

export default class DateTimeCellTemplate implements CellTemplate<DateTimeCell> {
    private configId

    private escKeyPressed = false

    private strToDate = strToDate

    constructor({
        configId,
    }: {
        configId: string
    }) {
        this.configId = configId
    }

    getFormat(cell: UncertainCompatible<DateTimeCell> | Uncertain<DateTimeCell>) {
        const {
            format,
        } = selectSpreadSheetConfigByFieldName(
            store.getState(), {
                name: this.configId,
                field: cell.name,
            },
        ) || {}

        return format
    }

    getCompatibleCell(uncertainCell: Uncertain<DateTimeCell>): Compatible<DateTimeCell> {
        let text

        try {
            text = getCellProperty(uncertainCell, 'text', 'string')
        } catch {
            text = undefined
        }

        return {
            ...uncertainCell,
            text,
            date: text && this.strToDate(text, this.getFormat(uncertainCell)),
            value: text,
        }
    }

    handleKeyDown(
        cell: Compatible<DateTimeCell>,
        keyCode: number, ctrl: boolean, shift: boolean, alt: boolean, key: string,
    ): { cell: Compatible<DateTimeCell>, enableEditMode: boolean } {
        if (!ctrl && isCharAlphaNumeric(getCharFromKey(key))) {
            return {
                cell: this.getCompatibleCell({
                    ...cell,
                }),
                enableEditMode: true,
            }
        }
        return {
            cell, enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
        }
    }

    update(cell: Compatible<DateTimeCell>,
        cellToMerge: UncertainCompatible<DateTimeCell>): Compatible<DateTimeCell> {
        return this.getCompatibleCell({
            ...cell, date: new Date(cellToMerge.value), text: cellToMerge.text,
        })
    }

    render(cell: Compatible<DateTimeCell>,
        isInEditMode: boolean,
        onCellChanged: (
               cell: Compatible<DateTimeCell>,
               commit: boolean
           ) => void) {
        if (!isInEditMode) {
            return cell.text
        }

        return (
            <DateTime
                cell={cell}
                onBlur={(value) => {
                    onCellChanged(this.getCompatibleCell({
                        ...cell,
                        text: value ? dateToStr(value, this.getFormat(cell)) : null,
                        date: value,
                    }), !this.escKeyPressed)
                    this.escKeyPressed = false
                }}
                wasEscKeyPressed={this.escKeyPressed}
            />
        )
    }
}
