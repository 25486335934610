export type DamageType = {
    name: string,
    id: number,
    expectedContainerStatus: ExpectedContainerStatus,
    expectedFixingDays?: number,
    comment?: string,
    blocksQcPassed: boolean,
    technicalDrawings?: {id: string}[],
    pictures?: {id: string}[],
    archived?: boolean,
}

export enum ExpectedContainerStatus {
    TO_BE_REPAIRED = 'TO_BE_REPAIRED',
    TO_BE_MAINTAINED = 'TO_BE_MAINTAINED',
    TO_BE_OVERHAULED = 'TO_BE_OVERHAULED',
    TO_BE_RECYCLED = 'TO_BE_RECYCLED',
    OK = 'OK',
}
