import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'

import requests from '../../PreBookings.requests'
import {
    PreBookingEntity,
} from '../../PreBooking.types'

export default () => {
    const token = useJWTToken()

    const {
        onSuccess,
        onError,
    } = useHandleStatusRequest({
        title: 'Prebookings were updated',
        id: 'PrebookingsBulkUpdate',
    })

    const {
        mutateAsync: updatePrebookings,
    } = useMutation({
        mutationFn: (prebookings: PreBookingEntity[]) => {
            return simpleApi({
                ...requests.bulkEdit({
                    data: prebookings,
                }),
                token,
            })
        },
        onError,
        onSuccess,
    })

    return useCallback(async (
        prebookings: PreBookingEntity[],
        options: AsyncMutationOptions,
    ) => {
        if (!prebookings.length) {
            onError({
                response: {
                    data: ['Nothing was changed'],
                    statusCode: 500,
                },
            })
            if (options?.onSettled) options?.onSettled(prebookings)
            return Promise.resolve()
        }

        return updatePrebookings(prebookings, options)
    }, [
        updatePrebookings,
        onError,
    ])
}
