import {
    RequestConfig,
} from 'app/types/request.types'

type DamageTypesRequests = {
    domainName: string,
    filterRequest: ({
        params: any,
    }) => RequestConfig,
    create: () => RequestConfig,
    get: ({
        id: number,
    }) => RequestConfig,
    update: ({
        id: number,
    }) => RequestConfig,
    modifications: ({
        id: number,
    }) => RequestConfig,
    archive: ({
        id: number,
    }) => RequestConfig,
    restore: ({
        id: number,
    }) => RequestConfig,
}

const damageTypesRequests: DamageTypesRequests = {
    domainName: 'DamageType',
    filterRequest: ({
        params,
    }) => {
        return {
            method: 'POST',
            url: 'damagetype/filter-request/all',
            ...params,
        }
    },
    create: () => {
        return {
            url: 'damagetype',
            method: 'POST',
        }
    },
    get: ({
        id,
    }) => {
        return {
            url: `damagetype/${id}`,
            method: 'GET',
        }
    },
    update: ({
        id,
    }) => {
        return {
            url: `damagetype/${id}`,
            method: 'PUT',
        }
    },
    modifications: ({
        id,
    }) => {
        return {
            url: `damagetype/${id}/modifications`,
            method: 'GET',
        }
    },
    archive: ({
        id,
    }) => {
        return {
            url: `damagetype/${id}/archive`,
            method: 'PATCH',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `damagetype/${id}/restore`,
            method: 'PATCH',
        }
    },
}

export default damageTypesRequests
