import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    TemperatureRange,
    RegionFirstLevel,
} from 'app/types/enums'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    RegionalGroup,
} from './regionalGroups.types'

const config = (): SkyNetTableConfig<keyof RegionalGroup> => {
    return {
        name: 'RegionalGroups',
        tabs: [{
            name: 'All Regional Groups',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'regionalGroupNumber',
                    reduced: true,
                },
                {
                    name: 'companyName',
                    labelKey: 'RegionalGroups.accountName',
                    filterField: 'companyName',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'originRegion',
                    componentName: 'EnumValue',
                    allowedValuesEnum: RegionFirstLevel,
                    filterField: 'originRegion',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'destinationRegion',
                    componentName: 'EnumValue',
                    allowedValuesEnum: RegionFirstLevel,
                    filterField: 'destinationRegion',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'tempRange',
                    filterField: 'tempRange',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: TemperatureRange,
                },
                {
                    name: 'annualPotentialVolume',
                    filterField: 'annualPotentialVolume',
                    filterType: FilterType.Array,
                },
                {
                    name: 'createdBy',
                    filterField: 'createdBy',
                    filterType: FilterType.Array,
                },
                {
                    name: 'createdOn',
                    componentName: 'DateTimeCell',
                },
            ],
        }],
    }
}

export default config
