// eslint-disable-next-line import/prefer-default-export
export const getAccountPlanDocumentName = (link: string) => {
    if (link) {
        const regex = /[^\\/]+.xlsx/
        const parcers = link.match(regex)

        return (parcers && parcers.length > 0) ? decodeURI(parcers[0]) : decodeURI(link)
    }

    return ''
}
