import React from 'react'
import Link from '@mui/material/Link'

import Tooltip from 'app/shared-components/Tooltip'

import useStyles from '../common.styles'

type Props = {
    removeSort: () => void,
}

const ClearSorting = ({
    removeSort,
}: Readonly<Props>) => {
    const {
        classes,
    } = useStyles()

    return (
        <Link
            className={classes.link}
            component="button"
            variant="body1"
            color="textSecondary"
            onClick={removeSort}
        >
            <Tooltip title="Clear Sorting">
                <img
                    src="assets/images/icons/clear_sorting.svg"
                    alt="Clear Sorting"
                />
            </Tooltip>
        </Link>
    )
}

export default ClearSorting
