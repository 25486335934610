import React, {
    useCallback,
    useEffect,
    useState,
} from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import {
    v1 as uuidv1,
} from 'uuid'

import {
    ProductType,
} from 'app/types/enums'
import {
    RequiredContainersCustomer,
} from 'app/types/common.types'
import useEnumValues from 'app/hooks/useEnumValues'
import Select from 'app/shared-components/Select'
import InputSingleline from 'app/shared-components/InputSingleline'

import AdvancedList, {
    ListColumn,
} from '../../AdvancedList'
import useStyles from './RequiredContainerByProductTypeList.styles'

type Props = {
    value?: RequiredContainersCustomer[],
    onChange: (changeValue: (RequiredContainersCustomer)[]) => void,
    disabled?: boolean,
    required?: boolean,
    allowedProductTypes?: ProductType[],
}

const defaultProps: Partial<Props> = {
    disabled: false,
    required: false,
    allowedProductTypes: [],
}

const defaultValue: Partial<RequiredContainersCustomer> = {
    quantity: 1,
    productType: undefined,
}

const convertToSelectorOptions = (options:ProductType[], allowedTypes: unknown) => {
    const result = options.reduce((acc, item) => {
        return {
            ...acc,
            [item]: allowedTypes[item],
        }
    }, {})

    return result
}

const columns: ListColumn[] = [
    {
        colSpan: 6,
        label: 'Container Type',
    },
    {
        colSpan: true,
        label: 'Amount',
        align: 'right',
    },
]

function RequiredContainerByProductTypeList({
    value,
    onChange,
    disabled,
    required,
    allowedProductTypes,
}: Readonly<Props>) {
    const allowedValues = useEnumValues()(ProductType)
    const {
        classes,
    } = useStyles()
    const [
        initialAllowedTypes,
        setInitialAllowedTypes,
    ] = useState(allowedProductTypes)
    const [
        listkey,
        setListkey,
    ] = useState(`key-${uuidv1()}`)

    useEffect(() => {
        if (initialAllowedTypes !== allowedProductTypes) {
            setInitialAllowedTypes(allowedProductTypes)
            // using uniq key to re-create AdvancedList to sync it's state to value
            setListkey(`key-${uuidv1()}`)
            onChange(undefined)
        }
    }, [
        allowedProductTypes,
        initialAllowedTypes,
        onChange,
    ])

    const onItemCellChange = useCallback(({
        item, newValue, index, handleChange,
    }) => {
        const newItem = {
            ...item,
            ...newValue,
        }

        handleChange(newItem, index)
    }, [])

    const validateItemForAdd = useCallback((item: RequiredContainersCustomer) => {
        return item.quantity > 0
            && Boolean(item.productType)
    }, [])

    const renderItem = useCallback(({
        index,
        item,
        handleChange,
        state,
    }) => {
        const filteredOptions = allowedProductTypes.filter((key) => {
            return !state.some((el) => {
                return el.productType === key && el !== item
            })
        })
        const preparedFilteredOptions = convertToSelectorOptions(filteredOptions, allowedValues)

        return (
            <>
                <Grid
                    key={`row-container-${index}`}
                    xs={columns[0].colSpan}
                >
                    <Select
                        name="containerType"
                        allowedValues={preparedFilteredOptions}
                        value={item.productType}
                        onChange={(newValue) => {
                            return onItemCellChange({
                                newValue: {
                                    productType: newValue,
                                },
                                index,
                                item,
                                handleChange,
                            })
                        }}
                        required={required}
                        disabled={disabled}
                    />
                </Grid>
                <Grid
                    key={`row-ammount-${index}`}
                    xs={columns[1].colSpan}
                >
                    <InputSingleline
                        name="amount"
                        type="number"
                        min={1}
                        centerAligned
                        onChange={(newValue) => {
                            return onItemCellChange({
                                index,
                                item,
                                handleChange,
                                newValue: {
                                    quantity: Number(newValue),
                                },
                            })
                        }}
                        className={classes.input}
                        value={item.quantity}
                        required={required}
                        disabled={disabled}
                    />
                </Grid>
            </>
        )
    }, [
        classes.input,
        onItemCellChange,
        allowedValues,
        allowedProductTypes,
        required,
        disabled,
    ])

    return (
        <AdvancedList<RequiredContainersCustomer>
            key={listkey}
            onChange={onChange}
            disabled={disabled}
            value={value}
            renderItem={renderItem}
            defaultValue={defaultValue}
            addButtonLabel="new container"
            columns={columns}
            validateForAdd={validateItemForAdd}
        />
    )
}

RequiredContainerByProductTypeList.defaultProps = defaultProps

export default RequiredContainerByProductTypeList
