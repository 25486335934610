import {
    SkyNetTableField,
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable/types/skyNetTable.types'
import {
    ContactMainType,
} from 'app/types/enums'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    Contact,
} from './Contacts.types'

type ContactTable = Contact & {
    locationLocationName: string,
    locationCompanyCompanyName: string
}

export enum ContactsTableTabs {
    Prospect = 'prospect'
}

export default (): SkyNetTableConfig<keyof ContactTable> => {
    const locationField: SkyNetTableField<keyof ContactTable>[] = [{
        name: 'locationLocationName',
        filterField: 'locationLocationName',
        labelKey: 'Contact.location',
    }]
    const basicFields: SkyNetTableField<keyof ContactTable>[] = [
        {
            name: 'contactName',
            filterField: 'contactName',
            reduced: true,
            labelKey: 'Contact.contactName',
        },
        {
            name: 'email',
            filterField: 'email',
            labelKey: 'Contact.email',
        },
        {
            name: 'phone',
            filterField: 'phone',
            labelKey: 'Contact.phone',
        },
        {
            name: 'isMainContact',
            componentName: 'ChipCheckMark',
            labelKey: 'Contact.isMainContact',
        },
    ]

    const restFields: SkyNetTableField<keyof ContactTable>[] = [
        {
            name: 'locationCompanyCompanyName',
            filterField: 'locationCompanyCompanyName',
            reduced: true,
            labelKey: 'Location.company',
        },
        {
            name: 'mainType',
            componentName: 'EnumList',
            allowedValuesEnum: ContactMainType,
            labelKey: 'Contact.mainType',
        },
        {
            name: 'createdBy',
            filterField: 'createdBy',
            labelKey: 'AccountQuotes.createdBy',
        },
    ]

    return {
        name: 'ContactsTable',
        tabs: [
            {
                name: 'Contacts',
                url: CommonRoutes.ALL,
                includeFilters: {
                    isProspect: [false],
                },
                fields: [
                    ...basicFields,
                    ...locationField,
                    ...restFields,
                ],
            },
            {
                name: 'Prospect Contacts',
                url: ContactsTableTabs.Prospect,
                requestUrl: 'prospect',
                fields: [
                    ...basicFields,
                    ...restFields,
                ],
            },
        ],
    }
}
