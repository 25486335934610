import {
    CollServiceType, DeliveryServiceType, TransportModeRoad,
} from '../../OrderManagement/Lanes/lanes.types'

export const showFTL = (transportModeRoad) => {
    return [
        TransportModeRoad.FTL,
        TransportModeRoad.FTL_OR_LTL,
    ].includes(transportModeRoad)
}

export const showLTL = (transportModeRoad) => {
    return [
        TransportModeRoad.LTL,
        TransportModeRoad.FTL_OR_LTL,
    ].includes(transportModeRoad)
}

export const isDeliveryRoad = (deliveryServiceType) => {
    return deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD
}

export const isCollection = (collectionServiceType) => {
    return [
        CollServiceType.COLLECTION,
        CollServiceType.DROPOFF_AIRPORT,
    ].includes(collectionServiceType)
}

export const isCollectionOnly = (type) => {
    return type === CollServiceType.COLLECTION
}
