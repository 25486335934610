import React, {
    useCallback,
    useEffect,
    useState,
} from 'react'
import noop from 'lodash/noop'

import replaceTempIds from 'app/utils/replaceTempIds'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import DomainObjectFormWithControls from 'app/shared-components/DomainObjectFormWithControls'
import {
    RequestMethods,
} from 'app/types/common.enums'
import {
    FormFields,
} from 'app/types/form.types'

import useSkyNetConfirmationContext from '../SkyNetConfirmation/useSkyNetConfirmationContext'

import useSimpleApiRequest from './hooks/useSimpleApiRequest'

type Props = {
    value?: Record<string, any>,
    name: string,
    onSuccess?: (data: Record<string, any>) => void,
    onError?: (err: any) => void,
    setExternalValue?: (data: Record<string, any>) => void,
    requestParams: {
        url: string,
        method?: string,
    },
    modifyDataBeforeSend?: (data: Record<string, any>) => Record<string, any>
    onCancel?: () => void
    disabled?: boolean,
    exists?: boolean,
    className?: string,
    canDelete?: boolean,
    canCancel?: boolean,
    createEnabled?: boolean,
    cancelEnabled?: boolean,
    customLabelSave?: string,
    'data-testid'?: string,
    customButtons?: JSX.Element[],
    getCustomButtons?: (a: any) => JSX.Element[],
    classNames?: {
        gridWrapper?: string,
        gridAreaTemplate?: string,
        gridCardWrapper?: string,
        title?: string,
    },
    fields: FormFields,
    noCard?: boolean,
    wrapper?: string,
    disableOnError?: boolean,
    isValid?: boolean,
}

const defaultProps = {
    value: {},
    onSuccess: noop,
    onError: noop,
    onCancel: noop,
    setExternalValue: noop,
    modifyDataBeforeSend: (value) => { return value },
    disabled: false,
    exists: false,
    className: undefined,
    canDelete: false,
    canCancel: true,
    createEnabled: true,
    cancelEnabled: false,
    customLabelSave: undefined,
    'data-testid': undefined,
    customButtons: undefined,
    classNames: undefined,
    noCard: false,
    wrapper: undefined,
    disableOnError: false,
    isValid: true,
    getCustomButtons: undefined,
}

const FormWithControls = ({
    value,
    name,
    disabled,
    exists,
    requestParams,
    onSuccess,
    onError,
    modifyDataBeforeSend,
    setExternalValue,
    onCancel,
    className,
    canDelete,
    canCancel,
    createEnabled,
    cancelEnabled,
    customLabelSave,
    'data-testid': dataTestid,
    getCustomButtons,
    customButtons,
    classNames,
    fields,
    noCard,
    wrapper,
    disableOnError,
    isValid,
}: Props) => {
    const [defaultData] = useState<Record<string, any>>(value)
    const [
        data,
        setData,
    ] = useState<Record<string, any>>()

    const {
        setOpen,
        setAction,
    } = useSkyNetConfirmationContext() || {}

    useEffect(() => {
        setData((oldData) => {
            if (value !== oldData) {
                return value
            }
            return oldData
        })
    }, [value])

    const {
        onStartSaving,
        onError: onErrorHandler,
    } = useHandleStatusRequest({
        id: name,
    })

    const onChange = (newValue: Record<string, any>): void => {
        setData(newValue)
        setExternalValue(newValue)
    }

    const cancelChanges = (): void => {
        setData(defaultData)
        onCancel()
    }

    const getModifiedData = useCallback((): Promise<Record<string, any>> => {
        const modifiedData = modifyDataBeforeSend(replaceTempIds(data))

        if (!(modifiedData instanceof Promise)) {
            return Promise.resolve(modifiedData)
        }

        return modifiedData
    }, [
        modifyDataBeforeSend,
        data,
    ])

    const onSuccessUpdate = (resp: Record<string, any>): any => {
        onSuccess({
            ...resp,
            action: RequestMethods.UPDATE,
        })
    }

    const onSuccessCreate = (resp: Record<string, any>): void => {
        onSuccess({
            ...resp,
            action: RequestMethods.CREATE,
        })
    }

    const onSuccessDelete = (resp: Record<string, any>): void => {
        onSuccess({
            ...resp,
            action: RequestMethods.DELETE,
        })
    }

    const updateRequest = useSimpleApiRequest({
        name,
        requestParams: {
            method: 'PUT',
            ...requestParams,
        },
        onSuccess: onSuccessUpdate,
        onError,
    })

    const createRequest = useSimpleApiRequest({
        name,
        requestParams: {
            method: 'POST',
            ...requestParams,
        },
        title: 'The object has been created correctly.',
        onSuccess: onSuccessCreate,
        onError,
    })

    const deleteRequest = useSimpleApiRequest({
        name,
        requestParams: {
            ...requestParams,
            method: 'DELETE',
        },
        onSuccess: onSuccessDelete,
        onError,
    })

    const updateEntity = useCallback((): void => {
        getModifiedData()
            .then((modifiedData) => {
                onStartSaving()
                updateRequest.mutate(modifiedData)
            })
            .catch(onErrorHandler)
    }, [
        getModifiedData,
        onErrorHandler,
        onStartSaving,
        updateRequest,
    ])

    const createEntity = useCallback((): void => {
        getModifiedData()
            .then((modifiedData) => {
                onStartSaving()
                createRequest.mutate(modifiedData)
            })
            .catch(onErrorHandler)
    }, [
        getModifiedData,
        onErrorHandler,
        onStartSaving,
        createRequest,
    ])

    const deleteEntity = useCallback(() => {
        onStartSaving()
        deleteRequest.mutate(undefined)
    }, [
        deleteRequest,
        onStartSaving,
    ])

    const onDelete = useCallback(() => {
        if (setAction && setOpen) {
            setAction(() => { return deleteEntity })
            setOpen(true)
            return
        }

        deleteEntity()
    }, [
        deleteEntity,
        setAction,
        setOpen,
    ])

    return (
        <>
            <DomainObjectFormWithControls
                value={data}
                name={name}
                onChange={onChange}
                reset={cancelChanges}
                update={updateEntity}
                create={createEntity}
                remove={onDelete}
                edited={data !== defaultData}
                disabled={disabled}
                exists={exists}
                className={className}
                canDelete={canDelete}
                canCancel={canCancel}
                createEnabled={createEnabled}
                cancelEnabled={cancelEnabled}
                customLabelSave={customLabelSave}
                data-testid={dataTestid}
                getCustomButtons={getCustomButtons}
                customButtons={customButtons}
                classNames={classNames}
                fields={fields}
                noCard={noCard}
                wrapper={wrapper}
                isLoading={createRequest?.isPending || updateRequest.isPending}
                disableOnError={disableOnError}
                isValid={isValid}
            />
        </>
    )
}

FormWithControls.defaultProps = defaultProps

export default FormWithControls
