import {
    RequestConfig,
} from 'app/types/request.types'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

type LaneGroupRequests = {
    domainName: string,
    create: ({
        contractId: number,
    }) => RequestConfig
    update: ({
        id: number,
    }) => RequestConfig,
    editableData: ({
        id: number,
    }) => RequestConfig,
    get: ({
        id: number,
    }) => RequestConfig,
    duplicate: ({
        id: number,
    }) => RequestConfig,
    archive: ({
        id: number,
    }) => RequestConfig,
    restore: ({
        id: number,
    }) => RequestConfig,
    filterRequest: ({
        contractId,
        quoteId,
        params,
    }) => RequestConfig,
    filterCountRequest: ({
        contractId,
    }) => {
        customUrl: string
    },
    modifications: ({
        id: number,
    }) => RequestConfig,
    handoverData: ({
        data,
    }) => RequestConfig,
    [RequestKeys.checkAwardedLanes]: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
}

const requests: LaneGroupRequests = {
    domainName: 'LaneGroup',
    create: ({
        contractId,
    }) => {
        return {
            url: `contractbasis/${contractId}/lanegroup`,
            method: 'POST',
        }
    },
    update: ({
        id,
    }) => {
        return {
            url: `lanegroup/${id}`,
            method: 'PATCH',
        }
    },
    editableData: ({
        id,
    }) => {
        return {
            url: `lanegroup/${id}/editable-data`,
            method: 'PATCH',
        }
    },
    get: ({
        id,
    }) => {
        return {
            url: `lanegroup/${id}`,
            method: 'GET',
        }
    },
    duplicate: ({
        id,
    }) => {
        return {
            url: `lanegroup/${id}/duplication`,
            method: 'PATCH',
        }
    },
    archive: ({
        id,
    }) => {
        return {
            url: `lanegroup/${id}/archive`,
            method: 'PATCH',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `lanegroup/${id}/restore`,
            method: 'PATCH',
        }
    },
    filterRequest: ({
        contractId,
        quoteId,
        params,
    }) => {
        return {
            url: `contractbasis/${contractId}/lanegroup/filter-request/all`,
            method: 'POST',
            data: {
                quoteId,
            },
            ...params,
        }
    },
    filterCountRequest: ({
        contractId,
    }) => {
        return {
            customUrl: `contractbasis/${contractId}/lanegroup/filter-request/all`,
        }
    },
    modifications: ({
        id,
    }) => {
        return {
            url: `lanegroup/${id}/modifications`,
            method: 'GET',
        }
    },
    handoverData: ({
        data,
    }) => {
        return {
            url: `lanegroup/${data.id}/handover-data`,
            method: 'PATCH',
            data,
        }
    },
    [RequestKeys.checkAwardedLanes]: ({
        id,
    }) => {
        return {
            url: `lanegroup/${id}/awarded-implemented-lanes-data`,
            method: 'GET',
        }
    },
}

export default requests
