import {
    RequestConfig,
} from 'app/types/request.types'

type OpportunitiesRequests = {
    domainName: string,
    filterRequest: ({
        id,
        params,
    }) => RequestConfig,
    filterCountRequest: ({
        id,
    }) => {
        customUrl: string
    },
}

const opportunitiesRequests: OpportunitiesRequests = {
    domainName: 'Opportunity',
    filterRequest: ({
        id,
        params,
    }) => {
        return {
            url: `opportunity/regionalgroup/${id}/filter-request/all`,
            method: 'POST',
            ...params,
        }
    },
    filterCountRequest: ({
        id,
    }) => {
        return {
            customUrl: `opportunity/regionalgroup/${id}/filter-request/all`,
        }
    },
}

export default opportunitiesRequests
