import {
    createContext, useContext,
} from 'react'

import {
    StepperContextType,
} from 'app/types/common.types'

import {
    FeesData,
    RateCardsData,
} from 'app/Apps/AccountManagement/CQuotes/CQuotes.types'
import {
    StepperData,
} from './simplifiedCQuoteCreate.types'

export type SimplifiedCQuoteCreateContextType = StepperContextType<StepperData> & {
    rateCardsData: RateCardsData,
    setRateCardsData: (val: RateCardsData) => void,
    feesData: FeesData,
    setFeesData: (val: FeesData) => void,
}

export const SimplifiedCQuoteCreateContext = createContext<SimplifiedCQuoteCreateContextType>(null)

const useSimplifiedCQuoteCreateContext = () => {
    return useContext<SimplifiedCQuoteCreateContextType>(SimplifiedCQuoteCreateContext)
}

export default useSimplifiedCQuoteCreateContext
