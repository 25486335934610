import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    DeletedLogger,
} from 'app/Apps/ContainerManagement/Containers/containers.types'
import {
    NestedKeyOf,
} from 'app/types/utils.types'
import {
    LoggerConfiguration,
} from 'app/types/enums'

const deletedLoggersColumns: RichTableColumns<NestedKeyOf<DeletedLogger>>[] = [
    {
        id: 'logger.loggerNumber',
        headerProps: {
            children: 'Logger',
        },
        mapCellProps: 'logger.loggerNumber',
        minWidth: '150px',
    },
    {
        id: 'isDefaultLogger',
        headerProps: {
            children: 'Is Default',
        },
        mapCellProps: 'isDefaultLogger',
        componentName: 'Boolean',
    },
    {
        id: 'loggerPositionConfiguration',
        headerProps: {
            children: 'Logger Configuration',
        },
        mapCellProps: 'loggerPositionConfiguration',
        componentName: 'EnumValue',
        allowedValuesEnum: LoggerConfiguration,
        minWidth: '150px',
    },
    {
        id: 'exchangeTimestamp',
        headerProps: {
            children: 'Exchange On',
        },
        mapCellProps: 'exchangeTimestamp',
        minWidth: '200px',
        componentName: 'DateTimeCell',
    },
    {
        id: 'removedOn',
        headerProps: {
            children: 'Removed On',
        },
        mapCellProps: 'removedOn',
        minWidth: '200px',
        componentName: 'DateTimeCell',
    },
    {
        id: 'deletedBy',
        headerProps: {
            children: 'Deleted By',
        },
        mapCellProps: 'deletedBy',
    },
    {
        id: 'deletedOn',
        headerProps: {
            children: 'Deleted On',
        },
        mapCellProps: 'deletedOn',
        minWidth: '200px',
        componentName: 'DateTimeCell',
    },
]

export default deletedLoggersColumns
