import {
    AddressMainCategory,
    Location,
} from 'app/Apps/ContactManagement/Locations/Locations.types'
import {
    Validate,
} from 'app/types/form.types'
import {
    AddressCategory,
} from 'app/types/enums'

export const isProspect: Validate = (location: Location, errorMsg?: string) => {
    if (location?.isProspect) {
        return [errorMsg || 'The Location is a Prospect.']
    }
    return true
}

export const isNotShippingLocation: Validate = (location: Location, errorMsg?: string) => {
    if (!location?.isShippingLocation) {
        return [errorMsg || 'The Location is not a Shipping Location.']
    }
    return true
}

export const isCategoryNotServiceCenter: Validate = (
    location: Location, errorMsg?: string,
) => {
    if (location?.addressCategory?.includes(AddressCategory.SERVICECENTER)) {
        return true
    }
    return [errorMsg || 'Location needs to be a Service Center.']
}

export const isAddressMainCategoryServiceCenter: Validate = (
    location: Location, errorMsg?: string,
) => {
    if (location?.addressMainCategory?.includes(AddressMainCategory.SERVICECENTER)) {
        return [errorMsg || 'The Collection/Drop-off Point cannot be a Service Center.']
    }
    return true
}

export const isProspectAndNotShippingLocation: Validate = (
    location: Location, errorMsg?: string,
) => {
    const notShippingError = isNotShippingLocation(location)

    if (Array.isArray(notShippingError) && notShippingError?.length) {
        const isProspectError = isProspect(location)

        if (Array.isArray(isProspectError) && isProspectError?.length) {
            return [errorMsg || 'The Location is a Prospect and not a Shipping Location.']
        }
        return notShippingError
    }

    return isProspect(location)
}

export const addressCategoryValidation: Validate = (
    location: Location, errorMsg?: string,
) => {
    const isAddressCategoryError = isCategoryNotServiceCenter(location, errorMsg)

    if (Array.isArray(isAddressCategoryError) && !isAddressCategoryError?.length) {
        return isProspectAndNotShippingLocation(location, errorMsg)
    }

    return isAddressCategoryError
}

export const addressMainCategoryValidation: Validate = (
    location: Location, errorMsg?: string,
) => {
    const isAddressMainCategoryError = isAddressMainCategoryServiceCenter(location, errorMsg)

    if (Array.isArray(isAddressMainCategoryError) && !isAddressMainCategoryError?.length) {
        return isProspectAndNotShippingLocation(location, errorMsg)
    }

    return isAddressMainCategoryError
}
