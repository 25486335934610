import {
    useReducer, useMemo, useCallback, useState,
} from 'react'

export type FilterState = Record<string, any>

const initialState = {} as FilterState
const SET_FILTER = 'SET_FILTER'
const REMOVE_FILTER = 'REMOVE_FILTER'
const CLEAR_FILTERS = 'CLEAR_FILTERS'

export type FilterPayload = {
    filterField: string,
    value?: (string|number|boolean)[]
}

function filterReducer(
    state: FilterState = initialState,
    action: { type: string, payload?: FilterPayload },
): FilterState {
    switch (action?.type) {
    case SET_FILTER: {
        const {
            filterField, value,
        } = action.payload

        return {
            ...state,
            [filterField]: value,
        }
    }

    case CLEAR_FILTERS: {
        return {
            ...initialState,
        }
    }

    case REMOVE_FILTER: {
        const newState = {
            ...state,
        }

        delete newState[action.payload.filterField]
        return newState
    }

    default: return state
    }
}

export type FilteredData<T = Record<string, any>[]> = T

export type FilterOptions<D = Record<string, any>[]> = {
    filter: FilterState,
    setFilter: (payload: FilterPayload) => void,
    removeFilter: (payload: FilterPayload) => void,
    clearFilters: () => void,
    filteredData: FilteredData<D>,
    setFilteredData: React.Dispatch<React.SetStateAction<FilteredData>>
}

export default <D = Record<string, any>[]>(): FilterOptions<D> => {
    const [
        filter,
        dispatch,
    ] = useReducer(filterReducer, initialState)

    const [
        filteredData,
        setFilteredData,
    ] = useState<FilteredData>([])

    const setFilter = useCallback((payload: FilterPayload) => {
        return dispatch({
            type: SET_FILTER,
            payload,
        })
    }, [dispatch])

    const removeFilter = useCallback((payload: FilterPayload) => {
        return dispatch({
            type: REMOVE_FILTER,
            payload,
        })
    }, [dispatch])

    const clearFilters = useCallback(() => {
        return dispatch({
            type: CLEAR_FILTERS,
        })
    }, [dispatch])

    return useMemo(() => {
        return {
            filter,
            setFilter,
            clearFilters,
            removeFilter,
            filteredData,
            setFilteredData,
        } as unknown as FilterOptions<D>
    }, [
        setFilter,
        clearFilters,
        removeFilter,
        filter,
        filteredData,
        setFilteredData,
    ])
}
