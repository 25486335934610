import ContainerStatusEnriched from 'app/Apps/DomainObject/ComponentMapper/ContainerStatusEnriched'
import InputSingleline from 'app/shared-components/InputSingleline'
import Checkbox from 'app/shared-components/Checkbox'
import Select from 'app/shared-components/Select'
import getUniqueId from 'app/utils/getUniqueId'
import {
    RejectionReason,
} from 'app/types/enums'
import {
    Column,
} from 'app/types/simpletable.types'
import SkyNetLink, {
    SizeLink, TypeLink,
} from 'app/shared-components/SkyNetLink'

import {
    DedicatedContainerType,
} from '../../longTermLease.types'

type Args = {
    classes: Record<string, any>,
    l: (key:string)=> string,
    rejectionReasons: Record<string, string>,
    getAvailableContainersLink: (containerSerialNumber: string) => string,
    disabled: boolean,
}

type DedicatedContainerTableType = DedicatedContainerType & {
    selected?: boolean,
}

const getColumns = ({
    classes,
    l,
    rejectionReasons,
    getAvailableContainersLink,
    disabled,
}: Args): Column<keyof DedicatedContainerTableType>[] => {
    return [
        ...!disabled ? [{
            label: '',
            component: Checkbox,
            fieldName: 'selected',
            getProps: () => {
                return {
                    className: classes.checkbox,
                }
            },
            id: getUniqueId(),
        }] as Column<keyof DedicatedContainerTableType>[] : [],
        {
            label: l('OrderSelectedContainersView.serialNumber'),
            component: SkyNetLink,
            fieldName: 'containerSerialNumber',
            getProps: (propsData) => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                    link: getAvailableContainersLink(propsData.containerSerialNumber),
                    type: TypeLink.EXTERNAL,
                    size: SizeLink.S,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('Container.jypNumber'),
            component: InputSingleline,
            fieldName: 'containerJypId',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('ContainerView.containerStatusEnriched'),
            component: ContainerStatusEnriched,
            fieldName: 'containerContainerStatus',
            getProps: (propsData) => {
                return {
                    disabled: true,
                    rowProps: {
                        containerStatus: propsData.containerContainerStatus,
                    },
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('ContainerView.tempRange'),
            component: InputSingleline,
            fieldName: 'containerTempRange',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('OrderSelectedContainersView.containerTypeContainerModel'),
            component: InputSingleline,
            fieldName: 'containerContainerTypeContainerModel',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('Contact.location'),
            component: InputSingleline,
            fieldName: 'containerLocationLocationName',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('OrderSelectedContainersView.rejectionReason'),
            component: Select,
            fieldName: 'rejectionReason',
            getProps: () => {
                return {
                    allowedValues: rejectionReasons,
                    required: true,
                    disabled,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('OrderSelectedContainersView.rejectionComment'),
            component: InputSingleline,
            fieldName: 'rejectionComment',
            getProps: ({
                rejectionReason,
            }) => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    className: classes.input,
                    required: rejectionReason === RejectionReason.REJECTION_OTHER,
                    disabled,
                }
            },
            id: getUniqueId(),
        },
    ]
}

export default getColumns
