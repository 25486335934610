import React, {
    useCallback,
} from 'react'
import noop from 'lodash/noop'
import {
    useLocation,
} from 'react-router-dom'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import useRequestTable from 'app/hooks/useRequestTable'
import AdditionalFeesRequests from 'app/Apps/Pricing/ContractBasis/AdditionalFees/AdditionalFees.requests'

import additionalFeesColumns from '../../AdditionalFees/additionalFees.columns'

import useStyles from './AdditionalFeesTable.style'

type Props = {
    selectedRows?: string[],
    onSelectedRow?: (feesNumber: any) => void,
    contractId: number,
    getDetailPanel?: ({
        rowProps,
    })=> JSX.Element,
    onRowClick?: (object: Record<string, any>)=> void,
    openRowId?: string,
    disabled?: boolean,
    renderControlPanel?:(params: any) => JSX.Element,
    showArchived?: boolean,
}
const defaultProps: Partial<Props> = {
    getDetailPanel: null,
    onRowClick: noop,
    openRowId: null,
    disabled: false,
    selectedRows: [],
    onSelectedRow: undefined,
    renderControlPanel: noop,
    showArchived: false,
}

const DOMAIN_NAME = 'AdditionalFees'

const AdditionalFeesTable = ({
    selectedRows,
    onSelectedRow,
    contractId,
    getDetailPanel,
    onRowClick,
    openRowId,
    disabled,
    renderControlPanel,
    showArchived,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const {
        pathname,
    } = useLocation()
    const showArchivedFromUrl = pathname.includes('additionalfee-archived')

    const {
        onParamsChange,
        data,
        loadData,
    } = useRequestTable<{data:any}>({
        config: AdditionalFeesRequests.filterRequest,
        params: {
            contractId,
        },
        key: RequestKeys.getContractBasisAdditionalFee,
        showArchived: showArchived || showArchivedFromUrl,
    })

    const handleOnSelectRow = useCallback(([selectedRow]:number[]): void => {
        onSelectedRow(data?.data?.filter((selected) => {
            return selected.additionalFeesNumber === selectedRow
        }).map((number) => {
            return {
                id: number.id,
                uniqField: number.additionalFeesNumber,
            }
        }))
    }, [
        data,
        onSelectedRow,
    ])

    return (
        <RichTable
            configName="AdditionalFeesTable"
            name={DOMAIN_NAME}
            load={loadData}
            onParamsChange={onParamsChange}
            uniqField="additionalFeesNumber"
            columns={additionalFeesColumns}
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
            radioSelect
            onSelectRow={onSelectedRow ? handleOnSelectRow : undefined}
            selectedRows={selectedRows}
            detailPanel={getDetailPanel}
            onRowClick={onRowClick}
            openRowId={openRowId}
            disabled={disabled}
            renderControlPanel={renderControlPanel}
        />
    )
}

AdditionalFeesTable.defaultProps = defaultProps

export default AdditionalFeesTable
