import React from 'react'

import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'

import DamageTypeForm from '../DamageTypeForm'
import requests from '../DamageTypes.requests'

const DamageTypeCreate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    return (
        <DamageTypeForm
            onSuccess={onSuccess}
            requestParams={requests.create()}
        />
    )
}

export default DamageTypeCreate
