import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        wrapper: {
            paddingBottom: theme.spacing(1),
        },
        link: {
            color: theme.palette.primary[500],
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
            display: 'flex',
            alignItems: 'center',
            lineHeight: 'normal',
        },
        text: {
            paddingRight: theme.spacing(0.5),
            wordBreak: 'break-all',
        },
        small: {
            fontSize: theme.typography.body1.fontSize,
        },
        medium: {
            fontSize: theme.typography.h3.fontSize,
            fontWeight: theme.typography.h4.fontWeight,
        },
        mediumIcon: {
            '&&': {
                width: 24,
                height: 24,
            },
        },
        smallIcon: {
            '&&': {
                width: 20,
                height: 20,
            },
        },
    }
})

export default useStyles
