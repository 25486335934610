import {
    Currency, LeaseType, OpportunityPalletType,
    ProductType, Frequency, ContractCurrency,
    LaneStatus,
} from 'app/types/enums'
import {
    Location,
} from 'app/shared-components/LocationSelector'
import {
    Contact,
} from 'app/types/common.types'
import {
    Account,
} from 'app/Apps/AccountManagement/Accounts/Account.types'
import {
    OpportunityType,
} from '../Opportunities/opportunities.types'
import {
    AccountLane,
} from '../AccountLanes/AccountLanes.types'
import {
    CollectionServiceType,
    DeliveryServiceType,
} from '../../OrderManagement/Orders/orders.types'
import {
    LaneGroup,
} from '../../Pricing/Pricing.types'

export enum QuoteStatus {
    CREATED = 'CREATED',
    IN_PROGRESS = 'IN_PROGRESS',
    CLOSED = 'CLOSED',
    CANCELLED = 'CANCELLED',
}

export type AQuoteTable = {
    accountName: string,
    pricingAccountCompanyName: string,
    currency: Currency,
    id: number,
    quoteNumber: string,
    quotedForCompanyLocationName: string,
    requestedBy: string,
    createdBy: string,
    quoteStatus: QuoteStatus,
    pricingResponsible: Contact,
    originAirportsIataCode: string[],
    destinationAirportsIataCode: string[],
}

export type RequestedOpportunity = {
    id: number,
    opportunity: OpportunityType,
    productTypes: ProductType[],
    palletType: OpportunityPalletType
}

export type RequestedLane = {
    id: number,
    expPalletsYear: number,
    frequency: Frequency,
    accountLane: AccountLane,
    baseLeaseDays?: number[],
    otherComments: string
}

export type AQuote = {
    id: number,
    createdOn: string,
    changedOn: string,
    createdBy: string,
    changedBy:string,
    quoteNumber: string,
    quoteStatus: QuoteStatus,
    account: Account,
    currency: ContractCurrency,
    leaseType: LeaseType,
    quotedForCompany: Location,
    quotedFor: string,
    requestedBy: {
        id: number
    },
    isPerformanceQualification: boolean,
    requestedOpportunities: RequestedOpportunity[],
    requestedLanes: RequestedLane[],
    supportingDocuments?: {id: number}[],
    pricingResponsible: Contact,
    comment: string,
    customerQuotation: Location,
    laneStatus: LaneStatus,
    plannedContractBasis: {
        id: number,
    },
    linkedContractBasis: {
        id: number,
    },
    contractBasisNumber: string,
    linkedContractBasisId?: number,
    archived: boolean,
    skyCellContact: {id: number}
}

export type AssignPricingLaneGroupsTable = {
    deliveryServiceType: DeliveryServiceType,
    handoverPointLocationName: string,
    collectionServiceType: CollectionServiceType,
    collectionDropoffPointLocationName: string,
    leaseType: LeaseType,
    originAirportsIataCode: string[],
    destinationAirportsIataCode: string[],
    tempRange: string,
    laneGroupNumber: string,
    laneFeesValidTo?: string,
    additionalFeesValidTo?: string,
}

export type AssignPricingLaneGroupResponse = {
    laneGroup: LaneGroup,
    quote: {
        id: 0,
        changedOn: string,
        baseLeaseDays: number[]
    },
    lanes: AccountLane[],
    requestedOpportunity: RequestedOpportunity
}

export type RequestDataUpdate = {
    requestedOpportunities: {
        opportunity: { id: number },
        productTypes: ProductType[],
        palletType: OpportunityPalletType
    }[],
    requestedLanes: {
        accountLane: { id: number },
        baseLeaseDays?: number[],
        otherComments: string
    }[]
}

export enum AQuotesTab {
    AssignQuote ='assign-quote',
    ReviewGroup = 'review-group',
    AssignPricing = 'assign-pricing',
    AwardReject = 'award-reject',
    QuotePdf = 'quote-pdf',
}
