import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    ContractCurrency,
} from 'app/types/enums'
import {
    SizeLink, TypeLink,
} from 'app/shared-components/SkyNetLink'
import {
    TypeIcon,
} from 'app/shared-components/NotificationIcon/notificationIcon.types'
import {
    CQuote,
} from '../CQuotes.types'

const getCQuoteFormFields = ({
    getAllowedValues,
    linkedContractBasis,
    contractBasisNumber,
}: {
    getAllowedValues: (r: Record<string, string>) => Record<string, string>,
    linkedContractBasis: string,
    contractBasisNumber: string,
}): DTOCardFormField<keyof CQuote>[] => {
    return [{
        id: 'cquote-form',
        title: 'Quote Information',
        fields: [
            {
                id: 'cquote-form-col-1',
                fields: [
                    {
                        name: 'pricingAccount',
                        labelKey: 'AccountLane.pricingAccount',
                        componentName: 'AccountSelector',
                    },
                    {
                        name: 'currency',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(ContractCurrency),
                        useDropdown: true,
                        required: true,
                        labelKey: 'LaneOpsInformationDto.currency',
                    },
                    {
                        name: 'linkedContractBasis',
                        labelKey: 'ContractBasis.assignedContractBasisId',
                        type: TypeLink.EXTERNAL,
                        size: SizeLink.S,
                        componentName: 'SkyNetLink',
                        text: contractBasisNumber,
                        link: (contractBasisNumber && linkedContractBasis)
                            ? linkedContractBasis : undefined,
                    },
                    {
                        name: 'quotedForCompany',
                        componentName: 'LocationObjectSelector',
                        labelKey: 'AccountQuotes.quotedForCompany',
                    },
                ],
            },
            {
                id: 'cquote-form-col-2',
                fields: [
                    {
                        name: 'comment',
                        componentName: 'LongText',
                        labelKey: 'AccountQuotes.comment',
                    },
                    {
                        name: 'quotedFor',
                        componentName: 'ContactObjectSelector',
                        notificationIconProps: {
                            text: 'This contact will be shown in the quote document as a Customer Contact.',
                            type: TypeIcon.INFO,
                        },
                        labelKey: 'AccountQuotes.quotedFor',
                    },
                    {
                        name: 'skyCellContact',
                        componentName: 'UserObjectSelector',
                        labelKey: 'AccountQuotes.skycellContact',
                    },
                ],
            },
            {
                id: 'cquote-form-col-3',
                fields: [
                    {
                        name: 'createdOn',
                        labelKey: 'Accounts.createdOn',
                        componentName: 'DateTimeSelect',
                    },
                    {
                        name: 'createdBy',
                        labelKey: 'Accounts.createdBy',
                        componentName: 'InputSingleline',
                    },
                ],
            },
        ],
    }]
}

export default getCQuoteFormFields
