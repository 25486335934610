import {
    RequestConfig,
} from 'app/types/request.types'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

type CQuoteRequest = {
    [RequestKeys.getCQuoteFeesData]: (params?: Record<string, any>) => RequestConfig
    create: (params?: Record<string, any>) => RequestConfig,
    [RequestKeys.getCQuoteQuotationDetails]: (params?: Record<string, any>) => RequestConfig,
    [RequestKeys.cQuotesFilterRequest]: (params?: Record<string, any>) => RequestConfig,
    [RequestKeys.getCQuote]: ({
        id,
    }: {id: number}) => RequestConfig
    [RequestKeys.getCQuoteDocuments]: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
}

const cQuoteRequest: CQuoteRequest = {
    [RequestKeys.getCQuoteFeesData]: (params) => {
        return {
            url: 'cquote/fees-data',
            data: {
                ...params,
            },
            method: 'POST',
        }
    },
    create: (params = {}) => {
        return {
            url: 'cquote/generate',
            data: {
                ...params,
            },
            method: 'POST',
        }
    },
    [RequestKeys.getCQuoteQuotationDetails]: (params = {}) => {
        return {
            url: 'cquote/quotation-details',
            data: {
                ...params,
            },
            method: 'POST',
        }
    },
    [RequestKeys.cQuotesFilterRequest]: (params = {}) => {
        return {
            url: 'cquote/filter-request/all',
            method: 'POST',
            ...params,
        }
    },
    [RequestKeys.getCQuote]: ({
        id,
    }) => {
        return {
            url: `cquote/${id}`,
            method: 'GET',
        }
    },
    [RequestKeys.getCQuoteDocuments]: ({
        id,
    }) => {
        return {
            url: `cquote/${id}/cquotedocument/filter-request/all`,
            method: 'POST',
        }
    },
}

export default cQuoteRequest
