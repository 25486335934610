import randomColor from 'app/utils/generateRandomColor'
import {
    LoggerDataTypes,
} from '../Loggers/Loggers.types'

import {
    getDoorEventSeriesOptions,
} from './doorEvent'

const AMBIENT_TEMPERATURE_PREFIX = 'Ambient'
const INTERNAL_TEMPERATURE_PREFIX = 'Internal'
const DOOR_EVENT_LABEL_PREFIX = 'Door'

const options = {
    chartArea: {
        left: 30,
        top: 20,
        width: '95%',
        height: '87%',
    },
    axisTitlesPosition: 'none',
    vAxis: {
        title: 'Temperature',
        gridlines: {
            count: 0,
        },
    },
    curveType: 'function',
    intervals: {
        style: 'sticks',
        lineWidth: 3,
    },
}

const getDateRangeSeriesOptions = (isDateRange) => {
    if (isDateRange) {
        return [
            {
                targetAxisIndex: 0,
                lineWidth: 3,
                color: '#edae49',
                lineDashStyle: [
                    4,
                    4,
                ],

            },
            {
                targetAxisIndex: 0,
                lineWidth: 3,
                color: '#edae49',
                lineDashStyle: [
                    4,
                    4,
                ],
            },
        ]
    }
    return []
}

const getSeriesOptions = (sensorLabels, showTempRange, showDoorEvent, numberDoorEventLoggers) => {
    const firstAmbienTemp = sensorLabels ? sensorLabels.findIndex((label) => {
        return label.includes(AMBIENT_TEMPERATURE_PREFIX)
    }) : null
    const firstInternalTemp = sensorLabels ? sensorLabels.findIndex((label) => {
        return label.includes(INTERNAL_TEMPERATURE_PREFIX)
    }) : null

    const series = (sensorLabels || []).map((label, index) => {
        if (label.includes(AMBIENT_TEMPERATURE_PREFIX) && index === firstAmbienTemp) {
            return {
                targetAxisIndex: 0,
                lineWidth: 3,
                color: '#CF3B8A',
                pointSize: 6,
            }
        }
        if (label.includes(INTERNAL_TEMPERATURE_PREFIX) && index === firstInternalTemp) {
            return {
                targetAxisIndex: 0,
                lineWidth: 3,
                color: '#61C6E9',
                pointSize: 6,
            }
        }
        return {
            targetAxisIndex: 0,
            lineWidth: 3,
            color: randomColor(),
            pointSize: 6,
            visibleInLegend: !showDoorEvent || !label.includes(DOOR_EVENT_LABEL_PREFIX),
        }
    })

    return [
        {
            targetAxisIndex: 0,
            lineWidth: 0,
            color: 'transparent',
            pointSize: 0,
            visibleInLegend: false,
            tooltip: false,
        },
        ...series,
        ...getDateRangeSeriesOptions(showTempRange),
        ...getDoorEventSeriesOptions(showDoorEvent, numberDoorEventLoggers),
    ]
}

const getChartOptions = (sensorLabels,
    showTempRange = false, showDoorEvent = false, numberDoorEventLoggers = 0, dataType) => {
    return {
        ...options,
        ...dataType === LoggerDataTypes.RELATIVE_HUMIDITY ? {
            vAxis: {
                title: 'Temperature',
                gridlines: {
                    count: 0,
                },
                format: '#\'%\'',
            },
        } : {},
        series: getSeriesOptions(sensorLabels,
            showTempRange,
            showDoorEvent,
            numberDoorEventLoggers),
    }
}

export default getChartOptions
