import React, {
    useMemo,
} from 'react'

import useShareContext from 'app/shared-components/SharedContext/useSharedContext'

import getSummaryTimeSteps from '../utils/getSummaryTimeSteps'
import {
    AnalyseTemperatureType,
    SimulationStep,
    SimulationTempResult,
} from '../simulation.types'

import AnalyseTemperatureChart from './AnalyseTemperatureChart'
import Summary from './Summary'
import SimulationOptions from './SimulationOptions'
import useStyles from './AnalyseTemperature.style'

type Props = {
    value?: AnalyseTemperatureType,
    onChange: (newVal: AnalyseTemperatureType) => void
}

const defaultProps = {
    value: {},
}

const AnalyseTemperature = ({
    value,
    onChange,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const {
        simulationResults,
        simulationSteps,
    }: {
        simulationResults: SimulationTempResult[],
        simulationSteps: SimulationStep[],
    } = useShareContext()

    const summaryTimeSteps: {
        h?: number,
        m?: number,
    } = useMemo(() => {
        return getSummaryTimeSteps(simulationSteps)
    }, [simulationSteps])

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <Summary
                    steps={simulationSteps}
                    simulationResults={simulationResults}
                    summaryTimeSteps={summaryTimeSteps}
                    value={value}
                    onChange={onChange}
                />
                <SimulationOptions
                    value={value}
                    onChange={onChange}
                />
            </div>
            <AnalyseTemperatureChart
                simulationSteps={simulationSteps}
                value={value}
                summaryTimeSteps={summaryTimeSteps}
            />
        </div>
    )
}

AnalyseTemperature.defaultProps = defaultProps

export default AnalyseTemperature
