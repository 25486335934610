import React, {
    useState,
    useCallback,
    useMemo,
} from 'react'
import useRequestTable from 'app/hooks/useRequestTable'
import {
    AccountLanesTable,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import requests from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.requests'
import Card from 'app/shared-components/Card'
import useLabel from 'app/hooks/useLabel'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Button from 'app/shared-components/Button'

import {
    LaneStatus, LeaseType, TemperatureRange,
} from 'app/types/enums'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    TableResponse,
} from 'app/types/request.types'

import useLoadFilters from 'app/Apps/DomainObject/hooks/useLoadFilters'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import useAccountManagementRoutes from 'app/Apps/AccountManagement/hooks/useAccountManagementRoutes'

import SkyNetStepperButtons from 'app/shared-components/SkyNetStepper/SkyNetStepperButtons'
import useCQuoteInformationContext from '../../CQuoteInformationContext'
import useStyles from '../../CQuoteCreate.styles'
import getColumns from './CQuoteSelectLane.columns'

export default function CQuoteSelectLane({
    index,
}: Readonly<{index: number}>) {
    const {
        Lanes: LanesRoutes,
    } = useAccountManagementRoutes()
    const defaultFilters = useMemo(() => {
        return {
            laneStatus: Object.values(LaneStatus).filter((status) => {
                return ![
                    LaneStatus.CANCELLED,
                    LaneStatus.REJECTED_PRICING,
                    LaneStatus.REJECTED_CUSTOMER,
                ].includes(status)
            }),
            leaseType: [LeaseType.DIRECT_LEASE],
            temperatureRange: [
                TemperatureRange.C,
                TemperatureRange.CRT,
            ],
            accountIsStrategicAccount: [false],
        }
    }, [])

    const {
        value,
        setValue,
        setActiveStep,
        activeStep,
    } = useCQuoteInformationContext()
    const {
        classes,
    } = useStyles()
    const l = useLabel()
    const {
        loadData,
        onParamsChange,
    } = useRequestTable<TableResponse<AccountLanesTable>>({
        config: requests.filterRequest,
        params: {},
        key: RequestKeys.getAccountLanes,
        keepPreviousData: true,
        defaultValue: {
            filter: defaultFilters,
        },
    })

    const [
        selected,
        setSelected,
    ] = useState<number>(null)

    const columns = useMemo(() => {
        return getColumns({
            l,
            getLaneLink: (id) => {
                return id ? generateSkyNetLink({
                    domainPath: LanesRoutes.Overview.path,
                    params: {
                        key: id,
                    },
                }) : null
            },
            laneStyle: classes.skyNetLink,
        })
    }, [l]) //eslint-disable-line

    const onSelectRow = useCallback((ids: number[]) => {
        setSelected(ids?.[0] || null)
    }, [])

    const nextAction = useCallback(() => {
        if (selected) {
            setValue({
                lane: {
                    id: selected,
                },
            })
        }
        setActiveStep(activeStep + 1)
    }, [
        selected,
        setActiveStep,
        activeStep,
        setValue,
    ])

    const injectLoadFilters = useLoadFilters(
        {
            customUrl: requests.filterRequest().url,
        },
    )

    const columnsWithFilters = useMemo(() => {
        return injectLoadFilters(columns)
    }, [
        injectLoadFilters,
        columns,
    ])

    const customButtons = useMemo(() => {
        return [<Button
            key="create-lane"
            label="Create Lane"
            disabled={activeStep > index}
            onClick={() => {
                setValue({
                    lane: null,
                })
                setSelected(null)
                setActiveStep(activeStep + 1)
            }}
            className={classes.stepperButton}
            data-testid="btn-next"
        />]
    }, [
        setValue,
        index,
        activeStep,
        classes.stepperButton,
        setActiveStep,
    ])

    const editAction = useCallback(() => {
        setActiveStep(index)
        setValue({
            lane: value?.lane,
        })
    }, [
        index,
        setActiveStep,
        setValue,
        value?.lane,
    ])

    return (
        <>
            <Card
                className={classes.cardWrapper}
                title="Available Lanes"
                titleClass={classes.cardTitle}
                contentClass={classes.cardContent}
            >
                <RichTable
                    configName="CQuoteSelectLane"
                    name="Available Lanes"
                    onParamsChange={onParamsChange}
                    radioSelect
                    disabled={activeStep !== index || value?.nonEditable}
                    onSelectRow={onSelectRow}
                    load={loadData}
                    columns={columnsWithFilters}
                    selectedRows={[selected]}
                    uniqField="id"
                    className={classes.richTableContent}
                    filter={defaultFilters}
                    classNames={{
                        contentWrapper: classes.richTableContentWrapper,
                    }}
                />
            </Card>
            <SkyNetStepperButtons
                isValid
                disabled={!selected || value?.nonEditable}
                nextLabel="Continue"
                nextAction={nextAction}
                canEdit={activeStep > index}
                editAction={editAction}
                customButtons={customButtons}
            />
        </>
    )
}
