import useRequestTable from 'app/hooks/useRequestTable'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import requests from 'app/shared-components/AreaSelector/AreaSelector.request'

import {
    LocationAreas,
} from '../LocationAreas.types'

export default (locationId) => {
    const {
        onParamsChange,
        isDataReady,
        isFetching,
        isError,
        error,
        refetch,
        data,
        loadData,
    } = useRequestTable<LocationAreas>({
        key: RequestKeys.getLocationAreaDetailsByLocationId,
        params: {
            id: locationId,
        },
        config: requests.filterRequest,
        keepPreviousData: true,
    })

    return {
        onParamsChange,
        isDataReady,
        isFetching,
        isError,
        error,
        refetch,
        data,
        loadData,
    }
}
