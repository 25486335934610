import React, {
    useMemo,
    useState,
    useCallback,
    useEffect,
} from 'react'
import isEqual from 'lodash/isEqual'

import FormWithControls from 'app/shared-components/FormWithControls'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    Currency,
    OrderStatuses,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    BillingCompany,
    BillingInformationType,
    BillingInformationResponse,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import useGetBillingInformation from 'app/Apps/OrderManagement/Orders/CustomerTransport/hooks/useGetBillingInformation'

import useGetSharePositioning from '../SharedPositioning/hooks/useGetSharePositioning'

import useStyles from './BillingInformation.style'
import getFields from './BillingInformation.fields'

type BillingInformationFlat = BillingInformationType & {
    billingComment: string,
    leaseStartTimestamp: string,
    hasBillingCompleted: boolean,
    billingCompany: BillingCompany,
    billingCurrency: Currency,
    billingCompanyRemarks: string,
    requiresManualBillingReviewPricing: boolean,
    requiresManualBillingReviewOps: boolean,
}

const DOMAIN_NAME = orderManagementRequest.Billing.name

const modifyData = (data: BillingInformationType): BillingInformationFlat => {
    return {
        ...data,
        ...data?.billing,
        billingComment: data?.billing?.billingComment,
        leaseStartTimestamp: data?.billing?.leaseStartTimestamp,
        hasBillingCompleted: data?.billing?.hasBillingCompleted,
        requiresManualBillingReviewPricing: data?.billing?.requiresManualBillingReviewPricing,
        requiresManualBillingReviewOps: data?.billing?.requiresManualBillingReviewOps,
        billingCompany: data?.billing?.billingCompany,
        billingCurrency: data?.billing?.billingCurrency,
        billingCompanyRemarks: data?.billing?.billingCompany?.remarks,
    }
}

const BillingInformation = ({
    id: orderNumber,
}: {id: string}) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const {
        billingInformation,
        isDataReady,
        isFetching,
        isError,
        error,
    }: BillingInformationResponse = useGetBillingInformation(orderNumber)
    const {
        sharedPositioning,
    } = useGetSharePositioning(orderNumber)

    const [
        value,
        setValue,
    ] = useState<BillingInformationFlat>()

    const handleOnChange = useCallback((newValue) => {
        if (!isEqual(value, newValue)) {
            let updatedValue = {
                ...value,
                ...newValue,
            }

            if (value.billingCompany?.id !== newValue.billingCompany?.id) {
                updatedValue = {
                    ...updatedValue,
                    billingCompanyRemarks: newValue.billingCompany?.remarks,
                }
            }

            setValue(updatedValue)
        }
    }, [value])

    useEffect(() => {
        if (billingInformation) {
            setValue(modifyData(billingInformation))
        }
    }, [billingInformation])

    const isCancelled: boolean = useMemo(() => {
        return value?.orderStatus === OrderStatuses.CANCELLED
    }, [value?.orderStatus])

    const fieldsWithClasses = useFieldsWithClassName(getFields({
        baseLeaseDays: value?.billing?.baseLeaseDays,
        extraDays: value?.billing?.extraDays,
        billingCurrency: value?.billingCurrency,
        sharedPositioning,
        orderNumber,
        isCancelled,
        getAllowedValues,
    }), classes)

    const modifyDataBeforeSend = useCallback((requestData: BillingInformationFlat) => {
        return {
            billingCompany: requestData.billingCompany && {
                id: requestData.billingCompany.id,
            },
            hasBillingCompleted: requestData.hasBillingCompleted,
            leaseStartTimestamp: requestData.leaseStartTimestamp,
            billingComment: requestData.billingComment,
            billingCurrency: requestData.billingCurrency,
            requiresManualBillingReviewPricing: requestData.requiresManualBillingReviewPricing,
            requiresManualBillingReviewOps: requestData.requiresManualBillingReviewOps,
        }
    }, [])

    const requestParams = useMemo(() => {
        return orderManagementRequest.Billing.request(orderNumber)
    }, [orderNumber])

    return (
        <StatusHandler
            isSuccess={isDataReady && !!value}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <FormWithControls
                value={value}
                name={DOMAIN_NAME}
                classNames={{
                    gridWrapper: isCancelled
                        ? classes.gridWrapperCancelled
                        : classes.gridWrapper,
                }}
                fields={fieldsWithClasses}
                setExternalValue={handleOnChange}
                modifyDataBeforeSend={modifyDataBeforeSend}
                exists
                requestParams={requestParams}
            />

        </StatusHandler>
    )
}

export default BillingInformation
