import React, {
    useCallback,
    useState,
} from 'react'
import omit from 'lodash/omit'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import useEnumValues from 'app/hooks/useEnumValues'

import {
    RelinkLaneType,
} from '../dataUpdate.types'
import useRelinkLane from '../hooks/useRelinkLane'

import getFields from './relinkLaneDialog.fields'
import useStyles from './RelinkLaneDialog.style'

type Props = {
    open: boolean,
    setOpen: (value: boolean) => void
    orderNumber: string,
    onSuccess: () => void
}

const DIALOG_TITLE = 'lane update'

const RelinkLaneDialog = ({
    open,
    setOpen,
    orderNumber,
    onSuccess,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const [
        value,
        setValue,
    ] = useState<RelinkLaneType>()

    const onSuccessRelink = useCallback((): void => {
        setOpen(false)
        onSuccess()
    }, [
        onSuccess,
        setOpen,
    ])

    const {
        isPending: isLoading,
        mutate,
    } = useRelinkLane(onSuccessRelink)

    const relinkLane = useCallback((): void => {
        mutate({
            orderNumber,
            data: omit(value, ['modificationType']),
            modificationType: value?.modificationType,
        })
    }, [
        mutate,
        orderNumber,
        value,
    ])

    const handleClose = useCallback((action: boolean) => {
        if (!action) {
            setOpen(false)
            setValue(undefined)

            return
        }

        relinkLane()
    }, [
        relinkLane,
        setOpen,
    ])

    const fields = getFields(getAllowedValues)

    return (
        <ConfirmationDialog
            open={open}
            handleClose={handleClose}
            dialogTitle={DIALOG_TITLE}
            positiveLabel="update lane"
            negativeLabel="cancel"
            classNames={{
                paper: classes.paper,
            }}
            isLoading={isLoading}
        >
            <DomainObjectForm
                fields={fields}
                name="RelinkLaneOrder"
                value={value}
                onChange={setValue}
                className={classes.gridWrapper}
                wrapper="simple"
            />
        </ConfirmationDialog>
    )
}

export default RelinkLaneDialog
