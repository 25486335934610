import React from 'react'

import Button from 'app/shared-components/Button'

import {
    LaneStatus,
} from 'app/types/enums'

import useStyles from './RemoveFromLG.style'

const RemoveFromLG = ({
    laneStatus,
    onRemoveFromLG,
    laneId,
}: {
    laneStatus: LaneStatus,
    onRemoveFromLG: (params: {
        laneId: number,
    }) => void,
    laneId: number,
}) => {
    const {
        classes,
    } = useStyles()

    const onClick = () => {
        onRemoveFromLG({
            laneId,
        })
    }

    if (laneStatus === LaneStatus.IN_PROGRESS) {
        return (
            <Button
                secondary
                label="Remove from LG"
                className={classes.btnContainer}
                classNameLabel={classes.classNameLabel}
                onClick={onClick}
            />
        )
    }

    return null
}

export default RemoveFromLG
