import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import {
    OpportunityType,
} from '../../opportunities.types'

import requests from '../../opportunities.request'

export default () => {
    const token = useJWTToken()

    const {
        onSuccess,
        onError,
    } = useHandleStatusRequest({
        title: 'Opportunities were updated',
        id: 'OpportunitiesBulkUpdate',
    })

    const {
        mutateAsync: updateOpportunities,
    } = useMutation({
        mutationFn: (opportunities: OpportunityType[]) => {
            return simpleApi({
                ...requests.bulkEdit({
                    data: opportunities,
                }),
                token,
            })
        },
        onError,
        onSuccess,
    })

    return useCallback(async (opportunities: OpportunityType[], options: AsyncMutationOptions) => {
        if (!opportunities.length) {
            onError({
                response: {
                    data: ['Nothing was changed'],
                    statusCode: 500,
                },
            })

            if (options?.onSettled) options?.onSettled(opportunities)
            return Promise.resolve()
        }

        return updateOpportunities(opportunities, options)
    }, [
        updateOpportunities,
        onError,
    ])
}
