import {
    SkyNetTableTab,
} from 'app/shared-components/SkyNetTable/types/skyNetTable.types'
import {
    AdditionalFeesAllowed,
    OtherFeesAllowed,
} from 'app/shared-components/Fees'
import {
    ContractCurrency,
    FeeChargeability, ProductType,
} from 'app/types/enums'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    RateCardsTabs,
} from '../RateCards.types'
import {
    OtherLaneFeesTable,
} from './OtherLaneFees.types'

import requests from './OtherLaneFees.request'

export default function getOtherLaneFeesConfig(): SkyNetTableTab<keyof OtherLaneFeesTable> {
    return {
        name: 'Other Standard Lane Fees',
        url: RateCardsTabs.OtherLaneFees,
        customUrl: requests.filterRequest().url,
        fields: [
            {
                name: 'feeName',
                labelKey: 'LaneFeesRateCard.feeName',
                componentName: 'EnumValue',
                allowedValuesEnum: {
                    ...OtherFeesAllowed, ...AdditionalFeesAllowed,
                },
                width: '15%',
            },
            {
                name: 'feeChargability',
                labelKey: 'LaneFeesRateCard.feeChargability',
                componentName: 'EnumValue',
                allowedValuesEnum: FeeChargeability,
                minWidth: '25%',
            },
            {
                name: 'productType',
                labelKey: 'LaneFeesRateCard.productType',
                componentName: 'EnumValue',
                allowedValuesEnum: ProductType,
            },
            {
                name: 'description',
                labelKey: 'LaneFeesRateCard.description',
                minWidth: '35%',
            },
            {
                name: 'currency',
                labelKey: 'LaneFeesRateCard.currency',
                componentName: 'EnumValue',
                allowedValuesEnum: ContractCurrency,
                filterField: 'currency',
                filterType: FilterType.Array,
            },
            {
                name: 'feeValue',
                labelKey: 'LaneFeesRateCard.feeValue',
            },
        ],
    }
}
