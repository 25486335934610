import React, {
    useMemo,
} from 'react'
import {
    SkyNetRouteConfigObject,
    useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import useRatesRoutes from './hooks/useRatesRoutes'
import RateCards from './RateCards'
import {
    RatesDomains,
} from './Rates.types'

export default function Rates() {
    const Routes = useRatesRoutes()

    const routesConfig: SkyNetRouteConfigObject<RatesDomains> = useMemo(() => {
        return {
            [Routes.RateCards.route]: <RateCards />,
        }
    }, [Routes])

    return useSkyNetRoutes({
        routesConfig,
    })
}
