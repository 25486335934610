import isNil from 'lodash/isNil'

import {
    DTOCardFormField, Validate,
} from 'app/types/form.types'
import {
    LocationType, RegionSecondLevel,
} from 'app/types/enums'
import {
    LeaseTypePossibilityStatus, RateCardsData, QuotationLevel,
    ProductTypeCQuote, TemperatureRangeCQuote,
} from 'app/Apps/AccountManagement/CQuotes/CQuotes.types'

import {
    SimplifiedCQuoteCreateType,
} from '../../simplifiedCQuoteCreate.types'

const getValue = (val: boolean) => {
    if (!isNil(val)) {
        return val ? 'Available' : 'Not Available'
    }

    return null
}

const getFields = ({
    getAllowedValues,
    originQuotationLevel,
    destinationQuotationLevel,
    validateEmbargoOrigin,
    validateEmbargoDestination,
    rateCardData,
    disabled,
    classes,
    originCountryData,
    needSelectOriginState,
    destinationCountryData,
    needSelectDestinationState,
}: {
    getAllowedValues: (enumType: any) => Record<string, string>,
    originQuotationLevel: QuotationLevel,
    destinationQuotationLevel: QuotationLevel,
    validateEmbargoOrigin: Validate,
    validateEmbargoDestination: Validate,
    rateCardData: RateCardsData,
    disabled: boolean,
    classes: Record<string, string>,
    originCountryData? : {id: number, states?: {id: number}[]},
    needSelectOriginState: boolean,
    destinationCountryData: {id: number, states?: {id: number}[]},
    needSelectDestinationState: boolean,
}): DTOCardFormField<keyof SimplifiedCQuoteCreateType>[] => {
    return [
        {
            title: 'General',
            className: 'general',
            id: 'general',
            fields: [{
                id: 'general-column1',
                fields: [
                    {
                        name: 'pricingAccount',
                        componentName: 'LongText',
                        value: 'Any Non-Strategic Account',
                        disabled: true,
                        labelKey: 'AccountLane.pricingAccount',
                    },
                    {
                        name: 'temperatureRange',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(TemperatureRangeCQuote),
                        useDropdown: true,
                        required: true,
                        labelKey: 'Opportunities.temperatureRange',
                    },
                    {
                        name: 'productType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(ProductTypeCQuote),
                        useDropdown: true,
                        disabled: true,
                        labelKey: 'Fee.productType',
                    },
                    {
                        name: 'originQuotationLevel',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(QuotationLevel),
                        noSort: true,
                        required: true,
                        labelKey: 'AccountQuotes.quotationLevelOrigin',
                    },
                    {
                        name: 'destinationQuotationLevel',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(QuotationLevel),
                        noSort: true,
                        required: true,
                        labelKey: 'AccountQuotes.quotationLevelDestination',
                    },
                ],
            }],
        },
        {
            title: 'Regional Service',
            className: 'regionalService',
            id: 'regionalService',
            fields: [
                {
                    id: 'regional-column1',
                    fields: [
                        {
                            name: 'originAirport',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.AIRPORT,
                            required: true,
                            hidden: originQuotationLevel !== QuotationLevel.IATA,
                            labelKey: 'AccountQuotes.originIATA',
                        },
                        {
                            name: 'originCountry',
                            componentName: 'CountryDataSelector',
                            hidden: originQuotationLevel === QuotationLevel.REGION_SECOND_LEVEL,
                            disabled: originQuotationLevel !== QuotationLevel.COUNTRY || disabled,
                            required: true,
                            validate: validateEmbargoOrigin,
                            labelKey: 'Prebooking.originCountry',
                        },
                        {
                            name: 'originState',
                            componentName: 'StateSelector',
                            country: originCountryData?.id,
                            labelKey: 'AccountQuotes.originState',
                            hidden: originQuotationLevel !== QuotationLevel.COUNTRY
                                || !needSelectOriginState,
                            disabled: originQuotationLevel !== QuotationLevel.COUNTRY || disabled,
                            required: true,
                        },
                        {
                            name: 'originRegion',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(RegionSecondLevel),
                            disabled: originQuotationLevel !== QuotationLevel.REGION_SECOND_LEVEL
                                || disabled,
                            required: true,
                            labelKey: 'AccountQuotes.originRegionL2',
                        },
                        {
                            name: 'destinationAirport',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.AIRPORT,
                            required: true,
                            hidden: destinationQuotationLevel !== QuotationLevel.IATA,
                            labelKey: 'AccountQuotes.destinationIATA',
                        },
                        {
                            name: 'destinationCountry',
                            componentName: 'CountryDataSelector',
                            hidden: destinationQuotationLevel
                                === QuotationLevel.REGION_SECOND_LEVEL,
                            disabled: destinationQuotationLevel !== QuotationLevel.COUNTRY
                                || disabled,
                            required: true,
                            validate: validateEmbargoDestination,
                            labelKey: 'Prebooking.destinationCountry',
                        },
                        {
                            name: 'destinationState',
                            componentName: 'StateSelector',
                            country: destinationCountryData?.id,
                            labelKey: 'AccountQuotes.destinationState',
                            hidden: destinationQuotationLevel !== QuotationLevel.COUNTRY
                                || !needSelectDestinationState,
                            disabled: destinationQuotationLevel !== QuotationLevel.COUNTRY
                                || disabled,
                            required: true,
                        },
                        {
                            name: 'destinationRegion',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(RegionSecondLevel),
                            disabled: destinationQuotationLevel
                                !== QuotationLevel.REGION_SECOND_LEVEL || disabled,
                            required: true,
                            labelKey: 'AccountQuotes.destinationRegionL2',
                        },
                    ],
                },
                {
                    id: 'regional-column2',
                    fields: [
                        {
                            name: 'allowedLeaseType',
                            componentName: 'InputSingleline',
                            value: getAllowedValues(
                                LeaseTypePossibilityStatus,
                            )[rateCardData?.leaseTypePossibilityStatus],
                            disabled: true,
                            classNames: {
                                text: rateCardData?.leaseTypePossibilityStatus
                                === LeaseTypePossibilityStatus.DIRECT_LEASE_POSSIBLE
                                    ? classes.green
                                    : classes.red,
                            },
                            labelKey: 'AccountQuotes.allowedLeaseType',
                        },
                        {
                            name: 'originPositioningFtl',
                            componentName: 'InputSingleline',
                            value: getValue(rateCardData?.originPositioningFtl),
                            disabled: true,
                            classNames: {
                                text: rateCardData?.originPositioningFtl && classes.green,
                            },
                            labelKey: 'AccountQuotes.originDeliveryFTL',
                        },
                        {
                            name: 'originTruckCapacity',
                            componentName: 'InputSingleline',
                            value: rateCardData?.originTruckCapacity,
                            disabled: true,
                            labelKey: 'AccountQuotes.palletsPerTruck',
                        },
                        {
                            name: 'originPositioningLtl',
                            componentName: 'InputSingleline',
                            value: getValue(rateCardData?.originPositioningLtl),
                            disabled: true,
                            classNames: {
                                text: rateCardData?.originPositioningLtl && classes.green,
                            },
                            labelKey: 'AccountQuotes.originDeliveryLTL',
                        },
                        {
                            name: 'destinationCollectionLtl',
                            componentName: 'InputSingleline',
                            value: getValue(rateCardData?.destinationCollectionLtl),
                            disabled: true,
                            classNames: {
                                text: rateCardData?.destinationCollectionLtl && classes.green,
                            },
                            labelKey: 'AccountQuotes.destinationCollectionLTL',
                        },
                    ],
                },
            ],
        },
    ]
}

export default getFields
