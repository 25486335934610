import {
    SkyNetTableConfig, SkyNetTableField,
} from 'app/shared-components/SkyNetTable'
import {
    LocationType, RegionFirstLevel, RegionSecondLevel, RegionZeroLevel,
} from 'app/types/enums'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    AirportsAndSeaportsDomains,
} from './AirportsAndSeaports.enums'
import {
    AirportTable, SeaportsTable,
} from './AirportsAndSeaports.types'

const getFields = (
    optionalFields: SkyNetTableField<keyof AirportTable | keyof SeaportsTable
    >[],
): SkyNetTableField<keyof AirportTable | keyof SeaportsTable>[] => {
    return [
        {
            name: 'locationName',
            labelKey: 'Location.locationName',
            reduced: true,
        },
        ...optionalFields,
        {
            name: 'regionSecondLevel',
            labelKey: 'Country.regionSecondLevel',
            componentName: 'EnumValue',
            allowedValuesEnum: RegionSecondLevel,
            sorting: false,
        },
        {
            name: 'regionFirstLevel',
            labelKey: 'Country.regionFirstLevel',
            componentName: 'EnumValue',
            allowedValuesEnum: RegionFirstLevel,
        },
        {
            name: 'regionZeroLevel',
            labelKey: 'Country.regionZeroLevel',
            componentName: 'EnumValue',
            allowedValuesEnum: RegionZeroLevel,
        },
        {
            name: 'zip',
            labelKey: 'Location.zip',
        },
        {
            name: 'city',
            labelKey: 'Location.city',
        },
        {
            name: 'countryName',
            labelKey: 'Location.country',
            filterField: 'countryName',
            filterType: FilterType.Array,
        },
    ]
}

export default (): SkyNetTableConfig<keyof (AirportTable & SeaportsTable)> => {
    return {
        name: 'AirportsAndSeaports',
        tabs: [
            {
                name: 'Airports',
                url: AirportsAndSeaportsDomains.Airports,
                requestUrl: 'all',
                includeFilters: {
                    locationType: [LocationType.AIRPORT],
                },
                fields: getFields([{
                    name: 'iataCode',
                    labelKey: 'Location.iataCode',
                    reduced: true,
                }]),
                defaultTab: true,
            },
            {
                name: 'Seaports',
                url: AirportsAndSeaportsDomains.Seaports,
                requestUrl: 'all',
                includeFilters: {
                    locationType: [LocationType.SEAPORT],
                },
                fields: getFields([{
                    name: 'unloCode',
                    labelKey: 'Location.unloCode',
                    reduced: true,
                }]),
            },
        ],
    }
}
