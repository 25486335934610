/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
    useRef,
    useEffect,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import omit from 'lodash/omit'

import InputSingleline from 'app/shared-components/InputSingleline'
import useOutsideClick from 'app/hooks/useOutsideClick'

import InputMultiline from '../InputMultiline'

import useStyles from './Typeahead.styles'

const propTypes = {
    searchPhrase: PropTypes.string.isRequired,
    name: PropTypes.string,
    labelData: PropTypes.shape({
        value: PropTypes.string,
        iconPath: PropTypes.string,
    }),
    onChange: PropTypes.func,
    title: PropTypes.string,
    setSearchPhrase: PropTypes.func.isRequired,
    options: (props, propName, componentName) => {
        const {
            options,
            valueField,
        } = props

        if (!Array.isArray(options)) {
            return new Error(`Invalid prop ${propName} in Component ${componentName} need to be an array`)
        }

        const notValidElementIndex = options.findIndex((option) => {
            return !(option.label && option[valueField])
        })

        if (notValidElementIndex !== -1) {
            return new Error(`Invalid element with index ${notValidElementIndex} in prop ${propName} in Component ${componentName}.`)
        }

        return null
    },
    optionsVisible: PropTypes.bool.isRequired,
    labelField: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]).isRequired,
    valueField: PropTypes.string.isRequired,
    openOptions: PropTypes.func.isRequired,
    closeOptions: PropTypes.func.isRequired,
    variant: PropTypes.oneOf([
        'standard',
        'outlined',
        'filled',
    ]),
    classNames: PropTypes.shape({
        options: PropTypes.string,
    }),
    className: PropTypes.string,
    isRequired: PropTypes.bool,
    multiline: PropTypes.bool,
    getLabelValue: PropTypes.func,
    errors: PropTypes.arrayOf(PropTypes.string),
    validated: PropTypes.bool,
    noneOption: PropTypes.bool,
}

const defaultProps = {
    labelData: {},
    name: '',
    onChange: noop,
    options: [],
    errors: undefined,
    title: '',
    variant: 'outlined',
    classNames: {},
    className: '',
    isRequired: false,
    validated: undefined,
    multiline: false,
    getLabelValue: noop,
    noneOption: true,
}

const optionDirection = {
    leftToRight: 'leftToRight',
    rightToLeft: 'rightToLeft',
}
const getElementInfos = (el) => {
    return {
        position: el.getBoundingClientRect().left,
        size: el.offsetWidth,
    }
}

const removeFields = (obj) => {
    return omit(obj, [
        'value',
        'Comp',
        'valueField',
        'labelField',
        'loadLabel',
        'loadOptions',
        'options',
        'loadAirports',
        'className',
    ])
}

const screenSize = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

const Typeahead = (props) => {
    const {
        onChange,
        options,
        openOptions,
        optionsVisible,
        setSearchPhrase,
        closeOptions,
        searchPhrase,
        valueField,
        variant,
        labelData,
        name,
        classNames,
        className,
        isRequired,
        multiline,
        getLabelValue,
        labelField,
        noneOption,
    } = props

    const {
        value,
        iconPath,
    } = labelData

    const wrapperRef = useRef()
    const {
        classes,
        cx,
    } = useStyles()

    useOutsideClick(wrapperRef, closeOptions)

    const onOptionClick = useCallback((event) => {
        const target = event.currentTarget || event.target
        const {
            dataset: {
                value: newValue,
            },
        } = target

        const optionValue = options.find(({
            id,
        }) => { return String(id) === String(newValue) }) || (newValue ? {
            id: newValue,
        } : undefined)

        onChange(optionValue, {
            target: {
                name,
                value: optionValue,
            },
        })
        closeOptions()
    }, [
        closeOptions,
        name,
        onChange,
        options,
    ])

    const onEnterPress = useCallback((event) => {
        const {
            key,
        } = event

        if (optionsVisible && key === 'Enter') {
            onChange(options[0], {
                target: {
                    name,
                    value: options[0],
                },
            })
            closeOptions()
        }
    }, [
        closeOptions,
        name,
        onChange,
        options,
        optionsVisible,
    ])

    const InputComponent = multiline ? InputMultiline : InputSingleline

    const [
        optionsPosition,
        setOptionsPosition,
    ] = useState({
        direction: undefined,
        parentSize: 200,
    })

    useEffect(() => {
        if (!optionsPosition.direction) {
            const wrapper = getElementInfos(wrapperRef.current)

            if (wrapper.position > (screenSize / 2)) {
                setOptionsPosition({
                    direction: optionDirection.rightToLeft,
                    parentSize: wrapper.size || 200,
                })
            } else {
                setOptionsPosition({
                    direction: optionDirection.leftToRight,
                    parentSize: wrapper.size || 200,
                })
            }
        }
    }, [optionsPosition])

    return (
        <div
            data-testid={`typeahead-${name}`}
            className={cx(classes.root, className)}
            ref={wrapperRef}
        >
            <InputComponent
                {...removeFields(props)}
                required={isRequired}
                onChange={setSearchPhrase}
                classNames={classNames}
                value={optionsVisible ? searchPhrase : value}
                onKeyPress={onEnterPress}
                onFocus={openOptions}
                variant={variant}
                iconPath={iconPath}
                autoComplete="off"
                name={name.replace('Id', '')} // needed to remove 'Id',strangely lastpass flagged it as user/pass field
            />
            <div
                className={
                    cx(
                        classes.optionsWrapper,
                        optionsVisible && 'active',
                    )
                }
                data-testid="typeahead-options-wrapper"
            >
                {options.length > 0 && (
                    <div
                        className={cx(
                            classes.options,
                            classNames.options,
                        )}
                        style={{
                            right: optionsPosition.direction === optionDirection.rightToLeft ? `-${optionsPosition.parentSize}px` : 'auto',
                            minWidth: `${Math.round(optionsPosition.parentSize * 0.9)}px`,
                        }}
                    >
                        {noneOption ? (
                            <button
                                type="button"
                                key="option-none"
                                onClick={onOptionClick}
                                className={cx(classes.option, classes.optionNone)}
                                data-value={undefined}
                                title="None"
                            >
                                None
                            </button>
                        ) : null}
                        {options.map((option) => {
                            return (
                                <button
                                    type="button"
                                    key={`option-${option[valueField]}`}
                                    onClick={onOptionClick}
                                    className={classes.option}
                                    data-value={option[valueField]}
                                >
                                    {option.label || getLabelValue(option, labelField)}
                                </button>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

Typeahead.propTypes = propTypes
Typeahead.defaultProps = defaultProps

export default Typeahead
