import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'

import useHasPermission from 'app/hooks/useHasPermission'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import useLabel from 'app/hooks/useLabel'
import SharedContextProvider from 'app/shared-components/SharedContext'
import UploadFile from 'app/shared-components/UploadFile'

import useAQuoteDocuments from '../hooks/useAQuoteDocuments'
import quotesPdfRequests from '../AQuotes.requests'
import useAddQuotePdf from '../hooks/useAddQuotePdf'

import columns from './AQuotePdf.columns'
import CreateQuotePDF from './CreateQuotePDF'
import useStyles from './QuotePDF.styles'

type Props = {
    id: number,
}
const QuotePDF = ({
    id,
}: Props) => {
    const quotePdfCreate = useHasPermission(['account_quote_pdf_create'])
    const quotePdfDelete = useHasPermission(['account_quote_pdf_delete'])
    const quotePdfUpload = useHasPermission(['account_quote_pdf_upload'])
    const quotePdfUpdate = useHasPermission(['contract_basis_update'])
    const {
        classes,
    } = useStyles()
    const l = useLabel()
    const [
        showCreateQuote,
        setShowCreateQuote,
    ] = useState(false)

    const {
        onParamsChange,
        load,
        refresh,
    } = useAQuoteDocuments(id)

    const fields = useMemo(() => {
        return columns({
            l,
            quotePdfDelete,
            quotePdfUpdate,
        })
    }, [
        l,
        quotePdfDelete,
        quotePdfUpdate,
    ])

    const addClick = useCallback(() => {
        setShowCreateQuote(true)
    }, [])

    const showRefreshDocuments = useCallback(() => {
        setShowCreateQuote(false)
        refresh()
    }, [refresh])

    const {
        mutate,
    } = useAddQuotePdf(refresh)

    const onSuccessUpload = (idDocument: number) => {
        mutate({
            quoteId: id,
            data: {
                id: idDocument,
            },
        })
    }

    return (
        <>
            {showCreateQuote && (
                <CreateQuotePDF
                    quoteId={id}
                    afterCreate={showRefreshDocuments}
                />
            )}
            {!showCreateQuote && (
                <Card
                    fullHeight
                    contentClass={classes.cardContent}
                >
                    <div className={classes.header}>
                        <Typography variant="h3">
                            Documents
                        </Typography>
                        <div className={classes.iconWrapper}>
                            { quotePdfCreate && (
                                <>
                                    {quotePdfUpload
                                        && (<UploadFile onSuccessUpload={onSuccessUpload} />)}
                                    <AddIcon
                                        className={classes.icon}
                                        onMouseDown={addClick}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div
                        className={classes.gridCardWrapper}
                    >
                        <SharedContextProvider
                            value={{
                                quoteId: id,
                            }}
                        >
                            <RichTable
                                configName={quotesPdfRequests.domainName}
                                onParamsChange={onParamsChange}
                                name={quotesPdfRequests.domainName}
                                load={load}
                                columns={fields}
                                uniqField="id"
                                className={classes.tableContent}
                                classNames={{
                                    contentWrapper: classes.tableContentWrapper,
                                }}
                            />
                        </SharedContextProvider>
                    </div>
                </Card>
            )}
        </>
    )
}

export default QuotePDF
