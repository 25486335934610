import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    RegionSecondLevel,
} from 'app/types/enums'
import {
    State,
} from '../Countries.types'

const columns = ({
    l,
}: {
    l: (l: string) => string,
}): RichTableColumns<keyof State>[] => {
    return [
        {
            id: 'stateName',
            mapCellProps: 'stateName',
            headerProps: {
                children: l('Location.state'),
            },
        },
        {
            id: 'stateIsoAlpha2Code',
            headerProps: {
                children: l('Country.isoCode'),
            },
            mapCellProps: 'stateIsoAlpha2Code',
        },
        {
            id: 'regionSecondLevel',
            headerProps: {
                children: l('Country.regionSecondLevel'),
            },
            mapCellProps: 'regionSecondLevel',
            componentName: 'EnumValue',
            allowedValuesEnum: RegionSecondLevel,
        },
    ]
}

export default columns
