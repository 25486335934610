import React from 'react'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import ChangeHistory from 'app/shared-components/ChangeHistory'

import config from '../DamageTypes.requests'

const DamageTypeChangeHistory = ({
    id,
}: {id: number}) => {
    return (
        <ChangeHistory
            id={id}
            key={RequestKeys.getDamageModifications}
            config={config.modifications}
            configName="DamageTypeChangeHistoryTable"
            name="DamageTypeChangeHistory"
            labelPrefixForChangedColumn={['DamageTypes']}
        />
    )
}

export default DamageTypeChangeHistory
