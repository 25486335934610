import React, {
    useCallback, useEffect,
    useMemo, useState,
} from 'react'
import isEqual from 'lodash/isEqual'
import Typography from '@mui/material/Typography'
import Card from 'app/shared-components/Card'
import getEnumLabel from 'app/utils/getEnumLabel'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import Button from 'app/shared-components/Button'
import {
    ForecastsByProductType,
} from '../ScenarioEditor.types'
import useStyles from '../ScenarioEditorForm/ScenarioEditorForm.styles'
import ScenarioByProductTypeSpreadsheet from '../ScenarioByProductTypeSpreadsheet'
import useScenarioEditorContext from '../ScenarioEditorContext'
import {
    parseForecastsByProduct,
    ScenarioByProductState, ScenarioByProductTypeRow,
} from './ForecastsByProduct.utils'

export default function ForecastsByProduct({
    data,
    applyParameters,
}: Readonly<{
    data: ForecastsByProductType[],
    applyParameters: (value: ScenarioByProductState) => void
}>) {
    const {
        contextValue,
        setContextValue,
    } = useScenarioEditorContext()

    const initData = useMemo(() => {
        return parseForecastsByProduct(data)
    }, [data])

    const [
        value,
        setValue,
    ] = useState<ScenarioByProductState>(initData)

    const {
        classes,
    } = useStyles()

    const onChange = useCallback((productType) => {
        return (updated: ScenarioByProductTypeRow[]) => {
            setValue({
                ...value,
                [productType]: {
                    ...value[productType],
                    data: updated,
                },
            })
            if (!contextValue?.modified) {
                setContextValue({
                    modified: true,
                })
            }
        }
    }, [
        contextValue?.modified,
        setContextValue,
        value,
    ])

    useEffect(() => {
        if (!contextValue.modified) {
            setValue(initData)
        }
    }, [
        contextValue.modified,
        initData,
    ])

    return (
        <>
            <Card>
                {Object.entries(value).map(([
                    productType,
                    {
                        columns, data: dataByProductType,
                    },
                ]) => {
                    return (
                        <div key={`scenario-${productType}`}>
                            <Typography
                                variant="h3"
                                className={classes.subtitle}
                            >
                                {`Scenario by Product Type ${getEnumLabel(productType)}`}
                            </Typography>
                            <ScenarioByProductTypeSpreadsheet
                                key={`${productType}-spreadsheet`}
                                columns={columns}
                                data={dataByProductType}
                                onChange={onChange(productType)}
                            />
                        </div>
                    )
                })}
            </Card>
            <ButtonContainer>
                <Button
                    onClick={() => {
                        applyParameters(value)
                    }}
                    key="apply-parameters"
                    label="Apply Parameters"
                    disabled={isEqual(initData, value)}
                />
            </ButtonContainer>
        </>
    )
}
