import {
    Validate,
} from 'app/types/form.types'

export const checkNegativeNumberAndZero: Validate<number> = (value: number, errorMsg) => {
    return value <= 0 ? [errorMsg || 'Only positive numbers are allowed'] : undefined
}

export const checkNegativeNumber: Validate<number> = (value: number, errorMsg) => {
    return value < 0 ? [errorMsg || 'Only positive numbers and zero are allowed'] : undefined
}

export const isNegativeNumber = (fieldName: string, errorMsg?: string, options?: {
    allowZero?: boolean
}) => {
    return (value: Record<string, any>) => {
        const validate = options?.allowZero ? checkNegativeNumber : checkNegativeNumberAndZero

        return validate(value?.[fieldName], errorMsg)
    }
}
