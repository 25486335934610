import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import request from '../AQuotes.requests'

const useAddQuotePdf = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'Add PDF',
    })

    return useMutation({
        mutationFn: ({
            quoteId,
            data,
        }: {
            quoteId: number,
            data: {
                id: number,
            }
        }) => {
            return request.addQuotePdf.mutationFn({
                quoteId,
                data,
                token,
            })
        },
        onSuccess,
        onError,
    })
}

export default useAddQuotePdf
