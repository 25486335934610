import {
    RequestConfig,
} from 'app/types/request.types'

type ContainerTypeRequest = {
    name: string,
    get: ({
        id: number,
    }) => RequestConfig,
    update: () => RequestConfig,
    selector: {url: string}
}

const containerTypeRequest: ContainerTypeRequest = {
    name: 'ContainerType',
    get: ({
        id,
    }) => {
        return {
            url: `containertype/${id}`,
            method: 'GET',
        }
    },
    update: () => {
        return {
            url: 'containertype',
            method: 'PUT',
        }
    },
    selector: {
        url: 'containertype/filter-request/selector',
    },
}

export default containerTypeRequest
