import React, {
    useState, useMemo, useCallback,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'

import SkyNetStepper from 'app/shared-components/SkyNetStepper'
import HistoryBlockPrompt from 'app/shared-components/HistoryBlockPrompt'
import useBeforeUnloadDialog from 'app/hooks/useBeforeUnloadDialog'

import {
    AQuote,
} from '../AQuotes.types'
import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'

import {
    AQuoteInformationContext,
} from './AQuoteInformationContext'
import AQuoteInformationCreate from './AQuoteInformationCreate'
import QuoteOpportunities from './QuoteOpportunities'
import QuoteLanes from './QuoteLanes'
import QuotationOptions from './QuotationOptions'
import useAQuoteCreate from './hooks/useAQuoteCreate'

const AQuoteCreate = () => {
    const {
        state,
    } = useLocation()
    const [
        value,
        setValue,
    ] = useState<AQuote>(state || {} as AQuote)
    const {
        AQuotes: AQuotesRoutes,
    } = useAccountManagementRoutes()

    const [
        isEdited,
        setIsEdited,
    ] = useState<boolean>()

    useBeforeUnloadDialog(isEdited)

    const [
        activeStep,
        setActiveStep,
    ] = useState<number>(0)

    const onSuccess = useCallback(({
        id,
    }) => {
        AQuotesRoutes.Overview.navigate({
            params: {
                key: id,
            },
        })
    }, [AQuotesRoutes.Overview])

    const createAQuote = useAQuoteCreate({
        onSuccess,
        setIsEdited,
    })

    const stepperSteps = useMemo(() => {
        return [
            {
                key: '1',
                label: 'Fill Quote Information',
                expanded: true,
                content: <AQuoteInformationCreate
                    data={value}
                    disabled={activeStep !== 0}
                />,
            },
            {
                key: '2',
                label: 'Select Opportunities & Required Products',
                expanded: (step) => {
                    return step > 0
                },
                content: <QuoteOpportunities disabled={activeStep !== 1} />,
            },
            {
                key: '3',
                label: 'Select Lanes',
                expanded: (step) => {
                    return step > 1
                },
                content: <QuoteLanes disabled={activeStep !== 2} />,
            },
            {
                key: '4',
                label: 'Specify Quotation Options',
                expanded: (step) => {
                    return step > 2
                },
                content: <QuotationOptions
                    data={value}
                    actionQuote={createAQuote}
                />,
            },
        ]
    }, [
        value,
        activeStep,
        createAQuote,
    ])

    const aQuoteInformationContext = useMemo(() => {
        return {
            activeStep,
            setActiveStep,
            value,
            setValue,
            setIsEdited,
        }
    }, [
        activeStep,
        setActiveStep,
        value,
        setValue,
    ])

    return (
        <AQuoteInformationContext.Provider value={aQuoteInformationContext}>
            <SkyNetStepper
                activeStep={activeStep}
                steps={stepperSteps}
            />
            <HistoryBlockPrompt
                when={isEdited}
                positiveLabel="Discard"
                isPositiveAlert
                promptText="You are about to leave this page, your progress will be lost. Would you like to discard your changes anyway?"
            />
        </AQuoteInformationContext.Provider>
    )
}

export default AQuoteCreate
