import {
    SkyNetTableTab,
} from 'app/shared-components/SkyNetTable/types/skyNetTable.types'
import {
    BaseLeaseCategory, ContractCurrency, ProductType,
} from 'app/types/enums'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    RateCardsTabs,
} from '../RateCards.types'

import {
    BaseLeaseTable,
} from './BaseLease.types'
import requests from './BaseLease.request'

export default function getBaseLeaseConfig(): SkyNetTableTab<keyof BaseLeaseTable> {
    return {
        name: 'Standard Base Lease',
        url: RateCardsTabs.BaseLease,
        customUrl: requests.filterRequest().url,
        defaultTab: true,
        fields: [
            {
                name: 'pricingAccountCompanyName',
                labelKey: 'LaneFeesRateCard.pricingAccountName',
                filterField: 'pricingAccountCompanyName',
                filterType: FilterType.Array,
            },
            {
                name: 'baseLeaseCategory',
                componentName: 'EnumValue',
                allowedValuesEnum: BaseLeaseCategory,
                labelKey: 'BaseLease.baseLeaseCategory',
            },
            {
                name: 'productType',
                componentName: 'EnumValue',
                allowedValuesEnum: ProductType,
                labelKey: 'BaseLease.productType',
            },
            {
                name: 'currency',
                componentName: 'EnumValue',
                allowedValuesEnum: ContractCurrency,
                labelKey: 'BaseLease.currency',
            },
            {
                name: 'baseFeeValue',
                labelKey: 'BaseLease.base',
            },
            {
                name: 'additionalPeriodOne',
                labelKey: 'BaseLease.additionalPeriod1',
            },
            {
                name: 'additionalPeriodTwo',
                labelKey: 'BaseLease.additionalPeriod2',
            },
            {
                name: 'additionalPeriodThree',
                labelKey: 'BaseLease.additionalPeriod3',
            },
        ],
    }
}
