import React, {
    useCallback,
    useMemo,
} from 'react'
import AwardRejectLanes from 'app/Apps/AccountManagement/components/AwardRejectLanes'
import AwardRejectLaneFees from 'app/Apps/AccountManagement/components/AwardRejectLaneFees'

export default function AQuoteAwardRejectLanes({
    id,
}: Readonly<{ id: number }>) {
    const filters = useMemo(() => {
        return {
            aQuoteId: id,
        }
    }, [id])

    const renderLaneFees = useCallback(() => {
        return (
            <AwardRejectLaneFees
                filters={filters}
            />
        )
    }, [filters])

    return (
        <AwardRejectLanes
            filters={filters}
            renderLaneFees={renderLaneFees}
        />
    )
}
