import React, {
    useCallback, useMemo,
} from 'react'

import useRequestTable from 'app/hooks/useRequestTable'
import useHasPermission from 'app/hooks/useHasPermission'
import useLabel from 'app/hooks/useLabel'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import RichTableWithAddIcon from 'app/shared-components/RichTableWithAddIcon'

import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'
import request from '../../AQuotes/AQuotes.requests'
import {
    AQuoteTable,
} from '../../AQuotes/AQuotes.types'

import getColumns from './QuotesByAccount.columns'

export default function QuotesByAccount({
    id,
}: Readonly<{id: number}>) {
    const l = useLabel()
    const {
        AQuotes: AQuotesRoutes,
    } = useAccountManagementRoutes()

    const accountCreate = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])

    const {
        onParamsChange,
        loadData,
    } = useRequestTable<{data: AQuoteTable[]}>({
        key: RequestKeys.getAQuotes,
        params: {},
        defaultValue: {
            filter: {
                accountId: [id],
            },
        },
        config: request.filterRequest,
        keepPreviousData: true,
        enabled: Boolean(id),
    })

    const openCreateForm = useCallback(() => {
        AQuotesRoutes.Create.navigate({
            options: {
                state: {
                    account: {
                        id,
                    },
                },
            },
        })
    }, [
        AQuotesRoutes.Create,
        id,
    ])

    const onRowClick = useCallback(({
        id: quoteId,
    }: AQuoteTable) => {
        AQuotesRoutes.Overview.navigate({
            params: {
                key: quoteId,
            },
        })
    }, [AQuotesRoutes.Overview])

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    return (
        <RichTableWithAddIcon
            title="Quotes"
            onOpenForm={openCreateForm}
            name={request.domainName}
            configName="QuotesByAccountTable"
            createEnabled={accountCreate}
            loadData={loadData}
            onParamsChange={onParamsChange}
            columns={columns}
            onRowClick={onRowClick}
        />
    )
}
