import React, {
    useCallback,
} from 'react'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    getEditedItems,
    useGetRequestSpreadSheetAdapter,
} from 'app/shared-components/SkyNetSpreadSheet'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import useFilter from 'app/hooks/useFilter'
import {
    useSkyNetPagination, SkyNetPaginationContext,
} from 'app/shared-components/SkyNetPagination'
import useBulkEdit from 'app/hooks/useBulkEdit'
import requests from '../BaseLease.request'
import {
    BaseLeaseEditable,
    BaseLeaseTable,
} from '../BaseLease.types'
import BaseLeaseSpreadsheet from '../BaseLeaseSpreadsheet'
import {
    prepareBaseLeaseToBulkEdit,
} from '../BaseLease.utils'

export default function BaseLeaseBulkEdit({
    onClose,
}: {
    onClose: () => void
}) {
    const filterOptions = useFilter<BaseLeaseTable[]>()
    const paginationOptions = useSkyNetPagination(25)
    const {
        isError,
        loaded,
        error,
    } = useGetRequestSpreadSheetAdapter<BaseLeaseTable>({
        config: requests.filterRequest,
        requestKey: RequestKeys.getBaseLeaseRateCards,
        enabled: true,
        filterOptions,
        paginationOptions,
    })

    const bulkEdit = useBulkEdit<BaseLeaseEditable>({
        requestConfig: requests.bulkEdit,
        successMessage: 'Base Lease Rate Cards were updated',
        id: 'BaseLeaseBulkUpdate',
    })

    const onSave = useCallback((
        items: BaseLeaseTable[],
        options?: AsyncMutationOptions,
    ) => {
        const editedItems = getEditedItems({
            allItems: items,
            initData: filterOptions?.filteredData,
        })

        bulkEdit(prepareBaseLeaseToBulkEdit(editedItems), {
            ...options,
            onSuccess: options?.onSuccess || onClose,
        })
    }, [
        bulkEdit,
        filterOptions?.filteredData,
        onClose,
    ])

    return (
        <StatusHandler
            isError={isError}
            isFetching={!loaded}
            error={error}
            isSuccess={loaded}
        >
            <SkyNetPaginationContext.Provider value={paginationOptions}>
                <BaseLeaseSpreadsheet
                    filterOptions={filterOptions}
                    onCancel={onClose}
                    onSave={onSave}
                />
            </SkyNetPaginationContext.Provider>
        </StatusHandler>
    )
}
