import React, {
    useMemo,
} from 'react'
import Typography from '@mui/material/Typography'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import requests from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.requests'
import {
    AccountLanesTable,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import useLabel from 'app/hooks/useLabel'
import useTableStyles from 'app/tss/table.styles'
import useLinkStyles from 'app/tss/link.styles'
import {
    TableResponse,
} from 'app/types/request.types'

import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'

import getColumns from './CQuoteRequestedLanes.columns'

export default function CQuoteRequestedLanes({
    requestedLanes,
}: Readonly<{
    requestedLanes: {id:number}[]
}>) {
    const {
        classes,
    } = useTableStyles()
    const {
        classes: linkClasses,
    } = useLinkStyles()
    const {
        Lanes: LanesRoutes,
    } = useAccountManagementRoutes()
    const l = useLabel()
    const defaultFilters = useMemo(() => {
        return {
            id: requestedLanes.map(({
                id,
            }) => { return id }),
        }
    }, [requestedLanes])

    const {
        loadData,
    } = useRequestTable<TableResponse<AccountLanesTable>>({
        config: requests.filterRequest,
        params: {},
        key: RequestKeys.getAccountLanes,
        keepPreviousData: true,
        defaultValue: {
            filter: defaultFilters,
        },
    })

    const columns = useMemo(() => {
        return getColumns({
            l,
            laneStyle: linkClasses.tableLink,
            getLaneLink: (id) => {
                return id ? generateSkyNetLink({
                    domainPath: LanesRoutes.Overview.path,
                    params: {
                        key: id,
                    },
                }) : null
            },
        })
    }, [
        LanesRoutes,
        l,
        linkClasses.tableLink,
    ])

    return (
        <Card
            className={classes.cardWrapper}
        >
            <div className={classes.headerWrapper}>
                <Typography variant="h3">
                    Requested Lanes
                </Typography>
            </div>
            <RichTable
                configName="RequestedLanesTable"
                name="Requested Lanes"
                load={loadData}
                columns={columns}
                uniqField="id"
                filter={defaultFilters}
                className={classes.tableContent}
                classNames={{
                    contentWrapper: classes.tableContentWrapper,
                }}
            />
        </Card>
    )
}
