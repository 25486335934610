import React from 'react'
import {
    createBrowserRouter, Navigate,
} from 'react-router-dom'

import Root from '../Root'
import Apps from '../Apps'

import {
    APPS, CommonRoutes,
} from './SkyNetRoutes.types'

const skyNetRoutes = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        children: [
            {
                path: `${APPS}/${CommonRoutes.ASTERISK}`,
                element: <Apps />,
            },
            {
                path: CommonRoutes.SLASH,
                element: <Navigate
                    to={`/${APPS}`}
                    replace
                />,
            },
        ],
    },
    {
        path: CommonRoutes.SLASH,
        element: <Navigate
            to={`/${APPS}`}
            replace
        />,
    },
])

export default skyNetRoutes
