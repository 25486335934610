import React, {
    useCallback,
    useMemo,
} from 'react'
import noop from 'lodash/noop'
import sortBy from 'lodash/sortBy'
import {
    v1 as uuidv1,
} from 'uuid'

import Loading from 'app/shared-components/Loading'
import FxRate from 'app/shared-components/FxRate/FxRate.types'
import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    Fee,
} from 'app/Apps/Pricing/Pricing.types'
import {
    ProductTypeContainerTempRange,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    ContractCurrency,
} from 'app/types/enums'
import {
    useGetFxRate,
} from '../FxRate'

import config from './Fee.request'
import Fees from './Fees'
import FeesSpreadSheet from './FeesSpreadSheet'

type FeeDynamicallyChangeable = {
    id: string,
    feeChargeability: string,
    productType: string,
    feeName: string,
}

type Props = {
    name?: string,
    value?: Fee[],
    onChange?: (newvalue: any, target?: Record<string, any>) => void,
    disabled?: boolean,
    fxRate?: FxRate,
    currency?: ContractCurrency,
    tempRange?: string,
    feeValueLabel?: string,
    showSkyNetSpreadSheet?: boolean,
}

const defaultProps : Partial<Props> = {
    name: 'fees',
    value: [],
    onChange: noop,
    disabled: false,
    fxRate: undefined,
    currency: undefined,
    tempRange: undefined,
    feeValueLabel: 'EUR',
    showSkyNetSpreadSheet: false,
}

const FeesContainer = ({
    name,
    value,
    onChange,
    disabled,
    fxRate,
    currency,
    tempRange,
    feeValueLabel,
    showSkyNetSpreadSheet,
}: Props) => {
    const {
        data: fxRateData,
    } = useGetFxRate(fxRate?.id)
    const {
        isDataReady,
        data: productTypeData,
    } = useRequest<ProductTypeContainerTempRange[]>({
        key: RequestKeys.getContractBasisProductType,
        requestFunc: RequestFn.getEntity(config.ProductType.requestFn),
    })

    const sorterValues: Fee[] = useMemo(() => {
        return sortBy(value, 'feeName')
    }, [value])

    const handleChange = useCallback((change: Fee) => {
        const updatedFees = value.map((feeReturned) => {
            if (feeReturned.id === change.id) {
                return {
                    ...change,
                }
            }
            return feeReturned
        })

        onChange(
            updatedFees,
            {
                target: {
                    name,
                    value: updatedFees,
                },
            },
        )
    }, [
        value,
        name,
        onChange,
    ])

    const handleAddEntry = useCallback(() => {
        const newEntry = [
            ...value,
            {
                id: `temp-${uuidv1()}`,
                feeChargeability: null,
                productType: null,
                feeName: null,
            } as FeeDynamicallyChangeable,
        ]

        onChange(
            newEntry,
            {
                target: {
                    name,
                    value: newEntry,
                },
            },
        )
    }, [
        name,
        onChange,
        value,
    ])

    const handleDeleteEntry = useCallback((feeId) => {
        onChange(value.filter(({
            id,
        }) => {
            return id !== feeId
        }))
    }, [
        value,
        onChange,
    ])

    if (!isDataReady) {
        return (<Loading />)
    }

    return showSkyNetSpreadSheet ? (
        <FeesSpreadSheet
            value={sorterValues}
            onChange={onChange}
            productTypes={productTypeData}
            disabled={disabled}
            fxRate={fxRateData || fxRate}
            currency={currency}
            feeName={name}
            tempRange={tempRange}
        />
    ) : (
        <Fees
            value={sorterValues}
            onChange={handleChange}
            addEntry={handleAddEntry}
            deleteEntry={handleDeleteEntry}
            productTypes={productTypeData}
            disabled={disabled}
            fxRate={fxRateData || fxRate}
            currency={currency}
            name={name}
            tempRange={tempRange}
            feeValueLabel={feeValueLabel}
        />
    )
}

FeesContainer.defaultProps = defaultProps

export default FeesContainer
