import {
    useQueryClient,
} from '@tanstack/react-query'
import {
    useCallback,
} from 'react'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import config from '../FxRate.request'
import FxRate from '../FxRate.types'

export default (
    id: number | string,
) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getFxRateById],
        })
    }, [queryClient])

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<FxRate>({
        key: RequestKeys.getFxRateById,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(config.FxRate.requestFn),
        enabled: Boolean(id),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}
