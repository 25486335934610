import React, {
    useState,
    useCallback,
    useMemo,
    useEffect,
} from 'react'
import PropTypes from 'prop-types'
import {
    TextField,
} from '@mui/material'
import moment from 'moment'
import noop from 'lodash/noop'

import {
    dateToStrTimezone,
    getTimeZone,
    dateTimeZonedMask,
    dateToStr,
} from 'app/utils/date'
import {
    useAppSelector,
    useAppDispatch,
} from 'app/store/hooks'
import {
    toggleLocalTime,
    selectIsLocalTime,
} from 'app/store/Settings'

import DateTimeZonedSelect from './DateTimeZonedSelect'

const propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    isDisableWeekends: PropTypes.bool,
    defaultTimeZone: PropTypes.string,
    title: PropTypes.string,
}

const defaultProps = {
    name: '',
    title: undefined,
    value: null,
    onChange: noop,
    disabled: false,
    required: false,
    isDisableWeekends: false,
    defaultTimeZone: undefined,
}

const DateTimeZonedSelectContainer = (props) => {
    const {
        name,
        value,
        onChange,
        disabled,
        defaultTimeZone,
    } = props

    const isLocalTime = useAppSelector(selectIsLocalTime)
    const dispatch = useAppDispatch()

    const timeZone = useMemo(() => {
        if (value) {
            return getTimeZone(value)
        }

        return defaultTimeZone || moment().format('ZZ')
    }, [
        defaultTimeZone,
        value,
    ])

    const [
        derivedTimeZone,
        setDerivedTimeZone,
    ] = useState(timeZone)

    const [
        state,
        setState,
    ] = useState({
        open: false,
        newZone: timeZone,
        newDatetime: value,
    })

    const handleChangeZone = useCallback((newValue) => {
        setState({
            ...state,
            newZone: newValue,
        })
    }, [state])

    useEffect(() => {
        if (timeZone === derivedTimeZone) {
            return
        }

        setDerivedTimeZone(timeZone)
        handleChangeZone(timeZone)
    }, [
        derivedTimeZone,
        handleChangeZone,
        timeZone,
    ])

    const handleClose = useCallback(() => {
        setState({
            ...state,
            open: false,
        })
    }, [state])

    const showDate = useCallback((datetime) => {
        if (!datetime) {
            return ''
        }

        return isLocalTime
            ? dateToStr(
                moment(datetime, dateTimeZonedMask)
                    .utcOffset(moment().format('ZZ')),
            )
            : dateToStrTimezone(datetime, state.newZone)
    }, [
        isLocalTime,
        state.newZone,
    ])

    const setOpenDialog = useCallback(() => {
        if (!disabled) {
            setState((prev) => {
                return {
                    ...prev,
                    open: true,
                    newDatetime: prev.newDatetime || dateToStr(Date.now(), dateTimeZonedMask),
                }
            })
        }
    }, [disabled])

    const handleChange = useCallback((datetime) => {
        onChange(datetime, {
            target: {
                name,
                value: datetime,
            },
        })
    }, [
        name,
        onChange,
    ])

    const handleSetLocalTime = useCallback(() => {
        dispatch(toggleLocalTime())
    }, [dispatch])

    return (
        <DateTimeZonedSelect
            {...props} // eslint-disable-line react/jsx-props-no-spreading
            renderInput={(params) => {
                return <TextField {...params} /> // eslint-disable-line react/jsx-props-no-spreading
            }}
            value={value}
            state={state}
            setState={setState}
            handleChangeZone={handleChangeZone}
            handleSetLocalTime={handleSetLocalTime}
            isLocalTime={isLocalTime}
            showDate={showDate}
            setOpenDialog={setOpenDialog}
            handleChange={handleChange}
            handleClose={handleClose}
        />
    )
}

DateTimeZonedSelectContainer.propTypes = propTypes
DateTimeZonedSelectContainer.defaultProps = defaultProps

export default DateTimeZonedSelectContainer
