import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    ModificationReason,
    ModificationRequiredContainersFields,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    ProductType,
} from 'app/types/enums'

const columns: RichTableColumns<ModificationRequiredContainersFields>[] = [
    {
        id: 'modificationRequestedOn',
        headerProps: {
            children: 'Requested On',
        },
        mapCellProps: 'modificationRequestedOn',
        componentName: 'DateTimeCell',
    },
    {
        id: 'modificationReason',
        headerProps: {
            children: 'Reason',
        },
        mapCellProps: 'modificationReason',
        componentName: 'EnumValue',
        allowedValuesEnum: ModificationReason,
    },
    {
        id: 'modifiedBy',
        headerProps: {
            children: 'Modified By',
        },
        mapCellProps: 'modifiedBy',
    },
    {
        id: 'previousRequiredContainers',
        headerProps: {
            children: 'Previous Value',
        },
        mapCellProps: 'previousRequiredContainers',
        componentName: 'RequiredContainersList',
        allowedValuesEnum: ProductType,
    },
    {
        id: 'requiredContainers',
        headerProps: {
            children: 'New Value',
        },
        mapCellProps: 'requiredContainers',
        componentName: 'RequiredContainersList',
        allowedValuesEnum: ProductType,
    },
    {
        id: 'modificationComment',
        headerProps: {
            children: 'Modification Comment',
        },
        mapCellProps: 'modificationComment',
    },
    {
        id: 'isOrderConfirmed',
        headerProps: {
            children: 'Order Confirmed',
        },
        mapCellProps: 'isOrderConfirmed',
        componentName: 'ChipCheckMark',
    },
]

export default columns
