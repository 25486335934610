import {
    DTOCardFormField, FieldInCard,
} from 'app/types/form.types'
import {
    ProductType,
} from 'app/types/enums'
import {
    TypeLink,
    SizeLink,
} from 'app/shared-components/SkyNetLink'

import {
    AccountTableEntity, AccountStatus,
} from './Account.types'
import {
    getAccountPlanDocumentName,
} from './Account.utils'

const getAccountFields = ({
    getAllowedValues,
    exists,
    accountPlanLink,
    accountLinkAsExtenal,
}: {
    getAllowedValues: (val: Record<string, string>) => Record<string, string>,
    exists: boolean,
    accountPlanLink?: string,
    accountLinkAsExtenal?: boolean,
}): DTOCardFormField<keyof AccountTableEntity>[] => {
    const accountPlanLinkComponent: FieldInCard<keyof AccountTableEntity> = accountLinkAsExtenal ? {
        name: 'accountPlanLink',
        labelKey: 'Accounts.accountPlanLink',
        componentName: 'SkyNetLink',
        type: TypeLink.EXTERNAL,
        size: SizeLink.S,
        text: getAccountPlanDocumentName(accountPlanLink),
        link: accountPlanLink,
    } : {
        name: 'accountPlanLink',
        labelKey: 'Accounts.accountPlanLink',
        componentName: 'LongText',
        rows: 8,
    }

    return [{
        title: 'General Information',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'company',
                        labelKey: 'Accounts.accountCompany',
                        componentName: 'CompanyDataSelector',
                        required: true,
                        disabled: exists,
                    },
                    {
                        name: 'accountStatus',
                        labelKey: 'Accounts.accountStatus',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(AccountStatus),
                        useDropdown: true,
                        required: true,
                    },
                    {
                        name: 'annualPotentialVolume',
                        labelKey: 'Accounts.annualPotentialVolume',
                        componentName: 'InputSingleline',
                        required: true,
                    },
                    accountPlanLinkComponent,
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'accountOwner',
                        labelKey: 'Accounts.accountOwner',
                        componentName: 'UserObjectSelector',
                        required: true,
                    },
                    {
                        name: 'approvedProducts',
                        labelKey: 'Accounts.approvedProducts',
                        componentName: 'EnumChipSelector',
                        allowedValues: getAllowedValues(ProductType),
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'collaborators',
                        labelKey: 'Accounts.accountCollaborators',
                        componentName: 'MultipleUserSelector',
                    },
                    {
                        name: 'createdOn',
                        labelKey: 'Accounts.createdOn',
                        componentName: 'DateTimeSelect',
                        disabled: true,
                    },
                    {
                        name: 'createdBy',
                        labelKey: 'Accounts.createdBy',
                        componentName: 'InputSingleline',
                        disabled: true,
                    },
                ],
            },
            {
                id: 'column4',
                fields: [
                    {
                        name: 'hasMLA',
                        labelKey: 'Accounts.hasMLA',
                        componentName: 'Checkbox',
                    },
                    {
                        name: 'hasAcquisitionPlan',
                        labelKey: 'Accounts.hasAcquisitionPlan',
                        componentName: 'Checkbox',
                    },
                    {
                        name: 'isStrategicAccount',
                        labelKey: 'Accounts.strategicAccount',
                        componentName: 'Checkbox',
                    },
                    {
                        name: 'supportingDocuments',
                        labelKey: 'Accounts.supportingDocuments',
                        componentName: 'AttachmentsObjectSelector',
                    },
                ],
            },
        ],

    }]
}

export default getAccountFields
