import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    AdditionalFeesStatus,
    ContractCurrency,
} from 'app/types/enums'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    AlLogisticPricing,
} from './AlLogisticPricing.types'

export default (): SkyNetTableConfig<keyof AlLogisticPricing> => {
    return {
        name: 'AlLogisticPricing',
        tabs: [{
            name: 'All',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'airlineName',
                    filterField: 'airlineName',
                    labelKey: 'Opportunity.airline',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'currency',
                    labelKey: 'LaneOpsInformationDto.currency',
                    componentName: 'EnumValue',
                    allowedValuesEnum: ContractCurrency,
                    filterField: 'currency',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'status',
                    labelKey: 'LaneBaseDto.laneStatus',
                    filterField: 'status',
                    componentName: 'EnumValue',
                    allowedValuesEnum: AdditionalFeesStatus,
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'validFrom',
                    labelKey: 'LaneFeesUpdateDto.validFrom',
                    filterField: 'validPricingFrom',
                    filterType: FilterType.DateRange,
                    componentName: 'DateTimeCell',
                },
                {
                    name: 'validTo',
                    filterField: 'validTo',
                    labelKey: 'LaneFeesUpdateDto.validTo',
                    filterType: FilterType.DateRange,
                    componentName: 'LaneDateEnriched',
                },
            ],
        }],
    }
}
