import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    LaneStatus,
} from 'app/types/enums'
import {
    AccountLanesTable,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import {
    FilterType,
} from 'app/types/common.enums'
import SkyNetLink, {
    SizeLink, TypeLink,
} from 'app/shared-components/SkyNetLink'

export default ({
    l,
    getLaneLink,
    laneStyle,
}: {
    l: (l: string) => string,
    laneStyle: string,
    getLaneLink: (id: string) => string
}): RichTableColumns<keyof AccountLanesTable>[] => {
    return [
        {
            id: 'laneNumber',
            headerProps: {
                children: l('AccountLane.lane'),
            },
            mapCellProps: ({
                laneNumber,
                id,
            }) => {
                return {
                    value: laneNumber,
                    link: getLaneLink(id),
                    type: TypeLink.EXTERNAL,
                    className: laneStyle,
                    size: SizeLink.S,
                }
            },
            Cell: SkyNetLink,
        },
        {
            id: 'customerExecution',
            headerProps: {
                children: l('AccountLane.customerExecution'),
            },
            mapCellProps: ({
                customerExecution,
            }) => {
                return {
                    children: customerExecution ?? 'Not defined',
                }
            },
            filterType: FilterType.Array,
            filterField: 'customerExecution',
        },
        {
            id: 'accountCompanyName',
            headerProps: {
                children: l('AccountLane.accountCompanyName'),
            },
            mapCellProps: ({
                accountCompanyName,
            }) => {
                return {
                    children: accountCompanyName ?? 'Not defined',
                }
            },
            filterType: FilterType.Array,
            filterField: 'accountCompanyName',
        },
        {
            id: 'pricingAccountCompanyName',
            headerProps: {
                children: l('AccountLane.pricingAccount'),
            },
            mapCellProps: ({
                pricingAccountCompanyName,
            }) => {
                return {
                    children: pricingAccountCompanyName ?? 'Not defined',
                }
            },
            filterType: FilterType.Array,
            filterField: 'pricingAccountCompanyName',
        },
        {
            id: 'laneStatus',
            headerProps: {
                children: l('AccountLane.laneStatus'),
            },
            mapCellProps: 'laneStatus',
            componentName: 'EnumValue',
            allowedValuesEnum: LaneStatus,
        },
        {
            id: 'temperatureRange',
            headerProps: {
                children: l('AccountLane.tempRange'),
                sorting: false,
            },
            mapCellProps: 'temperatureRange',
        },
        {
            id: 'handoverPointLocationName',
            headerProps: {
                children: l('AccountLane.handoverPoint'),
                sorting: false,
            },
            mapCellProps: ({
                handoverPointLocationName,
            }) => {
                return {
                    children: handoverPointLocationName ?? 'Not defined',
                    sorting: false,
                }
            },
        },
        {
            id: 'collectionDropoffPointLocationName',
            headerProps: {
                children: l('AccountLane.collectionDropoffPoint'),
                sorting: false,
            },
            mapCellProps: ({
                collectionDropoffPointLocationName,
            }) => {
                return {
                    children: collectionDropoffPointLocationName ?? 'Not defined',
                }
            },
        },
        {
            id: 'originAirport',
            headerProps: {
                children: l('AccountLane.from'),
            },
            mapCellProps: 'originAirport',
            filterType: FilterType.Array,
            filterField: 'originAirport',
        },
        {
            id: 'destinationAirport',
            headerProps: {
                children: l('AccountLane.to'),
            },
            mapCellProps: 'destinationAirport',
            filterType: FilterType.Array,
            filterField: 'destinationAirport',
        },
    ]
}
