import React from 'react'
import noop from 'lodash/noop'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import {
    RequestConfig,
} from 'app/types/request.types'
import useScrollToForm from 'app/hooks/useScrollToForm'
import FormWithControls from 'app/shared-components/FormWithControls'
import {
    LocationAreas,
} from '../LocationAreas.types'
import LocationAreasRequests from '../LocationAreas.requests'

import locationAreasFields from './LocationAreasForm.fields'
import useStyles from './LocationAreasForm.styles'

type Props = {
    data: LocationAreas,
    onSuccess?: (props: any) => void,
    exists?: boolean,
    onCancel?: (...args: any[]) => void,
    requestParam: RequestConfig,
}
const defaultProps: Partial<Props> = {
    exists: false,
    onSuccess: noop,
    onCancel: noop,
}
const LocationAreasForm = (props: Props) => {
    const {
        data,
        onSuccess,
        requestParam,
        exists,
        onCancel,
    } = props
    const {
        classes,
    } = useStyles()
    const scrollToForm = useScrollToForm(`locationAreas-${data?.id}`)
    const fields = useFieldsWithClassName(
        locationAreasFields,
        classes,
    )

    return (
        <div
            className={classes.root}
            ref={scrollToForm}
        >
            <FormWithControls
                name={LocationAreasRequests.create.name}
                value={data}
                onSuccess={onSuccess}
                fields={fields}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                }}
                requestParams={requestParam}
                exists={exists}
                onCancel={onCancel}
            />

        </div>
    )
}

LocationAreasForm.defaultProps = defaultProps

export default LocationAreasForm
