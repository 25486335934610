/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useMemo,
} from 'react'
import noop from 'lodash/noop'
import {
    useTheme,
} from '@mui/material/styles'

import LineChart from 'app/shared-components/LineChart'
import FullScreen from 'app/shared-components/FullScreen'
import addNotationValuesForChart from './addNotationValuesForChart'

import createChartColumns from './createChartColumns'
import chartColumns from './chartColumns'
import options from './options'

import useStyles from './TemperatureChart.style'

type Props = {
    sensorData?: {
        d: number[],
        t: string,
    }[],
    sensorLabels?: string[],
    onError?: () => void,
    isChartPrinting?: boolean,
    onFullScreen?: (full: boolean) => void,
    userOptions?: any,
    temperatureChartFullscreen?: boolean,
    setTemperatureChartFullscreen?: (value: boolean) => void,
    customColumns?: any,
    customData?: string[][],
    allowFullScreen?: boolean,
    customClassName?: string,
    isDateRange?: boolean,
    measurement?: string,
}

const defaultProps: Partial<Props> = {
    sensorData: undefined,
    sensorLabels: undefined,
    onError: noop,
    onFullScreen: noop,
    customColumns: [],
    customData: [],
    isChartPrinting: false,
    userOptions: undefined,
    temperatureChartFullscreen: false,
    setTemperatureChartFullscreen: noop,
    allowFullScreen: true,
    customClassName: '',
    isDateRange: false,
    measurement: '°C',
}

const DAY = 24 * 60 * 60 * 1000

const TemperatureChart = (props: Props) => {
    const {
        sensorData,
        sensorLabels,
        isChartPrinting,
        onFullScreen,
        userOptions,
        temperatureChartFullscreen,
        setTemperatureChartFullscreen,
        onError,
        customColumns,
        customData,
        allowFullScreen,
        customClassName,
        isDateRange,
        measurement,
    } = props

    const {
        classes,
    } = useStyles()
    const theme = useTheme()
    const locaUserOptions = userOptions || options(theme)

    const FullScreenComponent = useMemo(() => {
        if (!allowFullScreen) {
            return ({
                children,
            }: {
                children: React.ReactNode;
            }) => {
                return (
                    <>
                        {children}
                    </>
                )
            }
        }

        return FullScreen
    }, [allowFullScreen])

    const chartArea = useMemo(() => {
        return {
            left: 20,
            top: 10,
            width: '100%',
            height: '87%',
        }
    }, [])

    const columns = useMemo(() => {
        return [
            ...chartColumns,
            [
                'number',
                'Filler',
            ],
            ...createChartColumns(sensorLabels, measurement),
            ...customColumns,
        ]
    }, [
        sensorLabels,
        measurement,
        customColumns,
    ])

    const chartPrinting = useMemo(() => {
        return isChartPrinting
            ? classes.chartPrinting
            : customClassName || classes.chart
    }, [
        classes.chart,
        classes.chartPrinting,
        isChartPrinting,
        customClassName,
    ])

    const chartOptions = useMemo(() => {
        return isChartPrinting ? {
            ...locaUserOptions,
            chartArea,
        } : locaUserOptions
    }, [
        chartArea,
        isChartPrinting,
        locaUserOptions,
    ])

    const value = useMemo(() => {
        if (!sensorData?.length) {
            const date = new Date()

            return [[
                new Date(date.getTime() - DAY),
                0,
            ]]
        }
        return sensorData.map(({
            d,
            t,
        }, i) => {
            if (customData.length > 0) {
                return [
                    t,
                    0,
                    ...addNotationValuesForChart(d),
                    ...customData[i],
                ]
            }

            return [
                t,
                0,
                ...addNotationValuesForChart(d),
            ]
        })
    }, [
        sensorData,
        customData,
    ])

    return (
        <div
            className={classes.wrapper}
        >
            <FullScreenComponent
                {...props}
                onFullScreen={onFullScreen}
                setTemperatureChartFullscreen={setTemperatureChartFullscreen}
            >
                <LineChart
                    value={value}
                    isDateRange={isDateRange}
                    columns={columns}
                    userOptions={chartOptions}
                    onError={onError}
                    className={temperatureChartFullscreen
                        ? classes.fullscreen
                        : chartPrinting}
                />
            </FullScreenComponent>
        </div>
    )
}

TemperatureChart.defaultProps = defaultProps

export default TemperatureChart
