import React, {
    useCallback,
    useMemo,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'
import noop from 'lodash/noop'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import useLabel from 'app/hooks/useLabel'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'
import useLoadFilters from 'app/Apps/DomainObject/hooks/useLoadFilters/useLoadFilters'
import {
    LaneGroup,
} from 'app/Apps/Pricing/Pricing.types'
import LaneGroupRequests from 'app/Apps/Pricing/LaneGroups/LaneGroup.requests'

import useStyles from './LaneGroupsTable.styles'
import laneGroupsColumns from './LaneGroups.columns'

const MAX_LAGEGROUPS_ROWS = 200

type SelectedRow = {
    id: number | string,
    uniqField: number | string,
}

type Props = {
    selectedRows?: number[],
    onSelectedRow?: (selectedRows: SelectedRow[]) => void,
    contractId: number,
    getDetailPanel?: ({
        rowProps,
        closeOpenedRow,
    })=> JSX.Element,
    onRowClick?: (object: Record<string, any>)=> void,
    openRowId?: string,
    disabled?: boolean,
    renderControlPanel?:(params: any) => JSX.Element,
    showArchived?: boolean,
    showSelectAll?: boolean,

    quoteId?: number,
}

const defaultProps : Partial<Props> = {
    selectedRows: [],
    onSelectedRow: undefined,
    getDetailPanel: null,
    onRowClick: noop,
    openRowId: null,
    disabled: false,
    renderControlPanel: noop,
    showArchived: false,
    showSelectAll: false,
    quoteId: undefined,
}

const LaneGroupsTable = ({
    selectedRows,
    onSelectedRow,
    contractId,
    getDetailPanel,
    onRowClick,
    openRowId,
    disabled,
    renderControlPanel,
    showArchived,
    showSelectAll,
    quoteId,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const l = useLabel()
    const {
        pathname,
    } = useLocation()
    const showArchivedFromUrl = pathname.includes('lanegroup-archived')

    const {
        onParamsChange,
        data,
        loadData,
    } = useRequestTable<{data: LaneGroup[]}>({
        config: LaneGroupRequests.filterRequest,
        params: {
            contractId,
            quoteId,
        },
        key: RequestKeys.getContractBasisLaneGroups,
        keepPreviousData: true,
        defaultValue: {
            rows: MAX_LAGEGROUPS_ROWS,
        },
        showArchived: showArchived || showArchivedFromUrl,
    })

    const handleOnSelectRow = useCallback((selectedRow) => {
        onSelectedRow(data?.data?.filter((selected) => {
            return selectedRow.includes(selected.laneGroupNumber)
        }).map((number) => {
            return {
                id: number.id,
                uniqField: number.laneGroupNumber,
            }
        }))
    }, [
        data,
        onSelectedRow,
    ])

    const injectLoadFilters = useLoadFilters(
        LaneGroupRequests.filterCountRequest({
            contractId,
        }),
    )

    const columnsWithFilters = useMemo(() => {
        return injectLoadFilters(laneGroupsColumns({
            l,
        }))
    }, [
        injectLoadFilters,
        l,
    ])

    return (
        <RichTable
            configName="LaneGroups"
            name="LaneGroup"
            load={loadData}
            onParamsChange={onParamsChange}
            columns={columnsWithFilters}
            uniqField="laneGroupNumber"
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
            onSelectRow={onSelectedRow ? handleOnSelectRow : undefined}
            renderControlPanel={renderControlPanel}
            selectedRows={selectedRows}
            detailPanel={getDetailPanel}
            onRowClick={onRowClick}
            openRowId={openRowId}
            disabled={disabled}
            showSelectAll={showSelectAll}
        />
    )
}

LaneGroupsTable.defaultProps = defaultProps

export default LaneGroupsTable
