import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import useGetDedicatedContainers from '../../hooks/useGetDedicatedContainers'
import DedicatedContainers from './DedicatedContainers'

type Props = {
    value: number,
    disabled?: boolean,
}

const defaultProps: Partial<Props> = {
    disabled: false,
}

const DedicatedContainersContainer = ({
    value,
    disabled = false,
}: Props) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetDedicatedContainers(value)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <DedicatedContainers
                value={data}
                onSuccess={invalidate}
                longTermLeaseId={value}
                disabled={disabled}
            />
        </StatusHandler>
    )
}

DedicatedContainersContainer.defaultProps = defaultProps

export default DedicatedContainersContainer
