import {
    RequestConfig,
} from 'app/types/request.types'
import simpleApi from 'app/services/simpleApi'

import {
    AQuote, RequestDataUpdate,
} from './AQuotes.types'

type AQuotesRequests = {
    domainName: string,
    get: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    getAll: string,
    update: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    create: ({
        data,
    }: {
        data: AQuote
    }) => RequestConfig,
    updateRequestedLane: ({
        data,
        id,
    }: {
        data: RequestDataUpdate,
        id: number,
    }) => RequestConfig,
    assignToContractBasis: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    getLanesToBeGrouped: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    getLanesToAwardReject:({
        id,
        params,
    }: {
        id: number,
        params?: Record<string, any>
    }) => RequestConfig,
    getLaneGroupsPricingAssignment: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    getLaneGroupPricingAssignment: ({
        id,
        quoteId,
    }: {
        id: number,
        quoteId: number,
    }) => RequestConfig,
    getLaneFeesToAwardReject: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    getQuoteDocuments: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    removeQuoteDocument: ({
        id,
        quoteDocumentId,
    }: {
        id: number,
        quoteDocumentId: number,
    }) => RequestConfig,
    addQuotePdf: {
        mutationFn: ({
            quoteId,
            token,
            data,
        }: {
            quoteId: number,
            token: string,
            data: {
                id: number,
            },
        }) => Promise<any>,
    },
    getLanesByQuote: ({
        params,
    }
    ?: Record<string, any>) => RequestConfig,
    quotePDFPreview: () => RequestConfig,
    RemoveLaneFromLaneGroup: {
        mutationFn: ({
            laneId,
            token,
        }: {
            laneId: number,
            token: string,
        }) => Promise<any>,
    },
    filterRequest: (p?: {params?: Record<string, string>}) => RequestConfig,
}

const aQuoteRequests: AQuotesRequests = {
    domainName: 'quote',
    get: ({
        id,
    }) => {
        return {
            url: `quote/${id}`,
            method: 'GET',
        }
    },
    filterRequest: ({
        params = {},
    } = {}) => {
        return {
            url: 'quote/filter-request/all',
            method: 'POST',
            ...params,
        }
    },
    getAll: 'quote/filter-request/all',
    update: ({
        id,
    }) => {
        return {
            url: `quote/${id}`,
            method: 'PATCH',
        }
    },
    create: ({
        data,
    }) => {
        return {
            url: 'quote',
            method: 'POST',
            data,
        }
    },
    updateRequestedLane: ({
        data,
        id,
    }) => {
        return {
            url: `quote/${id}/requestedlane`,
            method: 'POST',
            data,
        }
    },
    assignToContractBasis: ({
        id,
    }) => {
        return {
            url: `quote/${id}/assignment-to-contractbasis`,
            method: 'PATCH',
        }
    },
    getLanesToBeGrouped: ({
        id,
    }) => {
        return {
            url: `quote/${id}/lane/to-be-grouped`,
            method: 'GET',
        }
    },
    getLanesToAwardReject: ({
        id,
        params,
    }) => {
        return {
            url: `quote/${id}/lane/filter-request/awardreject`,
            method: 'POST',
            ...params,
        }
    },
    getLaneGroupsPricingAssignment: ({
        id,
    }) => {
        return {
            url: `quote/${id}/lanegroup/filter-request/pricing-assignment`,
            method: 'POST',
        }
    },
    getLaneGroupPricingAssignment: ({
        id,
        quoteId,
    }) => {
        return {
            url: `/quote/${quoteId}/lanegroup/${id}/pricing-assignment`,
            method: 'GET',
        }
    },
    getQuoteDocuments: ({
        id,
    }) => {
        return {
            url: `quote/${id}/quotedocument/filter-request/all`,
            method: 'POST',
        }
    },
    getLaneFeesToAwardReject: ({
        id,
    }) => {
        return {
            url: `quote/${id}/lanefees/filter-request/awardreject`,
            method: 'POST',
        }
    },
    removeQuoteDocument: ({
        id,
        quoteDocumentId,
    }) => {
        return {
            url: `quote/${id}/quotedocument/filter-request/${quoteDocumentId}/remove`,
            method: 'POST',
        }
    },
    addQuotePdf: {
        mutationFn: ({
            quoteId,
            token,
            data,
        }) => {
            return simpleApi({
                url: `quote/${quoteId}/quotedocument/existing-file`,
                data,
                method: 'POST',
                token,
            })
        },
    },
    getLanesByQuote: ({
        params = {},
    } = {}) => {
        return {
            url: 'lane/filter-request/quote-stepper',
            method: 'POST',
            ...params,
        }
    },
    quotePDFPreview: () => {
        return {
            url: 'quote/quotedocument/preview/pdf',
            method: 'POST',
        }
    },
    RemoveLaneFromLaneGroup: {
        mutationFn: ({
            laneId,
            token,
        }) => {
            return simpleApi({
                url: `lane/${laneId}/unassignment-from-lanegroup`,
                method: 'PATCH',
                token,
            })
        },
    },
}

export default aQuoteRequests
