import React, {
    useCallback,
} from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import {
    RichTableData,
} from 'app/types/richtable.types'
import useContainerManagementRoutes from 'app/Apps/ContainerManagement/hooks/useContainerManagementRoutes'

import {
    SelectedContainer,
} from '../../orders.types'

import columns from './SelectedAssets.columns'
import useStyles from './SelectedAssets.styles'

type Props = {
    value?: SelectedContainer[],
}

const defaultProps: Partial<Props> = {
    value: [],
}

const SelectedAssets = ({
    value,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const {
        Containers: ContainersRoutes,
    } = useContainerManagementRoutes()

    const onRowClick = useCallback(({
        serialNumber,
    }) => {
        ContainersRoutes.SensorData.navigate({
            params: {
                key: serialNumber,
            },
        })
    }, [ContainersRoutes])

    const load = useCallback((): Promise<RichTableData<SelectedContainer[]>> => {
        return Promise.resolve({
            data: value,
        })
    }, [value])

    return (
        <RichTable
            configName="SelectedAssets"
            name="SelectedContainers"
            columns={columns}
            load={load}
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
            rowStyle={{
                crossLine: 'isRejected',
            }}
            onRowClick={onRowClick}
        />
    )
}

SelectedAssets.defaultProps = defaultProps

export default SelectedAssets
