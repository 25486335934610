import {
    Validate,
} from 'app/types/form.types'
import {
    Contact,
} from 'app/Apps/ContactManagement/Contacts/Contacts.types'

export const isContactRequired: Validate<Contact[]> = (value, errorMsg) => {
    if (!value || !value?.length) return [errorMsg || 'Contacts field cannot be empty.']
    return true
}

export const isContactProspect: Validate<Contact> = (value, errorMsg) => {
    if (value?.isProspect) return [errorMsg || 'This contact is prospect']
    return true
}

export const isOneOfContactsProspect: Validate<Contact[]> = (value, errorMsg) => {
    if ((value || []).some((contact) => {
        return Array.isArray(isContactProspect(contact))
    })) return [errorMsg || 'One or more contacts are Prospect.']
    return true
}
