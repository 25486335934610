import React, {
    useCallback,
    useState,
    useMemo,
} from 'react'
import noop from 'lodash/noop'
import omit from 'lodash/omit'
import {
    useLocation,
} from 'react-router-dom'

import FormWithControls from 'app/shared-components/FormWithControls'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useHasPermissionType from 'app/Apps/ContactManagement/utils/useHasPermissionType'
import useHasPermission from 'app/hooks/useHasPermission'
import useGetMainCategory from 'app/hooks/useGetMainCategory'
import {
    useGetLocation,
} from 'app/Apps/ContactManagement/Locations/hooks'
import {
    ContactMainType,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'
import getAllowedCategoriesTypes from 'app/Apps/ContactManagement/utils/getAllowedCategoriesTypes'
import useGetAllowedCategoryType from 'app/Apps/ContactManagement/utils/useGetAllowedCategoryType'
import {
    RequestConfig,
} from 'app/types/request.types'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import useRelationshipsManagementRoutes from 'app/Apps/RelationshipsManagement/hooks/useRelationshipsManagementRoutes'

import {
    Contact,
} from '../Contacts.types'

import contactFormFields from './ContactForm.fields'
import useStyles from './ContactForm.style'
import PROSPECT_READ_ONLY_LIST from './prospectReadOnlyList'

export type ContactFormProps = {
    data?: Contact,
    onCancel: (props?: any) => void,
    disabled: boolean,
    onSuccess: (props: any) => void,
    request: RequestConfig,
    customButtons?: JSX.Element[],
}

const defaultProps:Partial<ContactFormProps> = {
    data: {} as Contact,
    onCancel: noop,
    disabled: true,
    onSuccess: undefined,
    customButtons: [],
}

const ContactForm = ({
    request,
    onCancel,
    disabled,
    data,
    onSuccess,
    customButtons,
}: ContactFormProps) => {
    const {
        classes,
    } = useStyles()
    const isNew = !data?.id
    const {
        state,
    } = useLocation()

    const {
        Engagements: EngagementsRoutes,
    } = useRelationshipsManagementRoutes()

    const getAllowedValues = useEnumValues()
    const contactMainTypes = getAllowedValues(ContactMainType)

    const mainCategories = useGetMainCategory()

    const permissionType = disabled ? 'read' : 'edit'
    const mainTypes = useGetAllowedCategoryType('ContactAccessRights', isNew ? 'create' : permissionType)

    const allowedMainTypes = useMemo(() => {
        if (mainCategories) {
            return getAllowedCategoriesTypes(
                mainCategories?.contactMainType,
                mainTypes,
                false,
                getAllowedValues,
            )
        }
        return []
    }, [
        mainCategories,
        mainTypes,
        getAllowedValues,
    ])

    const formatMainTypes = (mainType) => {
        return mainType?.map((type) => {
            return {
                value: type,
                label: contactMainTypes[type],
            }
        })
    }

    const onlyReadProspect = useHasPermission(PROSPECT_READ_ONLY_LIST)
    const canCreateProspect = useHasPermission(['location_sales'])// && !onlyReadProspect
    const showEngagementLink = useHasPermission(['relationships_sales'])

    const isProspectOnLoad = useMemo(() => {
        if (isNew) {
            return !onlyReadProspect
        }
        return data?.isProspect
    }, [
        data?.isProspect,
        onlyReadProspect,
        isNew,
    ])

    const [
        value,
        setValue,
    ] = useState({
        ...data,
        mainType: formatMainTypes(data.mainType),
        isProspect: isProspectOnLoad,
        company: isNew ? state?.company : data?.company,
    })

    const {
        isProspect,
    } = value

    const isProspectDisabled = useMemo(() => {
        return !canCreateProspect || (!isNew && !isProspect)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const fields = useMemo(() => {
        return contactFormFields(
            {
                disabled,
                allowedMainTypes,
                hasRelatedUser: value?.hasRelatedUser,
                isProspectDisabled,
                isProspect,
                showEngagementLink,
                engagementLink: value?.engagementId ? generateSkyNetLink({
                    domainPath: EngagementsRoutes.Overview.path,
                    params: {
                        key: value?.engagementId,
                    },
                }) : null,
            },
        )
    }, [
        EngagementsRoutes,
        disabled,
        allowedMainTypes,
        value?.hasRelatedUser,
        isProspectDisabled,
        isProspect,
        showEngagementLink,
        value?.engagementId,
    ])

    const contactComponents = useFieldsWithClassName(
        fields, classes,
    )

    const modifyDataBeforeSend = useCallback((newValue) => {
        return omit(newValue, [
            'mainType',
            ...isProspect ? ['location'] : ['company'],
        ])
    }, [isProspect])

    const hasReadPermissions = useHasPermissionType(data.mainType, 'ContactAccessRights')

    const hasEditPermissions = useHasPermissionType(data.mainType, 'ContactAccessRights', 'edit')

    const isNotReadable = useMemo(() => {
        return !hasReadPermissions && !isNew
            && value.mainType
            && value.mainType.length
    }, [
        hasReadPermissions,
        value.mainType,
        isNew,
    ])

    const isDisabled = useMemo(() => {
        return (!hasEditPermissions && !isNew && Boolean(value.mainType)
            && Boolean(value.mainType.length)) || disabled
    }, [
        hasEditPermissions,
        value.mainType,
        isNew,
        disabled,
    ])

    const {
        data: locationData,
    } = useGetLocation(!isProspect ? value?.location?.id : undefined)

    const enhancedValue = useMemo(() => {
        return {
            ...value,
            country: isProspect ? value?.country : locationData?.country,
        }
    }, [
        locationData?.country,
        value,
        isProspect,
    ])

    const onSuccessHandle = useCallback((args) => {
        onSuccess({
            ...args,
            isProspect,
        })
    }, [
        onSuccess,
        isProspect,
    ])

    if (isNotReadable) {
        return <div>Access denied</div>
    }
    return (
        <FormWithControls
            name="Contact"
            value={enhancedValue}
            className={classes.rowFormContainer}
            classNames={{
                gridWrapper: classes.gridWrapper,
            }}
            fields={contactComponents}
            exists={!isNew}
            cancelEnabled={isNew}
            onCancel={onCancel}
            disabled={isDisabled}
            setExternalValue={setValue}
            onSuccess={onSuccessHandle}
            modifyDataBeforeSend={modifyDataBeforeSend}
            requestParams={request}
            customButtons={customButtons}
        />
    )
}

ContactForm.defaultProps = defaultProps

export default ContactForm
