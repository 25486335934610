import React, {
    useMemo, useState,
} from 'react'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'

import AddRemoveIcon from 'app/shared-components/CollapsableAddRemovePanel/AddRemoveIcon'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import useLabel from 'app/hooks/useLabel'
import SharedContext from 'app/shared-components/SharedContext'

import {
    RequestConfig,
} from 'app/types/request.types'
import CommentCreateForm from './CommentCreateForm'
import getColumns from './commentsTable.columns'
import useStyles from './CommentsTable.style'

const defaultProps = {
    className: '',
    sorting: true,
}

const CommentsTable = ({
    requestConfigCreate,
    deleteComment,
    onParamsChange,
    loadData,
    refetch,
    className,
    sorting,
}: {
    requestConfigCreate: RequestConfig,
    deleteComment: (id: number) => void,
    onParamsChange: (...args: any) => void,
    loadData: () => any,
    className?: string,
    sorting?: boolean,
    refetch: () => void }) => {
    const {
        classes,
        cx,
    } = useStyles()

    const l = useLabel()

    const [
        createFormOpened,
        setCreateFormOpened,
    ] = useState(false)

    const closeCreateForm = (): void => {
        setCreateFormOpened(false)
    }

    const openCreateForm = (): void => {
        setCreateFormOpened(true)
    }

    const onCreate = () => {
        refetch()
        closeCreateForm()
    }

    const columns = useMemo(() => {
        return getColumns(l, sorting)
    }, [
        l,
        sorting,
    ])

    return (
        <Card
            className={cx(classes.root, className)}
            data-testid="OrderComments-card"
        >
            <SharedContext
                value={{
                    deleteComment,
                    requestConfigCreate,
                }}
            >
                <div>
                    <Typography variant="h3">
                        Comments
                    </Typography>
                    <AddRemoveIcon
                        formOpened={createFormOpened}
                        onOpenForm={openCreateForm}
                        onCloseForm={closeCreateForm}
                    />
                </div>
                <Collapse in={createFormOpened}>
                    <CommentCreateForm
                        onSuccess={onCreate}
                        onCancel={closeCreateForm}
                    />
                </Collapse>
                <RichTable
                    configName="OrderNotificationsTable"
                    name="OrderComments"
                    load={loadData}
                    onParamsChange={onParamsChange}
                    columns={columns}
                    className={classes.tableContent}
                    classNames={{
                        contentWrapper: classes.tableContentWrapper,
                    }}
                />
            </SharedContext>

        </Card>
    )
}

CommentsTable.defaultProps = defaultProps

export default CommentsTable
