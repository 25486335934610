import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    LeaseType, ProductType,
    TemperatureRange,
} from 'app/types/enums'
import {
    ForecastDataSpreadsheetColumnsConfig,
    ForecastOngoingOpportunities, ForecastSpreadsheetDataType,
} from '../../Forecast.types'

const getAccountFields = ({
    getAllowedValues,
    productType,
    tmpFields,
    disabled,
    onSpreadsheetChange,
    productTypeLabels,
}: {
    getAllowedValues: (e: Record<string, string>) => Record<string, string>,
    productType: ProductType,
    tmpFields: ForecastDataSpreadsheetColumnsConfig,
    disabled: boolean,
    onSpreadsheetChange: (d: ForecastSpreadsheetDataType[]) => void,
    productTypeLabels: Record<string, string>
}): DTOCardFormField<keyof (ForecastOngoingOpportunities & {
    spreadsheetData: ForecastSpreadsheetDataType
})>[] => {
    return [
        {
            title: 'Forecast Information',
            className: 'card',
            fields: [
                {
                    id: 'column1',
                    fields: [
                        {
                            name: 'accountName',
                            componentName: 'InputSingleline',
                            labelKey: 'Forecasts.accountName',
                            disabled: true,
                        },
                        {
                            name: 'opportunityNumber',
                            componentName: 'InputSingleline',
                            labelKey: 'Forecasts.opportunityNumber',
                            disabled: true,
                        },
                    ],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'temperatureRange',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(TemperatureRange),
                            labelKey: 'Opportunities.temperatureRange',
                            disabled: true,
                        },
                        {
                            name: 'leaseType',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(LeaseType),
                            labelKey: 'Forecasts.leaseType',
                            disabled: true,
                        },
                    ],
                },
                {
                    id: 'column3',
                    fields: [
                        {
                            name: 'originAirportIataCode',
                            componentName: 'InputSingleline',
                            labelKey: 'Forecasts.originAirport',
                            disabled: true,
                        },
                        {
                            name: 'destinationAirportIataCode',
                            componentName: 'InputSingleline',
                            labelKey: 'Forecasts.destinationAirport',
                            disabled: true,
                        },
                    ],
                },
                {
                    id: 'column4',
                    fields: [
                        {
                            name: 'changedOn',
                            componentName: 'DateTimeSelect',
                            labelKey: 'Forecasts.validFrom',
                            disabled: true,
                        },
                        {
                            name: 'opportunityCollaborators',
                            componentName: 'MultipleUserSelector',
                            labelKey: 'Opportunity.opportunityCollaborators',
                            disabled: true,
                        },
                    ],
                },
            ],
        },
        {
            title: `Forecast by Product Type ${productTypeLabels[productType]}`,
            className: 'card',
            fields: [{
                name: 'spreadsheetData',
                componentName: 'SingleForecastSpreadsheet',
                disabled,
                tmpFields,
                onSpreadsheetChange,
            }],
        },
    ]
}

export default getAccountFields
