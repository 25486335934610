import React from 'react'
import Link from '@mui/material/Link'

import Tooltip from 'app/shared-components/Tooltip'

import FilterIcon from '../../FilterIcon'
import useCommonStyles from '../common.styles'

import useStyles from './ClearFilters.styles'

type Props = {
    removeFilter: () => void,
}

const ClearFilters = ({
    removeFilter,
}: Readonly<Props>) => {
    const {
        classes,
    } = useStyles()
    const {
        classes: commonClasses,
    } = useCommonStyles()

    return (
        <Tooltip title="Clear Filters">
            <Link
                className={commonClasses.link}
                component="button"
                color="textSecondary"
                variant="body1"
                onClick={removeFilter}
            >
                <FilterIcon className={classes.icon} />
            </Link>
        </Tooltip>
    )
}

export default ClearFilters
