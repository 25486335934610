import React, {
    useCallback,
    useMemo,
} from 'react'
import round from 'lodash/round'
import isEmpty from 'lodash/isEmpty'

import {
    Fee,
} from 'app/Apps/Pricing/Pricing.types'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    ContractCurrency,
    FeeChargeability, ProductType,
} from 'app/types/enums'
import {
    AdditionalFeesAllowed,
    LaneFeesAllowed,
    OtherFeesAllowed,
    FeesType,
} from 'app/shared-components/Fees/Fee.types'
import {
    ProductTypeContainerTempRange,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import useLabel from 'app/hooks/useLabel'
import StatusHandler from 'app/shared-components/StatusHandler'
import FxRate from 'app/shared-components/FxRate/FxRate.types'
import SkyNetSpreadSheet from 'app/shared-components/SkyNetSpreadSheet'

import useFeePricingName from '../FeeInputs/useFeePricingName'
import useFeesNameToFeesChargeabilityMapping from '../useFeesNameToFeesChargeabilityMapping'

import getSpreadSheetConfig from './FeesSpreadSheet.config'

const LANE_INDEPENDENT_FEE_NAME = 'laneIndependentPricingFees'
const OTHERFEES = 'otherFees'
const FEES = 'fees'

const feeChargeabilityWithoutValues = [
    'NOT_APPLICABLE',
    'INCLUDED',
    'PERCENTAGE_BASE_LEASE_PERIOD',
    'PERCENTAGE_BASE_LEASE_POSITIONING_PERIOD',
    'TBD',
    'UNSPECIFIED',
]

const CHARGEABILITIES_WITH_PRODUCT_TYPE = [
    'FIXED_AMOUNT_PER_DAY_CONTAINER',
    'FIXED_AMOUNT_CONTAINER_SPECIFIC',
]

const feeNames = {
    [OTHERFEES]: OtherFeesAllowed,
    [LANE_INDEPENDENT_FEE_NAME]: AdditionalFeesAllowed,
    [FEES]: LaneFeesAllowed,
}

const defaultProps = {
    productTypes: [],
    tempRange: undefined,
    currency: undefined,
    fxRate: undefined,
}

const FeesSpreadSheet = ({
    value,
    productTypes,
    tempRange,
    currency,
    feeName,
    disabled,
    fxRate,
    onChange,
}: {
    value: Fee[],
    productTypes?: ProductTypeContainerTempRange[],
    tempRange?: string,
    currency?: ContractCurrency,
    feeName: string,
    disabled: boolean,
    fxRate?: FxRate,
    onChange: (...args: any[]) => void,
}) => {
    const getEnumValues = useEnumValues()
    const getLabel = useLabel()
    const allowedProductTypes = getEnumValues(ProductType)
    const allowedFeeChargeabilities = getEnumValues(FeeChargeability)
    const feePricingName = useFeePricingName()
    const name = feePricingName || feeName
    const chargeabilityMapping = useFeesNameToFeesChargeabilityMapping(name as FeesType)

    const productTypesOptions = useMemo(() => {
        const list = tempRange ? Object.values(productTypes)?.filter((pt) => {
            return pt.temperatureRange === tempRange
        }) : Object.values(productTypes)

        return list?.reduce((acc, element) => {
            return {
                ...acc,
                [element.productType]: allowedProductTypes[element.productType],
            }
        }, {})
    }, [
        productTypes,
        allowedProductTypes,
        tempRange,
    ])

    const defaultChargeabilityOptions = useMemo(() => {
        // we need it to paste values into spreadsheet
        return Array.from(Object.values(chargeabilityMapping || {}).reduce((acc, values) => {
            values.forEach((val) => {
                return acc.add(val)
            })
            return acc
        }, new Set())) as string[]
    }, [chargeabilityMapping])

    const getChargeabilityOptions = useCallback((item: Fee) => {
        if (chargeabilityMapping) {
            const optionKeys = item.feeName
                ? chargeabilityMapping[item?.feeName]
                : defaultChargeabilityOptions

            return optionKeys ? optionKeys.map((key) => {
                return [
                    key,
                    allowedFeeChargeabilities[key],
                ]
            }) as [any, string][] : []
        }
        return []
    },
    [
        allowedFeeChargeabilities,
        chargeabilityMapping,
        defaultChargeabilityOptions,
    ])

    const showProductTypeSelect = useCallback((item: Fee) => {
        if (!item?.feeChargeability) {
            // we need it to paste values into spreadsheet
            return false
        }
        return !CHARGEABILITIES_WITH_PRODUCT_TYPE.includes(item.feeChargeability)
    }, [])

    const showFeeValue = useCallback((item: Fee) => {
        if (!item?.feeChargeability) {
            return false
        }

        return feeChargeabilityWithoutValues.includes(item?.feeChargeability)
    }, [])

    const showPercentage = useCallback((item: Fee) => {
        return item?.feeChargeability === 'PERCENTAGE_BASE_LEASE'
    }, [])

    const currencyConverter = useCallback((item: Fee) => {
        if (!fxRate) {
            return null
        }

        return round(Number(item?.feeValue) / fxRate?.exchangeRate)
    }, [fxRate])

    const spreadSheetConfig = useMemo(() => {
        return getSpreadSheetConfig({
            feeNameOptions: getEnumValues(feeNames[name]),
            productTypesOptions,
            getChargeabilityOptions,
            showProductTypeSelect,
            showFeeValue,
            showPercentage,
            currency,
            currencyConverter,
            getLabel,
        })
    }, [
        name,
        showProductTypeSelect,
        showFeeValue,
        showPercentage,
        getEnumValues,
        getChargeabilityOptions,
        productTypesOptions,
        currency,
        currencyConverter,
        getLabel,
    ])

    const handleChange = useCallback((data) => {
        onChange(data, {
            target: {
                name: feeName,
                value: data,
            },
        })
    }, [
        onChange,
        feeName,
    ])

    const validateRow = useCallback((item) => {
        return !isEmpty(item.feeName)
    }, [])

    return (
        <StatusHandler
            isSuccess={Boolean(chargeabilityMapping)}
            isFetching={!chargeabilityMapping}
        >
            <SkyNetSpreadSheet
                value={value}
                config={spreadSheetConfig}
                disabled={disabled}
                onChange={handleChange}
                validateRow={validateRow}
            />
        </StatusHandler>

    )
}

FeesSpreadSheet.defaultProps = defaultProps

export default FeesSpreadSheet
