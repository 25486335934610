import React, {
    useMemo,
} from 'react'

import {
    ReactComponent as WarningIcon,
} from 'app/shared-components/images/icons/warning_icon.svg'

import useStyles from './TextIcon.styles'

export enum IconType {
    Warning = 'warning'
}

export default function TextIcon({
    type,
    value,
}: Readonly<{
    type: IconType,
    value: string,
}>) {
    const {
        classes, cx,
    } = useStyles({
        type,
    })
    const icon = useMemo(() => {
        switch (type) {
        case IconType.Warning: return <WarningIcon className={classes.icon} />
        default: return null
        }
    }, [
        classes.icon,
        type,
    ])

    return (
        <div className={classes.cell}>
            <div className={cx(classes.text, classes[type])}>{value}</div>
            {icon}
        </div>
    )
}
