import {
    FieldInCard,
} from 'app/types/form.types'
import {
    GeneralStatusChangeNote,
    Note,
} from '../../../ContainerNotes.types'

export const ChangeConfig: FieldInCard<keyof Note> = {
    title: 'Change',
    className: 'change',
    fields: [
        {
            name: 'noteSubject',
            componentName: 'InputSingleline',
            labelKey: 'Note.noteSubject',
        },
        {
            name: 'noteText',
            componentName: 'LongText',
            labelKey: 'Note.noteText',
        },
        {
            name: 'attachments',
            componentName: 'AttachmentsObjectSelector',
            labelKey: 'Note.attachments',
            disabled: true,
        },
    ],
}

export const AuditTrailConfig: FieldInCard<keyof Note> = {
    title: 'Audit Trail',
    className: 'audit',
    fields: [
        {
            name: 'createdBy',
            componentName: 'InputSingleline',
            labelKey: 'Note.createdBy',
        },
        {
            name: 'createdOn',
            componentName: 'DateTimeSelect',
            labelKey: 'Note.createdOn',
            disabled: true,
        },
        {
            name: 'validDateTimestamp',
            componentName: 'DateTimeZonedSelect',
            labelKey: 'Note.validDateTimestamp',
        },
    ],
}

export const StatusChangeConfig: FieldInCard<keyof GeneralStatusChangeNote> = {
    title: 'Status Change',
    className: 'Status',
    fields: [{
        name: 'statusChange',
        componentName: 'StatusSelector',
        labelKey: 'Note.statusChange',
    }],
}
