import React, {
    useCallback,
    useState,
} from 'react'

import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import Button from 'app/shared-components/Button'
import Card from 'app/shared-components/Card'
import {
    OrderStatuses,
    StepTypeDetailed,
} from 'app/types/enums'
import OrderStepsForm from 'app/Apps/OrderManagement/Orders/Components/OrderStepsForm'
import ModificationWarningMsg from 'app/shared-components/ModificationWarningMsg'
import {
    ModificationType,
    StepStatus,
    OrderStep,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    ALLOWED_UPDATE_LOCATION,
} from 'app/Apps/OrderManagement/Orders/utils/orderStatus'

import OrderStepCard from '../OrderStepCard'
import OrderNotifications from '../../OrderNotifications'
import {
    FilterNotifications,
} from '../OrderSteps.types'

import useStyles from './SavedOrderStepsForm.style'
import useGetFilterNotifications from './hooks/useGetFilterNotifications'
import UpdateLocationDialog from './UpdateLocationDialog'
import useUpdateOrderStep from './hooks/useUpdateOrderStep'

const WARNING_MSG = 'You are modifying already saved information. All changes will be logged.'

type Props = {
    value: OrderStep,
    orderId: number,
    orderVersion?: number
    orderNumber: string,
    isOrderTypeCustomer?: boolean,
    disabled?: boolean,
    isOpen?: boolean,
    onSuccess: () => void,
    baseLeaseUntilTimestamp?: string,
    orderStatus: OrderStatuses,
}

const defaultProps = {
    disabled: false,
    orderVersion: undefined,
    baseLeaseUntilTimestamp: undefined,
    isOpen: false,
    isOrderTypeCustomer: false,
}

const SavedOrderStepsForm = ({
    value,
    disabled,
    isOpen,
    orderId,
    orderNumber,
    orderVersion,
    isOrderTypeCustomer,
    baseLeaseUntilTimestamp,
    onSuccess,
    orderStatus,
}: Props) => {
    const {
        stepTypeDetailed,
        stepProgress,
        stepStatus,
        id: orderStepId,
    } = value

    const {
        classes,
    } = useStyles()

    const [defaultValue] = useState<OrderStep>(value)
    const [
        data,
        setData,
    ] = useState<OrderStep>(value)

    const [
        modificationType,
        setModificationType,
    ] = useState<ModificationType>()

    const [
        isFormUpdateLocationOpen,
        setFormUpdateLocationOpen,
    ] = useState<boolean>(false)

    const [
        isFormOpen,
        setFormOpen,
    ] = useState<boolean>(isOpen)

    const openFormUpdateLocation = () => {
        setFormUpdateLocationOpen(true)
    }

    const resetData = useCallback((): void => {
        setData(defaultValue)
        setModificationType(undefined)
    }, [defaultValue])

    const openForm = useCallback((): void => {
        if (isFormOpen) {
            resetData()
        }
        setFormOpen(!isFormOpen)
    }, [
        resetData,
        isFormOpen,
    ])

    const filterNotifications: FilterNotifications = useGetFilterNotifications({
        stepTypeDetailed,
        stepProgress,
        orderId,
    })

    const {
        mutate,
        isPending: isLoading,
    } = useUpdateOrderStep(onSuccess)
    const saveOrderStep = (): void => {
        mutate({
            data: {
                ...data,
                version: orderVersion,
            },
            orderNumber,
            modificationType,
            id: orderStepId,
        })
    }

    const reset = (): void => {
        resetData()
        setFormOpen(false)
    }

    const modifyOrder = (modificationFlag: ModificationType): void => {
        setModificationType(modificationFlag)
    }

    const isCancelDisabled: boolean = !(defaultValue !== data || modificationType)

    const isClosed: boolean = stepStatus === StepStatus.CLOSED

    const isInProgress: boolean = stepStatus === StepStatus.IN_PROGRESS

    const isUpdateLocation: boolean = (stepTypeDetailed === StepTypeDetailed.UNLOADING_CONFIRM
            || stepTypeDetailed === StepTypeDetailed.DROPOFF_CONFIRM)
        && ALLOWED_UPDATE_LOCATION.includes(orderStatus)

    return (
        <>
            <div
                className={classes.container}
            >
                <Card>
                    <OrderStepCard
                        value={value}
                        openForm={openForm}
                        isFormOpen={isFormOpen}
                        className={classes.pointer}
                    />
                    {isFormOpen && (
                        <>
                            {modificationType && (
                                <ModificationWarningMsg msg={WARNING_MSG} />
                            )}
                            <OrderStepsForm
                                value={data}
                                onChange={setData}
                                disabled={disabled}
                                isModified={Boolean(modificationType)}
                                baseLeaseUntilTimestamp={baseLeaseUntilTimestamp}
                                exists
                            />
                            <OrderNotifications
                                includeFilters={{
                                    ...filterNotifications,
                                }}
                                className={classes.wrapper}
                                disabled={disabled}
                                orderNumber={orderNumber}
                                url={orderManagementRequest.Notifications.OrderSteps.url(
                                    orderNumber,
                                    orderStepId,
                                )}
                            />
                            {(!disabled || isUpdateLocation) && (
                                <ButtonContainer>
                                    {!disabled && (!isClosed || modificationType) && (
                                        <>
                                            <Button
                                                label="Save"
                                                onClick={saveOrderStep}
                                                disabled={defaultValue === data}
                                                saving={isLoading}
                                            />
                                            <Button
                                                disabled={isCancelDisabled}
                                                secondary
                                                label="Cancel"
                                                onClick={reset}
                                            />
                                        </>
                                    )}
                                    {
                                        (
                                            !disabled && !modificationType) && (
                                            <>
                                                <Button
                                                    label="Modify (SkyCell)"
                                                    onClick={() => {
                                                        modifyOrder(ModificationType.INTERNAL)
                                                    }}
                                                    secondary={isInProgress}
                                                    data-testid="modify-btn-internal"
                                                />
                                                {isOrderTypeCustomer && (
                                                    <Button
                                                        label="Modify (Customer)"
                                                        onClick={() => {
                                                            modifyOrder(ModificationType.CUSTOMER)
                                                        }}
                                                        secondary={isInProgress}
                                                    />
                                                )}
                                            </>
                                        )
                                    }
                                    {isUpdateLocation && (
                                        <Button
                                            label="Update Location"
                                            onClick={openFormUpdateLocation}
                                        />
                                    )}
                                </ButtonContainer>
                            )}
                        </>
                    )}
                </Card>
            </div>
            <UpdateLocationDialog
                open={isFormUpdateLocationOpen}
                setOpen={setFormUpdateLocationOpen}
                onSuccess={onSuccess}
                orderNumber={orderNumber}
            />
        </>
    )
}

SavedOrderStepsForm.defaultProps = defaultProps

export default SavedOrderStepsForm
