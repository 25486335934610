/* eslint-disable import/prefer-default-export */
import omit from 'lodash/omit'

import {
    BaseLeaseTable, BaseLeaseEditable,
} from './BaseLease.types'

export const prepareBaseLeaseToBulkEdit = (items: BaseLeaseTable[] = []): BaseLeaseEditable[] => {
    return items.map((i) => {
        return omit(i, [
            'productType',
            'currency',
            'baseLeaseCategory',
        ])
    })
}
