import React, {
    useCallback,
    useEffect,
    useState,
} from 'react'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    Country,
} from 'app/Apps/ContactManagement/Countries/Countries.types'
import Typeahead from 'app/shared-components/Typeahead'
import useGetLocation from 'app/Apps/ContactManagement/Locations/hooks/useGetLocation'

import CustomerExecutionSelectorRequests from './CustomerExecutionSelector.requests'

import useStyles from './CustomerExecutionSelector.styles'

type Props = {
    title?: string,
    name?: string,
    value?: number | string,
    onChange: () => void,
    disabled?: boolean,
    required?: boolean,
    className?: string,
    contractId: number,
}
const defaultProps = {
    name: '',
    title: '',
    value: undefined,
    disabled: false,
    required: false,
    className: '',
}

type CustomerExecutionTypes = {
    addressCategory: string[],
    addressLine1: string,
    city: string,
    country: Country,
    id: number,
    locationName: string,
    timeZone: string,
    zip: string,

}

const CustomerExecutionSelector = ({
    name,
    title,
    value,
    onChange,
    disabled,
    required,
    className,
    contractId,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const [
        options,
        setOptions,
    ] = useState([])

    const {
        data: labelData,
    } = useGetLocation(value)

    const {
        data,
        isDataReady,
    } = useRequest<CustomerExecutionTypes[]>({
        key: RequestKeys.getCustomerExecution,
        params: {
            id: contractId,
        },
        requestFunc: RequestFn.getEntity(CustomerExecutionSelectorRequests.request),
        enabled: Boolean(contractId),
    })

    useEffect(() => {
        if (!isDataReady) {
            return
        }
        setOptions(data?.map(((customerExecution) => {
            return {
                ...customerExecution,
                label: customerExecution.locationName,
            }
        })))
    }, [
        data,
        setOptions,
        isDataReady,
    ])

    const loadOptions = useCallback(({
        phrase,
    }) => {
        return Promise.resolve(options.filter(((option) => {
            return option.locationName.toLowerCase().includes(phrase.toLowerCase())
        })))
    }, [options])

    return (
        <div
            className={className}
        >
            <Typeahead
                name={name}
                value={value}
                title={title}
                onChange={onChange}
                loadOptions={loadOptions}
                labelData={labelData}
                labelField="locationName"
                classNames={{
                    options: classes.options,
                }}
                disabled={disabled}
                isRequired={required}
            />
        </div>
    )
}

CustomerExecutionSelector.defaultProps = defaultProps

export default CustomerExecutionSelector
