import useRequestTable from 'app/hooks/useRequestTable'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import {
    LeaseType,
} from 'app/types/enums'

import {
    AccountLanesTable,
} from '../../AccountLanes/AccountLanes.types'
import requests from '../AQuotes.requests'

const useGetLanesByQuote = ({
    opportunityIds, leaseType, quoteId,
}: {
    opportunityIds: number[], leaseType?: LeaseType, quoteId: number
}) => {
    const {
        isDataReady,
        data,
    } = useRequestTable<{data: AccountLanesTable[]}>({
        key: RequestKeys.getLanesByQuote,
        params: {},
        defaultValue: {
            data: {
                filters: {
                    opportunityIds,
                    leaseType,
                    quoteId,
                },
            },
            sort: {
                field: 'laneNumber',
                direction: 'asc',
            },
        },
        config: requests.getLanesByQuote,
        keepPreviousData: true,
        enabled: Boolean(quoteId) && !!opportunityIds?.length,
    })

    return {
        data: data?.data,
        isDataReady,
    }
}

export default useGetLanesByQuote
