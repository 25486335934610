import React, {
    useCallback, useState, useEffect,
} from 'react'
import isEqual from 'lodash/isEqual'
import {
    NavigationType, useNavigate,
    useBlocker,
} from 'react-router-dom'

import UnsavedChangesPrompt from '../UnsavedChangesPrompt'

const defaultProps = {
    promptText: 'Would you like to save your changes before you leave this page?',
    discard: undefined,
    positiveLabel: undefined,
    isPositiveAlert: false,
    action: undefined,
}

const HistoryBlockPrompt = ({
    when, action, promptText, discard, positiveLabel, isPositiveAlert,
}: {
    when: boolean,
    discard?: () => void,
    action?: (...a: any[]) => Promise<any> | any,
    promptText?: string,
    positiveLabel?: string,
    isPositiveAlert?: boolean,
}) => {
    const navigate = useNavigate()
    const [
        next,
        setNext,
    ] = useState<string>(null)

    const [
        actionClicked,
        setActionClicked,
    ] = useState<boolean>(false)

    const {
        proceed, state, reset,
    } = useBlocker(({
        currentLocation, nextLocation, historyAction,
    }) => {
        // it is used for browser back button since blocker is not working normally with it
        if (when && !isEqual(currentLocation, nextLocation)
            && historyAction === NavigationType.Pop) {
            setNext(nextLocation?.pathname)
            return !actionClicked
        }

        return when
    })

    const handleAction = useCallback(async () => {
        try {
            if (action) {
                await action()
            }

            setActionClicked(true)

            proceed()
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }, [
        action,
        proceed,
    ])

    const handleDiscard = useCallback(() => {
        discard()
        setActionClicked(true)
        proceed()
    }, [
        proceed,
        discard,
    ])

    // this effect should be called only for browser back button
    // when we need to omit when event with custom navigation
    useEffect(() => {
        if (actionClicked && when && next) {
            navigate(next)
        }

        return () => {
            return setActionClicked(false)
        }
    }, [
        next,
        actionClicked,
        when,
        navigate,
    ])

    return (
        <UnsavedChangesPrompt
            action={handleAction}
            discard={discard ? handleDiscard : null}
            open={state === 'blocked'}
            close={reset}
            promptText={promptText}
            positiveLabel={positiveLabel}
            isPositiveAlert={isPositiveAlert}
        />
    )
}

HistoryBlockPrompt.defaultProps = defaultProps

export default HistoryBlockPrompt
