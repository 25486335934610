import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    LaneGroupTemperatureRange, OrderStatuses, ProductType,
} from 'app/types/enums'

import {
    LongTermLeaseType, StatusLongTermLease,
} from '../longTermLease.types'
import {
    IncoTerm,
} from '../../Lanes/lanes.types'

const getFields = ({
    getAllowedValues,
    allowedProductTypes,
    status,
}: {
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
    allowedProductTypes: ProductType[],
    status: StatusLongTermLease,
}): DTOCardFormField<keyof LongTermLeaseType>[] => {
    return [
        {
            id: 'contractInfo',
            className: 'contractInfo',
            title: 'Contract Info',
            fields: [
                {
                    name: 'longTermLeaseNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'LongTermLease.contractNumber',
                },
                {
                    name: 'tempRange',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                    labelKey: 'LaneGroup.tempRange',
                },
                {
                    name: 'createdBy',
                    componentName: 'InputSingleline',
                    labelKey: 'OrderByNumberView.createdBy',
                },
            ],
        },
        {
            id: 'summary',
            className: 'summary',
            title: 'Summary',
            fields: [
                {
                    id: 'summaryColumn1',
                    fields: [
                        {
                            name: 'leaseStartTimestamp',
                            componentName: 'DateTimeZonedSelect',
                            labelKey: 'OrderBilling.leaseStartTimestamp',
                        },
                        {
                            name: 'baseLeaseDays',
                            componentName: 'InputSingleline',
                            labelKey: 'OrderBilling.baseLeaseDays',
                        },
                        {
                            name: 'leaseEndTimestamp',
                            componentName: 'InputSingleline',
                            labelKey: 'OrderBilling.leaseEndTimestamp',
                        },
                        {
                            name: 'receivedOn',
                            componentName: 'InputSingleline',
                            labelKey: 'LongTermLeaseForm.receivedOn',
                        },
                        {
                            name: 'enteredOn',
                            componentName: 'InputSingleline',
                            labelKey: 'LongTermLeaseForm.enteredOn',
                        },
                    ],
                },
                {
                    id: 'summaryColumn2',
                    fields: [
                        {
                            name: 'requiredContainers',
                            componentName: 'RequiredContainerByProductTypeList',
                            allowedProductTypes,
                            labelKey: 'Prebooking.requiredContainers',
                        },
                        {
                            name: 'pricingAccount',
                            componentName: 'AccountSelector',
                            labelKey: 'Prebooking.pricingAccount',
                        },
                        {
                            name: 'commentForBillingOps',
                            componentName: 'LongText',
                            labelKey: 'OrderByNumberView.commentForBillingOps',
                        },
                        {
                            name: 'incoTerm',
                            componentName: 'Select',
                            allowedValues: getAllowedValues(IncoTerm),
                            labelKey: 'LaneOpsInformationDto.incoTerm',
                        },
                    ],
                },
            ],
        },
        {
            id: 'dedicatedContainers',
            className: 'dedicatedContainers',
            title: 'Dedicated Containers',
            fields: [{
                name: 'id',
                componentName: 'DedicatedContainers',
                disabled: true,
                labelKey: 'OrderByNumberView.orderStatus',
            }],
        },
        {
            id: 'orderStatus',
            className: 'orderStatus',
            title: 'Order Status',
            fields: [{
                name: 'status',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(OrderStatuses),
                labelKey: 'OrderByNumberView.orderStatus',
            }],
        },
        {
            id: 'customer',
            className: 'customer',
            title: 'Customer',
            fields: [
                {
                    name: 'customerExecution',
                    componentName: 'LocationExpandedViewObjectSelector',
                    required: true,
                    labelKey: 'AccountLane.customerExecution',
                },
                {
                    name: 'customerExecutionContacts',
                    componentName: 'MultipleContactObjectSelector',
                    required: true,
                    labelKey: 'Order.customerContacts',
                },
            ],
        },
        {
            id: 'orderStatusHistory',
            className: 'orderStatusHistory',
            title: 'Order Status History',
            fields: [{
                name: 'statusTransitions',
                componentName: 'OrderStatusHistory',
                orderStatus: status,
                noLabel: true,
            }],
        },
    ]
}

export default getFields
