import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    ProductType,
} from 'app/types/enums'
import {
    OrderSubType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    PoolingFields,
} from '../pooling.types'

export default (): SkyNetTableConfig<PoolingFields> => {
    return {
        name: 'Pooling.ContainerSelection',
        tabs: [{
            name: 'Upcoming Container Selection',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'orderNumber',
                    width: '100px',
                },
                {
                    name: 'from',
                    filterField: 'from',
                    filterType: FilterType.Array,
                    width: '135px',
                },
                {
                    name: 'to',
                    filterField: 'to',
                    filterType: FilterType.Array,
                    width: '170px',
                },
                {
                    name: 'orderSubType',
                    filterField: 'orderSubType',
                    componentName: 'EnumValue',
                    filterType: FilterType.Array,
                    allowedValuesEnum: OrderSubType,
                },
                {
                    name: 'originLocationLocationName',
                    filterField: 'originLocationLocationName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'destinationLocationLocationName',
                    filterField: 'destinationLocationLocationName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'plannedPickup',
                    filterField: 'plannedPickup',
                    filterType: FilterType.DateRange,
                    componentName: 'DateTimeCell',
                },
                {
                    name: 'requiredContainers',
                    componentName: 'RequiredContainersList',
                    allowedValuesEnum: ProductType,
                    filterField: 'requiredContainersContainerModel',
                    filterType: FilterType.Array,
                    sorting: false,
                },
                {
                    name: 'containerSelectionUntilUtc',
                    componentName: 'ContainerSelectionUntil',
                    headerProps: {
                        children: 'Remaining / Overdue',
                    },
                },
            ],
        }],
    }
}
