import {
    RequestConfig,
} from 'app/types/request.types'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

type MarginsRequests = {
    [RequestKeys.marginsFilterRequest]: (params?: Record<string, any>) => RequestConfig,
    bulkEdit: ({
        data,
    }: {
        data: Record<string, any>,
    }) => RequestConfig
}

const requests: MarginsRequests = {
    [RequestKeys.marginsFilterRequest]: (params = {}) => {
        return {
            url: 'marginratecard/filter-request/all',
            method: 'POST',
            ...params,
        }
    },
    bulkEdit: ({
        data,
    }) => {
        return {
            url: 'marginratecard/bulk',
            method: 'PATCH',
            data,
        }
    },
}

export default requests
