import {
    useMemo,
} from 'react'
import {
    useTheme,
} from '@mui/material/styles'

import {
    tss,
} from 'app/tss'

const useStyles = () => {
    const theme = useTheme()

    return useMemo(() => {
        const styles: Record<string, any> = {
            cell: {
                ...theme.typography.body1,
                color: theme.palette.secondary[900],
                background: theme.palette.common.white,
                paddingLeft: '2px',
                border: {
                    left: {
                        color: theme.palette.secondary[200],
                        width: '1px',
                    },
                    right: {
                        color: theme.palette.secondary[200],
                        width: '1px',
                    },
                    bottom: {
                        color: theme.palette.secondary[200],
                        width: '1px',
                    },
                    top: {
                        color: theme.palette.secondary[200],
                        width: '1px',
                    },
                },
            },
            header: {
                ...theme.typography.h4,
                background: theme.palette.common.white,
                fontWeight: 500,
                textWrap: 'balance',
                zIndex: 0,
            },
            superHeader: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            scrolledGridContainer: {
                overflowY: 'hidden',
                width: '100%',
            },
            recalculatedContainer: {
                overflowX: 'hidden',
            },
            required: {
                background: theme.palette.primary[30],
            },
            disabled: {
                background: theme.palette.secondary[100],
                color: theme.palette.secondary[900],
            },
            hidden: {
                display: 'none',
            },
            errors: {
                background: theme.palette.warning.main,
            },
            title: {
                padding: `${theme.spacing(2)} 0`,
            },
        }

        return {
            getClasses: tss.create({
                scrolledGridContainer: styles.scrolledGridContainer,
                recalculatedContainer: styles.recalculatedContainer,
                title: styles.title,
            } as Record<string, any>),
            styles,
        }
    }, [theme])
}

export default useStyles
