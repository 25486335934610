/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useReducer,
    useEffect,
    useCallback,
    useMemo, useState,
} from 'react'
import noop from 'lodash/noop'
import createReducer from 'app/utils/createReducer'

import {
    DelayedLoadOptions,
} from 'app/hooks/useDelayedLoadOptions'

import {
    NotificationIconProps,
} from '../NotificationIcon/notificationIcon.types'

import Typeahead from './Typeahead'

export type Props = {
    name?: string,
    title?: string,
    loadOptions: (options: DelayedLoadOptions) => Promise<any>,
    labelData?: Record<string, any>,
    value?: string | number,
    valueField?: string,
    labelField?: string,
    onChange: (newVal: string | number) => void,
    classNames?: {
        options?: string,
        label?: string,
    },
    className?: string,
    isRequired?: boolean,
    multiline?: boolean,
    disabled?: boolean,
    variant?: string,
    validated?: boolean,
    notificationIconProps: NotificationIconProps,
    errors?: string[],
    labelTooltip?: JSX.Element,
    placeholder?: string,
    noneOption?: boolean,
}
export const defaultProps = {
    valueField: 'id',
    onChange: noop,
    value: '',
    labelField: '',
    labelData: undefined,
    name: '',
    title: '',
    classNames: {},
    className: '',
    isRequired: false,
    multiline: false,
    disabled: false,
    variant: 'outlined',
    errors: undefined,
    validated: undefined,
    notificationIconProps: undefined,
    labelTooltip: undefined,
    placeholder: undefined,
    noneOption: true,
}

const OPEN_OPTIONS = 'OPEN_OPTIONS'
const CLOSE_OPTIONS = 'CLOSE_OPTIONS'
const SET_SEARCH_PHRASE = 'SET_SEARCH_PHRASE'

export const getLabelValue = (option, labelField) => {
    if (!option) {
        return null
    }
    if (Array.isArray(labelField)) {
        return labelField.map((item) => {
            return option[item]
        }).join(' | ')
    }
    return option[labelField]
}

const reducer = createReducer({
    [SET_SEARCH_PHRASE]: (state, {
        searchPhrase,
    }) => {
        return {
            ...state,
            searchPhrase,
        }
    },
    [OPEN_OPTIONS]: (state) => {
        return {
            ...state,
            optionsVisible: true,
            searchPhrase: '',
        }
    },
    [CLOSE_OPTIONS]: (state) => {
        return {
            ...state,
            optionsVisible: false,
            searchPhrase: '',
        }
    },
})

const TypeaheadObjectContainer = (props: Props) => {
    const {
        loadOptions,
        labelData,
        labelField,
        value,
    } = props

    const [
        {
            optionsVisible,
            searchPhrase,
        },
        dispatch,
    ]: [
        { optionsVisible: boolean, searchPhrase: string },
        (args: { type: string, searchPhrase?: string }) => void,
    ] = useReducer(reducer, {
        optionsVisible: false,
        searchPhrase: '',
    })

    const [
        options,
        setOptions,
    ] = useState()

    const label: {
        value: string,
        iconPath: string,
    } = useMemo(() => {
        return value ? {
            value: getLabelValue(labelData || value, labelField),
            iconPath: labelData?.iconPath,
        } : undefined
    }, [
        labelData,
        labelField,
        value,
    ])

    const openOptions = useCallback((): void => {
        dispatch({
            type: OPEN_OPTIONS,
        })
    }, [dispatch])

    const closeOptions = useCallback((): void => {
        dispatch({
            type: CLOSE_OPTIONS,
        })
    }, [dispatch])

    const setSearchPhrase = useCallback((newSearchPhrase: string): void => {
        dispatch({
            type: SET_SEARCH_PHRASE,
            searchPhrase: newSearchPhrase,
        })
    }, [dispatch])

    const dataNew = useCallback((phrase): void => {
        loadOptions({
            phrase,
        })
            .then((resp) => {
                setOptions(resp)
            })
    }, [loadOptions])

    useEffect(() => {
        if (searchPhrase) {
            return dataNew(searchPhrase)
        }

        return dataNew('')
    }, [
        dataNew,
        searchPhrase,
    ])

    return (
        <Typeahead
            {...props}
            labelData={label}
            setSearchPhrase={setSearchPhrase}
            searchPhrase={searchPhrase}
            options={options}
            closeOptions={closeOptions}
            optionsVisible={optionsVisible}
            openOptions={openOptions}
            getLabelValue={getLabelValue}
            labelField={labelField}
        />
    )
}

TypeaheadObjectContainer.defaultProps = defaultProps

export default TypeaheadObjectContainer
