import React, {
    useMemo,
} from 'react'

import useHasPermission from 'app/hooks/useHasPermission'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import DTOSpec from 'app/services/dtoSpec'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'

import useFinanceRoutes from '../hooks/useFinanceRoutes'

import Overview from './Overview'
import ChangeData from './ChangeData'
import Create from './Create'
import getTableConfig from './FxRates.config'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const FxRates = () => {
    const {
        FxRates: FxRateRoutes,
    } = useFinanceRoutes()
    const fxrateCreate = useHasPermission(['fxrate_create'])
    const fxrateUpdate = useHasPermission(['fxrate_update'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                url: FxRateRoutes.Overview.route,
                label: 'Overview',
                Component: Overview,
            },
            permission: true,
        },
        {
            tab: {
                url: FxRateRoutes.Update.route,
                label: 'Data Update',
                Component: ChangeData,
            },
            permission: fxrateUpdate,
        },
    ])

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            actionComponents={{
                Create: fxrateCreate ? Create : undefined,
            }}
            name={DTOSpec.FxRate.Update.refresh}
            sidebarTabs={tabs}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>
    )
}

export default FxRates
