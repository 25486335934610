import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'

import {
    DailyOpsFields,
} from '../dailyOps.types'

import deliveryViaRoadFields from './deliveryViaRoad.fields'

export default (): SkyNetTableConfig<DailyOpsFields> => {
    return {
        name: 'DeliveryViaRoadConfirm',
        tabs: [{
            name: 'Delivery: Confirmation',
            url: CommonRoutes.ALL,
            fields: deliveryViaRoadFields,
        }],
    }
}
