import React, {
    useCallback,
    useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import FormWithControls from 'app/shared-components/FormWithControls'
import {
    monthYearFullMask,
} from 'app/utils/date'

import requests from '../../Forecast.requests'
import {
    getKeyByMonthYear,
} from '../../Forecast.utils'

import fields from './CurrentMonthForecastUpdate.fields'
import useGetCurrentMonthForecast from './hooks/useGetCurrentMonthForecast'

const CurrentMonthForecastUpdate = ({
    id,
}: {id: number}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetCurrentMonthForecast(id)

    const formValues = useMemo(() => {
        return {
            currentMonth: getKeyByMonthYear({
                month: data?.month - 1,
                year: data?.year,
            }, monthYearFullMask)[1],
            submittedValue: data?.units,
        }
    }, [data])

    const modifyDataBeforeSend = useCallback(({
        units,
    }) => {
        return {
            units,
        }
    }, [])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <FormWithControls
                value={formValues}
                name="CurrentMonthForecastUpdate"
                requestParams={requests.updateCurrentMonth({
                    id,
                })}
                fields={fields}
                onSuccess={invalidate}
                onCancel={invalidate}
                modifyDataBeforeSend={modifyDataBeforeSend}
                customLabelSave="Update"
                exists
            />
        </StatusHandler>
    )
}

export default CurrentMonthForecastUpdate
