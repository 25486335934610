import {
    RequestConfig,
} from 'app/types/request.types'

type ContainerRequests = {
    name: string,
    get: ({
        serialNumber: string,
    }) => RequestConfig,
    update: ({
        serialNumber,
    }: { serialNumber: string }) => RequestConfig,
    statusChange: ({
        serialNumber,
    }: { serialNumber: string }) => RequestConfig,
    batchCreate: () => RequestConfig,
    getLastGeolocation: ({
        serialNumber: string,
    }) => RequestConfig,
    getDamageByContainer: ({
        params,
        serialNumber,
    }) => RequestConfig,
    damageByContainerCountRequest: (serialNumber: string) => {
        customUrl: string,
    },
    getAllowedContainerStatusTransitions: ({
        serialNumber,
    }: {
        serialNumber: string,
    }) => RequestConfig
}

const containerRequests: ContainerRequests = {
    name: 'Container',
    get: ({
        serialNumber,
    }) => {
        return {
            url: `container?serialNumber=${serialNumber}`,
        }
    },
    update: ({
        serialNumber,
    }) => {
        return {
            url: `container/${serialNumber}/data`,
            method: 'PATCH',
        }
    },
    statusChange: ({
        serialNumber,
    }) => {
        return {
            method: 'PATCH',
            url: `container/${serialNumber}/status-change`,
        }
    },
    batchCreate: () => {
        return {
            method: 'POST',
            url: 'container/batch-creation',
        }
    },
    getLastGeolocation: ({
        serialNumber,
    }) => {
        return {
            method: 'GET',
            url: `container/${serialNumber}/last-geolocation`,
        }
    },
    getDamageByContainer: ({
        params,
        serialNumber,
    }) => {
        return {
            url: `container/${serialNumber}/damage/filter-request/all`,
            method: 'POST',
            ...params,
        }
    },
    damageByContainerCountRequest: (serialNumber) => {
        return {
            customUrl: `container/${serialNumber}/damage/filter-request/all`,
        }
    },
    getAllowedContainerStatusTransitions: ({
        serialNumber,
    }) => {
        return {
            url: `container/${serialNumber}/status-transition/allowed`,
            method: 'GET',
        }
    },
}

export default containerRequests
