import React, {
    useCallback,
    useMemo,
} from 'react'
import Typography from '@mui/material/Typography'

import useEnumValues from 'app/hooks/useEnumValues'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import RadioButtonTile from 'app/shared-components/RadioButtonTile'
import useContainerManagementRoutes from 'app/Apps/ContainerManagement/hooks/useContainerManagementRoutes'
import {
    ServiceCenterFormType,
} from 'app/Apps/ContainerManagement/ServiceCenters/serviceCenters.types'

import getServiceCenterMap from '../getServiceCenterMap'
import {
    LocationSelectionDataType, PositioningSelection, PositioningSetupType,
} from '../orderConfirmation.types'
import getFields from './locationSelection.fields'
import useStyles from './locationSelection.style'

const LocationSelection = ({
    positioningSetup,
    locationSelectionData,
    setLocationSelectionData,
    isDeliveryRoad,
    serviceCenter,
}: {
    positioningSetup: PositioningSetupType,
    locationSelectionData: LocationSelectionDataType,
    setLocationSelectionData: (val: LocationSelectionDataType) => void,
    isDeliveryRoad: boolean,
    serviceCenter: ServiceCenterFormType,
}) => {
    const {
        classes,
    } = useStyles()

    const {
        ServiceCenters: ServiceCentersRoutes,
    } = useContainerManagementRoutes()
    const getAllowedValues = useEnumValues()

    const serviceCenterMap = useMemo(() => {
        return getServiceCenterMap(positioningSetup)
    }, [positioningSetup])

    const onSelectOriginServiceCenter = useCallback(
        (positioningSelection: PositioningSelection) => {
            setLocationSelectionData({
                ...serviceCenterMap.origin[positioningSelection].data,
                originServiceCenterSelection: positioningSelection,
            })
        }, [
            serviceCenterMap.origin,
            setLocationSelectionData,
        ],
    )

    const fields = getFields({
        originServiceCenterId: locationSelectionData.originServiceCenter?.id,
        originServiceCenterSelection: locationSelectionData.originServiceCenterSelection,
        isDeliveryRoad,
        getAllowedValues,
        serviceCenter,
        isOtherByDefault: positioningSetup.originServiceCenterSelection
            === PositioningSelection.OTHER,
        serviceCenterNumberLink: serviceCenter?.id ? generateSkyNetLink({
            domainPath: ServiceCentersRoutes.Overview.path,
            params: {
                key: serviceCenter?.id,
            },
        }) : null,
    })

    return (
        <>
            <div>
                <Typography className={classes.title}>Confirm Order</Typography>
                <div className={classes.wrapperTiles}>
                    <RadioButtonTile
                        title="Origin Service Center"
                        onClick={() => {
                            onSelectOriginServiceCenter(PositioningSelection.DEFAULT)
                        }}
                        selected={
                            locationSelectionData.originServiceCenterSelection
                            === PositioningSelection.DEFAULT
                        }
                    />
                    {isDeliveryRoad && (
                        <>
                            <RadioButtonTile
                                title="Backup Service Center"
                                onClick={() => {
                                    onSelectOriginServiceCenter(PositioningSelection.BACKUP)
                                }}
                                selected={
                                    locationSelectionData.originServiceCenterSelection
                                    === PositioningSelection.BACKUP
                                }
                            />
                            <RadioButtonTile
                                title="Other"
                                onClick={() => {
                                    onSelectOriginServiceCenter(PositioningSelection.OTHER)
                                }}
                                selected={
                                    locationSelectionData.originServiceCenterSelection
                                    === PositioningSelection.OTHER
                                }
                            />
                        </>
                    )}
                </div>
                <DomainObjectForm
                    value={locationSelectionData}
                    onChange={setLocationSelectionData}
                    fields={fields}
                    name="OrderConfirmation"
                />
            </div>
        </>
    )
}

export default LocationSelection
