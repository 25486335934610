import React from 'react'
import {
    RouterProvider,
} from 'react-router-dom'
import {
    Provider,
} from 'react-redux'
import {
    DndProvider,
} from 'react-dnd'
import {
    HTML5Backend,
} from 'react-dnd-html5-backend'
import 'moment/locale/en-gb'
import {
    AdapterMoment,
} from '@mui/x-date-pickers/AdapterMoment'
import {
    LocalizationProvider,
} from '@mui/x-date-pickers/LocalizationProvider'

import {
    QueryClientProvider,
    QueryClient,
} from '@tanstack/react-query'

import Auth from 'app/Auth'

import ThemeProvider from './ThemeProvider'
import store from './store'
import skyNetRoutes from './SkyNetRoutes/skyNetRoutes'

const queryClient = new QueryClient()
const App = () => {
    return (
        <DndProvider backend={HTML5Backend}>
            <Provider store={store}>
                <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="en-gb"
                >
                    <QueryClientProvider client={queryClient}>
                        <Auth>
                            <ThemeProvider>
                                <React.StrictMode>
                                    <RouterProvider router={skyNetRoutes} />
                                </React.StrictMode>
                            </ThemeProvider>
                        </Auth>
                    </QueryClientProvider>
                </LocalizationProvider>
            </Provider>
        </DndProvider>
    )
}

export default App
