import useRequestTable from 'app/hooks/useRequestTable'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    TableResponse,
} from 'app/types/request.types'
import {
    LanesQuotation,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import requests from '../../AQuotes.requests'

export default (id: number) => {
    const {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    } = useRequestTable<TableResponse<LanesQuotation>>({
        key: RequestKeys.getLaneGroupsPricingAssessment,
        params: {
            id,
        },
        config: requests.getLaneGroupsPricingAssignment,
        keepPreviousData: true,
        enabled: Boolean(id),
    })

    return {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    }
}
