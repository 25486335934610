import {
    useState, useEffect,
} from 'react'

type ExpandCollapseState = {
    externalState?: boolean
    onStateChange?: (isExpanded: boolean) => void
}

export default function useExpandCollapseState({
    externalState,
    onStateChange,
}: ExpandCollapseState = {}) {
    const [
        isExpanded,
        setIsExpanded,
    ] = useState<boolean>(true)

    useEffect(() => {
        if (externalState !== undefined) {
            setIsExpanded(externalState)
        }
    }, [externalState])

    const toggleExpand = () => {
        const newState = !isExpanded

        setIsExpanded(newState)
        onStateChange?.(newState)
    }

    return {
        isExpanded,
        toggleExpand,
    }
}
