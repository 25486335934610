import {
    tss,
} from 'app/tss'

export default tss.withParams<{isExpanded: boolean}>().create(({
    theme,
    isExpanded,
}) => {
    return {
        root: {
            display: 'flex',
            height: '100%',
            width: '100%',
            '@media print': {
                display: 'none',
            },
        },
        rootResponsive: {
            transition: 'width 0.2s ease-in',
            width: 'auto',
            maxWidth: '30%',
            minWidth: isExpanded ? '20%' : 0,
            '@media print': {
                display: 'none',
            },
        },
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            height: 'min-content',
            minHeight: 300,
            boxSizing: 'border-box',
            border: 'none',
            position: 'absolute',
        },
        contentWrapper: {
            height: '100%',
            transition: 'padding-right 0.2s ease-in',
            position: 'relative',
            width: isExpanded ? 'calc(100% - 11px) !important' : 'auto',
        },
        tableHeaderWrapper: {
            position: 'relative',
            '&&': {
                position: 'relative',
            },
        },
        tableContent: {
            boxShadow: 'none !important',
            '& .MuiCheckbox-root': {
                marginLeft: theme.spacing(2),
            },
        },
        wrapper: {
            whiteSpace: 'nowrap',
            height: isExpanded ? '100%' : 'auto',
        },
        tableWrapper: {
            height: '100%',
        },
        expandCollapseButtonContainer: {
            height: 'calc(100% - 37px)',
            marginTop: 36,
            backgroundColor: theme.palette.secondary[50],
            borderTop: `1px solid ${theme.palette.secondary[300]}`,
            borderLeft: !isExpanded ? `1px solid ${theme.palette.secondary[300]}` : 0,
        },
        expandCollapseButton: {
            backgroundColor: theme.palette.common.white,
            padding: '0 !important',
        },
        expandCollapseContainer: {
            borderBottom: `1px solid ${theme.palette.secondary[300]}`,
        },
    }
})
