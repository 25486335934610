import React, {
    useCallback,
} from 'react'
import {
    useLocation,
    useNavigate,
} from 'react-router-dom'
import Link from '@mui/material/Link'

import {
    SkyNetDomainRoutes,
} from 'app/shared-components/SkyNetDomain'

import useStyles from '../common.styles'

const defaultProps = {
    onClick: undefined,
}

const BulkEditIcon = () => {
    const {
        classes,
    } = useStyles()

    const {
        pathname,
    } = useLocation()

    const navigate = useNavigate()

    const redirectToBulkEdit = useCallback(() => {
        navigate(`../${SkyNetDomainRoutes.BULK_EDIT}`, {
            state: {
                from: pathname,
            },
        })
    }, [
        navigate,
        pathname,
    ])

    return (
        <Link
            className={classes.link}
            component="button"
            color="secondary"
            onClick={redirectToBulkEdit}
        >
            <img
                src="assets/images/icons/bulk_edit.svg"
                alt="Bulk Edit"
            />
        </Link>
    )
}

BulkEditIcon.defaultProps = defaultProps

export default BulkEditIcon
