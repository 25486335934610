import React, {
    useCallback,
    useMemo,
} from 'react'
import clsx from 'clsx'
import noop from 'lodash/noop'

import IntegerInput from 'app/shared-components/IntegerInput'
import {
    ContainerCode,
} from 'app/types/enums'

import {
    ContainerTypeItem,
} from '../../co2simulations.types'

import useStyles from './ContainerTypeSelectionItem.style'

type Props = {
    value: ContainerTypeItem,
    onChange: (val: ContainerTypeItem, isFormChange?: boolean,) => void,
    selectedContainerTypeCode?: ContainerCode,
}

const defaultProps = {
    selectedContainerTypeCode: null,
}

const ContainerTypeSelectionItem = ({
    value,
    selectedContainerTypeCode,
    onChange,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const {
        containerCode,
        title,
        imgSrc,
    } = value

    const isSelected: boolean = useMemo(() => {
        return selectedContainerTypeCode === containerCode
    }, [
        selectedContainerTypeCode,
        containerCode,
    ])

    const unselected: boolean = useMemo(() => {
        return selectedContainerTypeCode && !isSelected
    }, [
        selectedContainerTypeCode,
        isSelected,
    ])

    const handleClick = useCallback((): void => {
        onChange({
            ...value,
            containerAmount: 1,
        })
    }, [
        onChange,
        value,
    ])

    const setContainerAmount = (newValue: number): void => {
        onChange({
            ...value,
            containerAmount: newValue,
        }, true)
    }

    const handleFormClick = useCallback((event: React.SyntheticEvent<EventTarget>): void => {
        if (isSelected) {
            event.stopPropagation()
        }
    }, [isSelected])

    return (
        <li
            key={`Container-selection-item-${containerCode}`}
            data-testid={`container-selection-item-${containerCode}`}
            className={classes.selectionItemContainer}
        >
            <div
                className={classes.selectionItemBtn}
                onClick={handleClick}
                onKeyPress={noop}
                data-testid="selection-item-btn"
            >
                <div
                    className={
                        clsx(
                            classes.selectionItem,
                            unselected && classes.unselectedViewItem,
                        )
                    }
                >
                    <div className={classes.selectionItemContent}>
                        <div
                            className={
                                clsx(
                                    classes.itemTitle,
                                    unselected && classes.unselectedItemTitle,
                                )
                            }
                        >
                            {title}
                        </div>
                        <div
                            className={classes.selectionItemImageContainer}
                        >
                            <img
                                className={classes.selectionItemImage}
                                alt={title}
                                src={imgSrc}
                            />
                        </div>
                        { isSelected && (
                            <div
                                className={classes.containerAmount}
                                data-testid="container-amount"
                                onClick={handleFormClick}
                                onKeyPress={noop}
                            >
                                <div className={classes.noWrapText}>
                                    Quantity:
                                </div>
                                <IntegerInput
                                    value={value.containerAmount}
                                    name="containerAmount"
                                    onChange={setContainerAmount}
                                    className={classes.inputAmount}
                                    min={1}
                                />
                            </div>
                        )}
                    </div>
                    <div
                        className={
                            clsx(
                                classes.footer,
                                unselected && classes.unselectedViewItemFooter,
                            )
                        }
                    />
                </div>
            </div>
        </li>
    )
}

ContainerTypeSelectionItem.defaultProps = defaultProps

export default ContainerTypeSelectionItem
