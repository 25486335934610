import omit from 'lodash/omit'

import {
    MarginsTable, MarginsEditable,
} from './Margins.types'

// eslint-disable-next-line import/prefer-default-export
export const prepareMarginsToBulkEdit = (items: MarginsTable[] = []): MarginsEditable[] => {
    return items.map((i) => {
        return omit(i, [
            'baseLeaseCategory',
            'regionSecondLevel',
        ])
    })
}
