import {
    useMemo,
} from 'react'
import isEmpty from 'lodash/isEmpty'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    TemperatureRangeAll,
    ProductType,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'
import config from 'app/shared-components/Fees/Fee.request'

import {
    ProductTypeTypes,
} from '../ProductTypeSelector.types'

const reduceProduct = (values, allowedValues) => {
    return values?.reduce((acc, element) => {
        return {
            ...acc,
            [element.productType]: allowedValues[element.productType],
        }
    }, {})
}

export default (tempRange: TemperatureRangeAll) => {
    const getAllowedValues = useEnumValues()
    const allowedValuesProductType = getAllowedValues(ProductType)
    const {
        data: productTypes,
        isDataReady,
    } = useRequest<ProductTypeTypes[]>({
        key: RequestKeys.getContractBasisProductType,
        requestFunc: RequestFn.getEntity(config.ProductType.requestFn),
        keepPreviousData: true,
        staleTime: Infinity,
    })

    const allowedValues = useMemo(() => {
        if (!isDataReady) return {}
        if (!tempRange) {
            return reduceProduct(productTypes, allowedValuesProductType)
        }
        const productTypesFiltered = productTypes.filter((containers) => {
            return containers.temperatureRange === tempRange
        })

        if (isEmpty(productTypesFiltered)) {
            return {}
        }
        return reduceProduct(productTypesFiltered, allowedValuesProductType)
    }, [
        allowedValuesProductType,
        productTypes,
        tempRange,
        isDataReady,
    ])

    return allowedValues
}
