import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    LaneFeesStatus,
} from 'app/types/enums'
import {
    AwardRejectLaneFeesTable,
} from './AwardRejectLaneFees.types'

const columns = ({
    l,
}: {
    l: (l: string) => string,
}): RichTableColumns<keyof AwardRejectLaneFeesTable>[] => {
    return [
        {
            id: 'laneFeesNumber',
            headerProps: {
                children: l('LaneFees.laneFeesNumber'),
            },
            mapCellProps: 'laneFeesNumber',
        },
        {
            id: 'laneGroupNumber',
            mapCellProps: 'laneGroupNumber',
            headerProps: {
                children: 'Lane Group',
            },
        },
        {
            id: 'laneFeesStatus',
            headerProps: {
                children: l('LaneFeesUpdateDto.laneFeesStatus'),
            },
            filterField: 'laneFeesStatus',
            filterType: FilterType.Array,
            mapCellProps: 'laneFeesStatus',
            componentName: 'EnumValue',
            allowedValuesEnum: LaneFeesStatus,
        },
        {
            id: 'validFrom',
            headerProps: {
                children: l('LaneFees.validFrom'),
            },
            filterField: 'validFrom',
            filterType: FilterType.Array,
            mapCellProps: 'validFrom',
            componentName: 'DateTimeCell',
        },
        {
            id: 'validTo',
            headerProps: {
                children: l('LaneFees.validTo'),
            },
            filterField: 'validTo',
            filterType: FilterType.Array,
            mapCellProps: 'validTo',
            componentName: 'DateTimeCell',
        },
        {
            id: 'rentalDays',
            headerProps: {
                children: l('LaneFeesUpdateDto.rentalDays'),
            },
            mapCellProps: 'rentalDays',
        },
        {
            id: 'logisticsDays',
            headerProps: {
                children: l('LaneFeesUpdateDto.logisticsDays'),
            },
            mapCellProps: 'logisticsDays',
        },
        {
            id: 'changedBy',
            headerProps: {
                children: l('AccountLane.changedBy'),
            },
            mapCellProps: 'changedBy',
        },
    ]
}

export default columns
