import {
    useContext, createContext,
} from 'react'
import {
    AQuote,
} from '../AQuotes.types'

export type AQuoteContextType = AQuote & {
    quoteId: number,
}

export const AQuoteContext = createContext<AQuoteContextType>(null)

const useAQuoteContext = () => {
    return useContext<AQuoteContextType>(AQuoteContext)
}

export default useAQuoteContext
