import isNil from 'lodash/isNil'

import {
    DTOCardFormField, Validate,
} from 'app/types/form.types'
import {
    LocationType, RegionSecondLevel,
} from 'app/types/enums'

import {
    LeaseTypePossibilityStatus, ProductTypeCQuote, RateCardsData, TemperatureRangeCQuote,
} from 'app/Apps/AccountManagement/CQuotes/CQuotes.types'

import {
    CQuoteLaneDetails,
} from '../../CQuoteInformationContext'

const getValue = (val: boolean) => {
    if (!isNil(val)) {
        return val ? 'Available' : 'Not Available'
    }

    return null
}

const getFields = ({
    getAllowedValues,
    validateEmbargoOrigin,
    validateEmbargoDestination,
    rateCardsData,
    classes,
    nonEditable,
}: {
    getAllowedValues: (enumType: any) => Record<string, string>,
    validateEmbargoOrigin: Validate,
    validateEmbargoDestination: Validate,
    rateCardsData: RateCardsData,
    classes: Record<string, string>,
    nonEditable: boolean,
}): DTOCardFormField<keyof (CQuoteLaneDetails & RateCardsData)>[] => {
    return [
        {
            title: 'General',
            className: 'general',
            id: 'general',
            fields: [{
                id: 'general-column1',
                fields: [
                    {
                        name: 'contractBasisAccount',
                        required: true,
                        componentName: 'AccountSelector',
                        labelKey: 'AccountLane.pricingAccount',
                        includeFilters: {
                            isStrategicAccount: [false],
                        },
                    },
                    {
                        name: 'temperatureRange',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(TemperatureRangeCQuote),
                        useDropdown: true,
                        disabled: nonEditable,
                        required: true,
                        labelKey: 'Opportunities.temperatureRange',
                    },
                    {
                        name: 'productType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(ProductTypeCQuote),
                        useDropdown: true,
                        disabled: true,
                        labelKey: 'Fee.productType',
                    },
                    {
                        name: 'expPalletsYear',
                        required: true,
                        componentName: 'IntegerInput',
                        labelKey: 'LaneBasicView.expPalletsYear',
                        min: 1,
                    },
                ],
            }],
        },
        {
            title: 'Regional Service',
            className: 'regionalService',
            id: 'regionalService',
            fields: [
                {
                    id: 'regional-column1',
                    fields: [
                        {
                            name: 'originAirport',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.AIRPORT,
                            required: true,
                            labelKey: 'AccountQuotes.originIATA',
                            disabled: nonEditable,
                        },
                        {
                            name: 'originCountry',
                            componentName: 'CountryDataSelector',
                            disabled: true,
                            validate: validateEmbargoOrigin,
                            labelKey: 'Prebooking.originCountry',
                        },
                        {
                            name: 'originRegion',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(RegionSecondLevel),
                            disabled: true,
                            labelKey: 'AccountQuotes.originRegionL2',
                        },
                        {
                            name: 'destinationAirport',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.AIRPORT,
                            required: true,
                            labelKey: 'AccountQuotes.destinationIATA',
                            disabled: nonEditable,
                        },
                        {
                            name: 'destinationCountry',
                            componentName: 'CountryDataSelector',
                            disabled: true,
                            validate: validateEmbargoDestination,
                            labelKey: 'Prebooking.destinationCountry',
                        },
                        {
                            name: 'destinationRegion',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(RegionSecondLevel),
                            disabled: true,
                            labelKey: 'AccountQuotes.destinationRegionL2',
                        },
                    ],
                },
                {
                    id: 'regional-column2',
                    fields: [
                        {
                            name: 'allowedLeaseType',
                            componentName: 'InputSingleline',
                            value: getAllowedValues(
                                LeaseTypePossibilityStatus,
                            )[rateCardsData?.leaseTypePossibilityStatus],
                            disabled: true,
                            classNames: {
                                text: rateCardsData?.leaseTypePossibilityStatus
                                === LeaseTypePossibilityStatus.DIRECT_LEASE_POSSIBLE
                                    ? classes.green
                                    : classes.red,
                            },
                            labelKey: 'AccountQuotes.allowedLeaseType',
                        },
                        {
                            name: 'originPositioningFtl',
                            componentName: 'InputSingleline',
                            value: getValue(rateCardsData?.originPositioningFtl),
                            disabled: true,
                            classNames: {
                                text: rateCardsData?.originPositioningFtl && classes.green,
                            },
                            labelKey: 'AccountQuotes.originDeliveryFTL',
                        },
                        {
                            name: 'originTruckCapacity',
                            componentName: 'InputSingleline',
                            value: rateCardsData?.originTruckCapacity,
                            disabled: true,
                            labelKey: 'AccountQuotes.palletsPerTruck',
                        },
                        {
                            name: 'originPositioningLtl',
                            componentName: 'InputSingleline',
                            value: getValue(rateCardsData?.originPositioningLtl),
                            disabled: true,
                            classNames: {
                                text: rateCardsData?.originPositioningLtl && classes.green,
                            },
                            labelKey: 'AccountQuotes.originDeliveryLTL',
                        },
                        {
                            name: 'destinationCollectionLtl',
                            componentName: 'InputSingleline',
                            value: getValue(rateCardsData?.destinationCollectionLtl),
                            disabled: true,
                            classNames: {
                                text: rateCardsData?.destinationCollectionLtl && classes.green,
                            },
                            labelKey: 'AccountQuotes.destinationCollectionLTL',
                        },
                    ],
                },
            ],
        },
    ]
}

export default getFields
