import {
    Dispatch, SetStateAction,
} from 'react'
import isNumber from 'lodash/isNumber'
import isArray from 'lodash/isArray'
import isNaN from 'lodash/isNaN'
import isEmpty from 'lodash/isEmpty'
import isBoolean from 'lodash/isBoolean'

import {
    Validate,
} from 'app/types/form.types'

import {
    isRowNotEmpty,
} from '../hooks/useExcludeEmptyRows'

export const validateRequired = (name: string, required: boolean): undefined | Validate => {
    if (!required) return undefined
    return (value: Record<string, any>) => {
        if (!isRowNotEmpty(value)) return undefined

        if (isNumber(value[name])) {
            return isNaN(value[name]) ? [`${name} is required.`] : undefined
        }

        if (isBoolean(value[name])) {
            return undefined
        }

        if (isArray(value[name])) {
            return value[name].length === 0 ? [`${name} is required.`] : undefined
        }
        return isEmpty(value[name]) ? [`${name} is required.`] : undefined
    }
}

export const compileValidations = ({
    name, validate, required,
}: {
    name: string,
    validate: Validate | Validate[],
    required: boolean,
}): Validate[] | [] => {
    const validateRequiredFn = validateRequired(name, required)

    if (!validate) return validateRequiredFn ? [validateRequiredFn] : []

    if (isArray(validate)) {
        return validateRequiredFn ? [
            ...validate as Validate[],
            validateRequiredFn,
        ] : validate as Validate[]
    }

    return validateRequiredFn ? [
        validate as Validate,
        validateRequiredFn,
    ] : [validate] as Validate[]
}

export const getValidated = ({
    name, validate, required, valid, setValid, validateRow = isRowNotEmpty,
}: {
    name: string,
    validate: Validate | Validate[],
    required: boolean,
    valid: boolean,
    setValid: Dispatch<SetStateAction<boolean>>,
    validateRow: (item: Record<string, any>) => boolean,
}) => {
    const v = compileValidations({
        name, validate, required,
    })

    return (value) => {
        if (!validateRow(value)) return undefined

        let errors

        for (let i = 0; i < v.length; i++) {
            const result = v[i](value)

            if (result && Array.isArray(result)) {
                errors = errors?.length ? [
                    ...errors || [],
                    ...result,
                ] : result
            }
        }

        if (errors && valid) {
            setValid(false)
        }

        return errors
    }
}

export default getValidated
