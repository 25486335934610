import React, {
    useMemo,
} from 'react'
import isEmpty from 'lodash/isEmpty'

import CollapsibleTable from 'app/shared-components/CollapsibleTable'
import useLabel from 'app/hooks/useLabel'
import Card from 'app/shared-components/Card'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useRequestTable from 'app/hooks/useRequestTable'
import {
    TableResponse,
} from 'app/types/request.types'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import requests from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.requests'
import useStyles from 'app/tss/collapsibleTable.styles'
import useAccountManagementRoutes from 'app/Apps/AccountManagement/hooks/useAccountManagementRoutes'
import {
    LanesQuotation,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import AwardRejectLane from './AwardRejectLane'
import getColumns from './AwardRejectLanes.columns'

export default function AwardRejectLanes({
    filters,
    renderLaneFees,
}: Readonly<{
    filters: Record<'aQuoteId', number> | Record<'cQuoteId', number>,
    renderLaneFees?: (data?: LanesQuotation[]) => JSX.Element,
}>) {
    const l = useLabel()
    const {
        classes,
    } = useStyles()

    const requestsHandlers = useRequestTable<TableResponse<LanesQuotation>>({
        key: RequestKeys.lanesFilterRequestAwardReject,
        params: {
            data: {
                filters,
            },
        },
        defaultValue: {},
        config: requests[RequestKeys.lanesFilterRequestAwardReject],
        keepPreviousData: true,
        enabled: !isEmpty(filters),
    })

    const {
        Lanes: LanesRoutes,
    } = useAccountManagementRoutes()

    const laneLinkConfig = useMemo(() => {
        return {
            path: LanesRoutes.Overview.path,
            params: {
                key: 'id',
            },
        }
    }, []) // eslint-disable-line

    const columns = useMemo(() => {
        return getColumns({
            l,
            laneLinkConfig,
        })
    }, [
        l,
        laneLinkConfig,
    ])

    const actionComponents = useMemo(() => {
        return {
            Update: AwardRejectLane,
        }
    }, [])

    return (
        <>
            <div
                data-testid="LanesToAwardRejectTable"
                className={classes.root}
            >
                <Card
                    fullHeight
                    contentClass={classes.cardContent}
                    data-testid="LanesTable-card"
                >
                    <CollapsibleTable
                        title="Lanes"
                        domainName={requests.domainName}
                        configName="LanesToAwardReject"
                        columns={columns}
                        preDefinedFilters={filters}
                        customUrl={requests[RequestKeys.lanesFilterRequestAwardReject]().url}
                        requestHandlers={requestsHandlers}
                        actionComponents={actionComponents}
                        createEnabled={false}
                        tableControls={[TableControlTypes.Search]}
                    />
                </Card>
            </div>
            {renderLaneFees
                ? renderLaneFees(requestsHandlers?.data?.data as LanesQuotation[])
                : null}
        </>
    )
}
