import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requestApiUrl from 'app/hooks/useRequest/utils/requestApiUrl'
import {
    dateToStr,
} from 'app/utils/date'

import requestConfig from '../simulation.request'
import {
    SimulationForm, SimulationTempResult,
} from '../simulation.types'
import getFilteredSteps from '../utils/getFilteredSteps'

const useGetTemperatureSimulation = ({
    selectedContainer,
    simulationSteps,
    analyseTemperature,
}: SimulationForm,
isDataChange: boolean) => {
    const {
        preconditionTemperature,
        useCustomTempRange,
        customLowerTempRange,
        customUpperTempRange,
    } = analyseTemperature
    const steps = getFilteredSteps(simulationSteps)
        .map(({
            ambientTemperature,
            palletTemperature,
            amountCoolingGel,
            palletWeight,
            durationHours,
            durationMinutes,
            palletLoaded,
        }) => {
            return {
                ambientTemperature,
                amountCoolingGel,
                palletTemperature,
                palletWeight,
                intervalLengthHours: durationHours,
                intervalLengthMinutes: durationMinutes,
                palletLoaded,
            }
        })
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<SimulationTempResult[]>({
        key: RequestKeys.getTemperatureSimulation,
        params: {
            productType: selectedContainer?.productType,
            amountCoolingGel: selectedContainer?.amountCoolingGel,
            temperatureRange: selectedContainer?.tempRange,
            simulationSteps: steps,
            preconditionTemperature,
            ...useCustomTempRange ? {
                customLowerTempRange,
                customUpperTempRange,
            } : {},
            startTimestamp: dateToStr(new Date()),
        },
        requestFunc: RequestFn.getEntity(
            requestConfig.TemperatureSimulation.requestFn,
            (promise) => {
                return promise.then((resp) => {
                    return resp.data.simulationData
                })
            },
            requestApiUrl,
        ),
        enabled: Boolean(selectedContainer && steps.length && !isDataChange),
        keepPreviousData: false,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useGetTemperatureSimulation
