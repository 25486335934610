import {
    tss,
} from 'app/tss'

export default tss.create(({
    theme,
}) => {
    return {
        cardWrapper: {
            marginTop: theme.spacing(2),
        },
        cardContent: {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
        },
        cardTitle: {
            padding: `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
        },
        richTableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            top: 0,
        },
        richTableContent: {
            boxShadow: 'none !important',
            maxHeight: 520,
        },
        stepperButtonContainer: {
            width: 100,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            display: 'flex',
        },
        stepperButton: {
            width: 100,
            paddingRight: 10,
        },
        skyNetLink: {
            paddingTop: theme.spacing(0.5),
            paddingLeft: theme.spacing(0.5),
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        },
    }
})
