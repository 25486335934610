import {
    FieldConfig,
    SkyNetSpreadSheetCellType,
    SkyNetSpreadSheetConfigType,
} from 'app/shared-components/SkyNetSpreadSheet'
import {
    RegionSecondLevel,
} from 'app/types/enums'
import {
    Availability, YesNo,
} from 'app/types/common.enums'
import {
    RegionalServiceAvailabilityItem,
} from '../RegionalServiceAvailability.types'

const isOriginTruckCapacityNotAvailable = (value: RegionalServiceAvailabilityItem) => {
    return !value?.originTruckCapacityAvailability
}

export default function RegionalServiceAvailabilitySpreadsheetConfig({
    getLabel,
    getAllowedValues,
}: {
    getLabel: (s: string) => string,
    getAllowedValues: (type: Record<string, string>) => Record<string, string>,
}): SkyNetSpreadSheetConfigType<keyof RegionalServiceAvailabilityItem> {
    const fields: FieldConfig<keyof RegionalServiceAvailabilityItem>[] = [
        {
            name: 'regionSecondLevel',
            title: getLabel('Country.regionSecondLevel'),
            width: 150,
            disabled: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(RegionSecondLevel),
        },
        {
            name: 'originAirlineLeaseRequired',
            title: getLabel('RegionalServiceAvailabilityRateCard.originAirlineLeaseRequired'),
            width: 150,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.CHECKBOX,
            },
            renderAsSelectValues: [
                YesNo.YES,
                YesNo.NO,
            ],
        },
        {
            name: 'originPickupStationAvailableCol',
            title: getLabel('RegionalServiceAvailabilityRateCard.originPickupStationAvailableCOL'),
            width: 150,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.CHECKBOX,
            },
            renderAsSelectValues: [
                Availability.AVAILABLE,
                Availability.NOT_AVAILABLE,
            ],
        },
        {
            name: 'originPickupStationAvailableCrt',
            title: getLabel('RegionalServiceAvailabilityRateCard.originPickupStationAvailableCRT'),
            width: 150,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.CHECKBOX,
            },
            renderAsSelectValues: [
                Availability.AVAILABLE,
                Availability.NOT_AVAILABLE,
            ],
        },
        {
            name: 'originTruckPositioning',
            title: getLabel('RegionalServiceAvailabilityRateCard.originTruckPositioning'),
            width: 150,
            disabled: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.CHECKBOX,
            },
            renderAsSelectValues: [
                Availability.AVAILABLE,
                Availability.NOT_AVAILABLE,
            ],
        },
        {
            name: 'originPositioningLtl',
            title: getLabel('RegionalServiceAvailabilityRateCard.originPositioningLTL'),
            width: 150,
            disabled: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.CHECKBOX,
            },
            renderAsSelectValues: [
                Availability.AVAILABLE,
                Availability.NOT_AVAILABLE,
            ],
        },
        {
            name: 'originPositioningFtl',
            title: getLabel('RegionalServiceAvailabilityRateCard.originPositioningFTL'),
            width: 150,
            disabled: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.CHECKBOX,
            },
            renderAsSelectValues: [
                Availability.AVAILABLE,
                Availability.NOT_AVAILABLE,
            ],
        },
        {
            name: 'originTruckCapacityAvailability',
            title: getLabel('RegionalServiceAvailabilityRateCard.originTruckCapacityAvailability'),
            width: 150,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.CHECKBOX,
            },
            renderAsSelectValues: [
                Availability.AVAILABLE,
                Availability.NOT_AVAILABLE,
            ],
        },
        {
            name: 'originTruckCapacity',
            title: getLabel('RegionalServiceAvailabilityRateCard.originTruckCapacity'),
            width: 150,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                conditionalType: [[
                    isOriginTruckCapacityNotAvailable,
                    SkyNetSpreadSheetCellType.NO_VALUE,
                ]],
            },
        },
        {
            name: 'destinationAirlineLeaseRoundtripRequired',
            title: getLabel('RegionalServiceAvailabilityRateCard.destinationAirlineLeaseRoundtripRequired'),
            width: 150,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.CHECKBOX,
            },
            renderAsSelectValues: [
                YesNo.YES,
                YesNo.NO,
            ],
        },
        {
            name: 'destinationDropoff',
            title: getLabel('RegionalServiceAvailabilityRateCard.destinationDropoff'),
            width: 150,
            disabled: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.CHECKBOX,
            },
            renderAsSelectValues: [
                Availability.AVAILABLE,
                Availability.NOT_AVAILABLE,
            ],
        },
        {
            name: 'destinationCollectionLtl',
            title: getLabel('RegionalServiceAvailabilityRateCard.destinationCollectionLTL'),
            width: 150,
            disabled: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.CHECKBOX,
            },
            renderAsSelectValues: [
                Availability.AVAILABLE,
                Availability.NOT_AVAILABLE,
            ],
        },
        {
            name: 'id',
            width: 1,
            hidden: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.ID,
            },
        },
    ]

    return {
        id: 'RegionalServiceAvailability',
        title: 'Regional Service Availability',
        headerRow: true,
        rowsCounter: true,
        headerHeight: 60,
        addRowMenuOption: false,
        enableRangeSelection: true,
        enableRowSelection: true,
        stickyTopRows: 1,
        filterable: false,
        rowsLimit: 25,
        emptyRows: 0,
        initialFocusLocation: {
            columnId: 'regionSecondLevel',
            rowId: 0,
        },
        fields,
    }
}
