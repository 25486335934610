import {
    createContext, useContext,
} from 'react'
import {
    AQuote,
} from '../AQuotes.types'

export type QuoteInformationContextType = {
    activeStep: number,
    setActiveStep: (n: number) => void,
    value: AQuote,
    initialValue?: AQuote,
    setValue: (a: AQuote) => void,
    setIsEdited: (isEdited: boolean) => void,
}

export const AQuoteInformationContext = createContext<QuoteInformationContextType>(null)

const useAQuoteInformationContext = () => {
    return useContext<QuoteInformationContextType>(AQuoteInformationContext)
}

export default useAQuoteInformationContext
