import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    NestedKeyOf,
} from 'app/types/utils.types'
import {
    RangeType,
} from 'app/types/enums'

import {
    FeesData,
} from 'app/Apps/AccountManagement/CQuotes/CQuotes.types'

const getFields = ({
    getAllowedValues,
    value,
}: {
    getAllowedValues: (val: Record<string, string>) => Record<string, string>,
    value: FeesData,
}): DTOCardFormField<NestedKeyOf<FeesData>>[] => {
    return [
        {
            id: 'general',
            title: 'General',
            className: 'general',
            fields: [
                {
                    id: 'general-column1',
                    fields: [
                        {
                            name: 'laneFees.expressOrderTimeline',
                            componentName: 'InputSingleline',
                            value: value?.laneFees?.expressOrderTimeline,
                            labelKey: 'MarginsRateCard.expressOrderTimeline',
                        },
                        {
                            name: 'laneFees.postponementFeeTimeline',
                            componentName: 'InputSingleline',
                            value: value?.laneFees?.postponementFeeTimeline,
                            labelKey: 'LaneGroupUpdateDto.postponementFeeTimeline',
                        },
                        {
                            name: 'additionalFees.cancellationFeeTimeline',
                            componentName: 'InputSingleline',
                            value: value?.additionalFees?.cancellationFeeTimeline,
                            labelKey: 'MarginsRateCard.cancellationFeeTimeline',
                        },
                    ],
                },
                {
                    id: 'general-column2',
                    fields: [
                        {
                            name: 'laneFees.expressDaysType',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(RangeType),
                            value: value?.laneFees?.expressDaysType,
                            labelKey: 'LaneGroupCreateOrDuplicateDto.expressDaysType',
                        },
                        {
                            name: 'laneFees.postponementDaysType',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(RangeType),
                            value: value?.laneFees?.postponementDaysType,
                            labelKey: 'LaneGroupCreateOrDuplicateDto.postponementDaysType',
                        },
                        {
                            name: 'additionalFees.cancellationDaysType',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(RangeType),
                            value: value?.additionalFees?.cancellationDaysType,
                            labelKey: 'AdditionalFeesCreateDto.cancellationDaysType',
                        },
                    ],
                },
            ],
        },
        {
            id: 'laneFeesValidity',
            title: 'Lane Fees Validity',
            className: 'laneFeesValidity',
            fields: [
                {
                    id: 'laneFees-column1',
                    fields: [{
                        name: 'laneFees.validFrom',
                        componentName: 'InputSingleline',
                        value: value?.laneFees?.validFrom,
                        labelKey: 'AdditionalFeesCreateDto.validFrom',
                    }],
                },
                {
                    id: 'laneFees-column2',
                    fields: [{
                        name: 'laneFees.validTo',
                        componentName: 'InputSingleline',
                        value: value?.laneFees?.validTo,
                        labelKey: 'LaneFeesUpdateDto.validTo',
                    }],
                },
            ],
        },
        {
            id: 'additionalFeesValidity',
            title: 'Additional Fees Validity',
            className: 'additionalFeesValidity',
            fields: [
                {
                    id: 'feesValidity-column1',
                    fields: [{
                        name: 'additionalFees.validFrom',
                        componentName: 'InputSingleline',
                        value: value?.additionalFees?.validFrom,
                        labelKey: 'AdditionalFeesCreateDto.validFrom',
                    }],
                },
                {
                    id: 'feesValidity-column2',
                    fields: [{
                        name: 'additionalFees.validTo',
                        componentName: 'InputSingleline',
                        value: value?.additionalFees?.validTo,
                        labelKey: 'LaneFeesUpdateDto.validTo',
                    }],
                },
            ],
        },
    ]
}

export default getFields
