/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import config from 'app/Apps/ContactManagement/Countries/Countries.request'
import StateSelector, {
    State,
} from './StateSelector'

type Props = {
    country?: string,
}

const defaultProps = {
    country: null,
}

const StateSelectorContainer = (props: Props) => {
    const {
        country: id,
    } = props

    const {
        data: countryData,
    } = useRequest<{ states: State[] }>({
        key: RequestKeys.getContryForStateSelector,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(config.get),
        enabled: Boolean(id),
    })

    return (
        <StateSelector
            {...props}
            country={countryData}
        />
    )
}

StateSelectorContainer.defaultProps = defaultProps

export default StateSelectorContainer
