import {
    ForecastsByDateType, ForecastsByProductType,
} from '../ScenarioEditor.types'
import {
    AllowedProductTypes,
} from '../../serviceCenters.types'

export enum ScenarioByProductTypeMeasures {
    variationDry = 'Variation Dry',
    variationPreconditioned = 'Variation Preconditioned',
    processingThroughput = 'Processing Throughput',
}

export type ScenarioByProductTypeRow = {
    measure: ScenarioByProductTypeMeasures[keyof ScenarioByProductTypeMeasures]
} & Record<string, number>

export type ScenarioByProductTypeConfig = {
    columns: Set<string>,
    data: ScenarioByProductTypeRow[]
}

export type ScenarioByProductState = Record<AllowedProductTypes, ScenarioByProductTypeConfig>

export const parseForecastsByDate = (
    data: ForecastsByDateType[],
): ScenarioByProductTypeConfig => {
    const columns = new Set<string>()
    const sortedByMeasure = data.reduce((acc, {
        date, ...values
    }) => {
        columns.add(date)
        return {
            ...acc,
            ...Object.keys(ScenarioByProductTypeMeasures).reduce((measureAcc, key) => {
                return {
                    ...measureAcc,
                    [key]: {
                        ...acc?.[key], [date]: values?.[key],
                    },
                }
            }, {}),
        }
    }, {})

    return {
        data: Object.entries(sortedByMeasure).map(([
            measure,
            values,
        ]) => {
            return {
                measure,
                ...(values || {}) as Record<string, number>,
            }
        }) as ScenarioByProductTypeRow[],
        columns,
    }
}

export const parseForecastsByProduct = (data: ForecastsByProductType[]) => {
    return data.reduce((acc, {
        productType, forecastsByDate,
    }) => {
        return {
            ...acc,
            [productType]: parseForecastsByDate(forecastsByDate),
        }
    }, {} as Record<AllowedProductTypes, ScenarioByProductTypeConfig>)
}

export const formatToForecastsByDate = (
    data: ScenarioByProductTypeRow[],
): ForecastsByDateType[] => {
    return Object.entries(data.reduce((accByDate, {
        measure, ...dates
    }) => {
        return Object.entries(dates).reduce((datesAcc, [
            key,
            value,
        ]) => {
            return {
                ...datesAcc,
                [key]: {
                    ...datesAcc?.[key],
                    [measure as string]: value,
                },
            }
        }, accByDate)
    }, {} as Record<string, Record<string, number>>)).map(([
        date,
        measures,
    ]) => {
        return {
            date,
            ...(measures || {}) as Record<string, number>,
        } as ForecastsByDateType
    })
}

export const formatToForecastsByProductType = (
    values: Record<AllowedProductTypes, ScenarioByProductTypeConfig>,
): ForecastsByProductType[] => {
    return Object.entries(values).reduce((acc, [
        productType,
        {
            data,
        },
    ]) => {
        return [
            ...acc,
            {
                productType,
                forecastsByDate: formatToForecastsByDate(data),
            },
        ]
    }, [])
}
