import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    CollServiceType, DeliveryServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    LaneStatus,
} from 'app/types/enums'
import {
    LanesQuotation,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'

const columns = ({
    l,
}: {
    l: (l: string) => string,
}): RichTableColumns<keyof LanesQuotation>[] => {
    return [
        {
            id: 'laneNumber',
            headerProps: {
                children: l('AccountQuotes.lanes'),
            },
            mapCellProps: 'laneNumber',
        },
        {
            id: 'laneStatus',
            headerProps: {
                children: l('AccountQuotes.laneStatus'),
            },
            mapCellProps: 'laneStatus',
            componentName: 'EnumValue',
            allowedValuesEnum: LaneStatus,
        },
        {
            id: 'originAirport',
            headerProps: {
                children: l('AccountQuotes.laneFrom'),
            },
            mapCellProps: 'originAirport',
        },
        {
            id: 'destinationAirport',
            headerProps: {
                children: l('AccountQuotes.laneTo'),
            },
            mapCellProps: 'destinationAirport',
        },
        {
            id: 'deliveryServiceType',
            headerProps: {
                children: l('AccountQuotes.laneDeliveryType'),
            },
            mapCellProps: 'deliveryServiceType',
            componentName: 'EnumValue',
            allowedValuesEnum: DeliveryServiceType,
        },
        {
            id: 'collectionServiceType',
            headerProps: {
                children: l('AccountQuotes.laneCollectionType'),
            },
            mapCellProps: 'collectionServiceType',
            componentName: 'EnumValue',
            allowedValuesEnum: CollServiceType,
        },
        {
            id: 'changedBy',
            headerProps: {
                children: l('LaneGroup.changedBy'),
            },
            mapCellProps: 'changedBy',
        },
    ]
}

export default columns
