import {
    FieldConfig, isNegativeNumber,
    SkyNetSpreadSheetCellType, SkyNetSpreadSheetConfigType,
} from 'app/shared-components/SkyNetSpreadSheet'
import {
    BaseLeaseCategory, RangeType, RegionSecondLevel,
} from 'app/types/enums'
import {
    MarginsTable,
} from '../Margins.types'

export default function MarginsSpreadsheetConfig({
    getLabel,
    getAllowedValues,
}: {
    getLabel: (s: string) => string,
    getAllowedValues: (type: Record<string, string>) => Record<string, string>,
}): SkyNetSpreadSheetConfigType<keyof MarginsTable> {
    const fields: FieldConfig<keyof MarginsTable>[] = [
        {
            name: 'regionSecondLevel',
            title: getLabel('MarginsRateCard.regionSecondLevel'),
            width: 150,
            disabled: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(RegionSecondLevel),
        },
        {
            name: 'baseLeaseCategory',
            title: getLabel('MarginsRateCard.baseLeaseCategory'),
            width: 150,
            disabled: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(BaseLeaseCategory),
        },
        {
            name: 'logisticsDays',
            title: getLabel('MarginsRateCard.logisticsDays'),
            width: 150,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                validate: isNegativeNumber('logisticsDays', undefined, {
                    allowZero: true,
                }),
            },
            hideZero: false,
        },
        {
            name: 'dropOffMargin',
            title: getLabel('MarginsRateCard.dropOffMargin'),
            width: 150,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                validate: isNegativeNumber('dropOffMargin', undefined, {
                    allowZero: true,
                }),
            },
        },
        {
            name: 'positioningMargin',
            title: getLabel('MarginsRateCard.positioningMargin'),
            width: 150,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                validate: isNegativeNumber('positioningMargin'),
            },
        },
        {
            name: 'postponementFeeTimeline',
            title: getLabel('MarginsRateCard.postponementFeeTimeline'),
            width: 150,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                validate: isNegativeNumber('postponementFeeTimeline'),
            },
        },
        {
            name: 'postponementDaysType',
            title: getLabel('MarginsRateCard.postponementDaysType'),
            width: 150,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(RangeType),
        },
        {
            name: 'expressOrderTimeline',
            title: getLabel('MarginsRateCard.expressOrderTimeline'),
            width: 150,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                validate: isNegativeNumber('expressOrderTimeline'),
            },
        },
        {
            name: 'expressDaysType',
            title: getLabel('MarginsRateCard.expressDaysType'),
            width: 150,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(RangeType),
        },
        {
            name: 'cancellationFeeTimeline',
            title: getLabel('MarginsRateCard.cancellationFeeTimeline'),
            width: 150,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                validate: isNegativeNumber('cancellationFeeTimeline'),
            },
        },
        {
            name: 'cancellationDaysType',
            title: getLabel('MarginsRateCard.cancellationDaysType'),
            width: 150,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(RangeType),
        },
        {
            name: 'id',
            width: 1,
            hidden: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.ID,
            },
        },
    ]

    return {
        id: 'Margins',
        title: 'Margins',
        headerRow: true,
        rowsCounter: true,
        headerHeight: 60,
        addRowMenuOption: false,
        enableRangeSelection: true,
        enableRowSelection: true,
        stickyTopRows: 1,
        filterable: false,
        rowsLimit: 25,
        emptyRows: 0,
        initialFocusLocation: {
            columnId: 'regionSecondLevel',
            rowId: 0,
        },
        fields,
    }
}
