import {
    AQuote,
    RequestDataUpdate,
} from '../AQuotes.types'

const getRequestData = (
    aQuote: AQuote,
    initialData: AQuote,
): RequestDataUpdate => {
    const requestedOpportunities = aQuote?.requestedOpportunities?.reduce((acc, {
        opportunity,
        productTypes,
        palletType,
    }) => {
        const isExist = initialData.requestedOpportunities.some(({
            opportunity: {
                id: opportunityId,
            },
        }) => {
            return opportunityId === opportunity.id
        })

        if (!isExist) {
            return [
                ...acc,
                {
                    opportunity: {
                        id: opportunity.id,
                    },
                    productTypes,
                    palletType,
                },
            ]
        }

        return acc
    }, [])

    const requestedLanes = aQuote.requestedLanes.reduce((acc, {
        accountLane,
        baseLeaseDays,
        otherComments,
    }) => {
        const isExist = initialData.requestedLanes.some(({
            accountLane: {
                id: accountLaneId,
            },
        }) => {
            return accountLaneId === accountLane.id
        })

        if (!isExist) {
            return [
                ...acc,
                {
                    accountLane: {
                        id: accountLane.id,
                    },
                    baseLeaseDays,
                    otherComments,
                },
            ]
        }

        return acc
    }, [])

    return {
        requestedOpportunities,
        requestedLanes,
    }
}

export default getRequestData
