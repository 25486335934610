import {
    DTOCardFormField,
    FieldInCard,
} from 'app/types/form.types'
import {
    ContractCurrency,
    AdditionalFeesStatus,
    PricingStatus,
} from 'app/types/enums'

import DeliveryCollectionFeesInputs from '../../TableFees/Inputs/DeliveryCollectionFeesInputs'

import {
    AlLogisticPricingFields,
} from '../AlLogisticPricing.types'

type Params = {
    getAllowedValues: (param: Record<string, any>) => Record<string, string>,
    originalStatus?: PricingStatus,
    exists?: boolean,
}
const alLogisticPricingComponents = ({
    originalStatus,
    getAllowedValues,
    exists,
}: Params) => {
    const disabled = [
        PricingStatus.VALID,
        PricingStatus.CANCELLED,
    ].includes(originalStatus)

    const generalInformation:DTOCardFormField<AlLogisticPricingFields> = {
        title: 'General Information',
        className: 'generalInformation',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'airline',
                        componentName: 'LocationObjectSelector',
                        labelKey: 'Opportunity.airline',
                        required: true,
                        disabled,
                    },
                    {
                        name: 'currency',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(ContractCurrency),
                        useDropdown: true,
                        labelKey: 'LocationDefaultTaxCode.currency',
                        required: true,
                        disabled,
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'status',
                        labelKey: 'AdditionalFeesCreateDto.additionalFeesStatus',
                        originalStatus,
                        componentName: 'StatusTransitionSelector',
                        moduleName: 'generalPricingStatus',
                        useDropdown: true,
                        allowedValues: getAllowedValues(AdditionalFeesStatus),
                        required: true,
                        disabled: !exists,
                    },
                    {
                        name: 'billingCompany',
                        componentName: 'BillingCompanyObjectSelector',
                        labelKey: 'OrderBilling.billingCompany',
                        required: true,
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'validFrom',
                        componentName: 'DateSelect',
                        labelKey: 'AdditionalFeesCreateDto.validFrom',
                        required: true,
                        disabled,
                    },
                    {
                        name: 'comment',
                        componentName: 'LongText',
                        labelKey: 'AdditionalFeesDto.comment',
                    },
                ],
            },
            {
                id: 'column4',
                fields: [{
                    name: 'validTo',
                    componentName: 'DateSelect',
                    labelKey: 'AdditionalFeesCreateDto.validTo',
                    required: true,
                }],
            },
        ],
    }
    const deliveryCollectionFees:FieldInCard<AlLogisticPricingFields> = {
        id: 'deliveryCollectionFees',
        title: 'Delivery Collection Fees',
        className: 'deliveryCollectionFees',
        fields: [{
            name: 'deliveryCollectionFees',
            componentName: 'TableFees',
            inputs: DeliveryCollectionFeesInputs,
            disabled,
        }],
    }
    const otherFees:FieldInCard<AlLogisticPricingFields> = {
        id: 'otherFees',
        title: 'Other Fees',
        className: 'otherFees',
        fields: [{
            name: 'otherFees',
            componentName: 'Fees',
            feeValueLabel: 'Value',
            disabled,
        }],
    }

    return [
        generalInformation,
        deliveryCollectionFees,
        otherFees,
    ]
}

export default alLogisticPricingComponents
