import {
    RichTableColumns,
} from 'app/types/richtable.types'
import formatCurrencyValue from 'app/utils/formatCurrencyValue'

import {
    BestOptionType,
} from '../../../createAutomaticallyForm.types'

const getSeaColumns = (l: (l:string) => string): RichTableColumns<keyof BestOptionType>[] => {
    return [
        {
            id: 'exportForwarder',
            headerProps: {
                children: l('BestOptions.exportForwarder'),
            },
            mapCellProps: 'exportForwarder',
        },
        {
            id: 'importForwarder',
            headerProps: {
                children: l('BestOptions.importForwarder'),
            },
            mapCellProps: 'importForwarder',
        },
        {
            id: 'seaContainerLoad',
            headerProps: {
                children: l('BestOptions.seaContainerLoad'),
            },
            mapCellProps: 'seaContainerLoad',
        },
        {
            id: 'originPort',
            headerProps: {
                children: l('OrderStep.originSeaport'),
            },
            mapCellProps: 'originPort',
        },
        {
            id: 'destinationPort',
            headerProps: {
                children: l('OrderStep.destinationSeaport'),
            },
            mapCellProps: 'destinationPort',
        },
        {
            id: 'destinationLocation',
            headerProps: {
                children: l('BestOptions.destinationLocation'),
            },
            mapCellProps: 'destinationLocation',
        },
        {
            id: 'leadTime',
            headerProps: {
                children: l('BestOptions.leadTime'),
            },
            mapCellProps: 'leadTime',
        },
        {
            id: 'price',
            headerProps: {
                children: l('BestOptions.price'),
            },
            mapCellProps: ({
                price,
            }) => {
                return {
                    children: price ? `${formatCurrencyValue(price)} €` : '',
                }
            },
        },
        {
            id: 'validUntil',
            headerProps: {
                children: l('BestOptions.validUntil'),
            },
            mapCellProps: 'validUntil',
            componentName: 'LaneDateEnriched',
        },
    ]
}

export default getSeaColumns
