import React, {
    useMemo,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import Typography from '@mui/material/Typography'
import uniq from 'lodash/uniq'

import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import TemperatureChart from 'app/shared-components/TemperatureChart'
import TemperatureChartOptions from 'app/shared-components/TemperatureChartOptions'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import Checkbox from 'app/shared-components/Checkbox'
import Card from 'app/shared-components/Card'
import DateRangeSelector from 'app/shared-components/DateRangeSelector'
import containerTemperatureRanges from 'app/utils/containerTemperatureRanges'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    LoggerType,
    TemperatureRange,
    SensorCode,
} from 'app/types/enums'
import OtherLoggersSelect from 'app/Apps/Loggers/Logger/OtherLoggersSelect'
import LoggerOptions from 'app/shared-components/LoggerOptions'
import useDoorEventForSensorData from 'app/hooks/useDoorEventForSensorData'
import EnumSelector from 'app/shared-components/EnumSelector'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    SENSOR_CODES_NAME,
} from 'app/utils/optionsLoggers'
import {
    getDateToIso,
} from 'app/utils/date'

import {
    LoggerDataTypes, LoggerStatus,
} from '../Loggers.types'
import useStyles from './Logger.styles'
import loggerFields from './Logger.fields'
import radioOptions from './radioOptions'
import {
    allowedLoggerDataTypes,
} from './Logger.utils'
import GatewayTimelineChart from './GatewayTimelineChart/GatewayTimelineChart'
import useSendRecoveryRequest from './hooks/useSendRecoveryRequest'

const LINE_CHART_TYPES = [
    LoggerDataTypes.MARKER,
    LoggerDataTypes.GATEWAY_NUMBER,
    LoggerDataTypes.IS_HISTORIC_READOUT,
]

const getPercentData = (sensorData: {
    t: string,
    d: number[],
}[]) => {
    return sensorData.map(({
        t,
        d,
    }) => {
        return {
            t,
            d: d.map((item) => {
                if (item) {
                    return item * 100
                }

                return item
            }),
        }
    })
}

type Props = {
    name: string,
    loggerInfo?: {
        allowedSensorCodes?: SensorCode[],
        loggerNumber?: string,
        containerSerialNumber?: string,
        gateway?: any,
        lastMeasuredOnUtc?: number,
        loggerStatus?: LoggerStatus,
        loggerType?: LoggerType,
        totalAllowedSensorValues?: string[],
        loggerTypeDetails?: {
            dataTypes?: string[],
        },
    },
    timeRange: {
        from: any,
        to: any,
    },
    setTimeRange: (value: any)=> void,
    data?: {
        sensorData?: {
            t: string,
            d: (number|string)[],
        }[],
        sensorLabels?: string[],
        sensorCodes?: string[],
        positions?: { label:string, value: string }[],
    },
    showTempRange: boolean,
    onCheckShowTempRange: (value: boolean)=> void,
    selectedTemperatureRange: string,
    temperatureChartFullscreen: boolean,
    setTemperatureChartFullscreen: (value: boolean)=> void,
    onChangeSelectedTemperatureRange: (value: TemperatureRange)=> void,
    configLoggers?: any[],
    setConfigLoggers: (value: any[])=> void,
    otherLoggers?: any[],
    onOtherLoggersChange: (value: any[])=> void,
    isDateRange: boolean,
    setDateRange: (value: boolean)=> void,
    showDoorEvent: boolean,
    onCheckShowDoorEvent: (value: boolean)=> void,
    totalAllowedSensorValues?: string[],
    utcTime: boolean,
    setUtcTime: (value: boolean)=> void,
    dataType: LoggerDataTypes,
    setDataType: (value: LoggerDataTypes) => void,
    isDataReady: boolean,
    setShowIncludeInvalid?: (value: boolean)=> void,
    showIncludeInvalid?: boolean,
    invalidateSensorData: () => void,
    from: Date,
    to: Date,
}

const defaultProps: Partial<Props> = {
    data: {},
    loggerInfo: {
        loggerTypeDetails: {},
    },
    configLoggers: [],
    otherLoggers: [],
    totalAllowedSensorValues: [],
    setShowIncludeInvalid: undefined,
    showIncludeInvalid: false,
}

const Logger = (props: Props) => {
    const getEnumValues = useEnumValues()
    const {
        classes,
    } = useStyles()

    const {
        name,
        data,
        loggerInfo,
        timeRange,
        setTimeRange,
        utcTime,
        setUtcTime,
        dataType,
        setDataType,
        showTempRange,
        onCheckShowTempRange,
        selectedTemperatureRange,
        onChangeSelectedTemperatureRange,
        temperatureChartFullscreen,
        setTemperatureChartFullscreen,
        configLoggers,
        setConfigLoggers,
        otherLoggers,
        onOtherLoggersChange,
        isDateRange,
        setDateRange,
        showDoorEvent,
        onCheckShowDoorEvent,
        totalAllowedSensorValues,
        isDataReady,
        setShowIncludeInvalid,
        showIncludeInvalid,
        invalidateSensorData,
        from,
        to,
    } = props

    const loggerUpdatePermission = useHasPermission(['logger_update'])

    const {
        mutate,
    } = useSendRecoveryRequest(invalidateSensorData)

    const {
        loggerTypeDetails: {
            dataTypes: loggerDataTypes,
        },
        containerSerialNumber,
    } = loggerInfo

    const {
        sensorData,
        sensorLabels,
        sensorCodes,
    } = data

    const fieldsWithClassName = useFieldsWithClassName(useMemo(() => {
        return loggerFields({
            containerSerialNumber,
            getAllowedValues: getEnumValues,
        })
    }, [
        containerSerialNumber,
        getEnumValues,
    ]), classes)

    const temperatureRange = useMemo(() => {
        return containerTemperatureRanges[selectedTemperatureRange]
    }, [selectedTemperatureRange])

    const loggerDataTypesEnum = useMemo(() => {
        const dataTypes = getEnumValues(LoggerDataTypes)

        return allowedLoggerDataTypes.reduce((acc, type) => {
            if (loggerDataTypes?.includes(type)) {
                return {
                    ...acc,
                    [type]: dataTypes[type],
                }
            }
            return acc
        }, {})
    }, [
        getEnumValues,
        loggerDataTypes,
    ])

    const {
        userOptions,
        filteredSensorData,
        customColumns,
        customData,
    } = useDoorEventForSensorData({
        sensorData,
        sensorLabels,
        sensorCodes,
        showTempRange,
        temperatureRange,
        showDoorEvent,
        dataType,
    })

    const sensorCodesRequest = useMemo(() => {
        return configLoggers.reduce((acc, {
            sensorCodes: sensorCodesConfig,
            name: nameCode,
        }) => {
            if (nameCode === SENSOR_CODES_NAME && sensorCodesConfig) {
                return [
                    ...acc,
                    ...sensorCodesConfig,
                ]
            }
            return uniq(acc)
        }, [])
    }, [configLoggers])

    const sendRecoveryRequest = () => {
        mutate({
            loggerNumber: loggerInfo?.loggerNumber,
            data: {
                from: getDateToIso(new Date(from), isDateRange),
                to: getDateToIso(new Date(to), isDateRange),
                sensorCodes: sensorCodesRequest,
            },
        })
    }

    return (
        <div className={classes.gridWrapper}>
            <div className={classes.domainObject}>
                <DomainObjectForm
                    name={name}
                    disabled
                    value={loggerInfo}
                    className={classes.gridWrapperForm}
                    classNames={{
                        gridCardWrapper: classes.gridCardWrapperForm,
                    }}
                    fields={fieldsWithClassName}
                />
            </div>
            <div
                className={classes.temperatureChart}
                data-testid="chart"
            >
                <Card
                    className={classes.chartContainer}
                >
                    <div className={classes.chartHeading}>
                        <Typography variant="h3">
                            Chart Data
                        </Typography>
                        <div className={classes.chartControls}>
                            <DateRangeSelector
                                value={timeRange}
                                onChange={setTimeRange}
                                setDateRange={setDateRange}
                                showTimeRange
                                mini
                            />
                            <Checkbox
                                title="UTC Time"
                                value={utcTime}
                                onChange={setUtcTime}
                            />
                        </div>
                    </div>
                    { LINE_CHART_TYPES.includes(dataType) && isDataReady && sensorData
                        && (
                            <GatewayTimelineChart
                                sensorData={sensorData}
                                sensorLabels={sensorLabels}
                                temperatureChartFullscreen={temperatureChartFullscreen}
                                setTemperatureChartFullscreen={setTemperatureChartFullscreen}
                            />
                        )}
                    { !LINE_CHART_TYPES.includes(dataType) && (
                        <TemperatureChart
                            sensorData={dataType === LoggerDataTypes.RELATIVE_HUMIDITY
                                ? getPercentData(filteredSensorData) : filteredSensorData}
                            sensorLabels={sensorLabels}
                            customData={customData}
                            customColumns={customColumns}
                            userOptions={userOptions}
                            temperatureChartFullscreen={temperatureChartFullscreen}
                            setTemperatureChartFullscreen={setTemperatureChartFullscreen}
                            isDateRange={isDateRange}
                            measurement={dataType === LoggerDataTypes.RELATIVE_HUMIDITY ? '%' : undefined}
                        />
                    )}
                    {loggerUpdatePermission && (
                        <ButtonContainer>
                            <Button
                                onClick={sendRecoveryRequest}
                                label="send recovery request"
                            />
                        </ButtonContainer>
                    )}
                </Card>
            </div>
            <div className={classes.sideBar}>
                { !isEmpty(loggerDataTypesEnum) && (
                    <div className={classes.cardRoot}>
                        <Card title="Data Types">
                            <EnumSelector
                                allowedValues={loggerDataTypesEnum}
                                onChange={setDataType}
                                title="Data Types"
                                name="dataType"
                                value={dataType}
                                useDropdown
                                noNone
                            />
                        </Card>
                    </div>
                ) }
                <div className={classes.cardRoot}>
                    <TemperatureChartOptions
                        sensorData={sensorData}
                        sensorCodes={sensorCodes}
                        serialNumber={loggerInfo.loggerNumber}
                        showTempRange={showTempRange}
                        onCheckShowTempRange={onCheckShowTempRange}
                        selectedTemperatureRange={selectedTemperatureRange}
                        radioOptions={radioOptions}
                        onChangeSelectedTemperatureRange={onChangeSelectedTemperatureRange}
                        showTemperatureRangeOption={dataType === LoggerDataTypes.TEMPERATURE}
                        showTemperatureRangeAllOptions={dataType === LoggerDataTypes.TEMPERATURE}
                        showCsvButton
                        showDoorEventOption={loggerDataTypes?.includes(LoggerDataTypes.DOOR)}
                        showDoorEvent={showDoorEvent}
                        onCheckShowDoorEvent={onCheckShowDoorEvent}
                        showIncludeInvalidOption
                        showIncludeInvalid={showIncludeInvalid}
                        setShowIncludeInvalid={setShowIncludeInvalid}
                    />
                </div>
                {totalAllowedSensorValues?.length > 1 && (
                    <LoggerOptions
                        className={classes.cardRoot}
                        value={configLoggers}
                        onChange={setConfigLoggers}
                        title="Sensor Codes"
                        allowedSensorCodes={loggerInfo.allowedSensorCodes}
                    />
                )}
                <Card title="Other Loggers">
                    <OtherLoggersSelect
                        value={otherLoggers}
                        onChange={onOtherLoggersChange}
                    />
                </Card>
            </div>
        </div>
    )
}

Logger.defaultProps = defaultProps

export default Logger
