import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requests from 'app/Apps/Pricing/LaneGroups/LaneGroup.requests'

type AwardedLanes = {
    hasAwardedImplementedLanes: boolean,
}

export default (laneGroupId: number) => {
    const {
        data,
        isDataReady,
    } = useRequest<AwardedLanes>({
        key: RequestKeys.checkAwardedLanes,
        params: {
            id: laneGroupId,
        },
        requestFunc: RequestFn.getEntity(requests[RequestKeys.checkAwardedLanes]),
        enabled: Boolean(laneGroupId),
        keepPreviousData: true,
    })

    if (!isDataReady) {
        return true
    }

    return data?.hasAwardedImplementedLanes
}
