import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    AdditionalFeesStatus, ContractCurrency,
} from 'app/types/enums'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    AlLeasePricing,
} from './AlLeasePricing.types'

const GENERIC_LABEL = 'Generic'

export default (): SkyNetTableConfig<keyof AlLeasePricing> => {
    return {
        name: 'AlLeasingPricing',
        tabs: [{
            name: 'All',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'accountCompanyCompanyName',
                    filterField: 'accountCompanyCompanyName',
                    labelKey: 'AccountLane.pricingAccount',
                    filterType: FilterType.Array,
                    reduced: true,
                    mapCellProps: ({
                        isGenericAccount,
                        accountCompanyCompanyName,
                    }) => {
                        return {
                            children: isGenericAccount ? GENERIC_LABEL : accountCompanyCompanyName,
                        }
                    },
                    customFilterItemLabelConverter: (value) => {
                        return value || GENERIC_LABEL
                    },
                },
                {
                    name: 'currency',
                    labelKey: 'LaneOpsInformationDto.currency',
                    componentName: 'EnumValue',
                    allowedValuesEnum: ContractCurrency,
                    filterField: 'currency',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'status',
                    labelKey: 'LaneBaseDto.laneStatus',
                    filterField: 'status',
                    componentName: 'EnumValue',
                    allowedValuesEnum: AdditionalFeesStatus,
                    filterType: FilterType.Array,
                },
                {
                    name: 'validFrom',
                    labelKey: 'LaneFeesUpdateDto.validFrom',
                    filterField: 'validPricingFrom',
                    filterType: FilterType.DateRange,
                    componentName: 'DateTimeCell',
                },
                {
                    name: 'validTo',
                    filterField: 'validTo',
                    labelKey: 'LaneFeesUpdateDto.validTo',
                    filterType: FilterType.DateRange,
                    componentName: 'LaneDateEnriched',
                },
            ],
        }],
    }
}
