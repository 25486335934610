import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    ContractCurrency,
    ProductType,
} from 'app/types/enums'
import {
    DeliveryServiceType, CollServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import requests from 'app/Apps/AccountManagement/CQuotes/CQuote.request'

type RequestData = {
    lane: { id: number },
    pricingAccount: { id: number },
    expPalletsYear: number,
    productType: ProductType,
    originAirport: { id: number },
    destinationAirport: { id: number },
    deliveryServiceType: DeliveryServiceType,
    collectionServiceType: CollServiceType,
    handoverPoint: { id: number },
    collectionDropoffPoint: { id: number },
    currency: ContractCurrency,
    rentalDays: number,
    logisticsDays: number,
    customerQuotation: { id: number },
    skyCellContact: { id: number },
    quotedFor: { id: number },
    generatePdf: boolean
}

export default ({
    onSuccess: onSuccessExternal,
}: { onSuccess: ({
    id: number,
}) => void }) => {
    const token = useJWTToken()

    const {
        onSuccess,
        onError,
    } = useHandleStatusRequest({
        title: 'New C-Quote was created',
        id: 'CQuoteCreated',
        onSuccess: onSuccessExternal,
    })

    return useMutation({
        mutationFn: (cQuote: RequestData) => {
            return simpleApi({
                ...requests.create(cQuote),
                token,
            })
        },
        onError,
        onSuccess,
    })
}
