import React, {
    useLayoutEffect, useRef,
} from 'react'

import useHandleStatusRequest from '../../hooks/useHandleStatusRequest'

import loadChart from './loadChart'

const defaultProps = {
    className: '',
}

const TimelineChart = ({
    value,
    options,
    className,
    columns,
}: {
    value: (string | number | Date)[][],
    options: Record<string, any>,
    className?: string,
    columns: {type: string, id: string}[],
}) => {
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'TimelineChart',
    })
    const elm = useRef()

    useLayoutEffect(() => {
        loadChart({
            chartData: value,
            elm: elm.current,
            options,
            onError,
            columns,
        })
    }, [
        columns,
        onError,
        options,
        value,
    ])

    return (
        <div
            className={className}
            ref={elm}
        />
    )
}

TimelineChart.defaultProps = defaultProps

export default TimelineChart
