import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requestConfig from '../../serviceCenters.request'
import {
    ForecastsByProductType,
    ScenarioEditorContainerAvailability,
} from '../ScenarioEditor.types'

type RequestData = {
    serviceCenter: {
        id: number
    },
    forecastsByProduct: ForecastsByProductType[],
    processingTime: number
}

const useGetScenarioEditorContainerAvailability = (requestData: RequestData) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<ScenarioEditorContainerAvailability>({
        key: RequestKeys.scenarioEditorContainerAvailability,
        params: requestData,
        requestFunc: RequestFn.getEntity(
            requestConfig[RequestKeys.scenarioEditorContainerAvailability],
        ),
        enabled: Boolean(
            requestData.serviceCenter
            && requestData.forecastsByProduct.length
            && requestData.forecastsByProduct,
        ),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetScenarioEditorContainerAvailability
