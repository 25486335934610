import React, {
    useState, useCallback,
} from 'react'
import pick from 'lodash/pick'
import isEqual from 'lodash/isEqual'
import {
    FormControl,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import Card from 'app/shared-components/Card'
import Checkbox from 'app/shared-components/Checkbox'
import Label from 'app/shared-components/Label'
import IntegerInput from 'app/shared-components/IntegerInput'
import useLabel from 'app/hooks/useLabel'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import Button from 'app/shared-components/Button'
import useScenarioEditorContext from '../ScenarioEditorContext'
import {
    ScenarioEditorParameters,
} from '../ScenarioEditor.types'
import useStyles from './ScenarioEditorForm.styles'

export default function ScenarioEditorForm() {
    const {
        contextValue,
        setContextValue,
    } = useScenarioEditorContext()

    const scenarioEditorParameters: ScenarioEditorParameters = pick(contextValue, [
        'processingTime',
        'orderSelectionDelay',
        'includeNotStartedOrders',
        'includePreBookings',
    ])

    const [
        value,
        setValue,
    ] = useState<ScenarioEditorParameters>(scenarioEditorParameters)

    const {
        classes, cx,
    } = useStyles()
    const l = useLabel()

    const onChange = useCallback((name: string) => {
        return (newValue: any) => {
            return setValue({
                ...value,
                [name]: newValue,
            })
        }
    }, [value])

    const reset = useCallback(() => {
        setValue(scenarioEditorParameters)
        setContextValue({
            ...scenarioEditorParameters,
            forecastsByProduct: [],
            modified: false,
        })
    }, [
        scenarioEditorParameters,
        setContextValue,
    ])

    const recalculate = useCallback(() => {
        setContextValue({
            ...value,
            forecastsByProduct: [],
            modified: false,
        })
    }, [
        setContextValue,
        value,
    ])

    return (
        <>
            <Typography
                variant="h2"
                className={classes.title}
            >
                Scenario Editor
            </Typography>
            <Card>
                <Typography
                    variant="h3"
                    className={classes.subtitle}
                >
                    Spreadsheet Parameters
                </Typography>
                <div className={classes.scenarioEditor}>
                    <FormControl className={cx(classes.control)}>
                        <Label title={l('ServiceCenterStorage.processingTime')} />
                        <IntegerInput
                            name="processingTime"
                            onChange={onChange('processingTime')}
                            value={value.processingTime}
                            min={1}
                            max={3}
                        />
                    </FormControl>
                    <FormControl className={cx(classes.control)}>
                        <Label title={l('ServiceCenter.orderSelectionDelay')} />
                        <IntegerInput
                            name="orderSelectionDelay"
                            onChange={onChange('orderSelectionDelay')}
                            value={value.orderSelectionDelay}
                        />
                    </FormControl>
                    <FormControl className={classes.control}>
                        <Label
                            title={l('ServiceCenter.includesOrders')}
                            className={classes.checkboxLabel}
                        />
                        <div className={classes.wrapperCheckbox}>
                            <Checkbox
                                name="includeNotStartedOrders"
                                title={l('ServiceCenter.notStarted')}
                                value={value.includeNotStartedOrders}
                                onChange={onChange('includeNotStartedOrders')}
                                className={classes.checkbox}
                                data-testid="not-started-orders-checkbox"
                            />
                            <Checkbox
                                name="includePreBookings"
                                title={l('ServiceCenter.preBookings')}
                                value={value.includePreBookings}
                                onChange={onChange('includePreBookings')}
                                className={classes.checkbox}
                                data-testid="pre-bookings-checkbox"
                            />
                        </div>
                    </FormControl>
                </div>
            </Card>
            <ButtonContainer>
                <Button
                    onClick={reset}
                    key="reset"
                    secondary
                    label="Reset"
                    disabled={isEqual(value, scenarioEditorParameters) && !contextValue?.modified}
                />
                <Button
                    onClick={recalculate}
                    key="recalculate"
                    label="Recalculate Spreadsheets"
                    disabled={isEqual(value, scenarioEditorParameters)}
                />
            </ButtonContainer>
        </>
    )
}
