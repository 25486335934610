import React, {
    useCallback,
    useState,
    useMemo,
} from 'react'

import useLabel from 'app/hooks/useLabel'
import Card from 'app/shared-components/Card'
import Button from 'app/shared-components/Button'
import SimpleTable from 'app/shared-components/SimpleTable'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    RejectionReason,
} from 'app/types/enums'
import {
    Column,
} from 'app/types/simpletable.types'
import useContainerManagementRoutes from 'app/Apps/ContainerManagement/hooks/useContainerManagementRoutes'

import {
    DedicatedContainerType, RejectContainersRequestData,
} from '../../longTermLease.types'
import useRejectContainers from '../../hooks/useRejectContainers'

import getColumns from './dedicatedContainers.columns'
import TableRowComponent from './TableRowComponent'
import useStyles from './DedicatedContainers.styles'

const DedicatedContainers = ({
    value,
    onSuccess,
    longTermLeaseId,
    disabled,
}: {
    value: DedicatedContainerType[],
    onSuccess: () => void,
    longTermLeaseId: number,
    disabled: boolean,
}) => {
    const {
        classes,
    } = useStyles()

    const l = useLabel()

    const [
        data,
        setData,
    ] = useState<(DedicatedContainerType & {selected?: boolean})[]>(value)
    const rejectionReasons = useEnumValues()(RejectionReason)

    const {
        mutate,
        isPending,
    } = useRejectContainers(onSuccess)

    const {
        Containers: ContainersRoutes,
    } = useContainerManagementRoutes()

    const onChange = useCallback((newValue: DedicatedContainerType): void => {
        const updatedValue = data.map((item) => {
            if (item.id === newValue.id) {
                return newValue
            }

            return item
        })

        setData(updatedValue)
    }, [data])

    const getAvailableContainersLink = useCallback((containerSerialNumber: string): string => {
        return generateSkyNetLink({
            domainPath: ContainersRoutes.Overview.path,
            params: {
                key: containerSerialNumber,
            },
        })
    }, [ContainersRoutes.Overview.path])

    const columns = useMemo((): Column<keyof (DedicatedContainerType & {
        selected?: boolean,
    })>[] => {
        return getColumns({
            classes,
            l,
            rejectionReasons,
            getAvailableContainersLink,
            disabled,
        })
    }, [
        classes,
        l,
        rejectionReasons,
        getAvailableContainersLink,
        disabled,
    ])

    const selectedContainers: RejectContainersRequestData[] = useMemo(() => {
        return data.reduce((acc, {
            selected,
            rejectionReason,
            rejectionComment,
            id,
        }) => {
            if (selected && rejectionReason) {
                return [
                    ...acc,
                    {
                        rejectionReason,
                        rejectionComment,
                        bookedContainer: {
                            id,
                        },
                    },
                ]
            }

            return acc
        }, [])
    }, [data])

    const onRejectContainers = () => {
        mutate({
            data: selectedContainers,
            longTermLeaseId,
        })
    }

    return (
        disabled ? (
            <SimpleTable
                name="DedicatedContainers"
                columns={columns}
                data={data}
                component={TableRowComponent}
                disabled
            />
        )
            : (
                <Card
                    title="Dedicated Containers"
                    contentClass={classes.wrapper}
                    titleClass={classes.title}
                >
                    <SimpleTable
                        name="DedicatedContainers"
                        columns={columns}
                        data={data}
                        component={TableRowComponent}
                        onChange={onChange}
                        disabled
                    />
                    <ButtonContainer>
                        <Button
                            label="reject containers"
                            onClick={onRejectContainers}
                            disabled={!selectedContainers.length}
                            saving={isPending}
                            data-testid="btn-dedicated-containers"
                        />
                    </ButtonContainer>
                </Card>
            )
    )
}

export default DedicatedContainers
