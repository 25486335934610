import React, {
    useEffect, useMemo, useState,
} from 'react'
import omit from 'lodash/omit'

import Button from 'app/shared-components/Button'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import Tooltip from 'app/shared-components/Tooltip'
import {
    TransportModeRoad,
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import validateForm from 'app/utils/validateForm'

import {
    AdditionalLaneStepType,
    SelectLaneStepType,
} from '../../createCustomerTransportForm.types'

import getFields from './additionalLaneStep.field'
import useStyles from './AdditionalLaneStep.style'

type Props = {
    activeStep: number,
    setActiveStep: (val: number) => void,
    index: number,
    selectLaneData: SelectLaneStepType | Lane,
    value: AdditionalLaneStepType | Lane,
    onChange: (newVal: AdditionalLaneStepType | Lane) => void,
    onReset: () => void,
    onEdit: () => void,
}

const AdditionalLaneStep = ({
    activeStep,
    setActiveStep,
    index,
    selectLaneData,
    value,
    onChange,
    onReset,
    onEdit,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const {
        deliveryServiceType,
        collectionServiceType,
        transportModeRoad,
    } = value

    const [
        deliveryServiceTypeDefault,
        setDeliveryServiceTypeDefault,
    ] = useState<DeliveryServiceType>(deliveryServiceType)

    const [
        transportModeRoadDefault,
        setTransportModeRoadDefault,
    ] = useState<TransportModeRoad>()

    const {
        id: selectedLaneId,
    } = selectLaneData as Lane

    useEffect(() => {
        if (selectedLaneId) {
            onChange(selectLaneData as Lane)
            setActiveStep(activeStep + 1)

            return
        }

        onChange({
            ...value,
            ...omit(selectLaneData, [
                'selectedLaneId',
                'customerExecution',
            ]),
        })
    }, []) // eslint-disable-line

    useEffect(() => {
        if (transportModeRoadDefault !== transportModeRoad) {
            setTransportModeRoadDefault(transportModeRoad)

            let temperatureControlled = {
                isLtlPositioningTemperatureControlled: true,
                isFtlPositioningTemperatureControlled: true,
            }

            if (transportModeRoad === TransportModeRoad.LTL) {
                temperatureControlled = {
                    isLtlPositioningTemperatureControlled: true,
                    isFtlPositioningTemperatureControlled: null,
                }
            }

            if (transportModeRoad === TransportModeRoad.FTL) {
                temperatureControlled = {
                    isLtlPositioningTemperatureControlled: null,
                    isFtlPositioningTemperatureControlled: true,
                }
            }

            onChange({
                ...value,
                ...temperatureControlled,
            })
        }
    }, [
        onChange,
        transportModeRoad,
        transportModeRoadDefault,
        value,
    ])

    useEffect(() => {
        if (deliveryServiceTypeDefault !== deliveryServiceType) {
            setDeliveryServiceTypeDefault(deliveryServiceType)
            onChange({
                ...value,
                transportModeRoad: null,
            })
        }
    }, [
        deliveryServiceType,
        deliveryServiceTypeDefault,
        onChange,
        value,
    ])

    const fields = useFieldsWithClassName(getFields({
        getAllowedValues,
        deliveryServiceType,
        collectionServiceType,
        transportModeRoad,
        selectedLaneId,
    }), classes)

    const isFormValid: boolean = useMemo(() => {
        return validateForm({
            fields,
            value,
        })
    }, [
        fields,
        value,
    ])

    const onClickBack = (): void => {
        setActiveStep(index - 1)
        onReset()
    }

    const onClickEdit = (): void => {
        setActiveStep(index)
        onEdit()
    }

    return (
        <div>
            <DomainObjectForm
                value={value}
                onChange={onChange}
                fields={fields}
                className={classes.gridWrapper}
                disabled={Boolean(selectedLaneId) || activeStep > index}
                name="OrderByNumberView"
            />
            {!selectedLaneId && (
                <div className={classes.actions}>
                    {activeStep === index && (
                        <>
                            <Tooltip
                                title="Please fill in all mandatory fields to continue"
                                disabled={isFormValid}
                            >
                                <div>
                                    <Button
                                        label="Continue"
                                        onClick={() => { setActiveStep(index + 1) }}
                                        className={classes.button}
                                        disabled={!isFormValid}
                                        data-testid="btn-continue"
                                    />
                                </div>
                            </Tooltip>
                            <Button
                                label="Back"
                                secondary
                                onClick={onClickBack}
                                className={classes.button}
                                data-testid="btn-back"
                            />
                        </>
                    )}
                    {activeStep > index && (
                        <Button
                            label="Edit"
                            onClick={onClickEdit}
                            className={classes.button}
                            data-testid="btn-edit"
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default AdditionalLaneStep
