import React, {
    useCallback,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import useFilter from 'app/hooks/useFilter'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import {
    getEditedItems, useGetRequestSpreadSheetAdapter,
} from 'app/shared-components/SkyNetSpreadSheet'
import {
    useSkyNetPagination, SkyNetPaginationContext,
} from 'app/shared-components/SkyNetPagination'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import OpportunitiesSpreadSheet from '../OpportunitiesSpreadSheet'
import {
    OpportunityBulkEditType,
    OpportunityType,
} from '../opportunities.types'
import requests from '../opportunities.request'

import useOpportunitiesBulkEdit from './hooks/useOpportunitiesBulkEdit'
import dataAdapter from './OpportunitiesBulkEdit.utils'

const OpportunitiesBulkEdit = ({
    onClose,
}: {
    onClose: () => void
}) => {
    const filterOptions = useFilter()
    const paginationOptions = useSkyNetPagination(25)

    const {
        isError,
        loaded,
        error,
    } = useGetRequestSpreadSheetAdapter<OpportunityBulkEditType, OpportunityType>({
        filterOptions,
        paginationOptions,
        dataAdapter,
        config: requests.getAll,
        requestKey: RequestKeys.getOpportunities,
        enabled: false,
    })

    const opportunitiesEdit = useOpportunitiesBulkEdit()

    const onSave = useCallback((
        opportunities: OpportunityType[],
        options?: AsyncMutationOptions,
    ) => {
        const editedItems = getEditedItems({
            allItems: opportunities,
            initData: filterOptions.filteredData,
        })

        opportunitiesEdit(editedItems, {
            ...options,
            onSuccess: options?.onSuccess || onClose,
        })
    }, [
        filterOptions?.filteredData,
        onClose,
        opportunitiesEdit,
    ])

    return (
        <StatusHandler
            isError={isError}
            isFetching={!loaded}
            error={error}
            isSuccess={loaded}
        >
            <SkyNetPaginationContext.Provider value={paginationOptions}>
                <OpportunitiesSpreadSheet
                    onSave={onSave}
                    onCancel={onClose}
                    filterOptions={filterOptions}
                />
            </SkyNetPaginationContext.Provider>
        </StatusHandler>

    )
}

export default OpportunitiesBulkEdit
