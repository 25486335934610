import React, {
    useMemo,
} from 'react'
import {
    IconButton,
} from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import useExpandCollapseState from './hooks/useExpandCollapseState'
import useStyles from './SkyNetExpandCollapseButton.styles'

type SkyNetExpandCollapseButtonProps = {
    children: JSX.Element
    classNames?: {
        container?: string,
        content?: string,
        buttonContainer?: string,
        button?: string,
        icon?: string,
    },
    state?: boolean
    onStateChange?: (isExpanded: boolean) => void,
    enabled?: boolean,
}

export default function SkyNetExpandCollapseButton({
    children,
    classNames,
    state,
    onStateChange,
    enabled = true,
}: Readonly<SkyNetExpandCollapseButtonProps>) {
    const {
        classes,
        cx,
    } = useStyles()

    const {
        isExpanded,
        toggleExpand,
    } = useExpandCollapseState({
        externalState: state,
        onStateChange,
    })

    const Icon = useMemo(() => {
        return isExpanded ? ArrowBackIosIcon : ArrowForwardIosIcon
    }, [isExpanded])

    if (!enabled) return children

    return (
        <div className={cx(classes.container, classNames?.container)}>
            <div className={cx(classes.content, classNames?.content, {
                expanded: isExpanded,
                collapsed: !isExpanded,
            })}
            >
                {children}
            </div>
            <div className={cx(classes.buttonContainer, classNames?.buttonContainer)}>
                <div // eslint-disable-line
                    role="button"
                    data-testid="expand-collapse-button"
                    onClick={toggleExpand}
                    onKeyDown={toggleExpand}
                    className={cx(classes.button, classNames?.button, {
                        expanded: isExpanded,
                    })}
                >
                    <IconButton
                        className={cx(classes.icon, classNames?.icon)}
                    >
                        <Icon className={cx(classes.icon, classNames?.icon)} />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}
