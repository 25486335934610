import React, {
    useCallback,
    useMemo,
} from 'react'
import moment from 'moment'

import {
    monthYearShortMask,
} from 'app/utils/date/monthYear'
import StatusHandler from 'app/shared-components/StatusHandler'
import useFilter from 'app/hooks/useFilter'
import DataContentWrapper from 'app/shared-components/DataContentWrapper'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import {
    useSkyNetPagination, SkyNetPaginationContext,
} from 'app/shared-components/SkyNetPagination'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    useGetRequestSpreadSheetAdapter,
} from 'app/shared-components/SkyNetSpreadSheet'
import useBulkEdit from 'app/hooks/useBulkEdit'

import ForecastSpreadsheet from '../ForecastSpreadsheet'
import {
    ROWS_PER_REQUEST,
} from '../Forecast.constants'
import {
    dataToSpreadsheetColumnsMap,
} from '../Forecast.utils'
import {
    ForecastDataSpreadsheetColumnsConfig, ForecastSpreadsheetDataType, ForecastType,
} from '../Forecast.types'
import requests from '../Forecast.requests'

import useConvertToSpreadsheetData from './hooks/useConvertToSpreadsheetData'

const ForecastSubmission = ({
    onClose,
}: {
    onClose: () => void
}) => {
    const filterOptions = useFilter()
    const paginationOptions = useSkyNetPagination(ROWS_PER_REQUEST)
    const {
        forecastFields,
        actualDataFields,
        convertToSpreadsheetData,
    } = useConvertToSpreadsheetData()

    const {
        isError,
        error,
        refetch,
        loaded,
    } = useGetRequestSpreadSheetAdapter<ForecastType, ForecastSpreadsheetDataType>({
        filterOptions,
        paginationOptions,
        dataAdapter: convertToSpreadsheetData,
        config: requests.getAll,
        requestKey: RequestKeys.getForecastSubmissionData,
        enabled: true,
    })

    const save = useBulkEdit<ForecastType>({
        successMessage: 'Forecasts were updated',
        id: 'ForecastsBulkUpdate',
        requestConfig: requests.bulkUpdate,
    })

    const onSave = useCallback((items, options: AsyncMutationOptions) => {
        return save(items.map((item) => {
            const {
                id, isConfirmed,
            } = item

            return {
                id,
                isConfirmed,
                forecasts: Array.from(forecastFields).map(([key]) => {
                    const momentDate = moment(key, monthYearShortMask)

                    return {
                        month: momentDate.month() + 1,
                        year: momentDate.year(),
                        units: item[key],
                    }
                }),
            }
        }, []), {
            ...options,
            onSuccess: options?.onSuccess || refetch,
        })
    }, [
        save,
        refetch,
        forecastFields,
    ])

    const getFieldsMapper = useCallback(dataToSpreadsheetColumnsMap, [])

    const tmpFields: ForecastDataSpreadsheetColumnsConfig = useMemo(() => {
        return [
            ...Array.from(forecastFields).sort().reverse().map(getFieldsMapper({
                disabled: false,
            })),
            ...Array.from(actualDataFields).sort().reverse().map(getFieldsMapper({
                disabled: true,
            })),
        ]
    }, [
        actualDataFields,
        forecastFields,
        getFieldsMapper,
    ])

    return (
        <StatusHandler
            isError={isError}
            isFetching={!loaded}
            error={error}
            isSuccess={loaded}
        >
            <DataContentWrapper isEmpty={!loaded}>
                <SkyNetPaginationContext.Provider value={paginationOptions}>
                    <ForecastSpreadsheet
                        onSave={onSave}
                        onCancel={onClose}
                        tmpFields={tmpFields}
                        filterOptions={filterOptions}
                    />
                </SkyNetPaginationContext.Provider>
            </DataContentWrapper>
        </StatusHandler>
    )
}

export default ForecastSubmission
