import {
    FieldConfig,
    SkyNetSpreadSheetCellType,
    SkyNetSpreadSheetConfigType,
} from 'app/shared-components/SkyNetSpreadSheet'
import {
    PickupFeeType,
    RegionFirstLevel,
    RegionSecondLevel,
} from 'app/types/enums'

import {
    LogisticsCostsItem,
} from '../LogisticsCosts.types'

const isOriginCostPickUpCustomerNotAvailable = (value: LogisticsCostsItem) => {
    return value?.originCostPickUpCustomerAvailability !== PickupFeeType.CHARGE
}
const isOriginCostLtlNotAvailable = (value: LogisticsCostsItem) => {
    return value?.originCostLtlAvailability !== PickupFeeType.CHARGE
}
const isOriginCostFtlNotAvailable = (value: LogisticsCostsItem) => {
    return value?.originCostFtlAvailability !== PickupFeeType.CHARGE
}
const isCollectionLtlNotAvailable = (value: LogisticsCostsItem) => {
    return value?.collectionLtlAvailability !== PickupFeeType.CHARGE
}
const isCollectionFtlNotAvailable = (value: LogisticsCostsItem) => {
    return value?.collectionFtlAvailability !== PickupFeeType.CHARGE
}
const isReverseCostNotAvailable = (value: LogisticsCostsItem) => {
    return value?.reverseCostAvailability !== PickupFeeType.CHARGE
}

export default function LogisticsCostsSpreadsheetConfig({
    getLabel,
    getAllowedValues,
}: {
    getLabel: (s: string) => string,
    getAllowedValues: (type: Record<string, string>) => Record<string, string>,
}): SkyNetSpreadSheetConfigType<keyof LogisticsCostsItem> {
    const fields: FieldConfig<keyof LogisticsCostsItem>[] = [
        {
            name: 'regionFirstLevel',
            title: getLabel('Country.regionFirstLevel'),
            width: 150,
            disabled: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(RegionFirstLevel),
        },
        {
            name: 'regionSecondLevel',
            title: getLabel('Country.regionSecondLevel'),
            width: 120,
            disabled: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(RegionSecondLevel),
        },
        {
            name: 'countries',
            title: getLabel('LogisticsCostsRateCard.countries'),
            width: 150,
            disabled: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.TEXT,
            },
            allowedValues: getAllowedValues(RegionSecondLevel),
        },
        {
            name: 'originCostPickUpCustomerAvailability',
            title: getLabel('LogisticsCostsRateCard.originCostPickUpCustomerAvailability'),
            required: true,
            width: 320,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(PickupFeeType),
        },
        {
            name: 'originCostPickUpCustomer',
            title: getLabel(
                'LogisticsCostsRateCard.originCostPickUpCustomer',
            ),
            width: 220,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                conditionalType: [[
                    isOriginCostPickUpCustomerNotAvailable,
                    SkyNetSpreadSheetCellType.NO_VALUE,
                ]],
            },
        },
        {
            name: 'originCostLtlAvailability',
            title: getLabel('LogisticsCostsRateCard.originCostLtlAvailability'),
            required: true,
            width: 200,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(PickupFeeType),
        },
        {
            name: 'originCostLtl',
            title: getLabel(
                'LogisticsCostsRateCard.originCostLtl',
            ),
            width: 120,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                conditionalType: [[
                    isOriginCostLtlNotAvailable,
                    SkyNetSpreadSheetCellType.NO_VALUE,
                ]],
            },
        },
        {
            name: 'originCostFtlAvailability',
            title: getLabel('LogisticsCostsRateCard.originCostFtlAvailability'),
            required: true,
            width: 200,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(PickupFeeType),
        },
        {
            name: 'originCostFtl',
            title: getLabel(
                'LogisticsCostsRateCard.originCostFtl',
            ),
            width: 130,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                conditionalType: [[
                    isOriginCostFtlNotAvailable,
                    SkyNetSpreadSheetCellType.NO_VALUE,
                ]],
            },
        },
        {
            name: 'reverseCostAvailability',
            title: getLabel('LogisticsCostsRateCard.reverseCostAvailability'),
            required: true,
            width: 190,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(PickupFeeType),
        },
        {
            name: 'reverseCost',
            title: getLabel(
                'LogisticsCostsRateCard.reverseConst',
            ),
            width: 110,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                conditionalType: [[
                    isReverseCostNotAvailable,
                    SkyNetSpreadSheetCellType.NO_VALUE,
                ]],
            },
        },
        {
            name: 'collectionLtlAvailability',
            title: getLabel('LogisticsCostsRateCard.collectionLtlAvailability'),
            required: true,
            width: 230,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(PickupFeeType),
        },
        {
            name: 'collectionLtl',
            title: getLabel(
                'LogisticsCostsRateCard.collectionBySkyCellLTL',
            ),
            width: 200,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                conditionalType: [[
                    isCollectionLtlNotAvailable,
                    SkyNetSpreadSheetCellType.NO_VALUE,
                ]],
            },
        },
        {
            name: 'collectionFtlAvailability',
            title: getLabel('LogisticsCostsRateCard.collectionFtlAvailability'),
            required: true,
            width: 250,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(PickupFeeType),
        },
        {
            name: 'collectionFtl',
            title: getLabel(
                'LogisticsCostsRateCard.collectionBySkyCellFTL',
            ),
            width: 190,
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
                conditionalType: [[
                    isCollectionFtlNotAvailable,
                    SkyNetSpreadSheetCellType.NO_VALUE,
                ]],
            },
        },
        {
            name: 'id',
            width: 1,
            hidden: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.ID,
            },
        },
    ]

    return {
        id: 'LogisticCosts',
        title: 'Logistic Costs',
        headerRow: true,
        rowsCounter: true,
        headerHeight: 60,
        addRowMenuOption: false,
        enableRangeSelection: true,
        enableRowSelection: true,
        stickyTopRows: 1,
        filterable: false,
        rowsLimit: 25,
        emptyRows: 0,
        initialFocusLocation: {
            columnId: 'regionFirstLevel',
            rowId: 0,
        },
        fields,
    }
}
