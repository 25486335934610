import {
    tss,
} from './config'

export default tss.create(({
    theme,
}) => {
    return {
        cardWrapper: {
            marginTop: theme.spacing(2),
        },
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            boxSizing: 'border-box',
            border: 'none',
            position: 'static',
        },
        tableContent: {
            boxShadow: 'none !important',
        },
        headerWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        actionWrapper: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            color: theme.palette.primary.main,
        },
    }
})
