import React from 'react'

import DamageTypeUpdate from '../DamageTypeUpdate'

const DamageTypeOverview = ({
    id,
}: { id: number }) => {
    return (
        <DamageTypeUpdate
            id={id}
            disabled
        />
    )
}

export default DamageTypeOverview
