import React, {
    useMemo,
} from 'react'

import {
    LaneFeesFormContext,
} from 'app/shared-components/LaneFees'

import LaneGroupContextWrapper from '../LaneGroupContextWrapper'

import getFields from './LaneFeesTable/LaneFeesForm.fields'
import LaneFeesTable from './LaneFeesTable'

const LaneFees = ({
    id,
}: { id: number }) => {
    const value = useMemo(() => {
        return {
            getFields,
        }
    }, [])

    return (
        <LaneGroupContextWrapper id={id}>
            <LaneFeesFormContext.Provider value={value}>
                <LaneFeesTable />
            </LaneFeesFormContext.Provider>
        </LaneGroupContextWrapper>
    )
}

export default LaneFees
