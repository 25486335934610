import React from 'react'

import ContainerAvailabilityForecast from './ContainerAvailabilityForecast'
import DryStorage from './DryStorage'
import TruckCapacity from './TruckCapacity'

const Monitoring = ({
    id,
}: {
    id: number
}) => {
    return (
        <>
            <ContainerAvailabilityForecast id={id} />
            <DryStorage id={id} />
            <TruckCapacity id={id} />
        </>
    )
}

export default Monitoring
