import React, {
    useCallback,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'

import StatusHandler from 'app/shared-components/StatusHandler'

import useGetAQuote from '../hooks/useGetAQuote'
import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'

import AQuoteUpdate from './AQuoteUpdate'

const AQuoteUpdateContainer = ({
    id,
}: {id: number}) => {
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetAQuote(id)
    const {
        AQuotes: AQuotesRoutes,
    } = useAccountManagementRoutes()

    const {
        state,
    } = useLocation()

    const onSuccess = useCallback(() => {
        if (state?.path) {
            AQuotesRoutes.Overview.navigate({
                params: {
                    key: data?.id,
                },
            })
        }

        invalidate()
    }, [
        state?.path,
        invalidate,
        AQuotesRoutes.Overview,
        data?.id,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <AQuoteUpdate
                data={data}
                onSuccess={onSuccess}
            />
        </StatusHandler>
    )
}

export default AQuoteUpdateContainer
