import {
    createContext, useContext, Dispatch, SetStateAction,
} from 'react'
import {
    AccountLane,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import {
    ContractCurrency,
    RegionSecondLevel,
} from 'app/types/enums'
import {
    Contact,
} from 'app/types/common.types'
import {
    User,
} from 'app/Apps/ContactManagement/Users/Users.types'
import {
    LeaseTypePossibilityStatus,
    ProductTypeCQuote,
    RateCardsData,
} from '../../CQuotes.types'

export type CQuoteDeliveryOptions = Pick<AccountLane,
    'collectionDropoffPoint' | 'handoverPoint'
    | 'deliveryServiceType' | 'collectionServiceType'
>

export type CQuoteLaneDetails = AccountLane & {
    productType: ProductTypeCQuote,
    originRegion: RegionSecondLevel,
    destinationRegion: RegionSecondLevel,
    originCountry: {id: number},
    destinationCountry: {id: number},
    allowedLeaseType: LeaseTypePossibilityStatus,
}

export type CQuoteOfferSetup = {
    currency: ContractCurrency,
    rentalDays: number,
    logisticsDays: number,
    customerQuotation: { id: number },
    plannedPaymentTerms: number,
}

export type CQuoteContactDetails = {
    skyCellContact: User,
    quotedFor: Contact,
    generatePdf?: boolean
}

export type TCQuoteStepper = {
    lane?: {
        id: number
    },
    laneDetails: CQuoteLaneDetails,
    deliveryOptions: CQuoteDeliveryOptions,
    nonEditable?: boolean,
    rateCardsData: RateCardsData,
    offerSetup: CQuoteOfferSetup,
    contactDetails: CQuoteContactDetails,
    isGroupedLaneStatuses?: boolean,
}

export type QuoteInformationContextType = {
    activeStep: number,
    setActiveStep: Dispatch<SetStateAction<number>>,
    value: TCQuoteStepper,
    initialValue?: TCQuoteStepper,
    setValue: Dispatch<SetStateAction<Partial<TCQuoteStepper>>>,
};

export const CQuoteInformationContext = createContext<QuoteInformationContextType>(null)

const useCQuoteInformationContext = () => {
    return useContext<QuoteInformationContextType>(CQuoteInformationContext)
}

export default useCQuoteInformationContext
