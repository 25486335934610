import React, {
    useCallback,
} from 'react'
import Grid from '@mui/material/Unstable_Grid2'

import {
    RequiredContainersInternal,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    OrderTemperatureRange,
} from 'app/types/enums'
import {
    ContainerType,
} from 'app/Apps/ContainerManagement/Containers/containers.types'
import Select from 'app/shared-components/Select'
import InputSingleline from 'app/shared-components/InputSingleline'
import ContainerTypeSelector from 'app/shared-components/ContainerTypeSelector'

import AdvancedList, {
    ListColumn,
} from '../../AdvancedList'
import useStyles from './RequiredContainersByContainerTypeList.styles'

const defaultValue: Partial<RequiredContainersInternal> = {
    quantity: 1,
    containerType: {},
}

type Props = {
    value?: RequiredContainersInternal[],
    disabled?: boolean,
    required?: boolean,
    containerTypes: ContainerType[],
    onChange: (changeValue: RequiredContainersInternal[]) => void,
    temperatureRange?: Omit<OrderTemperatureRange, 'BT'>,
}

const defaultProps = {
    value: [],
    disabled: false,
    required: true,
    temperatureRange: undefined,
}

const columns: ListColumn[] = [
    {
        colSpan: 6,
        label: 'Container Type',
    },
    {
        colSpan: true,
        label: 'Amount',
        align: 'right',
    },
]

const convertToSelectorOptions = (containerTypes: ContainerType[]) => {
    return containerTypes?.reduce((acc, element) => {
        return {
            ...acc,
            [element.id]: element.containerModel,
        }
    }, {})
}

const RequiredContainersByContainerTypeList = ({
    value,
    onChange,
    disabled,
    required,
    containerTypes,
    temperatureRange,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const onItemCellChange = useCallback(({
        item, newValue, index, handleChange,
    }) => {
        const newItem = {
            ...item,
            ...newValue,
        }

        handleChange(newItem, index)
    }, [])

    const validateItemForAdd = useCallback((item: RequiredContainersInternal) => {
        return item.quantity > 0
            && Boolean(item.containerType?.id)
    }, [])

    const renderItem = useCallback(({
        index,
        item,
        handleChange,
        state,
    }) => {
        const filteredOptions = containerTypes.filter((key) => {
            return !state.some((el) => {
                return Number(el.containerType?.id) === key.id && el !== item
            })
        })

        return (
            <>
                <Grid
                    key={`row-container-${index}`}
                    xs={columns[0].colSpan}
                >
                    {
                        temperatureRange === OrderTemperatureRange.ANY_TEMP_RANGE ? (
                            <ContainerTypeSelector
                                value={item.containerType?.id}
                                disabled={disabled}
                                required={required}
                                onChange={(newValue) => {
                                    return onItemCellChange({
                                        newValue: {
                                            containerType: {
                                                id: newValue,
                                            },
                                        },
                                        index,
                                        item,
                                        handleChange,
                                    })
                                }}
                                classNames={{
                                    label: classes.selectorLabel,
                                }}
                                noneOption={false}
                            />
                        ) : (
                            <Select
                                name="containerType"
                                allowedValues={convertToSelectorOptions(filteredOptions)}
                                value={item.containerType?.id}
                                onChange={(newValue) => {
                                    return onItemCellChange({
                                        newValue: {
                                            containerType: {
                                                id: newValue,
                                            },
                                        },
                                        index,
                                        item,
                                        handleChange,
                                    })
                                }}
                                required={required}
                                disabled={disabled}
                                noNone
                            />
                        )
                    }
                </Grid>
                <Grid
                    key={`row-ammount-${index}`}
                    xs={columns[1].colSpan}
                >
                    <InputSingleline
                        name="amount"
                        type="number"
                        min={1}
                        centerAligned
                        onChange={(newValue) => {
                            return onItemCellChange({
                                index,
                                item,
                                handleChange,
                                newValue: {
                                    quantity: Number(newValue),
                                },
                            })
                        }}
                        className={classes.input}
                        value={item.quantity}
                        required={required}
                        disabled={disabled}
                    />
                </Grid>
            </>
        )
    }, [
        containerTypes,
        temperatureRange,
        disabled,
        required,
        classes.selectorLabel,
        classes.input,
        onItemCellChange,
    ])

    return (
        <AdvancedList<RequiredContainersInternal>
            onChange={onChange}
            disabled={disabled}
            value={value}
            renderItem={renderItem}
            defaultValue={defaultValue}
            addButtonLabel="new container"
            columns={columns}
            validateForAdd={validateItemForAdd}
        />
    )
}

RequiredContainersByContainerTypeList.defaultProps = defaultProps

export default RequiredContainersByContainerTypeList
