// eslint-disable-next-line import/prefer-default-export
export enum ContactManagementDomains {
    Companies = 'companies',
    Locations = 'locations',
    BillingCompanies = 'billing-companies',
    Contacts = 'contacts',
    Countries = 'countries',
    Users = 'users',
    AirportsAndSeaports = 'ports',
}
