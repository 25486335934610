import {
    useMemo,
} from 'react'

import {
    ValidationResult,
} from 'app/types/form.types'
import useGetCountryEmbargo from 'app/Apps/Rates/RateCards/EmbargoCountries/hooks/useGetCountryEmbargo'

export default function useEmbargoValidations({
    origin,
    destination,
}: {
    origin: number,
    destination: number,
}) {
    const {
        data: embargoOrigin,
    } = useGetCountryEmbargo(origin)

    const {
        data: embargoDestination,
    } = useGetCountryEmbargo(destination)

    return useMemo(() => {
        return {
            embargoOrigin,
            embargoDestination,
            validateEmbargoOrigin: (): ValidationResult => {
                if (embargoOrigin?.embargoed) {
                    return ['Quote Declined - Embargo']
                }

                return undefined
            },
            validateEmbargoDestination: (): ValidationResult => {
                if (embargoDestination?.embargoed) {
                    return ['Quote Declined - Embargo']
                }

                return undefined
            },
        }
    }, [
        embargoOrigin,
        embargoDestination,
    ])
}
