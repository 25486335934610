import {
    RichTableColumns,
} from 'app/types/richtable.types'

import {
    PreBooking, PreBookingStatus,
} from 'app/Apps/PreBooking/PreBooking.types'
import {
    ProductType,
} from 'app/types/enums'
import {
    FilterType,
} from 'app/types/common.enums'

const columns = ({
    l,
}: {
    l: (l: string) => string,
}): RichTableColumns<keyof PreBooking>[] => {
    return [
        {
            id: 'preBookingNumber',
            headerProps: {
                children: l('Prebooking.number'),
            },
            mapCellProps: 'preBookingNumber',
        },
        {
            id: 'laneNumber',
            headerProps: {
                children: l('Prebooking.lane'),
            },
            mapCellProps: 'laneNumber',
        },
        {
            id: 'customerExecution',
            headerProps: {
                children: l('Prebooking.customerExecution'),
            },
            mapCellProps: 'customerExecution',
            filterField: 'customerExecution',
            filterType: FilterType.Array,
        },
        {
            id: 'pricingAccountCompanyName',
            headerProps: {
                children: l('Prebooking.pricingAccount'),
            },
            mapCellProps: 'pricingAccountCompanyName',
            filterField: 'pricingAccountCompanyName',
            filterType: FilterType.Array,
        },
        {
            id: 'originAirportIataCode',
            headerProps: {
                children: l('Prebooking.originAirport'),
            },
            mapCellProps: 'originAirportIataCode',
            filterField: 'originAirportIataCode',
            filterType: FilterType.Array,
        },
        {
            id: 'destinationAirportIataCode',
            headerProps: {
                children: l('Prebooking.destinationAirport'),
            },
            mapCellProps: 'destinationAirportIataCode',
            filterField: 'destinationAirportIataCode',
            filterType: FilterType.Array,
        },
        {
            id: 'requestedHandover',
            headerProps: {
                children: l('Prebooking.requestedHandover'),
            },
            mapCellProps: 'requestedHandover',
            filterField: 'requestedHandover',
            filterType: FilterType.DateRange,
            componentName: 'DateTimeCell',
        },
        {
            id: 'preBookingStatus',
            headerProps: {
                children: l('Prebooking.prebookingStatus'),
            },
            mapCellProps: 'preBookingStatus',
            componentName: 'EnumValue',
            allowedValuesEnum: PreBookingStatus,
        },
        {
            id: 'requiredContainers',
            headerProps: {
                children: l('Prebooking.requiredContainers'),
            },
            mapCellProps: 'requiredContainers',
            componentName: 'RequiredContainersList',
            allowedValuesEnum: ProductType,
        },
    ]
}

export default columns
