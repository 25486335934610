import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requests from '../../Logger.request'

const useSendRecoveryRequest = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessRecoveryRequest,
        onError,
    } = useHandleStatusRequest({
        title: 'Recovery request was successfully sent',
        id: 'SendRecoveryRequest',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            loggerNumber,
            data,
        }:{
            loggerNumber: string,
            data: {
                from: string,
                to: string,
                sensorCodes: string[]
            }
        }) => {
            return simpleApi({
                ...requests.recoveryRequest({
                    loggerNumber,
                    data,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessRecoveryRequest,
    })
}

export default useSendRecoveryRequest
