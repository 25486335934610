import React from 'react'
import noop from 'lodash/noop'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'

import {
    PreBookingEntity,
} from '../../PreBooking.types'

import useStyles from './PreBookingLaneDetails.styles'

type Props = {
    disabled: boolean,
    onChange?: (a: PreBookingEntity) => void,
    formValues: PreBookingEntity,
    fields: any,
    showLaneInfo?: boolean,
}

const defaultProps: Partial<Props> = {
    onChange: noop,
    showLaneInfo: false,
}

const PreBookingLaneDetails = ({
    disabled,
    onChange,
    formValues,
    fields,
    showLaneInfo,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const fieldsWithClass = useFieldsWithClassName(fields, classes)

    return (
        <DomainObjectForm
            name="PreBookingInformation"
            className={showLaneInfo ? classes.gridWrapperWithGeneral : classes.gridWrapper}
            value={formValues}
            fields={fieldsWithClass}
            onChange={onChange}
            disabled={disabled}
        />
    )
}

PreBookingLaneDetails.defaultProps = defaultProps

export default PreBookingLaneDetails
