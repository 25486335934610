import React, {
    useCallback,
} from 'react'

import useFilter from 'app/hooks/useFilter/useFilter'
import {
    getEditedItems, useGetRequestSpreadSheetAdapter,
} from 'app/shared-components/SkyNetSpreadSheet'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import {
    useSkyNetPagination, SkyNetPaginationContext,
} from 'app/shared-components/SkyNetPagination'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import {
    PreBooking,
    PreBookingEntity,
} from '../PreBooking.types'
import PreBookingSpreadsheet from '../PreBookingSpreadsheet'
import {
    convertToPrebookingEntity,
    convertToPrebookingEntitySpreadsheetData,
} from '../PreBookingSpreadsheet/PrebookingSpreadsheet.utils'
import requests from '../PreBookings.requests'
import {
    PreBookingEntitySpreadsheetData,
} from '../PreBookingSpreadsheet/PreBookingSpreadsheet.config'

import usePreBookingsBulkEdit from './hooks/usePreBookingsBulkEdit'

const PreBookingBulkEdit = ({
    onClose,
}: Readonly<{onClose: () => {}}>) => {
    const filterOptions = useFilter()
    const paginationOptions = useSkyNetPagination(25)

    const {
        isError,
        loaded,
        error,
    } = useGetRequestSpreadSheetAdapter<PreBooking, PreBookingEntitySpreadsheetData>({
        filterOptions,
        paginationOptions,
        dataAdapter: convertToPrebookingEntitySpreadsheetData,
        config: requests.filterRequestForBulkEdit,
        requestKey: RequestKeys.getPreBookings,
        enabled: false,
    })

    const prebookingBulkEdit = usePreBookingsBulkEdit()

    const onSave = useCallback((
        prebookings: PreBookingEntity[],
        options?: AsyncMutationOptions,
    ) => {
        const editedItems = getEditedItems({
            allItems: prebookings,
            initData: filterOptions.filteredData,
        })

        prebookingBulkEdit(convertToPrebookingEntity(editedItems), {
            ...options,
            onSuccess: options?.onSuccess || onClose,
        })
    }, [
        filterOptions?.filteredData,
        onClose,
        prebookingBulkEdit,
    ])

    return (
        <StatusHandler
            isError={isError}
            isFetching={!loaded}
            error={error}
            isSuccess={loaded}
        >
            <SkyNetPaginationContext.Provider value={paginationOptions}>
                <PreBookingSpreadsheet
                    onSave={onSave}
                    onCancel={onClose}
                    filterOptions={filterOptions}
                />
            </SkyNetPaginationContext.Provider>
        </StatusHandler>
    )
}

export default PreBookingBulkEdit
