import React, {
    useState, useRef, useMemo,
} from 'react'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    useSharedContext,
} from 'app/shared-components/SharedContext'
import StatusHandler from 'app/shared-components/StatusHandler'
import useHasUnreadAlerts from './hooks/useHasUnreadAlerts'
import NotificationsList from './NotificationsList'

const AlertNotifications = () => {
    const height: number = useSharedContext() || 0
    const alertsReadPermission = useHasPermission(['alerts_read'])
    const bellRef = useRef<HTMLDivElement>(null)
    const [
        anchorEl,
        setAnchorEl,
    ] = useState<HTMLDivElement | null>(null)

    const onClick = (
        event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    ) => {
        setAnchorEl(event.currentTarget)
    }

    const onClose = () => {
        setAnchorEl(null)
    }
    const {
        data,
        isDataReady,
        invalidate,
    } = useHasUnreadAlerts({
        enabled: alertsReadPermission,
    })

    const iconPath = `/assets/images/icons/notification_bell${data?.hasUnreadAlerts ? '_unread' : ''}.svg`
    const element = useMemo(() => {
        return (
            <>
                <div
                    data-testid="IconContainer"
                    ref={bellRef}
                    role="button"
                    onClick={onClick}
                    onKeyDown={onClick}
                    tabIndex={0}
                >
                    <img
                        data-testid="Icon"
                        src={iconPath}
                        alt="alert"
                    />
                </div>
                <NotificationsList
                    open={Boolean(anchorEl)}
                    onClose={onClose}
                    anchorEl={anchorEl}
                    anchorPosition={{
                        top: height ?? 0,
                        left: bellRef?.current?.getBoundingClientRect()?.right ?? 0,
                    }}
                    hasUnreadAlertsInvalidate={invalidate}
                />
            </>
        )
    }, [
        anchorEl,
        height,
        iconPath,
        invalidate,
    ])

    if (!alertsReadPermission) {
        return null
    }

    return (
        <StatusHandler
            isError={false}
            isFetching={false}
            error={null}
            isSuccess={isDataReady}
            fallback={element}
        >
            {element}
        </StatusHandler>
    )
}

export default AlertNotifications
