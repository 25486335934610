import React from 'react'
import {
    Grid,
} from '@mui/material'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import FormWithControls from 'app/shared-components/FormWithControls'
import StatusHandler from 'app/shared-components/StatusHandler'
import useEnumValues from 'app/hooks/useEnumValues'

import useGetContainerBySerialNumber from '../../hooks/useGetContainerBySerialNumber'
import containerRequests from '../../Containers.request'

import useStyles from './ChangeData.styles'
import getFields from './ChangeData.fields'

export type ContainersChangeDataProps = {
    id: string,
}

const ChangeData = ({
    id: serialNumber,
}: ContainersChangeDataProps) => {
    const {
        classes,
    } = useStyles()

    const getAllowedValues = useEnumValues()

    const fieldsWithClasses = useFieldsWithClassName(
        getFields(getAllowedValues), classes,
    )

    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetContainerBySerialNumber(serialNumber)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    xs={12}
                    className={classes.fullHeight}
                >
                    <FormWithControls
                        name={containerRequests.name}
                        value={data}
                        onSuccess={invalidate}
                        fields={fieldsWithClasses}
                        classNames={{
                            gridWrapper: classes.gridWrapper,
                        }}
                        exists
                        requestParams={containerRequests.update({
                            serialNumber,
                        })}
                    />
                </Grid>
            </Grid>
        </StatusHandler>
    )
}

export default ChangeData
