import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    LoggerContainerInfo,
} from 'app/Apps/ContainerManagement/Containers/containers.types'
import {
    NestedKeyOf,
} from 'app/types/utils.types'
import {
    LoggerType,
    LoggerConfiguration,
} from 'app/types/enums'

const loggerPairingColumns: RichTableColumns<NestedKeyOf<LoggerContainerInfo>>[] = [
    {
        id: 'logger.loggerNumber',
        headerProps: {
            children: 'Logger',
        },
        mapCellProps: 'logger.loggerNumber',
        minWidth: '150px',
    },
    {
        id: 'logger.loggerType',
        headerProps: {
            children: 'Logger Type',
        },
        mapCellProps: 'logger.loggerType',
        componentName: 'EnumValue',
        allowedValuesEnum: LoggerType,
        minWidth: '200px',
    },
    {
        id: 'isCurrentLogger',
        headerProps: {
            children: 'Is Current',
        },
        mapCellProps: 'isCurrentLogger',
        componentName: 'Boolean',
        width: '90px',
    },
    {
        id: 'isDefaultLogger',
        headerProps: {
            children: 'Is Default',
        },
        mapCellProps: 'isDefaultLogger',
        componentName: 'Boolean',
        width: '90px',
    },
    {
        id: 'loggerPositionConfiguration',
        headerProps: {
            children: 'Logger Configuration',
        },
        mapCellProps: 'loggerPositionConfiguration',
        componentName: 'EnumValue',
        allowedValuesEnum: LoggerConfiguration,
        minWidth: '150px',
    },
    {
        id: 'exchangeTimestamp',
        headerProps: {
            children: 'Exchange Timestamp',
        },
        mapCellProps: 'exchangeTimestamp',
        minWidth: '200px',
        componentName: 'DateTimeCell',
    },
    {
        id: 'removedOn',
        headerProps: {
            children: 'Removed On',
        },
        mapCellProps: 'removedOn',
        minWidth: '250px',
        componentName: 'DateTimeCell',
    },
]

export default loggerPairingColumns
