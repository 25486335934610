import React, {
    useCallback, useState, useMemo,
} from 'react'
import isEqual from 'lodash/isEqual'

import useEnumValues from 'app/hooks/useEnumValues'
import useLabel from 'app/hooks/useLabel'
import SkyNetSpreadSheet, {
    SkyNetSpreadSheetControlPanel,
} from 'app/shared-components/SkyNetSpreadSheet'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'

import {
    MarginsTable,
} from '../Margins.types'
import getConfig from './MarginsSpreadsheet.config'

export default function MarginsSpreadsheet({
    data,
    onSave,
    onCancel,
}: Readonly<{
    data: MarginsTable[];
    onSave: (v: MarginsTable[], o?: AsyncMutationOptions) => void;
    onCancel?: (...args: any[]) => void;
}>) {
    const getAllowedValues = useEnumValues()
    const getLabel = useLabel()
    const [
        value,
        setValue,
    ] = useState<MarginsTable[]>(data)

    const handleSave = useCallback(
        async (options?: { onSuccess?: (...a: any) => void }) => {
            return onSave(value, options)
        },
        [
            onSave,
            value,
        ],
    )

    const config = useMemo(() => {
        return getConfig({
            getLabel,
            getAllowedValues,
        })
    }, [
        getLabel,
        getAllowedValues,
    ])

    const edited = !isEqual(data, value)

    return (
        <SkyNetSpreadSheetControlPanel
            onSave={handleSave}
            onCancel={onCancel}
            edited={edited}
        >
            <SkyNetSpreadSheet
                config={config}
                value={value}
                onChange={setValue}
            />
        </SkyNetSpreadSheetControlPanel>
    )
}
