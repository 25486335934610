import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            marginLeft: theme.spacing(1.5),
            flexGrow: 1,
            flexShrink: 1,
        },
        emptyState: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
            flexShrink: 1,
            marginLeft: theme.spacing(1.5),
        },
        chart: {
            height: 775,
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        button: {
            marginTop: theme.spacing(3),
        },
    }
})

export default useStyles
