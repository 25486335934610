import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    NestedKeyOf,
} from 'app/types/utils.types'

import {
    SimplifiedCQuoteCreateType,
} from '../../simplifiedCQuoteCreate.types'

const fields : DTOCardFormField<NestedKeyOf<SimplifiedCQuoteCreateType>>[] = [{
    id: 'contactDetails',
    title: 'Contact Details',
    className: 'contactDetails',
    fields: [
        {
            id: 'contactDetails-column1',
            fields: [{
                name: 'skycellContact',
                componentName: 'UserObjectSelector',
                required: true,
                labelKey: 'AccountQuotes.skycellContact',
            }],
        },
        {
            id: 'contactDetails-column2',
            fields: [{
                name: 'contactNameAndSurname',
                componentName: 'InputSingleline',
                required: true,
                labelKey: 'AccountQuotes.contactNameAndSurname',
            }],
        },
        {
            id: 'contactDetails-column3',
            fields: [{
                name: 'contactEmail',
                componentName: 'InputSingleline',
                required: true,
                labelKey: 'AccountQuotes.contactEmail',
            }],
        },
    ],
}]

export default fields
