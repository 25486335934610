import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    CQuoteDeliveryOptions,
} from '../../CQuoteInformationContext'

export default ({
    getAllowedValues,
    deliveryOptions,
    collectionOptions,
}: {
    getAllowedValues: (a: Record<string, string>) => Record<string, string>,
    deliveryOptions: Record<string, string>,
    collectionOptions: Record<string, string>,
}): DTOCardFormField<keyof CQuoteDeliveryOptions>[] => {
    return [{
        title: 'Delivery Options',
        id: 'deliveryOptions',
        fields: [
            {
                name: 'deliveryServiceType',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(deliveryOptions),
                showInColumns: true,
                labelKey: 'AccountLane.deliveryServiceType',
                required: true,
                className: 'deliveryServiceType',
            },
            {
                name: 'handoverPoint',
                componentName: 'LocationExpandedViewObjectSelector',
                labelKey: 'LaneGroup.handoverPoint',
                required: true,
                className: 'handoverPoint',
            },
            {
                name: 'collectionServiceType',
                componentName: 'EnumSelector',
                showInColumns: true,
                allowedValues: getAllowedValues(collectionOptions),
                labelKey: 'AccountLane.collectionServiceType',
                required: true,
                className: 'collectionServiceType',
            },
            {
                name: 'collectionDropoffPoint',
                componentName: 'LocationExpandedViewObjectSelector',
                labelKey: 'LaneGroup.collectionDropoffPoint',
                required: true,
                className: 'collectionDropoffPoint',
            },
        ],
    }]
}
