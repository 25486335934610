import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'

import {
    RateCardsValues,
} from './RateCards.types'
import getBaseLeaseConfig from './BaseLease/BaseLease.config'
import getEmbargoCountriesConfig from './EmbargoCountries/EmbargoContries.config'
import getOtherLaneFeesConfig from './OtherLaneFees/OtherLaneFees.config'
import getAdditionalFeesConfig from './AdditionalFees/AdditionalFees.config'
import getMarginsConfig from './Margins/Margins.config'
import getLogisticsCostsConfig from './LogisticsCosts/LogisticsCosts.config'
import getRegionalServiceAvailabilityConfig from './RegionalServiceAvailability/RegionalServiceAvailability.config'

const config: SkyNetTableConfig<keyof RateCardsValues> = {
    name: 'RateCards',
    tabs: [
        getBaseLeaseConfig(),
        getOtherLaneFeesConfig(),
        getAdditionalFeesConfig(),
        getMarginsConfig(),
        getLogisticsCostsConfig(),
        getRegionalServiceAvailabilityConfig(),
        getEmbargoCountriesConfig(),
    ],
}

export default config
