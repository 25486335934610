import {
    useMemo,
} from 'react'
import flatten from 'lodash/flatten'
import {
    ReactComponent as OrdersIcon,
} from 'app/shared-components/images/icons/order.svg'
import {
    ReactComponent as ContainersIcon,
} from 'app/shared-components/images/icons/container.svg'
import {
    ReactComponent as SensorsIcon,
} from 'app/shared-components/images/icons/sensors.svg'
import {
    ReactComponent as FinanceIcon,
} from 'app/shared-components/images/icons/finance.svg'
import {
    ReactComponent as LocationIcon,
} from 'app/shared-components/images/icons/location.svg'
import {
    ReactComponent as LanesIcon,
} from 'app/shared-components/images/icons/lanes.svg'
import {
    ReactComponent as TransportPlannerIcon,
} from 'app/shared-components/images/icons/transport_planner.svg'
import {
    ReactComponent as RelationshipsManagementIcon,
} from 'app/shared-components/images/icons/relationships_management.svg'
import {
    ReactComponent as AccountManagementIcon,
} from 'app/shared-components/images/icons/account_management.svg'
import {
    ReactComponent as DamagesIcon,
} from 'app/shared-components/images/icons/damages.svg'
import {
    ReactComponent as ForecastIcon,
} from 'app/shared-components/images/icons/forecast.svg'
import {
    ReactComponent as RateCardsIcon,
} from 'app/shared-components/images/icons/rate_cards.svg'

import authRoles from 'app/authRoles'
import {
    ActiveTab,
} from 'app/MainMenu/MainMenu.types'
import {
    App,
} from 'app/SkyNetRoutes'
import useContactManagementRoutes from 'app/Apps/ContactManagement/hooks/useContactManagementRoutes'
import useRelationshipsManagementRoutes
    from 'app/Apps/RelationshipsManagement/hooks/useRelationshipsManagementRoutes'
import useAccountManagementRoutes from 'app/Apps/AccountManagement/hooks/useAccountManagementRoutes'
import usePricingRoutes from 'app/Apps/Pricing/hooks/usePricingRoutes'
import useForecastRoutes from 'app/Apps/Forecast/hooks/useForecastRoutes'
import useOrderManagementRoutes from 'app/Apps/OrderManagement/hooks/useOrderManagementRoutes'
import useContainerManagementRoutes from 'app/Apps/ContainerManagement/hooks/useContainerManagementRoutes'
import useLoggerRoutes from 'app/Apps/Loggers/hooks/useLoggerRoutes'
import useGatewaysRoutes from 'app/Apps/Sensors/Gateways/hooks/useGatewayRoutes'
import useDeploymentRoutes from 'app/Apps/Sensors/Deployments/hooks/useDeploymentRoutes'
import useFinanceRoutes from 'app/Apps/Finance/hooks/useFinanceRoutes'
import useTransportPlannerRoutes from 'app/Apps/TransportPlanner/hooks/useTransportPlannerRoutes'
import useDamageTrackingRoutes from 'app/Apps/DamageTracking/hooks/useDamageTrackingRoutes'
import useRatesRoutes from 'app/Apps/Rates/hooks/useRatesRoutes'
import {
    OrderManagementDomains,
} from 'app/Apps/OrderManagement/OrderManagement.types'
import {
    ContactManagementDomains,
} from 'app/Apps/ContactManagement/ContactManagement.types'
import {
    AccountManagementDomains,
} from 'app/Apps/AccountManagement/AccountManagement.types'
import {
    PricingDomains,
} from 'app/Apps/Pricing/Pricing.types'
import {
    ForecastDomains,
} from 'app/Apps/Forecast/Forecast.types'
import {
    ContainerManagementDomains,
} from 'app/Apps/ContainerManagement/ContainerManagement.types'
import {
    FinanceDomains,
} from 'app/Apps/Finance/Finance.types'
import {
    TransportPlannerDomains,
} from 'app/Apps/TransportPlanner/TransportPlanner.types'
import {
    DamageTrackingDomains,
} from 'app/Apps/DamageTracking/Damages/Damages.types'
import {
    RatesDomains,
} from 'app/Apps/Rates/Rates.types'

const [accountReadAll] = authRoles.account_read_all
const [accountReadRestricted] = authRoles.account_read_restricted

export default function useMainMenuConfig(): ActiveTab[] {
    const ContactManagementRoutes = useContactManagementRoutes()
    const RelationshipsManagementRoutes = useRelationshipsManagementRoutes()
    const AccountManagementRoutes = useAccountManagementRoutes()
    const PricingRoutes = usePricingRoutes()
    const ForecastRoutes = useForecastRoutes()
    const OrderManagementRoutes = useOrderManagementRoutes()
    const ContainerManagementRoutes = useContainerManagementRoutes()
    const RatesRoutes = useRatesRoutes()
    const {
        Loggers: LoggerRoutes,
    } = useLoggerRoutes()
    const {
        Gateways: GatewaysRoutes,
    } = useGatewaysRoutes()
    const {
        Deployments: DeploymentRoutes,
    } = useDeploymentRoutes()
    const FinanceRoutes = useFinanceRoutes()
    const TransportPlannerRoutes = useTransportPlannerRoutes()
    const DamageTrackingRoutes = useDamageTrackingRoutes()

    return useMemo(() => {
        return [
            {
                title: 'Contact Management',
                id: App.CONTACT_MANAGEMENT,
                subTitleText: 'Most of SkyCell\'s business partners get managed here.',
                icon: LocationIcon,
                className: 'iconSmall',
                links: [
                    {
                        title: 'Locations',
                        id: ContactManagementDomains.Locations,
                        auth: authRoles.location_read,
                        url: ContactManagementRoutes.Locations.pattern,
                    },
                    {
                        title: 'Billing Companies',
                        id: ContactManagementDomains.BillingCompanies,
                        auth: authRoles.location_read,
                        url: ContactManagementRoutes.BillingCompanies.pattern,
                    },
                    {
                        title: 'Companies',
                        id: ContactManagementDomains.Companies,
                        auth: authRoles.location_read,
                        url: ContactManagementRoutes.Companies.pattern,
                    },
                    {
                        title: 'Airports and Seaports',
                        id: ContactManagementDomains.AirportsAndSeaports,
                        auth: authRoles.location_read,
                        url: ContactManagementRoutes.AirportsAndSeaports.pattern,
                    },
                    {
                        title: 'Contacts',
                        id: ContactManagementDomains.Contacts,
                        auth: authRoles.location_read,
                        url: ContactManagementRoutes.Contacts.pattern,
                    },
                    {
                        title: 'Countries',
                        id: ContactManagementDomains.Countries,
                        auth: authRoles.location_read,
                        url: ContactManagementRoutes.Countries.pattern,
                    },
                    {
                        title: 'Users',
                        id: ContactManagementDomains.Users,
                        auth: authRoles.user_read,
                        url: ContactManagementRoutes.Users.pattern,
                    },
                ],
            },
            {
                title: 'Relationships Management',
                id: App.RELATIONSHIPS_MANAGEMENT,
                subTitleText: 'Manage relationships with business partners here.',
                icon: RelationshipsManagementIcon,
                className: 'iconSmall',
                links: [
                    {
                        title: 'Activity Records',
                        id: 'activity-records',
                        auth: [
                            ...authRoles.relationships_ops,
                            ...authRoles.relationships_sales,
                        ],
                        url: RelationshipsManagementRoutes.ActivityRecords.pattern,
                    },
                    {
                        title: 'Engagements',
                        id: 'engagements',
                        auth: [...authRoles.relationships_sales],
                        url: RelationshipsManagementRoutes.Engagements.pattern,
                    },
                ],
            },
            {
                title: 'Account Management',
                id: App.ACCOUNT_MANAGEMENT,
                subTitleText: 'Develop business with existing customers and create new ones.',
                icon: AccountManagementIcon,
                className: 'iconLarge',
                links: [
                    {
                        title: 'Dashboard',
                        id: AccountManagementDomains.KamDashboard,
                        auth: authRoles.account_quote_lane_award_reject,
                        url: AccountManagementRoutes.KamDashboard.pattern,
                    },
                    {
                        title: 'Accounts',
                        id: AccountManagementDomains.Accounts,
                        auth: [
                            accountReadAll,
                            accountReadRestricted,
                        ],
                        url: AccountManagementRoutes.Accounts.pattern,
                    },
                    {
                        title: 'Regional Groups',
                        id: AccountManagementDomains.RegionalGroups,
                        auth: [
                            accountReadAll,
                            accountReadRestricted,
                        ],
                        url: AccountManagementRoutes.RegionalGroups.pattern,
                    },
                    {
                        title: 'Opportunities',
                        id: AccountManagementDomains.Opportunitites,
                        auth: [
                            accountReadAll,
                            accountReadRestricted,
                        ],
                        url: AccountManagementRoutes.Opportunities.pattern,
                    },
                    {
                        title: 'Lanes',
                        id: AccountManagementDomains.Lanes,
                        auth: [
                            accountReadAll,
                            accountReadRestricted,
                        ],
                        url: AccountManagementRoutes.Lanes.pattern,
                    },
                    {
                        title: 'A-Quotes',
                        id: AccountManagementDomains.AQuotes,
                        auth: [
                            accountReadAll,
                            accountReadRestricted,
                        ],
                        url: AccountManagementRoutes.AQuotes.pattern,
                    },
                    {
                        title: 'C-Quotes',
                        id: AccountManagementDomains.CQuotes,
                        auth: [
                            accountReadAll,
                            accountReadRestricted,
                        ],
                        url: AccountManagementRoutes.CQuotes.pattern,
                    },
                    {
                        title: 'Pre Bookings',
                        id: AccountManagementDomains.PreBookings,
                        auth: authRoles.pre_booking_read,
                        url: AccountManagementRoutes.PreBookings.pattern,
                    },
                ],
            },
            {
                title: 'Pricing',
                id: App.PRICING,
                subTitleText: 'Access pricing relevant information that SkyCell maintains with it\'s customers.',
                icon: LanesIcon,
                className: 'iconMedium',
                links: [
                    {
                        title: 'Dashboard',
                        id: PricingDomains.PricingDashboard,
                        auth: authRoles.account_quote_lane_group,
                        url: PricingRoutes.PricingDashboard.pattern,
                    },
                    {
                        title: 'Contract Basis',
                        id: PricingDomains.ContractBasis,
                        auth: authRoles.contract_basis_read,
                        url: PricingRoutes.ContractBasis.pattern,
                    },
                    {
                        title: 'Lane Groups',
                        id: PricingDomains.LaneGroups,
                        auth: authRoles.contract_basis_read,
                        url: PricingRoutes.LaneGroups.pattern,
                    },
                    {
                        title: 'Lanes',
                        id: PricingDomains.Lanes,
                        auth: authRoles.contract_basis_read,
                        url: PricingRoutes.Lanes.pattern,
                    },
                    {
                        title: 'Al Logistic Pricing',
                        id: PricingDomains.AlLogisticPricing,
                        auth: authRoles.contract_basis_read,
                        url: PricingRoutes.AlLogisticPricing.pattern,
                    },
                    {
                        title: 'Al Lease Pricing',
                        id: PricingDomains.AlLeasePricing,
                        auth: authRoles.contract_basis_read,
                        url: PricingRoutes.AlLeasePricing.pattern,
                    },
                    {
                        title: 'A-Quotes',
                        id: PricingDomains.AQuotes,
                        auth: authRoles.contract_basis_read,
                        url: PricingRoutes.AQuotes.pattern,
                    },
                ],
            },
            {
                title: 'Forecast',
                id: App.FORECAST,
                subTitleText: 'Check and create forecast data for future shipments.',
                icon: ForecastIcon,
                className: 'iconMedium',
                links: [{
                    title: 'Short Term Forecast',
                    id: ForecastDomains.ShortTermForecasts,
                    auth: flatten([
                        authRoles.forecast_read,
                        authRoles.forecast_super,
                    ]),
                    url: ForecastRoutes.ShortTermForecast.pattern,
                }],
            },
            {
                title: 'Order Management',
                id: App.ORDER_MANAGEMENT,
                subTitleText: 'Create and update customer and internal orders.',
                icon: OrdersIcon,
                links: [
                    {
                        title: 'Daily Ops',
                        id: OrderManagementDomains.DailyOps,
                        auth: authRoles.order_read,
                        url: OrderManagementRoutes.DailyOps.pattern,
                    },
                    {
                        title: 'Pooling',
                        id: OrderManagementDomains.Pooling,
                        auth: authRoles.order_read,
                        url: OrderManagementRoutes.Pooling.pattern,
                    },
                    {
                        title: 'Open Bookings',
                        id: OrderManagementDomains.OpenBookings,
                        auth: authRoles.order_read,
                        url: OrderManagementRoutes.OpenBookings.pattern,
                    },
                    {
                        title: 'Lanes',
                        id: OrderManagementDomains.Lanes,
                        auth: authRoles.contract_basis_read,
                        url: OrderManagementRoutes.Lanes.pattern,
                    },
                    {
                        title: 'Customer Orders',
                        id: OrderManagementDomains.CustomerOrders,
                        auth: authRoles.order_read,
                        url: OrderManagementRoutes.CustomerOrders.pattern,
                    },
                    {
                        title: 'Internal Orders',
                        id: OrderManagementDomains.InternalOrders,
                        auth: authRoles.order_read,
                        url: OrderManagementRoutes.InternalOrders.pattern,
                    },
                    {
                        title: 'Long-Term Lease',
                        id: OrderManagementDomains.LongTermLease,
                        auth: authRoles.long_term_lease_read,
                        url: OrderManagementRoutes.LongTermLease.pattern,
                    },
                    {
                        title: 'Pre Bookings',
                        id: OrderManagementDomains.PreBookings,
                        auth: authRoles.pre_booking_read,
                        url: OrderManagementRoutes.PreBookings.pattern,
                    },
                    {
                        title: 'Proforma Invoices',
                        id: OrderManagementDomains.ProformaInvoices,
                        auth: authRoles.proforma_rule_read,
                        url: OrderManagementRoutes.ProformaInvoices.pattern,
                    },
                ],
            },
            {
                title: 'Container Management',
                id: App.CONTAINERS,
                subTitleText: 'Manage SkyCell\'s container fleet and check the status of service centers around the world.',
                icon: ContainersIcon,
                className: 'iconLarge',
                links: [
                    {
                        title: 'Containers',
                        id: ContainerManagementDomains.Containers,
                        auth: authRoles.container_read,
                        url: ContainerManagementRoutes.Containers.pattern,
                    },
                    {
                        title: 'Container Selection',
                        id: ContainerManagementDomains.ContainersSelection,
                        auth: authRoles.container_read,
                        url: ContainerManagementRoutes.ContainersSelection.pattern,
                    },
                    {
                        title: 'Container Types',
                        id: ContainerManagementDomains.ContainerTypes,
                        auth: authRoles.container_read,
                        url: ContainerManagementRoutes.ContainerTypes.pattern,
                    },
                    {
                        title: 'Service Centers',
                        id: ContainerManagementDomains.ServiceCenters,
                        auth: authRoles.service_center_read,
                        url: ContainerManagementRoutes.ServiceCenters.pattern,
                    },
                ],
            },
            {
                title: 'Loggers And Gateways',
                id: 'loggers-and-gateways',
                subTitleText: 'Check the logger inventory and the gateways feeding data into our system.',
                icon: SensorsIcon,
                links: [
                    {
                        title: 'Loggers',
                        id: App.LOGGERS,
                        auth: authRoles.logger_read,
                        url: LoggerRoutes.pattern,
                    },
                    {
                        title: 'Gateways',
                        id: App.GATEWAYS,
                        auth: authRoles.gateway_read,
                        url: GatewaysRoutes.pattern,
                    },
                    {
                        title: 'Deployments',
                        id: App.DEPLOYMENTS,
                        auth: authRoles.gateway_read,
                        url: DeploymentRoutes.pattern,
                    },
                ],
            },
            {
                title: 'Billing',
                id: App.FINANCE,
                subTitleText: 'Prepare invoices resulting from shipments done for customers.',
                icon: FinanceIcon,
                className: 'iconMedium',
                links: [
                    {
                        title: 'Invoices',
                        id: FinanceDomains.Invoices,
                        auth: authRoles.outgoing_invoice_read,
                        url: FinanceRoutes.Invoices.pattern,
                    },
                    {
                        title: 'FX Rates',
                        id: FinanceDomains.FxRates,
                        auth: authRoles.fxrate_read,
                        url: FinanceRoutes.FxRates.pattern,
                    },
                ],
            },
            {
                title: 'Transport Planner',
                id: App.TRANSPORT_PLANNER,
                subTitleText: 'Simulate a transport to see if the planned lane does not lead to excursions.',
                icon: TransportPlannerIcon,
                className: 'iconMedium',
                links: [
                    {
                        title: 'New Temperature Simulation',
                        id: TransportPlannerDomains.Simulation,
                        auth: authRoles.transport_planner_create,
                        url: TransportPlannerRoutes.NewSimulation.pattern,
                    },
                    {
                        title: 'Saved Temperature Simulations',
                        id: TransportPlannerDomains.SavedSimulations,
                        auth: authRoles.transport_planner_read,
                        url: TransportPlannerRoutes.SavedSimulations.pattern,
                    },
                    {
                        title: `CO${String.fromCharCode(8322)} Calculation`,
                        id: TransportPlannerDomains.C02Calculation,
                        auth: authRoles.transport_planner_read,
                        url: TransportPlannerRoutes.CO2Calculation.pattern,
                    },
                ],
            },
            {
                title: 'Damage Tracking',
                id: App.DAMAGE_TRACKING,
                subTitleText: 'Manage damages on containers and check the status of repairs.',
                icon: DamagesIcon,
                className: 'iconMedium',
                links: [
                    {
                        title: 'Damages',
                        id: DamageTrackingDomains.Damages,
                        auth: authRoles.damage_tracking_read,
                        url: DamageTrackingRoutes.Damages.pattern,
                    },
                    {
                        title: 'Damage Types',
                        id: DamageTrackingDomains.DamageTypes,
                        auth: authRoles.damage_types_read,
                        url: DamageTrackingRoutes.DamageTypes.pattern,
                    },
                ],
            },
            {
                title: 'Rate Cards',
                id: App.RATES,
                subTitleText: 'Review and manage rate cards used for automated pricing.',
                icon: RateCardsIcon,
                className: 'iconMedium',
                links: [{
                    title: 'Rate Cards',
                    id: RatesDomains.RateCards,
                    auth: authRoles.rates_read,
                    url: RatesRoutes.RateCards.pattern,
                }],
            },
        ] as ActiveTab[]
    }, [
        AccountManagementRoutes,
        ContactManagementRoutes,
        ContainerManagementRoutes,
        DamageTrackingRoutes,
        DeploymentRoutes,
        FinanceRoutes,
        ForecastRoutes,
        GatewaysRoutes,
        LoggerRoutes,
        OrderManagementRoutes,
        PricingRoutes,
        RelationshipsManagementRoutes,
        TransportPlannerRoutes,
        RatesRoutes,
    ])
}
