/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useEffect,
    useRef,
} from 'react'
import {
    DragDropContext,
    Droppable,
} from 'react-beautiful-dnd'

import {
    SimulationStep,
} from '../simulation.types'

import StepItemDnd from './StepItemDnd'
import useStyles from './RouteSteps.styles'

type Props = {
    value: SimulationStep[],
    deleteStep: (index: number) => void,
    moveStep: (dragIndex: number, hoverIndex: number) => void,
    onChangeStep: (step: SimulationStep) => void,
    addStep: () => void,
}

const RouteSteps = ({
    value,
    moveStep,
    onChangeStep,
    addStep,
    deleteStep,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const lastElm = useRef<HTMLDivElement & {scrollIntoViewIfNeeded:() => void}>()
    const isFirstRun = useRef<boolean>(true)

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false
            return
        }

        if (lastElm.current?.scrollIntoViewIfNeeded) {
            lastElm.current.scrollIntoViewIfNeeded()
        } else {
            // IE, Firefox
            lastElm.current?.scrollIntoView()
        }
    }, [value.length])

    const onDragEnd = ({
        source,
        destination,
    }: {
        source: {index: number},
        destination: {index: number},
    }): void => {
        const sourceIndex = source?.index
        const destinationIndex = destination?.index

        if (!destination || sourceIndex === destinationIndex) {
            return
        }

        moveStep(sourceIndex, destinationIndex)
    }

    return (
        <div className={classes.container}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                    droppableId="StepComponentsDroppable"
                    direction="horizontal"
                >
                    {(provided) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={classes.stepsContainer}
                            >
                                {value.map((step, index, list) => {
                                    return (
                                        <div
                                            key={step.id}
                                            ref={index === list.length - 1 ? lastElm : null}
                                            data-testid={`stepItem-${index}`}
                                        >
                                            <StepItemDnd
                                                value={step}
                                                onChangeStep={onChangeStep}
                                                index={index}
                                                deleteStep={deleteStep}
                                                canBeDeleted={list.length !== 1}
                                            />
                                        </div>
                                    )
                                })}
                                {provided.placeholder}
                            </div>
                        )
                    }}
                </Droppable>
            </DragDropContext>
            <div
                onMouseDown={addStep}
                className={classes.addBtn}
                data-testid="add-step"
            >
                +
            </div>
        </div>
    )
}

export default RouteSteps
