import React from 'react'

import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import ServiceCenterForm from '../ServiceCenterForm'
import request from '../serviceCenters.request'
import {
    ServiceCenterStatus,
    StorageTableType,
    StorageTypeEnum,
} from '../serviceCenters.types'

const ServiceCenterCreate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    return (
        <ServiceCenterForm
            data={{
                storages: [
                    {
                        storageCapacity: 0,
                        storageType: StorageTypeEnum.DRY,
                        processingTime: 0,
                    },
                    {
                        storageCapacity: 0,
                        storageType: StorageTypeEnum.C,
                        processingTime: 2,
                    },
                ] as StorageTableType[],
                serviceCenterStatus: ServiceCenterStatus.PLANNED,
                enhancedSkyCenterScanning: true,
                orderSelectionDelay: 2,
            }}
            onSuccess={onSuccess}
            exists={false}
            requestParam={request.CreateServiceCenter()}
        />
    )
}

export default ServiceCenterCreate
