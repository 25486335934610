/* eslint-disable class-methods-use-this */
import React from 'react'
import {
    Cell,
    CellTemplate,
    Compatible,
    getCellProperty,
    keyCodes,
    Uncertain,
    UncertainCompatible,
} from '@silevis/reactgrid'
import isEmpty from 'lodash/isEmpty'

import {
    TooltipWrapper,
} from 'app/shared-components/Tooltip'

import getCellOptions from '../../getCellOptions'
import {
    SkyNetSpreadSheetCellType,
} from '../../SkyNetSpreadSheet.types'
import SelectableElement from '../../SelectableElement'
import MultipleSelectCellWrapper from './MultipleSelectCell'

export interface MultipleSelectCell extends Cell {
    type: SkyNetSpreadSheetCellType.MULTIPLE_SELECT,
    text?: string,
    id?: string | number,
    selectedValue: string[]
    name?: string,
}

export default class MultipleSelectCellTemplate implements CellTemplate<MultipleSelectCell> {
    private configId: string

    private delimiter = '; '

    private getCellOptions = getCellOptions

    constructor({
        configId,
    }: {
        configId: string
    }) {
        this.configId = configId
    }

    findOption(cell: Uncertain<MultipleSelectCell>, selectedValue: string) {
        return this.getCellOptions(cell, this.configId).find(({
            value: optionValue,
        }) => {
            return optionValue === selectedValue
        })
    }

    getLabels(
        cell: Uncertain<MultipleSelectCell> | Compatible<MultipleSelectCell>,
        selectedValue: string[],
    ): string[] {
        return (selectedValue || []).reduce((acc, value) => {
            return [
                ...acc,
                this.findOption(cell, value)?.label,
            ]
        }, [])
    }

    getCompatibleCell(
        uncertainCell: Uncertain<MultipleSelectCell>,
    ): Compatible<MultipleSelectCell> {
        let selectedValue

        try {
            selectedValue = getCellProperty(uncertainCell, 'selectedValue', 'object')
        } catch {
            selectedValue = []
        }

        return {
            ...uncertainCell,
            selectedValue,
            text: this.getLabels(uncertainCell, selectedValue).join(this.delimiter),
            value: 0,
        }
    }

    handleKeyDown(
        cell: Compatible<MultipleSelectCell>,
        keyCode: number,
    ): { cell: Compatible<MultipleSelectCell>, enableEditMode: boolean } {
        return {
            cell,
            enableEditMode:
                keyCode === keyCodes.POINTER
                || keyCode === keyCodes.ENTER || keyCode === keyCodes.SPACE,
        }
    }

    getSelectedValueByText(text: string, cell: Compatible<MultipleSelectCell>) {
        return (text.split(this.delimiter) || []).reduce((acc, el) => {
            const option = this.getCellOptions(cell, this.configId).find(({
                label,
            }) => { return label === el })

            if (option?.value) {
                return [
                    ...acc,
                    option.value,
                ]
            }

            return acc
        }, [])
    }

    update(
        cell: Compatible<MultipleSelectCell>,
        cellToMerge: UncertainCompatible<MultipleSelectCell>,
    ): Compatible<MultipleSelectCell> {
        if (cellToMerge.type === SkyNetSpreadSheetCellType.MULTIPLE_SELECT) {
            return this.getCompatibleCell({
                ...cell,
                ...cellToMerge,
                selectedValue: this.getSelectedValueByText(cellToMerge.text, cell),
                text: cellToMerge.selectedValue?.length
                    ? this.getLabels(cellToMerge, cellToMerge.selectedValue).join(this.delimiter)
                    : cellToMerge.text,
            })
        }

        return this.getCompatibleCell({
            ...cell,
            text: cellToMerge.text,
            selectedValue: this.getSelectedValueByText(cellToMerge.text, cell),
        })
    }

    getTooltipValue(cell: Compatible<MultipleSelectCell>): JSX.Element {
        if (isEmpty(cell.text)) return null
        return (
            <div>
                { (cell.text.split(this.delimiter) || []).map((text, i) => {
                    // eslint-disable-next-line react/no-array-index-key
                    return <div key={`${i}-${text}`}>{text}</div>
                })}
            </div>
        )
    }

    render(
        cell: Compatible<MultipleSelectCell>,
        isInEditMode: boolean,
        onCellChanged: (cell: Compatible<MultipleSelectCell>, commit: boolean) => void,
    ): React.ReactNode {
        if (!isInEditMode) {
            return (
                <TooltipWrapper
                    value={this.getTooltipValue(cell)}
                    ownTooltipProps={{
                        placement: 'top',
                        followCursor: true,
                        arrow: false,
                    }}
                    styles={{
                        width: '100%',
                    }}
                >
                    <SelectableElement
                        text={cell.text}
                        id={cell.id}
                        name={cell.name}
                        editMode={false}
                    />
                </TooltipWrapper>
            )
        }

        return (
            <MultipleSelectCellWrapper
                cell={cell}
                options={this.getCellOptions(cell, this.configId)}
                onChange={({
                    selected,
                }) => {
                    return onCellChanged(this.getCompatibleCell({
                        ...cell,
                        selectedValue: selected,
                    }), true)
                }}
            />
        )
    }
}
