import React, {
    useMemo, useState, useCallback,
} from 'react'
import isEmpty from 'lodash/isEmpty'

import useLabel from 'app/hooks/useLabel'
import RichTable from 'app/shared-components/RichTableReduxWrapper/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import Button from 'app/shared-components/Button'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import StatusHandler from 'app/shared-components/StatusHandler'
import useLoadFilters from 'app/Apps/DomainObject/hooks/useLoadFilters'
import requests from 'app/Apps/Pricing/LaneGroups/LaneFees/LaneFees.requests'
import {
    TableResponse,
} from 'app/types/request.types'
import useRequestTable from 'app/hooks/useRequestTable'
import useTableStyles from 'app/tss/table.styles'

import useBulkAwardLaneFees from './hooks/useBulkAwardLaneFees'
import useBulkRejectLaneFees from './hooks/useBulkRejectLaneFees'
import RejectLaneFeesConfirmationDialog from './RejectLaneFeesConfirmationDialog'
import getColumns from './AwardRejectLaneFees.columns'
import useStyles from './AwardRejectLaneFees.styles'
import {
    AwardRejectLaneFeesTable,
} from './AwardRejectLaneFees.types'

const AwardRejectLaneFees = ({
    filters,
    actionLink,
}: Readonly<{
    filters: Record<'aQuoteId', number> | Record<'cQuoteId', number>,
    actionLink?: JSX.Element
}>) => {
    const {
        classes,
    } = useStyles()
    const {
        classes: tableClasses,
    } = useTableStyles()

    const {
        isDataReady,
        isFetching,
        isError,
        error,
        loadData,
        refetch,
        onParamsChange,
    } = useRequestTable<TableResponse<AwardRejectLaneFeesTable[]>>({
        key: RequestKeys.laneFeesFilterRequestAwardReject,
        params: {
            data: {
                filters,
            },
        },
        config: requests[RequestKeys.laneFeesFilterRequestAwardReject],
        keepPreviousData: true,
        enabled: !isEmpty(filters),
    })

    const injectLoadFilters = useLoadFilters(
        {
            customUrl: requests[RequestKeys.laneFeesFilterRequestAwardReject]().url,
            preDefinedFilters: filters,
        },
    )

    const l = useLabel()
    const [
        confirmation,
        setConfirmation,
    ] = useState(false)
    const [
        selected,
        setSelected,
    ] = useState([])

    const columnsWithFilters = useMemo(() => {
        return injectLoadFilters(getColumns({
            l,
        }))
    }, [
        injectLoadFilters,
        l,
    ])

    const onSuccess = useCallback(() => {
        refetch()
        setSelected([])
    }, [refetch])

    const bulkAward = useBulkAwardLaneFees({
        onSuccess,
    })
    const bulkReject = useBulkRejectLaneFees({
        onSuccess,
    })

    const selectedLaneFees: {id: number}[] = useMemo(() => {
        return selected.map((laneFeesId) => {
            return {
                id: laneFeesId,
            }
        })
    }, [selected])

    const bulkRejectHandler = useCallback((data) => {
        bulkReject({
            ...data,
            laneFees: selectedLaneFees,
        })
    }, [
        bulkReject,
        selectedLaneFees,
    ])

    return (
        <Card
            title="Lane Fees"
            actionLink={actionLink}
            titleClass={tableClasses.headerWrapper}
        >
            <StatusHandler
                isFetching={isFetching}
                isError={isError}
                isSuccess={isDataReady}
                error={error}
            >
                <>
                    <RichTable
                        configName="AwardRejectLaneFees"
                        name="AwardRejectLaneFees"
                        load={loadData}
                        showSelectAll
                        selectedRows={selected}
                        onSelectRow={setSelected}
                        onParamsChange={onParamsChange}
                        columns={columnsWithFilters}
                        className={classes.tableContent}
                        classNames={{
                            contentWrapper: classes.tableContentWrapper,
                        }}
                    />
                    <ButtonContainer>
                        <Button
                            key="set-as-awarded"
                            label="Set as awarded"
                            onClick={() => {
                                bulkAward(selectedLaneFees)
                            }}
                            disabled={!selected.length}
                        />
                        <Button
                            key="set-as-rejected"
                            label="Set as rejected"
                            onClick={() => {
                                setConfirmation(true)
                            }}
                            disabled={!selected.length}
                        />
                    </ButtonContainer>
                    <RejectLaneFeesConfirmationDialog
                        open={confirmation}
                        action={bulkRejectHandler}
                        close={() => {
                            setConfirmation(false)
                        }}
                    />
                </>
            </StatusHandler>
        </Card>
    )
}

export default AwardRejectLaneFees
