import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    Currency,
} from 'app/types/enums'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    QuoteStatus,
    AQuoteTable,
} from './AQuotes.types'

const config = (): SkyNetTableConfig<keyof AQuoteTable> => {
    return {
        name: 'quote',
        tabs: [{
            name: 'All',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'quoteNumber',
                    labelKey: 'AccountQuotes.quoteNumber',
                    filterField: 'quoteNumber',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'quotedForCompanyLocationName',
                    labelKey: 'AccountQuotes.quotedForCompany',
                    filterField: 'quotedForCompanyLocationName',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'accountName',
                    labelKey: 'AccountQuotes.accountName',
                    filterField: 'accountName',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'currency',
                    labelKey: 'AccountQuotes.currency',
                    componentName: 'EnumValue',
                    allowedValuesEnum: Currency,
                    filterField: 'currency',
                    filterType: FilterType.Array,
                },
                {
                    name: 'quoteStatus',
                    labelKey: 'LaneBaseDto.laneStatus',
                    filterField: 'quoteStatus',
                    componentName: 'EnumValue',
                    allowedValuesEnum: QuoteStatus,
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'pricingResponsible',
                    labelKey: 'AccountQuotes.pricingResponsible',
                    componentName: 'List',
                    filterField: 'pricingResponsible',
                    filterType: FilterType.Array,
                },
                {
                    name: 'createdBy',
                    labelKey: 'AccountQuotes.createdBy',
                    filterField: 'createdBy',
                    filterType: FilterType.Array,
                },
            ],
        }],
    }
}

export default config
