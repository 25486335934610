import React from 'react'
import {
    CommonRoutes, useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import useAccountManagementRoutes from 'app/Apps/AccountManagement/hooks/useAccountManagementRoutes'
import CQuoteCreateSelectMethod from './CQuoteCreateSelectMethod'

export default function CQuoteCreateSelectMethodRouter() {
    const {
        CQuotes,
    } = useAccountManagementRoutes()

    return useSkyNetRoutes({
        routes: [{
            path: CommonRoutes.SLASH.concat(CQuotes.Create.stringParams),
            element: <CQuoteCreateSelectMethod />,
        }],
    })
}
