import React from 'react'
import clsx from 'clsx'

import {
    OrderStatuses,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    OrderStatusTransition,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import {
    StatusLongTermLease,
} from '../../../LongTermLease/longTermLease.types'

import useStyles from './OrderStatusHistory.style'

const getIconName = ({
    status,
    completedOn,
    orderStatus,
}) => {
    if (completedOn) {
        return 'completed'
    }

    if (status === orderStatus) {
        return 'in_progress'
    }

    return 'not_started'
}

type Props = {
    value?: OrderStatusTransition[]
    orderStatus?: OrderStatuses | StatusLongTermLease,
}

const defaultProps: Partial<Props> = {
    value: [],
    orderStatus: undefined,
}

const OrderStatusHistory = ({
    value,
    orderStatus,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const allowedValuesOrderStatus = useEnumValues()(OrderStatuses)

    return (
        <>
            {
                value.map(({
                    to,
                    completedOn,
                    completedBy,
                }, index) => {
                    const iconName = getIconName({
                        status: to,
                        completedOn,
                        orderStatus,
                    })

                    return (
                        <div
                            className={classes.root}
                            key={`order-status-history-${to}-${index}`} /* eslint-disable-line react/no-array-index-key */
                        >
                            <div className={
                                clsx(
                                    classes.iconWrapper,
                                    index === 0 && classes.iconWrapperFirst,
                                    index === (value.length - 1) && classes.iconWrapperLast,
                                )
                            }
                            >
                                {index !== 0 && (
                                    <div className={
                                        clsx(classes.line, classes.firstLine)
                                    }
                                    />
                                )}
                                <img
                                    src={`assets/images/orderSteps/${iconName}.svg`}
                                    alt={allowedValuesOrderStatus[to]}
                                />
                                {
                                    index !== (value.length - 1)
                                    && <div className={clsx(classes.line, classes.secondLine)} />
                                }
                            </div>
                            <div>
                                <div className={classes.title}>{allowedValuesOrderStatus[to]}</div>
                                {completedBy && <div className={classes.info}>{completedBy}</div>}
                                {completedOn && <div className={classes.info}>{completedOn}</div>}
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

OrderStatusHistory.defaultProps = defaultProps

export default OrderStatusHistory
