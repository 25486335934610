import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    LaneFee,
} from 'app/Apps/Pricing/Pricing.types'
import {
    LaneFeesStatus,
} from 'app/types/enums'

const columns: RichTableColumns<keyof LaneFee>[] = [
    {
        headerProps: {
            children: 'Pricing',
        },
        id: 'laneFeesNumber',
        mapCellProps: 'laneFeesNumber',
    },
    {
        headerProps: {
            children: 'Status',
        },
        id: 'laneFeesStatus',
        mapCellProps: 'laneFeesStatus',
        filterField: 'laneFeesStatus',
        filterType: FilterType.Array,
        componentName: 'EnumValue',
        allowedValuesEnum: LaneFeesStatus,
    },
    {
        headerProps: {
            children: 'Perf. Qualification',
        },
        id: 'isPerformanceQualification',
        mapCellProps: 'isPerformanceQualification',
        filterField: 'isPerformanceQualification',
        componentName: 'ChipCheckMark',
        filterType: FilterType.Array,
    },
    {
        headerProps: {
            children: 'Valid From',
        },
        id: 'validFrom',
        mapCellProps: 'validFrom',
        filterField: 'validFrom',
        filterType: FilterType.DateRange,
        componentName: 'DateTimeCell',
    },
    {
        headerProps: {
            children: 'Valid To',
        },
        id: 'validTo',
        mapCellProps: 'validTo',
        filterField: 'validTo',
        filterType: FilterType.DateRange,
        componentName: 'LaneDateEnriched',
    },
    {
        headerProps: {
            children: 'Rental Days',
        },
        id: 'rentalDays',
        mapCellProps: 'rentalDays',
        filterField: 'rentalDays',
    },
    {
        headerProps: {
            children: 'Logistics Days',
        },
        id: 'logisticsDays',
        mapCellProps: 'logisticsDays',
        filterField: 'logisticsDays',
    },
    {
        headerProps: {
            children: 'Changed On',
        },
        id: 'changedOn',
        mapCellProps: 'changedOn',
        componentName: 'DateTimeCell',
    },
    {
        headerProps: {
            children: 'Changed By',
        },
        id: 'changedBy',
        mapCellProps: 'changedBy',
        filterField: 'changedBy',
    },
]

export default columns
