import React, {
    useCallback,
    useMemo, useState,
} from 'react'
import isEqual from 'lodash/isEqual'

import SkyNetSpreadSheet, {
    SkyNetSpreadSheetControlPanel, useTypeaheadOptions,
} from 'app/shared-components/SkyNetSpreadSheet'
import useLabel from 'app/hooks/useLabel'
import useEnumValues from 'app/hooks/useEnumValues'
import useEnumLabels from 'app/hooks/useEnumLabels'
import useGetAccountOptions from 'app/Apps/AccountManagement/hooks/useGetAccountOptions'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import {
    useLocationAirportLoadOptions,
} from 'app/shared-components/LocationSelector'
import DataContentWrapper from 'app/shared-components/DataContentWrapper'
import CompaniesRequests from 'app/Apps/ContactManagement/Companies/Companies.request'
import {
    FilterContext,
    FilterContextType,
    FilterOptions,
    FilteredData,
} from 'app/hooks/useFilter'
import useFetchRegionalGroupById from 'app/Apps/AccountManagement/RegionalGroups/hooks/useFetchRegionalGroupById'
import useRegionalGroupsLoadOptions
    from 'app/shared-components/RegionalGroupSelector/hooks/useRegionalGroupsLoadOptions'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import userRequests from 'app/Apps/ContactManagement/Users/Users.request'
import {
    userParser,
} from 'app/shared-components/UserSelector/UserSelector'
import useAccountCollaborators from 'app/Apps/AccountManagement/hooks/useAccountCollaborators'

import requests from '../opportunities.request'
import {
    OpportunityType,
} from '../opportunities.types'

import getConfig from './OpportunitiesSpreadSheet.config'
import opportunitiesFilterConfig from './OpportunitiesSpreadSheet.filterConfig'

const defaultProps = {
    onSave: undefined,
    onCancel: undefined,
    enableAddNewRows: false,
    filterOptions: undefined,
}

const OpportunitiesSpreadSheet = ({
    onSave,
    onCancel,
    enableAddNewRows,
    filterOptions,
}: {
    onSave?: (v: OpportunityType[], o?: AsyncMutationOptions) => void
    onCancel?: (...args: any[]) => void,
    enableAddNewRows?: boolean,
    filterOptions?: FilterOptions,
}) => {
    const [
        value,
        setValue,
    ] = useState(filterOptions?.filteredData as FilteredData<OpportunityType[]>)

    const getLabel = useLabel()
    const getAllowedValues = useEnumValues()
    const getEnumLabels = useEnumLabels()
    const getRegionalGroupById = useFetchRegionalGroupById()

    const filterByAccount = useMemo(() => {
        return {
            accountId: filterOptions?.filter?.accountId,
        }
    }, [filterOptions?.filter])

    const {
        data: accountOptions,
    } = useGetAccountOptions(filterByAccount?.accountId)

    const loadAirlineOptions = useDelayedLoadOptions(CompaniesRequests.selector)
    const airlineParams = useTypeaheadOptions({
        load: loadAirlineOptions,
    })

    const loadRegionalGroupOptions = useRegionalGroupsLoadOptions(filterByAccount)
    const regionalGroupParams = useTypeaheadOptions({
        load: loadRegionalGroupOptions,
        filters: filterByAccount,
        isFilterRequired: true,
    })

    const loadOriginRegionAirportOptions = useLocationAirportLoadOptions()
    const originAirportParams = useTypeaheadOptions({
        load: loadOriginRegionAirportOptions,
        isFilterRequired: true,
    })

    const accountCollaborators = useAccountCollaborators(filterByAccount?.accountId)

    const loadOpportunityCollaboratorsOptions = useDelayedLoadOptions({
        url: userRequests.all.url,
        convertion: userParser,
        includeFilters: {
            id: accountCollaborators,
        },
    })
    const opportunityCollaboratorsParams = useTypeaheadOptions({
        load: loadOpportunityCollaboratorsOptions,
        isFilterRequired: true,
    })

    const onRegionalGroupChange = useCallback(async ({
        id,
    }: {id: number}) => {
        const regionalGroup = await getRegionalGroupById(id)

        if (regionalGroup) {
            return {
                originRegion: regionalGroup?.originRegion,
                destinationRegion: regionalGroup?.destinationRegion,
                temperatureRange: regionalGroup?.tempRange,
                reprOriginAirport: undefined,
                reprDestinationAirport: undefined,
            }
        }
        return undefined
    }, [getRegionalGroupById])

    const originRegionFilter = useCallback(({
        originRegion,
    }: OpportunityType) => {
        return {
            regionFirstLevel: [originRegion],
        }
    }, [])

    const destinationRegionFilter = useCallback(({
        destinationRegion,
    }: OpportunityType) => {
        return {
            regionFirstLevel: [destinationRegion],
        }
    }, [])

    const noOriginRegion = useCallback(({
        originRegion,
    }: OpportunityType) => {
        return !originRegion
    }, [])

    const noDestinationRegion = useCallback(({
        destinationRegion,
    }: OpportunityType) => {
        return !destinationRegion
    }, [])

    const isRegionalGroupDisabled = useCallback(({
        existNonCanceledLanes,
    }: OpportunityType) => {
        return existNonCanceledLanes
    }, [])

    const loadDestinationRegionAirportOptions = useLocationAirportLoadOptions()
    const destinationAirportParams = useTypeaheadOptions({
        load: loadDestinationRegionAirportOptions,
        isFilterRequired: true,
    })

    const allowedTempRanges = useMemo(() => {
        return getEnumLabels(accountOptions?.tempRange || [])
    }, [
        getEnumLabels,
        accountOptions?.tempRange,
    ])

    const config = useMemo(() => {
        return getConfig({
            getLabel,
            allowedTempRanges,
            getAllowedValues,
            airlineParams,
            originAirportParams,
            destinationAirportParams,
            editMode: !enableAddNewRows,
            onRegionalGroupChange,
            regionalGroupParams,
            opportunityCollaboratorsParams,
            noOriginRegion,
            noDestinationRegion,
            originRegionFilter,
            destinationRegionFilter,
            isRegionalGroupDisabled,
        })
    }, [
        getLabel,
        allowedTempRanges,
        getAllowedValues,
        airlineParams,
        originAirportParams,
        destinationAirportParams,
        enableAddNewRows,
        onRegionalGroupChange,
        regionalGroupParams,
        noOriginRegion,
        noDestinationRegion,
        originRegionFilter,
        destinationRegionFilter,
        isRegionalGroupDisabled,
        opportunityCollaboratorsParams,
    ])

    const handleSave = useCallback(async (options?: { onSuccess?: (...a: any) => void }) => {
        const opportunities = value.map((opportunity) => {
            return {
                ...opportunity,
                account: {
                    id: filterByAccount?.accountId?.[0],
                },
            }
        }) as OpportunityType[]

        return onSave(opportunities, options)
    }, [
        filterByAccount,
        onSave,
        value,
    ])

    const edited = !isEqual(filterOptions?.filteredData, value)

    const saveBeforeFilter = useCallback((setFilter) => {
        handleSave({
            onSuccess: setFilter,
        })
    }, [handleSave])

    const filterConfig: FilterContextType = useMemo(() => {
        return {
            ...filterOptions,
            config: opportunitiesFilterConfig,
            request: requests.filterSpreadsheetCount,
            beforeFilterCallback: saveBeforeFilter,
            edited,
        }
    }, [
        edited,
        filterOptions,
        saveBeforeFilter,
    ])

    return (
        <FilterContext.Provider value={filterConfig}>
            <SkyNetSpreadSheetControlPanel
                onSave={handleSave}
                onCancel={onCancel}
                edited={edited}
            >
                <DataContentWrapper
                    isEmpty={!filterByAccount.accountId}
                    emptyDataReplacer={`Please select the account to ${enableAddNewRows ? 'add new' : 'edit'} opportunities`}
                >
                    <SkyNetSpreadSheet
                        config={config}
                        value={value}
                        disabled={false}
                        onChange={setValue}
                    />
                </DataContentWrapper>
            </SkyNetSpreadSheetControlPanel>
        </FilterContext.Provider>
    )
}

OpportunitiesSpreadSheet.defaultProps = defaultProps

export default OpportunitiesSpreadSheet
