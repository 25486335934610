import React from 'react'
import Typography from '@mui/material/Typography'
import RadioButtonTile from 'app/shared-components/RadioButtonTile'
import useStyles
    from './RadioButtonTileSelector.styles'

export default function RadioButtonTileSelector<S extends Record<keyof S, S[keyof S]>>({
    values,
    setValue,
    title,
    selectedValue,
    labels,
}: Readonly<{
    values: S,
    setValue: (value: S[keyof S]) => void,
    title: string,
    selectedValue: S[keyof S],
    labels?: Record<S[keyof S], string>,
}>) {
    const {
        classes,
    } = useStyles()

    return (
        <div className={classes.root}>
            <Typography
                variant="h3"
                className={classes.title}
            >
                {title}
            </Typography>
            <div className={classes.wrapper}>
                { Object.entries(values).map(([
                    key,
                    value,
                ]) => {
                    return (
                        <RadioButtonTile
                            key={key}
                            title={labels?.[value as S[keyof S]] ?? value as string}
                            onClick={() => { setValue(value as S[keyof S]) }}
                            selected={value === selectedValue}
                        />
                    )
                })}
            </div>
        </div>
    )
}
