import React, {
    useCallback, useMemo,
} from 'react'
import {
    Location,
} from 'app/Apps/ContactManagement/Locations/Locations.types'
import useValidateItem from 'app/hooks/useValidateItem'

import useStyles from './LocationSelector.style'
import useLocationRequests from './hooks/useLocationRequests'
import useLocationSearch from './hooks/useLocationSearch'
import {
    getBasicInfoByLocation, getTooltipData,
} from './utils'
import {
    LocationLabelIconType,
    LocationSelectorProps,
    LocationSelectorDefaultProps,
} from './types/LocationSelector.types'

type BaseLocationPropTypes = LocationSelectorProps & {
    searchComponent: (props: Record<string, any>) => JSX.Element
}

const BaseLocationSelector = ({
    value,
    filterByCompanyId,
    filterLocationType,
    freightRateServiceProviderCode,
    filterAddressCategory,
    filterAddressMainCategory,
    isShippingLocation,
    isApprovedSubContractor,
    onChange,
    name,
    title,
    disabled,
    isRequired,
    validate,
    className,
    classNames,
    'data-testid': dataTestId,
    allowedLocationTypes,
    searchComponent: Component,
    includeFilters,
    setErrors,
}: BaseLocationPropTypes) => {
    const {
        classes,
    } = useStyles()

    const getLabelConfig = useCallback<(location: Location) => LocationLabelIconType>(
        getBasicInfoByLocation, [])

    const {
        location,
        labelData,
    } = useLocationRequests(value, getLabelConfig)

    const tooltipValue = useMemo((): JSX.Element => {
        return getTooltipData(location)
    }, [location])

    const loadOptions = useLocationSearch({
        allowedLocationTypes,
        filterByCompanyId,
        filterLocationType,
        filterAddressCategory,
        filterAddressMainCategory,
        freightRateServiceProviderCode,
        isApprovedSubContractor,
        isShippingLocation,
        classes,
        includeFilters,
    })

    const {
        errors,
        validated,
    } = useValidateItem({
        validate, value: location, setFormError: setErrors, key: name,
    })

    return (
        <div
            className={className}
            data-testid={dataTestId}
        >
            <Component
                name={name}
                value={value}
                title={title}
                onChange={onChange}
                loadOptions={loadOptions}
                labelData={labelData}
                labelField="label"
                className={className}
                classNames={{
                    options: classes.options,
                    ...classNames,
                }}
                disabled={disabled}
                isRequired={isRequired}
                iconTooltip={tooltipValue}
                errors={errors}
                validated={validated}
            />
        </div>
    )
}

BaseLocationSelector.defaultProps = LocationSelectorDefaultProps

export default BaseLocationSelector
