import {
    OrderStatuses,
} from 'app/types/enums'

export const NOT_ALLOWED_CANCEL_MODIFY_ORDER_STATUSES = [
    OrderStatuses.CANCELLED,
    OrderStatuses.CLOSED,
]

export const ENABLED_ORDER_STATUSES = [
    OrderStatuses.TO_BE_PROVISIONED,
    OrderStatuses.READY_TO_SHIP,
    OrderStatuses.NOT_STARTED,
    OrderStatuses.CONFIRMED,
    OrderStatuses.SELECTED,
    OrderStatuses.BOOKED,
]

export const ENABLED_CONTAINER_ORDER_STATUSES = [
    OrderStatuses.TO_BE_PROVISIONED,
    OrderStatuses.READY_TO_SHIP,
    OrderStatuses.CONFIRMED,
    OrderStatuses.SELECTED,
    OrderStatuses.BOOKED,
    OrderStatuses.RESERVED,
    OrderStatuses.TRANSPORT,
]

export const ENABLED_CONTAINER_ORDER_STATUSES_INTERNAL = [
    OrderStatuses.NOT_STARTED,
    OrderStatuses.SELECTED,
    OrderStatuses.BOOKED,
    OrderStatuses.TRANSPORT,
]

export const DISABLED_STEP_ORDER_STATUSES = [
    OrderStatuses.NOT_STARTED,
    OrderStatuses.CANCELLED,
]

export const DISABLED_BOOKED_CONTAINERS_STATUSES = [
    OrderStatuses.CANCELLED,
    OrderStatuses.CLOSED,
]

export const NOT_ALLOWED_POSTPONE_ORDER_STATUSES = [
    OrderStatuses.CLOSED,
    OrderStatuses.CANCELLED,
    OrderStatuses.TRANSPORT,
]

export const ALLOWED_UPDATE_LOCATION = [
    OrderStatuses.TRANSPORT,
    OrderStatuses.CLOSED,
]
