import {
    useMemo,
} from 'react'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import {
    SkyNetTableTabWithLabel,
} from '../types/skyNetTable.types'
import useTableTab from './useTableTab'

export default function useSkyNetTableControls({
    showSearch,
    tableControls,
    tabs,
}: {
    showSearch: boolean,
    tableControls: TableControlTypes[],
    tabs: SkyNetTableTabWithLabel[]
}): TableControlTypes[] {
    const selected = useTableTab({
        tabs,
    })

    return useMemo((): TableControlTypes[] => {
        const result = tableControls

        if (showSearch) {
            return [
                TableControlTypes.Search,
                ...tableControls,
            ]
        }

        return result.filter((control) => {
            return !selected?.exclude?.tableControls?.includes(control)
        })
    }, [
        showSearch,
        tableControls,
        selected?.exclude?.tableControls,
    ])
}
