import React, {
    useCallback, useMemo, useState,
} from 'react'
import AddIcon from '@mui/icons-material/Add'

import Card from 'app/shared-components/Card'
import useLabel from 'app/hooks/useLabel'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import Button from 'app/shared-components/Button'
import {
    RouteDescription, RoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'
import {
    LeaseType,
} from 'app/types/enums'
import {
    YesNo,
} from 'app/types/common.enums'
import Radio from 'app/shared-components/Radio'

import useOrderManagementRoutes from '../hooks/useOrderManagementRoutes'

import useStyles from './Pooling.style'
import getDirectLeaseConfig from './configs/directLease.config'
import getInternalTransportsConfig from './configs/internalTransports.config'
import getContainerSelectionConfig from './configs/containerSelection.config'
import getAirlineLeaseConfig from './configs/airlineLease.config'
import getPickupAddInfoConfig from './configs/pickupAddInfo.config'
import getPickupAddPickUpConfig from './configs/pickupAddPickUp.config'
import getPickupDeliveryConfirmationConfig from './configs/pickupDeliveryConfirmation.config'
import getTransportDepartureConfig from './configs/transportDeparture.config'
import getTransportArrivalConfig from './configs/transportArrival.config'
import getDeliveryAddInfoConfig from './configs/deliveryAddInfo.config'
import getDeliveryAddPickupConfig from './configs/deliveryAddPickup.config'
import getDeliveryConfirmationConfig from './configs/deliveryConfirmation.config'
import getPreBookings from './configs/preBookings.config'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const filterOptions: {
    label: string,
    value: boolean,
}[] = [
    {
        value: true,
        label: YesNo.YES,
    },
    {
        value: false,
        label: YesNo.NO,
    },
]

const Pooling = () => {
    const Routes = useOrderManagementRoutes()
    const {
        classes,
    } = useStyles()

    const l = useLabel()

    const [
        isOverdueFilter,
        setIsOverdueFilter,
    ] = useState<boolean>(true)

    const openCreateForm = useCallback(
        ({
            collectionDropoffPointId, selectedPreviousOrderId,
        }): void => {
            Routes.InternalOrders.Create.navigate({
                options: {
                    state: {
                        collectionDropoffPointId,
                        selectedPreviousOrderId,
                    },
                },
            })
        },
        [Routes],
    )

    const onRowClick = useCallback(
        (
            rout: RouteDescription,
            {
                orderNumber,
            }: {
                orderNumber: string;
            },
        ): void => {
            rout.navigate({
                params: {
                    key: orderNumber,
                },
                options: {
                    state: {
                        isOverdueFilter,
                    },
                },
            })
        },
        [isOverdueFilter],
    )

    const onRowClickPreBookings = ({
        id,
    }: { id: number }): void => {
        Routes.PreBookings.Overview.navigate({
            params: {
                key: id,
            },
        })
    }

    const {
        containerSelectionConfig,
        preBookings,
        directLeaseConfig,
        airlineLeaseConfig,
        pickupAddInfoConfig,
        pickupAddPickUpConfig,
        pickupDeliveryConfirmationConfig,
        transportDepartureConfig,
        transportArrivalConfig,
        deliveryAddInfoConfig,
        deliveryAddPickupConfig,
        deliveryConfirmationConfig,
        internalTransportsConfig,
    } = useMemo(() => {
        return {
            containerSelectionConfig: getContainerSelectionConfig(),
            preBookings: getPreBookings(),
            directLeaseConfig: getDirectLeaseConfig(openCreateForm),
            airlineLeaseConfig: getAirlineLeaseConfig(openCreateForm),
            pickupAddInfoConfig: getPickupAddInfoConfig(isOverdueFilter),
            pickupAddPickUpConfig: getPickupAddPickUpConfig(isOverdueFilter),
            pickupDeliveryConfirmationConfig: getPickupDeliveryConfirmationConfig(isOverdueFilter),
            transportDepartureConfig: getTransportDepartureConfig(isOverdueFilter),
            transportArrivalConfig: getTransportArrivalConfig(),
            deliveryAddInfoConfig: getDeliveryAddInfoConfig(isOverdueFilter),
            deliveryAddPickupConfig: getDeliveryAddPickupConfig(isOverdueFilter),
            deliveryConfirmationConfig: getDeliveryConfirmationConfig(isOverdueFilter),
            internalTransportsConfig: getInternalTransportsConfig(),
        }
    }, [
        isOverdueFilter,
        openCreateForm,
    ])

    return (
        <div className={classes.root}>
            <div className={classes.headerTitle}>
                Container Selection
                <div className={classes.actionWrapper}>
                    <Button
                        label="Create Internal Order"
                        onClick={openCreateForm}
                        data-testid="openCreateFormBtn"
                        icon={<AddIcon />}
                    />
                </div>
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingContainerSelection}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick(
                            Routes.InternalOrders.ContainerSelection,
                            args,
                        )
                    }}
                    uniqField="orderNumber"
                    customUrl={
                        orderManagementRequest.Pooling.url.poolingContainerSelection
                    }
                    tableConfig={containerSelectionConfig}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.headerTitle}>Pre Bookings</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.PreBookingsTransports}
                    tableControls={tableControls}
                    onRowClick={onRowClickPreBookings}
                    customUrl={orderManagementRequest.Pooling.url.preBookings}
                    tableConfig={preBookings}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                    data-testid="preBookingsTransports"
                />
            </div>
            <div className={classes.headerTitle}>Customer Transports</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingCustomerTransport}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick(Routes.CustomerOrders.OrderSteps, args)
                    }}
                    uniqField="orderNumber"
                    customUrl={
                        orderManagementRequest.Pooling.url.poolingCustomerTransport
                    }
                    filter={{
                        orderSubType: [LeaseType.DIRECT_LEASE],
                    }}
                    tableConfig={directLeaseConfig}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingCustomerTransport}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick(Routes.CustomerOrders.OrderSteps, args)
                    }}
                    uniqField="orderNumber"
                    customUrl={
                        orderManagementRequest.Pooling.url.poolingCustomerTransport
                    }
                    filter={{
                        orderSubType: [LeaseType.AIRLINE_LEASE],
                    }}
                    tableConfig={airlineLeaseConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.headerTitle}>Internal Transports</div>
            <Card
                title="Filter by"
                className={classes.filterWrapper}
            >
                <Radio
                    title={l('Order.IsDelayed')}
                    value={isOverdueFilter}
                    options={filterOptions}
                    compact
                    onChange={setIsOverdueFilter}
                />
            </Card>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick(
                            (Routes.InternalOrders.PickupAddInfo as RoutesConfig).OrderSteps,
                            args,
                        )
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.poolingPickupAddInfo}
                    tableConfig={pickupAddInfoConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick(
                            (Routes.InternalOrders.PickupAddPickup as RoutesConfig).OrderSteps,
                            args,
                        )
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.poolingPickupAddPickup}
                    tableConfig={pickupAddPickUpConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick(
                            (Routes.InternalOrders.PickupDeliveryConfirmation as RoutesConfig)
                                .OrderSteps,
                            args,
                        )
                    }}
                    uniqField="orderNumber"
                    customUrl={
                        orderManagementRequest.Pooling.url.pickupDeliveryConfirmation
                    }
                    tableConfig={pickupDeliveryConfirmationConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick(
                            (Routes.InternalOrders.TransportDeparture as RoutesConfig).OrderSteps,
                            args,
                        )
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.transportDeparture}
                    tableConfig={transportDepartureConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick(
                            (Routes.InternalOrders.TransportArrival as RoutesConfig).OrderSteps,
                            args,
                        )
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.transportArrival}
                    tableConfig={transportArrivalConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick(
                            (Routes.InternalOrders.DeliveryAddInfo as RoutesConfig).OrderSteps,
                            args,
                        )
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.deliveryAddInfo}
                    tableConfig={deliveryAddInfoConfig}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick(
                            (Routes.InternalOrders.DeliveryAddPickup as RoutesConfig).OrderSteps,
                            args,
                        )
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.deliveryAddPickup}
                    tableConfig={deliveryAddPickupConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick(
                            (Routes.InternalOrders.DeliveryConfirmation as RoutesConfig).OrderSteps,
                            args,
                        )
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.deliveryConfirmation}
                    tableConfig={deliveryConfirmationConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.headerTitle}>Open Transports</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick(Routes.InternalOrders.OrderSteps, args)
                    }}
                    uniqField="orderNumber"
                    customUrl={
                        orderManagementRequest.Pooling.url.poolingInternalTransports
                    }
                    tableConfig={internalTransportsConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
        </div>
    )
}

export default Pooling
