import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    AQuote,
} from '../../AQuotes.types'
import requests from '../../AQuotes.requests'

export default ({
    onSuccess,
    setIsEdited,
}) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessCreateAQuote,
        onError,
    } = useHandleStatusRequest({
        title: 'New A-Quote was created',
        id: 'AQuoteCreated',
        onSuccess,
    })

    const {
        mutateAsync: createAQuote,
    } = useMutation({
        mutationFn: (aQuote: AQuote) => {
            return simpleApi({
                ...requests.create({
                    data: aQuote,
                }),
                token,
            })
        },
        onError,
    })

    return useCallback(async (aQuote: AQuote) => {
        setIsEdited(false)
        return createAQuote(aQuote)
            .then((data) => {
                onSuccessCreateAQuote(data)
            })
    }, [
        createAQuote,
        setIsEdited,
        onSuccessCreateAQuote,
    ])
}
