import React, {
    useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import CountriesRequest from './Countries.request'

import CountryOverview from './CountryOverview'
import getTableConfig from './Country.config'

const Countries = () => {
    const tabs: SidebarTab[] = [{
        url: CommonRoutes.OVERVIEW,
        label: 'Overview',
        Component: CountryOverview,
    }]

    const tableControls: TableControlTypes[] = [TableControlTypes.ClearSorting]
    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            sidebarTabs={tabs}
            name="CountryView"
        >
            <SkyNetTable
                moduleName={CountriesRequest.domainName}
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default Countries
