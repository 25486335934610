import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    AsyncMutationOptions, RequestConfig,
} from 'app/types/request.types'

export default function useBulkEdit <T = []>({
    id,
    successMessage,
    requestConfig,
}: {
    id: string,
    successMessage: string,
    requestConfig: ({
        data,
    }: Record<string, any>) => RequestConfig
}) {
    const token = useJWTToken()

    const {
        onSuccess,
        onError,
    } = useHandleStatusRequest({
        title: successMessage, id,
    })

    const {
        mutateAsync: bulkUpdate,
    } = useMutation({
        mutationFn: (items: T[]) => {
            return simpleApi({
                ...requestConfig({
                    data: {
                        items,
                    },
                }),
                token,
            })
        },
        onError,
        onSuccess,
    })

    return useCallback(async (
        items: T[],
        options: AsyncMutationOptions,
    ) => {
        if (!items.length) {
            onError({
                response: {
                    data: ['Nothing was changed'],
                    statusCode: 400,
                },
            })

            if (options?.onSettled) options?.onSettled(items)
            return Promise.resolve()
        }

        return bulkUpdate(items, options)
    }, [
        bulkUpdate,
        onError,
    ])
}
