import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    RegionSecondLevel,
} from 'app/types/enums'
import {
    RateCardsData,
    ProductTypeCQuote,
} from 'app/Apps/AccountManagement/CQuotes/CQuotes.types'
import requestConfig from '../RateCards.requests'

export default function useGetRateCardsData(requestData: {
    originRegion: RegionSecondLevel,
    destinationRegion: RegionSecondLevel,
    productType: ProductTypeCQuote,
}) {
    return useRequest<RateCardsData>({
        key: RequestKeys.getRateCardsData,
        params: requestData,
        requestFunc: RequestFn.getEntity(requestConfig.getLogisticDetails),
        enabled: Boolean(
            requestData?.originRegion && requestData?.destinationRegion && requestData?.productType,
        ),
    })
}
