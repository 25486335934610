/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import Typeahead from '../Typeahead'

import BaseLocationSelector from './BaseLocationSelector'
import {
    LocationSelectorProps, LocationSelectorDefaultProps,
} from './types/LocationSelector.types'

const LocationSelector = (props: LocationSelectorProps) => {
    return (
        <BaseLocationSelector
            {...props}
            searchComponent={Typeahead}
        />
    )
}

LocationSelector.defaultProps = LocationSelectorDefaultProps

export default LocationSelector
