import queryString from 'query-string'

import {
    UseRequestConfig,
} from 'app/types/request.types'
import simpleApi from 'app/services/simpleApi'

const config: Record<string, UseRequestConfig> = {
    AvailableLanes: {
        requestFn: ({
            params,
        }) => {
            return {
                url: 'lane/filter-request/airline-lease-available-lanes',
                method: 'POST',
                ...params,
            }
        },
    },
    AllowedProductTypes: {
        requestFn: ({
            selectedLaneId,
            leaseStartTimestamp,
            isPerformanceQualification = false,
        }) => {
            return {
                url: queryString.stringifyUrl({
                    url: `lane/${selectedLaneId}/allowedproducttypes`,
                    query: {
                        leaseStartTimestamp,
                        isPerformanceQualification,
                    },
                }),
            }
        },
    },
    AllProductType: {
        requestFn: () => {
            return {
                url: 'contractbasis/producttype/all',
            }
        },
    },
    AutomaticQuote: {
        mutationFn: ({
            data,
            token,
        }) => {
            return simpleApi({
                url: 'lane/automatic-quote',
                data,
                method: 'POST',
                token,
            })
        },
    },
    CreateCTOrder: {
        mutationFn: ({
            data,
            token,
        }) => {
            return simpleApi({
                url: 'order/customer-transport',
                data,
                method: 'POST',
                token,
            })
        },
    },
}

export default config
