import React, {
    useMemo,
} from 'react'
import clsx from 'clsx'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'

import {
    ValidationResult,
} from 'app/types/form.types'

import {
    TooltipWrapper,
} from '../Tooltip'

import useStyles from './Validated.styles'

const defaultProps = {
    classNames: {},
    validated: undefined,
    errors: undefined,
}

const Validated = ({
    validated,
    errors,
    children,
    classNames,
}: {
    validated?: boolean,
    errors?: ValidationResult,
    children: JSX.Element,
    classNames?: Record<string, string>
}) => {
    const {
        classes,
    } = useStyles()

    const errorsText = useMemo(() => {
        if (!errors || !Array.isArray(errors)) return undefined
        return (
            <div className={classNames.errorsContainer}>
                { errors.map((el) => {
                    return (
                        <div
                            key={el}
                            className={classNames.error}
                        >
                            {el}
                        </div>
                    )
                }) }
            </div>
        )
    }, [
        errors,
        classNames,
    ])

    if (!validated) {
        return children
    }

    return (
        <div className={clsx(classes.container, classNames.validatedContainer)}>
            { children }
            { Array.isArray(errors) && errors?.length > 0
                ? (
                    <TooltipWrapper
                        value={errorsText}
                        ownTooltipProps={{
                            enterDelay: 100,
                            placement: 'bottom',
                        }}
                    >
                        <ErrorIcon classes={{
                            root: clsx(classes.icon, classes.error, classNames.validatedIcon),
                        }}
                        />
                    </TooltipWrapper>
                )
                : (
                    <CheckCircleIcon classes={{
                        root: clsx(classes.icon, classes.success, classNames.validatedIcon),
                    }}
                    />
                )}
        </div>
    )
}

Validated.defaultProps = defaultProps

export default Validated
