import React, {
    useCallback,
} from 'react'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    getEditedItems,
    useGetRequestSpreadSheetAdapter,
} from 'app/shared-components/SkyNetSpreadSheet'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import {
    useSkyNetPagination,
    SkyNetPaginationContext,
} from 'app/shared-components/SkyNetPagination'
import useBulkEdit from 'app/hooks/useBulkEdit'

import requests from '../LogisticsCosts.request'
import LogisticsCostsSpreadsheet from '../LogisticsCostsSpreadsheet'
import {
    LogisticsCostsEditable, LogisticsCostsItem,
} from '../LogisticsCosts.types'
import {
    prepareLogisticsCostsToBulkEdit,
} from '../LogisticsCosts.utils'

export default function LogisticsCostsBulkEdit({
    onClose,
}: Readonly<{
  onClose: () => void;
}>) {
    const paginationOptions = useSkyNetPagination(25)
    const {
        isError, loaded, error, data,
    } = useGetRequestSpreadSheetAdapter<LogisticsCostsItem>({
        config: requests[RequestKeys.logisticsCostsFilterRequest],
        requestKey: RequestKeys.logisticsCostsFilterRequest,
        enabled: true,
        paginationOptions,
    })

    const bulkEdit = useBulkEdit<LogisticsCostsEditable>({
        requestConfig: requests.bulkEdit,
        successMessage: 'Logistics Costs was updated',
        id: 'LogisticsCostsBulkUpdate',
    })

    const onSave = useCallback(
        (
            items: LogisticsCostsItem[],
            options?: AsyncMutationOptions,
        ) => {
            const editedItems = getEditedItems({
                allItems: items,
                initData: data,
            })

            bulkEdit(prepareLogisticsCostsToBulkEdit(editedItems), {
                ...options,
                onSuccess: options?.onSuccess || onClose,
            })
        },
        [
            bulkEdit,
            data,
            onClose,
        ],
    )

    return (
        <StatusHandler
            isError={isError}
            isFetching={!loaded}
            error={error}
            isSuccess={loaded}
        >
            <SkyNetPaginationContext.Provider value={paginationOptions}>
                <LogisticsCostsSpreadsheet
                    data={data}
                    onCancel={onClose}
                    onSave={onSave}
                />
            </SkyNetPaginationContext.Provider>
        </StatusHandler>
    )
}
