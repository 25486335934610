import React, {
    useMemo, useCallback,
} from 'react'
import AwardRejectLanes from 'app/Apps/AccountManagement/components/AwardRejectLanes'
import AwardRejectLaneFees from 'app/Apps/AccountManagement/components/AwardRejectLaneFees'
import SkyNetLink, {
    SizeLink, TypeLink,
} from 'app/shared-components/SkyNetLink'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import {
    LaneStatus,
} from 'app/types/enums'
import {
    LanesQuotation,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'

export default function CQuotesAwardRejectLanes({
    id,
}: Readonly<{ id: number }>) {
    const {
        CQuotes: CQuotesRoutes,
    } = useAccountManagementRoutes()
    const filters = useMemo(() => {
        return {
            cQuoteId: id,
        }
    }, [id])

    const actionLink = useCallback((showLink: boolean) => {
        return showLink ? (
            <SkyNetLink
                link={generateSkyNetLink({
                    domainPath: CQuotesRoutes.Create.path,
                    params: {
                        selectedMethod: 'cquote',
                    },
                })}
                text="Update/Add New Pricing"
                type={TypeLink.EXTERNAL}
                size={SizeLink.S}
            />
        ) : undefined
    }, [CQuotesRoutes])

    const renderLaneFees = useCallback((data: LanesQuotation[] = []) => {
        return (
            <AwardRejectLaneFees
                filters={filters}
                actionLink={actionLink(data.some(({
                    laneStatus,
                }) => {
                    return [
                        LaneStatus.AWARDED,
                        LaneStatus.IMPLEMENTED,
                        LaneStatus.INACTIVE,
                    ].includes(laneStatus)
                }))}
            />
        )
    }, [
        actionLink,
        filters,
    ])

    return (
        <AwardRejectLanes
            filters={filters}
            renderLaneFees={renderLaneFees}
        />
    )
}
