import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    LeaseType, LaneStatus,
} from 'app/types/enums'

import {
    CollServiceType, DeliveryServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    LanesFields,
} from './Lanes.types'

const columns = ({
    l,
}: {
    l: (l:string) => string,
}): RichTableColumns<LanesFields>[] => {
    return [
        {
            id: 'laneNumber',
            headerProps: {
                children: l('Order.lane'),
            },
            mapCellProps: 'laneNumber',
        },
        {
            id: 'laneStatus',
            headerProps: {
                children: l('Accounts.status'),
            },
            mapCellProps: 'laneStatus',
            filterField: 'laneStatus',
            componentName: 'EnumValue',
            filterType: FilterType.Array,
            allowedValuesEnum: LaneStatus,
        },
        {
            id: 'leaseType',
            headerProps: {
                children: l('AccountLane.leaseType'),
            },
            mapCellProps: 'leaseType',
            filterField: 'leaseType',
            componentName: 'EnumValue',
            filterType: FilterType.Array,
            allowedValuesEnum: LeaseType,
        },
        {
            id: 'customerExecution',
            mapCellProps: 'customerExecution',
            headerProps: {
                children: l('LaneQuoteRequest.selectedCustomerExecution'),
            },
            filterField: 'customerExecution',
            filterType: FilterType.Array,
        },
        {
            id: 'deliveryServiceType',
            headerProps: {
                children: l('AccountLane.deliveryServiceType'),
            },
            mapCellProps: 'deliveryServiceType',
            componentName: 'EnumValue',
            allowedValuesEnum: DeliveryServiceType,
        },
        {
            id: 'collectionServiceType',
            headerProps: {
                children: l('AccountLane.collectionServiceType'),
            },
            mapCellProps: 'collectionServiceType',
            componentName: 'EnumValue',
            allowedValuesEnum: CollServiceType,
        },
        {
            id: 'originAirport',
            headerProps: {
                children: l('AccountQuotes.laneFrom'),
            },
            mapCellProps: 'originAirport',
            filterField: 'originAirport',
            filterType: FilterType.Array,
        },
        {
            id: 'destinationAirport',
            headerProps: {
                children: l('AccountQuotes.laneTo'),
            },
            mapCellProps: 'destinationAirport',
            filterField: 'destinationAirport',
            filterType: FilterType.Array,
        },
    ]
}

export default columns
