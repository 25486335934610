import React, {
    useMemo,
} from 'react'
import {
    useTheme,
} from '@mui/material/styles'

import {
    Event,
} from 'app/types/common.types'

import {
    Co2simulationType, Technology,
} from '../co2simulations.types'

import useLoadCo2Calculation from './hooks/useLoadCo2Calculation'
import Summary from './Summary'
import useStyles from './AnalyseCO2Emissions.style'
import AnalyseCO2EmissionsChart from './AnalyseCO2EmissionsChart'
import getDataChart from './getDataChart'
import Options from './Options'

type Props = {
    value: Co2simulationType,
    onChange: (newVal: string, event: Event<string>) => void,
}

const AnalyseCO2Emissions = ({
    value,
    onChange,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const theme = useTheme()

    const {
        selectedContainer,
        route,
    } = value

    const filteredRoutes: number[] = useMemo(() => {
        return [
            route.routeOriginAirport,
            ...route.routeViaAirports,
            route.routeDestinationAirport,
        ]
            .map((item) => { return item?.id })
            .filter((item) => {
                return Boolean(item)
            })
    }, [
        route.routeDestinationAirport,
        route.routeOriginAirport,
        route.routeViaAirports,
    ])

    const {
        data,
    } = useLoadCo2Calculation(selectedContainer, filteredRoutes)

    const co2Calculation: Record<Technology, number> = useMemo(() => {
        if (!data?.co2Calculation) {
            return {} as Record<Technology, number>
        }

        return data.co2Calculation.reduce((acc, item) => {
            const sumC02 = item?.co2CostPerLeg.reduce((sum, el) => {
                return sum + el
            }, 0)

            return {
                ...acc,
                [item.technology]: Math.floor(sumC02 * 100) / 100,
            }
        }, {} as Record<Technology, number>)
    }, [data?.co2Calculation])

    const totalCo2Competition: number = useMemo(() => {
        const {
            ACTIVE = 0,
            PASSIVE = 0,
        } = co2Calculation

        return ACTIVE > PASSIVE ? PASSIVE : ACTIVE
    }, [co2Calculation])

    const totalCo2Saved: number = useMemo(() => {
        const {
            SKYCELL_HYBRID = 0,
        } = co2Calculation

        return Math.abs(Math.round((totalCo2Competition - SKYCELL_HYBRID) * 10) / 10)
    }, [
        co2Calculation,
        totalCo2Competition,
    ])

    const dataChart = getDataChart(co2Calculation, theme)

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <Options
                    className={classes.info}
                    onChange={onChange}
                    simulationName={value.simulationName}
                />
                <Summary
                    route={filteredRoutes}
                    totalEmissions={`${Math.round(co2Calculation.SKYCELL_HYBRID) || 0}tCO${String.fromCharCode(8322)}`}
                    totalCo2Saved={`${Math.round(totalCo2Saved)}tCO${String.fromCharCode(8322)}`}
                    totalCo2Competition={`${Math.round(totalCo2Competition)}tCO${String.fromCharCode(8322)}`}
                    className={classes.info}
                />
            </div>
            <AnalyseCO2EmissionsChart
                value={dataChart}
            />
        </div>
    )
}

export default AnalyseCO2Emissions
