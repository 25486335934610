import omit from 'lodash/omit'

import {
    RegionalServiceAvailabilityItem,
    RegionalServiceAvailabilityEditable,
} from './RegionalServiceAvailability.types'

// eslint-disable-next-line import/prefer-default-export
export const prepareRegionalServiceAvailabilityToBulkEdit = (
    items: RegionalServiceAvailabilityItem[] = [],
): RegionalServiceAvailabilityEditable[] => {
    return items.map((i) => {
        return omit(i, [
            'regionSecondLevel',
            'originTruckPositioning',
            'originPositioningLtl',
            'originPositioningFtl',
            'destinationDropoff',
            'destinationCollectionLtl',
        ])
    })
}
