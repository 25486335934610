import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    SimplifiedCQuoteCreateType,
} from '../../simplifiedCQuoteCreate.types'

const getFields = ({
    getAllowedValues,
    allowedCurrency,
    plannedPaymentTerms,
}: {
    getAllowedValues: (val: Record<string, string>) => Record<string, string>,
    allowedCurrency: Record<string, string>,
    plannedPaymentTerms: number,
}): DTOCardFormField<keyof SimplifiedCQuoteCreateType>[] => {
    return [{
        id: 'general',
        title: 'General',
        className: 'general',
        fields: [
            {
                id: 'offerSetup-column1',
                fields: [
                    {
                        name: 'currency',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(allowedCurrency),
                        useDropdown: true,
                        required: true,
                        labelKey: 'LaneOpsInformationDto.currency',
                    },
                    {
                        name: 'rentalDays',
                        componentName: 'IntegerInput',
                        min: 5,
                        required: true,
                        labelKey: 'LaneFeesUpdateDto.rentalDays',
                    },
                    {
                        name: 'logisticsDays',
                        componentName: 'IntegerInput',
                        required: true,
                        labelKey: 'LaneFeesUpdateDto.logisticsDays',
                    },
                ],
            },
            {
                id: 'offerSetup-column2',
                fields: [
                    {
                        name: 'companyName',
                        componentName: 'InputSingleline',
                        required: true,
                        labelKey: 'AccountQuotes.companyName',
                    },
                    {
                        name: 'companyAddress',
                        componentName: 'LongText',
                        required: true,
                        labelKey: 'AccountQuotes.companyAddress',
                    },
                    {
                        name: 'plannedPaymentTerms',
                        componentName: 'InputSingleline',
                        value: plannedPaymentTerms,
                        disabled: true,
                        labelKey: 'ContractBasisUpdateDto.plannedPaymentTerms',
                    },
                ],
            },
        ],
    }]
}

export default getFields
