/* eslint-disable react/jsx-props-no-spreading, react/jsx-props-no-spreading */
import React, {
    useCallback,
    useMemo,
} from 'react'
import {
    Chip,
} from '@mui/material'
import clsx from 'clsx'
import Creatable from 'react-select/creatable'
import AsyncCreatable from 'react-select/async-creatable'
import PropTypes from 'prop-types'

import Label from 'app/shared-components/Label'

import LabelPanel from '../LabelPanel'

import Control from './Control'
import Menu from './Menu'
import MultiValue from './MultiValue'
import NoOptionsMessage from './NoOptionsMessage'
import Option from './Option'
import Placeholder from './Placeholder'
import SingleValue from './SingleValue'
import ValueContainer from './ValueContainer'
import useStyles from './ChipSelect.style'

const propTypes = {
    title: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.object),
    enableOptionCreation: PropTypes.bool,
    disabled: PropTypes.bool,
    loadOptions: PropTypes.func,
    getValueLabel: PropTypes.func,
    showTitleOptions: PropTypes.bool,
    name: PropTypes.string,
    defaultOptions: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    iconPath: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        label: PropTypes.string,
    })),
    menuShouldBlockScroll: PropTypes.bool,
    menuPortalTarget: PropTypes.instanceOf(Element),
    validated: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
    cacheOptions: PropTypes.bool,
    notificationIconProps: PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.string,
    }),
}

const defaultProps = {
    title: '',
    name: '',
    iconPath: undefined,
    enableOptionCreation: false,
    value: [],
    disabled: false,
    loadOptions: undefined,
    getValueLabel: undefined,
    options: undefined,
    showTitleOptions: false,
    defaultOptions: false,
    required: false,
    menuShouldBlockScroll: false,
    menuPortalTarget: null,
    validated: false,
    errors: undefined,
    notificationIconProps: undefined,
    cacheOptions: true, // for consistency with previous behavior
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
}

function ChipSelect(props) {
    const {
        title,
        value,
        enableOptionCreation,
        disabled,
        loadOptions,
        getValueLabel,
        showTitleOptions,
        required,
        name,
        defaultOptions,
        iconPath,
        validated,
        errors,
        cacheOptions,
        notificationIconProps,
    } = props

    const {
        classes,
    } = useStyles()
    const checkValidOption = useCallback(() => {
        return enableOptionCreation
    }, [enableOptionCreation])

    const creatableOptions = useMemo(() => {
        return {
            className: clsx(classes.paper, required && classes.isRequired),
            classNamePrefix: 'chip-select',
            isMulti: true,
            autoComplete: false,
            isValidNewOption: checkValidOption,
            placeholder: '',
            textFieldProps: {
                InputLabelProps: {
                    shrink: true,
                },
                variant: 'standard',
            },
            iconPath,
            components,
        }
    }, [
        classes.paper,
        classes.isRequired,
        required,
        checkValidOption,
        iconPath,
    ])

    const getTitle = useCallback(() => {
        return title ? (
            <LabelPanel
                validated={validated}
                errors={errors}
                notificationIconProps={notificationIconProps}
            >
                <Label title={title} />
            </LabelPanel>
        ) : null
    }, [
        notificationIconProps,
        validated,
        errors,
        title,
    ])

    const loadSelectableOptions = useCallback((phrase) => {
        return loadOptions({
            phrase,
        })
    }, [loadOptions])

    if (disabled) {
        return (
            <>
                {getTitle()}
                <div>
                    {value.length > 0 ? value.map((item) => {
                        const {
                            label,
                        } = item

                        const currentLabel = (getValueLabel && getValueLabel(item)) || label

                        if (!currentLabel) return null
                        return (
                            <Chip
                                label={currentLabel}
                                className={classes.chip}
                                key={`chip-${label}`}
                                avatar={iconPath && (
                                    <img
                                        alt={label}
                                        src={iconPath}
                                    />
                                )}
                            />
                        )
                    }) : '-'}
                </div>
            </>
        )
    }

    return (
        <div className={classes.root}>
            {getTitle()}
            {(required && !disabled) && <div className={classes.requiredMark} />}
            {loadOptions ? (
                <AsyncCreatable
                    menuPortalTarget={document.body}
                    data-testid={`chip-${name}`}
                    cacheOptions={cacheOptions}
                    getValueLabel={getValueLabel}
                    showTitleOptions={showTitleOptions}
                    defaultOptions={defaultOptions}
                    {...creatableOptions}
                    {...props}
                    loadOptions={loadSelectableOptions}
                />
            ) : (
                <Creatable
                    {...creatableOptions}
                    {...props}
                    loadOptions={loadSelectableOptions}
                />
            )}
        </div>
    )
}

ChipSelect.propTypes = propTypes
ChipSelect.defaultProps = defaultProps

export default React.memo(ChipSelect)
