import queryString from 'query-string'

import {
    RequestConfig,
} from 'app/types/request.types'

type BaseLeaseRequests = {
    filterRequest: () => RequestConfig,
    getCountryEmbargo: ({
        countryId: number,
    }) => RequestConfig,
}

const requests: BaseLeaseRequests = {
    filterRequest: () => {
        return {
            url: 'embargocountryratecard/filter-request/all',
        }
    },
    getCountryEmbargo: ({
        countryId,
    }) => {
        return {
            url: queryString.stringifyUrl({
                url: 'embargocountryratecard/countryembargoed',
                query: {
                    countryId,
                },
            }),
            method: 'GET',
        }
    },
}

export default requests
