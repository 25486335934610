import {
    RequestConfig,
    RequestForDomainForm,
} from 'app/types/request.types'
import {
    RequestKeys,
} from '../../../../hooks/useRequest'

export type ChangeStatusToRejected = {
    rejectedReason: string,
    rejectedReasonComment: string,
}

type LaneFeesRequests = {
    domainName: string,
    create: RequestForDomainForm<{laneGroupId: number}>,
    update: RequestForDomainForm<{laneFeesId: number}>,
    editableData: RequestForDomainForm<{laneFeesId: number}>,
    getLaneFeesByLaneGroup: ({
        laneGroupId,
        params,
    }: {
        laneGroupId: number,
        params?: any
    }) => RequestConfig,
    getLaneFee: ({
        id,
    }) => RequestConfig,
    duplicate: ({
        laneFeesId,
    }) => RequestConfig,
    filterRequest: ({
        laneGroupId,
    }) => string,
    archive: ({
        id,
    }:{
        id: number,
    }) => RequestConfig,
    restore: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    cancellation: ({
        id,
    }) => RequestConfig,
    validity: ({
        id,
    }) => RequestConfig,
    awardedBulk: ({
        laneFees,
    }: {laneFees: { id: number }[]}) => RequestConfig,
    rejectedBulk: ({
        laneFees,
        rejectedReason,
        rejectedReasonComment,
    }: {
        laneFees: { id: number }[],
        rejectedReason: string,
        rejectedReasonComment: string
    }) => RequestConfig,
    changeStatus: ({
        id,
        status,
        data,
    }: {
        id: number,
        status: string,
        data: ChangeStatusToRejected,
    }) => RequestConfig,
    [RequestKeys.laneFeesFilterRequestAwardReject]: (params?: Record<string, any>) => RequestConfig,
}

const laneFeesRequests: LaneFeesRequests = {
    domainName: 'LaneFees',
    create: {
        name: 'LaneFeesCreateDto',
        request: ({
            laneGroupId,
        }) => {
            return {
                url: `lanegroup/${laneGroupId}/lanefees`,
                method: 'POST',
                domainName: 'LaneFeesCreateDto',
            }
        },
    },
    update: {
        name: 'LaneFeesUpdateDto',
        request: ({
            laneFeesId,
        }) => {
            return {
                url: `lanefees/${laneFeesId}`,
                method: 'PATCH',
                domainName: 'LaneFees',
            }
        },
    },
    editableData: {
        name: 'LaneFeesUpdateDto',
        request: ({
            laneFeesId,
        }) => {
            return {
                url: `lanefees/${laneFeesId}/editable-data`,
                method: 'PATCH',
                domainName: 'LaneFees',
            }
        },
    },
    getLaneFee: ({
        id,
    }) => {
        return {
            url: `lanefees/${id}`,
            method: 'GET',
        }
    },
    duplicate: ({
        laneFeesId,
    }) => {
        return {
            url: `lanefees/${laneFeesId}/duplication`,
            method: 'POST',
            domainName: 'LaneFees',
        }
    },
    // TODO-JM: couldn't change it to return {} because it will re-render the component: ID-12230
    filterRequest: ({
        laneGroupId,
    }) => {
        return `lanegroup/${laneGroupId}/lanefees/filter-request/all`
    },
    archive: ({
        id,
    }) => {
        return {
            url: `lanefees/${id}/archive`,
            method: 'PATCH',
        }
    },
    getLaneFeesByLaneGroup: ({
        laneGroupId,
        params,
    }) => {
        return {
            url: `lanegroup/${laneGroupId}/lanefees/filter-request/all`,
            method: 'POST',
            ...params,
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `lanefees/${id}/restore`,
            method: 'PATCH',
        }
    },
    cancellation: ({
        id,
    }) => {
        return {
            url: `lanefees/${id}/cancellation`,
            method: 'PATCH',
            domainName: 'LaneFees',
        }
    },
    validity: ({
        id,
    }) => {
        return {
            url: `lanefees/${id}/validity`,
            method: 'PATCH',
            domainName: 'LaneFees',
        }
    },
    awardedBulk: (data) => {
        return {
            url: 'lanefees/lanefeesstatus/awarded/bulk',
            method: 'PATCH',
            data,
        }
    },
    rejectedBulk: (data) => {
        return {
            url: 'lanefees/lanefeesstatus/rejected/bulk',
            method: 'PATCH',
            data,
        }
    },
    changeStatus: ({
        id,
        status,
        data,
    }) => {
        return {
            url: `lanefees/${id}/lanefeesstatus/${status}`,
            method: 'PATCH',
            data,
        }
    },
    [RequestKeys.laneFeesFilterRequestAwardReject]: (params: Record<string, any> = {}) => {
        return {
            url: 'lanefees/filter-request/awardreject',
            method: 'POST',
            ...params?.params,
            data: {
                ...params?.data,
            },
        }
    },
}

export default laneFeesRequests
