import React, {
    useCallback,
} from 'react'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    getEditedItems, useGetRequestSpreadSheetAdapter,
} from 'app/shared-components/SkyNetSpreadSheet'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import {
    useSkyNetPagination, SkyNetPaginationContext,
} from 'app/shared-components/SkyNetPagination'
import useBulkEdit from 'app/hooks/useBulkEdit'
import requests from '../Margins.request'
import {
    MarginsEditable, MarginsTable,
} from '../Margins.types'
import MarginsSpreadsheet from '../MarginsSpreadsheet'
import {
    prepareMarginsToBulkEdit,
} from '../Margins.utils'

export default function MarginsBulkEdit({
    onClose,
}: { onClose: () => void }) {
    const paginationOptions = useSkyNetPagination(25)
    const {
        isError, loaded, error, data,
    } = useGetRequestSpreadSheetAdapter<MarginsTable>({
        config: requests[RequestKeys.marginsFilterRequest],
        requestKey: RequestKeys.marginsFilterRequest,
        enabled: true,
        paginationOptions,
    })

    const bulkEdit = useBulkEdit<MarginsEditable>({
        requestConfig: requests.bulkEdit,
        successMessage: 'Margins were updated',
        id: 'MarginsBulkUpdate',
    })

    const onSave = useCallback((items: MarginsTable[], options?: AsyncMutationOptions) => {
        const editedItems = getEditedItems({
            allItems: items,
            initData: data,
        })

        bulkEdit(prepareMarginsToBulkEdit(editedItems), {
            ...options,
            onSuccess: options?.onSuccess || onClose,
        })
    }, [
        bulkEdit,
        data,
        onClose,
    ])

    return (
        <StatusHandler
            isError={isError}
            isFetching={!loaded}
            error={error}
            isSuccess={loaded}
        >
            <SkyNetPaginationContext.Provider value={paginationOptions}>
                <MarginsSpreadsheet
                    data={data}
                    onCancel={onClose}
                    onSave={onSave}
                />
            </SkyNetPaginationContext.Provider>
        </StatusHandler>
    )
}
