import React, {
    useCallback,
} from 'react'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    getEditedItems,
    useGetRequestSpreadSheetAdapter,
} from 'app/shared-components/SkyNetSpreadSheet'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import {
    useSkyNetPagination,
    SkyNetPaginationContext,
} from 'app/shared-components/SkyNetPagination'
import useBulkEdit from 'app/hooks/useBulkEdit'
import requests from '../RegionalServiceAvailability.request'
import {
    RegionalServiceAvailabilityEditable,
    RegionalServiceAvailabilityItem,
} from '../RegionalServiceAvailability.types'
import RegionalServiceAvailabilitySpreadsheet from '../RegionalServiceAvailabilitySpreadsheet'
import {
    prepareRegionalServiceAvailabilityToBulkEdit,
} from '../RegionalServiceAvailability.utils'

export default function RegionalServiceAvailabilityBulkEdit({
    onClose,
}: Readonly<{
  onClose: () => void;
}>) {
    const paginationOptions = useSkyNetPagination(25)
    const {
        isError, loaded, error, data,
    } = useGetRequestSpreadSheetAdapter<RegionalServiceAvailabilityItem>({
        config: requests[RequestKeys.regionalServiceAvailabilityFilterRequest],
        requestKey: RequestKeys.regionalServiceAvailabilityFilterRequest,
        enabled: true,
        paginationOptions,
    })

    const bulkEdit = useBulkEdit<RegionalServiceAvailabilityEditable>({
        requestConfig: requests.bulkEdit,
        successMessage: 'Regional Service Availability was updated',
        id: 'RegionalServiceAvailabilityBulkUpdate',
    })

    const onSave = useCallback(
        (
            items: RegionalServiceAvailabilityItem[],
            options?: AsyncMutationOptions,
        ) => {
            const editedItems = getEditedItems({
                allItems: items,
                initData: data,
            })

            bulkEdit(prepareRegionalServiceAvailabilityToBulkEdit(editedItems), {
                ...options,
                onSuccess: options?.onSuccess || onClose,
            })
        },
        [
            bulkEdit,
            data,
            onClose,
        ],
    )

    return (
        <StatusHandler
            isError={isError}
            isFetching={!loaded}
            error={error}
            isSuccess={loaded}
        >
            <SkyNetPaginationContext.Provider value={paginationOptions}>
                <RegionalServiceAvailabilitySpreadsheet
                    data={data}
                    onCancel={onClose}
                    onSave={onSave}
                />
            </SkyNetPaginationContext.Provider>
        </StatusHandler>
    )
}
