import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    NestedKeyOf,
} from 'app/types/utils.types'

import {
    CQuoteContactDetails,
} from '../../CQuoteInformationContext'

const fields : DTOCardFormField<NestedKeyOf<CQuoteContactDetails>>[] = [{
    id: 'contactDetails',
    title: 'Contact Details',
    className: 'contactDetails',
    fields: [
        {
            id: 'contactDetails-column1',
            fields: [{
                name: 'skyCellContact',
                componentName: 'UserObjectSelector',
                required: true,
                labelKey: 'AccountQuotes.skycellContact',
            }],
        },
        {
            id: 'contactDetails-column2',
            fields: [{
                name: 'quotedFor',
                componentName: 'ContactObjectSelector',
                required: true,
                labelKey: 'AccountQuotes.quotedFor',
            }],
        },
        {
            id: 'contactDetails-column3',
            fields: [{
                name: 'generatePdf',
                componentName: 'Checkbox',
                required: true,
                labelKey: 'AccountQuotes.generatePdf',
            }],
        },
    ],
}]

export default fields
