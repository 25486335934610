import React, {
    useCallback, useMemo,
} from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import {
    RichTableData,
} from 'app/types/richtable.types'
import useLabel from 'app/hooks/useLabel'
import {
    Currency,
} from 'app/types/enums'

import {
    AdditionalFees,
} from '../../CQuotes.types'
import getColumns from './additionalFeesTable.columns'
import useStyles from './additionalFeesTable.styles'

const AdditionalFeesTable = ({
    value,
    currency,
    title,
}: {
    value: AdditionalFees[],
    currency: Currency,
    title: string,
}) => {
    const l = useLabel()
    const {
        classes,
    } = useStyles()

    const load = useCallback((): Promise<RichTableData<AdditionalFees[]>> => {
        return Promise.resolve({
            data: value,
        })
    }, [value])

    const columns = useMemo(() => {
        return getColumns(l, currency)
    }, [
        currency,
        l,
    ])

    return (
        <Card
            title={title}
            className={classes.card}
        >
            <RichTable
                configName="AdditionalFeesTable"
                name="AdditionalFees"
                columns={columns}
                load={load}
                className={classes.tableContent}
                classNames={{
                    contentWrapper: classes.tableContentWrapper,
                }}
            />
        </Card>
    )
}

export default AdditionalFeesTable
