import {
    makeStyles,
} from 'app/tss'

const contractInfo = 'contractInfo'
const summary = 'summary'
const orderStatus = 'orderStatus'
const customer = 'customer'
const dedicatedContainers = 'dedicatedContainers'
const orderStatusHistory = 'orderStatusHistory'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gap: theme.spacing(2),
            gridTemplateAreas: `
                "${contractInfo} ${summary} ${summary} ${orderStatus}" 
                "${customer} ${dedicatedContainers} ${dedicatedContainers} ${dedicatedContainers}" 
                "${orderStatusHistory} ${dedicatedContainers} ${dedicatedContainers} ${dedicatedContainers}"
                `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        contractInfo: {
            gridArea: contractInfo,
        },
        summary: {
            gridArea: summary,
        },
        orderStatus: {
            gridArea: orderStatus,
        },
        customer: {
            gridArea: customer,
        },
        dedicatedContainers: {
            gridArea: dedicatedContainers,
        },
        orderStatusHistory: {
            gridArea: orderStatusHistory,
        },
    }
})

export default useStyles
