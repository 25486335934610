import React, {
    useEffect,
    useMemo,
    useState,
} from 'react'

import Card from 'app/shared-components/Card'

import StatusHandler from 'app/shared-components/StatusHandler'
import {
    ContainerAvailabilityForecastContainerStatuses,
    ForecastAvailabilityOptions,
} from '../../serviceCenters.types'
import useGetScenarioEditorContainerAvailability from '../hooks/useGetScenarioEditorContainerAvailability'
import useScenarioEditorContext from '../ScenarioEditorContext'
import OptionPanel from '../../Monitoring/ContainerAvailabilityForecast/OptionPanel'
import ContainerAvailabilityForecastChart
    from '../../Monitoring/ContainerAvailabilityForecast/ContainerAvailabilityForecastChart'
import useStyles from './scenarioEditorAvailabilityChart.styles'

const ScenarioEditorAvailabilityChart = () => {
    const {
        classes,
    } = useStyles()

    const [
        optionChart,
        setOptionChart,
    ] = useState<ForecastAvailabilityOptions>({
        containerStatus: {
            [ContainerAvailabilityForecastContainerStatuses.ToBeProcessed]: true,
            [ContainerAvailabilityForecastContainerStatuses.InProcess]: false,
            [ContainerAvailabilityForecastContainerStatuses.Processed]: false,
            [ContainerAvailabilityForecastContainerStatuses.InPreconditioning]: false,
            [ContainerAvailabilityForecastContainerStatuses.Preconditioned]: true,
        },
        productType: null,
    })

    const {
        contextValue,
    } = useScenarioEditorContext()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetScenarioEditorContainerAvailability({
        serviceCenter: contextValue?.serviceCenter,
        forecastsByProduct: contextValue?.forecastsByProduct,
        processingTime: contextValue?.processingTime,
    })

    const allowedValuesProductType = useMemo(() => {
        return data?.forecastsByProduct?.reduce((acc, {
            productType,
        }) => {
            return {
                ...acc,
                [productType]: productType,
            }
        }, {})
    }, [data?.forecastsByProduct])

    useEffect(() => {
        if (!optionChart.productType && data?.forecastsByProduct) {
            setOptionChart({
                ...optionChart,
                productType: data?.forecastsByProduct?.[0]?.productType,
            })
        }
    }, [
        data?.forecastsByProduct,
        optionChart,
    ])

    const dataChart = useMemo(() => {
        return data?.forecastsByProduct?.find(({
            productType,
        }) => {
            return productType === optionChart.productType
        })
    }, [
        data?.forecastsByProduct,
        optionChart.productType,
    ])

    return (
        <Card
            contentClass={classes.cardContent}
            data-testid="availability-scenario-editor"
            title="Container Availability Forecast"
        >
            <OptionPanel
                value={optionChart}
                onChange={setOptionChart}
                allowedValues={allowedValuesProductType}
            />
            <StatusHandler
                isSuccess={isDataReady && Boolean(dataChart)}
                isFetching={isFetching}
                isError={isError}
                error={error}
            >
                <ContainerAvailabilityForecastChart
                    value={dataChart?.forecast || []}
                    listStatuses={Object.keys(optionChart.containerStatus)}
                    containerStatus={optionChart.containerStatus}
                    minPreconditioned={dataChart?.minPreconditioned}
                />
            </StatusHandler>
        </Card>
    )
}

export default ScenarioEditorAvailabilityChart
