import React, {
    useCallback, useMemo, useEffect, useState,
} from 'react'

import Collapse from '@mui/material/Collapse'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import useHistoryNavigation from 'app/hooks/useHistoryNavigation'
import useLoadFilters from 'app/Apps/DomainObject/hooks/useLoadFilters'

import useRenderTableControlPanel from './useRenderTableControlPanel'
import useCollapsablePanel from './hooks/useCollapsablePanel'
import useStyles from './CollapsibleTable.styles'
import {
    collapsibleDefaultTableProps,
    CollapsibleTableProps,
} from './CollapsibleTableProps'
import {
    CollapsibleTableContext,
} from './useCollapsibleTableContext'

const CollapsibleTable = ({
    title,
    domainName,
    configName,
    columns,
    customUrl,
    requestHandlers,
    actionComponents: ActionComponents,
    createEnabled,
    tableControls,
    switchableFormTable,
    backToTableText,
    routeParams,
    radioSelect,
    preDefinedFilters,
}: Readonly<CollapsibleTableProps>) => {
    const {
        classes,
    } = useStyles()
    const [
        search,
        setSearch,
    ] = useState<string>()

    const {
        id,
        action,
        updatePathWithId,
        updatePathWithParams,
    } = useHistoryNavigation(routeParams)
    const {
        loadData, refetch, onParamsChange, data, isDataReady,
    } = requestHandlers

    useEffect(() => {
        onParamsChange({
            searchAll: search,
            filter: {},
        })
    }, [
        search,
        onParamsChange,
    ])

    // effect to open the record if there's the only line in table
    useEffect(() => {
        if (isDataReady) {
            let result

            if (Array.isArray(data)) {
                result = data
            } else {
                result = data?.data
            }

            if (result?.length === 1) {
                updatePathWithId({
                    id: result?.[0]?.id,
                })
            }
        }
    }, [isDataReady, data]) // eslint-disable-line

    const {
        openCreateForm,
        createFormOpened,
        closeCreateForm,
    } = useCollapsablePanel(id)

    const renderTableControlPanel = useRenderTableControlPanel({
        title,
        stateName: domainName,
        createFormOpened,
        openCreateForm,
        closeCreateForm,
        createEnabled,
        tableControls,
        applySearch: setSearch,
    })

    const injectLoadFilters = useLoadFilters({
        customUrl,
        preDefinedFilters,
    })

    const columnsWithFilters = useMemo(() => {
        return injectLoadFilters(columns)
    }, [
        injectLoadFilters,
        columns,
    ])

    const onCreate = useCallback(({
        id: newId,
    }) => {
        updatePathWithParams({
            id: newId,
        })
        refetch()
        closeCreateForm()
    }, [
        closeCreateForm,
        updatePathWithParams,
        refetch,
    ])

    const getUpdateForm = useCallback(() => {
        return (
            <ActionComponents.Update
                id={id}
                refetch={refetch}
            />
        )
    }, [
        ActionComponents,
        id,
        refetch,
    ])

    const renderCopyComponent = useCallback(() => {
        return ActionComponents.Copy ? (
            <ActionComponents.Copy
                id={id}
                refetch={refetch}
            />
        ) : null
    }, [
        id,
        refetch,
        ActionComponents,
    ])

    const renderCreateComponent = useCallback(() => {
        return ActionComponents.Create ? (
            <Collapse in={createFormOpened}>
                <ActionComponents.Create
                    onSuccess={onCreate}
                    onCancel={closeCreateForm}
                />
            </Collapse>
        ) : null
    }, [
        onCreate,
        closeCreateForm,
        ActionComponents,
        createFormOpened,
    ])

    const removeAllParams = useCallback(() => {
        updatePathWithId({
            id: undefined,
        })
    }, [updatePathWithId])

    const context = useMemo(() => {
        return {
            id,
            action,
            routeParams,
            updatePathWithId,
            updatePathWithParams,
        }
    }, [
        id,
        action,
        routeParams,
        updatePathWithId,
        updatePathWithParams,
    ])

    return (
        <CollapsibleTableContext.Provider value={context}>
            <div className={classes.container}>
                {(switchableFormTable && id)
                    ? (
                        <>
                            <div className={classes.backToTableText}>
                                <ArrowBackIosIcon className={classes.icon} />
                                <a
                                    onKeyDown={removeAllParams} // eslint-disable-line max-len
                                    onClick={removeAllParams}
                                >
                                    {backToTableText}
                                </a>
                            </div>

                            {getUpdateForm()}
                        </>
                    ) : (
                        <>
                            {
                                action === 'copy'
                                    ? renderCopyComponent()
                                    : renderCreateComponent()
                            }
                            <RichTable
                                configName={configName || domainName}
                                name={domainName}
                                columns={customUrl ? columnsWithFilters : columns}
                                load={loadData}
                                onParamsChange={onParamsChange}
                                uniqField="id"
                                className={classes.tableContent}
                                classNames={{
                                    contentWrapper: classes.tableContentWrapper,
                                    headerWrapper: classes.tableHeaderWrapper,
                                }}
                                radioSelect={radioSelect}
                                detailPanel={switchableFormTable ? undefined : getUpdateForm}
                                onRowClick={updatePathWithId}
                                openRowId={(action || createFormOpened) ? undefined : Number(id)}
                                renderControlPanel={renderTableControlPanel}
                            />
                        </>
                    )}
            </div>
        </CollapsibleTableContext.Provider>

    )
}

CollapsibleTable.defaultProps = collapsibleDefaultTableProps

export default CollapsibleTable
