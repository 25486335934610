import React, {
    useMemo,
    useState,
} from 'react'

import SkyNetTable, {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    getUrlsFromTableConfig,
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    DeliveryServiceType,
    TransportModeRoad,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import OrdersContext from 'app/Apps/OrderManagement/Orders/CustomerTransport/OrdersContext'
import ContainerSelection from 'app/Apps/OrderManagement/Orders/Components/ContainerSelection'
import OrderNotificationsTab from 'app/Apps/OrderManagement/Orders/Components/OrderNotificationsTab'
import OrderSteps from 'app/Apps/OrderManagement/Orders/Components/OrderSteps'
import {
    OrderStatuses,
} from 'app/types/enums'
import SkyNetDomainForm from 'app/shared-components/SkyNetDomain/SkyNetDomainForm'

import useOrderManagementRoutes from '../../hooks/useOrderManagementRoutes'
import orderRequest from '../orders.request'

import OrderChanges from './OrderChanges'
import CustomerTransportsOverview from './CustomerTransportsOverview'
import DataUpdate from './DataUpdate'
import BillingInformation from './BillingInformation'
import Invoices from './Invoices'
import SharedPositioning from './SharedPositioning'
import OrderConfirmation from './OrderConfirmation'
import getCustomerOrderConfig from './customerTransport.config'
import CreateAirlineLease from './CreateAirlineLease/CreateCustomerTransportForm'
import CreateDirectLease from './CreateDirectLease'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

type Props = {
    name?: string,
    defaultTab?: string,
    config?: SkyNetTableConfig,
}

const defaultProps = {
    name: orderRequest.dataModelName,
    config: null,
    defaultTab: 'overview',
}

const CustomerTransport = ({
    name,
    config,
    defaultTab,
}: Props) => {
    const {
        CustomerOrders: CustomerOrdersRoutes,
    } = useOrderManagementRoutes()
    const [
        selectedInvoiceId,
        setSelectedInvoiceId,
    ] = useState<number>()

    const outgoingInvoiceRead: boolean = useHasPermission(['outgoing_invoice_read'])
    const orderUpdateTransport: boolean = useHasPermission(['order_update_transport'])
    const orderConfirmationCondition = ({
        orderStatus,
    }: {
        orderStatus: OrderStatuses,
    }): boolean => {
        return orderStatus === OrderStatuses.NOT_STARTED
    }
    const sharedPositioningCondition = ({
        transportModeRoad,
        deliveryServiceType,
    }: {
        transportModeRoad: TransportModeRoad,
        deliveryServiceType: DeliveryServiceType,
    }): boolean => {
        return transportModeRoad === TransportModeRoad.FTL
                && deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD
    }
    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                url: CustomerOrdersRoutes.Overview.route,
                label: 'Overview',
                Component: CustomerTransportsOverview,
            },
            permission: true,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.Update.route,
                label: 'Data Update',
                Component: DataUpdate,
            },
            permission: true,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.Notifications.route,
                label: 'Notifications',
                Component: OrderNotificationsTab,
            },
            permission: true,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.OrderChanges.route,
                label: 'Order Changes',
                Component: OrderChanges,
            },
            permission: true,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.ContainerSelection.route,
                label: 'Containers / Freight',
                Component: ContainerSelection,
            },
            permission: true,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.OrderSteps.route,
                label: 'Order Steps',
                Component: OrderSteps,
            },
            permission: true,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.BillingInformation.route,
                label: 'Billing Information',
                Component: BillingInformation,
            },
            permission: outgoingInvoiceRead,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.Invoices.route,
                label: 'Invoices',
                Component: Invoices,
            },
            permission: outgoingInvoiceRead,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.SharedPositioning.route,
                label: 'Shared Positioning',
                Component: SharedPositioning,
                condition: sharedPositioningCondition,
            },
            permission: orderUpdateTransport,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.OrderConfirmation.route,
                label: 'Order Confirmation',
                Component: OrderConfirmation,
                condition: orderConfirmationCondition,
            },
            permission: true,
        },
    ])

    const contextValue = useMemo(() => {
        return {
            selectedInvoiceId,
            setSelectedInvoiceId,
        }
    }, [selectedInvoiceId])

    const customRoutes = useMemo(() => {
        return [
            {
                path: CustomerOrdersRoutes.CreateAirlineLease.route,
                element: (
                    <SkyNetDomainForm onClose={() => {
                        CustomerOrdersRoutes.navigate()
                    }}
                    >
                        <CreateAirlineLease />
                    </SkyNetDomainForm>
                ),
            },
            {
                path: CustomerOrdersRoutes.CreateDirectLease.route,
                element: (
                    <SkyNetDomainForm onClose={() => {
                        CustomerOrdersRoutes.navigate()
                    }}
                    >
                        <CreateDirectLease />
                    </SkyNetDomainForm>
                ),
            },
        ]
    }, [CustomerOrdersRoutes])

    const tableConfig = useMemo(() => {
        return config || getCustomerOrderConfig()
    }, [config])

    return (
        <OrdersContext.Provider value={contextValue}>
            <SkyNetDomain
                name={name}
                sidebarTabs={tabs}
                subdomainUrls={getUrlsFromTableConfig(tableConfig)}
                getDomainObject={orderRequest.OrderByNumber.requestFn}
                domainRequestKey={RequestKeys.getOrderByNumber}
                defaultTab={defaultTab}
                uniqField="orderNumber"
                customRoutes={customRoutes}
            >
                <SkyNetTable
                    moduleName={orderRequest.moduleName}
                    tableControls={tableControls}
                    tableConfig={tableConfig}
                    showSearch
                />
            </SkyNetDomain>
        </OrdersContext.Provider>
    )
}

CustomerTransport.defaultProps = defaultProps

export default CustomerTransport
