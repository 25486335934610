import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        panel: {
            display: 'flex',
        },
        containerStatus: {
            '&&': {
                paddingRight: theme.spacing(6),
            },
        },
        productType: {
            '&&': {
                flexBasis: 200,
                paddingRight: theme.spacing(6),
            },
        },
        wrapperCheckbox: {
            display: 'flex',
        },
        checkbox: {
            padding: '0 !important',
        },
    }
})

export default useStyles
