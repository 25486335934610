import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react'
import isEqual from 'lodash/isEqual'
import every from 'lodash/every'

import useStyles from 'app/Apps/AccountManagement/CQuotes/CQuoteCreateSelectMethod/QuotationDetails.styles'
import useGetAccountLane from 'app/Apps/AccountManagement/AccountLanes/hooks/useGetAccountLane'
import useEnumValues from 'app/hooks/useEnumValues'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEmbargoValidations from 'app/Apps/AccountManagement/CQuotes/CQuoteCreateSelectMethod/hooks/useEmbargoValidations'
import useGetRateCardsData from 'app/Apps/Rates/RateCards/hooks/useGetRateCardsData'
import useValidation from 'app/hooks/useValidation'
import useGetLocation from 'app/Apps/ContactManagement/Locations/hooks/useGetLocation'
import {
    ProductType, TemperatureRange, LaneStatus,
} from 'app/types/enums'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import StatusHandler from 'app/shared-components/StatusHandler'
import SkyNetStepperButtons from 'app/shared-components/SkyNetStepper/SkyNetStepperButtons'
import {
    LeaseTypePossibilityStatus,
} from 'app/Apps/AccountManagement/CQuotes/CQuotes.types'

import useCQuoteInformationContext, {
    TCQuoteStepper,
    CQuoteLaneDetails as TCQuoteLaneDetails,
} from '../../CQuoteInformationContext'

import getFields from './CQuoteLaneDetails.fields'

export default function CQuoteLaneDetails({
    index,
}: Readonly<{index: number}>) {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const {
        value: contextValue,
        setValue: setContextValue,
        setActiveStep,
        activeStep,
    } = useCQuoteInformationContext()

    const [
        value,
        setValue,
    ] = useState<TCQuoteStepper>(contextValue)

    const {
        data,
        isDataReady,
        isError,
        isFetching,
        error,
    } = useGetAccountLane(contextValue?.lane?.id)

    useEffect(() => {
        if (isDataReady) {
            setValue({
                ...value,
                laneDetails: {
                    ...data, expPalletsYear: data?.expPalletsYear || 1,
                } as TCQuoteLaneDetails,
                isGroupedLaneStatuses: [
                    LaneStatus.IN_PROGRESS,
                    LaneStatus.PRICED,
                    LaneStatus.AWARDED,
                    LaneStatus.IMPLEMENTED,
                    LaneStatus.INACTIVE,
                ].includes(data?.laneStatus),
            })
        }
    }, [isDataReady]) // eslint-disable-line

    const {
        embargoOrigin,
        embargoDestination,
        validateEmbargoOrigin,
        validateEmbargoDestination,
    } = useEmbargoValidations({
        origin: value.laneDetails?.originCountry?.id,
        destination: value.laneDetails?.destinationCountry?.id,
    })

    const productTypeMap = useMemo(() => {
        return {
            [TemperatureRange.CRT]: ProductType.VAL_1500XCRT,
            [TemperatureRange.C]: ProductType.VAL_1500XCOL,
        }
    }, [])

    const formValues = useMemo(() => {
        return {
            ...value?.laneDetails,
            expPalletsYear: value?.laneDetails?.expPalletsYear || 1,
            productType: productTypeMap?.[value?.laneDetails?.temperatureRange],
        }
    }, [
        productTypeMap,
        value?.laneDetails,
    ])

    const rateCardsParams = useMemo(() => {
        const {
            originRegion,
            destinationRegion,
            productType,
        } = formValues

        if (every([
            originRegion,
            destinationRegion,
            productType,
        ], Boolean)
            && !embargoDestination?.embargoed && !embargoOrigin?.embargoed) {
            return {
                originRegion,
                destinationRegion,
                productType,
            }
        }
        return null
    }, [
        formValues,
        embargoOrigin,
        embargoDestination,
    ])

    const {
        data: rateCardsData,
    } = useGetRateCardsData(rateCardsParams)

    const {
        data: originAirportData,
    } = useGetLocation(formValues?.originAirport?.id)

    const {
        data: destinationAirportData,
    } = useGetLocation(formValues?.destinationAirport?.id)

    useEffect(() => {
        // set originCountry and originRegion if originAirport is selected
        if (
            originAirportData && formValues?.originAirport
            && formValues?.originRegion !== originAirportData.regionSecondLevel
        ) {
            setValue({
                ...value,
                laneDetails: {
                    ...value.laneDetails,
                    originCountry: originAirportData.country,
                    originRegion: originAirportData.regionSecondLevel,
                },
            })
        }
    }, [
        value,
        originAirportData,
        setValue,
        formValues,
    ])

    useEffect(() => {
        // set destinationCountry and destinationRegion if destinationAirport is selected
        if (
            destinationAirportData && formValues?.destinationAirport
            && formValues?.destinationRegion !== destinationAirportData.regionSecondLevel
        ) {
            setValue({
                ...value,
                laneDetails: {
                    ...value.laneDetails,
                    destinationCountry: destinationAirportData.country,
                    destinationRegion: destinationAirportData.regionSecondLevel,
                },
            })
        }
    }, [
        destinationAirportData,
        setValue,
        formValues,
        value,
    ])

    const disabled = Boolean(activeStep !== index || value?.nonEditable)

    const fields = useFieldsWithClassName(getFields({
        getAllowedValues,
        validateEmbargoOrigin,
        validateEmbargoDestination,
        rateCardsData,
        classes,
        nonEditable: Boolean(value?.lane?.id) || disabled,
    }), classes)

    const onChange = useCallback((newValue) => {
        if (!isEqual(newValue, value?.laneDetails)) {
            setValue({
                ...value,
                laneDetails: newValue,
            })
        }
    }, [
        value,
        setValue,
    ])

    const {
        isValid,
    } = useValidation({
        fields,
        values: formValues,
    })

    const isFormValid = useMemo(() => {
        return isValid && !embargoOrigin?.embargoed && !embargoDestination?.embargoed
        && rateCardsData?.leaseTypePossibilityStatus
            === LeaseTypePossibilityStatus.DIRECT_LEASE_POSSIBLE
    }, [
        embargoDestination?.embargoed,
        embargoOrigin?.embargoed,
        isValid,
        rateCardsData?.leaseTypePossibilityStatus,
    ])

    useEffect(() => {
        if ([
            LaneStatus.IN_PROGRESS,
            LaneStatus.PRICED,
            LaneStatus.AWARDED,
            LaneStatus.IMPLEMENTED,
        ].includes(formValues.laneStatus) && isFormValid && rateCardsData) {
            setActiveStep(3)
            setContextValue({
                ...value,
                laneDetails: {
                    ...value.laneDetails,
                    productType: productTypeMap?.[value?.laneDetails?.temperatureRange],
                },
                rateCardsData,
                nonEditable: true,
            })
        }
    }, [
        isFormValid,
        formValues,
        setActiveStep,
        value,
        setContextValue,
        rateCardsData,
        productTypeMap,
    ])

    const nextAction = useCallback(() => {
        setContextValue({
            ...value,
            laneDetails: {
                ...value.laneDetails,
                productType: productTypeMap?.[value?.laneDetails?.temperatureRange],
            },
            rateCardsData,
        })
        setActiveStep(activeStep + 1)
    }, [
        setContextValue,
        value,
        productTypeMap,
        rateCardsData,
        setActiveStep,
        activeStep,
    ])

    const editAction = useCallback(() => {
        setActiveStep(index)
        setContextValue({
            lane: value?.lane,
            laneDetails: value?.laneDetails,
            rateCardsData: value?.rateCardsData,
            isGroupedLaneStatuses: value?.isGroupedLaneStatuses,
        })
    }, [
        index,
        setActiveStep,
        setContextValue,
        value,
    ])

    return (
        <>
            <StatusHandler
                isError={isError}
                error={error}
                isFetching={isFetching}
                isSuccess={!contextValue?.lane?.id || isDataReady}
            >
                <DomainObjectForm
                    value={formValues}
                    onChange={onChange}
                    fields={fields}
                    disabled={disabled}
                    className={classes.gridWrapper}
                    name="CQuoteLaneDetails"
                />
            </StatusHandler>
            <SkyNetStepperButtons
                isValid={isFormValid}
                disabled={disabled}
                nextLabel="Continue"
                nextAction={nextAction}
                canEdit={activeStep > index && !contextValue?.nonEditable}
                editAction={editAction}
            />
        </>
    )
}
