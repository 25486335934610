import React, {
    useCallback,
    useMemo,
} from 'react'
import pick from 'lodash/pick'

import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import FormWithControls from 'app/shared-components/FormWithControls'
import {
    NotificationTypes,
} from 'app/types/enums'

import {
    NotificationTableView,
} from '../utils/OrderNotifications.types'

import getFields from './orderNotificationForm.fields'
import useStyles from './OrderNotificationForm.style'

type Props = {
    rowProps: NotificationTableView,
    closeOpenedRow: () => void,
    onSuccess: (data: NotificationTableView) => void,
    orderNumber: string,
    disabled?: boolean,
}

const defaultProps: Partial<Props> = {
    disabled: false,
}

const OrderNotificationForm = ({
    rowProps,
    closeOpenedRow,
    onSuccess: onSuccessDerived,
    orderNumber,
    disabled,
}: Props) => {
    const {
        notificationName,
        notificationType,
        sentFirstTimeOn,
        sentThirdTimeOn,
        id,
    } = rowProps

    const {
        classes,
    } = useStyles()

    const onSuccess = useCallback((data: NotificationTableView): void => {
        onSuccessDerived(data)
        closeOpenedRow()
    }, [
        onSuccessDerived,
        closeOpenedRow,
    ])

    const fields = useMemo(() => {
        return getFields({
            notificationType,
            notificationName,
            orderNumber,
            id,
        })
    }, [
        id,
        notificationName,
        notificationType,
        orderNumber,
    ])

    const disabledRow: boolean = useMemo(() => {
        if (disabled) {
            return true
        }

        return (sentFirstTimeOn && notificationType === NotificationTypes.NOTIFICATION)
            || (sentThirdTimeOn && notificationType === NotificationTypes.FOLLOW_UP)
    }, [
        disabled,
        notificationType,
        sentFirstTimeOn,
        sentThirdTimeOn,
    ])

    const modifyDataBeforeSend = useCallback((requestData) => {
        return pick(requestData, [
            'sendFirstTime',
            'sendSecondTime',
            'sendThirdTime',
            'recipients',
            'customAttachments',
        ])
    }, [])

    const requestParams = orderManagementRequest.OrderNotification.Update({
        id,
    })

    return (
        <>
            <FormWithControls
                value={rowProps}
                name={orderManagementRequest.OrderNotification.domainName}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                }}
                className={classes.rowFormContainer}
                modifyDataBeforeSend={modifyDataBeforeSend}
                fields={fields}
                onSuccess={onSuccess}
                onCancel={closeOpenedRow}
                disabled={disabledRow}
                requestParams={requestParams}
                exists
            />
        </>
    )
}

OrderNotificationForm.defaultProps = defaultProps

export default OrderNotificationForm
