import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
        },
        columnLeft: {
            paddingRight: theme.spacing(1),
            width: '50%',
        },
        columnRight: {
            paddingLeft: theme.spacing(1),
            width: '50%',
        },
    }
})

export default useStyles
