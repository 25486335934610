import React, {
    useCallback,
} from 'react'

import useFilter from 'app/hooks/useFilter'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'

import PreBookingSpreadsheet from '../PreBookingSpreadsheet'
import {
    convertToPrebookingEntity,
} from '../PreBookingSpreadsheet/PrebookingSpreadsheet.utils'
import {
    PreBookingEntitySpreadsheetData,
} from '../PreBookingSpreadsheet/PreBookingSpreadsheet.config'

import usePreBookingBulkCreate from './hooks/usePreBookingBulkCreate'

const PreBookingBulkCreate = ({
    onClose,
}: Readonly<{onClose: () => void}>) => {
    const filterOptions = useFilter()

    const bulkCreate = usePreBookingBulkCreate()

    const onSave = useCallback((
        preBookings: PreBookingEntitySpreadsheetData[], options?: AsyncMutationOptions,
    ) => {
        bulkCreate(convertToPrebookingEntity(preBookings), {
            ...options,
            onSuccess: options?.onSuccess || onClose,
        })
    }, [
        onClose,
        bulkCreate,
    ])

    return (
        <PreBookingSpreadsheet
            filterOptions={filterOptions}
            onSave={onSave}
            onCancel={onClose}
            enableAddNewRows
        />
    )
}

export default PreBookingBulkCreate
