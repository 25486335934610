import React, {
    useMemo,
} from 'react'
import Typography from '@mui/material/Typography'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Card from 'app/shared-components/Card'
import useLabel from 'app/hooks/useLabel'
import StatusHandler from 'app/shared-components/StatusHandler'

import useStyles from '../CompanyOverview.styles'

import columns from './ParentCompanySubsidiaries.columns'
import useGetSubsidiaries from './hooks/useGetSubsidiaries'

const ParentCompanySubsidiaries = ({
    id: parentCompany,
}: {id: number}) => {
    const {
        classes,
    } = useStyles()

    const l = useLabel()

    const {
        isDataReady,
        load,
        isError,
        isFetching,
        error,
    } = useGetSubsidiaries(parentCompany)

    const fields = useMemo(() => {
        return columns({
            l,
        })
    }, [l])

    return (
        <div
            className={classes.tableContainer}
        >
            <Card
                fullHeight
                className={classes.cardRoot}
                contentClass={classes.cardContent}
            >
                <StatusHandler
                    isSuccess={isDataReady}
                    isFetching={isFetching}
                    isError={isError}
                    error={error}
                >
                    <>
                        <div className={classes.header}>
                            <Typography variant="h3">
                                Subsidiaries
                            </Typography>
                        </div>
                        <RichTable
                            configName="parentCompanySubsidiaries"
                            name="Subsidiaries"
                            load={load}
                            columns={fields}
                            uniqField="id"
                            className={classes.tableContent}
                            classNames={{
                                contentWrapper: classes.tableContentWrapper,
                            }}
                        />
                    </>
                </StatusHandler>
            </Card>
        </div>
    )
}

export default ParentCompanySubsidiaries
