import {
    useMemo,
} from 'react'
import {
    createRoutesConfig,
    SkyNetRoutesConfig,
    useSkyNetNavigate, App, CommonRoutes,
} from 'app/SkyNetRoutes'
import useCreateSkyNetDomainRoutes from 'app/shared-components/SkyNetDomain/useCreateSkyNetDomainRoutes'
import {
    ContainerManagementDomains,
} from '../ContainerManagement.types'
import {
    ContainersTabs,
} from '../Containers/containers.types'
import {
    ServiceCenterTabs,
} from '../ServiceCenters/serviceCenters.types'

export default function useContainerManagementRoutes() {
    const skyNetNavigate = useSkyNetNavigate()
    const SkyNetDomainRoutes = useCreateSkyNetDomainRoutes()()

    return useMemo(() => {
        const config: SkyNetRoutesConfig = {
            path: [App.CONTAINERS],
            Containers: {
                route: ContainerManagementDomains.Containers,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ChangeStatus: {
                    route: ContainersTabs.ChangeStatus,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Loggers: {
                    route: ContainersTabs.Loggers,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                SensorData: {
                    route: ContainersTabs.SensorData,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Damages: {
                    route: ContainersTabs.Damages,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Geolocation: {
                    route: ContainersTabs.GeoLocation,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
            ContainersSelection: {
                route: ContainerManagementDomains.ContainersSelection,
            },
            ContainerTypes: {
                route: ContainerManagementDomains.ContainerTypes,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
            ServiceCenters: {
                route: ContainerManagementDomains.ServiceCenters,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Monitoring: {
                    route: ServiceCenterTabs.Monitoring,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ScenarioEditor: {
                    route: ServiceCenterTabs.ScenarioEditor,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [
        SkyNetDomainRoutes,
        skyNetNavigate,
    ])
}
