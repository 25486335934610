import {
    SkyNetTableTab,
} from 'app/shared-components/SkyNetTable/types/skyNetTable.types'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import {
    RateCardsTabs,
} from '../RateCards.types'

import requests from './EmbargoCountries.request'
import {
    EmbargoContriesTable, EmbargoType,
} from './EmbargoContries.types'

export default function getEmbargoCountriesConfig(): SkyNetTableTab<keyof EmbargoContriesTable> {
    return {
        name: 'Embargo Countries',
        url: RateCardsTabs.EmbargoCountries,
        customUrl: requests.filterRequest().url,
        fields: [
            {
                name: 'countryCountryName',
                labelKey: 'EmbargoCountries.countryName',
            },
            {
                name: 'embargoType',
                componentName: 'EnumValue',
                allowedValuesEnum: EmbargoType,
                labelKey: 'EmbargoCountries.embargoType',
            },
        ],
        exclude: {
            tableControls: [TableControlTypes.BulkEdit],
        },
    }
}
