import {
    SkyNetTableTab,
} from 'app/shared-components/SkyNetTable/types/skyNetTable.types'
import {
    RegionSecondLevel,
} from 'app/types/enums'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    RateCardsTabs,
} from '../RateCards.types'
import requests from './RegionalServiceAvailability.request'
import {
    RegionalServiceAvailabilityItem,
} from './RegionalServiceAvailability.types'

const availabilityCellMapper = (propertyName: string) => {
    return (item: RegionalServiceAvailabilityItem) => {
        return {
            children: item[propertyName] ? 'Available' : 'Not Available',
        }
    }
}

export default function getRegionalServiceAvailabilityConfig():
    SkyNetTableTab<keyof RegionalServiceAvailabilityItem> {
    return {
        name: 'Regional Service Availability',
        url: RateCardsTabs.RegionalServiceAvailability,
        customUrl: requests[RequestKeys.regionalServiceAvailabilityFilterRequest]().url,
        fields: [
            {
                name: 'regionSecondLevel',
                labelKey: 'Country.regionSecondLevel',
                componentName: 'EnumValue',
                allowedValuesEnum: RegionSecondLevel,
            },
            {
                name: 'originAirlineLeaseRequired',
                labelKey: 'RegionalServiceAvailabilityRateCard.originAirlineLeaseRequired',
                componentName: 'Boolean',
            },
            {
                name: 'originPickupStationAvailableCol',
                labelKey: 'RegionalServiceAvailabilityRateCard.originPickupStationAvailableCOL',
                componentName: 'Boolean',
                mapCellProps: availabilityCellMapper('originPickupStationAvailableCol'),
            },
            {
                name: 'originPickupStationAvailableCrt',
                labelKey: 'RegionalServiceAvailabilityRateCard.originPickupStationAvailableCRT',
                componentName: 'Boolean',
                mapCellProps: availabilityCellMapper('originPickupStationAvailableCrt'),
            },
            {
                name: 'originTruckPositioning',
                labelKey: 'RegionalServiceAvailabilityRateCard.originTruckPositioning',
                componentName: 'Boolean',
                mapCellProps: availabilityCellMapper('originTruckPositioning'),
                sorting: false,
            },
            {
                name: 'originPositioningLtl',
                labelKey: 'RegionalServiceAvailabilityRateCard.originPositioningLTL',
                componentName: 'Boolean',
                mapCellProps: availabilityCellMapper('originPositioningLtl'),
                sorting: false,
            },
            {
                name: 'originPositioningFtl',
                labelKey: 'RegionalServiceAvailabilityRateCard.originPositioningFTL',
                componentName: 'Boolean',
                mapCellProps: availabilityCellMapper('originPositioningFtl'),
                sorting: false,
            },
            {
                name: 'originTruckCapacity',
                labelKey: 'RegionalServiceAvailabilityRateCard.originTruckCapacity',
                mapCellProps: ({
                    originTruckCapacity,
                    originTruckCapacityAvailability,
                }: RegionalServiceAvailabilityItem) => {
                    return {
                        children: originTruckCapacityAvailability ? originTruckCapacity : 'Not Available',
                    }
                },
            },
            {
                name: 'destinationAirlineLeaseRoundtripRequired',
                labelKey: 'RegionalServiceAvailabilityRateCard.destinationAirlineLeaseRoundtripRequired',
                componentName: 'Boolean',
            },
            {
                name: 'destinationDropoff',
                labelKey: 'RegionalServiceAvailabilityRateCard.destinationDropoff',
                componentName: 'Boolean',
                mapCellProps: availabilityCellMapper('destinationDropoff'),
                sorting: false,
            },
            {
                name: 'destinationCollectionLtl',
                labelKey: 'RegionalServiceAvailabilityRateCard.destinationCollectionLTL',
                componentName: 'Boolean',
                mapCellProps: availabilityCellMapper('destinationCollectionLtl'),
                sorting: false,
            },

        ],
    }
}
