import isEmpty from 'lodash/isEmpty'
import {
    CollServiceType, DeliveryServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import requests from 'app/Apps/AccountManagement/CQuotes/CQuote.request'

import {
    FeesData, ProductTypeCQuote,
} from 'app/Apps/AccountManagement/CQuotes/CQuotes.types'
import {
    ContractCurrency,
} from 'app/types/enums'

export type FeesRequestData = {
    expPalletsYear: number,
    rentalDays: number,
    logisticsDays: number,
} & ({
    lane: { id: number },
} | {
    productType: ProductTypeCQuote,
    originAirport: { id: number },
    destinationAirport: { id: number },
    deliveryServiceType: DeliveryServiceType,
    collectionServiceType: CollServiceType,
    handoverPoint: { id: number },
    collectionDropoffPoint: { id: number },
    currency: ContractCurrency,
})

export default function useGetCQuotesFeesData(requestData: FeesRequestData) {
    return useRequest<FeesData>({
        key: RequestKeys.getCQuoteFeesData,
        params: requestData,
        requestFunc: RequestFn.getEntity(requests[RequestKeys.getCQuoteFeesData]),
        enabled: !isEmpty(requestData),
    })
}
