import React, {
    useMemo,
} from 'react'
import LaneFeesTable, {
    LaneFeesCopy, LaneFeesCreate,
    LaneFeesFormContext,
} from 'app/shared-components/LaneFees'

import LaneGroupContextWrapper from 'app/Apps/Pricing/LaneGroups/LaneGroupContextWrapper'
import getFields from './LaneFeesForm.fields'
import AssignPricingLaneFeesUpdate from './AssignPricingLaneFeesUpdate'

const LaneFees = ({
    id,
}: {
    id: number
}) => {
    const actionComponents = useMemo(() => {
        return {
            Copy: LaneFeesCopy,
            Update: AssignPricingLaneFeesUpdate,
            Create: LaneFeesCreate,
        }
    }, [])

    const value = useMemo(() => {
        return {
            getFields,
        }
    }, [])

    return (
        <LaneGroupContextWrapper id={id}>
            <LaneFeesFormContext.Provider value={value}>
                <LaneFeesTable actionComponents={actionComponents} />
            </LaneFeesFormContext.Provider>
        </LaneGroupContextWrapper>
    )
}

export default LaneFees
