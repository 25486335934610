import {
    useCallback,
} from 'react'
import {
    useQueryClient,
} from '@tanstack/react-query'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    RegionFirstLevel,
    TemperatureRange,
} from 'app/types/enums'

import AccountManagementRequests from '../AccountManagement.request'

const useGetAccountOptions = (id) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getAccountOptions],
        })
    }, [queryClient])

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<{
        originRegion: RegionFirstLevel[],
        destinationRegion: RegionFirstLevel[],
        tempRange: TemperatureRange[]
    }>({
        key: RequestKeys.getAccountOptions,
        params: {
            accountId: id,
        },
        requestFunc: RequestFn.getEntity(AccountManagementRequests.getRegionalGroupsByAccount),
        keepPreviousData: true,
        enabled: Boolean(id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetAccountOptions
