/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import noop from 'lodash/noop'

import TextSingleline from 'app/shared-components/TextSingleline'

import Select from './Select'

const propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    data: PropTypes.arrayOf(PropTypes.any),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    allowedValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    required: PropTypes.bool,
    disableUnderline: PropTypes.bool,
    className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    disableHelperText: PropTypes.bool,
    disableSorting: PropTypes.bool,
    noNone: PropTypes.bool,
}

const defaultProps = {
    value: '',
    name: undefined,
    data: [],
    onChange: noop,
    disabled: false,
    allowedValues: undefined,
    title: undefined,
    required: undefined,
    disableUnderline: undefined,
    className: undefined,
    disableHelperText: false,
    disableSorting: false,
    noNone: false,
}

const getLabel = (value, data, allowedValues) => {
    if (data.length > 0) {
        return get(data.find((item) => {
            return item[0] === value
        }), '[1]')
    }

    return get(allowedValues, `${value}`, '')
}

const SelectContainer = (props) => {
    const {
        value,
        data,
        onChange,
        disabled,
        allowedValues,
    } = props

    const handleChange = useCallback(({
        target,
    }) => {
        onChange(
            target.value,
            {
                target,
            },
        )
    }, [onChange])

    if (disabled) {
        return (
            <TextSingleline
                {...props}
                value={getLabel(value, data, allowedValues)}
            />
        )
    }

    return (
        <Select
            {...props}
            onChange={handleChange}
        />
    )
}

SelectContainer.propTypes = propTypes
SelectContainer.defaultProps = defaultProps

export default SelectContainer
