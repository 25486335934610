import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requestConfig from 'app/Apps/Rates/RateCards/EmbargoCountries/EmbargoCountries.request'

const useGetCountryEmbargo = (countryId: number) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<{embargoed: boolean}>({
        key: RequestKeys.getCountryEmbargo,
        params: {
            countryId,
        },
        requestFunc: RequestFn.getEntity(requestConfig.getCountryEmbargo),
        enabled: Boolean(countryId),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useGetCountryEmbargo
