import React, {
    useCallback,
} from 'react'
import Collapse from '@mui/material/Collapse'

import {
    useArchivedTableControl,
} from 'app/shared-components/TableControls/ShowArchived'
import LaneGroupsTable from 'app/Apps/Pricing/ContractBasis/components/LaneGroupsTable'
import StatusHandler from 'app/shared-components/StatusHandler'
import Card from 'app/shared-components/Card'
import LaneGroupRequests from 'app/Apps/Pricing/LaneGroups/LaneGroup.requests'
import useRenderTableControlPanel from 'app/shared-components/CollapsibleTable/useRenderTableControlPanel'
import LaneGroupForm from 'app/Apps/Pricing/LaneGroups/LaneGroupForm'
import useCollapsablePanel from 'app/shared-components/CollapsibleTable/hooks/useCollapsablePanel'

import useGetContractBasis from '../hooks/useGetContractBasis'
import usePricingRoutes from '../../hooks/usePricingRoutes'

import useStyles from './LaneGroups.styles'

const LaneGroups = ({
    id: contractId,
}: { id: number }) => {
    const {
        LaneGroups: LaneGroupsRoutes,
    } = usePricingRoutes()
    const {
        classes,
    } = useStyles()

    const {
        data,
        isDataReady,
        isError,
        isFetching,
        error,
    } = useGetContractBasis(contractId)

    const showArchived = useArchivedTableControl(LaneGroupRequests.domainName)

    const {
        openCreateForm,
        createFormOpened,
        closeCreateForm,
    } = useCollapsablePanel(data?.currency)

    const renderTableControlPanel = useRenderTableControlPanel({
        title: 'Lane Groups',
        stateName: LaneGroupRequests.domainName,
        createFormOpened,
        openCreateForm,
        closeCreateForm,
    })

    const onRowClick = useCallback(({
        id,
    }) => {
        LaneGroupsRoutes.Overview.navigate({
            params: {
                key: id,
            },
            options: {
                state: {
                    filter: {
                        customerQuotationLocationName: [data?.customerQuotation?.locationName],
                        pricingAccountCompanyName: [data?.account?.company?.companyName],
                        contractBasisCurrency: [data?.currency],
                    },
                },
            },
        })
    }, [
        LaneGroupsRoutes.Overview,
        data,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <div
                data-testid="LaneGroupsTable"
                className={classes.root}
            >
                <Card
                    fullHeight
                    contentClass={classes.cardContent}
                    data-testid="LaneGroupsTable-card"
                >
                    <div className={classes.container}>
                        <Collapse in={createFormOpened}>
                            <LaneGroupForm
                                onSuccess={onRowClick}
                                onCancel={closeCreateForm}
                                requestParam={LaneGroupRequests.create({
                                    contractId,
                                })}
                            />
                        </Collapse>
                        <LaneGroupsTable
                            contractId={contractId}
                            onRowClick={onRowClick}
                            renderControlPanel={renderTableControlPanel}
                            showArchived={showArchived}
                        />
                    </div>
                </Card>
            </div>
        </StatusHandler>
    )
}

export default LaneGroups
