import moment from 'moment'
import {
    Theme,
} from '@mui/material/styles/createTheme'

import {
    FieldConfig,
    SkyNetSpreadSheetCellType,
    SkyNetSpreadSheetConfigType, checkNegativeNumber,
} from 'app/shared-components/SkyNetSpreadSheet'
import {
    dateMask,
} from 'app/utils/date'
import {
    ScenarioByProductTypeMeasures,
    ScenarioByProductTypeRow,
} from '../ForecastsByProduct/ForecastsByProduct.utils'

export default ({
    columns,
    theme,
    styles,
}: {
    columns: Set<string>;
    theme: Theme;
    styles: Record<string, string>,
}): SkyNetSpreadSheetConfigType<keyof ScenarioByProductTypeRow> => {
    const applyNegativeNumberStyle = (name) => {
        return (value) => {
            if (checkNegativeNumber(value?.[name])) {
                return styles.errors
            }
            return undefined
        }
    }

    return {
        id: 'ScenarioByProductType',
        headerRow: true,
        rowsCounter: false,
        addRowMenuOption: false,
        enableRangeSelection: true,
        enableRowSelection: true,
        stickyTopRows: 1,
        emptyRows: 0,
        initialFocusLocation: {
            columnId: 'measure',
            rowId: 0,
        },
        fields: [
            {
                name: 'measure',
                title: 'Measure',
                width: 200,
                disabled: true,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.SELECT,
                    style: {
                        background: theme.palette.common.white,
                    },
                },
                allowedValues: ScenarioByProductTypeMeasures,
            },
            ...Array.from(columns).map((name) => {
                return {
                    name,
                    title: moment(name, dateMask).format('DD.MM'),
                    width: 50,
                    cellConfig: {
                        type: SkyNetSpreadSheetCellType.NUMBER,
                        style: applyNegativeNumberStyle(name),
                    },
                    hideZero: false,
                    required: true,
                }
            }),
        ] as FieldConfig<keyof ScenarioByProductTypeRow>[],
    }
}
