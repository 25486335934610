import React, {
    useMemo,
    useRef, useState,
} from 'react'
import clsx from 'clsx'

import useScrollOptions from 'app/hooks/useScrollOptions'

import {
    SpreadsheetCellOption,
} from '../SkyNetSpreadSheet.types'
import OptionCheckboxDropdown from '../OptionCheckboxDropdown'
import SearchNotification from '../SearchNotification'

import useStyles from './MultipleObjectSelectDropdown.style'

const defaultProps = {
    selected: [],
    pending: false,
}

const MultipleObjectSelectDropdown = (
    {
        rowHeight,
        options,
        selected,
        setSelected,
        pending,
    }: {
        rowHeight: number,
        options: SpreadsheetCellOption[],
        selected?: Record<string, any>[],
        setSelected: React.Dispatch<Record<string, any>[]>,
        pending?: boolean
    },
) => {
    const {
        classes,
    } = useStyles()
    const ref = useRef(null)
    const [copySelected] = useState(selected)

    const filteredOptions = useMemo(() => {
        return copySelected.length ? options?.filter((option) => {
            return !copySelected.some(({
                id,
            }) => {
                return id === option.id
            })
        }) : options
    }, [
        options,
        copySelected,
    ])

    const optionsList = useMemo(() => {
        return [
            ...copySelected,
            ...filteredOptions,
        ]
    }, [
        copySelected,
        filteredOptions,
    ])

    useScrollOptions(ref)

    if (pending) return <SearchNotification msg="Please, wait..." />

    return (
        (options || selected).length > 0 ? (
            <div
                ref={ref}
                data-testid="typeahead-object-options-wrapper"
                className={clsx(classes.options)}
                style={{
                    top: rowHeight,
                }}
            >
                {optionsList.map((option, i) => {
                    const {
                        value, label, id,
                    } = option
                    const checked = selected.some((item) => {
                        return item.id === id
                    })
                    const setAsSelected = (e) => {
                        e.stopPropagation()
                        if (checked) {
                            return setSelected(selected.filter((el) => {
                                return el.id !== id
                            }))
                        }
                        return setSelected([
                            ...selected,
                            option,
                        ])
                    }

                    return (
                        <OptionCheckboxDropdown
                            key={`option-${value}`}
                            onClick={setAsSelected}
                            value={value}
                            label={label}
                            checked={checked}
                            index={i}
                        />
                    )
                })}
            </div>
        ) : <SearchNotification msg="No search results" />
    )
}

MultipleObjectSelectDropdown.defaultProps = defaultProps

export default MultipleObjectSelectDropdown
